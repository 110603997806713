<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container
            style="height: 100vh;">
            <v-row
              align="center"
              style="height:calc(100vh - 100px)">
              <v-col>
                <v-card  
                  flat
                  color="transparent">
                  <v-card-text
                    class="text-center mb-8">
                    <v-img
                      contain
                      width="200"
                      height="auto"
                      class="ma-auto"
                      alt="bidanvitacare"
                      :src="require('@/assets/logo/favicon.png')">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div
                      style="position: relative; top: -35px;">
                      <div>
                        Mom, Baby & Kids Spa Sejak 2017
                      </div>
                      
                      <div
                        class="mt-2">
                        Sahabat Ceria Bunda & Buah Hati
                      </div>
                    </div>
                  </v-card-text>

                  <v-card-text
                    class="text-center">
                    <div
                      class="body-2 line-text-second mb-16">
                      Reset password akun berhasil, terima kasih Anda telah melakukan reset password untuk keamanan akun Anda.
                    </div>

                    <v-btn
                      to="/"
                      width="200"
                      large
                      rounded
                      elevation="0"
                      class="body-1 font-weight-bold white--text text-capitalize btn_auth"
                      :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                      <div
                        class="btn_auth_in"
                        :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                        Masuk Sekarang
                      </div>
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <div>
              <v-row
                justify="center">
                <v-col 
                  cols="2">
                  <v-divider 
                    :style="`border-top: 5px solid ${set_color}; border-radius: 50px;`" />
                </v-col>
              </v-row>

              <div
                class="body-2 text-center mt-2"
                style="opacity: 70%;">
                Copyright@{{ new Date().getFullYear() }} <strong>bidanvitacare.id</strong>
              </div>
            </div>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        // SEO
        content: {
          url: '/auth/success',
          title: 'Reset Password Terkirim',
          description: 'Reset Password Terkirim Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>