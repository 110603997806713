<template>
  <div>
    <v-dialog v-model="dialog.partner_or_terapis"
      persistent
      scrollable>
      <v-card
        class="border-radius pa-2">
        <v-card-title
          class="d-flex align-center py-0 px-3 text-right popup-header">
          <v-avatar
            size="30"
            tile>
            <v-img
              alt="bidanvitacare"
              :src="require('@/assets/logo/favicon.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    size="20"
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <span
            class="body-1 font-weight-bold text-capitalize ml-2">
            {{ selected.type_user }}
          </span>
        </v-card-title>

        <v-divider
          class="mx-4 mt-3 mb-2"/>

        <v-card-text v-if="selected.partner_or_terapis.length > 0"
          class="body-2 text-first line-text-second px-4 pb-2">
          <v-row>
            <v-col
              v-for="(item, index) in selected.partner_or_terapis"
              :key="index"
              cols="12">
              <v-list
                flat
                dense
                color="#FFFFFF40"
                class="border-radius"
                style="border: 1px dashed #bbb;">
                <v-list-item>
                  <v-list-item-avatar
                    size="40"
                    style="border: 2px solid #ffffff;">
                    <v-img
                      width="100%"
                      height="100%"
                      alt="bidanvitacare"
                      :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      class="body-2 font-weight-bold text-capitalize">
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider
          class="mx-4 mb-3 mt-0"/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            @click="dialog.partner_or_terapis = false"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
            <div
              class="btn_cancel_in">
              <v-icon
                size="20">
                mdi-close-thick
              </v-icon>

              <span
                class="ml-1">
                Tutup
              </span>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.filter"
      persistent>
      <v-card
        class="border-radius pa-2">
        <v-card-title
          class="d-flex align-center py-0 px-3 text-right popup-header">
          <v-avatar
            size="30"
            tile>
            <v-img
              alt="bidanvitacare"
              :src="require('@/assets/logo/favicon.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    size="20"
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <span
            class="body-1 font-weight-bold text-capitalize ml-2">
            Filter
          </span>
        </v-card-title>

        <v-divider
          class="mx-4 mt-3 mb-2"/>

        <v-card-text
          class="body-2 text-first line-text-second mt-3">
          <div>
            <div class="body-2 mb-2">
              Tanggal Kunjungan
            </div>

            <v-menu
              ref="range"
              min-width="290px"
              max-width="290px"
              transition="scale-transition"
              offset-y
              :nudge-right="40"
              :close-on-content-click="false"
              v-model="picker.range">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  readonly
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 2023-01-01 s/d 2023-01-10"

                  hide-details

                  clearable
                  @click:clear="filter.dates = []"

                  v-on="on"
                  v-bind="attrs"

                  v-model="date_range">
                </v-text-field>
              </template>

              <v-date-picker
                range
                :color="set_color"
                v-model="filter.dates"
                @input="picker.range = filter.dates.length > 1 ? false : true">
              </v-date-picker>
            </v-menu>
          </div>
        </v-card-text>

        <v-divider
          class="mx-4 mb-3 mt-0"/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            @click="dialog.filter = false; fetch();"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_save">
            <div
              class="btn_save_in d-flex align-center justify-center">
              <v-icon
                size="20">
                mdi-check-bold
              </v-icon>

              <span
                class="ml-1">
                Simpan
              </span>
            </div>
          </v-btn>

          <v-btn
            @click="dialog.filter = false"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
            <div
              class="btn_cancel_in">
              <v-icon
                size="20">
                mdi-close-thick
              </v-icon>

              <span
                class="ml-1">
                Batal
              </span>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text
          class="pb-0 mt-1 d-flex align-center">
          <v-text-field
            v-model="search"
            @click:clear="search = ''; fetch();"
            v-on:keyup.enter="fetch()"
            filled
            rounded
            dense
            single-line
            hide-details
            :color="set_color"
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari data yang Anda butuhkan ?"
            clearable>
          </v-text-field>

          <v-btn
            @click="dialog.filter = true"
            width="40"
            height="40"
            fab
            elevation="0"
            class="ml-3">
            <v-icon
              style="opacity: 60%;">
              mdi-filter-variant
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text
          class="pb-0">
          <v-row>
            <v-col
              cols="12">
              <v-skeleton-loader
                v-for="(item, index) in process.load ? 4 : list"
                :key="index"
                :loading="process.load"
                :class="index < list.length - 1 ? 'mb-4' : ''"
                type="list-item-avatar-three-line">
                <template>
                  <div>
                    <v-list
                      v-if="!process.load && list.length > 0"
                      flat
                      dense
                      three-line
                      color="#FFFFFF40"
                      class="border-radius">
                      <v-list-item
                        @click="sheet.detail = true; fetchDetail(item.id);">
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-2 font-weight-bold d-flex align-center">
                            <v-avatar
                              size="25"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <span
                              class="text-capitalize ml-2">
                              {{ item.customer_name }}
                            </span>
                          </v-list-item-title>

                          <v-list-item-subtitle
                            class="body-2 font-weight-light line-text-second black--text my-2 py-2"
                            style="border-top: 1px dashed #bbb; border-bottom: 1px dashed #bbb;">
                            <div
                              class="d-flex align-center">
                              <div>
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  Tipe
                                </div>

                                <div
                                  class="body-2 black--text">
                                  {{ item.type === 'on_site' ? 'On Site' : 'Home Care' }}
                                </div>
                              </div>

                              <v-spacer />

                              <div>
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  Umur
                                </div>

                                <div
                                  class="body-2 black--text">
                                  {{ item.age !== '' ? item.age : '-' }}
                                </div>
                              </div>
                            </div>

                            <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />

                            <div
                              class="d-flex align-center">
                              <div>
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  BB
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.bb }} Kg
                                </div>
                              </div>

                              <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                              <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  TB
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.tb }} Cm
                                </div>
                              </div>

                              <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                              <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  LILA
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.lila }} Cm
                                </div>
                              </div>

                              <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                              <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  LK
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.lk }} Cm
                                </div>
                              </div>

                              <v-spacer v-if="item.customer_type !== 'bayi' && item.customer_type !== 'anak'" />

                              <div v-if="item.customer_type !== 'bayi' && item.customer_type !== 'anak'">
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  Tensi
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.tensi }} mmHg
                                </div>
                              </div>
                            </div>

                            <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />

                            <div
                              class="d-flex align-center">
                              <div
                                class="caption"
                                style="opacity: 60%;">
                                Klien
                              </div>

                              <v-spacer />

                              <div>
                                <div
                                  class="d-flex align-center cursor-pointer">
                                  <v-avatar
                                    v-for="(item_customer, index_customer) in item.patner"
                                    :key="index_customer"
                                    v-show="index_customer < 2"
                                    size="24"
                                    class="mt-0"
                                    style="border: 2px solid #ffffff;"
                                    :style="item.patner.length === 2 && index_customer === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                            item.patner.length > 2 && index_customer === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                            item.patner.length > 2 && index_customer === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                                    <v-img
                                      width="100%"
                                      height="100%"
                                      alt="bidanvitacare"
                                      :src="item_customer.image_url !== '' ? item_customer.image_url : require('@/assets/image/member_default.jpg')"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular
                                            size="20"
                                            indeterminate
                                            :color="set_color">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>

                                  <span v-if="item.patner.length < 2"
                                    class="caption font-weight-bold one-line text-capitalize ml-1"
                                    style="width: 50px;">
                                    {{ item.patner[0].name }}
                                  </span>

                                  <span v-if="item.patner.length > 2"
                                    class="caption font-weight-bold ml-1">
                                    +{{ item.patner.length - 2 }}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <!-- <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />

                            <div
                              class="d-flex align-center">
                              <div
                                class="caption"
                                style="opacity: 60%;">
                                Terapis
                              </div>

                              <v-spacer />

                              <div>
                                <div
                                  class="d-flex align-center cursor-pointer">
                                  <v-avatar
                                    v-for="(item_therapist, index_therapist) in item.therapist"
                                    :key="index_therapist"
                                    v-show="index_therapist < 2"
                                    size="24"
                                    class="mt-0"
                                    style="border: 2px solid #ffffff;"
                                    :style="item.therapist.length === 2 && index_therapist === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                            item.therapist.length > 2 && index_therapist === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                            item.therapist.length > 2 && index_therapist === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                                    <v-img
                                      width="100%"
                                      height="100%"
                                      alt="bidanvitacare"
                                      :src="item_therapist.image_url !== '' ? item_therapist.image_url : require('@/assets/image/member_default.jpg')"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular
                                            size="20"
                                            indeterminate
                                            :color="set_color">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>

                                  <span v-if="item.therapist.length < 2"
                                    class="caption font-weight-bold one-line ml-1"
                                    style="width: 50px;">
                                    {{ item.therapist[0].name }}
                                  </span>

                                  <span v-if="item.therapist.length > 2"
                                    class="caption font-weight-bold ml-1">
                                    +{{ item.therapist.length - 2 }}
                                  </span>
                                </div>
                              </div>
                            </div> -->
                          </v-list-item-subtitle>

                          <v-list-item-subtitle
                            class="d-flex align-center">
                            <div>
                              <div
                                class="caption mb-1">
                                Tanggal & Waktu Kunjungan
                              </div>

                              <div
                                class="black--text">
                                {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                              </div>
                            </div>

                            <v-spacer />

                            <div>
                              <div
                                class="caption mb-1">
                                {{ item.customer_wali_status }}
                              </div>

                              <div
                                class="black--text">
                                {{ item.customer_wali_name }}
                              </div>
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </template>
              </v-skeleton-loader>
            </v-col>
          </v-row>

          <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
            class="text-center my-4">
            <v-btn
              @click="limit += 10"
              :loading="process.limit"
              small
              rounded
              outlined
              elevation="0"
              :color="set_color"
              class="text-capitalize"
              :style="`color: ${set_color};`">
              Lebih banyak
            </v-btn>
          </div>

          <Empty v-if="!process.load && pagination.total_page < 1"
            class="py-12"
            margin="my-16"
            size="125"
            message="Kunjungan" />
        </v-card-text>
      </v-card>
    </section>

    <v-bottom-sheet
      v-model="sheet.detail"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card v-if="Object.keys(detail).length > 0"
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.detail = false; detail = {}; fetch();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.detail = false; detail = {}; fetch();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Kunjungan {{ detail.customer_name }}
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 80px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="pt-16 mt-4 pb-0">
              <div
                class="d-flex align-center">
                <v-avatar
                  size="36"
                  style="border: 2px solid #ffffff;">
                  <v-img
                    width="100%"
                    height="100%"
                    alt="bidanvitacare"
                    :src="detail.customer_image_url !== '' ? detail.customer_image_url : require('@/assets/image/member_default.jpg')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="20"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <span
                  class="font-weight-bold text-capitalize black--text ml-2">
                  {{ detail.customer_name }}
                </span>
              </div>
            </v-card-text>

            <v-card-text>
              <v-divider
                class="mb-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="d-flex align-center">
                <div>
                  <div
                    class="body-2 mb-1">
                    Tipe
                  </div>

                  <div
                    class="body-2 font-weight-bold black--text">
                    {{ detail.type === 'on_site' ? 'On Site' : 'Home Care' }}
                  </div>
                </div>

                <v-spacer />

                <div>
                  <div
                    class="body-2 mb-1">
                    Umur
                  </div>

                  <div
                    class="body-2 font-weight-bold black--text">
                    {{ detail.age !== '' ? detail.age : '-' }}
                  </div>
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="d-flex align-center">
                <div>
                  <div
                    class="body-2 mb-1">
                    BB
                  </div>

                  <div
                    class="body-2 black--text font-weight-bold">
                    {{ detail.bb }} Kg
                  </div>
                </div>

                <v-spacer v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'" />

                <div v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'">
                  <div
                    class="body-2 mb-1">
                    TB
                  </div>

                  <div
                    class="body-2 black--text font-weight-bold">
                    {{ detail.tb }} Cm
                  </div>
                </div>

                <v-spacer v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'" />

                <div v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'">
                  <div
                    class="body-2 mb-1">
                    LILA
                  </div>

                  <div
                    class="body-2 black--text font-weight-bold">
                    {{ detail.lila }} Cm
                  </div>
                </div>

                <v-spacer v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'" />

                <div v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'">
                  <div
                    class="body-2 mb-1">
                    LK
                  </div>

                  <div
                    class="body-2 black--text font-weight-bold">
                    {{ detail.lk }} Cm
                  </div>
                </div>

                <v-spacer v-if="detail.customer_type !== 'bayi' && detail.customer_type !== 'anak'" />

                <div v-if="detail.customer_type !== 'bayi' && detail.customer_type !== 'anak'">
                  <div
                    class="body-2 mb-1">
                    Tensi
                  </div>

                  <div
                    class="body-2 black--text font-weight-bold">
                    {{ detail.tensi }} mmHg
                  </div>
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="d-flex align-center">
                <div
                  class="body-2">
                  Klien
                </div>

                <v-spacer />

                <div>
                  <div
                    @click="dialog.partner_or_terapis = true;
                            selected.type_user = 'Klien';
                            selected.partner_or_terapis = detail.patner;"
                    class="d-flex align-center cursor-pointer">
                    <v-avatar
                      v-for="(item_customer, index_customer) in detail.patner"
                      :key="index_customer"
                      v-show="index_customer < 2"
                      size="24"
                      class="mt-0"
                      style="border: 2px solid #ffffff;"
                      :style="detail.patner.length === 2 && index_customer === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                              detail.patner.length > 2 && index_customer === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                              detail.patner.length > 2 && index_customer === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="item_customer.image_url !== '' ? item_customer.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span v-if="detail.patner.length < 2"
                      class="caption black--text font-weight-bold one-line text-capitalize ml-1"
                      style="width: 50px;">
                      {{ detail.patner[0].name }}
                    </span>

                    <span v-if="detail.patner.length > 2"
                      class="caption black--text font-weight-bold ml-1">
                      +{{ detail.patner.length - 2 }}
                    </span>
                  </div>
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="d-flex align-center">
                <div
                  class="body-2">
                  Terapis
                </div>

                <v-spacer />

                <div>
                  <div
                    @click="dialog.partner_or_terapis = true;
                            selected.type_user = 'Terapis';
                            selected.partner_or_terapis = detail.therapist;"
                    class="d-flex align-center cursor-pointer">
                    <v-avatar
                      v-for="(item_therapist, index_therapist) in detail.therapist"
                      :key="index_therapist"
                      v-show="index_therapist < 2"
                      size="24"
                      class="mt-0"
                      style="border: 2px solid #ffffff;"
                      :style="detail.therapist.length === 2 && index_therapist === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                              detail.therapist.length > 2 && index_therapist === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                              detail.therapist.length > 2 && index_therapist === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="item_therapist.image_url !== '' ? item_therapist.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span v-if="detail.therapist.length < 2"
                      class="caption black--text font-weight-bold one-line ml-1"
                      style="width: 50px;">
                      {{ detail.therapist[0].name }}
                    </span>

                    <span v-if="detail.therapist.length > 2"
                      class="caption black--text font-weight-bold ml-1">
                      +{{ detail.therapist.length - 2 }}
                    </span>
                  </div>
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Tanggal & Waktu Kunjungan
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail.datetime | date }} Pukul {{ detail.datetime | time }}
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Status
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail.customer_wali_status !== '' ? detail.customer_wali_status : '-' }}
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Nama Wali
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail.customer_wali_name !== '' ? detail.customer_wali_name : '-' }}
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Nama Pendamping
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail.schedule_wali !== '' ? detail.schedule_wali : '-' }}
                </v-col>
              </v-row>

              <!-- <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Keluhan
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  <div
                    class="d-flex align-start">
                    <div>
                      :
                    </div>

                    <div
                      class="ml-1">
                      {{ detail.complaint !== '' ? detail.complaint : '-' }}
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Keterangan
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  <div
                    class="d-flex align-start">
                    <div>
                      :
                    </div>

                    <div
                      class="ml-1">
                      {{ detail.note !== '' ? detail.note : '-' }}
                    </div>
                  </div>
                </v-col>
              </v-row> -->

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Alamat
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  <div
                    class="d-flex align-start">
                    <div>
                      :
                    </div>

                    <div
                      class="ml-1">
                      {{ detail.address_full !== '' ? detail.address_full : '-' }}
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Kecamatan
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail.address_subdistrict_name !== '' ? detail.address_subdistrict_name : '-' }}
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Kabupaten
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail.address_city_name !== '' ? detail.address_city_name : '-' }}
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Provinsi
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail.address_province_name !== '' ? detail.address_province_name : '-' }}
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Rumah
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  <div
                    class="d-flex align-start">
                    <div>
                      :
                    </div>

                    <div
                      class="ml-1">
                      {{ detail.address_note !== '' ? detail.address_note : '-' }}
                    </div>
                  </div>
                </v-col>
              </v-row>

              <!-- <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Komplain untuk Terapis (Admin)
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  <div
                    class="d-flex align-start">
                    <div>
                      :
                    </div>

                    <div
                      class="ml-1">
                      {{ detail.admin_complaint !== '' ? detail.admin_complaint : '-' }}
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Saran / Masukkan (Admin)
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  <div
                    class="d-flex align-start">
                    <div>
                      :
                    </div>

                    <div
                      class="ml-1">
                      {{ detail.admin_suggest !== '' ? detail.admin_suggest : '-' }}
                    </div>
                  </div>
                </v-col>
              </v-row> -->

              <!-- <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Komplain untuk Terapis (Customer)
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  <div
                    class="d-flex align-start">
                    <div>
                      :
                    </div>

                    <div
                      class="ml-1">
                      {{ detail.therapist_complaint !== '' ? detail.therapist_complaint : '-' }}
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Saran / Masukkan (Customer)
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  <div
                    class="d-flex align-start">
                    <div>
                      :
                    </div>

                    <div
                      class="ml-1">
                      {{ detail.therapist_suggest !== '' ? detail.therapist_suggest : '-' }}
                    </div>
                  </div>
                </v-col>
              </v-row> -->

              <v-divider
                v-if="detail.graph_bb_image_url !== ''"
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <div v-if="detail.graph_bb_image_url !== ''"
                class="mb-6">
                <div class="body-2 mb-2">
                  <!-- Grafik BB -->
                  {{ detail.graph_bb_title }}
                </div>

                <v-img
                  contain
                  width="100%"
                  height="100%"
                  style="border-radius: 10px; border: 2px dashed #bbb;"
                  alt="bidanvitacare"
                  :src="detail.graph_bb_image_url !== '' ? detail.graph_bb_image_url : require('@/assets/image/image_default.png')"
                  class="ma-auto d-flex align-center text-center">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div
                  class="body-2 line-text-second mt-4">
                  <div
                    class="black--text font-weight-bold mb-2">
                    Kesimpulan
                  </div>

                  <div>
                    {{ detail.graph_bb_kesimpulan }}
                  </div>
                </div>
              </div>

              <v-divider
                v-if="detail.graph_tb_image_url !== ''"
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <div v-if="detail.graph_tb_image_url !== ''"
                class="mb-6">
                <div class="body-2 mb-2">
                  <!-- Grafik TB -->
                  {{ detail.graph_tb_title }}
                </div>

                <v-img
                  contain
                  width="100%"
                  height="100%"
                  style="border-radius: 10px; border: 2px dashed #bbb;"
                  alt="bidanvitacare"
                  :src="detail.graph_tb_image_url !== '' ? detail.graph_tb_image_url : require('@/assets/image/image_default.png')"
                  class="ma-auto d-flex align-center text-center">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div
                  class="body-2 line-text-second mt-4">
                  <div
                    class="black--text font-weight-bold mb-2">
                    Kesimpulan
                  </div>

                  <div>
                    {{ detail.graph_tb_kesimpulan }}
                  </div>
                </div>
              </div>

              <v-divider
                v-if="detail.graph_lila_image_url !== ''"
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <div v-if="detail.graph_lila_image_url !== ''"
                class="mb-6">
                <div class="body-2 mb-2">
                  <!-- Grafik LILA -->
                  {{ detail.graph_lila_title }}
                </div>

                <v-img
                  contain
                  width="100%"
                  height="100%"
                  style="border-radius: 10px; border: 2px dashed #bbb;"
                  alt="bidanvitacare"
                  :src="detail.graph_lila_image_url !== '' ? detail.graph_lila_image_url : require('@/assets/image/image_default.png')"
                  class="ma-auto d-flex align-center text-center">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div
                  class="body-2 line-text-second mt-4">
                  <div
                    class="black--text font-weight-bold mb-2">
                    Kesimpulan
                  </div>

                  <div>
                    {{ detail.graph_lila_kesimpulan }}
                  </div>
                </div>
              </div>

              <v-divider
                v-if="detail.graph_lk_image_url !== ''"
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <div v-if="detail.graph_lk_image_url !== ''"
                class="mb-6">
                <div class="body-2 mb-2">
                  <!-- Grafik LK -->
                  {{ detail.graph_lk_title }}
                </div>

                <v-img
                  contain
                  width="100%"
                  height="100%"
                  style="border-radius: 10px; border: 2px dashed #bbb;"
                  alt="bidanvitacare"
                  :src="detail.graph_lk_image_url !== '' ? detail.graph_lk_image_url : require('@/assets/image/image_default.png')"
                  class="ma-auto d-flex align-center text-center">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div
                  class="body-2 line-text-second mt-4">
                  <div
                    class="black--text font-weight-bold mb-2">
                    Kesimpulan
                  </div>

                  <div>
                    {{ detail.graph_lk_kesimpulan }}
                  </div>
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="mb-3">
                <div
                  class="font-weight-bold black--text mb-1">
                  Catatan Kejadian
                </div>

                <div>
                  {{ detail.visit_detail[0].report_note !== '' && detail.visit_detail[0].report_note !== null ? detail.visit_detail[0].report_note : '-' }}
                </div>
              </div>

              <div
                class="mb-3">
                <div
                  class="font-weight-bold black--text mb-1">
                  Perihal Kesehatan Ibu & Anak yang ditanyakan
                </div>

                <div>
                  {{ detail.visit_detail[0].report_mom_health !== '' && detail.visit_detail[0].report_mom_health !== null ? detail.visit_detail[0].report_mom_health : '-' }}
                </div>
              </div>

              <div
                class="mb-3">
                <div
                  class="font-weight-bold black--text mb-1">
                  Pertanyaan kepo tentang manajemen
                </div>

                <div>
                  {{ detail.visit_detail[0].report_question !== '' && detail.visit_detail[0].report_question !== null ? detail.visit_detail[0].report_question : '-' }}
                </div>
              </div>

              <div
                class="mb-3">
                <div
                  class="font-weight-bold black--text mb-1">
                  Kesalahan yang dilakukan Terapis
                </div>

                <div>
                  {{ detail.visit_detail[0].report_mistake !== '' && detail.visit_detail[0].report_mistake !== null ? detail.visit_detail[0].report_mistake : '-' }}
                </div>
              </div>

              <div
                class="mb-3">
                <div
                  class="font-weight-bold black--text mb-1">
                  Komplain Klien secara langsung
                </div>

                <div>
                  {{ detail.visit_detail[0].report_complaint !== '' && detail.visit_detail[0].report_complaint !== null ? detail.visit_detail[0].report_complaint : '-' }}
                </div>
              </div>

              <div
                class="mb-3">
                <div
                  class="font-weight-bold black--text mb-1">
                  Masukkan untuk manajemen bidanvitacare / kendala yang dihadapi saat homecare atau onsite
                </div>

                <div>
                  {{ detail.visit_detail[0].report_suggest !== '' && detail.visit_detail[0].report_suggest !== null ? detail.visit_detail[0].report_suggest : '-' }}
                </div>
              </div>

              <div>
                <div
                  class="font-weight-bold black--text mb-1">
                  Promosi
                </div>

                <div>
                  {{ detail.visit_detail[0].report_promotion !== '' && detail.visit_detail[0].report_promotion !== null ? detail.visit_detail[0].report_promotion : '-' }}
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="50"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row>
                <v-col
                  :cols="detail.customer_type === 'bayi' || detail.customer_type === 'anak' ? 4 : 6"
                  class="py-0">
                  <v-btn
                    @click="sheet.form = true; reset(detail);"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                    <div
                      class="btn_grey_in d-flex align-center justify-center">
                      <div>
                        <v-img
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/edit_pencil.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="blue--text ml-2">
                        Ubah
                      </div>
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  :cols="detail.customer_type === 'bayi' || detail.customer_type === 'anak' ? 4 : 6"
                  class="py-0">
                  <v-btn
                    @click="sheet.attachment = true; fetchAttachment();"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                    <div
                      class="btn_grey_in d-flex align-center justify-center">
                      <div>
                        <v-img
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/attachment.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="pink--text ml-2">
                        Lamp
                      </div>
                    </div>
                  </v-btn>
                </v-col>

                <!-- v-if="detail.type === 'on_site' && (detail.customer_type === 'bayi' || detail.customer_type === 'anak')" -->
                <v-col v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'"
                  cols="4"
                  class="py-0">
                  <v-btn
                    @click="sheet.form_grafik = true; reset_grafik();"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                    <div
                      class="btn_grey_in d-flex align-center justify-center">
                      <div>
                        <v-img
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/grafik.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="green--text ml-2">
                        Grafik
                      </div>
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.form"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.form = false; reset();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.form = false; reset();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Ubah
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 205px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="pt-16 mt-4">
              <ValidationObserver
                ref="form">
                <v-form>
                  <div>
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Tanggal Kunjungan
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-menu
                      ref="date"
                      min-width="290px"
                      max-width="290px"
                      transition="scale-transition"
                      offset-y
                      :nudge-right="40"
                      :close-on-content-click="false"
                      v-model="picker.date">
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          name="Tanggal Kunjungan"
                          rules="required"
                          v-slot="{ errors, valid }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            readonly
                            filled
                            rounded
                            dense
                            :color="set_color"
                            placeholder="Contoh. 2023-01-01"

                            :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                            clearable
                            @click:clear="form.date = ''"
                            v-on="on"
                            v-bind="attrs"

                            v-model="form.date"
                            :success="valid"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </template>

                      <v-date-picker
                        :color="set_color"
                        v-model="form.date"
                        @input="picker.date = false">
                      </v-date-picker>
                    </v-menu>
                  </div>

                  <div>
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Waktu Kunjungan
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-menu
                      ref="time"
                      v-model="picker.time"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="form.time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          name="Waktu Kunjungan"
                          rules="required"
                          v-slot="{ errors, valid }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            readonly
                            filled
                            rounded
                            dense
                            :color="set_color"
                            placeholder="Contoh. 09:00"

                            :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                            clearable
                            @click:clear="form.time = ''"
                            v-on="on"
                            v-bind="attrs"

                            v-model="form.time"
                            :success="valid"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </template>

                      <v-time-picker
                        v-if="picker.time"
                        v-model="form.time"
                        full-width
                        :color="set_color"
                        format="24hr"
                        @click:minute="$refs.time.save(`${form.time}:00`)">
                      </v-time-picker>
                    </v-menu>
                  </div>

                  <ValidationProvider
                    name="Berat Badan"
                    rules="required|min:0"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Berat Badan
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save"
                      type="number"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                      min="0"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 0.25"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      @focus="$event.target.select()"
                      suffix="Kg"

                      v-model="form.bb"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'"
                    name="Tinggi Badan"
                    rules="required|min:0"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Tinggi Badan
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save"
                      type="number"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                      min="0"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 100"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      @focus="$event.target.select()"
                      suffix="Cm"

                      v-model="form.tb"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'"
                    name="Lingkar Lengan Atas"
                    rules="required|min:0"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Lingkar Lengan Atas
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save"
                      type="number"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                      min="0"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 20"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      @focus="$event.target.select()"
                      suffix="Cm"

                      v-model="form.lila"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'"
                    name="Lingkar Kepala"
                    rules="required|min:0"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Lingkar Kepala
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save"
                      type="number"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                      min="0"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 35"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      @focus="$event.target.select()"
                      suffix="Cm"

                      v-model="form.lk"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider v-if="detail.customer_type !== 'bayi' && detail.customer_type !== 'anak'"
                    name="Tensi"
                    rules="required|min:0"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Tensi
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save"

                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 120/70"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      @focus="$event.target.select()"
                      suffix="mmHg"

                      v-model="form.tensi"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <!-- <ValidationProvider
                    name="Keluhan"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      Keluhan
                      <span>
                        (boleh dikosongi)
                      </span>
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. Kaki kesemutan"
                      :append-icon="form.complaint !== '' && valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.complaint"
                      :success="form.complaint !== '' && valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider> -->

                  <!-- <ValidationProvider
                    name="Keterangan"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      Keterangan
                      <span>
                        (boleh dikosongi)
                      </span>
                    </div>

                    <v-textarea
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      rows="5"
                      placeholder="Contoh. Tuliskan keterangan disini . . ."
                      :append-icon="form.note !== '' && valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.note"
                      :success="form.note !== '' && valid"
                      :error-messages="errors"
                      required>
                    </v-textarea>
                  </ValidationProvider> -->

                  <!-- <ValidationProvider
                    name="Komplain untuk Terapis"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      Komplain untuk Terapis
                      <span>
                        (boleh dikosongi)
                      </span>
                    </div>

                    <v-textarea
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      rows="5"
                      placeholder="Contoh. Tuliskan komplain disini . . ."
                      :append-icon="form.admin_complaint !== '' && valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.admin_complaint"
                      :success="form.admin_complaint !== '' && valid"
                      :error-messages="errors"
                      required>
                    </v-textarea>
                  </ValidationProvider> -->

                  <!-- <ValidationProvider
                    name="Saran / Masukkan"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      Saran / Masukkan
                      <span>
                        (boleh dikosongi)
                      </span>
                    </div>

                    <v-textarea
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      rows="5"
                      placeholder="Contoh. Tuliskan saran / masukkan disini . . ."
                      :append-icon="form.admin_suggest !== '' && valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.admin_suggest"
                      :success="form.admin_suggest !== '' && valid"
                      :error-messages="errors"
                      required>
                    </v-textarea>
                  </ValidationProvider> -->
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="text-center my-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="150"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row
                align="center">
                <v-col
                  cols="3"
                  class="pb-0">
                  <v-img
                    contain
                    alt="bidanvitacare"
                    :src="require('@/assets/image/footer/visit.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col
                  cols="9"
                  class="black--text pb-0">
                  <div>
                    <div
                      class="body-2 line-text-second black--text"
                      style="opacity: 60%;">
                      Masukkan data untuk mengubah kunjungan di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row
                class="mt-4">
                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save"
                    @click="save"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-check-bold
                      </v-icon>

                      Simpan
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    @click="sheet.form = false; reset();"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-close-thick
                      </v-icon>

                      Batal
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.attachment"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.attachment = false; fetch();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.attachment = false; fetch();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Lampiran {{ detail.customer_name }}
            </span>
          </v-card-title>

          <v-card-text
            class="pb-0 pt-16 mt-5">
            <!-- <v-text-field
              v-model="search_attachment"
              @click:clear="search_attachment = ''; fetchAttachment();"
              v-on:keyup.enter="fetchAttachment()"
              filled
              rounded
              dense
              single-line
              hide-details
              :color="set_color"
              prepend-inner-icon="mdi-magnify"
              placeholder="Cari data yang Anda butuhkan ?"
              clearable>
            </v-text-field> -->

            <v-btn
              @click="sheet.form_attachment = true; reset_attachment();"
              block
              large
              rounded
              elevation="0"
              class="body-1 font-weight-bold white--text text-capitalize btn_auth"
              :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
              <div
                class="btn_auth_in d-flex align-center justify-center"
                :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                <div>
                  <v-img
                    contain
                    width="20"
                    height="20"
                    alt="bidanvitacare"
                    :src="require('@/assets/icon/attachment.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="20"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>

                <div
                  class="ml-2">
                  Tambah Lampiran
                </div>
              </div>
            </v-btn>
          </v-card-text>

          <v-card-text
            class="pb-0"
            style="height: calc(100vh - 185px); overflow: auto;">
            <v-row>
              <v-col
                v-for="(item, index) in process.load ? 6 : list_attachment"
                :key="index"
                cols="6">
                <v-skeleton-loader
                  :loading="process.load"
                  type="image">
                  <template>
                    <div>
                      <v-card
                        v-if="!process.load && list_attachment.length > 0"
                        flat
                        color="transparent"
                        height="200">
                        <v-img
                          contain
                          width="100%"
                          height="100%"
                          alt="bidanvitacare"
                          :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                          class="ma-auto"
                          style="border: 5px solid #0000000d;
                                border-radius: 10px;">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-card>
                    </div>
                  </template>
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <Empty v-if="!process.load && pagination_attachment.total_page < 1"
              class="py-6"
              margin="my-16"
              size="125"
              message="Lampiran" />
          </v-card-text>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.form_attachment"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.form_attachment = false; reset_attachment();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.form_attachment = false; reset_attachment();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Tambah
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 205px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="d-flex align-center pt-16 mt-5">
              <upload-folder
                v-model="upload.folder_attachment"
                style="width: 100%;">
                <div
                  slot="activator"
                  class="cursor-pointer d-flex align-center justify-center"
                  style="border: 5px solid #0000000d;
                        border-radius: 10px;
                        min-width: 100%;
                        width: 100%;
                        height: 400px;">
                  <v-img v-if="!process.file && form_attachment.image_url !== ''"
                    contain
                    width="100%"
                    height="100%"
                    style="border-radius: 6px;"
                    alt="bidanvitacare"
                    :src="form_attachment.image_url !== '' ? form_attachment.image_url : require('@/assets/image/image_default.png')"
                    class="ma-auto d-flex align-center text-center">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>

                    <v-icon
                      size="50"
                      color="white">
                      mdi-cloud-upload-outline
                    </v-icon>
                  </v-img>

                  <v-icon v-if="!process.file && form_attachment.image_url === ''"
                    size="50"
                    color="grey">
                    mdi-cloud-upload-outline
                  </v-icon>

                  <v-progress-circular v-if="process.file"
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </div>
              </upload-folder>
            </v-card-text>

            <v-card-text>
              <div v-if="message.error.length > 0"
                class="text-center mt-3 mb-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="150"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row
                align="center">
                <v-col
                  cols="3"
                  class="pb-0">
                  <v-img
                    contain
                    alt="bidanvitacare"
                    :src="require('@/assets/icon/attachment.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col
                  cols="9"
                  class="black--text pb-0">
                  <div>
                    <div
                      class="body-2 line-text-second black--text"
                      style="opacity: 60%;">
                      Masukkan data Lampiran untuk rekam kunjungan di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row
                class="mt-4">
                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save_attachment"
                    @click="save_attachment"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-check-bold
                      </v-icon>

                      Simpan
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    @click="sheet.form_attachment = false; reset_attachment();"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-close-thick
                      </v-icon>

                      Batal
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.form_grafik"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.form_grafik = false; reset_grafik();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.form_grafik = false; reset_grafik();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Ubah
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 205px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="pt-16 mt-5">
              <div v-if="detail.graph === undefined">
                <Empty
                  class="py-6"
                  margin="my-16"
                  size="125"
                  message="Grafik" />
              </div>

              <div v-else>
                <div>
                  <div class="body-2 line-text-second">
                    {{ detail.graph.bb.title }}
                  </div>

                  <div
                    id="bb"
                    class="my-4">
                    <apexchart
                      height="300"
                      :options="bar.bb.chartOptions"
                      :series="bar.bb.series">
                    </apexchart>
                  </div>

                  <div class="body-2 line-text-second mb-1">
                    <span class="font-weight-bold black--text">
                      Kesimpulan:
                    </span>

                    {{ detail.graph.bb.kesimpulan !== '' ? detail.graph.bb.kesimpulan : '-' }}
                  </div>

                  <div
                    class="d-flex align-center">
                    <div>
                      Upload file grafik berat badan
                    </div>

                    <v-spacer />

                    <!-- <div>
                      <v-btn
                        @click="to_upload('bb', detail.graph)"
                        :loading="process.file_bb"
                        :disabled="process.file_bb"
                        x-small
                        rounded
                        elevation="0"
                        :color="form_grafik.graph_bb_image_url === '' ? set_color : 'green'"
                        class="text-capitalize white--text d-flex align-center">
                        <v-icon small color="white">mdi-cloud-upload-outline</v-icon>

                        <span
                          class="ml-1">
                          Unggah
                        </span>
                      </v-btn>
                    </div> -->
                  </div>
                </div>

                <v-divider
                  class="my-4"
                  style="border-top: 1px dashed #bbb;" />

                <div>
                  <div class="body-2 line-text-second">
                    {{ detail.graph.tb.title }}
                  </div>

                  <div
                    id="tb"
                    class="my-4">
                    <apexchart
                      height="300"
                      :options="bar.tb.chartOptions"
                      :series="bar.tb.series">
                    </apexchart>
                  </div>

                  <div class="body-2 line-text-second mb-1">
                    <span class="font-weight-bold black--text">
                      Kesimpulan:
                    </span>

                    {{ detail.graph.tb.kesimpulan !== '' ? detail.graph.tb.kesimpulan : '-' }}
                  </div>

                  <div
                    class="d-flex align-center">
                    <div>
                      Upload file grafik tinggi badan
                    </div>

                    <v-spacer />

                    <!-- <div>
                      <v-btn
                        @click="to_upload('tb', detail.graph)"
                        :loading="process.file_tb"
                        :disabled="process.file_tb"
                        x-small
                        rounded
                        elevation="0"
                        :color="form_grafik.graph_tb_image_url === '' ? set_color : 'green'"
                        class="text-capitalize white--text d-flex align-center">
                        <v-icon small color="white">mdi-cloud-upload-outline</v-icon>

                        <span
                          class="ml-1">
                          Unggah
                        </span>
                      </v-btn>
                    </div> -->
                  </div>
                </div>

                <v-divider
                  class="my-4"
                  style="border-top: 1px dashed #bbb;" />

                <div>
                  <div class="body-2 line-text-second">
                    {{ detail.graph.lila.title }}
                  </div>

                  <div
                    id="lila"
                    class="my-4">
                    <apexchart
                      height="300"
                      :options="bar.lila.chartOptions"
                      :series="bar.lila.series">
                    </apexchart>
                  </div>

                  <div class="body-2 line-text-second mb-1">
                    <span class="font-weight-bold black--text">
                      Kesimpulan:
                    </span>

                    {{ detail.graph.lila.kesimpulan !== '' ? detail.graph.lila.kesimpulan : '-' }}
                  </div>

                  <div
                    class="d-flex align-center">
                    <div>
                      Upload file grafik lingkar lengan atas
                    </div>

                    <v-spacer />

                    <!-- <div>
                      <v-btn
                        @click="to_upload('lila', detail.graph)"
                        :loading="process.file_lila"
                        :disabled="process.file_lila"
                        x-small
                        rounded
                        elevation="0"
                        :color="form_grafik.graph_lila_image_url === '' ? set_color : 'green'"
                        class="text-capitalize white--text d-flex align-center">
                        <v-icon small color="white">mdi-cloud-upload-outline</v-icon>

                        <span
                          class="ml-1">
                          Unggah
                        </span>
                      </v-btn>
                    </div> -->
                  </div>
                </div>

                <v-divider
                  class="my-4"
                  style="border-top: 1px dashed #bbb;" />

                <div>
                  <div class="body-2 line-text-second">
                    {{ detail.graph.lk.title }}
                  </div>

                  <div
                    id="lk"
                    class="my-4">
                    <apexchart
                      height="300"
                      :options="bar.lk.chartOptions"
                      :series="bar.lk.series">
                    </apexchart>
                  </div>

                  <div class="body-2 line-text-second mb-1">
                    <span class="font-weight-bold black--text">
                      Kesimpulan:
                    </span>

                    {{ detail.graph.lk.kesimpulan !== '' ? detail.graph.lk.kesimpulan : '-' }}
                  </div>

                  <div
                    class="d-flex align-center">
                    <div>
                      Upload file grafik lingkar kepala
                    </div>

                    <v-spacer />

                    <!-- <div>
                      <v-btn
                        @click="to_upload('lk', detail.graph)"
                        :loading="process.file_lk"
                        :disabled="process.file_lk"
                        x-small
                        rounded
                        elevation="0"
                        :color="form_grafik.graph_lk_image_url === '' ? set_color : 'green'"
                        class="text-capitalize white--text d-flex align-center">
                        <v-icon small color="white">mdi-cloud-upload-outline</v-icon>

                        <span
                          class="ml-1">
                          Unggah
                        </span>
                      </v-btn>
                    </div> -->
                  </div>
                </div>
              </div>

              <div v-if="message.error.length > 0"
                class="text-center mt-3 mb-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="150"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row
                align="center">
                <v-col
                  cols="3"
                  class="pb-0">
                  <v-img
                    contain
                    alt="bidanvitacare"
                    :src="require('@/assets/icon/grafik.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col
                  cols="9"
                  class="black--text pb-0">
                  <div>
                    <div
                      class="body-2 line-text-second black--text"
                      style="opacity: 60%;">
                      Masukkan data Grafik untuk rekam kunjungan di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row
                class="mt-4">
                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    :disabled="process.form || detail.graph === undefined"
                    :loading="process.form"
                    v-on:keyup.enter="to_save_all"
                    @click="to_save_all"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-check-bold
                      </v-icon>

                      Simpan
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    @click="sheet.form_grafik = false; reset_grafik();"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-close-thick
                      </v-icon>

                      Batal
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        dialog: {
          partner_or_terapis: false,
          filter: false
        },

        filter: {
          dates: []
        },

        sheet: {
          form: false,
          detail: false,

          attachment: false,
          form_attachment: false,

          form_grafik: false
        },
        selected: {
          type_user: 'Terapis',
          partner_or_terapis: []
        },
        search: '',
        search_attachment: '',
        detail: {},
        list: [],
        list_attachment: [],
        pagination: {},
        pagination_attachment: {},

        limit: 10,

        upload: {
          folder_attachment: null
        },

        picker: {
          date: false,
          time: false
        },

        form: {
          id: '',
          age: '',
          bb: '',
          tb: '',
          lila: '',
          lk: '',
          complaint: '',
          note: '',

          date: '',
          time: '',
          datetime: '',

          admin_complaint: '',
          admin_suggest: '',

          tensi: ''
        },
        form_attachment: {
          visit_id: '',
          image_url: ''
        },
        form_grafik: {
          id: '',

          graph_bb_title: '',
          graph_bb_image_url: '',
          graph_bb_kesimpulan: '',

          graph_tb_title: '',
          graph_tb_image_url: '',
          graph_tb_kesimpulan: '',

          graph_lila_title: '',
          graph_lila_image_url: '',
          graph_lila_kesimpulan: '',

          graph_lk_title: '',
          graph_lk_image_url: '',
          graph_lk_kesimpulan: ''
        },

        bar: {
          bb: {
            series: [],
            chartOptions: {
              chart: {
                animations: {
                  enabled: true,
                  easing: 'line',
                  dynamicAnimation: {
                    speed: 1000
                  }
                },
                toolbar: {
                  show: true
                },
                zoom: {
                  enabled: true
                },
                type: 'area'
              },
              stroke: {
                width: 3,
                curve: 'smooth'
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                show: false
              },
              colors: ['#000000'],
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return Math.round(value)
                  }
                }
              },
              xaxis: {
                categories: []
              }
            }
          },
          tb: {
            series: [],
            chartOptions: {
              chart: {
                animations: {
                  enabled: true,
                  easing: 'line',
                  dynamicAnimation: {
                    speed: 1000
                  }
                },
                toolbar: {
                  show: true
                },
                zoom: {
                  enabled: true
                },
                type: 'area'
              },
              stroke: {
                width: 3,
                curve: 'smooth'
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                show: false
              },
              colors: ['#000000'],
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return Math.round(value)
                  }
                }
              },
              xaxis: {
                categories: []
              }
            }
          },
          lila: {
            series: [],
            chartOptions: {
              chart: {
                animations: {
                  enabled: true,
                  easing: 'line',
                  dynamicAnimation: {
                    speed: 1000
                  }
                },
                toolbar: {
                  show: true
                },
                zoom: {
                  enabled: true
                },
                type: 'area'
              },
              stroke: {
                width: 3,
                curve: 'smooth'
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                show: false
              },
              colors: ['#000000'],
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return Math.round(value)
                  }
                }
              },
              xaxis: {
                categories: []
              }
            }
          },
          lk: {
            series: [],
            chartOptions: {
              chart: {
                animations: {
                  enabled: true,
                  easing: 'line',
                  dynamicAnimation: {
                    speed: 1000
                  }
                },
                toolbar: {
                  show: true
                },
                zoom: {
                  enabled: true
                },
                type: 'area'
              },
              stroke: {
                width: 3,
                curve: 'smooth'
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                show: false
              },
              colors: ['#000000'],
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return Math.round(value)
                  }
                }
              },
              xaxis: {
                categories: []
              }
            }
          }
        },

        process: {
          load: false,
          form: false,
          file: false,

          file_bb: false,
          file_tb: false,
          file_lila: false,
          file_lk: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'upload.folder_attachment': {
        handler: function () {
          if (this.upload.folder_attachment) {
            this.process.file = this.upload.folder_attachment.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form_attachment.image_url = this.upload.folder_attachment.fileuri
            }
          }
        },
        deep: true
      },

      'limit': function() {
        this.process.limit = true

        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },

      date_range () {
        return this.filter.dates.join(' s/d ')
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit,

          filter: [{
            'type': 'date',
            'field': 'datetime',
            'value': this.filter.dates.length > 1 ? `${this.filter.dates[0]}::${this.filter.dates[1]}` : '',
            'comparison': 'bet'
          }]
        }

        await this.$axios.$get(`${process.env.API}admin/customer-visit`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchDetail (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/customer-visit/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail = response.results.data

            if (this.detail.graph !== undefined) {
              let grafik_bb = {
                value: [],
                sd0: [],
                month: []
              }

              this.detail.graph.bb.data.map(obj => {
                grafik_bb.value.push(obj.value !== null ? obj.value : 0)
                grafik_bb.sd0.push(obj.sd0)

                grafik_bb.month.push(obj.month)
              })

              let grafik_tb = {
                value: [],
                sd0: [],
                month: []
              }

              this.detail.graph.tb.data.map(obj => {
                grafik_tb.value.push(obj.value !== null ? obj.value : 0)
                grafik_tb.sd0.push(obj.sd0)

                grafik_tb.month.push(obj.month)
              })

              let grafik_lila = {
                value: [],
                sd0: [],
                month: []
              }

              this.detail.graph.lila.data.map(obj => {
                grafik_lila.value.push(obj.value !== null ? obj.value : 0)
                grafik_lila.sd0.push(obj.sd0)

                grafik_lila.month.push(obj.month)
              })

              let grafik_lk = {
                value: [],
                sd0: [],
                month: []
              }

              this.detail.graph.lk.data.map(obj => {
                grafik_lk.value.push(obj.value !== null ? obj.value : 0)
                grafik_lk.sd0.push(obj.sd0)

                grafik_lk.month.push(obj.month)
              })

              this.bar = {
                bb: {
                  series:[
                    {
                      name: 'Berat Badan',
                      data: grafik_bb.value
                    },
                    {
                      name: 'Batas Normal',
                      data: grafik_bb.sd0
                    }
                  ],
                  chartOptions: {
                    chart: {
                      animations: {
                        enabled: true,
                        easing: 'line',
                        dynamicAnimation: {
                          speed: 1000
                        }
                      },
                      toolbar: {
                        show: true
                      },
                      zoom: {
                        enabled: true
                      }
                    },
                    stroke: {
                      width: 1,
                      curve: 'smooth'
                    },
                    dataLabels: {
                      enabled: false
                    },
                    legend: {
                      show: true
                    },
                    colors: ['#00BCD4', '#F44336'],
                    yaxis: {
                      labels: {
                        formatter: function (value) {
                          return Math.round(value)
                        }
                      }
                    },
                    xaxis: {
                      categories: grafik_bb.month,
                      axisTicks: {
                        autoskip: true,
                        maxTicksLimit: 4,
                        interval: 3
                      }
                    }
                  }
                },
                tb: {
                  series:[
                    {
                      name: 'Tinggi Badan',
                      data: grafik_tb.value
                    },
                    {
                      name: 'Batas Normal',
                      data: grafik_tb.sd0
                    }
                  ],
                  chartOptions: {
                    chart: {
                      animations: {
                        enabled: true,
                        easing: 'line',
                        dynamicAnimation: {
                          speed: 1000
                        }
                      },
                      toolbar: {
                        show: true
                      },
                      zoom: {
                        enabled: true
                      }
                    },
                    stroke: {
                      width: 1,
                      curve: 'smooth'
                    },
                    dataLabels: {
                      enabled: false
                    },
                    legend: {
                      show: true
                    },
                    colors: ['#00BCD4', '#F44336'],
                    yaxis: {
                      labels: {
                        formatter: function (value) {
                          return Math.round(value)
                        }
                      }
                    },
                    xaxis: {
                      categories: grafik_tb.month,
                      axisTicks: {
                        autoskip: true,
                        maxTicksLimit: 4,
                        interval: 3
                      }
                    }
                  }
                },
                lila: {
                  series:[
                    {
                      name: 'Lingkar Lengan Atas',
                      data: grafik_lila.value
                    },
                    {
                      name: 'Batas Normal',
                      data: grafik_lila.sd0
                    }
                  ],
                  chartOptions: {
                    chart: {
                      animations: {
                        enabled: true,
                        easing: 'line',
                        dynamicAnimation: {
                          speed: 1000
                        }
                      },
                      toolbar: {
                        show: true
                      },
                      zoom: {
                        enabled: true
                      }
                    },
                    stroke: {
                      width: 1,
                      curve: 'smooth'
                    },
                    dataLabels: {
                      enabled: false
                    },
                    legend: {
                      show: true
                    },
                    colors: ['#00BCD4', '#F44336'],
                    yaxis: {
                      labels: {
                        formatter: function (value) {
                          return Math.round(value)
                        }
                      }
                    },
                    xaxis: {
                      categories: grafik_lila.month,
                      axisTicks: {
                        autoskip: true,
                        maxTicksLimit: 4,
                        interval: 3
                      }
                    }
                  }
                },
                lk: {
                  series:[
                    {
                      name: 'Lingkar Kepala',
                      data: grafik_lk.value
                    },
                    {
                      name: 'Batas Normal',
                      data: grafik_lk.sd0
                    }
                  ],
                  chartOptions: {
                    chart: {
                      animations: {
                        enabled: true,
                        easing: 'line',
                        dynamicAnimation: {
                          speed: 1000
                        }
                      },
                      toolbar: {
                        show: true
                      },
                      zoom: {
                        enabled: true
                      }
                    },
                    stroke: {
                      width: 1,
                      curve: 'smooth'
                    },
                    dataLabels: {
                      enabled: false
                    },
                    legend: {
                      show: true
                    },
                    colors: ['#00BCD4', '#F44336'],
                    yaxis: {
                      labels: {
                        formatter: function (value) {
                          return Math.round(value)
                        }
                      }
                    },
                    xaxis: {
                      categories: grafik_lk.month,
                      axisTicks: {
                        autoskip: true,
                        maxTicksLimit: 4,
                        interval: 3
                      }
                    }
                  }
                }
              }
            }
          }
        })
      },
      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          id: item !== undefined ? item.id : '',
          age: item !== undefined ? item.age : '',
          bb: item !== undefined ? item.bb : '',
          tb: item !== undefined ? item.tb : '',
          lila: item !== undefined ? item.lila : '',
          lk: item !== undefined ? item.lk : '',
          complaint: item !== undefined ? item.complaint : '',
          note: item !== undefined ? item.note : '',

          date: item !== undefined ? item.datetime.split(' ')[0] : '',
          time: item !== undefined ? item.datetime.split(' ')[1] : '',

          datetime: item !== undefined ? item.datetime : '',

          admin_complaint: item !== undefined ? item.admin_complaint : '',
          admin_suggest: item !== undefined ? item.admin_suggest : '',

          tensi: item !== undefined ? item.tensi : ''
        }

        this.process.form = false

        this.message.error = ''
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          this.form.datetime = `${this.form.date} ${this.form.time}`

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}admin/customer-visit/update`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form = false

              this.fetchDetail(this.detail.id)
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      async fetchAttachment () {
        this.process.load = true

        let params = {
          visit_id: this.detail.id,
          search: this.search_attachment,
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/customer-visit-attachment`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list_attachment = response.results.data
            this.pagination_attachment = response.results.pagination
          }
        })
      },
      reset_attachment (item) {
        if (this.$refs.form_attachment) this.$refs.form_attachment.reset()

        this.form_attachment = {
          visit_id: this.detail.id,
          image_url: item !== undefined ? item.image_url : ''
        }

        this.process.form = false

        this.message.error = ''
      },
      async save_attachment () {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/customer-visit-attachment/add`, this.form_attachment)
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.sheet.form_attachment = false

            this.fetchAttachment()
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      },

      reset_grafik () {
        this.form_grafik = {
          id: this.detail.id,

          graph_bb_title: '',
          graph_bb_image_url: '',
          graph_bb_kesimpulan: '',

          graph_tb_title: '',
          graph_tb_image_url: '',
          graph_tb_kesimpulan: '',

          graph_lila_title: '',
          graph_lila_image_url: '',
          graph_lila_kesimpulan: '',

          graph_lk_title: '',
          graph_lk_image_url: '',
          graph_lk_kesimpulan: ''
        }

        this.process.form = false

        this.message.error = ''
      },
      to_upload (type, item) {
        if (type === 'bb') {
          this.uplaod_bb(item)
        } else if (type === 'tb') {
          this.uplaod_tb(item)
        } else if ( type === 'lila') {
          this.uplaod_lila(item)
        } else {
          this.uplaod_lk(item)
        }
      },
      to_save_all () {
        this.message.error = ''

        this.process.form = true

        this.uplaod_bb(this.detail.graph)
      },
      async uplaod_bb (item) {
        let self = this,
            data = document.getElementById('bb')

        self.process.file_bb = true

        await this.$html2canvas(data, {
          logging: true,
          letterRendering: true,
          allowTaint: false,
          useCORS: true,

          scale: 1920*2/window.innerWidth,

          scrollX: 0,
          scrollY: 0,
        })
        .then(function(canvas) {
          document.body.appendChild(canvas)

          let img = canvas.toDataURL('image/png', 1.0)

          fetch(img)
          .then(res => res.blob())
          .then(blob => {
            let formData = new FormData(),
                file = new File([blob], 'bidanvitacare.jpeg')
                formData.append('key', 'image')
                formData.append('image', file)


            fetch(`${process.env.API_CDN}image.php`, {
              method: 'POST',
              body: formData
            })
            .then(res => res.json())
            .then(response => {
              self.form_grafik.graph_bb_title = item.bb.title
              self.form_grafik.graph_bb_image_url = response.data.fileuri
              self.form_grafik.graph_bb_kesimpulan = item.bb.kesimpulan

              self.process.file_bb = false

              self.uplaod_tb(self.detail.graph)
            })
          })
        })
      },
      async uplaod_tb (item) {
        let self = this,
            data = document.getElementById('tb')

        self.process.file_tb = true

        await this.$html2canvas(data, {
          logging: true,
          letterRendering: true,
          allowTaint: false,
          useCORS: true,

          scale: 1920*2/window.innerWidth,

          scrollX: 0,
          scrollY: 0,
        })
        .then(function(canvas) {
          document.body.appendChild(canvas)

          let img = canvas.toDataURL('image/png', 1.0)

          fetch(img)
          .then(res => res.blob())
          .then(blob => {
            let formData = new FormData(),
                file = new File([blob], 'bidanvitacare.jpeg')
                formData.append('key', 'image')
                formData.append('image', file)


            fetch(`${process.env.API_CDN}image.php`, {
              method: 'POST',
              body: formData
            })
            .then(res => res.json())
            .then(response => {
              self.form_grafik.graph_tb_title = item.tb.title
              self.form_grafik.graph_tb_image_url = response.data.fileuri
              self.form_grafik.graph_tb_kesimpulan = item.tb.kesimpulan

              self.process.file_tb = false

              self.uplaod_lila(self.detail.graph)
            })
          })
        })
      },
      async uplaod_lila (item) {
        let self = this,
            data = document.getElementById('lila')

        self.process.file_lila = true

        await this.$html2canvas(data, {
          logging: true,
          letterRendering: true,
          allowTaint: false,
          useCORS: true,

          scale: 1920*2/window.innerWidth,

          scrollX: 0,
          scrollY: 0,
        })
        .then(function(canvas) {
          document.body.appendChild(canvas)

          let img = canvas.toDataURL('image/png', 1.0)

          fetch(img)
          .then(res => res.blob())
          .then(blob => {
            let formData = new FormData(),
                file = new File([blob], 'bidanvitacare.jpeg')
                formData.append('key', 'image')
                formData.append('image', file)


            fetch(`${process.env.API_CDN}image.php`, {
              method: 'POST',
              body: formData
            })
            .then(res => res.json())
            .then(response => {
              self.form_grafik.graph_lila_title = item.lila.title
              self.form_grafik.graph_lila_image_url = response.data.fileuri
              self.form_grafik.graph_lila_kesimpulan = item.lila.kesimpulan

              self.process.file_lila = false

              self.uplaod_lk(self.detail.graph)
            })
          })
        })
      },
      async uplaod_lk (item) {
        let self = this,
            data = document.getElementById('lk')

        self.process.file_lk = true

        await this.$html2canvas(data, {
          logging: true,
          letterRendering: true,
          allowTaint: false,
          useCORS: true,

          scale: 1920*2/window.innerWidth,

          scrollX: 0,
          scrollY: 0,
        })
        .then(function(canvas) {
          document.body.appendChild(canvas)

          let img = canvas.toDataURL('image/png', 1.0)

          fetch(img)
          .then(res => res.blob())
          .then(blob => {
            let formData = new FormData(),
                file = new File([blob], 'bidanvitacare.jpeg')
                formData.append('key', 'image')
                formData.append('image', file)


            fetch(`${process.env.API_CDN}image.php`, {
              method: 'POST',
              body: formData
            })
            .then(res => res.json())
            .then(response => {
              self.form_grafik.graph_lk_title = item.lk.title
              self.form_grafik.graph_lk_image_url = response.data.fileuri
              self.form_grafik.graph_lk_kesimpulan = item.lk.kesimpulan

              self.process.file_lk = false

              self.save_grafik()
            })
          })
        })
      },
      async save_grafik () {
        // this.message.error = ''

        // this.process.form = true

        if (this.form_grafik.graph_bb_image_url !== '' && this.form_grafik.graph_tb_image_url !== '' && this.form_grafik.graph_lila_image_url !== '' && this.form_grafik.graph_lk_image_url !== '') {
          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}admin/customer-visit/update-graph-image`, this.form_grafik)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form_grafik = false

              this.fetchDetail(this.detail.id)

              // clearInterval(refresh)
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
        }
      }
    }
  }
</script>
