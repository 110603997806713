<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.filter"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Filter
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3">
              <div>
                <div class="body-2 mb-2">
                  Tanggal Transaksi
                </div>

                <v-menu
                  ref="range"
                  min-width="290px"
                  max-width="290px"
                  transition="scale-transition"
                  offset-y
                  :nudge-right="40"
                  :close-on-content-click="false"
                  v-model="picker.range">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      readonly
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 2023-01-01 s/d 2023-01-10"

                      hide-details

                      clearable
                      @click:clear="filter.dates = []"

                      v-on="on"
                      v-bind="attrs"

                      v-model="date_range">
                    </v-text-field>
                  </template>

                  <v-date-picker
                    range
                    :color="set_color"
                    v-model="filter.dates"
                    @input="picker.range = filter.dates.length > 1 ? false : true">
                  </v-date-picker>
                </v-menu>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.filter = false; fetch();"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.filter = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text
              class="py-0 mt-1">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                filled
                rounded
                dense
                single-line

                hide-details

                class="mb-5"
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-select
                v-on:change="fetch()"
                filled
                rounded
                dense
                :color="set_color"
                placeholder="Pilih Cabang"

                hide-details

                :items="store"
                item-value="id"
                item-text="name"
                :item-color="set_color"

                :no-filter="false"

                v-model="form.store_id">
              </v-select>
            </v-card-text>

            <v-card-text
              class="pb-0"
              style="height: calc(100vh - 180px); overflow: auto;">
              <div
                class="d-flex align-center">
                <div
                  class="body-1 font-weight-bold black--text">
                  Metode Bayar
                </div>

                <v-spacer />

                <div>
                  <v-btn
                    @click="prints"
                    :loading="process.print"
                    :disabled="process.print"
                    small
                    rounded
                    :color="set_color"
                    elevation="0"
                    class="text-capitalize white--text font-weight-bold">
                    Print
                  </v-btn>
                </div>

                <div
                  class="ml-3">
                  <v-icon
                    @click="dialog.filter = true"
                    class="cursor-pointer">
                    mdi-filter-variant
                  </v-icon>
                </div>
              </div>

              <v-card v-if="!process.load"
                flat
                class="mt-6 mb-4"
                style="border: 2px dashed #bbb; border-radius: 10px;">
                <v-card-text>
                  <div
                    class="line-text-second">
                    <div
                      class="font-weight-bold black--text">
                      Total Transaksi
                    </div>

                    <div>
                      Dari tanggal <span>{{ summary.date_start | date }}</span> s/d <span>{{ summary.date_end | date }}</span>
                    </div>
                  </div>

                  <v-divider
                    class="my-3"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="d-flex align-center mb-1">
                    <div
                      class="body-2 black--text"
                      style="opacity: 60%;">
                      Cash
                    </div>

                    <v-spacer />

                    <div
                      class="body-2 black--text">
                      {{ summary.total_payment_cash | price }}
                    </div>
                  </div>

                  <div
                    class="d-flex align-center mb-1">
                    <div
                      class="body-2 black--text"
                      style="opacity: 60%;">
                      Transfer
                    </div>

                    <v-spacer />

                    <div
                      class="body-2 black--text">
                      {{ summary.total_payment_transfer | price }}
                    </div>
                  </div>

                  <div
                    class="d-flex align-center mb-1">
                    <div
                      class="body-2 black--text"
                      style="opacity: 60%;">
                      Edc
                    </div>

                    <v-spacer />

                    <div
                      class="body-2 black--text">
                      {{ summary.total_payment_edc | price }}
                    </div>
                  </div>

                  <div
                    class="d-flex align-center mb-1">
                    <div
                      class="body-2 black--text"
                      style="opacity: 60%;">
                      Qris
                    </div>

                    <v-spacer />

                    <div
                      class="body-2 black--text">
                      {{ summary.total_payment_qris | price }}
                    </div>
                  </div>

                  <div
                    class="d-flex align-center mb-1">
                    <div
                      class="body-2 black--text"
                      style="opacity: 60%;">
                      Shopeepay
                    </div>

                    <v-spacer />

                    <div
                      class="body-2 black--text">
                      {{ summary.total_payment_shopeepay | price }}
                    </div>
                  </div>

                  <div
                    class="d-flex align-center mb-1">
                    <div
                      class="body-2 black--text"
                      style="opacity: 60%;">
                      Down Payment
                    </div>

                    <v-spacer />

                    <div
                      class="body-2 black--text">
                      {{ summary.total_payment_dp | price }}
                    </div>
                  </div>

                  <v-divider
                    class="my-3"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="d-flex align-center">
                    <div
                      class="body-2 font-weight-bold black--text">
                      Total
                    </div>

                    <v-spacer />

                    <div
                      class="body-2 font-weight-bold black--text">
                      {{ summary.total_payment_cash + summary.total_payment_transfer + summary.total_payment_edc + summary.total_payment_qris + summary.total_payment_shopeepay + summary.total_payment_dp | price }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 4 : list"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.length - 1 ? 'mb-4' : ''"
                    type="article">
                    <template>
                      <div>
                        <v-card v-if="!process.load && list.length > 0"
                          flat
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-card-text>
                            <div
                              class="caption mb-1">
                              Jumlah. {{ item.count }}
                            </div>

                            <div
                              class="body-2 font-weight-bold black--text mb-2">
                              {{ item.date | date }}
                            </div>

                            <v-divider
                              class="my-3"
                              style="border-top: 1px dashed #bbb;" />

                            <div
                              class="d-flex align-center mb-1">
                              <div
                                class="body-2 black--text"
                                style="opacity: 60%;">
                                Cash
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 black--text">
                                {{ item.total_payment_cash | price }}
                              </div>
                            </div>

                            <div
                              class="d-flex align-center mb-1">
                              <div
                                class="body-2 black--text"
                                style="opacity: 60%;">
                                Transfer
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 black--text">
                                {{ item.total_payment_transfer | price }}
                              </div>
                            </div>

                            <div
                              class="d-flex align-center mb-1">
                              <div
                                class="body-2 black--text"
                                style="opacity: 60%;">
                                Edc
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 black--text">
                                {{ item.total_payment_edc | price }}
                              </div>
                            </div>

                            <div
                              class="d-flex align-center mb-1">
                              <div
                                class="body-2 black--text"
                                style="opacity: 60%;">
                                Qris
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 black--text">
                                {{ item.total_payment_qris | price }}
                              </div>
                            </div>

                            <div
                              class="d-flex align-center mb-1">
                              <div
                                class="body-2 black--text"
                                style="opacity: 60%;">
                                Shopeepay
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 black--text">
                                {{ item.total_payment_shopeepay | price }}
                              </div>
                            </div>

                            <div
                              class="d-flex align-center">
                              <div
                                class="body-2 black--text"
                                style="opacity: 60%;">
                                Down Payment
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 black--text">
                                {{ item.total_payment_dp | price }}
                              </div>
                            </div>

                            <v-divider
                              class="my-3"
                              style="border-top: 1px dashed #bbb;" />

                            <div
                              class="d-flex align-center">
                              <div
                                class="body-2 font-weight-bold black--text">
                                Total
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 font-weight-bold black--text">
                                {{ item.grand_total_so | price }}
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-12"
                margin="my-16"
                size="125"
                message="Metode Bayar" />
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import axios from "axios";
  import moment from "moment"
  import "moment/locale/id"
  export default {
    data () {
      return {
        dialog: {
          filter: false
        },

        filter: {
          dates: []
        },

        search: '',

        summary: {},
        store: [],
        list: [],
        pagination: {},

        limit: 10,

        picker: {
          range: false
        },

        form: {
          store_id: 0
        },
        process: {
          load: false,
          print: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.process.limit = true

        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },

      date_range () {
        return this.filter.dates.join(' s/d ')
      }
    },
    mounted () {
      this.fetchStore()
    },
    methods: {
      async fetchStore () {
        let params = {
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/store`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.store = response.results.data

            this.fetch()
          }
        })
      },
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          store_id: this.form.store_id,
          limit: this.limit,

          filter: [{
            'type': 'date',
            'field': 'date',
            'value': this.filter.dates.length > 1 ? `${this.filter.dates[0]}::${this.filter.dates[1]}` : '',
            'comparison': 'bet'
          }]
        }

        await this.$axios.$get(`${process.env.API}admin/report/so-payment-method`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination

            this.summary = response.results.summary
          }
        })
      },
      async prints () {
        this.process.print = true

        await axios({
          url: `${process.env.API}admin/report/so-payment-method/export`,
          method: 'GET',
          responseType: 'blob', // important,
          headers: {
            Authorization: process.env.API_AUTH,
            token: this.$store.state.member.auth.token
          }
        }).then((response) => {
          let res = response

          if (res.status === 200) {
            this.process.print = false

            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')

            link.href = url
            link.setAttribute('download', `BVC-Metode-Bayar-${moment().format('DD-MMMM-YYYY', 'id')}-${moment().format('HH:mm:ss', 'id')}.xls`)
            document.body.appendChild(link)
            link.click()
          } else {
            this.process.print = false
            this.message.error = res.message
          }
        })
      }
    }
  }
</script>
