<template>
  <div>
    <v-dialog v-model="dialog.deletes"
      persistent>
      <v-card 
        class="border-radius pa-2">
        <v-card-text>
          <v-img 
            width="150"
            height="150"
            alt="bidanvitacare" 
            :src="require('@/assets/icon/hapus_info.png')"
            class="ma-auto mt-4 mb-3">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  size="20"
                  indeterminate 
                  :color="set_color">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <div
            class="body-2 text-center line-text-second">
            <div>
              Apakah Anda yakin menghapus data
            </div>
            
            <div>
              <strong>{{ detail.name }}</strong>?
            </div>
          </div>
        </v-card-text>

        <v-divider 
          class="mx-4 mb-3 mt-0"/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            @click="deletes()"
            :loading="process.form"
            :disabled="process.form"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_save">
            <div
              class="btn_save_in d-flex align-center justify-center">
              <v-icon 
                size="20">
                mdi-check-bold
              </v-icon>

              <span
                class="ml-1">
                Ya, Saya yakin
              </span>
            </div>
          </v-btn>
          
          <v-btn
            @click="dialog.deletes = false"
            :disabled="process.form"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
            <div
              class="btn_cancel_in">
              <v-icon 
                size="20">
                mdi-close-thick
              </v-icon>

              <span
                class="ml-1">
                Batal
              </span>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.deletes_address"
      persistent>
      <v-card 
        class="border-radius pa-2">
        <v-card-text>
          <v-img 
            width="150"
            height="150"
            alt="bidanvitacare" 
            :src="require('@/assets/icon/hapus_info.png')"
            class="ma-auto mt-4 mb-3">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  size="20"
                  indeterminate 
                  :color="set_color">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <div
            class="body-2 text-center line-text-second">
            <div>
              Apakah Anda yakin menghapus data
            </div>
            
            <div>
              <strong>{{ detail_address.address_full }}</strong>?
            </div>
          </div>
        </v-card-text>

        <v-divider 
          class="mx-4 mb-3 mt-0"/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            @click="deletes_address()"
            :loading="process.form"
            :disabled="process.form"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_save">
            <div
              class="btn_save_in d-flex align-center justify-center">
              <v-icon 
                size="20">
                mdi-check-bold
              </v-icon>

              <span
                class="ml-1">
                Ya, Saya yakin
              </span>
            </div>
          </v-btn>
          
          <v-btn
            @click="dialog.deletes_address = false"
            :disabled="process.form"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
            <div
              class="btn_cancel_in">
              <v-icon 
                size="20">
                mdi-close-thick
              </v-icon>

              <span
                class="ml-1">
                Batal
              </span>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <section>
      <v-card 
        flat
        color="transparent">
        <v-card-text 
          class="pb-0 mt-1">
          <v-text-field
            v-model="search"
            @click:clear="search = ''; fetch();"
            v-on:keyup.enter="fetch()"
            filled
            rounded
            dense 
            single-line
            hide-details
            :color="set_color"
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari data yang Anda butuhkan ?"
            clearable>
          </v-text-field>

          <v-btn
            @click="sheet.form = true; reset();"
            block
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
            :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
            <div
              class="btn_auth_in d-flex align-center justify-center"
              :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
              <div>
                <v-img 
                  contain
                  width="20"
                  height="20"
                  alt="bidanvitacare" 
                  :src="require('@/assets/icon/add_user.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>

              <div
                class="ml-2">
                Tambah Pelanggan
              </div>
            </div>
          </v-btn>
        </v-card-text>

        <v-card-text
          class="pb-0"
          style="height: calc(100vh - 185px); overflow: auto;">
          <v-row>
            <v-col
              cols="12">
              <v-skeleton-loader
                v-for="(item, index) in process.load ? 5 : list"
                :key="index"
                :loading="process.load"
                :class="index < list.length - 1 ? 'mb-4' : ''"
                type="list-item-avatar-three-line">
                <template>
                  <div>
                    <v-list
                      v-if="!process.load && list.length > 0"
                      flat
                      dense
                      two-line
                      color="#FFFFFF40"
                      class="border-radius">
                      <v-list-item
                        @click="sheet.detail = true; fetchDetail(item.id);">
                        <v-list-item-avatar
                          size="40"
                          class="mt-0"
                          style="border: 2px solid #ffffff;">
                          <v-img 
                            width="100%"
                            height="100%"
                            alt="bidanvitacare" 
                            :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  size="20"
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="body-2 font-weight-bold text-capitalize">
                            {{ item.name }}
                          </v-list-item-title>

                          <v-list-item-subtitle
                            class="body-2 font-weight-light line-text-second black--text"
                            style="opacity: 70%;">
                            {{ item.email !== '' ? item.email : '-' }}
                          </v-list-item-subtitle>

                          <v-list-item-subtitle
                            class="body-2">
                            Telp. {{ item.mobilephone !== '' ? item.mobilephone : '-' }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </template>
              </v-skeleton-loader>
            </v-col>
          </v-row>

          <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
            class="text-center my-4">
            <v-btn
              @click="limit += 10"
              :loading="process.limit"
              small
              rounded
              outlined
              elevation="0"
              :color="set_color"
              class="text-capitalize"
              :style="`color: ${set_color};`">
              Lebih banyak
            </v-btn>
          </div>

          <Empty v-if="!process.load && pagination.total_page < 1"
            class="py-6"
            margin="my-16"
            size="125"
            message="Pelanggan" />
        </v-card-text>
      </v-card>
    </section>

    <v-bottom-sheet
      v-model="sheet.detail"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card v-if="Object.keys(detail).length > 0"
        flat
        class="background">
        <div
          class="blur">
          <v-card-title 
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon 
              @click="sheet.detail = false; detail = {};"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span 
              @click="sheet.detail = false; detail = {};"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              {{ detail.name }}
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 80px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text 
              class="pt-16 mt-4">
              <v-avatar 
                tile
                size="150">
                <v-img 
                  width="100%"
                  height="100%"
                  style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
                        border: 5px solid #0000000d;
                        border-radius: 10px;"
                  alt="bidanvitacare" 
                  :src="detail.image_url !== '' ? detail.image_url : require('@/assets/image/member_default.jpg')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </v-card-text>
            
            <v-card-text>
              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Nama Lengkap
                </div>

                <div
                  class="body-2 font-weight-bold black--text">
                  {{ detail.name }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Tipe
                </div>

                <div
                  class="body-2 black--text">
                  {{ 
                    detail.type === 'ibu_hamil' ? 'Ibu Hamil' :
                    detail.type === 'ibu_menyusui' ? 'Ibu Menyusui' :
                    detail.type === 'bayi' ? 'bayi' :
                    detail.type === 'anak' ? 'Anak' : 'Umum'
                  }}
                </div>
              </div>

              <div v-if="detail.type === 'ibu_hamil'"
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  HPHT
                </div>

                <div
                  class="body-2 black--text">
                  <span v-if="detail.hpht_date !== ''">
                    {{ detail.hpht_date | date }}
                  </span>

                  <span v-else>
                    -
                  </span>
                </div>
              </div>

              <div v-if="detail.type === 'ibu_hamil'"
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Umur Kehamilan
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.umur_kehamilan !== '' ? detail.umur_kehamilan : '' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Email
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.email !== '' ? detail.email : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Instagram
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.instagram !== '' ? detail.instagram : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  No. Handphone
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.mobilephone !== '' ? detail.mobilephone : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  No. Handphone Lainnya
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.other_mobilephone !== '' ? detail.other_mobilephone : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Jenis Kelamin
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.gender === 'male' ? 'Laki-laki' : 'Perempuan' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Tempat Lahir
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.birth_place !== '' ? detail.birth_place : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Tanggal Lahir
                </div>

                <div
                  class="body-2 black--text">
                  <span v-if="detail.birth_date !== ''">
                    {{ detail.birth_date | date }}
                  </span>

                  <span v-else>
                    -
                  </span>
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Umur
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.age !== '' && detail.age !== null ? detail.age : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Penyakit Bawaan
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.congenital_disease !== '' ? detail.congenital_disease : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Nama Wali / Penanggung Jawab
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.wali_name !== '' ? detail.wali_name : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Status Wali / Penanggung Jawab
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.wali_status !== '' ? detail.wali_status : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Keterangan
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail.notes.length > 0 ? detail.notes[0] : '-' }}
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="50"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row
                align="center">
                <v-col 
                  cols="4" 
                  class="py-0">
                  <v-btn
                    @click="sheet.form = true; reset(detail);"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                    <div
                      class="btn_grey_in d-flex align-center justify-center">
                      <div>
                        <v-img 
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare" 
                          :src="require('@/assets/icon/edit_user.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                size="20"
                                indeterminate 
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="blue--text ml-2">
                        Ubah
                      </div>
                    </div>
                  </v-btn>
                </v-col>

                <v-col 
                  cols="4" 
                  class="py-0">
                  <v-btn
                    @click="sheet.address = true; fetchAddress();"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                    <div
                      class="btn_grey_in d-flex align-center justify-center">
                      <div>
                        <v-img 
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare" 
                          :src="require('@/assets/icon/add_marker.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                size="20"
                                indeterminate 
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="pink--text ml-2">
                        Alamat
                      </div>
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  cols="2"
                  class="py-0">
                  <v-img 
                    @click="dialog.deletes = true"
                    contain
                    width="36"
                    height="36"
                    alt="bidanvitacare" 
                    :src="require('@/assets/icon/hapus.png')"
                    class="ma-auto cursor-pointer">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="20"
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col
                  cols="2"
                  class="py-0">
                  <v-img 
                    @click="sheet.block = true; fetchBlock();"
                    contain
                    width="36"
                    height="36"
                    alt="bidanvitacare" 
                    :src="require('@/assets/icon/block_user.png')"
                    class="ma-auto cursor-pointer">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="20"
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.form"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title 
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon 
              @click="sheet.form = false; reset();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span 
              @click="sheet.form = false; reset();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              {{ form.id === '' ? 'Tambah' : 'Ubah' }}
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 205px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text 
              class="d-flex align-center pt-16 mt-4">
              <v-avatar 
                tile
                size="150">
                <v-img 
                  width="100%"
                  height="100%"
                  style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
                        border: 5px solid #0000000d;
                        border-radius: 10px;"
                  alt="bidanvitacare" 
                  :src="form.image_url !== '' ? form.image_url : require('@/assets/image/member_default.jpg')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <div>
                <upload-folder 
                  v-model="upload.folder">
                  <div slot="activator">
                    <v-btn
                      :disabled="process.file"
                      :loading="process.file"
                      elevation="0"
                      rounded
                      block
                      large
                      color="#0000000d"
                      class="body-1 font-weight-bold text-capitalize d-flex align-center"
                      style="border-radius: 0px 50px 50px 0px;"
                      :style="`color: ${set_color};`">
                      <v-icon
                        size="24"
                        :color="set_color">
                        mdi-camera
                      </v-icon>

                      <span
                        class="ml-1">
                        Foto
                      </span>
                    </v-btn>
                  </div>
                </upload-folder>
              </div>
            </v-card-text>

            <v-card-text>
              <ValidationObserver 
                ref="form">
                <v-form>
                  <ValidationProvider 
                    name="Nama Lengkap" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Nama Lengkap
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. John Doe"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.name"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="Tipe" 
                    rules="required"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Tipe
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-select
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. Bayi"
                      
                      :items="type"
                      item-text="text"
                      item-value="value"
                      :item-color="set_color"

                      @change="form.hpht_date = ''"

                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                      v-model="form.type"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-select>
                  </ValidationProvider>

                  <div v-if="form.type === 'ibu_hamil'">
                    <div class="body-2 mb-2">
                      HPHT
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-menu
                      ref="picker_hpht"
                      min-width="290px"
                      max-width="290px"
                      transition="scale-transition"
                      offset-y
                      :nudge-right="40"
                      :close-on-content-click="false"
                      v-model="picker_hpht">
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider 
                          name="HPHT" 
                          v-slot="{ errors, valid }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            readonly
                            filled
                            rounded
                            dense
                            :color="set_color"
                            placeholder="Contoh. 2020-01-01"
                            
                            :append-icon="form.hpht_date !== '' && valid ? 'mdi-check-circle-outline' : ''"

                            clearable
                            @click:clear="form.hpht_date = ''"
                            v-on="on"
                            v-bind="attrs"

                            v-model="form.hpht_date"
                            :success="form.hpht_date !== '' && valid"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </template>
                      
                      <v-date-picker 
                        :color="set_color"
                        v-model="form.hpht_date"
                        @input="picker_hpht = false">
                      </v-date-picker>
                    </v-menu>
                  </div>

                  <ValidationProvider 
                    name="Email" 
                    rules="email" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <!-- <span class="red--text">*</span> -->
                      Email
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. johndoe@gmail.com"
                      :append-icon="valid && form.email !== '' ? 'mdi-check-circle-outline' : ''"

                      v-model="form.email"
                      :success="valid && form.email !== ''"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="Instagram" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <!-- <span class="red--text">*</span> -->
                      Instagram
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. @johndoe"
                      :append-icon="valid && form.instagram !== '' ? 'mdi-check-circle-outline' : ''"

                      v-model="form.instagram"
                      :success="valid && form.instagram !== ''"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="No. Handphone" 
                    rules="required|min:8|max:13"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      No. Handphone
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                      min="0"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 081332XXXXXX"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.mobilephone"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="No. Handphone Lainnya" 
                    rules="min:8|max:13"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <!-- <span class="red--text">*</span> -->
                      No. Handphone Lainnya
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                      min="0"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 085702XXXXXX"
                      :append-icon="valid && form.other_mobilephone !== '' ? 'mdi-check-circle-outline' : ''"

                      v-model="form.other_mobilephone"
                      :success="valid && form.other_mobilephone !== ''"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="Jenis Kelamin" 
                    rules="required"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Jenis Kelamin
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-select
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. Laki-laki"
                      
                      :items="[
                        {
                          text: 'Laki-laki',
                          value: 'male'
                        },
                        {
                          text: 'Perempuan',
                          value: 'female'
                        }
                      ]"
                      item-text="text"
                      item-value="value"
                      :item-color="set_color"

                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                      v-model="form.gender"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-select>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="Tempat Lahir" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <!-- <span class="red--text">*</span> -->
                      Tempat Lahir
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. Yogyakarta"
                      :append-icon="valid && form.birth_place !== '' ? 'mdi-check-circle-outline' : ''"

                      v-model="form.birth_place"
                      :success="valid && form.birth_place !== ''"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <div>
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Tanggal Lahir
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-menu
                      ref="picker"
                      min-width="290px"
                      max-width="290px"
                      transition="scale-transition"
                      offset-y
                      :nudge-right="40"
                      :close-on-content-click="false"
                      v-model="picker">
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider 
                          name="Tanggal Lahir" 
                          rules="required"
                          v-slot="{ errors, valid }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            readonly
                            filled
                            rounded
                            dense
                            :color="set_color"
                            placeholder="Contoh. 2020-01-01"
                            
                            :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                            clearable
                            @click:clear="form.birth_date = ''"
                            v-on="on"
                            v-bind="attrs"

                            v-model="form.birth_date"
                            :success="valid"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </template>
                      
                      <v-date-picker 
                        :color="set_color"
                        v-model="form.birth_date"
                        @input="picker = false">
                      </v-date-picker>
                    </v-menu>
                  </div>

                  <ValidationProvider 
                    name="Penyakit Bawaan" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      Penyakit Bawaan
                      <!-- <span>
                        (boleh dikosongi)
                      </span> -->
                    </div>

                    <v-textarea
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      rows="5"
                      placeholder="Contoh. Tuliskan penyakit bawaan disini . . ."
                      :append-icon="form.congenital_disease !== '' && valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.congenital_disease"
                      :success="form.congenital_disease !== '' && valid"
                      :error-messages="errors"
                      required>
                    </v-textarea>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="Nama Wali / Penanggung Jawab" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <!-- <span class="red--text">*</span> -->
                      Nama Wali / Penanggung Jawab
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. Rina Kurniawati"
                      :append-icon="valid && form.wali_name !== '' ? 'mdi-check-circle-outline' : ''"

                      v-model="form.wali_name"
                      :success="valid && form.wali_name !== ''"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="Status Wali / Penanggung Jawab" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <!-- <span class="red--text">*</span> -->
                      Status Wali / Penanggung Jawab
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. Orang Tua"
                      :append-icon="valid && form.wali_status !== '' ? 'mdi-check-circle-outline' : ''"

                      v-model="form.wali_status"
                      :success="valid && form.wali_status !== ''"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="Keterangan" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      Keterangan
                      <!-- <span>
                        (boleh dikosongi)
                      </span> -->
                    </div>

                    <v-textarea
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      rows="5"
                      placeholder="Contoh. Tuliskan keterangan disini . . ."
                      :append-icon="form.notes[0] !== '' && valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.notes[0]"
                      :success="form.notes[0] !== '' && valid"
                      :error-messages="errors"
                      required>
                    </v-textarea>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="text-center mt-3 mb-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
                  
                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="150"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row 
                align="center">
                <v-col 
                  cols="3" 
                  class="pb-0">
                  <v-img 
                    contain
                    alt="bidanvitacare" 
                    :src="require('@/assets/image/footer/pelanggan_bvc.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col 
                  cols="9" 
                  class="black--text pb-0">
                  <div>
                    <div 
                      class="body-2 line-text-second black--text" 
                      style="opacity: 60%;">
                      Masukkan data Pelanggan untuk membuat akun di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                    </div>
                  </div>
                </v-col>
              </v-row>
              
              <v-row 
                class="mt-4">
                <v-col 
                  cols="6" 
                  class="pb-0">
                  <v-btn
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save"
                    @click="save"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in">
                      <v-icon 
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-check-bold
                      </v-icon>

                      Simpan
                    </div>
                  </v-btn>
                </v-col>

                <v-col 
                  cols="6" 
                  class="pb-0">
                  <v-btn
                    @click="sheet.form = false; reset();"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon 
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-close-thick
                      </v-icon>

                      Batal
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.address"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card 
        flat
        class="background">
        <div
          class="blur">
          <v-card-title 
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon 
              @click="sheet.address = false; fetch();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span 
              @click="sheet.address = false; fetch();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Alamat {{ detail.name }}
            </span>
          </v-card-title>

          <v-card-text 
            class="pb-0 pt-16 mt-5">
            <v-text-field
              v-model="search_address"
              @click:clear="search_address = ''; fetchAddress();"
              v-on:keyup.enter="fetchAddress()"
              filled
              rounded
              dense 
              single-line
              hide-details
              :color="set_color"
              prepend-inner-icon="mdi-magnify"
              placeholder="Cari data yang Anda butuhkan ?"
              clearable>
            </v-text-field>

            <v-btn
              @click="sheet.form_address = true; reset_address();"
              block
              large
              rounded
              elevation="0"
              class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
              :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
              <div
                class="btn_auth_in d-flex align-center justify-center"
                :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                <div>
                  <v-img 
                    contain
                    width="20"
                    height="20"
                    alt="bidanvitacare" 
                    :src="require('@/assets/icon/add_marker.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="20"
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>

                <div
                  class="ml-2">
                  Tambah Alamat
                </div>
              </div>
            </v-btn>
          </v-card-text>

          <v-card-text
            class="pb-0"
            style="height: calc(100vh - 185px); overflow: auto;">
            <v-row>
              <v-col
                cols="12">
                <v-skeleton-loader
                  v-for="(item, index) in process.load ? 5 : list"
                  :key="index"
                  :loading="process.load"
                  :class="index < list.length - 1 ? 'mb-4' : ''"
                  type="list-item-avatar-three-line">
                  <template>
                    <div>
                      <v-list
                        v-if="!process.load && list.length > 0"
                        flat
                        dense
                        two-line
                        color="#FFFFFF40"
                        class="border-radius">
                        <v-list-item
                          @click="sheet.detail_address = true; fetchDetailAddress(item.id);">
                          <v-list-item-avatar
                            tile
                            size="40"
                            class="mt-0"
                            style="border: 2px solid #ffffff; border-radius: 5px !important;">
                            <v-img 
                              width="100%"
                              height="100%"
                              alt="bidanvitacare" 
                              :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    size="20"
                                    indeterminate 
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title
                              class="body-2 font-weight-bold text-capitalize">
                              {{ item.address_full }}
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class="body-2 font-weight-light line-text-second black--text"
                              style="opacity: 70%;">
                              {{ item.note !== '' ? item.note : '-' }}
                            </v-list-item-subtitle>

                            <v-list-item-subtitle
                              class="body-2 font-weight-light line-text-first black--text"
                              style="opacity: 70%;">
                              {{ item.subdistrict_name }}, {{ item.city_name }}, {{ item.province_name }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </template>
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <Empty v-if="!process.load && pagination.total_page < 1"
              class="py-6"
              margin="my-16"
              size="125"
              message="Alamat" />
          </v-card-text>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.detail_address"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card v-if="Object.keys(detail_address).length > 0"
        flat
        class="background">
        <div
          class="blur">
          <v-card-title 
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon 
              @click="sheet.detail_address = false; detail_address = {}; fetchAddress();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span 
              @click="sheet.detail_address = false; detail_address = {}; fetchAddress();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              {{ detail_address.customer_name }}
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 80px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text 
              class="pt-16 mt-4 pb-0">
              <div
                class="mb-3">
                <v-img 
                  contain
                  width="100%"
                  height="250"
                  style="border: 5px solid #0000000d;
                        border-radius: 10px;"
                  alt="bidanvitacare" 
                  :src="detail_address.image_url !== '' ? detail_address.image_url : require('@/assets/image/image_default.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>

              <v-row>
                <v-col
                  v-for="(item, index) in detail_address.image_array_json"
                  :key="index"
                  cols="6">
                  <v-img 
                    contain
                    width="100%"
                    height="100%"
                    style="border: 5px solid #0000000d;
                          border-radius: 10px;"
                    alt="bidanvitacare" 
                    :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
            </v-card-text>
            
            <v-card-text>
              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Alamat Lengkap
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail_address.address_full !== '' ? detail_address.address_full : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Kecamatan
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail_address.subdistrict_name !== '' ? detail_address.subdistrict_name : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Kabupaten
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail_address.city_name !== '' ? detail_address.city_name : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Provinsi
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail_address.province_name !== '' ? detail_address.province_name : '-' }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Map
                </div>

                <div>
                  <gmap-map
                    :center="{
                      lat: detail_address.latitute,
                      lng: detail_address.longitude
                    }"
                    :zoom="12"
                    :options="{
                      zoomControl: true,
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: true,
                      disableDefaultUi: false
                    }"
                    style="width: 100%; height: 300px;">
                    <gmap-marker
                      :position="{
                        lat: detail_address.latitute,
                        lng: detail_address.longitude
                      }">
                    </gmap-marker>
                  </gmap-map>
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Link
                </div>

                <div
                  class="body-2 black--text">
                  <a v-if="detail_address.link_map !== ''"
                    :href="detail_address.link_map"
                    target="_blank">
                    {{ detail_address.link_map }}
                  </a>

                  <div v-else>
                    -
                  </div>
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Keterangan
                </div>

                <div
                  class="body-2 black--text">
                  {{ detail_address.note !== '' ? detail_address.note : '-' }}
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="50"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row
                align="center">
                <v-col 
                  cols="6" 
                  class="py-0">
                  <v-btn
                    @click="sheet.form_address = true; reset_address(detail_address);"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                    <div
                      class="btn_grey_in d-flex align-center justify-center">
                      <div>
                        <v-img 
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare" 
                          :src="require('@/assets/icon/edit_pencil.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                size="20"
                                indeterminate 
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="blue--text ml-2">
                        Ubah
                      </div>
                    </div>
                  </v-btn>
                </v-col>

                <v-col 
                  cols="6" 
                  class="py-0">
                  <v-btn
                    @click="dialog.deletes_address = true"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                    <div
                      class="btn_grey_in d-flex align-center justify-center">
                      <div>
                        <v-img 
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare" 
                          :src="require('@/assets/icon/hapus.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                size="20"
                                indeterminate 
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="red--text ml-2">
                        Hapus
                      </div>
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.form_address"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title 
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon 
              @click="sheet.form_address = false; reset_address();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span 
              @click="sheet.form_address = false; reset_address();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              {{ form_address.id === '' ? 'Tambah' : 'Ubah' }}
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 205px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text 
              class="d-flex align-center pt-16 mt-5">
              <upload-folder 
                v-model="upload.folder_address"
                style="width: 100%;">
                <div 
                  slot="activator" 
                  class="cursor-pointer d-flex align-center justify-center"
                  style="border: 5px solid #0000000d;
                        border-radius: 10px;
                        min-width: 100%;
                        width: 100%;
                        height: 250px;">
                  <v-img v-if="!process.file && form_address.image_url !== ''"
                    width="100%"
                    height="100%"
                    style="border-radius: 6px;"
                    alt="bidanvitacare" 
                    :src="form_address.image_url !== '' ? form_address.image_url : require('@/assets/image/image_default.png')"
                    class="ma-auto d-flex align-center text-center">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>

                    <v-icon 
                      size="50" 
                      color="white">
                      mdi-cloud-upload-outline
                    </v-icon>
                  </v-img>

                  <v-icon v-if="!process.file && form_address.image_url === ''"
                    size="50" 
                    color="grey">
                    mdi-cloud-upload-outline
                  </v-icon>

                  <v-progress-circular v-if="process.file"
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </div>
              </upload-folder>
            </v-card-text>

            <v-card-text
              class="py-0">
              <v-row>
                <v-col 
                  v-for="(item, index) in form_address.image_array_json"
                  :key="index"
                  cols="6" 
                  md="6">
                  <v-card 
                    flat
                    width="100%"
                    height="200"
                    class="d-flex align-center justify-center"
                    style="border-radius: 15px;
                          border: 5px solid #0000000d;
                          box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; ">
                    <v-img 
                      alt="bidanvitacare" 
                      width="100%"
                      height="190"
                      :src="item.image_url"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>

                      <div 
                        @click="form_address.image_array_json.splice(index, 1)"
                        class="cursor-pointer"
                        style="position: absolute; top: 5px; right: 5px;">
                        <v-icon size="24" color="red">mdi-close-circle</v-icon>
                      </div>
                    </v-img>
                  </v-card>
                </v-col>

                <v-col
                  cols="6" 
                  md="6">
                  <upload-folder 
                    v-model="upload.folder_array">
                    <div 
                      slot="activator" 
                      class="cursor-pointer">
                      <v-card 
                        flat
                        width="100%"
                        height="200"
                        class="d-flex align-center justify-center"
                        style="border-radius: 15px;
                              border: 5px solid #0000000d;">
                        <v-icon v-if="!process.file_array"
                          size="50" 
                          color="grey">
                          mdi-plus
                        </v-icon>

                        <v-progress-circular v-else
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-card>
                    </div>
                  </upload-folder>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <ValidationObserver 
                ref="form_address">
                <v-form>
                  <ValidationProvider 
                    name="Alamat Lengkap" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Alamat Lengkap
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <div 
                      class="v-input v-input--has-state v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--single-line v-text-field--filled v-text-field--is-booted v-text-field--enclosed v-text-field--placeholder v-text-field--rounded success--text" style="">
                      <div class="v-input__control">
                        <div class="v-input__slot">
                          <div class="v-text-field__slot">
                            <input type="text" v-model="form_address.address_full"
                              style="display: none;">

                            <gmap-autocomplete 
                              id="input-161"
                              placeholder="Contoh. Yogyakarta" 
                              :value="form_address.address_full" 
                              @input="value = $event.target.value"
                              :select-first-on-enter="true" 
                              @place_changed="setPlace">
                            </gmap-autocomplete>
                          </div>

                          <div v-if="valid || form_address.address_full !== ''"
                            class="v-input__append-inner">
                            <div class="v-input__icon v-input__icon--append">
                              <i aria-hidden="true" class="v-icon notranslate mdi mdi-check-circle-outline theme--light success--text"></i>
                            </div>
                          </div>
                        </div>
                        
                        <div class="v-text-field__details">
                          <div v-if="errors && form_address.address_full === ''"
                            class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                              <div class="v-messages__message">
                                {{ errors[0]}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="Kecamatan" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Kecamatan
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-autocomplete
                      v-on:keyup.enter="save_address"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. Sleman"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      :search-input.sync="autocomplete.subdistrict"
                      :loading="process.subdistrict"
                      
                      :items="subdistrict"
                      item-value="id"
                      item-text="label"
                      :item-color="set_color"

                      :no-filter="false"

                      v-model="form_address.subdistrict_id"
                      :success="valid"
                      :error-messages="errors"
                      required>
                      <template v-slot:item="data">
                        <span
                          class="body-2 text-uppercase">
                          {{ data.item.label }}
                        </span>
                      </template>

                      <template v-slot:selection="data">
                        <span
                          class="body-2 text-uppercase">
                          {{ data.item.label }}
                        </span>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>

                  <div
                    class="mb-6">
                    <div class="body-2 line-text-first mb-2">
                      Silahkan geser marker untuk menentukan koordinat lokasi dibawah ini:
                    </div>

                    <gmap-map
                      :center="center"
                      :zoom="12"
                      :options="{
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: true,
                        disableDefaultUi: false
                      }"
                      :clickable="true"
                      @click="updatePlace"
                      style="width: 100%; height: 300px;">

                      <gmap-marker
                        :position="center"
                        :draggable="true"
                        @drag="updatePlace"
                        @click="center=center">
                      </gmap-marker>
                    </gmap-map>
                  </div>
                  
                  <ValidationProvider 
                    name="Link Map" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      Link Map
                      <!-- <span>
                        (boleh dikosongi)
                      </span> -->
                    </div>

                    <v-textarea
                      v-on:keyup.enter="save_address"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      rows="5"
                      placeholder="Contoh. https://goo.gl/maps/K973ZcF3s2Wi15cL9"
                      :append-icon="form_address.link_map !== '' && valid ? 'mdi-check-circle-outline' : ''"

                      @blur="to_latlng()"

                      v-model="form_address.link_map"
                      :success="form_address.link_map !== '' && valid"
                      :error-messages="errors"
                      required>
                    </v-textarea>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="Keterangan" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      Keterangan
                      <!-- <span>
                        (boleh dikosongi)
                      </span> -->
                    </div>

                    <v-textarea
                      v-on:keyup.enter="save_address"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      rows="5"
                      placeholder="Contoh. Tuliskan keterangan disini . . ."
                      :append-icon="form_address.note !== '' && valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form_address.note"
                      :success="form_address.note !== '' && valid"
                      :error-messages="errors"
                      required>
                    </v-textarea>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="text-center mt-3 mb-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
                  
                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="150"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row 
                align="center">
                <v-col 
                  cols="3" 
                  class="pb-0">
                  <v-img 
                    contain
                    alt="bidanvitacare" 
                    :src="require('@/assets/image/footer/maps.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col 
                  cols="9" 
                  class="black--text pb-0">
                  <div>
                    <div 
                      class="body-2 line-text-second black--text" 
                      style="opacity: 60%;">
                      Masukkan data Alamat untuk informasi di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                    </div>
                  </div>
                </v-col>
              </v-row>
              
              <v-row 
                class="mt-4">
                <v-col 
                  cols="6" 
                  class="pb-0">
                  <v-btn
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save_address"
                    @click="save_address"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in">
                      <v-icon 
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-check-bold
                      </v-icon>

                      Simpan
                    </div>
                  </v-btn>
                </v-col>

                <v-col 
                  cols="6" 
                  class="pb-0">
                  <v-btn
                    @click="sheet.form_address = false; reset_address();"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon 
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-close-thick
                      </v-icon>

                      Batal
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.block"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card 
        flat
        class="background">
        <div
          class="blur">
          <v-card-title 
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon 
              @click="sheet.block = false; fetch();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span 
              @click="sheet.block = false; fetch();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Blokir Terapis
            </span>
          </v-card-title>

          <v-card-text 
            class="pb-0 pt-16 mt-5">
            <v-text-field
              v-model="search_block"
              @click:clear="search_block = ''; fetchBlock();"
              v-on:keyup.enter="fetchBlock()"
              filled
              rounded
              dense 
              single-line
              hide-details
              :color="set_color"
              prepend-inner-icon="mdi-magnify"
              placeholder="Cari data yang Anda butuhkan ?"
              clearable>
            </v-text-field>

            <v-btn
              @click="sheet.form_block = true; reset_block();"
              block
              large
              rounded
              elevation="0"
              class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
              :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
              <div
                class="btn_auth_in d-flex align-center justify-center"
                :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                <div>
                  <v-img 
                    contain
                    width="20"
                    height="20"
                    alt="bidanvitacare" 
                    :src="require('@/assets/icon/block_add.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="20"
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>

                <div
                  class="ml-2">
                  Tambah Daftar Blokir
                </div>
              </div>
            </v-btn>
          </v-card-text>

          <v-card-text
            class="pb-0"
            style="height: calc(100vh - 185px); overflow: auto;">
            <v-row>
              <v-col
                cols="12">
                <v-skeleton-loader
                  v-for="(item, index) in process.load ? 5 : list"
                  :key="index"
                  :loading="process.load"
                  :class="index < list.length - 1 ? 'mb-4' : ''"
                  type="list-item-avatar-three-line">
                  <template>
                    <div>
                      <v-list
                        v-if="!process.load && list.length > 0"
                        flat
                        dense
                        color="#FFFFFF40"
                        class="border-radius">
                        <v-list-item>
                          <v-list-item-avatar
                            size="40"
                            style="border: 2px solid #ffffff;">
                            <v-img 
                              width="100%"
                              height="100%"
                              alt="bidanvitacare" 
                              :src="item.user_image_url !== '' ? item.user_image_url : require('@/assets/image/member_default.jpg')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    size="20"
                                    indeterminate 
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title
                              class="body-2 font-weight-bold text-capitalize">
                              {{ item.user_name }}
                            </v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action
                            @click="deletes_block(item.id)"
                            class="cursor-pointer">
                            <v-icon color="red">mdi-close-circle</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </div>
                  </template>
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <Empty v-if="!process.load && pagination.total_page < 1"
              class="py-6"
              margin="my-16"
              size="125"
              message="Blokir Terapis" />
          </v-card-text>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.form_block"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title 
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon 
              @click="sheet.form_block = false; reset_block(); fetchBlock();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span 
              @click="sheet.form_block = false; reset_block(); fetchBlock();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Tambah
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 205px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="mt-16">
              <v-text-field
                v-model="search_terapis"
                @click:clear="search_terapis = ''; fetchTerapis();"
                v-on:keyup.enter="fetchTerapis()"
                filled
                rounded
                dense 
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data terapis ?"
                clearable
                class="mb-2">
              </v-text-field>

              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list.length > 0"
                          flat
                          dense
                          two-line
                          color="#FFFFFF40"
                          class="border-radius"
                          :style="form_block.therapist_id.length > 0 && form_block.therapist_id === item.id? 'border: 2px dashed #ff8080;' : 'border: 1px dashed #bbb;'">
                          <v-list-item
                            @click="form_block.therapist_id = item.id">
                            <v-list-item-avatar
                              size="40"
                              class="mt-0"
                              style="border: 2px solid #ffffff;">
                              <v-img 
                                width="100%"
                                height="100%"
                                alt="bidanvitacare" 
                                :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      size="20"
                                      indeterminate 
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                {{ item.name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light text-capitalize line-text-second black--text"
                                style="opacity: 70%;">
                                {{ item.role }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="body-2">
                                Telp. {{ item.mobilephone }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <Empty v-if="!process.load && pagination.total_page < 1"
                margin="my-8"
                size="125"
                message="Terapis" />
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="150"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row 
                align="center">
                <v-col 
                  cols="3" 
                  class="pb-0">
                  <v-img 
                    contain
                    alt="bidanvitacare" 
                    :src="require('@/assets/icon/block_user.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col 
                  cols="9" 
                  class="black--text pb-0">
                  <div>
                    <div 
                      class="body-2 line-text-second black--text" 
                      style="opacity: 60%;">
                      Masukkan data Blokir Terapis untuk pelanggan di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                    </div>
                  </div>
                </v-col>
              </v-row>
              
              <v-row 
                class="mt-4">
                <v-col 
                  cols="6" 
                  class="pb-0">
                  <v-btn
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save_block"
                    @click="save_block"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in">
                      <v-icon 
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-check-bold
                      </v-icon>

                      Simpan
                    </div>
                  </v-btn>
                </v-col>

                <v-col 
                  cols="6" 
                  class="pb-0">
                  <v-btn
                    @click="sheet.form_block = false; reset_block(); fetchBlock();"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon 
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-close-thick
                      </v-icon>

                      Batal
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        dialog: {
          deletes: false,
          deletes_address: false
        },
        sheet: {
          form: false,
          detail: false,

          address: false,
          form_address: false,
          detail_address: false,

          block: false,
          form_block: false
        },
        search: '',
        search_address: '',
        search_block: '',
        search_terapis: '',
        detail: {},
        detail_address: {},
        list: [],
        pagination: {},

        limit: 10,

        upload: {
          folder: null,
          folder_address: null,
          folder_array: null
        },
        show: false,
        picker: false,
        picker_hpht: false,

        type: [
          {
            text: 'Ibu Hamil',
            value: 'ibu_hamil'
          },
          {
            text: 'Ibu Menyusui',
            value: 'ibu_menyusui'
          },
          {
            text: 'Bayi',
            value: 'bayi'
          },
          {
            text: 'Anak',
            value: 'anak'
          },
          {
            text: 'Umum',
            value: 'umum'
          }
        ],

        form: {
          id: '',
          name: '',
          email: '',
          image_url: '',
          mobilephone: '',
          gender: '',
          wali_name: '',
          wali_status: '',
          birth_place: '',
          birth_date: '',
          notes: [''],

          congenital_disease: '',
          instagram: '',
          other_mobilephone: '',

          type: 'umum',
          hpht_date: ''
        },
        subdistrict: [],
        autocomplete: {
          subdistrict: ''
        },
        center: {
          lat: -7.8032076, 
          lng: 110.3573354
        },
        form_address: {
          id: '',
          customer_id: '',
          address_full: '',
          subdistrict_id: '',
          latitute: -7.8032076,
          longitude: 110.3573354,
          image_url: '',
          image_array_json: [],
          note: '',

          link_map: ''
        },
        form_block: {
          customer_id: '',
          therapist_id: ''
        },
        process: {
          load: false,
          form: false,
          file: false,
          file_array: false,
          subdistrict: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form.image_url = this.upload.folder.fileuri
            }
          }
        },
        deep: true
      },
      'upload.folder_address': {
        handler: function () {
          if (this.upload.folder_address) {
            this.process.file = this.upload.folder_address.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form_address.image_url = this.upload.folder_address.fileuri
            }
          }
        },
        deep: true
      },
      'upload.folder_array': {
        handler: function () {
          if (this.upload.folder_array) {
            this.process.file_array = this.upload.folder_array.process

            if (!this.process.file_array) {
              // CALLBACK FROM UPLOAD IMAGE

              let index = this.form_address.image_array_json.length + 1

              this.form_address.image_array_json.push({
                label: `Rumah ${index}`,
                image_url: this.upload.folder_array.fileuri
              })
            }
          }
        },
        deep: true
      },
      'autocomplete.subdistrict': function() {
        this.fetchSubdistrict()
      },

      'limit': function() {
        this.process.limit = true
        
        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit,
          'role[neq]': 'superadmin'
        }

        await this.$axios.$get(`${process.env.API}admin/customer`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchDetail (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/customer/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail = response.results.data
          }
        })
      },
      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          id: item !== undefined ? item.id : '',
          name: item !== undefined ? item.name : '',
          email: item !== undefined ? item.email : '',
          image_url: item !== undefined ? item.image_url : '',
          mobilephone: item !== undefined ? item.mobilephone : '',
          gender: item !== undefined ? item.gender : '',
          wali_name: item !== undefined ? item.wali_name : '',
          wali_status: item !== undefined ? item.wali_status : '',
          birth_place: item !== undefined ? item.birth_place : '',
          birth_date: item !== undefined ? item.birth_date : '',
          notes: item !== undefined ? item.notes : [''],

          congenital_disease: item !== undefined ? item.congenital_disease : '',
          instagram: item !== undefined ? item.instagram : '',
          other_mobilephone: item !== undefined ? item.other_mobilephone : '',

          type: item !== undefined ? item.type : 'umum',
          hpht_date: item !== undefined ? item.hpht_date : ''
        }
        
        this.picker = false

        this.picker_hpht = false

        this.process.form = false

        this.message.error = ''
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form.id === '' ? 'admin/customer/add' : 'admin/customer/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form = false

              if (this.form.id === '') {
                // this.fetch()

                let customer_id = response.results.data.customer_id

                this.detail = { id: customer_id, notes: [] }

                this.sheet.address = true
                this.fetchAddress()

                this.sheet.form_address = true
                this.reset_address()
              } else {
                this.fetchDetail(this.detail.id)
              }
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      async fetchAddress () {
        this.process.load = true

        let params = {
          customer_id: this.detail.id,
          search: this.search_address,
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/customer-address`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchSubdistrict () {
        this.process.subdistrict = true

        let params = {
          search: this.autocomplete.subdistrict,
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}subdistrict`, { params })
        .then((response) => {
          this.process.subdistrict = false

          if (response.status === 200) {
            this.subdistrict = response.results.data
          }
        })
      },
      async fetchDetailAddress (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/customer-address/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail_address = response.results.data

            this.detail_address.latitute = parseFloat(this.detail_address.latitute)
            this.detail_address.longitude = parseFloat(this.detail_address.longitude)
          }
        })
      },
      reset_address (item) {
        if (this.$refs.form_address) this.$refs.form_address.reset()

        this.form_address = {
          id: item !== undefined ? item.id : '',
          customer_id: this.detail.id,
          address_full: item !== undefined ? item.address_full : '',
          subdistrict_id: item !== undefined ? item.subdistrict_id : '',
          latitute: item !== undefined ? item.latitute : -7.8032076,
          longitude: item !== undefined ? item.longitude : 110.3573354,
          image_url: item !== undefined ? item.image_url : '',
          image_array_json: item !== undefined ? item.image_array_json : [],
          note: item !== undefined ? item.note : '',

          link_map: item !== undefined ? item.link_map : ''
        }
        
        this.process.form = false

        this.message.error = ''
      },

      to_latlng () {
        // -7,7116400, 110,6008760
        // (-7.7116400, 110.6008760)
        // https://www.google.com/maps/search/?api=1&query={$schedule['customer_address_latitute']},{$schedule['customer_address_longitute']}

        if (this.form_address.link_map !== '') {
          let data = this.form_address.link_map.replace(/["'()]/g,"").split(', ')
  
          this.center = {
            lat: Number(data[0].replace(/,/g,".")),
            lng: Number(data[1].replace(/,/g,"."))
          }
  
          this.form_address.latitute = Number(data[0].replace(/,/g,"."))
          this.form_address.longitude = Number(data[1].replace(/,/g,"."))
  
          this.form_address.link_map = `https://www.google.com/maps/search/?api=1&query=${this.form_address.latitute},${this.form_address.longitude}`

          const geocoder = new google.maps.Geocoder()
          geocoder.geocode({ latLng: this.center }, (result, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              this.form_address.address_full = result[0].formatted_address
            }
          })
        }
      },

      setPlace (place) {
        console.log(place)
        this.center = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        }

        this.form_address.latitute = place.geometry.location.lat(),
        this.form_address.longitude = place.geometry.location.lng()

        this.form_address.link_map = `https://www.google.com/maps/search/?api=1&query=${this.form_address.latitute},${this.form_address.longitude}`

        this.form_address.address_full = place.formatted_address
      },
      updatePlace (location) {
        this.center = {
          lat: location.latLng.lat(),
          lng: location.latLng.lng()
        }

        this.form_address.latitute = location.latLng.lat()
        this.form_address.longitude = location.latLng.lng()

        this.form_address.link_map = `https://www.google.com/maps/search/?api=1&query=${this.form_address.latitute},${this.form_address.longitude}`

        const geocoder = new google.maps.Geocoder()
        geocoder.geocode({ latLng: this.center }, (result, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            this.form_address.address_full = result[0].formatted_address
          }
        })
      },
      async save_address () {
        this.message.error = ''

        const isValid = await this.$refs.form_address.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form_address.id === '' ? 'admin/customer-address/add' : 'admin/customer-address/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form_address)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {

              this.sheet.form_address = false

              if (this.form_address.id === '') {
                this.fetchAddress()
              } else {
                this.fetchDetailAddress(this.detail_address.id)
              }
            } else {
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form_address.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form_address.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      async fetchBlock () {
        this.process.load = true

        let params = {
          customer_id: this.detail.id,
          search: this.search_block,
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/therapist-block`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchTerapis () {
        this.process.load = true

        let params = {
          search: this.search_terapis,
          'role[eq]': 'therapist',
          limit: 10000
        }

        this.list.terapis = []

        await this.$axios.$get(`${process.env.API}admin/user`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      reset_block () {
        if (this.$refs.form_block) this.$refs.form_block.reset()

        this.form_block = {
          customer_id: this.detail.id,
          therapist_id: ''
        }
        
        this.process.form = false

        this.message.error = ''

        if (this.sheet.form_block) this.fetchTerapis()
      },
      async save_block () {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/therapist-block/add`, this.form_block)
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.sheet.form_block = false

            this.fetchBlock()
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        }) 
      },
      async deletes_block (id) {
        await this.$axios.$post(`${process.env.API}admin/therapist-block/delete`, { id: id })
        .then((response) => {
          if (response.status === 200) {
            this.fetchBlock()
          }
        })
      },

      async deletes () {
        this.message.error = ''

        this.process.form = true

          // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/customer/delete`, {
          id: this.detail.id
        })
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.dialog.deletes = false

            this.sheet.detail = false

            this.fetch()
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      },

      async deletes_address () {
        this.message.error = ''

        this.process.form = true

          // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/customer-address/delete`, {
          id: this.detail_address.id
        })
        .then((response) => {
          
          this.process.form = false

          if (response.status === 200) {

            this.dialog.deletes_address = false

            this.sheet.detail_address = false

            this.fetchAddress()
          } else {
            this.message.error = response.message
          }
        })
      }
    }
  }
</script>