var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"d-flex align-center",staticStyle:{"z-index":"1"},attrs:{"flat":"","min-height":"100vh"}},[_c('v-card-text',[_c('div',{staticClass:"text-h5 text-center font-weight-bold mb-2",style:(`color: ${_vm.set_color};`)},[_vm._v("\n        Bidanvitacare.id\n      ")]),_vm._v(" "),_c('div',{staticClass:"body-1 text-center"},[_vm._v("\n        Mom, Baby & Kids Spa Sejak 2017\n      ")]),_vm._v(" "),_c('div',{staticClass:"body-1 text-center mt-2"},[_vm._v("\n        Sahabat Ceria Bunda & Buah Hati\n      ")]),_vm._v(" "),_c('div',{staticClass:"px-12 ma-4 mx-12"},[(_vm.$route.name !== 'jadwal-desktop' &&
                _vm.$route.name !== 'jadwal-new-desktop' &&
                _vm.$route.name !== 'jadwal-new-desktop-tambah' &&
                _vm.$route.name !== 'jadwal-new-desktop-ubah-id')?_c('v-progress-linear',{attrs:{"indeterminate":"","height":"8","rounded":"","color":_vm.set_color}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'jadwal-desktop' ||
                _vm.$route.name === 'jadwal-new-desktop' ||
                _vm.$route.name === 'jadwal-new-desktop-tambah' ||
                _vm.$route.name === 'jadwal-new-desktop-ubah-id')?_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-linear',{staticStyle:{"width":"250px"},attrs:{"indeterminate":"","height":"8","rounded":"","color":_vm.set_color}})],1):_vm._e()],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }