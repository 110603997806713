<template>
  <div>
    <v-dialog v-model="dialog.filter"
      persistent>
      <v-card
        class="border-radius pa-2">
        <v-card-title
          class="d-flex align-center py-0 px-3 text-right popup-header">
          <v-avatar
            size="30"
            tile>
            <v-img
              alt="bidanvitacare"
              :src="require('@/assets/logo/favicon.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    size="20"
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <span
            class="body-1 font-weight-bold text-capitalize ml-2">
            Filter
          </span>
        </v-card-title>

        <v-divider
          class="mx-4 mt-3 mb-2"/>

        <v-card-text
          class="body-2 text-first line-text-second mt-3">
          <div>
            <div class="body-2 mb-2">
              Pilih Cabang
            </div>

            <v-select
              filled
              rounded
              dense
              :color="set_color"
              placeholder="Pilih Cabang"

              hide-details

              :items="store"
              item-value="id"
              item-text="name"
              :item-color="set_color"

              clearable
              @click:clear="filter.store_id = ''"

              :no-filter="false"

              v-model="filter.store_id">
            </v-select>
          </div>
        </v-card-text>

        <v-divider
          class="mx-4 mb-3 mt-0"/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            @click="dialog.filter = false; fetch();"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_save">
            <div
              class="btn_save_in d-flex align-center justify-center">
              <v-icon
                size="20">
                mdi-check-bold
              </v-icon>

              <span
                class="ml-1">
                Simpan
              </span>
            </div>
          </v-btn>

          <v-btn
            @click="dialog.filter = false"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
            <div
              class="btn_cancel_in">
              <v-icon
                size="20">
                mdi-close-thick
              </v-icon>

              <span
                class="ml-1">
                Batal
              </span>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text
          class="mt-1">
          <v-text-field
            v-model="search"
            @click:clear="search = ''; fetch();"
            v-on:keyup.enter="fetch()"
            filled
            rounded
            dense
            single-line
            hide-details
            :color="set_color"
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari data yang Anda butuhkan ?"
            clearable>
          </v-text-field>

          <v-btn
            @click="sheet.form = true; reset();"
            block
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
            :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
            <div
              class="btn_auth_in d-flex align-center justify-center"
              :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
              <div>
                <v-img
                  contain
                  width="20"
                  height="20"
                  alt="bidanvitacare"
                  :src="require('@/assets/icon/add_plus.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>

              <div
                class="ml-2">
                Tambah Titipan
              </div>
            </div>
          </v-btn>
        </v-card-text>

        <v-card-text
          class="pb-0">
          <div
            class="body-2 font-weight-bold black--text d-flex align-center mb-2">
            <div>
              Titipan
            </div>

            <v-spacer />

            <div>
              <v-icon
                @click="dialog.filter = true; fetchStore();"
                class="cursor-pointer">
                mdi-filter-variant
              </v-icon>
            </div>
          </div>

          <v-row>
            <v-col>
              <v-skeleton-loader
                v-for="(item, index) in process.load ? 5 : list.data"
                :key="index"
                :loading="process.load"
                :class="index < list.data.length - 1 ? 'mb-4' : ''"
                type="list-item-avatar-three-line">
                <template>
                  <div>
                    <v-list
                      v-if="!process.load && list.data.length > 0"
                      flat
                      dense
                      three-line
                      color="#FFFFFF40"
                      class="border-radius">
                      <v-list-item
                        @click="sheet.detail = true; fetchDetail(item.id);">
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            <div
                              class="caption mb-2 one-line d-flex align-center">
                              <v-avatar
                                size="36"
                                style="border: 2px solid #ffffff;">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="item.therapist_image_url !== '' ? item.therapist_image_url : require('@/assets/image/member_default.jpg')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        size="20"
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>

                              <div
                                class="ml-2">
                                <div
                                  class="font-weight-bold black--text">
                                  {{ item.therapist_name }}
                                </div>

                                <div
                                  class="caption">
                                  Terapis pembawa cash titipan
                                </div>
                              </div>
                            </div>
                          </v-list-item-subtitle>

                          <v-list-item-title
                            class="body-2 font-weight-bold text-capitalize">
                            {{ item.so_code }}
                          </v-list-item-title>

                          <v-list-item-subtitle
                            class="body-2 font-weight-light line-text-second black--text my-2 py-2"
                            style="border-top: 1px dashed #bbb;">
                            <div
                              class="d-flex align-center">
                              <div
                                class="caption"
                                style="opacity: 60%; margin-bottom: 1px;">
                                Nominal
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 black--text">
                                {{ item.nominal | price }}
                              </div>
                            </div>

                            <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />

                            <div
                              class="d-flex align-center">
                              <div
                                class="caption"
                                style="opacity: 60%; margin-bottom: 1px;">
                                Dikembalikan
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 black--text">
                                {{ item.nominal_return | price }}
                              </div>
                            </div>

                            <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />

                            <div
                              class="d-flex align-center mb-2">
                              <div
                                class="caption"
                                style="opacity: 60%; margin-bottom: 1px;">
                                Admin Pembuat Titipan
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 black--text">
                                {{ item.user_create_name }}
                              </div>
                            </div>

                            <div
                              class="d-flex align-center">
                              <div
                                class="caption"
                                style="opacity: 60%; margin-bottom: 1px;">
                                Tanggal Dibuat
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 orange--text">
                                {{ item.create_datetime | date }} Pukul {{ item.create_datetime | time }}
                              </div>
                            </div>

                            <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />

                            <div
                              class="d-flex align-center mb-2">
                              <div
                                class="caption"
                                style="opacity: 60%; margin-bottom: 1px;">
                                Admin Penerima Titipan
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 black--text">
                                {{ item.user_update_name }}
                              </div>
                            </div>

                            <div
                              class="d-flex align-center">
                              <div
                                class="caption"
                                style="opacity: 60%; margin-bottom: 1px;">
                                Tanggal Diterima
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 green--text">
                                {{ item.update_datetime | date }} Pukul {{ item.update_datetime | time }}
                              </div>
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </template>
              </v-skeleton-loader>
            </v-col>
          </v-row>

          <div v-if="!process.load && pagination.data.total_data > 10 && limit < pagination.data.total_data"
            class="text-center my-4">
            <v-btn
              @click="limit += 10"
              :loading="process.limit"
              small
              rounded
              outlined
              elevation="0"
              :color="set_color"
              class="text-capitalize"
              :style="`color: ${set_color};`">
              Lebih banyak
            </v-btn>
          </div>

          <Empty v-if="!process.load && pagination.data.total_page < 1"
            class="py-6"
            margin="my-16"
            size="125"
            message="Titipan" />
        </v-card-text>
      </v-card>
    </section>

    <v-bottom-sheet
      v-model="sheet.detail"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card v-if="Object.keys(detail).length > 0"
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.detail = false; detail = {}; fetch();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.detail = false; detail = {}; fetch();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Titipan {{ detail.therapist_name }}
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 80px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="pt-16 mt-4 pb-0">
              <div
                class="d-flex align-center">
                <v-avatar
                  size="40"
                  style="border: 2px solid #ffffff;">
                  <v-img
                    width="100%"
                    height="100%"
                    alt="bidanvitacare"
                    :src="detail.therapist_image_url !== '' ? detail.therapist_image_url : require('@/assets/image/member_default.jpg')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="20"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <div
                  class="ml-2">
                  <div
                    class="font-weight-bold black--text">
                    {{ detail.therapist_name }}
                  </div>

                  <div
                    class="caption">
                    Terapis pembawa cash titipan
                  </div>
                </div>
              </div>
            </v-card-text>

            <v-card-text>
              <v-divider
                class="mb-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="body-2 font-weight-bold">
                {{ detail.so_code }}
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />


              <div
                class="d-flex align-center mb-4">
                <div
                  class="body-2">
                  Nominal
                </div>

                <v-spacer />

                <div
                  class="body-2 font-weight-bold black--text">
                  {{ detail.nominal | price }}
                </div>
              </div>

              <div
                class="d-flex align-center">
                <div
                  class="body-2">
                  Dikembalikan
                </div>

                <v-spacer />

                <div
                  class="body-2 font-weight-bold black--text">
                  {{ detail.nominal_return | price }}
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="d-flex align-center mb-4">
                <div
                  class="body-2">
                  Admin Pembuat Titipan
                </div>

                <v-spacer />

                <div
                  class="body-2 font-weight-bold black--text">
                  {{ detail.user_create_name }}
                </div>
              </div>

              <div
                class="d-flex align-center mb-4">
                <div
                  class="body-2">
                  Tanggal Dibuat
                </div>

                <v-spacer />

                <div
                  class="body-2 orange--text">
                  {{ detail.create_datetime | date }} Pukul {{ detail.create_datetime | time }}
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="d-flex align-center mb-4">
                <div
                  class="body-2">
                  Admin Penerima Titipan
                </div>

                <v-spacer />

                <div
                  class="body-2 font-weight-bold black--text">
                  {{ detail.user_update_name }}
                </div>
              </div>

              <div
                class="d-flex align-center">
                <div
                  class="body-2">
                  Tanggal Diterima
                </div>

                <v-spacer />

                <div
                  class="body-2 green--text">
                  {{ detail.update_datetime | date }} Pukul {{ detail.update_datetime | time }}
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="50"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row>
                <v-col
                  cols="6"
                  class="py-0">
                  <v-btn
                    @click="sheet.form = true; reset(detail);"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                    <div
                      class="btn_grey_in d-flex align-center justify-center">
                      <div>
                        <v-img
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/edit_pencil.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="blue--text ml-2">
                        Ubah
                      </div>
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  cols="6"
                  class="py-0">
                  <v-btn
                    @click="sheet.form_return = true; reset(detail);"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                    <div
                      class="btn_grey_in d-flex align-center justify-center">
                      <div>
                        <v-img
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/money_send.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="orange--text ml-2">
                        Kembalikan
                      </div>
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.form"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.form = false; reset();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.form = false; reset();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              {{ form.id === '' ? 'Tambah' : 'Ubah' }}
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 205px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="pt-16 mt-4">
              <ValidationObserver
                ref="form">
                <v-form>
                  <ValidationProvider v-if="form.id === ''"
                    name="Transaksi"
                    rules="required"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Transaksi
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-autocomplete
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. SO/230620/00001"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      :search-input.sync="autocomplete.transaction"
                      :loading="process.transaction"

                      :items="list.transaction"
                      item-value="id"
                      item-text="code"
                      :item-color="set_color"

                      :no-filter="false"

                      v-model="form.so_id"
                      :success="valid"
                      :error-messages="errors"
                      required>
                      <template v-slot:item="data">
                        <span
                          class="body-2 text-capitalize my-4">
                          <div
                            class="mb-1">
                            {{ data.item.code }}
                          </div>

                          <div
                            class="font-weight-bold">
                            {{ data.item.grand_total | price }}
                          </div>
                        </span>
                      </template>

                      <template v-slot:selection="data">
                        <span
                          class="body-2 text-capitalize my-2">
                          <div
                            class="mb-1">
                            {{ data.item.code }}
                          </div>

                          <div
                            class="font-weight-bold">
                            {{ data.item.grand_total | price }}
                          </div>
                        </span>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>

                  <ValidationProvider v-if="form.id === ''"
                    name="Terapis"
                    rules="required"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Terapis
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-autocomplete
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. John Doe"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      :search-input.sync="autocomplete.terapis"
                      :loading="process.terapis"

                      :items="list.terapis"
                      item-value="id"
                      item-text="name"
                      :item-color="set_color"

                      :no-filter="false"

                      v-model="form.user_id"
                      :success="valid"
                      :error-messages="errors"
                      required>
                      <template v-slot:item="data">
                        <span
                          class="body-2 text-capitalize">
                          {{ data.item.name }}
                        </span>
                      </template>

                      <template v-slot:selection="data">
                        <span
                          class="body-2 text-capitalize">
                          {{ data.item.name }}
                        </span>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Nominal"
                    rules="required|min:0"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Nominal
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save"
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                      min="0"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 25.000"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      @focus="$event.target.select()"

                      v-model="form.nominal"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider v-if="form.id !== ''"
                    name="Nominal Pengembalian"
                    rules="required|min:0"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Nominal Pengembalian
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save"
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                      min="0"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 25.000"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      @focus="$event.target.select()"

                      v-model="form.nominal_return"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="text-center my-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="150"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row
                align="center">
                <v-col
                  cols="3"
                  class="pb-0">
                  <v-img
                    contain
                    alt="bidanvitacare"
                    :src="require('@/assets/image/footer/titipan.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col
                  cols="9"
                  class="black--text pb-0">
                  <div>
                    <div
                      class="body-2 line-text-second black--text"
                      style="opacity: 60%;">
                      Masukkan data untuk titipan pembayaran di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row
                class="mt-4">
                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save"
                    @click="save"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-check-bold
                      </v-icon>

                      Simpan
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    @click="sheet.form = false; reset();"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-close-thick
                      </v-icon>

                      Batal
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.form_return"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card v-if="Object.keys(detail).length > 0"
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.form_return = false; fetchDetail();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.form_return = false; fetchDetail();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Pengembalian Titipan {{ detail.therapist_name }}
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 205px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="pt-16 mt-4 pb-0">
              <div
                class="d-flex align-center">
                <v-avatar
                  size="40"
                  style="border: 2px solid #ffffff;">
                  <v-img
                    width="100%"
                    height="100%"
                    alt="bidanvitacare"
                    :src="detail.therapist_image_url !== '' ? detail.therapist_image_url : require('@/assets/image/member_default.jpg')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="20"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <div
                  class="ml-2">
                  <div
                    class="font-weight-bold black--text">
                    {{ detail.therapist_name }}
                  </div>

                  <div
                    class="caption">
                    Terapis pembawa cash titipan
                  </div>
                </div>
              </div>
            </v-card-text>

            <v-card-text>
              <v-divider
                class="mb-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="body-2 font-weight-bold">
                {{ detail.so_code }}
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />


              <div
                class="d-flex align-center mb-4">
                <div
                  class="body-2">
                  Nominal
                </div>

                <v-spacer />

                <div
                  class="body-2 font-weight-bold black--text">
                  {{ detail.nominal | price }}
                </div>
              </div>

              <div
                class="d-flex align-center">
                <div
                  class="body-2">
                  Dikembalikan
                </div>

                <v-spacer />

                <div
                  class="body-2 font-weight-bold black--text">
                  {{ detail.nominal_return | price }}
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="d-flex align-center mb-4">
                <div
                  class="body-2">
                  Admin Pembuat Titipan
                </div>

                <v-spacer />

                <div
                  class="body-2 font-weight-bold black--text">
                  {{ detail.user_create_name }}
                </div>
              </div>

              <div
                class="d-flex align-center mb-4">
                <div
                  class="body-2">
                  Tanggal Dibuat
                </div>

                <v-spacer />

                <div
                  class="body-2 orange--text">
                  {{ detail.create_datetime | date }} Pukul {{ detail.create_datetime | time }}
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="d-flex align-center mb-4">
                <div
                  class="body-2">
                  Admin Penerima Titipan
                </div>

                <v-spacer />

                <div
                  class="body-2 font-weight-bold black--text">
                  {{ detail.user_update_name }}
                </div>
              </div>

              <div
                class="d-flex align-center">
                <div
                  class="body-2">
                  Tanggal Diterima
                </div>

                <v-spacer />

                <div
                  class="body-2 green--text">
                  {{ detail.update_datetime | date }} Pukul {{ detail.update_datetime | time }}
                </div>
              </div>
            </v-card-text>

            <v-card-text>
              <ValidationObserver
                ref="form">
                <v-form>
                  <ValidationProvider
                    name="Nominal Pengembalian"
                    rules="required|min:0"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Nominal Pengembalian
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save_return"
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                      min="0"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 25.000"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      @focus="$event.target.select()"

                      v-model="form.nominal_return"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="text-center my-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="150"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row
                align="center">
                <v-col
                  cols="3"
                  class="pb-0">
                  <v-img
                    contain
                    alt="bidanvitacare"
                    :src="require('@/assets/icon/money_send.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col
                  cols="9"
                  class="black--text pb-0">
                  <div>
                    <div
                      class="body-2 line-text-second black--text"
                      style="opacity: 60%;">
                      Masukkan data untuk pengembalian titipan pembayaran di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row
                class="mt-4">
                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save_return"
                    @click="save_return"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-check-bold
                      </v-icon>

                      Simpan
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    @click="sheet.form_return = false; reset();"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-close-thick
                      </v-icon>

                      Batal
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          filter: false
        },

        sheet: {
          form: false,
          detail: false,

          form_return: false,
        },

        search: '',

        filter: {
          store_id: ''
        },

        autocomplete: {
          transaction: '',
          terapis: ''
        },
        detail: {},

        store: [],

        list: {
          data: [],
          transaction: [],
          terapis: []
        },
        pagination: {
          data: {
            total_data: 0,
            total_page: 0
          },
          transaction: {},
          terapis: {}
        },

        limit: 10,

        form: {
          so_id: '',
          user_id: '',
          nominal: 0,

          // RETURN
          id: '',
          nominal_return: 0
        },
        process: {
          load: false,
          transaction: false,
          terapis: false,
          form: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'autocomplete.transaction': function() {
        this.fetchTransaction()
      },
      'autocomplete.terapis': function() {
        this.fetchTerapis()
      },

      'limit': function() {
        this.process.limit = true

        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetchStore () {
        let params = {
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/store`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.store = response.results.data
          }
        })
      },

      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit,
          store_id: this.filter.store_id
        }

        await this.$axios.$get(`${process.env.API}admin/therapist-cash`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list.data = response.results.data
            this.pagination.data = response.results.pagination
          }
        })
      },
      async fetchDetail (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/therapist-cash/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail = response.results.data
          }
        })
      },
      async fetchTransaction () {
        this.process.transaction = true

        let params = {
          search: this.autocomplete.transaction,
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/order`, { params })
        .then((response) => {
          this.process.transaction = false

          if (response.status === 200) {
            this.list.transaction = response.results.data
            this.pagination.transaction = response.results.pagination
          }
        })
      },
      async fetchTerapis () {
        this.process.terapis = true

        let params = {
          search: this.autocomplete.terapis,
          'role[neq]': 'superadmin',
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/user`, { params })
        .then((response) => {
          this.process.terapis = false

          if (response.status === 200) {
            this.list.terapis = response.results.data
            this.pagination.terapis = response.results.pagination
          }
        })
      },

      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          so_id: item !== undefined ? item.so_id : '',
          user_id: item !== undefined ? item.therapist_id : '',
          nominal: item !== undefined ? item.nominal : 0,

          // RETURN
          id: item !== undefined ? item.id : '',
          nominal_return: item !== undefined ? item.nominal : 0
        }

        this.process.form = false

        this.message.error = ''
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form.id === '' ? 'admin/therapist-cash/add' : 'admin/therapist-cash/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form = false

              if (this.form.id === '') {
                this.fetch()
              } else {
                this.fetchDetail(this.form.id)
              }
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },
      async save_return () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}admin/therapist-cash/give-back`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form_return = false

              this.fetchDetail(this.detail.id)
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>
