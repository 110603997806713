<template>
  <div class="fill-height pt-14">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.admin"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Admin
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-2 px-4 pb-2">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetchAdmin();"
                v-on:keyup.enter="fetchAdmin()"
                filled
                rounded
                dense
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data admin ?"
                clearable
                class="mb-2">
              </v-text-field>

              <div
                class="body-2 font-italic orange--text mt-4">
                *Jika tidak memilih admin / kasir, maka otomatis data Anda sebagai kasir yang mencetak nota.
              </div>

              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list.user_admin"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.user_admin.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list.user_admin.length > 0"
                          flat
                          dense
                          two-line
                          color="#FFFFFF40"
                          class="border-radius"
                          :style="form.cashier_id === item.id ? 'border: 2px dashed #ff8080;' : 'border: 1px dashed #bbb;'">
                          <v-list-item
                            @click="form.cashier_id = item.id; form.cashier_name = item.name;">
                            <v-list-item-avatar
                              size="40"
                              class="mt-0"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                {{ item.name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light text-capitalize line-text-second black--text"
                                style="opacity: 70%;">
                                {{ item.role }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="body-2">
                                Telp. {{ item.mobilephone }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <Empty v-if="!process.load && pagination.total_page < 1"
                margin="my-8"
                size="125"
                message="Admin" />
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <div v-if="message.error.length > 0"
              class="text-center mx-4 d-flex align-center">
              <div
                class="error--text">
                <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                <span
                  class="ml-1">
                  {{ message.error }}
                </span>
              </div>
            </div>

            <v-card-actions>

              <v-spacer/>

              <v-btn
                @click="save()"
                :loading="process.form"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.admin = false; form.cashier_id = ''; form.cashier_name = ''; message.error = '';"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.info"
          scrollable>
          <v-card v-if="Object.keys(form).length > 0"
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Info Order
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3">
              <v-row>
                <v-col
                  cols="4"
                  class="py-0 pb-1">
                  Tipe
                </v-col>

                <v-col
                  cols="8"
                  class="py-0 pb-1">
                  {{ form.type === 'on_site' ? 'On Site' : 'Home Care' }}
                </v-col>

                <v-col
                  cols="4"
                  class="py-0 pb-1">
                  Jml Item
                </v-col>

                <v-col
                  cols="8"
                  class="py-0 pb-1">
                  {{ form.items.length }}
                </v-col>

                <v-col
                  cols="4"
                  class="py-0 pb-1">
                  Alamat
                </v-col>

                <v-col
                  cols="8"
                  class="py-0 pb-1">
                  {{ Object.keys(selected.address).length > 0 ? selected.address.address_full : '-' }}
                </v-col>

                <v-col
                  cols="4"
                  class="py-0 pb-1">
                  Transport
                </v-col>

                <v-col
                  cols="8"
                  class="py-0 pb-1">
                  {{ form.transport_qty }} x {{ form.transport_cost | price }} = {{ form.transport_total_cost | price }}
                </v-col>

                <v-col
                  cols="4"
                  class="py-0">
                  Diskon
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  <span v-if="form.discount_percent > 0">
                    {{ form.discount_percent }}%
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="#FFFFFF60"
            style="border-radius: 0px;">
            <v-list
              flat
              dense
              color="transparent"
              class="pb-0">
              <v-list-item>
                <v-list-item-avatar
                  size="40"
                  style="border: 2px solid #ffffff; overflow: unset;">
                  <v-img
                    width="100%"
                    height="100%"
                    alt="bidanvitacare"
                    :src="Object.keys(selected.pelanggan).length > 0 && selected.pelanggan.image_url !== '' ? selected.pelanggan.image_url : require('@/assets/image/member_default.jpg')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="20"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    class="body-2 font-weight-bold text-capitalize mb-2">
                    <!-- {{ Object.keys(selected.pelanggan).length > 0 ? selected.pelanggan.name : 'Umum' }} -->
                    {{ Object.keys(selected.pelanggan).length > 0 ? selected.pelanggan.name : 'Customer' }}
                  </v-list-item-title>

                  <v-list-item-subtitle
                    class="body-2 font-weight-light black--text">
                    {{ Object.keys(selected.pelanggan).length > 0 ? selected.pelanggan.mobilephone : 'Tidak ada informasi kontak' }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon
                    @click="dialog.info = true"
                    color="blue"
                    class="cursor-pointer">
                    mdi-information
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-card-text>
              <v-card
                flat
                color="#f9dee2"
                class="border-radius">
                <v-card-text
                  class="d-flex align-center">
                  <div
                    class="body-1 font-weight-bold">
                    Total Bayar
                  </div>

                  <v-spacer />

                  <div
                    class="title font-weight-bold black--text">
                    {{ form.amount_paid_sum | price }}
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>

          <v-card
            flat
            height="calc(100vh - 290px)"
            color="transparent"
            class="border-radius">
            <v-card-text>
              <ValidationObserver
                ref="form">
                <v-form>
                  <v-row
                    align="center">
                    <v-col
                      :cols="form.amount_paid > 0 ? 8 : 9">
                      <ValidationProvider
                        name="amount_paid"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          Jumlah Bayar
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 10.000"
                          :append-icon="parseInt(form.amount_paid) > 0 && parseInt(form.amount_paid) >= parseInt(form.amount_paid_sum) && valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"
                          @keyup="form.amount_paid = form.amount_paid === '' || parseInt(form.amount_paid) < 1 ? 0 : parseInt(form.amount_paid)"
                          @blur="form.amount_paid = form.amount_paid === '' || parseInt(form.amount_paid) < 1 ? 0 : parseInt(form.amount_paid)"

                          v-model="form.amount_paid"
                          :success="parseInt(form.amount_paid) > 0 && parseInt(form.amount_paid) >= parseInt(form.amount_paid_sum) && valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col
                      :cols="form.amount_paid > 0 ? 4 : 3"
                      class="d-flex align-center justify-center">
                      <div
                        @click="form.amount_paid = parseInt(form.amount_paid_sum)"
                        class="body-1 font-weight-bold cursor-pointer">
                        Sama
                      </div>

                      <div v-if="form.amount_paid > 0"
                        @click="form.amount_paid = 0"
                        class="body-1 font-weight-bold ml-3 red--text cursor-pointer">
                        Hapus
                      </div>
                    </v-col>
                  </v-row>

                  <div class="body-2 mb-2">
                    Atau pilih pecahan dibawah ini:
                  </div>

                  <v-row>
                    <v-col
                      v-for="(item, index) in list.money"
                      :key="index"
                      cols="6">
                      <v-btn
                        @click="form.amount_paid += item"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_auth"
                        :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                        <div
                          class="d-flex align-center justify-center btn_auth_in"
                          :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                          {{ item | price }}
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <!-- <div v-if="message.error.length > 0"
                    class="text-center my-4 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div> -->
                </v-form>
              </ValidationObserver>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="50"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row>
                <v-col
                  cols="7"
                  class="py-0">
                  <!-- <v-btn
                    @click="save()"
                    :disabled="process.form"
                    :loading="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in d-flex align-center justify-center">
                      <v-icon color="white">mdi-check-circle</v-icon>

                      <span
                        class="ml-2">
                        Simpan Transaksi
                      </span>
                    </div>
                  </v-btn> -->

                  <v-btn
                    @click="dialog.admin = true; fetchAdmin();"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in d-flex align-center justify-center">
                      <v-icon color="white">mdi-check-circle</v-icon>

                      <span
                        class="ml-2">
                        Simpan Transaksi
                      </span>
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  cols="5"
                  class="py-0">
                  <v-btn
                    to="/payment"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-arrow-left-circle
                      </v-icon>

                      <span
                        class="ml-8">
                        Kembali
                      </span>
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          admin: false,
          info: false
        },
        search: '',
        list: {
          money: [1000, 5000, 10000, 20000, 50000, 100000],
          user_admin: []
        },
        pagination: {},
        selected: {
          pelanggan: {},
          address: {}
        },
        form: {
          id: '',
          type: 'on_site',
          customer_id: '',
          customer_address_id: '',
          note: '',
          items: [],
          discount_percent: 0,
          discount_value: 0,
          tax_percent: 0,
          tax_value: 0,

          transport_id: '',
          transport_name: '',
          transport_cost: 0,
          transport_qty: 1,
          transport_total_cost: 0,

          dp_paid: 0,

          amount_paid_origin: 0,
          amount_paid_sum: 0,
          amount_paid: 0,
          status: 'complete',
          // payment_method: '',
          // payment_method_bank_name: '',
          // payment_method_ref: '',

          payment: [],

          switch_saldo: false,

          total_days: 0,

          // KASIR
          cashier_id: 0,
          cashier_name: '',

          // DOWN PAYMENT
          switch_dp: false,

          dp: 0,
          dp_payment_method: '',
          dp_payment_method_bank_name: '',
          dp_payment_method_ref: ''
        },
        process: {
          load: false,
          form: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/pembayaran',
          title: 'Pembayaran',
          description: 'Pembayaran Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'form.amount_paid': function(val) {
        this.form.amount_paid = val === '' ? 0 : parseInt(val)

        this.form.payment[0].amount = this.form.amount_paid
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      kasir () {
        return this.$store.state.kasir
      }
    },
    mounted () {
      this.form = this.kasir.kasir
      this.selected.pelanggan = this.kasir.pelanggan
      this.selected.address = this.kasir.address !== undefined ? this.kasir.address : {}
    },
    methods: {
      async fetchAdmin () {
        this.process.load = true

        let params = {
          search: this.search,
          'role[eq]': 'admin'
        }

        await this.$axios.$get(`${process.env.API}admin/user`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list.user_admin = response.results.data
            this.pagination = response.results.pagination

            this.form.cashier_id = this.user.id,
            this.form.cashier_name = this.user.name
          }
        })
      },

      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form.id === '' ? 'admin/order/add' : 'admin/order/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              let forms = {
                id: '',
                type: 'on_site',
                customer_id: '',
                customer_address_id: '',
                note: '',
                items: [],
                discount_percent: 0,
                discount_value: 0,
                tax_percent: 0,
                tax_value: 0,

                transport_id: '',
                transport_name: '',
                transport_cost: 0,
                transport_qty: 1,
                transport_total_cost: 0,

                dp_paid: 0,

                amount_paid_origin: 0,
                amount_paid_sum: 0,
                amount_paid: 0,
                status: 'complete',
                // payment_method: '',
                // payment_method_bank_name: '',
                // payment_method_ref: '',

                payment: [],

                switch_saldo: false,

                total_days: 0,

                // KASIR
                cashier_id: 0,
                cashier_name: '',

                // DOWN PAYMENT
                switch_dp: false,

                dp: 0,
                dp_payment_method: '',
                dp_payment_method_bank_name: '',
                dp_payment_method_ref: ''
              }

              this.$store.commit('kasir/setKasir', forms)
              Cookie.set('kasir', forms)

              this.$store.commit('kasir/setPelanggan', {})
              Cookie.set('pelanggan', {})

              this.$store.commit('kasir/setAddress', {})
              Cookie.set('address', {})

              this.$store.commit('kasir/setPayment', {})
              Cookie.set('payment', {})

              this.$router.push({ path: '/kasir' })

              window.open(`/invoice/${response.results.data.so_uuid}`, '_blank')
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>
