<template>
  <div>
    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text
          class="py-0">
          <div
            class="d-flex align-center">
            <div
              class="body-2 black--text font-weight-bold">
              Lampiran
            </div>

            <v-spacer />

            <div>
              <v-btn
                @click="sheet.form_attachment = true; reset_attachment();"
                :disabled="status_required.absen || status_required.absen_detail || status_required.is_done_bool"
                small
                rounded
                :color="set_color"
                elevation="0"
                class="text-capitalize d-flex align-center white--text">
                <v-icon small>mdi-plus-circle-outline</v-icon>

                <span
                  class="ml-1">
                  Tambah
                </span>
              </v-btn>
            </div>
          </div>
        </v-card-text>

        <v-card-text
          class="pb-0">
          <v-row>
            <v-col
              v-for="(item, index) in form_attachment.dokumentasi_json_array"
              :key="index"
              cols="6">
              <v-card
                flat
                width="100%"
                height="200"
                class="d-flex align-center justify-center"
                style="border-radius: 15px; border: 5px solid #0000000d; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
                <v-img
                  alt="bidanvitacare"
                  width="100%"
                  height="190"
                  :src="item"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </v-col>
          </v-row>

          <div v-if="form_attachment.dokumentasi_json_array.length < 1"
            class="body-2 pa-4"
            style="border: 2px dashed #bbb; border-radius: 10px;">
            Silahkan unggah data lampiran treatment
          </div>
        </v-card-text>
      </v-card>
    </section>

    <v-bottom-sheet
      v-model="sheet.form_attachment"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.form_attachment = false; reset_attachment();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.form_attachment = false; reset_attachment();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Tambah
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 205px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="pt-16">
              <v-row>
                <v-col
                  v-for="(item, index) in form_attachment.dokumentasi_json_array"
                  :key="index"
                  cols="6">
                  <v-card
                    flat
                    width="100%"
                    height="200"
                    class="d-flex align-center justify-center"
                    style="border-radius: 15px; border: 5px solid #0000000d; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
                    <v-img
                      alt="bidanvitacare"
                      width="100%"
                      height="190"
                      :src="item"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>

                      <div
                        @click="form_attachment.dokumentasi_json_array.splice(index, 1)"
                        class="cursor-pointer"
                        style="position: absolute; top: 5px; right: 5px;">
                        <v-icon size="24" color="red">mdi-close-circle</v-icon>
                      </div>
                    </v-img>
                  </v-card>
                </v-col>

                <v-col
                  cols="6">
                  <upload-folder
                    v-model="upload.folder_attachment">
                    <div
                      slot="activator"
                      class="cursor-pointer">
                      <v-card
                        flat
                        width="100%"
                        height="200"
                        class="d-flex align-center justify-center"
                        style="border-radius: 15px; border: 5px solid #0000000d;">
                        <v-icon v-if="!process.file"
                          size="50"
                          color="grey">
                          mdi-plus
                        </v-icon>

                        <v-progress-circular v-else
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-card>
                    </div>
                  </upload-folder>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text v-if="message.error.length > 0">
              <div
                class="text-center mt-3 mb-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="150"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row
                align="center">
                <v-col
                  cols="3"
                  class="pb-0">
                  <v-img
                    contain
                    alt="bidanvitacare"
                    :src="require('@/assets/icon/attachment.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col
                  cols="9"
                  class="black--text pb-0">
                  <div>
                    <div
                      class="body-2 line-text-second black--text"
                      style="opacity: 60%;">
                      Masukkan data Lampiran untuk rekam kunjungan di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row
                class="mt-4">
                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    v-on:keyup.enter="save_attachment()"
                    @click="save_attachment()"
                    :disabled="process.form"
                    :loading="process.form"

                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-check-bold
                      </v-icon>

                      Simpan
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    @click="sheet.form_attachment = false; reset_attachment(); $emit('load');"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-close-thick
                      </v-icon>

                      Batal
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      },
      status_required: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        sheet: {
          form_attachment: false
        },

        upload: {
          folder_attachment: null
        },

        form_attachment: {
          visit_id: '',
          dokumentasi_json_array: []
        },

        process: {
          form: false,
          file: false
        },

        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue')
    },
    watch: {
      'upload.folder_attachment': {
        handler: function () {
          if (this.upload.folder_attachment) {
            this.process.file = this.upload.folder_attachment.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form_attachment.dokumentasi_json_array.push(this.upload.folder_attachment.fileuri)
            }
          }
        },
        deep: true
      },

      'detail': {
        handler: function () {
          this.reset_attachment()
        },
        deep: true
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.reset_attachment()
    },
    methods: {
      reset_attachment () {
        if (this.$refs.form_attachment) this.$refs.form_attachment.reset()

        this.form_attachment = {
          visit_id: this.detail.id,
          dokumentasi_json_array: this.detail.dokumentasi_json_array
        }

        this.process.form = false

        this.message.error = ''
      },

      async save_attachment () {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}therapist/schedule_v3/lampiran/update`, this.form_attachment)
        .then((response) => {

          this.process.form = false

          if (response.status === 200) {
            this.sheet.form_attachment = false

            this.$emit('load')
          } else {
            this.message.error = response.message
          }
        })
      }
    }
  }
</script>
