<template>
  <div>
    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text
          class="pb-0 mt-1">
          <v-text-field
            v-model="search_data"
            @click:clear="search_data = ''; fetch();"
            v-on:keyup.enter="fetch()"
            filled
            rounded
            dense
            single-line
            hide-details
            :color="set_color"
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari data yang Anda butuhkan ?"
            clearable>
          </v-text-field>
        </v-card-text>

        <v-card-text
          class="pb-0">
          <v-row>
            <v-col
              cols="12">
              <v-skeleton-loader
                v-for="(item, index) in process.load ? 4 : list_data"
                :key="index"
                :loading="process.load"
                :class="index < list_data.length - 1 ? 'mb-4' : ''"
                type="list-item-avatar-three-line">
                <template>
                  <div>
                    <v-card v-if="!process.load && list_data.length > 0"
                      @click="sheet.detail = true; fetchDetail(item.id);"
                      flat
                      color="transparent">
                      <div
                        class="d-flex align-center">
                        <div
                          class="font-weight-bold black--text">
                          <span v-if="item.datetime !== ''">
                            {{ item.datetime | date }}
                          </span>

                          <span v-else>
                            Tanggal belum ditentukan
                          </span>
                        </div>

                        <v-spacer />

                        <div v-if="item.datetime !== ''"
                          class="font-weight-bold">
                          {{ item.datetime | time }}
                        </div>
                      </div>

                      <div>
                        <v-divider
                          class="my-2"
                          style="border-top: 1px dashed #bbb;" />
                      </div>

                      <div
                        class="d-flex align-center mb-1">
                        <div
                          class="d-flex align-center">
                          <v-avatar
                            size="22"
                            style="border: 2px solid #ffffff;">
                            <v-img
                              width="100%"
                              height="100%"
                              alt="bidanvitacare"
                              :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>

                          <span
                            class="ml-1">
                            {{ item.customer_name }}
                          </span>
                        </div>

                        <v-spacer />

                        <div>
                          {{ item.store_name }}
                        </div>
                      </div>

                      <div>
                        <v-divider
                          class="my-2"
                          style="border-top: 1px dashed #bbb;" />
                      </div>

                      <div
                        class="mt-3 mb-2 pa-3 pt-2"
                        style="border: 2px dashed #bbb; border-radius: 10px;">
                        <v-chip
                          x-small
                          class="white--text"
                          :color="item.type === 'on_site' ? 'blue' : 'green'"
                          style="font-weight: 500;">
                          {{ item.type === 'on_site' ? 'On Site' : 'Home Care' }}
                        </v-chip>

                        <div
                          v-for="(sub_item, sub_index) in item.details"
                          :key="sub_index">
                          <div
                            class="d-flex align-start my-1">
                            <v-avatar
                              tile
                              size="22"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="sub_item.item_image_url !== '' ? sub_item.item_image_url : require('@/assets/image/image_default.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <span
                              class="ml-1">
                              {{ sub_item.item_name }}
                            </span>
                          </div>

                          <div
                            class="d-flex align-center"
                            style="margin-left: 26px;">
                            <div>
                              Durasi. <strong v-if="sub_item.type !== 'product'">{{ sub_item.duration_minutes }} Menit</strong> <strong v-else>-</strong>
                            </div>

                            <v-spacer />

                            <div>
                              Qty. <strong>{{ sub_item.qty }}x</strong>
                            </div>

                            <v-spacer />

                            <div>
                              {{ sub_item.therapist_shift }}
                            </div>
                          </div>

                          <v-divider v-if="sub_index < item.details.length - 1"
                            class="my-2"
                            style="border-top: 1px dashed #bbb;" />
                        </div>
                      </div>

                      <v-row>
                        <v-col
                          cols="12"
                          class="pt-1">
                          <v-card
                            flat
                            color="#FFFFFF40"
                            class="pa-3"
                            style="border: 2px dashed #bbb; border-radius: 10px;">
                            <div
                              class="d-flex align-center">
                              <div>
                                Klien
                              </div>

                              <v-spacer />

                              <div v-if="item.client_name !== ''"
                                class="d-flex align-center">
                                <v-avatar
                                  size="22"
                                  style="border: 2px solid #ffffff;">
                                  <v-img
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare"
                                    :src="item.client_image_url !== '' ? item.client_image_url : require('@/assets/image/member_default.jpg')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          size="20"
                                          indeterminate
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>

                                <span
                                  class="ml-1">
                                  {{ item.client_name }}
                                </span>
                              </div>

                              <div v-else>
                                -
                              </div>
                            </div>

                            <div v-if="item.address_link_map !== ''">
                              <v-divider
                                class="my-3"
                                style="border-top: 1px dashed #bbb;" />
                            </div>

                            <div v-if="item.address_link_map !== ''"
                              class="d-flex align-center">
                              <div>
                                Alamat
                              </div>

                              <v-spacer />

                              <div>
                                <a
                                  :href="item.address_link_map"
                                  target="_blank">
                                  Lihat Lokasi
                                </a>
                              </div>
                            </div>

                            <div v-if="item.type === 'home_care'">
                              <v-divider
                                class="my-3"
                                style="border-top: 1px dashed #bbb;" />
                            </div>

                            <div v-if="item.type === 'home_care'">
                              <div v-if="item.transport_id !== '' && item.transport_id !== '0'"
                                class="d-flex align-center">
                                <div>
                                  Transport ({{ item.transport_qty }} x {{ Number(item.transport_cost) | price }})
                                </div>

                                <v-spacer />

                                <div class="font-weight-bold">
                                  {{ Number(item.transport_total_cost) | price }}
                                </div>
                              </div>

                              <div v-else
                                class="d-flex align-center">
                                <div>
                                  Transport
                                </div>

                                <v-spacer />

                                <div class="font-weight-bold">
                                  -
                                </div>
                              </div>
                            </div>

                            <div>
                              <v-divider
                                class="my-3"
                                style="border-top: 1px dashed #bbb;" />
                            </div>

                            <div
                              class="d-flex align-center">
                              <div>
                                Penugasan
                              </div>

                              <v-spacer />

                              <div class="text-uppercase font-weight-bold">
                                {{ !item.is_therapist_partner_bool ? 'Single' : 'Partner' }}
                              </div>
                            </div>

                            <div>
                              <v-divider
                                class="my-3"
                                style="border-top: 1px dashed #bbb;" />
                            </div>

                            <div
                              class="d-flex align-center">
                              <div>
                                Down Payment
                              </div>

                              <v-spacer />

                              <div class="text-uppercase font-weight-bold">
                                <span v-if="Number(item.dp) > 0">
                                  {{ Number(item.dp) | price }}
                                </span>

                                <span v-else>
                                  -
                                </span>
                              </div>
                            </div>

                            <div>
                              <v-divider
                                class="my-3"
                                style="border-top: 1px dashed #bbb;" />
                            </div>

                            <div
                              class="d-flex align-center">
                              <div>
                                Diinput Oleh
                              </div>

                              <v-spacer />

                              <div>
                                {{ item.create_user_name }}
                              </div>
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </template>
              </v-skeleton-loader>
            </v-col>
          </v-row>

          <div v-if="!process.load && pagination_data.total_data > 10 && limit_data < pagination_data.total_data"
            class="text-center my-4">
            <v-btn
              @click="limit_data += 10"
              :loading="process.limit"
              small
              rounded
              outlined
              elevation="0"
              :color="set_color"
              class="text-capitalize"
              :style="`color: ${set_color};`">
              Lebih banyak
            </v-btn>
          </div>

          <Empty v-if="!process.load && pagination_data.total_page < 1"
            class="py-6"
            margin="my-16"
            size="125"
            message="Jadwal" />
        </v-card-text>
      </v-card>
    </section>

    <!-- detail jadwal -->
    <v-bottom-sheet
      v-model="sheet.detail"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card v-if="Object.keys(detail).length > 0"
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.detail = false; detail = {}; fetch();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.detail = false; detail = {}; fetch();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Kunjungan {{ detail.customer_name }} - {{ detail.client_name }}
            </span>
          </v-card-title>

          <v-card v-if="Object.keys(detail).length > 0"
            flat
            class="pt-16"
            height="calc(100vh - 0px)"
            style="overflow: auto;"
            color="transparent">
            <Informasi
              :detail="detail"
              :status_required="status_required"
              @load="fetchDetail(detail.id)" />

            <v-divider
              class="mt-2 mb-4"
              style="border-top: 1px dashed #bbb;" />

            <Lampiran
              :detail="detail"
              :status_required="status_required"
              @load="fetchDetail(detail.id)" />

            <v-divider
              class="mb-5"
              :class="detail.dokumentasi_json_array.length > 0 ? 'mt-2' : 'mt-5'"
              style="border-top: 1px dashed #bbb;" />

            <Antropometri
              :detail="detail"
              :status_required="status_required"
              @load="fetchDetail(detail.id)" />

            <v-divider v-if="detail.kpsp.length > 0"
              class="mb-5"
              style="border-top: 1px dashed #bbb;" />

            <Kpsp v-if="detail.kpsp.length > 0"
              :detail="detail"
              :status_required="status_required"
              @load="fetchDetail(detail.id)" />

            <v-divider
              class="mt-2 mb-5"
              style="border-top: 1px dashed #bbb;" />

            <Laporan
              :detail="detail"
              :status_required="status_required"
              @load="fetchDetail(detail.id)"
              @close="sheet.detail = false" />
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        dialog: {
          go_back: false
        },

        sheet: {
          detail: false,
        },

        search_data: '',

        detail: {},

        list_data: [],
        pagination_data: {},
        limit_data: 10,

        status_required: {
          absen: false,
          absen_detail: false,
          lampiran: false,
          is_done_bool: false
        },

        process: {
          load: false
        },

        message: {
          success: '',
          error: '',
          errors: []
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty'),

      Informasi: () => import(/* webpackPrefetch: true */ '@/components/penjadwalan/new/step/informasi'),
      Lampiran: () => import(/* webpackPrefetch: true */ '@/components/penjadwalan/new/step/lampiran'),
      Antropometri: () => import(/* webpackPrefetch: true */ '@/components/penjadwalan/new/step/antropometri'),
      Kpsp: () => import(/* webpackPrefetch: true */ '@/components/penjadwalan/new/step/kpsp'),
      Laporan: () => import(/* webpackPrefetch: true */ '@/components/penjadwalan/new/step/laporan')
    },
    watch: {
      'limit_data': function() {
        this.process.limit = true

        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()

      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)

          t.dialog.go_back = true
        })
      },

      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search_data,
          limit: this.limit_data
        }

        await this.$axios.$get(`${process.env.API}therapist/schedule_v3/data_visit_today`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list_data = response.results.data
            this.pagination_data = response.results.pagination
          }
        })
      },

      async fetchDetail (id) {
        this.process.load = true

        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}therapist/schedule_v3/data_visit_detail`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.detail = response.results.data

            if (Object.keys(this.detail).length > 0) this.required_form()
          }
        })
      },

      checkItem () {
        let is_exist = false

        for (let i = 0; i < this.detail.details.length; i++) {
          if (this.detail.details[i].absen_selesai_datetime === '') {
            is_exist = true

            break
          }
        }

        return is_exist
      },

      required_form () {
        if (this.absen_berangkat_datetim === '' && this.detail.type === 'home_care') {
          this.status_required.absen = true
        } else {
          this.status_required.absen = false
        }

        this.status_required.absen_detail = this.checkItem()

        if (this.detail.dokumentasi_json_array.length < 1) {
          this.status_required.lampiran = true
        } else {
          this.status_required.lampiran = false
        }

        // this.status_required.is_done_bool = this.detail.is_done_bool
      }
    }
  }
</script>
