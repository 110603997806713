<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.deletes"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/hapus_info.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin menghapus data
                </div>

                <div>
                  <strong>{{ detail.name }}</strong>?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="deletes()"
                :loading="process.form"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Saya yakin
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.deletes = false"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.go_back"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin akan keluar
                </div>

                <div>
                  dari halaman ini?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                to="/home"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Keluar
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.go_back = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <!-- <div>
              <div v-if="process.load || list.length > 0"
                class="body-2 font-weight-bold px-4 my-2">
                Bahan yang sering digunakan
              </div>

              <no-ssr>
                <swiper
                  class="swiper"
                  ref="swiper"
                  :options="{ slidesPerView: 'auto' }">
                  <swiper-slide
                    v-for="(item, index) in process.load ? 5 : list"
                    :key="index"
                    class="py-3 loader_produk"
                    :class="index === 0 ? 'pl-4 pr-4' : index < list.length - 1 ? 'pr-4' : 'pr-4'"
                    style="max-width: 75px;">
                    <v-skeleton-loader
                      :loading="process.load"
                      type="image">
                      <div>
                        <v-card v-if="!process.load && list.length > 0"
                          flat
                          color="transparent"
                          class="border-radius">
                          <v-img
                            contain
                            width="75"
                            height="75"
                            alt="bidanvitacare"
                            :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                            class="ma-auto border-radius"
                            style="border: 2px solid #ffffff; border-radius: 10px !important;">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>
                      </div>
                    </v-skeleton-loader>
                  </swiper-slide>
                </swiper>
              </no-ssr>
            </div> -->

            <v-card-text
              :class="process.load || list.length > 0 ? 'mt-1' : ''">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                filled
                rounded
                dense
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-btn
                @click="sheet.form = true; reset();"
                block
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
                :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                <div
                  class="btn_auth_in d-flex align-center justify-center"
                  :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                  <div>
                    <v-img
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/add_plus.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <div
                    class="ml-2">
                    Tambah Bahan & Alat
                  </div>
                </div>
              </v-btn>
            </v-card-text>

            <v-card-text
              class="pb-0">
              <div
                class="body-2 font-weight-bold black--text d-flex align-center mb-2">
                <div>
                  Katalog Bahan & Alat
                </div>

                <v-spacer />

                <div>
                  <!-- <v-icon>mdi-filter-variant</v-icon> -->
                </div>
              </div>

              <v-row>
                <v-col>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list.length > 0"
                          flat
                          dense
                          three-line
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-list-item
                            @click="sheet.detail = true; fetchDetail(item.id);">
                            <v-list-item-avatar
                              size="40"
                              tile
                              class="mt-2"
                              style="border: 2px solid #ffffff; border-radius: 5px !important;">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                {{ item.name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light line-text-second black--text"
                                style="opacity: 70%;">
                                Stok. {{ item.sum_stock }}
                              </v-list-item-subtitle>

                              <!-- <v-list-item-subtitle
                                class="body-2">
                                <span
                                  :class="item.special_price > 0 ? 'text-decoration-line-through red--text' : 'font-weight-bold'">
                                  {{ item.retail_price | price }}
                                </span>

                                <span v-if="item.special_price > 0"
                                  class="font-weight-bold ml-2">
                                  {{ item.special_price | price }}
                                </span>
                              </v-list-item-subtitle> -->

                              <div
                                class="pa-3 mt-2"
                                style="border: 2px dashed #bbb; border-radius: 10px;">
                                <ul v-if="item.composition_json_array.length > 0"
                                  class="body-2 font-weight-light black--text"
                                  style="position: relative; left: -10px;">
                                  <li
                                    v-for="(item_alat, index_alat) in item.composition_json_array"
                                    :key="index_alat"
                                    :class="index_alat < item.composition_json_array.length - 1 ? 'mb-4' : ''">
                                    <div
                                      class="mb-2">
                                      {{ item_alat.name }}
                                    </div>

                                    <div
                                      v-for="(item_stok, index_stok) in item_alat.stock"
                                      :key="index_stok">
                                      <div
                                        class="font-weight-bold mb-1"
                                        style="opacity: 60%;">
                                        {{ item_stok.store_name }}
                                      </div>

                                      <div
                                        class="d-flex align-center">
                                        <div>
                                          <div
                                            class="caption"
                                            style="opacity: 60%;">
                                            Stok
                                          </div>

                                          <div>
                                            {{ item_stok.stock }}
                                          </div>
                                        </div>

                                        <v-spacer />

                                        <div>
                                          <div
                                            class="caption"
                                            style="opacity: 60%;">
                                            Minimal Stok
                                          </div>

                                          <div>
                                            {{ item_stok.stock_min }}
                                          </div>
                                        </div>
                                      </div>

                                      <v-divider
                                        v-if="index_stok < item_alat.stock.length - 1"
                                        class="my-3"
                                        style="border-top: 1px dashed #bbb;" />
                                    </div>
                                  </li>
                                </ul>

                                <div v-else>
                                  Tidak ada kelengkapan alat
                                </div>
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Bahan" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.detail"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.detail = false; detail = {}; fetch();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.detail = false; detail = {}; fetch();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Bahan {{ detail.name }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 80px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-4">
                  <v-avatar
                    tile
                    size="150">
                    <v-img
                      width="100%"
                      height="100%"
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
                            border: 5px solid #0000000d;
                            border-radius: 10px;"
                      alt="bidanvitacare"
                      :src="detail.image_url !== '' ? detail.image_url : require('@/assets/image/image_default.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                </v-card-text>

                <v-card-text>
                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Nama Bahan & Alat
                    </div>

                    <div
                      class="body-2 font-weight-bold black--text">
                      {{ detail.name }}
                    </div>
                  </div>

                  <!-- <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Harga Jual
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail.retail_price | price }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Harga Diskon
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail.special_price | price }}
                    </div>
                  </div> -->

                  <div
                    v-for="(item, index) in detail.store"
                    :key="index">
                    <v-divider
                      class="mb-4"
                      style="border-top: 1px dashed #bbb;" />

                    <div
                      class="font-weight-bold mb-3">
                      <!-- {{ item.store_name === 'Pusat' ? '' : 'Cabang' }} {{ item.store_name }} -->
                      {{ item.store_name }}
                    </div>

                    <div
                      class="mb-4">
                      <div
                        class="caption mb-1"
                        style="opacity: 60%;">
                        Stok
                      </div>

                      <div
                        class="body-2 black--text">
                        {{ item.stock }}
                      </div>
                    </div>

                    <div
                      class="mb-4">
                      <div
                        class="caption mb-1"
                        style="opacity: 60%;">
                        Minimal Stok
                      </div>

                      <div
                        class="body-2 black--text">
                        {{ item.stock_min }}
                      </div>
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Kelengkapan Alat
                    </div>

                    <div
                      class="pa-3 mt-2"
                      style="border: 2px dashed #bbb; border-radius: 10px;">
                      <ul v-if="detail.composition_json_array.length > 0"
                        class="body-2 font-weight-light black--text"
                        style="position: relative; left: -10px;">
                        <li
                          v-for="(item_alat, index_alat) in detail.composition_json_array"
                          :key="index_alat"
                          :class="index_alat < detail.composition_json_array.length - 1 ? 'mb-4' : ''">
                          <div
                            class="mb-2">
                            {{ item_alat.name }}
                          </div>

                          <div
                            v-for="(item_stok, index_stok) in item_alat.stock"
                            :key="index_stok">
                            <div
                              class="font-weight-bold mb-1"
                              style="opacity: 60%;">
                              {{ item_stok.store_name }}
                            </div>

                            <div
                              class="d-flex align-center">
                              <div>
                                <div
                                  class="caption"
                                  style="opacity: 60%;">
                                  Stok
                                </div>

                                <div>
                                  {{ item_stok.stock }}
                                </div>
                              </div>

                              <v-spacer />

                              <div>
                                <div
                                  class="caption"
                                  style="opacity: 60%;">
                                  Minimal Stok
                                </div>

                                <div>
                                  {{ item_stok.stock_min }}
                                </div>
                              </div>
                            </div>

                            <v-divider
                              v-if="index_stok < item_alat.stock.length - 1"
                              class="my-3"
                              style="border-top: 1px dashed #bbb;" />
                          </div>
                        </li>
                      </ul>

                      <div v-else>
                        Tidak ada kelengkapan alat
                      </div>
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Keterangan
                    </div>

                    <div
                      class="body-2 black--text">
                      <span v-if="detail.description !== ''"
                        v-html="detail.description">
                      </span>

                      <span v-else>
                        -
                      </span>
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Dibuat Tanggal
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail.create_datetime | date }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="50"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="6"
                      class="py-0">
                      <v-btn
                        @click="sheet.form = true; reset(detail);"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/edit_pencil.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="blue--text ml-2">
                            Ubah
                          </div>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="py-0">
                      <v-btn
                        @click="dialog.deletes = true"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/hapus.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="red--text ml-2">
                            Hapus
                          </div>
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.form"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.form = false; reset(); fetch();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.form = false; reset(); fetch();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  {{ form.id === '' ? 'Tambah' : 'Ubah' }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="d-flex align-center pt-16 mt-4">
                  <v-avatar
                    tile
                    size="150">
                    <v-img
                      width="100%"
                      height="100%"
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
                            border: 5px solid #0000000d;
                            border-radius: 10px;"
                      alt="bidanvitacare"
                      :src="form.image_url !== '' ? form.image_url : require('@/assets/image/image_default.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>

                  <div>
                    <upload-folder
                      v-model="upload.folder">
                      <div slot="activator">
                        <v-btn
                          :disabled="process.file"
                          :loading="process.file"
                          elevation="0"
                          rounded
                          block
                          large
                          color="#0000000d"
                          class="body-1 font-weight-bold text-capitalize d-flex align-center"
                          style="border-radius: 0px 50px 50px 0px;"
                          :style="`color: ${set_color};`">
                          <v-icon
                            size="24"
                            :color="set_color">
                            mdi-camera
                          </v-icon>

                          <span
                            class="ml-1">
                            Gambar
                          </span>
                        </v-btn>
                      </div>
                    </upload-folder>
                  </div>
                </v-card-text>

                <v-card-text>
                  <ValidationObserver
                    ref="form">
                    <v-form>
                      <ValidationProvider
                        name="Nama Bahan & Alat"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Nama Bahan & Alat
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Nebulizer"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.name"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider v-if="form.type === 'service'"
                        name="Durasi Layanan /menit"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Durasi Layanan /menit
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 30"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.duration_minutes"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <!-- <ValidationProvider
                        name="Harga Jual"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Harga Jual
                          <span class="error--text">
                            (wajib diisi)
                          </span>
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 50.000"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.retail_price"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider> -->

                      <!-- <ValidationProvider
                        name="Harga Diskon"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Harga Diskon
                          <span class="error--text">
                            (wajib diisi)
                          </span>
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 25.000"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.special_price"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider> -->

                      <!-- <ValidationProvider
                        name="Stok"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Stok
                          <span class="error--text">
                            (wajib diisi)
                          </span>
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 27"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.stock"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Minimal Stok"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Minimal Stok
                          <span class="error--text">
                            (wajib diisi)
                          </span>
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 12"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.stock_min"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider> -->

                      <div
                        v-for="(item, index) in form.store"
                        :key="index">
                        <v-divider
                          class="mb-4"
                          style="border-top: 1px dashed #bbb;" />

                        <div
                          class="font-weight-bold mb-3">
                          <!-- {{ item.store_name === 'Pusat' ? '' : 'Cabang' }} {{ item.store_name }} -->
                          {{ item.store_name }}
                        </div>

                        <ValidationProvider
                          name="Stok"
                          rules="required"
                          v-slot="{ errors, valid }">
                          <div class="body-2 mb-2">
                            <span class="red--text">*</span>
                            Stok
                            <!-- <span class="error--text">
                              (wajib diisi)
                            </span> -->
                          </div>

                          <v-text-field
                            v-on:keyup.enter="save"
                            type="number"

                            filled
                            rounded
                            dense
                            :color="set_color"
                            placeholder="Contoh. 27"
                            :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                            @focus="$event.target.select()"

                            v-model="item.stock"
                            :success="valid"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>

                        <ValidationProvider
                          name="Minimal Stok"
                          rules="required"
                          v-slot="{ errors, valid }">
                          <div class="body-2 mb-2">
                            <span class="red--text">*</span>
                            Minimal Stok
                            <!-- <span class="error--text">
                              (wajib diisi)
                            </span> -->
                          </div>

                          <v-text-field
                            v-on:keyup.enter="save"
                            type="number"

                            filled
                            rounded
                            dense
                            :color="set_color"
                            placeholder="Contoh. 12"
                            :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                            @focus="$event.target.select()"

                            v-model="item.stock_min"
                            :success="valid"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </div>

                      <!-- <v-divider
                        class="mb-4"
                        style="border-top: 1px dashed #bbb;" /> -->

                      <ValidationProvider
                        name="Kelengkapan Alat"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <!-- <span class="red--text">*</span> -->
                          Kelengkapan Alat
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Diffuser"

                          :items="list_alat"
                          item-text="name"
                          item-value="id"
                          :item-color="set_color"
                          :append-icon="form.composition.length > 0 && valid ? 'mdi-check-circle-outline' : ''"

                          multiple
                          chips

                          return-object

                          v-model="form.composition"
                          :success="form.composition.length > 0 && valid"
                          :error-messages="errors"
                          required>
                        </v-select>
                      </ValidationProvider>

                      <!-- <div>
                        <div class="body-2 font-weight-bold mb-3">
                          Kelengkapan Alat
                        </div>

                        <div>
                          <v-list
                            flat
                            dense
                            class="pa-0">
                            <v-list-item-group
                              v-model="form.composition"
                              multiple>
                              <v-skeleton-loader
                                v-for="(item_alat, index_alat) in process.alat ? 6 : list_alat"
                                :key="index_alat"
                                :loading="process.alat"
                                type="list-item-avatar-two-line">
                                <div>
                                  <template v-if="!process.alat && list_alat.length > 0">
                                    <v-list-item
                                      :value="item_alat"
                                      class="pa-0">
                                      <template v-slot:default="{ active, }">
                                        <v-list-item-action
                                          class="my-0 mr-3">
                                          <v-checkbox
                                            dense
                                            :input-value="active"
                                            :color="set_color">
                                          </v-checkbox>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                          <v-list-item-title>
                                            {{ item_alat.name }}
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-list-item>
                                  </template>
                                </div>
                              </v-skeleton-loader>
                            </v-list-item-group>
                          </v-list>
                        </div>
                      </div> -->

                      <!-- <v-divider
                        class="my-4"
                        style="border-top: 1px dashed #bbb;" /> -->

                      <div>
                        <div class="body-2 mb-2">
                          Keterangan
                        </div>

                        <quill-editor
                          ref="myTextEditor"
                          class="mb-2"
                          :options="{
                            placeholder: 'Tuliskan keterangan disini . . .'
                          }"
                          v-model="form.description">
                        </quill-editor>
                      </div>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center my-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/image/footer/material.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data untuk membuat bahan di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        v-on:keyup.enter="save"
                        @click="save"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.form = false; reset();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          deletes: false,

          go_back: false
        },
        sheet: {
          form: false,
          detail: false
        },
        search: '',
        detail: {},
        list: [],
        list_alat: [],
        pagination: {},

        limit: 10,

        upload: {
          folder: null
        },
        form: {
          id: '',
          category_id: '',
          name: '',
          image_url: '',
          type: 'material',
          duration_minutes: 0,
          retail_price: 0,
          special_price: 0,
          stock: 0,
          stock_min: 0,

          description: '',

          // STOK PER TOKO
          store: [],

          // ALAT YANG DIBAWA
          composition: []
        },
        process: {
          load: false,
          form: false,
          file: false,

          limit: false,

          alat: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/bahan',
          title: 'Bahan',
          description: 'Bahan Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form.image_url = this.upload.folder.fileuri
            }
          }
        },
        deep: true
      },

      'limit': function() {
        this.process.limit = true

        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()

      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)

          t.dialog.go_back = true
        })
      },

      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit,
          'type[eq]': 'material'
        }

        await this.$axios.$get(`${process.env.API}admin/item`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchDetail (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/item/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail = response.results.data
          }
        })
      },
      async fetchStore () {
        this.process.store = true

        await this.$axios.$get(`${process.env.API}admin/store`)
        .then((response) => {
          this.process.store = false

          if (response.status === 200) {
            let data = response.results.data

            data.map(obj => {
              this.form.store.push({
                store_id: obj.id,
                store_name: obj.name,
                stock: 0,
                stock_min: 0
              })
            })
          }
        })
      },

      async fetchAlat () {
        this.process.alat = true

        let params = {
          limit: 10000,
          'type[eq]': 'material'
        }

        this.list_alat = []

        await this.$axios.$get(`${process.env.API}admin/item`, { params })
        .then((response) => {
          this.process.alat = false

          if (response.status === 200) {
            let data = response.results.data

            data.map(obj => {
              this.list_alat.push({
                id: obj.id,
                name: obj.name,
                image: obj.image_url
              })
            })
          }
        })
      },

      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          id: item !== undefined ? item.id : '',
          category_id: item !== undefined ? item.category_id : '',
          name: item !== undefined ? item.name : '',
          type: item !== undefined ? item.type : 'material',
          image_url: item !== undefined ? item.image_url : '',
          duration_minutes: item !== undefined ? item.duration_minutes : 0,
          retail_price: item !== undefined ? item.retail_price : 0,
          special_price: item !== undefined ? item.special_price : 0,
          stock: item !== undefined ? item.stock : 0,
          stock_min: item !== undefined ? item.stock_min : 0,

          description: item !== undefined ? item.description : '',

          // STOK PER TOKO
          store: item !== undefined ? item.store : [],

          // ALAT YANG DIBAWA
          // composition: item !== undefined ? item.composition_json_array : []
          composition: []
        }

        if (item !== undefined) {
          if (item.composition_json_array.length > 0) {
            let data = item.composition_json_array

            this.form.composition = []

            data.map(obj => {
              this.form.composition.push({
                id: obj.id,
                name: obj.name,
                image: obj.image
              })
            })

          } else {
            this.form.composition = item.composition_json_array
          }
        }

        this.process.form = false

        this.message.error = ''

        if (this.sheet.form && this.form.store.length < 1) this.fetchStore()

        if (this.sheet.form) this.fetchAlat()
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form.id === '' ? 'admin/item/add' : 'admin/item/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form = false

              if (this.form.id === '') {
                this.fetch()
              } else {
                this.fetchDetail(this.detail.id)
              }
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },
      async deletes () {
        this.message.error = ''

        this.process.form = true

          // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/item/delete`, {
          id: this.detail.id
        })
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.dialog.deletes = false

            this.sheet.detail = false

            this.fetch()
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      }
    }
  }
</script>
