<template>
  <div class="fill-height pt-14">
    <v-layout wrap>
      <v-flex>
        <section v-if="form.payment.length > 0">
          <v-card
            flat
            color="#FFFFFF60"
            style="border-radius: 0px;">
            <v-list
              flat
              dense
              color="transparent"
              class="pb-0">
              <v-list-item>
                <v-list-item-avatar
                  size="40"
                  style="border: 2px solid #ffffff; overflow: unset;">
                  <v-img 
                    width="100%"
                    height="100%"
                    alt="bidanvitacare" 
                    :src="Object.keys(selected.pelanggan).length > 0 && selected.pelanggan.image_url !== '' ? selected.pelanggan.image_url : require('@/assets/image/member_default.jpg')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="20"
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    class="body-2 font-weight-bold text-capitalize mb-2">
                    {{ Object.keys(selected.pelanggan).length > 0 ? selected.pelanggan.name : 'Customer' }}
                  </v-list-item-title>

                  <v-list-item-subtitle
                    class="body-2 font-weight-light black--text">
                    {{ Object.keys(selected.pelanggan).length > 0 ? selected.pelanggan.mobilephone : 'Tidak ada informasi kontak' }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-card-text>
              <v-card
                flat
                color="#f9dee2"
                class="border-radius">
                <v-card-text
                  class="d-flex align-center">
                  <div
                    class="body-1 font-weight-bold">
                    Total Bayar
                  </div>

                  <v-spacer />

                  <div
                    class="title font-weight-bold black--text">
                    {{ form.amount_paid_origin | price }}
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>

          <v-card
            flat
            height="calc(100vh - 290px)"
            color="transparent"
            class="border-radius">
            <v-card-text>
              <ValidationObserver 
                ref="form">
                <v-form>
                  <v-row
                    align="center">
                    <v-col
                      :cols="form.amount_paid > 0 ? 8 : 9">
                      <ValidationProvider 
                        name="amount_paid" 
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          Jumlah Bayar
                        </div>
                        
                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 10.000"
                          :append-icon="parseInt(form.amount_paid) > 0 && parseInt(form.amount_paid) >= parseInt(form.amount_paid_sum) && valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"
                          @keyup="form.amount_paid = form.amount_paid === '' || parseInt(form.amount_paid) < 1 ? 0 : parseInt(form.amount_paid)"
                          @blur="form.amount_paid = form.amount_paid === '' || parseInt(form.amount_paid) < 1 ? 0 : parseInt(form.amount_paid)"

                          v-model="form.amount_paid"
                          :success="parseInt(form.amount_paid) > 0 && parseInt(form.amount_paid) >= parseInt(form.amount_paid_sum) && valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col
                      :cols="form.amount_paid > 0 ? 4 : 3"
                      class="d-flex align-center justify-center">
                      <div
                        @click="form.amount_paid = parseInt(form.amount_paid_sum)"
                        class="body-1 font-weight-bold cursor-pointer">
                        Sama
                      </div>

                      <div v-if="form.amount_paid > 0"
                        @click="form.amount_paid = 0"
                        class="body-1 font-weight-bold ml-3 red--text cursor-pointer">
                        Hapus
                      </div>
                    </v-col>
                  </v-row>

                  <div class="body-2 mb-2">
                    Atau pilih pecahan dibawah ini:
                  </div>

                  <v-row>
                    <v-col
                      v-for="(item, index) in list.money"
                      :key="index"
                      cols="6">
                      <v-btn
                        @click="form.amount_paid += item"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_auth"
                        :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                        <div
                          class="d-flex align-center justify-center btn_auth_in"
                          :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                          {{ item | price }}
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <div v-if="message.error.length > 0"
                    class="text-center my-4 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
                      
                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-form>
              </ValidationObserver>
            </v-card-text>
          </v-card>

          <v-card 
            flat
            color="#FFFFFF"
            min-height="50"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row>
                <v-col 
                  cols="7" 
                  class="py-0">
                  <v-btn 
                    @click="save()"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in d-flex align-center justify-center">
                      <v-icon color="white">mdi-check-circle</v-icon>

                      <span
                        class="ml-2">
                        Simpan Transaksi
                      </span>
                    </div>
                  </v-btn>
                </v-col>

                <v-col 
                  cols="5" 
                  class="py-0">
                  <v-btn
                    :to="`/transaksi/payment/${$route.params.id}`"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon 
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-arrow-left-circle
                      </v-icon>

                      <span
                        class="ml-8">
                        Kembali
                      </span>
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        list: {
          money: [1000, 5000, 10000, 20000, 50000, 100000]
        },

        selected: {
          pelanggan: {}
        },

        form: {
          id: 0,
          amount_paid_origin: 0,
          amount_paid_sum: 0,
          amount_paid: 0,

          payment: []
        },

        process: {
          load: false,

          form: false
        },

        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/transaksi/payment/pembayaran/:id',
          title: 'Ubah Metode Pembayaran',
          description: 'Ubah Metode Pembayaran Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {
      'form.amount_paid': function(val) {
        this.form.amount_paid = val === '' ? 0 : parseInt(val)

        this.form.payment[0].amount = this.form.amount_paid
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetchDetail()
    },
    methods: {
      async fetchDetail () {
        this.process.load = true

        let params = {
          id: this.$route.params.id
        }

        await this.$axios.$get(`${process.env.API}admin/order/detail`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            let data = response.results.data

            this.form = {
              id: data.id,

              amount_paid_origin: data.amount_paid,
              amount_paid_sum: data.amount_paid,
              amount_paid: data.amount_paid,
              
              payment: data.payments
            }

            this.form.payment[0].method = 'cash'
            this.form.payment[0].method_bank_name = ''
            this.form.payment[0].method_ref = ''

            this.selected.pelanggan = {
              name: data.customer_name,
              mobilephone: data.customer_mobilephone,
              image_url: data.customer_image_url
            }
          }
        })
      },

      async save () {
        this.message.error = ''
  
        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}admin/order/update-payment`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.$router.push({ path: '/transaksi' })
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>