<template>
  <div>
    <v-card
      flat
      min-height="100vh"
      class="d-flex align-center"
      style="z-index: 1;">
      <v-card-text>
        <div
          class="text-h5 text-center font-weight-bold mb-2"
          :style="`color: ${set_color};`">
          Bidanvitacare.id
        </div>

        <div
          class="body-1 text-center">
          Mom, Baby & Kids Spa Sejak 2017
        </div>

        <div
          class="body-1 text-center mt-2">
          Sahabat Ceria Bunda & Buah Hati
        </div>

        <div
          class="px-12 ma-4 mx-12">
          <v-progress-linear
            v-if="$route.name !== 'jadwal-desktop' &&
                  $route.name !== 'jadwal-new-desktop' &&
                  $route.name !== 'jadwal-new-desktop-tambah' &&
                  $route.name !== 'jadwal-new-desktop-ubah-id'"
            indeterminate
            height="8"
            rounded
            :color="set_color">
          </v-progress-linear>

          <div
            v-if="$route.name === 'jadwal-desktop' ||
                  $route.name === 'jadwal-new-desktop' ||
                  $route.name === 'jadwal-new-desktop-tambah' ||
                  $route.name === 'jadwal-new-desktop-ubah-id'"
            class="d-flex justify-center">
            <v-progress-linear
              indeterminate
              height="8"
              rounded
              :color="set_color"
              style="width: 250px;">
            </v-progress-linear>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>


<script>
  export default {
    computed: {
      set_color () {
        return this.$store.state.header.config.color
      }
    }
  }
</script>
