<template>
  <div class="fill-height pt-14">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.referensi"
          persistent>
          <v-card v-if="form.payment.length > 0"
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar 
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span 
                class="body-1 font-weight-bold text-capitalize ml-2">
                Nomor Referensi
              </span>
            </v-card-title>
            
            <v-divider 
              class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="body-2 text-first line-text-second mt-4">
              <v-text-field
                filled
                rounded
                dense
                :color="set_color"
                placeholder="Contoh. 1234567890"

                hide-details

                v-model.number="form.payment[0].method_ref">
              </v-text-field>
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.referensi = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon 
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>
              
              <v-btn
                @click="dialog.referensi = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon 
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.bank"
          persistent
          scrollable>
          <v-card v-if="form.payment.length > 0"
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar 
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span 
                class="body-1 font-weight-bold text-capitalize ml-2">
                Pilih Bank
              </span>
            </v-card-title>
            
            <v-divider 
              class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="body-2 text-first line-text-second mt-3">
              <v-row>
                <v-col
                  cols="12"
                  class="py-0">
                  <v-list
                    v-for="(item, index) in list.bank"
                    :key="index"
                    flat
                    dense
                    color="#FFFFFF40"
                    class="border-radius py-0">
                    <v-list-item
                      @click="form.payment[0].method_bank_name = item.text"
                      class="px-0">
                      <v-list-item-avatar
                        size="40"
                        tile>
                        <v-img 
                          contain
                          width="100%"
                          height="100%"
                          alt="bidanvitacare" 
                          :src="item.icon !== '' ? item.icon : require('@/assets/image/image_default.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                size="20"
                                indeterminate 
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="body-2 font-weight-bold text-capitalize">
                          {{ item.text }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action v-if="form.payment[0].method_bank_name === item.text">
                        <v-icon 
                          color="green"
                          size="18">
                          mdi-check-circle
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.bank = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon 
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>
              
              <v-btn
                @click="dialog.bank = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon 
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section v-if="form.payment.length > 0">
          <ValidationObserver 
            ref="form">
            <v-form>
              <v-card
                flat
                color="#FFFFFF60"
                style="border-radius: 0px;">
                <v-list
                  flat
                  dense
                  color="transparent"
                  class="pb-0">
                  <v-list-item>
                    <v-list-item-avatar
                      size="40"
                      style="border: 2px solid #ffffff; overflow: unset;">
                      <v-img 
                        width="100%"
                        height="100%"
                        alt="bidanvitacare" 
                        :src="Object.keys(selected.pelanggan).length > 0 && selected.pelanggan.image_url !== '' ? selected.pelanggan.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              size="20"
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        class="body-2 font-weight-bold text-capitalize mb-2">
                        {{ Object.keys(selected.pelanggan).length > 0 ? selected.pelanggan.name : 'Customer' }}
                      </v-list-item-title>

                      <v-list-item-subtitle
                        class="body-2 font-weight-light black--text">
                        {{ Object.keys(selected.pelanggan).length > 0 ? selected.pelanggan.mobilephone : 'Tidak ada informasi kontak' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-card-text>
                  <v-card
                    flat
                    color="#f9dee2"
                    class="border-radius">
                    <v-card-text
                      class="d-flex align-center">
                      <div
                        class="body-1 font-weight-bold">
                        Total Bayar
                      </div>

                      <v-spacer />

                      <div
                        class="title font-weight-bold black--text">
                        {{ form.amount_paid_origin | price }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="transparent"
                class="border-radius">
                <v-card-text
                  class="body-2 pb-1">
                  Pilih metode pembayaran
                </v-card-text>

                <v-card-text
                  class="py-0"
                  style="overflow: auto;"
                  :style="
                    form.payment[0].method !== '' && (form.payment[0].method === 'transfer' || form.payment[0].method === 'edc') ? 
                    'height: calc(100vh - 278px);' : 
                    form.payment[0].method !== '' && (form.payment[0].method === 'qris' || form.payment[0].method === 'shopeepay') ? 
                    'height: calc(100vh - 228px);' : 
                    form.payment[0].method !== '' && form.payment[0].method === 'cash' || form.payment[0].method === 'multi payment' ? 
                    'height: calc(100vh - 180px);' : 
                    'height: calc(100vh - 50px);'">
                  <v-row>
                    <v-col
                      cols="12">
                      <v-list
                        v-for="(item, index) in list.payment"
                        :key="index"
                        :class="index < list.payment.length - 1 ? 'mb-4' : ''"
                        flat
                        dense
                        three-line
                        color="#FFFFFF40"
                        class="border-radius">
                        <v-list-item
                          @click="changePayment(item)">
                          <v-list-item-avatar
                            size="40"
                            tile>
                            <v-img 
                              contain
                              width="100%"
                              height="100%"
                              alt="bidanvitacare" 
                              :src="item.icon !== '' ? item.icon : require('@/assets/image/image_default.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    size="20"
                                    indeterminate 
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title
                              class="body-2 font-weight-bold text-capitalize"
                              style="margin-bottom: 3px;">
                              {{ item.title }}
                            </v-list-item-title>
                            
                            <div
                              class="body-2 font-weight-light line-text-first black--text"
                              style="opacity: 70%;">
                              {{ item.desc }}
                            </div>

                            <v-icon v-if="selected.payment.title === item.title"
                              color="green"
                              size="18"
                              style="position: absolute; right: 0; top: -8px;">
                              mdi-check-circle
                            </v-icon>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card v-if="form.payment[0].method !== ''"
                flat
                color="#FFFFFF"
                min-height="50"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <div v-if="form.payment[0].method === 'transfer' || form.payment[0].method === 'edc'"
                    class="d-flex align-center">
                    <div
                      class="body-2">
                      Nama Bank
                    </div>

                    <v-spacer />

                    <div
                      @click="dialog.bank = true"
                      class="body-2 cursor-pointer"
                      :style="`color: ${set_color};`">
                      <span v-if="form.payment[0].method_bank_name === ''">
                        Pilih
                      </span>

                      <span v-else>
                        <span 
                          class="d-flex align-center">
                          <v-icon :color="set_color" size="14">mdi-circle-edit-outline</v-icon>

                          <span
                            class="font-weight-bold ml-1">
                            {{ form.payment[0].method_bank_name }}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>

                  <v-divider v-if="form.payment[0].method === 'transfer' || form.payment[0].method === 'edc'"
                    class="mt-3 mb-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div v-if="form.payment[0].method !== 'cash' && form.payment[0].method !== 'multi payment'"
                    class="d-flex align-center">
                    <div
                      class="body-2">
                      Nomor Referensi
                    </div>

                    <v-spacer />

                    <div
                      @click="dialog.referensi = true"
                      class="body-2 cursor-pointer"
                      :style="`color: ${set_color};`">
                      <span v-if="form.payment[0].method_ref === ''">
                        Tambahkan
                      </span>

                      <span v-else>
                        <span 
                          class="d-flex align-center">
                          <v-icon :color="set_color" size="14">mdi-circle-edit-outline</v-icon>

                          <span
                            class="font-weight-bold ml-1">
                            {{ form.payment[0].method_ref }}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>

                  <v-divider v-if="form.payment[0].method !== 'cash' && form.payment[0].method !== 'multi payment'"
                    class="mt-3 mb-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div 
                    class="d-flex align-center">
                    <div
                      class="body-1 font-weight-bold">
                      Grand Total
                    </div>

                    <v-spacer />

                    <div
                      class="body-1 font-weight-bold"
                      :style="`color: ${set_color};`">
                      <span v-if="form.payment[0].method !== 'cash' && form.payment[0].method !== 'multi payment'">
                        {{ form.amount_paid | price }}
                      </span>

                      <span v-if="form.payment[0].method === 'cash' || form.payment[0].method === 'multi payment'">
                        {{ form.amount_paid_sum | price }}
                      </span>
                    </div>
                  </div>

                  <v-divider 
                    class="mt-3 mb-4"
                    style="border-top: 1px dashed #bbb;" />

                  <v-row>
                    <v-col 
                      cols="7" 
                      class="py-0">
                      <v-btn v-if="form.payment[0].method === 'cash' || form.payment[0].method === 'multi payment'"
                        @click="save()"
                        :disabled="process.form"
                        :loading="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in d-flex align-center justify-center">
                          <span
                            class="mr-2">
                            Selanjutnya
                          </span>

                          <v-icon color="white">mdi-arrow-right-circle</v-icon>
                        </div>
                      </v-btn>

                      <v-btn v-if="form.payment[0].method !== 'cash' && form.payment[0].method !== 'multi payment'"
                        @click="save()"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in d-flex align-center justify-center">
                          <v-icon color="white">mdi-check-circle</v-icon>

                          <span
                            class="ml-2">
                            Simpan Transaksi
                          </span>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col 
                      cols="5" 
                      class="py-0">
                      <v-btn
                        to="/transaksi"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon 
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-arrow-left-circle
                          </v-icon>

                          <span
                            class="ml-8">
                            Batal
                          </span>
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>
          </ValidationObserver>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          bank: false,
          referensi: false
        },
        
        list: {
          payment: [
            {
              title: 'cash',
              icon: require('@/assets/payment/cash.png'),
              desc: 'Pembayaran Cash bisa langsung dengan memberikan sejumlah uang tunai.'
            },
            {
              title: 'transfer',
              icon: require('@/assets/payment/transfer.png'),
              desc: 'Pembayaran Transfer melalui Bank, ATM, Internet Banking atau Mobile Banking.'
            },
            {
              title: 'edc',
              icon: require('@/assets/payment/edc.png'),
              desc: 'Pembayaran melalui mesin EDC yang diterbitkan oleh Bank.'
            },
            {
              title: 'qris',
              icon: require('@/assets/payment/qris.png'),
              desc: 'Pembayaran Qris dilakukan dengan menscan kode QR yang disediakan.'
            },
            {
              title: 'shopeepay',
              icon: require('@/assets/payment/shopeepay.png'),
              desc: 'Pembayaran dapat dilakukan melalui Shopeepay.'
            },
            {
              title: 'multi payment',
              icon: require('@/assets/payment/multi_payment.png'),
              desc: 'Pembayaran dapat dilakukan dengan Cash, Transfer, EDC, Qris dan Shopeepay.'
            }
          ],
          bank: [
            {
              text: 'BRI',
              icon: require('@/assets/bank/bri.png')
            },
            {
              text: 'Mandiri',
              icon: require('@/assets/bank/mandiri.png')
            }
          ]
        },

        selected: {
          pelanggan: {},
          payment: {}
        },

        form: {
          id: 0,

          amount_paid_origin: 0,
          amount_paid_sum: 0,
          amount_paid: 0,

          payment: []
        },

        process: {
          load: false,

          form: false
        },

        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/transaksi/payment/:id',
          title: 'Ubah Metode Pembayaran',
          description: 'Ubah Metode Pembayaran Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetchDetail()
    },
    methods: {
      async fetchDetail () {
        this.process.load = true

        let params = {
          id: this.$route.params.id
        }

        await this.$axios.$get(`${process.env.API}admin/order/detail`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            let data = response.results.data

            this.form = {
              id: data.id,
              
              amount_paid_origin: data.amount_paid,
              amount_paid_sum: data.amount_paid,
              amount_paid: data.amount_paid,
              
              payment: data.payments
            }

            this.selected.pelanggan = {
              name: data.customer_name,
              mobilephone: data.customer_mobilephone,
              image_url: data.customer_image_url
            }

            if (data.payments.length < 2) {
              this.list.payment.forEach((item, index) => {
                if (item.title === data.payments[0].method) {
                  this.selected.payment = item
                }
              })
            } else {
              this.selected.payment = this.list.payment[5]
            }
          }
        })
      },

      changePayment (item) {
        this.selected.payment = item

        if (this.form.payment.length < 2) {
          this.form.payment[0].method = item.title
          this.form.payment[0].method_bank_name = ''
          this.form.payment[0].method_ref = ''
        } else {
          this.form.payment = {
            amount: this.form.amount_paid,
            method: item.title,
            method_bank_name: '',
            method_ref: ''
          }
        }

      },

      async save () {
        if (this.selected.payment.title === 'cash' || this.selected.payment.title === 'multi payment') {
          this.process.form = true

          let url = this.selected.payment.title === 'cash' ? `/transaksi/payment/pembayaran/${this.$route.params.id}` : `/transaksi/payment/multi/${this.$route.params.id}`
          
          this.$router.push({ path: url })

        } else {
          this.message.error = ''
  
          const isValid = await this.$refs.form.validate()
          if (isValid) {
            this.process.form = true

            // SAVE TO REST API
            await this.$axios.$post(`${process.env.API}admin/order/update-payment`, this.form)
            .then((response) => {

              if (response.status === 200) {
                this.$router.push({ path: '/transaksi' })
              } else {
                this.process.form = false
                this.message.error = response.message
              }
            })
          } else {
            // AUTO FOCUS ON TEXT-FIELD
            setTimeout(() => {
              const errors = Object.entries(this.$refs.form.errors)
                .map(([key, value]) => ({ key, value }))
                .filter(error => error['value'].length)
              this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              })
            }, 10)
          }
        }
      }
    }
  }
</script>