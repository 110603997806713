<template>
  <v-app
    :class="$route.name === 'jadwal-desktop' ||
            $route.name === 'jadwal-new-desktop-tambah' ||
            $route.name === 'jadwal-new-desktop-ubah-id' ? 'jadwal-desktop' :
            $route.name === 'jadwal-new-desktop' ? 'jadwal-desktop jadwal-desktop-detail' : ''">
    <TopToolbar />

    <!-- <BottomNav
      v-if="!firstLoad &&
            $route.path !== '/' &&
            $route.path !== '/auth/forgot' &&
            $route.path !== '/auth/check' &&
            $route.path !== '/auth/success' &&
            $route.name !== 'auth-reset-slug' &&
            $route.name !== 'forum-id' &&

            $route.name !== 'forum-form' &&
            $route.name !== 'forum-form-id' &&
            $route.path !== '/admin'" /> -->
  </v-app>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import TopToolbar from "~/components/public/Header.vue"
  import BottomNav from "~/components/Footer.vue"
  import firebase from "firebase/app"
  import "firebase/messaging"
  export default {
    data () {
      return {
        firstLoad: true
      }
    },
    components: {
      TopToolbar,
      BottomNav
    },
    mounted () {
      this.firstLoad = false
    },
    beforeCreate() {
      this.firstLoad = true
    },
    created() {
      this.requestPermission()
    },
    methods: {
      requestPermission () {
        try {
          firebase
            .messaging()
            .requestPermission()
            .then(() => {
              console.log("Notification permission granted")

              firebase
                .messaging()
                .getToken()
                .then((token) => {
                  window.console.log(token)

                  this.$store.commit('member/auth/setFcm', token)
                  Cookie.set('fcm', token)

                  this.receiveMessage()
                })
            })
            .catch((err) => {
              console.log("Unable to get token ", err)
            })
        } catch (e) {
          console.log(e)
        }
      },
      receiveMessage () {
        try {
          firebase
            .messaging()
            .onMessage((payload) => {
              console.log("payload", payload)
            })
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
</script>
