<template>
  <div>
    <WindowsBottomNavigation 
      v-model="selected"
      :options="menu"
      :foregroundColor="set_color"
      :border-color="set_color"
      backgroundColor="#f9dee2">
      <template #icon="{ props }">
        <v-img 
          :class="selected === props.id ? 'mb-2' : ''"
          :style="selected === props.id ? '' : 'opacity: 50%;'"
          contain
          width="22"
          height="22"
          alt="bidanvitacare" 
          :src="props.icon"
          class="ma-auto">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular 
                size="20"
                indeterminate 
                :color="set_color">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </template>

      <template #title="{ props }">
        <div
          :style="`color: ${set_color};`">
          {{ props.title }}
        </div>
      </template>
    </WindowsBottomNavigation>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        selected: 1,
        menu: [
          // {
          //   id: 1,
          //   title: "Beranda",
          //   icon: require('@/assets/image/footer/home.png'),
          //   path: { name: "home" }
          // },
          // {
          //   id: 2,
          //   title: "Produk",
          //   icon: require('@/assets/image/footer/catalog.png'),
          //   path: { name: "produk" }
          // },
          // {
          //   id: 3,
          //   title: "Rekam Kunjungan",
          //   icon: require('@/assets/image/footer/visit.png'),
          //   path: { name: "kunjungan" }
          // },
          // {
          //   id: 4,
          //   title: "Laporan",
          //   icon: require('@/assets/image/footer/report.png'),
          //   path: { name: "laporan" }
          // },
          // {
          //   id: 5,
          //   title: "Profil",
          //   icon: require('@/assets/image/footer/settings.png'),
          //   path: { name: "profil" }
          // }
        ]
      }
    },
    components: {
      
    },
    watch: {
      $route: function(val) {
        this.menu.map(obj => {
          if (obj.path.name === val.name) this.selected = obj.id
        })
      }
    },
    computed: {
      user() {
        return this.$store.state.member.auth.user
      },
      rps() {
        return this.$vuetify.breakpoint.name
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      this.menu.map(obj => {
        if (obj.path.name === this.$route.name) this.selected = obj.id
      })
    },
    methods: {
      
    }
  }
</script>

<style scoped>
  .wn-btn-container-foreground {
    max-width: 460px !important;
    height: 56px !important;
    z-index: 1 !important;
  }
</style>