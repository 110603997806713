<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.deletes"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-text>
              <v-img 
                width="150"
                height="150"
                alt="bidanvitacare" 
                :src="require('@/assets/icon/hapus_info.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin menghapus data
                </div>
                
                <div>
                  <strong>{{ detail.name }}</strong>?
                </div>
              </div>
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="deletes()"
                :loading="process.form"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon 
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Saya yakin
                  </span>
                </div>
              </v-btn>
              
              <v-btn
                @click="dialog.deletes = false"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon 
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.go_back"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-text>
              <v-img 
                width="150"
                height="150"
                alt="bidanvitacare" 
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin akan keluar
                </div>

                <div>
                  dari halaman ini?
                </div>
              </div>
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                to="/home"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon 
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Keluar
                  </span>
                </div>
              </v-btn>
              
              <v-btn
                @click="dialog.go_back = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon 
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text 
              class="pb-0 mt-1">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                filled
                rounded
                dense 
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-btn
                @click="sheet.form = true; reset();"
                block
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
                :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                <div
                  class="btn_auth_in d-flex align-center justify-center"
                  :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                  <div>
                    <v-img 
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare" 
                      :src="require('@/assets/icon/add_plus.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            size="20"
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <div
                    class="ml-2">
                    Tambah Shift
                  </div>
                </div>
              </v-btn>
            </v-card-text>

            <v-card-text
              class="pb-0">
              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 4 : list"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list.length > 0"
                          flat
                          dense
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-list-item
                            @click="sheet.detail = true; detail = item;">
                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                {{ item.name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="my-2">
                                <v-divider 
                                  style="border-top: 1px dashed #bbb;" />
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="d-flex align-center">
                                <div>
                                  <div
                                    class="caption mb-1">
                                    Mulai
                                  </div>

                                  <div
                                    class="black--text">
                                    {{ item.start_time }}
                                  </div>
                                </div>

                                <v-spacer />

                                <div>
                                  <div
                                    class="caption mb-1">
                                    Selesai
                                  </div>

                                  <div
                                    class="black--text">
                                    {{ item.end_time }}
                                  </div>
                                </div>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Shift" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.detail"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            flat
            class="background">
            <div
              class="blur">
              <v-card-title 
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon 
                  @click="sheet.detail = false; detail = {};"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span 
                  @click="sheet.detail = false; detail = {};"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Shift {{ detail.name }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 80px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text 
                  class="pt-16 mt-4">
                  <div
                    class="body-1 font-weight-bold black--text text-capitalize">
                    {{ detail.name }}
                  </div>

                  <v-divider
                    class="my-3"
                    style="border-top: 1px dashed #bbb;" />
                  
                  <div
                    class="d-flex align-center">
                    <div>
                      <div
                        class="caption mb-1">
                        Mulai
                      </div>

                      <div
                        class="black--text">
                        {{ detail.start_time }}
                      </div>
                    </div>

                    <v-spacer />

                    <div>
                      <div
                        class="caption mb-1">
                        Selesai
                      </div>

                      <div
                        class="black--text">
                        {{ detail.end_time }}
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="50"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col 
                      cols="6" 
                      class="py-0">
                      <v-btn
                        @click="sheet.form = true; reset(detail);"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img 
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare" 
                              :src="require('@/assets/icon/edit_pencil.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    size="20"
                                    indeterminate 
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="blue--text ml-2">
                            Ubah
                          </div>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col 
                      cols="6" 
                      class="py-0">
                      <v-btn
                        @click="dialog.deletes = true"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img 
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare" 
                              :src="require('@/assets/icon/hapus.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    size="20"
                                    indeterminate 
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="red--text ml-2">
                            Hapus
                          </div>
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.form"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title 
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon 
                  @click="sheet.form = false; reset();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span 
                  @click="sheet.form = false; reset();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  {{ form.id === '' ? 'Tambah' : 'Ubah' }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-4">
                  <ValidationObserver 
                    ref="form">
                    <v-form>
                      <ValidationProvider 
                        name="Nama Shift" 
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Nama Shift
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>
                        
                        <v-text-field
                          v-on:keyup.enter="save"
                          
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Pagi"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.name"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <div>
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Jam Mulai
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-menu
                          ref="start"
                          v-model="time.start"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="form.start_time"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider 
                              name="Jam Mulai" 
                              rules="required"
                              v-slot="{ errors, valid }">
                              <v-text-field
                                v-on:keyup.enter="save"
                                readonly
                                filled
                                rounded
                                dense
                                :color="set_color"
                                placeholder="Contoh. 08:00"

                                :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form.start_time = ''; form.end_time = '';"
                                v-on="on"
                                v-bind="attrs"
                                
                                v-model="form.start_time"
                                :success="valid"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>
                          </template>

                          <v-time-picker
                            v-if="time.start"
                            v-model="form.start_time"
                            full-width
                            :color="set_color"
                            format="24hr"
                            @click:minute="$refs.start.save(`${form.start_time}:00`); form.end_time = '';">
                          </v-time-picker>
                        </v-menu>
                      </div>

                      <div>
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Jam Selesai
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-menu
                          ref="end"
                          v-model="time.end"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="form.end_time"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider 
                              name="Jam Selesai" 
                              rules="required"
                              v-slot="{ errors, valid }">
                              <v-text-field
                                v-on:keyup.enter="save"
                                readonly
                                filled
                                rounded
                                dense
                                :color="set_color"
                                placeholder="Contoh. 17:00"

                                :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form.end_time = ''"
                                v-on="on"
                                v-bind="attrs"
                                
                                v-model="form.end_time"
                                :success="valid"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>
                          </template>

                          <v-time-picker
                            v-if="time.end"
                            v-model="form.end_time"
                            full-width
                            :color="set_color"
                            format="24hr"
                            @click:minute="$refs.end.save(`${form.end_time}:00`)">
                          </v-time-picker>
                        </v-menu>
                      </div>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center mt-3 mb-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
                      
                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row 
                    align="center">
                    <v-col 
                      cols="3" 
                      class="pb-0">
                      <v-img 
                        contain
                        alt="bidanvitacare" 
                        :src="require('@/assets/image/footer/shift.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col 
                      cols="9" 
                      class="black--text pb-0">
                      <div>
                        <div 
                          class="body-2 line-text-second black--text" 
                          style="opacity: 60%;">
                          Masukkan data untuk menentukan shift pegawai di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  
                  <v-row 
                    class="mt-4">
                    <v-col 
                      cols="6" 
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        v-on:keyup.enter="save"
                        @click="save"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon 
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col 
                      cols="6" 
                      class="pb-0">
                      <v-btn
                        @click="sheet.form = false; reset();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon 
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        sheet: {
          form: false,
          detail: false
        },
        dialog: {
          deletes: false,
          go_back: false
        },
        search: '',
        detail: {},
        list: [],
        pagination: {},

        limit: 10,

        time: {
          start: false,
          end: false
        },
        form: {
          id: '', 
          name: '', 
          start_time: '', 
          end_time: ''
        },
        process: {
          load: false,
          form: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/shift',
          title: 'Shift',
          description: 'Shift Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.process.limit = true
        
        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()

      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)
          
          t.dialog.go_back = true
        })
      },
      
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}admin/shift`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },

      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          id: item !== undefined ? item.id : '',
          name: item !== undefined ? item.name : '',
          start_time: item !== undefined ? item.start_time : '',
          end_time: item !== undefined ? item.end_time : ''
        }

        this.picker = false
        
        this.process.form = false

        this.message.error = ''
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form.id === '' ? 'admin/shift/add' : 'admin/shift/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.detail = false

              this.sheet.form = false

              this.fetch()
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },
      async deletes () {
        this.message.error = ''

        this.process.form = true

          // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/shift/delete`, {
          id: this.detail.id
        })
        .then((response) => {
          this.process.form = false

          if (response.status === 200) {

            this.dialog.deletes = false

            this.sheet.detail = false

            this.fetch()
          } else {
            this.message.error = response.message
          }
        })
      }
    }
  }
</script>