<template>
  <div>
    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text 
          class="pb-0 mt-1">
          <v-text-field
            v-model="search_category"
            @click:clear="search_category = ''; fetch();"
            v-on:keyup.enter="fetch()"
            filled
            rounded
            dense 
            single-line
            hide-details
            :color="set_color"
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari data yang Anda butuhkan ?"
            clearable>
          </v-text-field>
        </v-card-text>

        <v-card-text
          class="pb-0">
          <div
            class="body-2 font-weight-bold black--text d-flex align-center mb-2">
            <div>
              Kategori
            </div>

            <v-spacer />

            <div>
              <!-- <v-icon>mdi-filter-variant</v-icon> -->
            </div>
          </div>

          <v-row>
            <v-col>
              <v-skeleton-loader
                v-for="(item, index) in process.load ? 5 : category"
                :key="index"
                :loading="process.load"
                type="list-item-avatar-three-line">
                <template>
                  <div>
                    <v-list 
                      v-if="!process.load && category.length > 0"
                      flat
                      dense
                      color="#FFFFFF40"
                      class="border-radius">
                      <v-list-item
                        v-if="!search_category_boll"
                        @click="sheet.detail = true; selected.type = 'category'; selected.category_id = item.id; fetchDetail(item.id);">
                        <v-list-item-avatar
                          size="40"
                          class="title d-flex align-center justify-center font-weight-bold black--text"
                          style="border: 2px dashed #e0e0e0;">
                          <span
                            style="opacity: 40%;">
                            {{ item.name | initial }}
                          </span>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="body-2 font-weight-bold text-capitalize">
                            {{ item.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        v-if="search_category_boll"
                        @click="sheet.detail = true; selected.type = 'category'; selected.category_id = item.id; fetchDetail(item.id);">
                        <v-list-item-avatar
                          size="40"
                          tile
                          style="border: 2px solid #ffffff; border-radius: 5px !important;">
                          <v-img 
                            contain
                            width="100%"
                            height="100%"
                            alt="bidanvitacare" 
                            :src="item.image_json_array.length > 0 ? item.image_json_array[0].image_url : require('@/assets/image/image_default.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  size="20"
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-subtitle>
                            <v-chip
                              x-small
                              class="white--text"
                              :color="set_color">
                              {{ item.category_name }}
                            </v-chip>
                          </v-list-item-subtitle>

                          <v-list-item-title
                            class="body-2 font-weight-bold text-capitalize"
                            style="margin-top: 5px; margin-bottom: 6px;">
                            <span
                              v-html="item.question">
                            </span>
                          </v-list-item-title>

                          <v-list-item-subtitle
                            class="body-2 font-weight-light">
                            <div
                              class="line-text-first"
                              v-html="item.answer">
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </template>
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </section>

    <v-bottom-sheet
      v-model="sheet.detail"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card v-if="Object.keys(detail).length > 0"
        flat
        class="background">
        <div
          class="blur">
          <v-card-title 
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon 
              @click="sheet.detail = false; detail = {}; fetch();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span 
              @click="sheet.detail = false; detail = {}; fetch();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Kategori {{ detail.name }}
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 0px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="pt-16 mt-4 pb-1">
              <div
                class="d-flex align-center pa-2"
                style="border: 1px dashed #bbb; border-radius: 10px;">
                <div
                  class="body-1 font-weight-bold black--text">
                  {{ detail.name }}
                </div>
              </div>
            </v-card-text>


            <v-card-text 
              class="px-0">
              <v-card
                flat
                color="transparent">
                <v-card-text 
                  class="pt-1">
                  <v-text-field
                    v-model="search_question"
                    @click:clear="search_question = ''; fetchData();"
                    v-on:keyup.enter="fetchData()"
                    filled
                    rounded
                    dense 
                    single-line
                    hide-details
                    :color="set_color"
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Cari data yang Anda butuhkan ?"
                    clearable>
                  </v-text-field>
                </v-card-text>

                <v-card-text
                  class="py-0">
                  <div
                    class="body-2 font-weight-bold black--text d-flex align-center mb-2">
                    <div>
                      Pertanyaan
                    </div>

                    <v-spacer />

                    <div>
                      <!-- <v-icon>mdi-filter-variant</v-icon> -->
                    </div>
                  </div>

                  <v-row>
                    <v-col>
                      <v-skeleton-loader
                        v-for="(item, index) in process.load ? 5 : list"
                        :key="index"
                        :loading="process.load"
                        :class="index < list.length - 1 ? 'mb-4' : ''"
                        type="list-item-avatar-three-line">
                        <template>
                          <div>
                            <v-list
                              v-if="!process.load && list.length > 0"
                              flat
                              dense
                              three-line
                              color="#FFFFFF40"
                              class="border-radius">
                              <v-list-item
                                @click="sheet.detail_data = true; selected.type = 'data'; fetchDetailData(item.id);">
                                <v-list-item-avatar
                                  size="40"
                                  tile
                                  style="border: 2px solid #ffffff; border-radius: 5px !important;">
                                  <v-img 
                                    contain
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare" 
                                    :src="item.image_json_array.length > 0 ? item.image_json_array[0].image_url : require('@/assets/image/image_default.png')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          size="20"
                                          indeterminate 
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    <v-chip
                                      x-small
                                      class="white--text"
                                      :color="set_color">
                                      {{ item.category_name }}
                                    </v-chip>
                                  </v-list-item-subtitle>

                                  <v-list-item-title
                                    class="body-2 font-weight-bold text-capitalize"
                                    style="margin-top: 5px; margin-bottom: 6px;">
                                    <span
                                      v-html="item.question">
                                    </span>
                                  </v-list-item-title>

                                  <v-list-item-subtitle
                                    class="body-2 font-weight-light">
                                    <div
                                      class="line-text-first"
                                      v-html="item.answer">
                                    </div>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </div>
                        </template>
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>

                  <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                    class="text-center my-4">
                    <v-btn
                      @click="limit += 10"
                      :loading="process.limit"
                      small
                      rounded
                      outlined
                      elevation="0"
                      :color="set_color"
                      class="text-capitalize"
                      :style="`color: ${set_color};`">
                      Lebih banyak
                    </v-btn>
                  </div>

                  <Empty v-if="!process.load && pagination.total_page < 1"
                    class="py-6"
                    margin="my-16"
                    size="125"
                    message="Pertanyaan" />
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.detail_data"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card v-if="Object.keys(detail_data).length > 0"
        flat
        class="background">
        <div
          class="blur">
          <v-card-title 
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon 
              @click="sheet.detail_data = false; detail_data = {}; fetchData(); sheet.detail = true; selected.type = 'category';"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span 
              @click="sheet.detail_data = false; detail_data = {}; fetchData(); sheet.detail = true; selected.type = 'category';"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Pertanyaan <span v-html="detail_data.question"></span>
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 0px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="pt-16 mt-4">
              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Kategori
                </div>

                <div
                  class="body-2 font-weight-bold black--text">
                  {{ detail_data.category_name }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Pertanyaan
                </div>

                <div
                  class="body-2 line-text-second black--text"
                  v-html="detail_data.question">
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Jawaban
                </div>

                <div
                  class="body-2 line-text-second black--text"
                  v-html="detail_data.answer">
                </div>
              </div>

              <div>
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Gambar
                </div>

                <div v-if="detail_data.image_json_array !== undefined && detail_data.image_json_array.length > 0">
                  <v-row>
                    <v-col 
                      v-for="(item, index) in detail_data.image_json_array"
                      :key="index"
                      cols="6" 
                      md="6">
                      <v-card 
                        flat
                        width="100%"
                        height="200"
                        class="d-flex align-center justify-center"
                        style="border-radius: 15px;
                              border: 5px solid #0000000d;
                              box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; ">
                        <v-img 
                          alt="bidanvitacare" 
                          width="100%"
                          height="196"
                          :src="item.image_url"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>

                <div v-else
                  class="body-2 line-text-second black--text">
                  -
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        sheet: {
          detail: false,
          detail_data: false
        },

        search_category: '',
        search_category_boll: false,
        search_question: '',
        selected: {
          type: 'category',
          category_id: ''
        },

        detail: {},
        detail_data: {},

        category: [],
        list: [],
        pagination: {},

        limit: 10,

        process: {
          load: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.process.limit = true

        // if (this.selected.type === 'category') {
        //   this.fetch('limit')
        // } else {
          this.fetchData('limit')
        // }
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          limit: 10000
        }

        let params_data = {
          search: this.search_category,
          limit: 10000
        }

        this.category = []

        await this.$axios.$get(`${process.env.API}therapist/guide-category/data`, { params })
        .then((response) => {
          if (response.status === 200) {
            let data_first = response.results.data

            if (this.search_category === '' || this.search_category === null) {
              data_first.map(obj => {
                this.category.push({
                  id: obj.id,
                  name: obj.name,
                  
                  category_name: '',
                  question: '',
                  answer: '',
                  image_json_array: []
                })
              })
            }

            this.$axios.$get(`${process.env.API}therapist/guide-data/data`, { params_data })
            .then((response) => {
              this.process.load = false

              if (response.status === 200) {
                let data_second = response.results.data

                data_second.map(obj => {
                  this.category.push({
                    id: obj.category_id,
                    name: obj.category_name,
                    
                    category_name: obj.category_name,
                    question: obj.question,
                    answer: obj.answer,
                    image_json_array: obj.image_json_array
                  })
                })

                this.category = this.same_object(this.category)

                this.search_category_boll = this.search_category === '' || this.search_category === null ? false : true
              }
            })
          }
        })
      },
      same_object (arr) {
        let mapObj = new Map()
  
        arr.forEach(v => {
          let prevValue = mapObj.get(v.name)
          if(!prevValue){
            mapObj.set(v.name, v)
          } 
        })

        return [...mapObj.values()]
      },

      async fetchData (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search_question,
          limit: this.limit,

          category_id: this.selected.category_id
        }

        await this.$axios.$get(`${process.env.API}therapist/guide-data/data`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchDetail (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}therapist/guide-category/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail = response.results.data

            this.fetchData()
          }
        })
      },
      async fetchDetailData (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}therapist/guide-data/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail_data = response.results.data
          }
        })
      }
    }
  }
</script>