<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-list
              dense
              color="transparent"
              class="pa-0">
              <v-list-item>
                <v-list-item-avatar
                  tile
                  size="50">
                  <v-img
                    alt="kominfo"
                    :src="require(`@/assets/image/time/${isDay}.png`)"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          color="white">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <div
                    class="body-1 text-capitalize font-weight-bold black--text one-line">
                    Hai, {{ user.name }}
                  </div>

                  <div
                    class="body-2 black--text"
                    style="opacity: 60%;">
                    Selamat {{ isDay }}
                  </div>
                </v-list-item-content>

                <v-list-item-avatar
                  size="40"
                  :style="`border: 2px dashed ${set_color};`">
                  <v-img
                    width="100%"
                    height="100%"
                    alt="kominfo"
                    :src="user.image_url !== '' ? user.image_url : require('@/assets/image/member_default.jpg')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          color="white">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>
              </v-list-item>
            </v-list>

            <v-card-text>
              <v-row>
                <v-col
                  cols="6"
                  class="py-0">
                  <v-card
                    flat
                    :color="set_color_rgb_soft"
                    class="border-radius pa-4 py-3">
                    <div
                      class="body-2 font-weight-bold mb-2"
                      style="opacity: 70%;">
                      Layanan
                    </div>

                    <v-skeleton-loader
                      v-for="(item, index) in process.summary ? 2 : 1"
                      :key="index"
                      :loading="process.summary"
                      type="list-item-avatar-two-line">
                      <div>
                        <div v-if="!process.summary && Object.keys(summary).length > 0">
                          <div
                            class="mb-1">
                            <v-list
                              dense
                              color="transparent"
                              class="pa-0">
                              <v-list-item
                                class="px-0">
                                <v-list-item-avatar
                                  tile
                                  size="30"
                                  :color="set_color"
                                  class="mr-2"
                                  style="border-radius: 8px !important;">
                                  <v-img
                                    contain
                                    width="20"
                                    height="20"
                                    alt="kominfo"
                                    :src="require('@/assets/image/image_default.png')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          size="20"
                                          indeterminate
                                          color="white">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-list-item-avatar>

                                <v-list-item-content
                                  class="py-0">
                                  <div
                                    class="mt-2"
                                    style="font-size: 10px; opacity: 70%;">
                                    On Site
                                  </div>

                                  <div
                                    class="body-2 font-weight-bold text-capitalize mb-1"
                                    style="opacity: 70%;">
                                    {{ summary.percentage_layanan.on_site.percentage }}%
                                  </div>
                                </v-list-item-content>

                                <v-list-item-action
                                  class="d-flex justify-end mx-0">
                                  <v-icon
                                    small
                                    :color="summary.percentage_layanan.on_site.up_down === 'up' ? 'green' : 'red'">
                                    {{ summary.percentage_layanan.on_site.up_down === 'up' ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' }}
                                  </v-icon>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </div>

                          <div>
                            <v-list
                              dense
                              color="transparent"
                              class="pa-0">
                              <v-list-item
                                class="px-0">
                                <v-list-item-avatar
                                  tile
                                  size="30"
                                  :color="set_color"
                                  class="mr-2"
                                  style="border-radius: 8px !important;">
                                  <v-img
                                    contain
                                    width="20"
                                    height="20"
                                    alt="kominfo"
                                    :src="require('@/assets/image/image_default.png')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          size="20"
                                          indeterminate
                                          color="white">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-list-item-avatar>

                                <v-list-item-content
                                  class="py-0">
                                  <div
                                    class="mt-2"
                                    style="font-size: 10px; opacity: 70%;">
                                    Home Care
                                  </div>

                                  <div
                                    class="body-2 font-weight-bold text-capitalize mb-1"
                                    style="opacity: 70%;">
                                    {{ summary.percentage_layanan.home_care.percentage }}%
                                  </div>
                                </v-list-item-content>

                                <v-list-item-action
                                  class="d-flex justify-end mx-0">
                                  <v-icon
                                    small
                                    :color="summary.percentage_layanan.home_care.up_down === 'up' ? 'green' : 'red'">
                                    {{ summary.percentage_layanan.home_care.up_down === 'up' ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' }}
                                  </v-icon>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </div>
                        </div>
                      </div>
                    </v-skeleton-loader>
                  </v-card>
                </v-col>

                <v-col
                  cols="6"
                  class="py-0">
                  <v-card
                    flat
                    :color="set_color_rgb_soft"
                    class="border-radius pa-4 py-3">
                    <div
                      class="body-2 font-weight-bold mb-2"
                      style="opacity: 70%;">
                      Produk & Bahan
                    </div>

                    <v-skeleton-loader
                      v-for="(item, index) in process.summary ? 2 : 1"
                      :key="index"
                      :loading="process.summary"
                      type="list-item-two-line">
                      <div>
                        <div v-if="!process.summary && Object.keys(summary).length > 0">
                          <div
                            class="d-flex align-center mb-1"
                            style="opacity: 70%;">
                            <div
                              class="caption d-flex align-center">
                              <v-icon x-small :color="set_color">mdi-circle</v-icon>

                              <span
                                class="ml-1">
                                Layanan
                              </span>
                            </div>

                            <v-spacer />

                            <div
                              class="body-2 font-weight-bold">
                              {{ summary.product_material.service}}
                            </div>
                          </div>

                          <div
                            class="d-flex align-center mb-1"
                            style="opacity: 70%;">
                            <div
                              class="caption d-flex align-center">
                              <v-icon x-small :color="set_color">mdi-circle</v-icon>

                              <span
                                class="ml-1">
                                Produk
                              </span>
                            </div>

                            <v-spacer />

                            <div
                              class="body-2 font-weight-bold">
                              {{ summary.product_material.product }}
                            </div>
                          </div>

                          <div
                            class="d-flex align-center mb-1"
                            style="opacity: 70%;">
                            <div
                              class="caption d-flex align-center">
                              <v-icon x-small :color="set_color">mdi-circle</v-icon>

                              <span
                                class="ml-1">
                                Bahan & Alat
                              </span>
                            </div>

                            <v-spacer />

                            <div
                              class="body-2 font-weight-bold">
                              {{ summary.product_material.material }}
                            </div>
                          </div>

                          <div
                            class="d-flex align-center mb-1"
                            style="opacity: 70%;">
                            <div
                              class="caption d-flex align-center">
                              <v-icon x-small :color="set_color">mdi-circle</v-icon>

                              <span
                                class="ml-1">
                                Pinjam
                              </span>
                            </div>

                            <v-spacer />

                            <div
                              class="body-2 font-weight-bold">
                              {{ summary.product_material.loan_not_return }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-skeleton-loader>

                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text
              class="py-0">
              <v-row
                v-for="(item, index) in menu"
                :key="index">
                <v-col
                  class="py-2">
                  <v-list
                    dense
                    :style="`border: 2px solid ${set_color}; border-radius: 15px;`">
                    <v-list-item
                      @click="to_sheet(index)">
                      <v-list-item-avatar
                        tile
                        size="40"
                        :color="set_color_rgb_soft"
                        class="mr-2"
                        style="border-radius: 10px !important;">
                        <v-img
                          contain
                          width="25"
                          height="25"
                          alt="kominfo"
                          :src="item.icon"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                color="white">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content
                        class="py-0">
                        <div
                          class="caption my-0"
                          style="opacity: 70%;">
                          {{ item.text }}
                        </div>

                        <div
                          class="title font-weight-bold text-capitalize mb-1"
                          style="opacity: 70%; line-height: inherit;">
                          {{ item.total }}
                        </div>
                      </v-list-item-content>

                      <v-list-item-action
                        class="d-flex justify-end mx-0">
                        <v-icon
                          small
                          :color="set_color">
                          mdi-chevron-double-right
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>

            <div>
              <div
                class="pa-4"
                style="opacity: 70%;">
                <div
                  class="body-2 font-weight-bold mb-1">
                  Data SO
                </div>

                <div
                  class="body-2">
                  Grafik Data sampai {{ dateNow | date }}
                </div>
              </div>

              <apexchart
                height="300"
                :options="bar.chartOptions"
                :series="bar.series">
              </apexchart>
            </div>
          </v-card>
        </section>

        <!-- ABSEN -->
        <v-bottom-sheet
          v-model="sheet.all_absen"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.all_absen = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.all_absen = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Semua Absen
                </span>
              </v-card-title>

              <v-card-text
                class="pt-5 pb-0 mt-16">
                <v-text-field
                  v-model="search.all_absen"
                  @click:clear="search.all_absen = ''; fetchAllAbsen();"
                  v-on:keyup.enter="fetchAllAbsen()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>
              </v-card-text>

              <v-card-text
                class="pb-0"
                style="height: calc(100vh - 185px); overflow: auto;">
                <v-row>
                  <v-col
                    cols="12">
                    <v-skeleton-loader
                      v-for="(item, index) in process.all_absen ? 5 : all_absen"
                      :key="index"
                      :loading="process.all_absen"
                      :class="index < all_absen.length - 1 ? 'mb-4' : ''"
                      type="list-item-avatar-three-line">
                      <template>
                        <div>
                          <v-list
                            v-if="!process.all_absen && all_absen.length > 0"
                            flat
                            dense
                            two-line
                            color="#FFFFFF40"
                            class="border-radius">
                            <v-list-item
                              @click="sheet.detail_absen = true; selected.detail_absen = item; selected.menu_absen = 'Kehadiran';">
                              <v-list-item-avatar
                                size="40"
                                class="mt-0"
                                style="border: 2px solid #ffffff;">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="item.user_image_url !== '' ? item.user_image_url : require('@/assets/image/member_default.jpg')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        size="20"
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title
                                  class="body-2 font-weight-bold text-capitalize">
                                  {{ item.user_name }}
                                </v-list-item-title>

                                <v-list-item-subtitle
                                  class="body-2 font-weight-light line-text-second black--text"
                                  style="opacity: 70%;">
                                  {{ item.user_email !== '' ? item.user_email : '-' }}
                                </v-list-item-subtitle>

                                <v-list-item-subtitle
                                  class="body-2">
                                  Tanggal {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </template>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <div v-if="!process.all_absen && pagination.all_absen.total_data > 10 && limit < pagination.all_absen.total_data"
                  class="text-center my-4">
                  <v-btn
                    @click="limit += 10"
                    :loading="process.limit"
                    small
                    rounded
                    outlined
                    elevation="0"
                    :color="set_color"
                    class="text-capitalize"
                    :style="`color: ${set_color};`">
                    Lebih banyak
                  </v-btn>
                </div>

                <Empty v-if="!process.all_absen && pagination.all_absen.total_page < 1"
                  class="py-6"
                  margin="my-16"
                  size="125"
                  message="Semua Absen" />
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>

        <!-- DETAIL ABSEN -->
        <v-bottom-sheet
          v-model="sheet.detail_absen"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.detail_absen = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.detail_absen = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Detail Absen
                </span>
              </v-card-title>

              <v-card-text v-if="Object.keys(selected.detail_absen).length > 0"
                class="pt-3 mt-16">
                <v-list
                  flat
                  dense
                  two-line
                  color="#FFFFFF40"
                  class="border-radius pa-0">
                  <v-list-item
                    class="pa-0">
                    <v-list-item-avatar
                      size="40"
                      class="mt-0"
                      style="border: 2px solid #ffffff;">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="selected.detail_absen.user_image_url !== '' ? selected.detail_absen.user_image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        class="body-2 font-weight-bold text-capitalize">
                        {{ selected.detail_absen.user_name }}
                      </v-list-item-title>

                      <v-list-item-subtitle
                        class="body-2 font-weight-light line-text-second black--text"
                        style="opacity: 70%;">
                        {{ selected.detail_absen.user_email !== '' ? selected.detail_absen.user_email : '-' }}
                      </v-list-item-subtitle>

                      <v-list-item-subtitle
                        class="body-2">
                        Tanggal {{ selected.detail_absen.datetime | date }} Pukul {{ selected.detail_absen.datetime | time }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-divider
                  class="my-4"
                  style="border-top: 2px dashed #bbb;" />

                <v-row>
                  <v-col
                    v-for="(item, index) in menu_absen"
                    :key="index"
                    cols="12"
                    md="6">
                    <v-btn
                      @click="selected.menu_absen = item"

                      block
                      rounded
                      elevation="0"
                      :class="selected.menu_absen !== item ? '' : 'font-weight-bold'"
                      :color="selected.menu_absen !== item ? '#e2e2e2' : set_color"
                      :style="selected.menu_absen !== item ? 'color: #8c8c8c;' : 'color: #FFFFFF;'"
                      class="body-2 text-capitalize">
                      {{ item }}
                    </v-btn>
                  </v-col>
                </v-row>

                <div
                  class="mt-4 mb-6">
                  <gmap-map
                    :center="{
                      lat: selected.menu_absen === 'Kehadiran' ? parseFloat(selected.detail_absen.latitude) : parseFloat(selected.detail_absen.end_latitude),
                      lng: selected.menu_absen === 'Kehadiran' ? parseFloat(selected.detail_absen.longitude) : parseFloat(selected.detail_absen.end_longitude)
                    }"
                    :zoom="12"
                    :options="{
                      zoomControl: true,
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: true,
                      disableDefaultUi: false
                    }"
                    style="width: 100%; height: 300px;">
                    <gmap-marker
                      :position="{
                        lat: selected.menu_absen === 'Kehadiran' ? parseFloat(selected.detail_absen.latitude) : parseFloat(selected.detail_absen.end_latitude),
                        lng: selected.menu_absen === 'Kehadiran' ? parseFloat(selected.detail_absen.longitude) : parseFloat(selected.detail_absen.end_longitude)
                      }">
                    </gmap-marker>
                  </gmap-map>
                </div>

                <div
                  class="body-2 text-center"
                  style="opacity: 70%;">
                  <div
                    class="font-weight-bold mb-1">
                    Absen {{ selected.menu_absen }}
                  </div>

                  <div v-if="selected.menu_absen === 'Kehadiran'">
                    Tanggal {{ selected.detail_absen.datetime | date }} Pukul {{ selected.detail_absen.datetime | time }}
                  </div>

                  <div v-if="selected.menu_absen === 'Pulang'">
                    <span v-if="selected.detail_absen.end_datetime !== ''">
                      Tanggal {{ selected.detail_absen.end_datetime | date }} Pukul {{ selected.detail_absen.end_datetime | time }}
                    </span>

                    <span v-else>
                      Belum melakukan absensi pulang
                    </span>
                  </div>
                </div>
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.terapis_otw"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.terapis_otw = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.terapis_otw = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Terapis OTW
                </span>
              </v-card-title>

              <v-card-text
                class="pt-5 pb-0 mt-16">
                <v-text-field
                  v-model="search.terapis_otw"
                  @click:clear="search.terapis_otw = ''; fetchTerapisOtw();"
                  v-on:keyup.enter="fetchTerapisOtw()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>
              </v-card-text>

              <v-card-text
                class="pb-0"
                style="height: calc(100vh - 185px); overflow: auto;">
                <v-row>
                  <v-col
                    cols="12">
                    <v-skeleton-loader
                      v-for="(item, index) in process.terapis_otw ? 5 : terapis_otw"
                      :key="index"
                      :loading="process.terapis_otw"
                      :class="index < terapis_otw.length - 1 ? 'mb-4' : ''"
                      type="list-item-avatar-three-line">
                      <template>
                        <div>
                          <v-list
                            v-if="!process.terapis_otw && terapis_otw.length > 0"
                            flat
                            dense
                            two-line
                            color="#FFFFFF40"
                            class="border-radius">
                            <v-list-item>
                              <v-list-item-avatar
                                size="40"
                                class="mt-0"
                                style="border: 2px solid #ffffff;">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="item.user_image_url !== '' ? item.user_image_url : require('@/assets/image/member_default.jpg')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        size="20"
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title
                                  class="body-2 font-weight-bold text-capitalize">
                                  {{ item.user_name }}
                                </v-list-item-title>

                                <v-list-item-subtitle
                                  class="body-2 font-weight-light line-text-second black--text text-capitalize"
                                  style="opacity: 70%;">
                                  Status. {{ item.status }}
                                </v-list-item-subtitle>

                                <v-list-item-subtitle
                                  class="body-2">
                                  Tanggal {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </template>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <div v-if="!process.terapis_otw && pagination.terapis_otw.total_data > 10 && limit < pagination.terapis_otw.total_data"
                  class="text-center my-4">
                  <v-btn
                    @click="limit += 10"
                    :loading="process.limit"
                    small
                    rounded
                    outlined
                    elevation="0"
                    :color="set_color"
                    class="text-capitalize"
                    :style="`color: ${set_color};`">
                    Lebih banyak
                  </v-btn>
                </div>

                <Empty v-if="!process.terapis_otw && pagination.terapis_otw.total_page < 1"
                  class="py-6"
                  margin="my-16"
                  size="125"
                  message="Terapis OTW" />
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.terapis_mulai"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.terapis_mulai = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.terapis_mulai = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Terapis Mulai
                </span>
              </v-card-title>

              <v-card-text
                class="pt-5 pb-0 mt-16">
                <v-text-field
                  v-model="search.terapis_mulai"
                  @click:clear="search.terapis_mulai = ''; fetchTerapisMulai();"
                  v-on:keyup.enter="fetchTerapisMulai()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>
              </v-card-text>

              <v-card-text
                class="pb-0"
                style="height: calc(100vh - 185px); overflow: auto;">
                <v-row>
                  <v-col
                    cols="12">
                    <v-skeleton-loader
                      v-for="(item, index) in process.terapis_mulai ? 5 : terapis_mulai"
                      :key="index"
                      :loading="process.terapis_mulai"
                      :class="index < terapis_mulai.length - 1 ? 'mb-4' : ''"
                      type="list-item-avatar-three-line">
                      <template>
                        <div>
                          <v-list
                            v-if="!process.terapis_mulai && terapis_mulai.length > 0"
                            flat
                            dense
                            two-line
                            color="#FFFFFF40"
                            class="border-radius">
                            <v-list-item>
                              <v-list-item-avatar
                                size="40"
                                class="mt-0"
                                style="border: 2px solid #ffffff;">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="item.user_image_url !== '' ? item.user_image_url : require('@/assets/image/member_default.jpg')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        size="20"
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title
                                  class="body-2 font-weight-bold text-capitalize">
                                  {{ item.user_name }}
                                </v-list-item-title>

                                <v-list-item-subtitle
                                  class="body-2 font-weight-light line-text-second black--text text-capitalize"
                                  style="opacity: 70%;">
                                  Status. {{ item.status }}
                                </v-list-item-subtitle>

                                <v-list-item-subtitle
                                  class="body-2">
                                  Tanggal {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </template>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <div v-if="!process.terapis_mulai && pagination.terapis_mulai.total_data > 10 && limit < pagination.terapis_mulai.total_data"
                  class="text-center my-4">
                  <v-btn
                    @click="limit += 10"
                    :loading="process.limit"
                    small
                    rounded
                    outlined
                    elevation="0"
                    :color="set_color"
                    class="text-capitalize"
                    :style="`color: ${set_color};`">
                    Lebih banyak
                  </v-btn>
                </div>

                <Empty v-if="!process.terapis_mulai && pagination.terapis_mulai.total_page < 1"
                  class="py-6"
                  margin="my-16"
                  size="125"
                  message="Terapis Mulai" />
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.terapis_selesai"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.terapis_selesai = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.terapis_selesai = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Terapis Selesai
                </span>
              </v-card-title>

              <v-card-text
                class="pt-5 pb-0 mt-16">
                <v-text-field
                  v-model="search.terapis_selesai"
                  @click:clear="search.terapis_selesai = ''; fetchTerapisSelesai();"
                  v-on:keyup.enter="fetchTerapisSelesai()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>
              </v-card-text>

              <v-card-text
                class="pb-0"
                style="height: calc(100vh - 185px); overflow: auto;">
                <v-row>
                  <v-col
                    cols="12">
                    <v-skeleton-loader
                      v-for="(item, index) in process.terapis_selesai ? 5 : terapis_selesai"
                      :key="index"
                      :loading="process.terapis_selesai"
                      :class="index < terapis_selesai.length - 1 ? 'mb-4' : ''"
                      type="list-item-avatar-three-line">
                      <template>
                        <div>
                          <v-list
                            v-if="!process.terapis_selesai && terapis_selesai.length > 0"
                            flat
                            dense
                            two-line
                            color="#FFFFFF40"
                            class="border-radius">
                            <v-list-item>
                              <v-list-item-avatar
                                size="40"
                                class="mt-0"
                                style="border: 2px solid #ffffff;">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="item.user_image_url !== '' ? item.user_image_url : require('@/assets/image/member_default.jpg')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        size="20"
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title
                                  class="body-2 font-weight-bold text-capitalize">
                                  {{ item.user_name }}
                                </v-list-item-title>

                                <v-list-item-subtitle
                                  class="body-2 font-weight-light line-text-second black--text text-capitalize"
                                  style="opacity: 70%;">
                                  Status. {{ item.status }}
                                </v-list-item-subtitle>

                                <v-list-item-subtitle
                                  class="body-2">
                                  Tanggal {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </template>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <div v-if="!process.terapis_selesai && pagination.terapis_selesai.total_data > 10 && limit < pagination.terapis_selesai.total_data"
                  class="text-center my-4">
                  <v-btn
                    @click="limit += 10"
                    :loading="process.limit"
                    small
                    rounded
                    outlined
                    elevation="0"
                    :color="set_color"
                    class="text-capitalize"
                    :style="`color: ${set_color};`">
                    Lebih banyak
                  </v-btn>
                </div>

                <Empty v-if="!process.terapis_selesai && pagination.terapis_selesai.total_page < 1"
                  class="py-6"
                  margin="my-16"
                  size="125"
                  message="Terapis Selesai" />
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.over_time_daily"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.over_time_daily = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.over_time_daily = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Overtime Karyawan
                </span>
              </v-card-title>

              <v-card-text
                class="pt-5 pb-0 mt-16">
                <v-text-field
                  v-model="search.over_time_daily"
                  @click:clear="search.over_time_daily = ''; fetchOverTimeDaily();"
                  v-on:keyup.enter="fetchOverTimeDaily()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>
              </v-card-text>

              <v-card-text
                class="pb-0"
                style="height: calc(100vh - 185px); overflow: auto;">
                <v-row>
                  <v-col
                    cols="12">
                    <v-skeleton-loader
                      v-for="(item, index) in process.over_time_daily ? 5 : over_time_daily"
                      :key="index"
                      :loading="process.over_time_daily"
                      :class="index < over_time_daily.length - 1 ? 'mb-4' : ''"
                      type="list-item-avatar-three-line">
                      <template>
                        <div>
                          <v-list
                            v-if="!process.over_time_daily && over_time_daily.length > 0"
                            flat
                            dense
                            two-line
                            color="#FFFFFF40"
                            class="border-radius">
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="body-2 font-weight-bold text-capitalize">
                                  {{ item.user_name }}
                                </v-list-item-title>

                                <v-list-item-subtitle
                                  class="body-2 font-weight-light line-text-second black--text text-capitalize"
                                  style="opacity: 70%;">
                                  Tanggal {{ item.date | date }}
                                </v-list-item-subtitle>

                                <v-list-item-subtitle
                                  class="body-2">
                                  Total. {{ item.sum_over_time }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </template>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <div v-if="!process.over_time_daily && pagination.over_time_daily.total_data > 10 && limit < pagination.over_time_daily.total_data"
                  class="text-center my-4">
                  <v-btn
                    @click="limit += 10"
                    :loading="process.limit"
                    small
                    rounded
                    outlined
                    elevation="0"
                    :color="set_color"
                    class="text-capitalize"
                    :style="`color: ${set_color};`">
                    Lebih banyak
                  </v-btn>
                </div>

                <Empty v-if="!process.over_time_daily && pagination.over_time_daily.total_page < 1"
                  class="py-6"
                  margin="my-16"
                  size="125"
                  message="Overtime Karyawan" />
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.jadwal_today_by_store"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.jadwal_today_by_store = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.jadwal_today_by_store = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Jadwal Hari Ini
                </span>
              </v-card-title>

              <v-card-text
                class="pt-5 pb-0 mt-16">
                <v-text-field
                  v-model="search.jadwal_today_by_store"
                  @click:clear="search.jadwal_today_by_store = ''; fetchJadwalTodayByStore();"
                  v-on:keyup.enter="fetchJadwalTodayByStore()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>
              </v-card-text>

              <v-card-text
                class="pb-0"
                style="height: calc(100vh - 185px); overflow: auto;">
                <v-row>
                  <v-col
                    cols="12">
                    <v-skeleton-loader
                      v-for="(item, index) in process.jadwal_today_by_store ? 5 : jadwal_today_by_store"
                      :key="index"
                      :loading="process.jadwal_today_by_store"
                      :class="index < jadwal_today_by_store.length - 1 ? 'mb-4' : ''"
                      type="list-item-avatar-three-line">
                      <template>
                        <div>
                          <v-list
                            v-if="!process.jadwal_today_by_store && jadwal_today_by_store.length > 0"
                            flat
                            dense
                            two-line
                            color="#FFFFFF40"
                            class="border-radius">
                            <v-list-item>
                              <v-list-item-avatar
                                size="40"
                                class="mt-0"
                                style="border: 2px solid #ffffff;">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        size="20"
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title
                                  class="body-2 font-weight-bold text-capitalize">
                                  {{ item.customer_name }}
                                </v-list-item-title>

                                <v-list-item-subtitle
                                  class="body-2 font-weight-light line-text-second black--text text-capitalize"
                                  style="opacity: 70%;">
                                  {{ item.customer_mobilephone }}
                                </v-list-item-subtitle>

                                <v-list-item-subtitle
                                  class="body-2">
                                  {{ item.store_name }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </template>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <div v-if="!process.jadwal_today_by_store && pagination.jadwal_today_by_store.total_data > 10 && limit < pagination.jadwal_today_by_store.total_data"
                  class="text-center my-4">
                  <v-btn
                    @click="limit += 10"
                    :loading="process.limit"
                    small
                    rounded
                    outlined
                    elevation="0"
                    :color="set_color"
                    class="text-capitalize"
                    :style="`color: ${set_color};`">
                    Lebih banyak
                  </v-btn>
                </div>

                <Empty v-if="!process.jadwal_today_by_store && pagination.jadwal_today_by_store.total_page < 1"
                  class="py-6"
                  margin="my-16"
                  size="125"
                  message="Jadwal Hari Ini" />
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.klien_rutin_tomorrow"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.klien_rutin_tomorrow = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.klien_rutin_tomorrow = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Klien Rutin Hari Besok
                </span>
              </v-card-title>

              <v-card-text
                class="pt-5 pb-0 mt-16">
                <v-text-field
                  v-model="search.klien_rutin_tomorrow"
                  @click:clear="search.klien_rutin_tomorrow = ''; fetchKlienRutinTomorrow();"
                  v-on:keyup.enter="fetchKlienRutinTomorrow()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>
              </v-card-text>

              <v-card-text
                class="pb-0"
                style="height: calc(100vh - 185px); overflow: auto;">
                <v-row>
                  <v-col
                    cols="12">
                    <v-skeleton-loader
                      v-for="(item, index) in process.klien_rutin_tomorrow ? 5 : klien_rutin_tomorrow"
                      :key="index"
                      :loading="process.klien_rutin_tomorrow"
                      :class="index < klien_rutin_tomorrow.length - 1 ? 'mb-4' : ''"
                      type="list-item-avatar-three-line">
                      <template>
                        <div>
                          <v-list
                            v-if="!process.klien_rutin_tomorrow && klien_rutin_tomorrow.length > 0"
                            flat
                            dense
                            two-line
                            color="#FFFFFF40"
                            class="border-radius">
                            <v-list-item>
                              <v-list-item-avatar
                                size="40"
                                class="mt-0"
                                style="border: 2px solid #ffffff;">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        size="20"
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title
                                  class="body-2 font-weight-bold text-capitalize">
                                  {{ item.customer_name }}
                                </v-list-item-title>

                                <v-list-item-subtitle
                                  class="body-2 font-weight-light line-text-second black--text text-capitalize"
                                  style="opacity: 70%;">
                                  {{ item.customer_mobilephone }}
                                </v-list-item-subtitle>

                                <v-list-item-subtitle
                                  class="body-2 font-weight-light black--text"
                                  style="opacity: 70%;">
                                  {{ item.content }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </template>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <!-- <div v-if="!process.klien_rutin_tomorrow && pagination.klien_rutin_tomorrow.total_data > 10 && limit < pagination.klien_rutin_tomorrow.total_data"
                  class="text-center my-4">
                  <v-btn
                    @click="limit += 10"
                    :loading="process.limit"
                    small
                    rounded
                    outlined
                    elevation="0"
                    :color="set_color"
                    class="text-capitalize"
                    :style="`color: ${set_color};`">
                    Lebih banyak
                  </v-btn>
                </div>

                <Empty v-if="!process.klien_rutin_tomorrow && pagination.klien_rutin_tomorrow.total_page < 1"
                  class="py-6"
                  margin="my-16"
                  size="125"
                  message="Klien Rutin Hari Besok" /> -->
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.titipan_belum_kembali"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.titipan_belum_kembali = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.titipan_belum_kembali = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Titipan Belum Dikembalikan
                </span>
              </v-card-title>

              <v-card-text
                class="pt-5 pb-0 mt-16">
                <v-text-field
                  v-model="search.titipan_belum_kembali"
                  @click:clear="search.titipan_belum_kembali = ''; fetchTitipanBelumKembali();"
                  v-on:keyup.enter="fetchTitipanBelumKembali()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>
              </v-card-text>

              <v-card-text
                class="pb-0"
                style="height: calc(100vh - 185px); overflow: auto;">
                <v-row>
                  <v-col
                    cols="12">
                    <v-skeleton-loader
                      v-for="(item, index) in process.titipan_belum_kembali ? 5 : titipan_belum_kembali"
                      :key="index"
                      :loading="process.titipan_belum_kembali"
                      :class="index < titipan_belum_kembali.length - 1 ? 'mb-4' : ''"
                      type="list-item-avatar-three-line">
                      <template>
                        <div>
                          <v-list
                            v-if="!process.titipan_belum_kembali && titipan_belum_kembali.length > 0"
                            flat
                            dense
                            three-line
                            color="#FFFFFF40"
                            class="border-radius">
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-subtitle>
                                  <div
                                    class="caption mb-2 one-line d-flex align-center">
                                    <v-avatar
                                      size="25"
                                      style="border: 2px solid #ffffff;">
                                      <v-img
                                        width="100%"
                                        height="100%"
                                        alt="bidanvitacare"
                                        :src="item.therapist_image_url !== '' ? item.therapist_image_url : require('@/assets/image/member_default.jpg')"
                                        class="ma-auto">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular
                                              size="20"
                                              indeterminate
                                              :color="set_color">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-avatar>

                                    <span
                                      class="ml-1">
                                      {{ item.therapist_name }}
                                    </span>
                                  </div>
                                </v-list-item-subtitle>

                                <v-list-item-title
                                  class="body-2 font-weight-bold text-capitalize">
                                  {{ item.so_code }}
                                </v-list-item-title>

                                <v-list-item-subtitle
                                  class="body-2 font-weight-light line-text-second black--text my-2 py-2"
                                  style="border-top: 1px dashed #bbb; border-bottom: 1px dashed #bbb;">
                                  <div
                                    class="d-flex align-center">
                                    <div
                                      class="caption"
                                      style="opacity: 60%; margin-bottom: 1px;">
                                      Nominal
                                    </div>

                                    <v-spacer />

                                    <div
                                      class="body-2 black--text">
                                      {{ item.nominal | price }}
                                    </div>
                                  </div>

                                  <!-- <v-divider
                                    class="my-2"
                                    style="border-top: 1px dashed #bbb;" />

                                  <div
                                    class="d-flex align-center">
                                    <div
                                      class="caption"
                                      style="opacity: 60%; margin-bottom: 1px;">
                                      Dikembalikan
                                    </div>

                                    <v-spacer />

                                    <div
                                      class="body-2 black--text">
                                      {{ item.nominal_return | price }}
                                    </div>
                                  </div> -->
                                </v-list-item-subtitle>

                                <v-list-item-subtitle
                                  class="body-2 line-text-second">
                                  {{ item.create_datetime | date }} Pukul {{ item.create_datetime | time }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </template>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <div v-if="!process.titipan_belum_kembali && pagination.titipan_belum_kembali.total_data > 10 && limit < pagination.titipan_belum_kembali.total_data"
                  class="text-center my-4">
                  <v-btn
                    @click="limit += 10"
                    :loading="process.limit"
                    small
                    rounded
                    outlined
                    elevation="0"
                    :color="set_color"
                    class="text-capitalize"
                    :style="`color: ${set_color};`">
                    Lebih banyak
                  </v-btn>
                </div>

                <Empty v-if="!process.titipan_belum_kembali && pagination.titipan_belum_kembali.total_page < 1"
                  class="py-6"
                  margin="my-16"
                  size="125"
                  message="Titipan Belum Dikembalikan" />
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.stock_sekarang"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.stock_sekarang = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.stock_sekarang = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Data Stock Barang
                </span>
              </v-card-title>

              <v-card-text
                class="pt-5 pb-0 mt-16">
                <v-text-field
                  v-model="search.stock_sekarang"
                  @click:clear="search.stock_sekarang = ''; fetchStockSekarang();"
                  v-on:keyup.enter="fetchStockSekarang()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>
              </v-card-text>

              <v-card-text
                class="pb-0"
                style="height: calc(100vh - 185px); overflow: auto;">
                <v-row>
                  <v-col
                    cols="12">
                    <v-skeleton-loader
                      v-for="(item, index) in process.stock_sekarang ? 5 : stock_sekarang"
                      :key="index"
                      :loading="process.stock_sekarang"
                      :class="index < stock_sekarang.length - 1 ? 'mb-4' : ''"
                      type="list-item-avatar-three-line">
                      <template>
                        <div>
                          <v-list
                            v-if="!process.stock_sekarang && stock_sekarang.length > 0"
                            flat
                            dense
                            three-line
                            color="#FFFFFF40"
                            class="border-radius">
                            <v-list-item>
                              <v-list-item-avatar
                                size="40"
                                tile
                                class="mt-2"
                                style="border: 2px solid #ffffff; border-radius: 5px !important;">
                                <v-img
                                  contain
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        size="20"
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <div>
                                  <v-chip
                                    x-small
                                    class="caption white--text text-capitalize"
                                    :color="set_color"
                                    style="margin-bottom: 5px; max-width: 250px;">
                                    <span
                                      class="one-line">
                                      {{ item.category_name !== '' ? item.category_name : '-' }}
                                    </span>
                                  </v-chip>
                                </div>

                                <v-list-item-title
                                  class="body-2 font-weight-bold text-capitalize">
                                  {{ item.name }}
                                </v-list-item-title>

                                <v-list-item-subtitle
                                  class="body-2 font-weight-light line-text-second black--text"
                                  style="opacity: 70%;">
                                  Stok. {{ item.sum_stock }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </template>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <div v-if="!process.stock_sekarang && pagination.stock_sekarang.total_data > 10 && limit < pagination.stock_sekarang.total_data"
                  class="text-center my-4">
                  <v-btn
                    @click="limit += 10"
                    :loading="process.limit"
                    small
                    rounded
                    outlined
                    elevation="0"
                    :color="set_color"
                    class="text-capitalize"
                    :style="`color: ${set_color};`">
                    Lebih banyak
                  </v-btn>
                </div>

                <Empty v-if="!process.stock_sekarang && pagination.stock_sekarang.total_page < 1"
                  class="py-6"
                  margin="my-16"
                  size="125"
                  message="Data Stock Barang" />
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.transaksi_hari_ini"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.transaksi_hari_ini = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.transaksi_hari_ini = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Pemasukan Hari Ini
                </span>
              </v-card-title>

              <v-card-text
                class="pt-5 pb-0 mt-16">
                <v-text-field
                  v-model="search.transaksi_hari_ini"
                  @click:clear="search.transaksi_hari_ini = ''; fetchTransaksiHariIni();"
                  v-on:keyup.enter="fetchTransaksiHariIni()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>
              </v-card-text>

              <v-card-text
                class="pb-0"
                style="height: calc(100vh - 185px); overflow: auto;">
                <v-row>
                  <v-col
                    cols="12">
                    <v-skeleton-loader
                      v-for="(item, index) in process.transaksi_hari_ini ? 5 : transaksi_hari_ini"
                      :key="index"
                      :loading="process.transaksi_hari_ini"
                      :class="index < transaksi_hari_ini.length - 1 ? 'mb-4' : ''"
                      type="list-item-avatar-three-line">
                      <template>
                        <div>
                          <v-list
                            v-if="!process.transaksi_hari_ini && transaksi_hari_ini.length > 0"
                            flat
                            dense
                            two-line
                            color="#FFFFFF40"
                            class="border-radius">
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-subtitle
                                  class="body-2 black--text text-capitalize d-flex align-center">
                                  <div
                                    class="text-truncate">
                                    {{ item.customer_name }}
                                  </div>

                                  <v-spacer />

                                  <div>
                                    <v-chip
                                      small
                                      class="white--text"
                                      :color="item.status === 'complete' ? 'green' :
                                              item.status === 'refund' ? 'orange' :
                                              item.status === 'cancel' ? 'red' : 'grey'">
                                      {{ item.status }}
                                    </v-chip>
                                  </div>
                                </v-list-item-subtitle>

                                <v-list-item-title
                                  class="body-2 font-weight-bold text-capitalize my-2 py-3 d-flex align-center"
                                  style="border-top: 1px dashed #bbb; border-bottom: 1px dashed #bbb;">
                                  <div>
                                    {{ item.code }}
                                  </div>
                                </v-list-item-title>

                                <v-list-item-subtitle
                                  class="d-flex align-center">
                                  <div
                                    class="body-2 font-weight-light black--text">
                                    Jlm Item. {{ item.total_item_qty }}
                                  </div>

                                  <v-spacer />

                                  <div
                                    class="body-2">
                                    Total. <strong class="black--text">{{ item.grand_total | price }}</strong>
                                  </div>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </template>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <!-- <div v-if="!process.transaksi_hari_ini && pagination.transaksi_hari_ini.total_data > 10 && limit < pagination.transaksi_hari_ini.total_data"
                  class="text-center my-4">
                  <v-btn
                    @click="limit += 10"
                    :loading="process.limit"
                    small
                    rounded
                    outlined
                    elevation="0"
                    :color="set_color"
                    class="text-capitalize"
                    :style="`color: ${set_color};`">
                    Lebih banyak
                  </v-btn>
                </div>

                <Empty v-if="!process.transaksi_hari_ini && pagination.transaksi_hari_ini.total_page < 1"
                  class="py-6"
                  margin="my-16"
                  size="125"
                  message="Pemasukan Hari Ini" /> -->
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.alat_pinjam_belum_kembali"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.alat_pinjam_belum_kembali = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.alat_pinjam_belum_kembali = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Alat Yang Masih Dipinjam
                </span>
              </v-card-title>

              <v-card-text
                class="pt-5 pb-0 mt-16">
                <v-text-field
                  v-model="search.alat_pinjam_belum_kembali"
                  @click:clear="search.alat_pinjam_belum_kembali = ''; fetchAlatPinjamBelumKembali();"
                  v-on:keyup.enter="fetchAlatPinjamBelumKembali()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>
              </v-card-text>

              <v-card-text
                class="pb-0"
                style="height: calc(100vh - 185px); overflow: auto;">
                <v-row>
                  <v-col
                    cols="12">
                    <v-skeleton-loader
                      v-for="(item, index) in process.alat_pinjam_belum_kembali ? 5 : alat_pinjam_belum_kembali"
                      :key="index"
                      :loading="process.alat_pinjam_belum_kembali"
                      :class="index < alat_pinjam_belum_kembali.length - 1 ? 'mb-4' : ''"
                      type="list-item-avatar-three-line">
                      <template>
                        <div>
                          <v-list
                            v-if="!process.alat_pinjam_belum_kembali && alat_pinjam_belum_kembali.length > 0"
                            flat
                            dense
                            three-line
                            color="#FFFFFF40"
                            class="border-radius">
                            <v-list-item>
                              <v-list-item-avatar
                                size="40"
                                class="mt-2"
                                style="border: 2px solid #ffffff;">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="item.user_image_url !== '' ? item.user_image_url : require('@/assets/image/member_default.jpg')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        size="20"
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <div v-if="item.status_pinjam === 'Pengajuan'">
                                  <v-chip
                                    x-small
                                    class="caption white--text text-capitalize"
                                    :color="set_color"
                                    style="margin-bottom: 5px;">
                                    {{ item.status_pinjam }}
                                  </v-chip>
                                </div>

                                <v-list-item-title
                                  class="body-2 font-weight-bold">
                                  {{ item.user_name }}
                                </v-list-item-title>

                                <v-list-item-subtitle
                                  class="body-2 font-weight-light line-text-second black--text my-1">
                                  {{ item.item_name }}

                                  <v-divider
                                    class="my-2"
                                    style="border-top: 1px dashed #bbb;" />
                                </v-list-item-subtitle>

                                <v-list-item-subtitle
                                  class="d-flex align-center">
                                  <div>
                                    <div
                                      class="caption mb-1">
                                      Jumlah
                                    </div>

                                    <div
                                      class="black--text">
                                      {{ item.qty }} Pcs
                                    </div>
                                  </div>

                                  <v-spacer />

                                  <div>
                                    <div
                                      class="caption mb-1">
                                      Stok
                                    </div>

                                    <div
                                      class="black--text">
                                      {{ item.sum_stock }} Pcs
                                    </div>
                                  </div>
                                </v-list-item-subtitle>

                                <!-- <v-list-item-subtitle
                                  class="body-2 font-weight-light line-text-second black--text my-1">
                                  <div>
                                    <v-divider
                                      class="my-2"
                                      style="border-top: 1px dashed #bbb;" />

                                    <div
                                      class="d-flex align-center">
                                      <div>
                                        {{ item.status_pinjam }} Oleh
                                      </div>

                                      <v-spacer />

                                      <div
                                        class="d-flex align-center">
                                        <v-avatar
                                          size="24"
                                          style="border: 2px solid #ffffff;">
                                          <v-img
                                            width="100%"
                                            height="100%"
                                            alt="bidanvitacare"
                                            :src="item.status_user_image_url !== '' ? item.status_user_image_url : require('@/assets/image/member_default.jpg')"
                                            class="ma-auto">
                                            <template v-slot:placeholder>
                                              <v-row
                                                class="fill-height"
                                                align="center"
                                                justify="center">
                                                <v-progress-circular
                                                  size="20"
                                                  indeterminate
                                                  :color="set_color">
                                                </v-progress-circular>
                                              </v-row>
                                            </template>
                                          </v-img>
                                        </v-avatar>

                                        <span
                                          class="caption font-weight-bold one-line ml-1">
                                          {{ item.status_user_name }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div v-if="item.return_user_name !== ''">
                                    <v-divider
                                      class="my-2"
                                      style="border-top: 1px dashed #bbb;" />

                                    <div
                                      class="d-flex align-center">
                                      <div>
                                        Diterima Oleh
                                      </div>

                                      <v-spacer />

                                      <div
                                        class="d-flex align-center">
                                        <v-avatar
                                          size="24"
                                          style="border: 2px solid #ffffff;">
                                          <v-img
                                            width="100%"
                                            height="100%"
                                            alt="bidanvitacare"
                                            :src="item.return_user_image_url !== '' ? item.return_user_image_url : require('@/assets/image/member_default.jpg')"
                                            class="ma-auto">
                                            <template v-slot:placeholder>
                                              <v-row
                                                class="fill-height"
                                                align="center"
                                                justify="center">
                                                <v-progress-circular
                                                  size="20"
                                                  indeterminate
                                                  :color="set_color">
                                                </v-progress-circular>
                                              </v-row>
                                            </template>
                                          </v-img>
                                        </v-avatar>

                                        <span
                                          class="caption font-weight-bold one-line ml-1">
                                          {{ item.return_user_name }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <v-divider
                                    class="my-2"
                                    style="border-top: 1px dashed #bbb;" />

                                  {{ item.note !== '' ? item.note : '-' }}
                                </v-list-item-subtitle> -->
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </template>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <div v-if="!process.alat_pinjam_belum_kembali && pagination.alat_pinjam_belum_kembali.total_data > 10 && limit < pagination.alat_pinjam_belum_kembali.total_data"
                  class="text-center my-4">
                  <v-btn
                    @click="limit += 10"
                    :loading="process.limit"
                    small
                    rounded
                    outlined
                    elevation="0"
                    :color="set_color"
                    class="text-capitalize"
                    :style="`color: ${set_color};`">
                    Lebih banyak
                  </v-btn>
                </div>

                <Empty v-if="!process.alat_pinjam_belum_kembali && pagination.alat_pinjam_belum_kembali.total_page < 1"
                  class="py-6"
                  margin="my-16"
                  size="125"
                  message="Alat Yang Masih Dipinjam" />
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import axios from "axios";
  import moment from "moment"
  import "moment/locale/id"
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dateNow: new Date(),

        sheet: {
          all_absen: false,
          terapis_otw: false,
          terapis_mulai: false,
          terapis_selesai: false,
          over_time_daily: false,
          jadwal_today_by_store: false,
          klien_rutin_tomorrow: false,
          titipan_belum_kembali: false,
          stock_sekarang: false,
          transaksi_hari_ini: false,
          alat_pinjam_belum_kembali: false,

          detail_absen: false
        },

        search: {
          all_absen: '',
          terapis_otw: '',
          terapis_mulai: '',
          terapis_selesai: '',
          over_time_daily: '',
          jadwal_today_by_store: '',
          klien_rutin_tomorrow: '',
          titipan_belum_kembali: '',
          stock_sekarang: '',
          transaksi_hari_ini: '',
          alat_pinjam_belum_kembali: ''
        },

        selected: {
          detail_absen: {},

          menu_absen: 'Kehadiran'
        },

        menu_absen: ['Kehadiran', 'Pulang'],

        menu: [
          {
            text: 'Semua Absen',
            icon: require('@/assets/icon/fingerprint.png'),
            total: 0
          },
          {
            text: 'Terapis OTW',
            icon: require('@/assets/image/footer/nurse_bvc.png'),
            total: 0
          },
          {
            text: 'Terapis Mulai',
            icon: require('@/assets/icon/fingerprint_attendance_start.png'),
            total: 0
          },
          {
            text: 'Terapis Selesai',
            icon: require('@/assets/icon/fingerprint_attendance_stop.png'),
            total: 0
          },
          {
            text: 'Overtime Karyawan',
            icon: require('@/assets/icon/check_in.png'),
            total: 0
          },
          {
            text: 'Jadwal Hari Ini',
            icon: require('@/assets/icon/calendar_pending.png'),
            total: 0
          },
          {
            text: 'Klien Rutin Hari Besok',
            icon: require('@/assets/image/footer/client_rutin.png'),
            total: 0
          },
          {
            text: 'Titipan Belum Dikembalikan',
            icon: require('@/assets/image/footer/titipan.png'),
            total: 0
          },
          {
            text: 'Data Stock Barang',
            icon: require('@/assets/icon/items.png'),
            total: 0
          },
          {
            text: 'Pemasukan Hari Ini',
            icon: require('@/assets/icon/money_send.png'),
            total: 0
          },
          {
            text: 'Alat Yang Masih Dipinjam',
            icon: require('@/assets/icon/money_send.png'),
            total: 0
          },
        ],

        bar: {
          series: [
            {
              name: 'Series 1',
              data: [45, 52, 38, 45, 19, 23, 2]
            },
            {
              name: 'Series 2',
              data: [20, 42, 18, 55, 29, 43, 12]
            }
          ],
          chartOptions: {
            chart: {
              animations: {
                enabled: true,
                easing: 'linear',
                dynamicAnimation: {
                  speed: 1000
                }
              },
              toolbar: {
                show: true
              },
              zoom: {
                enabled: true
              },
              type: 'area'
            },
            stroke: {
              width: 3,
              curve: 'smooth'
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: false
            },
            colors: ['#00BCD4', '#F44336'],
            yaxis: {
              labels: {
                formatter: function (value) {
                  return Math.round(value)
                }
              }
            },
            xaxis: {
              categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'Mei',
                'Jun',
                'Jul',
                'Agu',
                'Sep',
                'Okt',
                'Nov',
                'Des'
              ]
            }
          }
        },

        summary: {},

        all_absen: [],
        terapis_otw: [],
        terapis_mulai: [],
        terapis_selesai: [],
        over_time_daily: [],
        jadwal_today_by_store: [],
        klien_rutin_tomorrow: [],
        titipan_belum_kembali: [],
        stock_sekarang: [],
        transaksi_hari_ini: [],
        alat_pinjam_belum_kembali: [],

        pagination: {
          all_absen: {},
          terapis_otw: {},
          terapis_mulai: {},
          terapis_selesai: {},
          over_time_daily: {},
          jadwal_today_by_store: {},
          klien_rutin_tomorrow: {},
          titipan_belum_kembali: {},
          stock_sekarang: {},
          transaksi_hari_ini: {},
          alat_pinjam_belum_kembali: {}
        },

        limit: 10,

        process: {
          summary: false,

          limit: false,

          all_absen: {},
          terapis_otw: {},
          terapis_mulai: {},
          terapis_selesai: {},
          over_time_daily: {},
          jadwal_today_by_store: {},
          klien_rutin_tomorrow: {},
          titipan_belum_kembali: {},
          stock_sekarang: {},
          transaksi_hari_ini: {},
          alat_pinjam_belum_kembali: {},
        },

        // SEO
        content: {
          url: '/dashboard',
          title: 'Dasboard',
          description: 'Dasboard Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.process.limit = true

        if (this.sheet.all_absen) {
          this.fetchAllAbsen('limit')
        }

        if (this.sheet.terapis_otw) {
          this.fetchTerapisOtw('limit')
        }

        if (this.sheet.terapis_mulai) {
          this.fetchTerapisMulai('limit')
        }

        if (this.sheet.terapis_selesai) {
          this.fetchTerapisSelesai('limit')
        }

        if (this.sheet.over_time_daily) {
          this.fetchOverTimeDaily('limit')
        }

        if (this.sheet.jadwal_today_by_store) {
          this.fetchJadwalTodayByStore('limit')
        }

        // TIDAK PAGING
        // if (this.sheet.klien_rutin_tomorrow) {
        //   this.fetchKlienRutinTomorrow('limit')
        // }

        if (this.sheet.titipan_belum_kembali) {
          this.fetchTitipanBelumKembali('limit')
        }

        if (this.sheet.stock_sekarang) {
          this.fetchStockSekarang('limit')
        }

        // TIDAK PAGING
        // if (this.sheet.transaksi_hari_ini) {
        //   this.fetchTransaksiHariIni('limit')
        // }

        if (this.sheet.alat_pinjam_belum_kembali) {
          this.fetchAlatPinjamBelumKembali('limit')
        }
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_soft () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.fetchSummary()

        this.fetchAllAbsen()
        this.fetchTerapisOtw()
        this.fetchTerapisMulai()
        this.fetchTerapisSelesai()
        this.fetchOverTimeDaily()
        this.fetchJadwalTodayByStore()
        this.fetchKlienRutinTomorrow()
        this.fetchTitipanBelumKembali()
        this.fetchStockSekarang()
        this.fetchTransaksiHariIni()
        this.fetchAlatPinjamBelumKembali()
      },

      async fetchSummary () {
        this.process.summary = true

        let params = {

        }

        await this.$axios.$get(`${process.env.API}admin/dashboard/summary`, { params })
        .then((response) => {
          this.process.summary = false

          if (response.status === 200) {
            this.summary = response.results

            let arrays = Object.keys(this.summary.graph_so_month_year).map((key) => this.summary.graph_so_month_year[key])

            this.bar = {
              series: [
                {
                  name: 'Data SO',
                  data: arrays
                }
              ],
              chartOptions: {
                chart: {
                  animations: {
                    enabled: true,
                    easing: 'linear',
                    dynamicAnimation: {
                      speed: 1000
                    }
                  },
                  toolbar: {
                    show: true
                  },
                  zoom: {
                    enabled: true
                  },
                  type: 'area'
                },
                stroke: {
                  width: 3,
                  curve: 'smooth'
                },
                dataLabels: {
                  enabled: false
                },
                legend: {
                  show: false
                },
                colors: [this.set_color],
                yaxis: {
                  labels: {
                    formatter: function (value) {
                      return Math.round(value)
                    }
                  }
                },
                xaxis: {
                  categories: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'Mei',
                    'Jun',
                    'Jul',
                    'Agu',
                    'Sep',
                    'Okt',
                    'Nov',
                    'Des'
                  ]
                }
              }
            }
          }
        })
      },

      async fetchAllAbsen (type) {
        this.process.all_absen = type === undefined ? true : false

        let params = {
          search: this.search.all_absen,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}admin/dashboard/all-absen`, { params })
        .then((response) => {

          this.process.all_absen = false

          this.process.limit = false

          if (response.status === 200) {
            this.all_absen = response.results.data
            this.pagination.all_absen = response.results.pagination

            this.menu[0].total = response.results.pagination.total_data
          }
        })
      },

      async fetchTerapisOtw (type) {
        this.process.terapis_otw = type === undefined ? true : false

        let params = {
          search: this.search.terapis_otw,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}admin/dashboard/therapist-otw`, { params })
        .then((response) => {

          this.process.terapis_otw = false

          this.process.limit = false

          if (response.status === 200) {
            this.terapis_otw = response.results.data
            this.pagination.terapis_otw = response.results.pagination

            this.menu[1].total = response.results.pagination.total_data
          }
        })
      },

      async fetchTerapisMulai (type) {
        this.process.terapis_mulai = type === undefined ? true : false

        let params = {
          search: this.search.terapis_mulai,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}admin/dashboard/therapist-mulai`, { params })
        .then((response) => {

          this.process.terapis_mulai = false

          this.process.limit = false

          if (response.status === 200) {
            this.terapis_mulai = response.results.data
            this.pagination.terapis_mulai = response.results.pagination

            this.menu[2].total = response.results.pagination.total_data
          }
        })
      },

      async fetchTerapisSelesai (type) {
        this.process.terapis_selesai = type === undefined ? true : false

        let params = {
          search: this.search.terapis_selesai,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}admin/dashboard/therapist-selesai`, { params })
        .then((response) => {

          this.process.terapis_selesai = false

          this.process.limit = false

          if (response.status === 200) {
            this.terapis_selesai = response.results.data
            this.pagination.terapis_selesai = response.results.pagination

            this.menu[3].total = response.results.pagination.total_data
          }
        })
      },

      async fetchOverTimeDaily (type) {
        this.process.over_time_daily = type === undefined ? true : false

        let params = {
          search: this.search.over_time_daily,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}admin/report/over-time/detail`, { params })
        .then((response) => {

          this.process.over_time_daily = false

          this.process.limit = false

          if (response.status === 200) {
            this.over_time_daily = response.results.data
            this.pagination.over_time_daily = response.results.pagination

            this.menu[4].total = response.results.pagination.total_data
          }
        })
      },

      async fetchJadwalTodayByStore (type) {
        this.process.jadwal_today_by_store = type === undefined ? true : false

        let params = {
          datetime: this.dateNow,
          store_id: this.user.store_id,

          search: this.search.jadwal_today_by_store,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}admin/schedule`, { params })
        .then((response) => {

          this.process.jadwal_today_by_store = false

          this.process.limit = false

          if (response.status === 200) {
            this.jadwal_today_by_store = response.results.data
            this.pagination.jadwal_today_by_store = response.results.pagination

            this.menu[5].total = response.results.pagination.total_data
          }
        })
      },

      // TIDAK PAGING
      async fetchKlienRutinTomorrow (type) {
        this.process.klien_rutin_tomorrow = type === undefined ? true : false

        let params = {
          search: this.search.klien_rutin_tomorrow,
          // limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}admin/dashboard/customer-routine-tomorrow`, { params })
        .then((response) => {

          this.process.klien_rutin_tomorrow = false

          this.process.limit = false

          if (response.status === 200) {
            this.klien_rutin_tomorrow = response.results.data
            // this.pagination.klien_rutin_tomorrow = response.results.pagination

            this.menu[6].total = response.results.data.length
          }
        })
      },

      async fetchTitipanBelumKembali (type) {
        this.process.titipan_belum_kembali = type === undefined ? true : false

        let params = {
          search: this.search.titipan_belum_kembali,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}admin/dashboard/titipan-belum-kembali`, { params })
        .then((response) => {

          this.process.titipan_belum_kembali = false

          this.process.limit = false

          if (response.status === 200) {
            this.titipan_belum_kembali = response.results.data
            this.pagination.titipan_belum_kembali = response.results.pagination

            this.menu[7].total = response.results.pagination.total_data
          }
        })
      },

      async fetchStockSekarang (type) {
        this.process.stock_sekarang = type === undefined ? true : false

        let params = {
          'type[eq]': 'product',

          search: this.search.stock_sekarang,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}admin/item`, { params })
        .then((response) => {

          this.process.stock_sekarang = false

          this.process.limit = false

          if (response.status === 200) {
            this.stock_sekarang = response.results.data
            this.pagination.stock_sekarang = response.results.pagination

            this.menu[8].total = response.results.pagination.total_data
          }
        })
      },

      // TIDAK PAGING
      async fetchTransaksiHariIni (type) {
        this.process.transaksi_hari_ini = type === undefined ? true : false

        let params = {
          status: 'complete',

          search: this.search.transaksi_hari_ini,
          // limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}admin/dashboard/today-transaction`, { params })
        .then((response) => {

          this.process.transaksi_hari_ini = false

          this.process.limit = false

          if (response.status === 200) {
            this.transaksi_hari_ini = response.results.data
            // this.pagination.transaksi_hari_ini = response.results.pagination

            this.menu[9].total = response.results.data.length
          }
        })
      },

      async fetchAlatPinjamBelumKembali (type) {
        this.process.alat_pinjam_belum_kembali = type === undefined ? true : false

        let params = {
          status: 'belum_kembali',
          status_pinjam: 'Disetujui',

          search: this.search.alat_pinjam_belum_kembali,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}admin/loan-item`, { params })
        .then((response) => {

          this.process.alat_pinjam_belum_kembali = false

          this.process.limit = false

          if (response.status === 200) {
            this.alat_pinjam_belum_kembali = response.results.data
            this.pagination.alat_pinjam_belum_kembali = response.results.pagination

            this.menu[10].total = response.results.pagination.total_data
          }
        })
      },

      to_sheet (index) {
        let sheet = Object.keys(this.sheet)[index]

        this.limit = 10

        if (sheet === 'all_absen') {
          this.sheet.all_absen = true

          this.fetchAllAbsen()
        }

        if (sheet === 'terapis_otw') {
          this.sheet.terapis_otw = true

          this.fetchTerapisOtw()
        }

        if (sheet === 'terapis_mulai') {
          this.sheet.terapis_mulai = true

          this.fetchTerapisMulai()
        }

        if (sheet === 'terapis_selesai') {
          this.sheet.terapis_selesai = true

          this.fetchTerapisSelesai()
        }

        if (sheet === 'over_time_daily') {
          this.sheet.over_time_daily = true

          this.fetchOverTimeDaily()
        }

        if (sheet === 'jadwal_today_by_store') {
          this.sheet.jadwal_today_by_store = true

          this.fetchJadwalTodayByStore()
        }

        // TIDAK PAGING
        if (sheet === 'klien_rutin_tomorrow') {
          this.sheet.klien_rutin_tomorrow = true

          this.fetchKlienRutinTomorrow()
        }

        if (sheet === 'titipan_belum_kembali') {
          this.sheet.titipan_belum_kembali = true

          this.fetchTitipanBelumKembali()
        }

        if (sheet === 'stock_sekarang') {
          this.sheet.stock_sekarang = true

          this.fetchStockSekarang()
        }

        // TIDAK PAGING
        if (sheet === 'transaksi_hari_ini') {
          this.sheet.transaksi_hari_ini = true

          this.fetchTransaksiHariIni()
        }

        if (sheet === 'alat_pinjam_belum_kembali') {
          this.sheet.alat_pinjam_belum_kembali = true

          this.fetchAlatPinjamBelumKembali()
        }
      }
    }
  }
</script>
