<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.info_berhasil">
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="125"
                height="125"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_berhasil.png')"
                class="ma-auto my-6">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Terima Kasih atas partisipasi anda dalam meningkatkan mutu layanan Bidanvitacare.id Semoga bunda dan keluarga sehat selalu.
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            height="100vh"
            class="transparent"
            style="overflow-y: auto;">
            <div
              id="nota">
              <v-card v-if="Object.keys(detail).length > 0"
                flat
                color="transparent">
                <v-card-text
                  class="text-center pt-0 mb-4">
                  <v-img
                    contain
                    width="200"
                    height="auto"
                    class="ma-auto"
                    alt="bidanvitacare"
                    :src="detail.store.logo">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <div>
                    <div
                      class="mb-7">
                      <div>
                        Mom, Baby & Kids Spa Sejak 2017
                      </div>

                      <div
                        class="mt-2">
                        Sahabat Ceria Bunda & Buah Hati
                      </div>
                    </div>

                    <div
                      class="mb-4">
                      <div
                        class="font-weight-bold black--text mb-2">
                        {{ detail.store.name }}
                      </div>

                      <div>
                        {{ detail.store.address }}
                      </div>
                    </div>

                    <div>
                      Buka Setiap Hari {{ detail.store.open_hours }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card v-if="Object.keys(detail).length > 0"
                flat
                color="transparent">
                <v-card-text
                  class="pt-0 mb-2">
                  <v-divider
                    class="mt-4 mb-8"
                    style="border-top: 2px dashed #e0e0e0;" />

                  <div
                    class="text-center">
                    <v-rating
                      v-model="form.rating"
                      dense
                      size="50"
                      color="yellow accent-4"
                      background-color="#E0E0E0"
                      empty-icon="$ratingFull">
                    </v-rating>

                    <div
                      class="body-2 line-text-second text-center my-8">
                      <div>
                        Silahkan masukkan rating Anda untuk pelayanan kami di Bidanvitacare.id, saran Anda sangat membantu untuk meningkatkan kualitas layanan kami.
                      </div>
                    </div>

                    <div
                      class="text-center my-8">
                      <v-btn
                        @click="save"
                        :loading="process.form"
                        :disabled="process.form"
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in d-flex align-center justify-center">
                          <v-icon
                            size="20">
                            mdi-check-bold
                          </v-icon>

                          <span
                            class="ml-1">
                            Kirim Feedback
                          </span>
                        </div>
                      </v-btn>
                    </div>

                    <div v-if="message.error.length > 0"
                      class="text-center my-2 d-flex align-center justify-center">
                      <div
                        class="error--text text-center">
                        <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                        <span
                          class="ml-1">
                          {{ message.error }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <v-divider
                    class="my-9"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="text-center">
                    <div
                      class="font-weight-bold black--text mt-6 mb-2">
                      Sosial Media Kami
                    </div>

                    <div>
                      Facebook: bidanvitacare.id
                    </div>

                    <div>
                      Instagram: bidanvitacare.id
                    </div>

                    <div>
                      Twitter: bidanvitacare.id
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  import moment from 'moment'
  moment.locale('id')

  export default {
    middleware: false,
    data () {
      return {
        dialog: {
          info_berhasil: false
        },
        detail: {},
        form: {
          uuid: '',
          rating: 0
        },
        process: {
          load: false,
          form: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/feedback/:slug',
          title: 'Feedback',
          description: 'Feedback Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          uuid: this.$route.params.slug
        }

        await this.$axios.$get(`${process.env.API}public/order/detail`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.detail = response.results.data

            this.reset()
          }
        })
      },

      reset () {
        this.form = {
          uuid: this.$route.params.slug,
          rating: 0
        }

        this.process.form = false

        this.message.error = ''
      },

      async save () {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}public/order/set-rating`, this.form)
        .then((response) => {

          this.process.form = false

          if (response.status === 200) {

            this.dialog.info_berhasil = true

            this.fetch()

            if (this.form.rating > 4) {
              setTimeout(() => {
                window.open(`${response.results.data.google_review_link}`, '_blank');
              });
            }
          } else {
            this.message.error = response.message
          }
        })
      }
    }
  }
</script>
