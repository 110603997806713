<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.go_back"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin akan keluar
                </div>

                <div>
                  dari halaman ini?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                to="/home"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Keluar
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.go_back = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.permission"
          persistent>
          <v-card
            class="border-radius">
            <v-card-title
              class="d-flex align-center pa-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="amanata"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Permission Telah di block
              </span>
            </v-card-title>

            <v-divider
              class="mb-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3">
              Silahkan aktifkan permission lokasi di perangkat Anda terlebih dahulu.
            </v-card-text>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text
              class="pt-16 pb-0">
              <v-row
                align="center"
                style="height: calc(100vh - 70px); overflow-y: auto;">
                <v-col
                  cols="12"
                  class="pa-0">
                  <ValidationObserver
                    ref="form">
                    <v-form>
                      <div
                        class="text-center">
                        <div>
                          <div
                            class="title font-weight-bold mb-6">
                            Hai, {{ user.name }}
                          </div>

                          <div
                            class="text-h4 font-weight-bold black--text">
                            {{ dateNow | time }}
                          </div>

                          <div
                            class="mb-8">
                            {{ dateNow | date }}
                          </div>

                          <div
                            class="font-weight-bold text-capitalize">
                            {{ user.email }}
                          </div>

                          <v-divider
                            class="my-2 mx-12"
                            style="border-top: 1px dashed #bbbbbb" />

                          <div
                            class="mb-16">
                            {{ user.mobilephone }}
                          </div>
                        </div>

                        <v-btn v-if="!process.absen && !absen_hadir"
                          @click="save"
                          :loading="process.form"
                          :disabled="process.form"
                          fab
                          width="175"
                          height="175"
                          elevation="0"
                          class="mt-2">
                          <v-avatar
                            size="150">
                            <v-img
                              contain
                              width="100%"
                              height="100%"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/fingerprint_attendance_start.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>
                        </v-btn>

                        <v-btn v-if="!process.absen && absen_hadir"
                          @click="save_stop"
                          :loading="process.form"
                          :disabled="process.form"
                          fab
                          width="175"
                          height="175"
                          elevation="0"
                          class="mt-2">
                          <v-avatar
                            size="150">
                            <v-img
                              contain
                              width="100%"
                              height="100%"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/fingerprint_attendance_stop.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>
                        </v-btn>

                        <div>
                          <a
                            :href="`http://maps.google.com/maps?q=${form.latitude},${form.longitude}`"
                            target="_blank"
                            class="d-flex align-center justify-center mt-4 mb-2">
                            <v-icon size="18" color="blue">mdi-map-marker-radius</v-icon>

                            <span
                              class="ml-1">
                              Lokasi Anda Terkini
                            </span>
                          </a>

                          <div
                            class="body-1 font-weight-bold mt-16 mb-4">
                            Absensi {{ !absen_hadir ? 'Kehadiran' : 'Pulang' }}
                          </div>

                          <div
                            class="body-2 line-text-second">
                            Silahkan melakukan absen untuk mencatat
                          </div>

                          <div
                            class="line-text-second">
                            data kehadiran Anda di Bidanvitacare.id
                          </div>
                        </div>
                      </div>
                    </v-form>
                  </ValidationObserver>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.success"
          inset>
          <v-sheet
            class="text-center"
            style="border-radius: 15px 15px 0px 0px; background: #f9dee2;">
            <div
              class="pa-4">
              <v-avatar
                size="50"
                style="border: 2px solid #ffffff;">
                <v-img
                  width="100%"
                  height="100%"
                  alt="bidanvitacare"
                  :src="user.image_url !== '' ? user.image_url : require('@/assets/image/member_default.jpg')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <div
                style="opacity: 70%;">
                <div
                  class="font-weight-bold mt-2 mb-3"
                  style="font-size: 18px;">
                  Selamat, {{ user.name }}
                </div>

                <div
                  class="body-2 line-text-second">
                  Absen {{ message_callback }} Anda
                </div>

                <div
                  class="body-2 line-text-second">
                  berhasil dicatat oleh sistem
                </div>
              </div>
            </div>
          </v-sheet>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.error"
          inset>
          <v-sheet
            class="text-center"
            style="border-radius: 15px 15px 0px 0px; background: #f9dee2;">
            <div
              class="pa-4">
              <v-avatar
                size="50"
                style="border: 2px solid #ffffff;">
                <v-img
                  width="100%"
                  height="100%"
                  alt="bidanvitacare"
                  :src="user.image_url !== '' ? user.image_url : require('@/assets/image/member_default.jpg')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <div
                style="opacity: 70%;">
                <div
                  class="font-weight-bold mt-2 mb-3"
                  style="font-size: 18px;">
                  Hai, {{ user.name }}
                </div>

                <div
                  class="body-2 line-text-second">
                  {{ message.error }}
                </div>
              </div>
            </div>
          </v-sheet>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dateNow: new Date(),
        dialog: {
          go_back: false,

          permission: false
        },

        sheet: {
          success: false,
          error: false
        },

        message_callback: '',

        absen_hadir: false,

        form: {
          latitude: '',
          longitude: ''
        },

        process: {
          form: false,

          absen: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/absen',
          title: 'Absensi',
          description: 'Absensi Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.reset()

      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)

          t.dialog.go_back = true
        })
      },

      reset () {
        if (this.$refs.form) this.$refs.form.reset()

        let self = this

        navigator.permissions.query({ name: 'geolocation' })
        .then(function(result) {
          if (result.state !== 'denied') {
            navigator.geolocation.getCurrentPosition(position => {
              self.form = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              }
            })
          } else {
            self.dialog.permission = true
          }
        })

        this.process.form = false

        this.message.error = ''

        this.fetchAbsen()
      },

      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          let self = this

          navigator.permissions.query({ name: 'geolocation' })
          .then(function(result) {
            if (result.state !== 'denied') {
              self.process.form = true

              let url = self.user.role === 'therapist' ? 'therapist/absen/add' : 'admin/absen/add'

              // SAVE TO REST API
              self.$axios.$post(`${process.env.API}${url}`, self.form)
              .then((response) => {

                if (response.status === 200) {
                  self.process.form = false

                  self.sheet.success = true
                  self.message_callback = 'kehadiran'

                  self.reset()
                } else {
                  self.process.form = false
                  self.message.error = response.message

                  self.sheet.error = true
                }
              })

            } else {
              self.dialog.permission = true
            }
          })
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      async fetchAbsen () {
        this.process.absen = true

        let url = this.user.role === 'therapist' ? 'therapist/absen/detail' : 'admin/absen/detail'

        await this.$axios.$get(`${process.env.API}${url}`)
        .then((response) => {

          this.process.absen = false

          if (response.status === 200) {
            this.absen_hadir = Object.keys(response.results.data).length > 0 ? true : false
          }
        })
      },

      async save_stop () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          let self = this

          navigator.permissions.query({ name: 'geolocation' })
          .then(function(result) {
            if (result.state !== 'denied') {

              self.process.form = true

              let url = self.user.role === 'therapist' ? 'therapist/absen/update_pulang' : 'admin/absen/update_pulang'

              // SAVE TO REST API
              self.$axios.$post(`${process.env.API}${url}`, self.form)
              .then((response) => {

                if (response.status === 200) {
                  self.process.form = false

                  self.sheet.success = true
                  self.message_callback = 'pulang'

                  self.reset()
                } else {
                  self.process.form = false
                  self.message.error = response.message

                  self.sheet.error = true
                }
              })

            } else {
              self.dialog.permission = true
            }
          })
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>
