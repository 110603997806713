<template>
  <div>
    <v-dialog v-model="dialog.filter"
      persistent>
      <v-card 
        class="border-radius pa-2">
        <v-card-title 
          class="d-flex align-center py-0 px-3 text-right popup-header">
          <v-avatar 
            size="30"
            tile>
            <v-img
              alt="bidanvitacare"
              :src="require('@/assets/logo/favicon.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    size="20"
                    indeterminate 
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <span 
            class="body-1 font-weight-bold text-capitalize ml-2">
            Filter
          </span>
        </v-card-title>
        
        <v-divider 
          class="mx-4 mt-3 mb-2"/>

        <v-card-text 
          class="body-2 text-first line-text-second mt-3">
          <div>
            <div class="body-2 mb-2">
              Pilih Cabang
            </div>

            <v-select
              filled
              rounded
              dense
              :color="set_color"
              placeholder="Pilih Cabang"
              
              :items="store"
              item-value="id"
              item-text="name"
              :item-color="set_color"

              clearable
              @click:clear="filter.store_id = ''"

              :no-filter="false"

              v-model="filter.store_id">
            </v-select>
          </div>

          <div>
            <div class="body-2 mb-2">
              Tanggal Pinjam
            </div>

            <v-menu
              ref="start"
              min-width="290px"
              max-width="290px"
              transition="scale-transition"
              offset-y
              :nudge-right="40"
              :close-on-content-click="false"
              v-model="picker.start">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  readonly
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 2023-01-01"

                  clearable
                  @click:clear="filter.date = ''; filter.due_date = '';"
                  v-on="on"
                  v-bind="attrs"

                  v-model="filter.date">
                </v-text-field>
              </template>

              <v-date-picker 
                :color="set_color"
                v-model="filter.date"
                @input="picker.start = false; filter.due_date = '';">
              </v-date-picker>
            </v-menu>
          </div>

          <div>
            <div class="body-2 mb-2">
              Tanggal Kembali
            </div>

            <v-menu
              ref="end"
              min-width="290px"
              max-width="290px"
              transition="scale-transition"
              offset-y
              :nudge-right="40"
              :close-on-content-click="false"
              v-model="picker.end">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  readonly
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 2023-01-10"

                  clearable
                  @click:clear="filter.due_date = ''"
                  v-on="on"
                  v-bind="attrs"

                  hide-details

                  v-model="filter.due_date">
                </v-text-field>
              </template>
              
              <v-date-picker 
                :min="filter.date"
                :color="set_color"
                v-model="filter.due_date"
                @input="picker.end = false">
              </v-date-picker>
            </v-menu>
          </div>
        </v-card-text>

        <v-divider 
          class="mx-4 mb-3 mt-0"/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            @click="dialog.filter = false; fetch();"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_save">
            <div
              class="btn_save_in d-flex align-center justify-center">
              <v-icon 
                size="20">
                mdi-check-bold
              </v-icon>

              <span
                class="ml-1">
                Simpan
              </span>
            </div>
          </v-btn>
          
          <v-btn
            @click="dialog.filter = false"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
            <div
              class="btn_cancel_in">
              <v-icon 
                size="20">
                mdi-close-thick
              </v-icon>

              <span
                class="ml-1">
                Batal
              </span>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text 
          class="pb-0 mt-1">
          <v-text-field
            v-model="search"
            @click:clear="search = ''; fetch();"
            v-on:keyup.enter="fetch()"
            filled
            rounded
            dense 
            single-line
            hide-details
            :color="set_color"
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari data yang Anda butuhkan ?"
            clearable>
          </v-text-field>

          <v-btn
            @click="sheet.form = true; reset();"
            block
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
            :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
            <div
              class="btn_auth_in d-flex align-center justify-center"
              :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
              <div>
                <v-img 
                  contain
                  width="20"
                  height="20"
                  alt="bidanvitacare" 
                  :src="require('@/assets/icon/add_plus.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>

              <div
                class="ml-2">
                Tambah Pinjam
              </div>
            </div>
          </v-btn>

          <v-row
            class="mt-2">
            <v-col
              v-for="(item, index) in menu" 
              :key="index" 
              cols="4">
              <v-btn
                @click="selected.menu = item; fetch();"
                block
                rounded
                elevation="0"
                color="#ececec"
                class="body-2 font-weight-bold text-capitalize"
                :class="selected.menu === item ? `color: ${set_color};` : ''"
                :style="selected.menu === item ? `border: 2px dashed ${set_color}; color: ${set_color};` : ''">
                {{ item }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text
          class="pb-0">
          <div
            class="body-2 font-weight-bold black--text d-flex align-center mb-2">
            <div>
              Pinjam Barang
            </div>

            <v-spacer />

            <div>
              <v-icon 
                @click="dialog.filter = true; fetchStore();"
                class="cursor-pointer">
                mdi-filter-variant
              </v-icon>
            </div>
          </div>

          <v-row>
            <v-col
              cols="12">
              <v-skeleton-loader
                v-for="(item, index) in process.load ? 4 : list"
                :key="index"
                :loading="process.load"
                :class="index < list.length - 1 ? 'mb-4' : ''"
                type="list-item-avatar-three-line">
                <template>
                  <div>
                    <v-list
                      v-if="!process.load && list.length > 0"
                      flat
                      dense
                      three-line
                      color="#FFFFFF40"
                      class="border-radius">
                      <v-list-item
                        @click="sheet.detail = true; fetchDetail(item.id);">
                        <v-list-item-avatar
                          size="40"
                          class="mt-2"
                          style="border: 2px solid #ffffff;">
                          <v-img 
                            width="100%"
                            height="100%"
                            alt="bidanvitacare" 
                            :src="item.user_image_url !== '' ? item.user_image_url : require('@/assets/image/member_default.jpg')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  size="20"
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="body-2 font-weight-bold">
                            {{ item.user_name }}
                          </v-list-item-title>

                          <v-list-item-subtitle
                            class="body-2 font-weight-light line-text-second black--text my-1">
                            {{ item.item_name }}

                            <v-divider 
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />
                          </v-list-item-subtitle>

                          <v-list-item-subtitle
                            class="d-flex align-center">
                            <div>
                              <div
                                class="caption mb-1">
                                Jumlah
                              </div>

                              <div
                                class="black--text">
                                {{ item.qty }} Pcs
                              </div>
                            </div>

                            <v-spacer />

                            <div>
                              <div
                                class="caption mb-1">
                                Stok
                              </div>

                              <div
                                class="black--text">
                                {{ item.sum_stock }} Pcs
                              </div>
                            </div>

                            <v-spacer />

                            <div>
                              <div
                                class="caption mb-1">
                                Tanggal Kembali
                              </div>

                              <div
                                class="black--text">
                                <span v-if="item.type === 'tempo'">
                                  <span v-if="item.return_datetime !== ''">
                                    <span>
                                      {{ item.return_datetime | date }}
                                    </span>
                                    
                                    <span>
                                      {{ item.return_datetime | time }}
                                    </span>
                                  </span>

                                  <span v-else>
                                    -
                                  </span>
                                </span>

                                <span v-else>
                                  -
                                </span>
                              </div>
                            </div>
                          </v-list-item-subtitle>

                          <v-list-item-subtitle
                            class="body-2 font-weight-light line-text-second black--text my-1">
                            <div
                              v-if="selected.menu !== 'Pengajuan'">
                              <v-divider 
                                class="my-2"
                                style="border-top: 1px dashed #bbb;" />

                              <div
                                class="d-flex align-center">
                                <div>
                                  {{ selected.menu }} Oleh
                                </div>

                                <v-spacer />

                                <div
                                  class="d-flex align-center">
                                  <v-avatar
                                    size="24"
                                    style="border: 2px solid #ffffff;">
                                    <v-img 
                                      width="100%"
                                      height="100%"
                                      alt="bidanvitacare" 
                                      :src="item.status_user_image_url !== '' ? item.status_user_image_url : require('@/assets/image/member_default.jpg')"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular 
                                            size="20"
                                            indeterminate 
                                            :color="set_color">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>

                                  <span
                                    class="caption font-weight-bold one-line ml-1">
                                    {{ item.status_user_name }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            
                            <v-divider 
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />

                            {{ item.note !== '' ? item.note : '-' }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </template>
              </v-skeleton-loader>
            </v-col>
          </v-row>

          <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
            class="text-center my-4">
            <v-btn
              @click="limit += 10"
              :loading="process.limit"
              small
              rounded
              outlined
              elevation="0"
              :color="set_color"
              class="text-capitalize"
              :style="`color: ${set_color};`">
              Lebih banyak
            </v-btn>
          </div>

          <Empty v-if="!process.load && pagination.total_page < 1"
            class="py-6"
            margin="my-16"
            size="125"
            message="Pinjam" />
        </v-card-text>
      </v-card>
    </section>

    <!-- <v-bottom-sheet
      v-model="sheet.detail"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card v-if="Object.keys(detail).length > 0"
        flat
        class="background">
        <div
          class="blur">
          
        </div>
      </v-card>
    </v-bottom-sheet> -->

    <v-bottom-sheet
      v-model="sheet.form"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title 
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon 
              @click="sheet.form = false; reset();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span 
              @click="sheet.form = false; reset();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              {{ form.id === '' ? 'Tambah' : 'Ubah' }}
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 205px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="pt-16 mt-4">
              <ValidationObserver 
                ref="form">
                <v-form>
                  <div v-if="form.item_id !== ''"
                    class="mb-6">
                    <v-img 
                      width="200"
                      height="200"
                      alt="bidanvitacare" 
                      :src="item_image !== '' ? item_image : require('@/assets/image/image_default.png')"
                      style="border: 5px solid #0000000d;
                            border-radius: 10px;">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            size="20"
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <ValidationProvider 
                    name="Item" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Item
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-autocomplete
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. Pijat Badan Keseluruhan"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      :search-input.sync="autocomplete.items"
                      :loading="process.items"
                      
                      :items="items"
                      item-value="id"
                      item-text="name"
                      :item-color="set_color"

                      :no-filter="false"

                      v-model="form.item_id"
                      :success="valid"
                      :error-messages="errors"
                      required>
                      <template v-slot:item="data">
                        <span
                          class="body-2 text-capitalize">
                          {{ data.item.name }}
                        </span>
                      </template>

                      <template v-slot:selection="data">
                        <span
                          class="body-2 text-capitalize">
                          {{ data.item.name }}
                        </span>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="Jumlah" 
                    rules="required|min:0"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Jumlah
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                      min="0"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 5"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      @focus="$event.target.select()"

                      v-model="form.qty"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <v-radio-group
                    v-model="form.type"
                    row
                    class="mt-0">
                    <v-radio
                      label="Tempo"
                      value="tempo"
                      :color="set_color">
                    </v-radio>

                    <v-radio
                      label="Habis"
                      value="habis"
                      :color="set_color">
                    </v-radio>
                  </v-radio-group>

                  <div v-if="form.type === 'tempo'">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Tanggal Pinjam
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-menu
                      ref="start"
                      min-width="290px"
                      max-width="290px"
                      transition="scale-transition"
                      offset-y
                      :nudge-right="40"
                      :close-on-content-click="false"
                      v-model="picker.start">
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider 
                          name="Tanggal Pinjam" 
                          rules="required"
                          v-slot="{ errors, valid }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            readonly
                            filled
                            rounded
                            dense
                            :color="set_color"
                            placeholder="Contoh. 2023-01-01"
                            
                            :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                            clearable
                            @click:clear="form.date = ''; form.due_date = '';"
                            v-on="on"
                            v-bind="attrs"

                            v-model="form.date"
                            :success="valid"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </template>

                      <v-date-picker 
                        :color="set_color"
                        v-model="form.date"
                        @input="picker.start = false; form.due_date = '';">
                      </v-date-picker>
                    </v-menu>
                  </div>

                  <!-- <div v-if="form.type === 'tempo'">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Tanggal Kembali
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-menu
                      ref="end"
                      min-width="290px"
                      max-width="290px"
                      transition="scale-transition"
                      offset-y
                      :nudge-right="40"
                      :close-on-content-click="false"
                      v-model="picker.end">
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider 
                          name="Tanggal Kembali" 
                          rules="required"
                          v-slot="{ errors, valid }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            readonly
                            filled
                            rounded
                            dense
                            :color="set_color"
                            placeholder="Contoh. 2023-01-10"
                            
                            :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                            :disabled="form.date === '' || form.date === null"

                            clearable
                            @click:clear="form.due_date = ''"
                            v-on="on"
                            v-bind="attrs"

                            v-model="form.due_date"
                            :success="valid"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </template>
                      
                      <v-date-picker 
                        :min="form.date"
                        :color="set_color"
                        v-model="form.due_date"
                        @input="picker.end = false">
                      </v-date-picker>
                    </v-menu>
                  </div> -->

                  <ValidationProvider 
                    name="Keterangan" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      Keterangan
                      <!-- <span>
                        (boleh dikosongi)
                      </span> -->
                    </div>

                    <v-textarea
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      rows="5"
                      placeholder="Contoh. Tuliskan keterangan disini . . ."
                      :append-icon="form.note !== '' && valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.note"
                      :success="form.note !== '' && valid"
                      :error-messages="errors"
                      required>
                    </v-textarea>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="text-center mt-3 mb-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
                  
                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="150"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row 
                align="center">
                <v-col 
                  cols="3" 
                  class="pb-0">
                  <v-img 
                    contain
                    alt="bidanvitacare" 
                    :src="require('@/assets/image/footer/loan.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col 
                  cols="9" 
                  class="black--text pb-0">
                  <div>
                    <div 
                      class="body-2 line-text-second black--text" 
                      style="opacity: 60%;">
                      Masukkan data Pinjam untuk peminjaman di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                    </div>
                  </div>
                </v-col>
              </v-row>
              
              <v-row 
                class="mt-4">
                <v-col 
                  cols="6" 
                  class="pb-0">
                  <v-btn
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save"
                    @click="save"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in">
                      <v-icon 
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-check-bold
                      </v-icon>

                      Simpan
                    </div>
                  </v-btn>
                </v-col>

                <v-col 
                  cols="6" 
                  class="pb-0">
                  <v-btn
                    @click="sheet.form = false; reset(); fetch();"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon 
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-close-thick
                      </v-icon>

                      Batal
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  import moment from "moment"
  import "moment/locale/id"
  export default {
    data () {
      return {
        dialog: {
          filter: false
        },
        
        sheet: {
          form: false,
          detail: false
        },
        search: '',

        filter: {
          store_id: '',
          date: '',
          due_date: ''
        },

        selected: {
          menu: 'Pengajuan'
        },
        menu: ['Pengajuan', 'Disetujui', 'Ditolak'],

        store: [],

        detail: {},
        list: [],
        items: [],
        pagination: {},

        limit: 10,

        picker: {
          start: false,
          end: false
        },

        autocomplete: {
          items: ''
        },
        form: {
          item_id: '',
          qty: 0,
          date: '',
          due_date: '',
          note: '',
          type: 'tempo'
        },

        item_image: '',

        process: {
          load: false,
          terapis: false,
          items: false,
          form: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'autocomplete.items': function() {
        this.fetchItem()
      },
      'form.type': function() {
        this.form.date = ''
        // this.form.due_date = ''
      },

      'limit': function() {
        this.process.limit = true
        
        this.fetch('limit')
      },

      'form.item_id': function() {
        let self = this
        let data = this.items.filter(function(v) {
          return self.form.item_id == v.id
        })

        if (data.length > 0) {
          this.item_image = data[0].image_url
        }
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit,

          status_loan: this.selected.menu,
          date: this.filter.date,
          // due_date: this.filter.due_date
          return_datetime: this.filter.due_date,

          store_id: this.filter.store_id
        }

        await this.$axios.$get(`${process.env.API}therapist/loan-item/data`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchDetail (id) {
        // let params = {
        //   id: id
        // }

        // await this.$axios.$get(`${process.env.API}admin/loan-item/detail`, { params })
        // .then((response) => {

        //   if (response.status === 200) {
        //     this.detail = response.results.data

        //     this.reset(this.detail)
        //   }
        // })
      },
      async fetchItem () {
        this.process.items = true

        let params = {
          search: this.autocomplete.items,
          'type[eq]': 'material',
          // 'type[neq]': 'transport',
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}therapist/item`, { params })
        .then((response) => {
          this.process.items = false

          if (response.status === 200) {
            this.items = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },

      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          item_id: '',
          qty: 0,
          date: moment().format('YYYY-MM-DD'),
          due_date: '',
          note: item !== undefined ? item.note : '',
          type: 'tempo'
        }

        this.picker = {
          start: false,
          end: false
        }
        
        this.process.form = false

        this.message.error = ''
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}therapist/loan-item/add`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.detail = false

              this.sheet.form = false

              this.fetch()
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      async fetchStore () {
        let params = {
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/store`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.store = response.results.data
          }
        })
      }
    }
  }
</script>