<template>
  <div>
    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text
          class="py-0">
          <div
            class="body-2 black--text font-weight-bold mb-6">
            Antropometri
          </div>

          <ValidationObserver 
            ref="form">
            <v-form>
              <ValidationProvider 
                name="Berat Badan" 
                rules="required|min:0"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  <span class="red--text">*</span>
                  Berat Badan
                  <!-- <span class="error--text">
                    (wajib diisi)
                  </span> -->
                </div>
                
                <v-text-field
                  v-on:keyup.enter="save"
                  type="number"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                  min="0"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 0.25"
                  :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                  @focus="$event.target.select()"
                  suffix="Kg"

                  @blur="save"

                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran || status_required.is_done_bool"

                  v-model="form.bb"
                  :success="valid"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'"
                name="Tinggi Badan" 
                rules="required|min:0"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  <span class="red--text">*</span>
                  Tinggi Badan
                  <!-- <span class="error--text">
                    (wajib diisi)
                  </span> -->
                </div>
                
                <v-text-field
                  v-on:keyup.enter="save"
                  type="number"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                  min="0"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 100"
                  :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                  @focus="$event.target.select()"
                  suffix="Cm"

                  @blur="save"

                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran || status_required.is_done_bool"

                  v-model="form.tb"
                  :success="valid"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'"
                name="Lingkar Lengan Atas" 
                rules="required|min:0"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  <span class="red--text">*</span>
                  Lingkar Lengan Atas
                  <!-- <span class="error--text">
                    (wajib diisi)
                  </span> -->
                </div>
                
                <v-text-field
                  v-on:keyup.enter="save"
                  type="number"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                  min="0"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 20"
                  :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                  @focus="$event.target.select()"
                  suffix="Cm"

                  @blur="save"

                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran || status_required.is_done_bool"

                  v-model="form.lila"
                  :success="valid"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'"
                name="Lingkar Kepala" 
                rules="required|min:0"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  <span class="red--text">*</span>
                  Lingkar Kepala
                  <!-- <span class="error--text">
                    (wajib diisi)
                  </span> -->
                </div>
                
                <v-text-field
                  v-on:keyup.enter="save"
                  type="number"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                  min="0"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 35"
                  :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                  @focus="$event.target.select()"
                  suffix="Cm"

                  @blur="save"

                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran || status_required.is_done_bool"

                  v-model="form.lk"
                  :success="valid"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider v-if="detail.customer_type !== 'bayi' && detail.customer_type !== 'anak'"
                name="Tensi" 
                rules="required|min:0"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  <span class="red--text">*</span>
                  Tensi
                  <!-- <span class="error--text">
                    (wajib diisi)
                  </span> -->
                </div>
                
                <v-text-field
                  v-on:keyup.enter="save"
                  
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 120/70"
                  :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                  @focus="$event.target.select()"
                  suffix="mmHg"

                  @blur="save"

                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran || status_required.is_done_bool"

                  v-model="form.tensi"
                  :success="valid"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <!-- <ValidationProvider 
                name="Keluhan" 
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  Keluhan
                  <span>
                    (boleh dikosongi)
                  </span>
                </div>
                
                <v-text-field
                  v-on:keyup.enter="save"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. Kaki kesemutan"
                  :append-icon="form.complaint !== '' && valid ? 'mdi-check-circle-outline' : ''"

                  v-model="form.complaint"
                  :success="form.complaint !== '' && valid"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider> -->

              <!-- <ValidationProvider 
                name="Keterangan" 
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  Keterangan
                  <span>
                    (boleh dikosongi)
                  </span>
                </div>

                <v-textarea
                  v-on:keyup.enter="save"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  rows="5"
                  placeholder="Contoh. Tuliskan keterangan disini . . ."
                  :append-icon="form.note !== '' && valid ? 'mdi-check-circle-outline' : ''"

                  v-model="form.note"
                  :success="form.note !== '' && valid"
                  :error-messages="errors"
                  required>
                </v-textarea>
              </ValidationProvider> -->

              <!-- <ValidationProvider 
                name="Komplain untuk Terapis" 
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  Komplain untuk Terapis
                  <span>
                    (boleh dikosongi)
                  </span>
                </div>

                <v-textarea
                  v-on:keyup.enter="save"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  rows="5"
                  placeholder="Contoh. Tuliskan komplain disini . . ."
                  :append-icon="form.therapist_complaint !== '' && valid ? 'mdi-check-circle-outline' : ''"

                  v-model="form.therapist_complaint"
                  :success="form.therapist_complaint !== '' && valid"
                  :error-messages="errors"
                  required>
                </v-textarea>
              </ValidationProvider> -->

              <!-- <ValidationProvider 
                name="Saran / Masukkan" 
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  Saran / Masukkan
                  <span>
                    (boleh dikosongi)
                  </span>
                </div>

                <v-textarea
                  v-on:keyup.enter="save"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  rows="5"
                  placeholder="Contoh. Tuliskan saran / masukkan disini . . ."
                  :append-icon="form.therapist_suggest !== '' && valid ? 'mdi-check-circle-outline' : ''"

                  v-model="form.therapist_suggest"
                  :success="form.therapist_suggest !== '' && valid"
                  :error-messages="errors"
                  required>
                </v-textarea>
              </ValidationProvider> -->
            </v-form>
          </ValidationObserver>

          <div v-if="message.error.length > 0"
            class="text-center my-2 d-flex align-center">
            <div
              class="error--text">
              <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
              
              <span
                class="ml-1">
                {{ message.error }}
              </span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </section>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      },
      status_required: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        form: {
          id: '',
          age: '',
          bb: '',
          tb: '',
          lila: '',
          lk: '',
          complaint: '',
          note: '',

          therapist_complaint: '',
          therapist_suggest: '',

          tensi: ''
        },
        process: {
          form: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      
    },
    watch: {
      
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.reset(this.detail)
    },
    methods: {
      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          id: item !== undefined ? item.visit_id : '',
          age: item !== undefined ? item.age : '',
          bb: item !== undefined ? item.bb : '',
          tb: item !== undefined ? item.tb : '',
          lila: item !== undefined ? item.lila : '',
          lk: item !== undefined ? item.lk : '',
          complaint: item !== undefined ? item.complaint : '',
          note: item !== undefined ? item.note : '',

          therapist_complaint: item !== undefined ? item.therapist_complaint : '',
          therapist_suggest: item !== undefined ? item.therapist_suggest : '',

          tensi: item !== undefined ? item.tensi : ''
        }
        
        this.process.form = false

        this.message.error = ''
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}therapist/customer-visit-today/update`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.$emit('load')
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>