<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.go_back"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-text>
              <v-img 
                width="150"
                height="150"
                alt="bidanvitacare" 
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin akan keluar
                </div>

                <div>
                  dari halaman ini?
                </div>
              </div>
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                to="/home"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon 
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Keluar
                  </span>
                </div>
              </v-btn>
              
              <v-btn
                @click="dialog.go_back = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon 
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <!-- <no-ssr>
              <swiper 
                class="swiper"
                ref="swiper"
                :options="{ slidesPerView: 'auto' }">
                <swiper-slide 
                  v-for="(item, index) in menu" 
                  :key="index" 
                  class="py-4" 
                  :class="index === 0 ? 'pl-4 pr-4' : index < menu.length - 1 ? 'pr-4' : 'pr-4'"
                  style="max-width: 130px;">
                  <div>
                    <v-btn
                      @click="selected.menu = item.value"
                      width="100"
                      rounded
                      elevation="0"
                      :class="selected.menu !== item.value ? '' : 'font-weight-bold'"
                      :color="selected.menu !== item.value ? '#e2e2e2' : set_color"
                      :style="selected.menu !== item.value ? 'color: #8c8c8c;' : 'color: #FFFFFF;'"
                      class="body-2 text-capitalize">
                      {{ item.text }}
                    </v-btn>
                  </div>
                </swiper-slide>
              </swiper>
            </no-ssr> -->

            <v-card-text>
              <v-select
                filled
                rounded
                dense
                :color="set_color"
                placeholder="Pilih Laporan"
                
                :items="menu"
                item-text="text"
                item-value="value"
                :item-color="set_color"

                hide-details

                v-model="selected.menu">
              </v-select>
            </v-card-text>

            <v-card-text
              class="pa-0">
              <Fee v-if="selected.menu === 1" />

              <Item v-if="selected.menu === 2" />

              <Customer v-if="selected.menu === 3" />

              <Coupon v-if="selected.menu === 4" />

              <Month v-if="selected.menu === 5" />

              <Payment v-if="selected.menu === 6" />

              <Dp v-if="selected.menu === 7" />

              <Terapis v-if="selected.menu === 8" />

              <Area v-if="selected.menu === 9" />

              <Transaksi v-if="selected.menu === 10" />

              <Overtime v-if="selected.menu === 11" />

              <Cabang v-if="selected.menu === 12" />
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          go_back: false
        },

        selected: {
          menu: 1
        },
        menu: [
          {
            text: 'Fee',
            value: 1
          },
          {
            text: 'Item',
            value: 2
          },
          {
            text: 'Pelanggan',
            value: 3
          },
          {
            text: 'Kupon',
            value: 4
          },
          {
            text: 'Order',
            value: 5
          },
          {
            text: 'Metode',
            value: 6
          },
          {
            text: 'DP',
            value: 7
          },
          {
            text: 'Terapis',
            value: 8
          },
          {
            text: 'Area',
            value: 9
          },
          {
            text: 'Transaksi',
            value: 10
          },
          {
            text: 'Overtime',
            value: 11
          },
          {
            text: 'Cabang',
            value: 12
          }
        ],
        // SEO
        content: {
          url: '/laporan',
          title: 'Laporan',
          description: 'Laporan Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Fee: () => import(/* webpackPrefetch: true */ '@/components/report/fee_therapist.vue'),
      Item: () => import(/* webpackPrefetch: true */ '@/components/report/top_item.vue'),
      Customer: () => import(/* webpackPrefetch: true */ '@/components/report/top_customer.vue'),
      Coupon: () => import(/* webpackPrefetch: true */ '@/components/report/customer_coupon.vue'),
      Month: () => import(/* webpackPrefetch: true */ '@/components/report/customer_so_month.vue'),
      Payment: () => import(/* webpackPrefetch: true */ '@/components/report/so_payment_method.vue'),
      Dp: () => import(/* webpackPrefetch: true */ '@/components/report/dp_payment_method.vue'),

      Terapis: () => import(/* webpackPrefetch: true */ '@/components/report/service_therapist.vue'),
      Area: () => import(/* webpackPrefetch: true */ '@/components/report/top_area.vue'),
      Transaksi: () => import(/* webpackPrefetch: true */ '@/components/report/transaction.vue'),

      Overtime: () => import(/* webpackPrefetch: true */ '@/components/report/over_time.vue'),

      Cabang: () => import(/* webpackPrefetch: true */ '@/components/report/transaction_store.vue')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)
          
          t.dialog.go_back = true
        })
      }
    }
  }
</script>