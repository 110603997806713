<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text
              class="pb-0">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="pt-1 pb-0">
                  <v-menu
                    ref="date"
                    min-width="290px"
                    max-width="290px"
                    transition="scale-transition"
                    offset-y
                    :nudge-right="40"
                    :close-on-content-click="false"
                    v-model="picker.date">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        readonly
                        filled
                        rounded
                        dense
                        :color="set_color"
                        placeholder="Pilih Tanggal"

                        clearable
                        @click:clear="filter.date = ''; fetch();"

                        v-on="on"
                        v-bind="attrs"

                        hide-details

                        class="mb-4"

                        v-model="filter.date">
                      </v-text-field>
                    </template>

                    <v-date-picker
                      :color="set_color"
                      v-model="filter.date"
                      @input="picker.date = false; fetch();">
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  class="pt-1 pb-0">
                  <v-select
                    filled
                    rounded
                    dense
                    :color="set_color"
                    placeholder="Pilih Cabang"

                    :items="store"
                    item-value="id"
                    item-text="name"
                    :item-color="set_color"

                    clearable
                    @click:clear="filter.store_id = ''; fetch();"

                    @change="fetch();"

                    :no-filter="false"

                    v-model="filter.store_id">
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text
              class="pt-0">
              <v-row v-if="process.load"
                v-for="index in 3"
                :key="index">
                <v-col
                  cols="12"
                  md="2">
                  <v-skeleton-loader
                    loading
                    type="list-item-avatar-three-line">
                  </v-skeleton-loader>
                </v-col>

                <v-col
                  cols="12"
                  md="10">
                  <v-row>
                    <v-col
                      v-for="index in 6"
                      :key="index"
                      cols="12"
                      md="4"
                      class="py-0">
                      <v-skeleton-loader
                        loading
                        type="list-item-avatar-three-line">
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <div v-if="!process.load && list.length > 0"
                v-for="(item, index) in list"
                :key="index">
                <v-row>
                  <v-col
                    cols="12"
                    md="2">
                    <v-list
                      flat
                      dense
                      color="#FFFFFF40"
                      style="border: 2px dashed #bbb; border-radius: 10px;">
                      <v-list-item>
                        <v-list-item-avatar
                          size="40"
                          style="border: 2px solid #ffffff;">
                          <v-img
                            width="100%"
                            height="100%"
                            alt="bidanvitacare"
                            :src="item.terapis.image_url !== '' ? item.terapis.image_url : require('@/assets/image/member_default.jpg')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="body-2 font-weight-bold text-capitalize">
                            {{ item.terapis.name }}
                          </v-list-item-title>

                          <v-list-item-subtitle
                            class="body-2 font-weight-light line-text-second black--text mt-1">
                            {{ item.terapis.store_name }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>

                  <v-col
                    cols="12"
                    md="10">
                    <v-row>
                      <v-col
                        v-for="(sub_item, sub_index) in item.data"
                        :key="sub_index"
                        cols="12"
                        md="4"
                        class="py-0"
                        :class="sub_index < item.data.length - 1 ? 'mb-6' : ''">
                        <div
                          class="font-weight-bold black--text mb-1">
                          {{ sub_item.datetime | time }}
                        </div>

                        <v-list
                          flat
                          dense
                          three-line
                          color="#FFFFFF40"
                          style="border: 2px dashed #bbb; border-radius: 10px;">
                          <v-list-item>
                            <v-list-item-avatar
                              size="40"
                              class="mt-2"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="sub_item.customer_image_url !== '' ? sub_item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                {{ sub_item.customer_name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light line-text-second black--text my-1">
                                {{ sub_item.type === 'on_site' ? 'On Site' : 'Home Care' }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="d-flex align-center">
                                <div>
                                  <div
                                    class="caption mb-1">
                                    Tanggal
                                  </div>

                                  <div
                                    class="black--text">
                                    {{ sub_item.datetime | datetime }}
                                  </div>
                                </div>

                                <v-spacer />

                                <div>
                                  <div
                                    class="caption mb-1">
                                    Status
                                  </div>

                                  <div
                                    :class="sub_item.status === 'waiting' ? 'red--text' : sub_item.status === 'process' ? 'orange--text' : 'green--text'">
                                    {{
                                      sub_item.status === 'waiting' ? 'Menunggu' :
                                      sub_item.status === 'process' ? 'Proses' : 'Terkonfirmasi'
                                    }}
                                  </div>
                                </div>
                              </v-list-item-subtitle>

                              <div
                                class="pa-3 mt-4"
                                style="border: 2px dashed #bbb; border-radius: 10px; height: 150px; overflow-y: auto;">
                                <div
                                  class="body-2 font-weight-light line-text-second black--text"
                                  v-html="sub_item.trello_format">
                                </div>
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-divider
                  v-if="index < list.length - 1"
                  class="my-4"
                  style="border-top: 2px dashed #bbb;" />
              </div>

              <Empty v-if="!process.load && list.length < 1"
                class="py-16"
                margin="my-16"
                size="125"
                message="Jadwal" />
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import moment from "moment"
  import "moment/locale/id"
  export default {
    middleware: 'authenticated',
    data () {
      return {
        search: '',

        filter: {
          date: moment().format('YYYY-MM-DD'),
          store_id: '',

          therapist_id: ''
        },

        store: [],

        terapis: [],

        list: [],
        pagination: {},

        limit: 10,

        picker: {
          date: false
        },

        process: {
          load: false,
          terapis: false
        },

        message: {
          success: '',
          error: '',
          errors: []
        },
        // SEO
        content: {
          url: '/jadwal/desktop',
          title: 'Jadwal Desktop',
          description: 'Jadwal Desktop Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetchStore()
      this.fetch()
    },
    methods: {
      async fetch () {
        let params = {
          date: this.filter.date,

          store_id: this.filter.store_id
        }

        await this.$axios.$get(`${process.env.API}admin/schedule/data-therapist-by-date`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.list = []

            if (response.results.data.length > 0) {
              response.results.data.forEach(obj => {
                this.fetchSchedule(obj)
              });
            }
          }
        })
      },

      async fetchStore () {
        let params = {
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/store`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.store = response.results.data
          }
        })
      },

      async fetchSchedule (item) {
        this.process.load = true

        let params = {
          limit: 10000,

          datetime: this.filter.date,
          therapist_id: item.id,

          store_id: this.filter.store_id
        }

        await this.$axios.$get(`${process.env.API}admin/schedule`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list.push({
              terapis: item,
              data: response.results.data
            })
          }
        })
      }
    }
  }
</script>
