<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.go_back"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin akan keluar
                </div>

                <div>
                  dari halaman ini?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                to="/home"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Keluar
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.go_back = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- filter -->
        <v-dialog v-model="dialog.filter"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Filter
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-6 pb-0">
              <div>
                <div class="body-2 mb-2">
                  Klien
                </div>

                <v-autocomplete
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. Cintya Zenit"

                  :search-input.sync="search_klien"
                  :loading="process.klien"

                  :items="list_klien"
                  item-value="id"
                  item-text="name"
                  :item-color="set_color"

                  :no-filter="false"

                  clearable
                  @click:clear="filter.client_id = ''"

                  v-model="filter.client_id">
                  <template v-slot:item="data">
                    <v-avatar
                      size="24">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="data.item.image_url !== '' ? data.item.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 text-capitalize ml-2">
                      {{ data.item.name }}
                    </span>
                  </template>

                  <template v-slot:selection="data">
                    <v-avatar
                      size="24">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="data.item.image_url !== '' ? data.item.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 text-capitalize ml-2">
                      {{ data.item.name }}
                    </span>
                  </template>
                </v-autocomplete>
              </div>

              <div>
                <div class="body-2 mb-2">
                  Cabang
                </div>

                <v-select
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Pilih Cabang"

                  :items="list_store"
                  item-value="id"
                  item-text="name"
                  :item-color="set_color"

                  clearable
                  @click:clear="filter.store_id = ''"

                  :no-filter="false"

                  v-model="filter.store_id">
                </v-select>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.filter = false; fetch();"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.filter = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- gambar rumah -->
        <v-dialog v-model="dialog.gambar_rumah"
          persistent
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Gambar Rumah
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second px-4 pb-2 mt-4">
              <div>
                <v-img v-if="selected.gambar_rumah_index === ''"
                  contain
                  width="100%"
                  height="250"
                  style="border: 5px solid #0000000d; border-radius: 10px;"
                  alt="bidanvitacare"
                  :src="detail.address_image_url !== '' ? detail.address_image_url : require('@/assets/image/image_default.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <v-img v-else
                  contain
                  width="100%"
                  height="250"
                  style="border: 5px solid #0000000d; border-radius: 10px;"
                  alt="bidanvitacare"
                  :src="JSON.parse(detail.address_image_array_json)[selected.gambar_rumah_index].image_url"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>

              <div v-if="detail.address_image_array_json.length > 0"
                class="mt-2">
                <no-ssr>
                  <swiper
                    class="swiper"
                    ref="swiper"
                    :options="{ slidesPerView: 'auto' }">
                    <swiper-slide
                      class="py-3 pl-0"
                      style="max-width: 120px;">
                      <v-img
                        @click="selected.gambar_rumah_index = ''"

                        contain
                        width="100%"
                        height="100px"
                        style="border: 5px solid #0000000d; border-radius: 10px;"
                        alt="bidanvitacare"
                        :src="detail.address_image_url !== '' ? detail.address_image_url : require('@/assets/image/image_default.png')"
                        class="ma-auto cursor-pointer">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>

                        <v-icon v-if="selected.gambar_rumah_index === ''"
                          color="green"
                          style="position: absolute; right: 0;">
                          mdi-check-circle
                        </v-icon>
                      </v-img>
                    </swiper-slide>

                    <swiper-slide
                      v-for="(item, index) in JSON.parse(detail.address_image_array_json)"
                      :key="index"
                      class="py-3"
                      :class="index === 0 ? 'pl-4 pr-4' : index < JSON.parse(detail.address_image_array_json).length - 1 ? 'pr-4' : 'pr-4'"
                      style="max-width: 120px;">
                      <v-img
                        @click="selected.gambar_rumah_index = index"

                        contain
                        width="100%"
                        height="100px"
                        alt="bidanvitacare"
                        :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                        class="ma-auto cursor-pointer"
                        style="border: 5px solid #0000000d; border-radius: 10px;">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>

                        <v-icon v-if="selected.gambar_rumah_index === index"
                          color="green"
                          style="position: absolute; right: 0;">
                          mdi-check-circle
                        </v-icon>
                      </v-img>
                    </swiper-slide>
                  </swiper>
                </no-ssr>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.gambar_rumah = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Tutup
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text
              class="pb-0">
              <div
                class="d-flex align-center mb-4">
                <v-text-field
                  v-model="search_data"
                  @click:clear="search_data = ''; fetch();"
                  v-on:keyup.enter="fetch()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>

                <v-btn
                  @click="dialog.filter = true; fetchStore();"
                  width="40"
                  height="40"
                  fab
                  elevation="0"
                  class="ml-3">
                  <v-icon
                    style="opacity: 60%;">
                    mdi-filter-variant
                  </v-icon>
                </v-btn>
              </div>

              <v-menu v-if="selected.menu !== 'non_tanggal'"
                ref="date"
                min-width="290px"
                max-width="290px"
                transition="scale-transition"
                offset-y
                :nudge-right="40"
                :close-on-content-click="false"
                v-model="picker.date">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    readonly
                    filled
                    rounded
                    dense
                    :color="set_color"
                    placeholder="Pilih Tanggal"

                    clearable
                    @click:clear="filter.date = ''; fetch();"

                    v-on="on"
                    v-bind="attrs"

                    hide-details

                    class="mb-4"

                    v-model="filter.date">
                  </v-text-field>
                </template>

                <v-date-picker
                  :color="set_color"
                  v-model="filter.date"
                  @input="picker.date = false; fetch();">
                </v-date-picker>
              </v-menu>
            </v-card-text>

            <v-card-text
              class="pb-0">
              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 4 : list_data"
                    :key="index"
                    :loading="process.load"
                    :class="index < list_data.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-card v-if="!process.load && list_data.length > 0"
                          @click="sheet.detail = true; fetchDetail(item.id);"
                          flat
                          color="transparent">
                          <div
                            class="d-flex align-center">
                            <div
                              class="font-weight-bold black--text">
                              <span v-if="item.date !== ''">
                                {{ item.date | date }}
                              </span>

                              <span v-else>
                                Tanggal belum ditentukan
                              </span>
                            </div>

                            <v-spacer />

                            <div
                              class="font-weight-bold">
                              {{ item.age ? item.age : '-' }}
                            </div>
                          </div>

                          <div>
                            <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />
                          </div>

                          <div
                            class="d-flex align-center mb-1">
                            <div
                              class="d-flex align-center">
                              <v-avatar
                                size="22"
                                style="border: 2px solid #ffffff;">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        size="20"
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>

                              <span
                                class="ml-1">
                                {{ item.customer_name }}
                              </span>
                            </div>

                            <v-spacer />

                            <div>
                              {{ item.store_name }}
                            </div>
                          </div>

                          <div>
                            <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />
                          </div>

                          <div
                            class="mt-3 mb-2 pa-3 pt-2"
                            style="border: 2px dashed #bbb; border-radius: 10px;">
                            <v-chip
                              x-small
                              class="white--text"
                              :color="item.type === 'on_site' ? 'blue' : 'green'"
                              style="font-weight: 500;">
                              {{ item.type === 'on_site' ? 'On Site' : 'Home Care' }}
                            </v-chip>

                            <div
                              v-for="(sub_item, sub_index) in item.details"
                              :key="sub_index">
                              <div
                                class="d-flex align-start my-1">
                                <v-avatar
                                  tile
                                  size="22"
                                  style="border: 2px solid #ffffff;">
                                  <v-img
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare"
                                    :src="sub_item.item_image_url !== '' ? sub_item.item_image_url : require('@/assets/image/image_default.png')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          size="20"
                                          indeterminate
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>

                                <span
                                  class="ml-1">
                                  {{ sub_item.item_name }}
                                </span>
                              </div>

                              <div
                                class="d-flex align-center"
                                style="margin-left: 26px;">
                                <div>
                                  Durasi. <strong>{{ sub_item.duration_minutes }} Menit</strong>
                                </div>

                                <v-spacer />

                                <div>
                                  Qty. <strong>{{ sub_item.qty }}x</strong>
                                </div>

                                <v-spacer />

                                <div>
                                  {{ sub_item.therapist_shift }}
                                </div>
                              </div>

                              <v-divider v-if="sub_index < item.details.length - 1"
                                class="my-2"
                                style="border-top: 1px dashed #bbb;" />
                            </div>
                          </div>

                          <div
                            class="mt-3 mb-2 pa-3 pt-2"
                            style="border: 2px dashed #bbb; border-radius: 10px;">
                            <div
                              class="d-flex align-center">
                              <div>
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  BB
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.bb }} Kg
                                </div>
                              </div>

                              <v-spacer v-if="item.client_type === 'bayi' || item.client_type === 'anak'" />

                              <div v-if="item.client_type === 'bayi' || item.client_type === 'anak'">
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  TB
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.tb }} Cm
                                </div>
                              </div>

                              <v-spacer v-if="item.client_type === 'bayi' || item.client_type === 'anak'" />

                              <div v-if="item.client_type === 'bayi' || item.client_type === 'anak'">
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  LILA
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.lila }} Cm
                                </div>
                              </div>

                              <v-spacer v-if="item.client_type === 'bayi' || item.client_type === 'anak'" />

                              <div v-if="item.client_type === 'bayi' || item.client_type === 'anak'">
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  LK
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.lk }} Cm
                                </div>
                              </div>

                              <v-spacer v-if="item.client_type !== 'bayi' && item.client_type !== 'anak'" />

                              <div v-if="item.client_type !== 'bayi' && item.client_type !== 'anak'">
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  Tensi
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.tensi !== '' && item.tensi !== null ? item.tensi : '0.00' }} mmHg
                                </div>
                              </div>
                            </div>
                          </div>

                          <v-row>
                            <v-col
                              cols="12"
                              class="pt-1">
                              <v-card
                                flat
                                color="#FFFFFF40"
                                class="pa-3"
                                style="border: 2px dashed #bbb; border-radius: 10px;">
                                <div
                                  class="d-flex align-center">
                                  <div>
                                    Terapis
                                  </div>

                                  <v-spacer />

                                  <div>
                                    <div v-if="item.visit.length > 0"
                                      class="d-flex align-center">
                                      <v-avatar
                                        v-for="(item_therapist, index_therapist) in item.visit"
                                        :key="index_therapist"
                                        v-show="index_therapist < 2"
                                        size="24"
                                        class="mt-0"
                                        style="border: 2px solid #ffffff;"
                                        :style="item.visit.length === 2 && index_therapist === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                                item.visit.length > 2 && index_therapist === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                                item.visit.length > 2 && index_therapist === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                                        <v-img
                                          width="100%"
                                          height="100%"
                                          alt="bidanvitacare"
                                          :src="item_therapist.therapist_image_url !== '' ? item_therapist.therapist_image_url : require('@/assets/image/member_default.jpg')"
                                          class="ma-auto">
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height"
                                              align="center"
                                              justify="center">
                                              <v-progress-circular
                                                size="20"
                                                indeterminate
                                                :color="set_color">
                                              </v-progress-circular>
                                            </v-row>
                                          </template>
                                        </v-img>
                                      </v-avatar>

                                      <span v-if="item.visit.length < 2"
                                        class="caption font-weight-bold one-line ml-1"
                                        style="width: 50px;">
                                        {{ item.visit[0].therapist_name }}
                                      </span>

                                      <span v-if="item.visit.length > 2"
                                        class="caption font-weight-bold ml-1">
                                        +{{ item.visit.length - 2 }}
                                      </span>
                                    </div>

                                    <div v-else
                                      class="font-weight-bold black--text">
                                      -
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div
                                  class="d-flex align-center">
                                  <div>
                                    Klien
                                  </div>

                                  <v-spacer />

                                  <div v-if="item.client_name !== ''"
                                    class="d-flex align-center">
                                    <v-avatar
                                      size="22"
                                      style="border: 2px solid #ffffff;">
                                      <v-img
                                        width="100%"
                                        height="100%"
                                        alt="bidanvitacare"
                                        :src="item.client_image_url !== '' ? item.client_image_url : require('@/assets/image/member_default.jpg')"
                                        class="ma-auto">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular
                                              size="20"
                                              indeterminate
                                              :color="set_color">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-avatar>

                                    <span
                                      class="ml-1">
                                      {{ item.client_name }}
                                    </span>
                                  </div>

                                  <div v-else>
                                    -
                                  </div>
                                </div>

                                <div v-if="item.address_link_map !== ''">
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div v-if="item.address_link_map !== ''"
                                  class="d-flex align-center">
                                  <div>
                                    Alamat
                                  </div>

                                  <v-spacer />

                                  <div>
                                    <a
                                      :href="item.address_link_map"
                                      target="_blank">
                                      Lihat Lokasi
                                    </a>
                                  </div>
                                </div>

                                <div v-if="item.type === 'home_care'">
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div v-if="item.type === 'home_care'">
                                  <div v-if="item.transport_id !== '' && item.transport_id !== '0'"
                                    class="d-flex align-center">
                                    <div>
                                      Transport ({{ item.transport_qty }} x {{ Number(item.transport_cost) | price }})
                                    </div>

                                    <v-spacer />

                                    <div class="font-weight-bold">
                                      {{ Number(item.transport_total_cost) | price }}
                                    </div>
                                  </div>

                                  <div v-else
                                    class="d-flex align-center">
                                    <div>
                                      Transport
                                    </div>

                                    <v-spacer />

                                    <div class="font-weight-bold">
                                      -
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div
                                  class="d-flex align-center">
                                  <div>
                                    Penugasan
                                  </div>

                                  <v-spacer />

                                  <div class="text-uppercase font-weight-bold">
                                    {{ !item.is_therapist_partner_bool ? 'Single' : 'Partner' }}
                                  </div>
                                </div>

                                <div>
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div
                                  class="d-flex align-center">
                                  <div>
                                    Down Payment
                                  </div>

                                  <v-spacer />

                                  <div class="text-uppercase font-weight-bold">
                                    <span v-if="Number(item.dp) > 0">
                                      {{ Number(item.dp) | price }}
                                    </span>

                                    <span v-else>
                                      -
                                    </span>
                                  </div>
                                </div>

                                <div>
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div
                                  class="d-flex align-center">
                                  <div>
                                    Diinput Oleh
                                  </div>

                                  <v-spacer />

                                  <div>
                                    {{ item.create_user_name }}
                                  </div>
                                </div>

                                <div>
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div class="font-italic">
                                  <div class="mb-1">
                                    *Catatan Khusus Admin
                                  </div>

                                  <div>
                                    {{ item.note_khusus !== '' ? item.note_khusus : 'Tidak ada catatan khusus admin' }}
                                  </div>
                                </div>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <div v-if="!process.load && pagination_data.total_data > 10 && limit_data < pagination_data.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit_data += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination_data.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Jadwal" />
            </v-card-text>
          </v-card>
        </section>

        <!-- detail -->
        <v-bottom-sheet
          v-model="sheet.detail"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.detail = false; detail = {}; fetch();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.detail = false; detail = {}; fetch();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Kunjungan {{ detail.customer_name }} - {{ detail.client_name}}
                </span>
              </v-card-title>

              <v-card-text
                class="mt-16 px-0"
                style="height:calc(100vh - 130px); overflow: auto;">
                <v-skeleton-loader
                  :loading="process.load"
                  type="list-item-avatar-three-line, article, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, article, actions">
                  <div>
                    <div v-if="!process.load && Object.keys(detail).length > 0">
                      <v-card
                        flat
                        color="transparent">
                        <v-card-text
                          class="pb-0">
                          <div
                            class="d-flex align-center">
                            <v-avatar
                              size="36"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="detail.customer_image_url !== '' ? detail.customer_image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <span
                              class="font-weight-bold text-capitalize black--text ml-2">
                              {{ detail.customer_name }}
                            </span>
                          </div>
                        </v-card-text>

                        <v-card-text
                          class="pb-0">
                          <v-divider
                            class="mb-4"
                            style="border-top: 1px dashed #bbb;" />

                          <!-- tipe & umur -->
                          <div
                            class="d-flex align-center">
                            <div>
                              <div
                                class="body-2 mb-1">
                                Tipe
                              </div>

                              <div
                                class="body-2 font-weight-bold black--text">
                                {{ detail.type === 'on_site' ? 'On Site' : 'Home Care' }}
                              </div>
                            </div>

                            <v-spacer />

                            <div>
                              <div
                                class="body-2 mb-1">
                                Umur
                              </div>

                              <div
                                class="body-2 font-weight-bold black--text">
                                {{ detail.age !== '' ? detail.age : '-' }}
                              </div>
                            </div>
                          </div>

                          <v-divider
                            class="my-4"
                            style="border-top: 1px dashed #bbb;" />

                          <!-- bb / tb / lila / lk -->
                          <div
                            class="d-flex align-center">
                            <div>
                              <div
                                class="body-2 mb-1">
                                BB
                              </div>

                              <div
                                class="body-2 black--text font-weight-bold">
                                {{ detail.bb }} Kg
                              </div>
                            </div>

                            <v-spacer v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'" />

                            <div v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'">
                              <div
                                class="body-2 mb-1">
                                TB
                              </div>

                              <div
                                class="body-2 black--text font-weight-bold">
                                {{ detail.tb }} Cm
                              </div>
                            </div>

                            <v-spacer v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'" />

                            <div v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'">
                              <div
                                class="body-2 mb-1">
                                LILA
                              </div>

                              <div
                                class="body-2 black--text font-weight-bold">
                                {{ detail.lila }} Cm
                              </div>
                            </div>

                            <v-spacer v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'" />

                            <div v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'">
                              <div
                                class="body-2 mb-1">
                                LK
                              </div>

                              <div
                                class="body-2 black--text font-weight-bold">
                                {{ detail.lk }} Cm
                              </div>
                            </div>

                            <v-spacer v-if="detail.client_type !== 'bayi' && detail.client_type !== 'anak'" />

                            <div v-if="detail.client_type !== 'bayi' && detail.client_type !== 'anak'">
                              <div
                                class="body-2 mb-1">
                                Tensi
                              </div>

                              <div
                                class="body-2 black--text font-weight-bold">
                                {{ detail.tensi !== '' && detail.tensi !== null ? detail.tensi : '0.00' }} mmHg
                              </div>
                            </div>
                          </div>

                          <v-divider
                            class="my-4"
                            style="border-top: 1px dashed #bbb;" />

                          <!-- klien -->
                          <div
                            class="d-flex align-center">
                            <div
                              class="body-2">
                              Klien
                            </div>

                            <v-spacer />

                            <div>
                              <div
                                class="d-flex align-center">
                                <v-avatar
                                  size="24"
                                  class="mt-0"
                                  style="border: 2px solid #ffffff;">
                                  <v-img
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare"
                                    :src="detail.client_image_url !== '' ? detail.client_image_url : require('@/assets/image/member_default.jpg')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          size="20"
                                          indeterminate
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>

                                <span
                                  class="caption black--text font-weight-bold one-line text-capitalize ml-1">
                                  {{ detail.client_name }}
                                </span>
                              </div>
                            </div>
                          </div>

                          <v-divider
                            class="my-4"
                            style="border-top: 1px dashed #bbb;" />

                          <!-- alat -->
                          <div
                            class="mb-4">
                            <div
                              class="body-2 font-weight-bold black--text mb-2">
                              Alat yang harus dibawa terapis
                            </div>

                            <div
                              class="body-2 text-capitalize">
                              <ul
                                v-for="(item, index) in detail.details"
                                style="position: relative; left: -10px;">
                                <li
                                  :class="index < detail.details.length - 1 ? 'mb-3' : ''">
                                  <div
                                    class="mb-1">
                                    {{ item.item_name }}
                                  </div>

                                  <ul v-if="item.material_json_array.length > 0"
                                    style="position: relative; left: -10px;">
                                    <li
                                      v-for="(sub_item, sub_index) in item.material_json_array"
                                      :key="sub_index"
                                      :class="sub_index < item.material_json_array.length - 1 ? 'mb-1' : ''"
                                      v-html="sub_item.name">
                                    </li>
                                  </ul>

                                  <div v-else>
                                    -
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div>
                            <div
                              class="body-2 font-weight-bold black--text mb-2">
                              Alat yang harus dipersiapkan customer
                            </div>

                            <div
                              class="body-2 text-capitalize">
                              <ul
                                v-for="(item, index) in detail.details"
                                style="position: relative; left: -10px;">
                                <li
                                  :class="index < detail.details.length - 1 ? 'mb-3' : ''">
                                  <div
                                    class="mb-1">
                                    {{ item.item_name }}
                                  </div>

                                  <ul v-if="item.customer_prepare_json_array.length > 0"
                                    style="position: relative; left: -10px;">
                                    <li
                                      v-for="(sub_item, sub_index) in item.customer_prepare_json_array"
                                      :key="sub_index"
                                      :class="sub_index < item.customer_prepare_json_array.length - 1 ? 'mb-1' : ''"
                                      v-html="sub_item.value">
                                    </li>
                                  </ul>

                                  <div v-else>
                                    -
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <v-divider
                            class="my-4"
                            style="border-top: 1px dashed #bbb;" />

                          <!-- tanggal dan jam -->
                          <v-row
                            class="mb-2">
                            <v-col
                              cols="4"
                              class="py-0">
                              Tanggal & Waktu Kunjungan
                            </v-col>

                            <v-col
                              cols="8"
                              class="py-0">
                              : {{ detail.details[0].datetime | date }} Pukul {{ detail.details[0].datetime | time }}
                            </v-col>
                          </v-row>

                          <!-- status wali -->
                          <v-row
                            class="mb-2">
                            <v-col
                              cols="4"
                              class="py-0">
                              Status Wali
                            </v-col>

                            <v-col
                              cols="8"
                              class="py-0">
                              : {{ detail.customer_wali_status !== '' ? detail.customer_wali_status : '-' }}
                            </v-col>
                          </v-row>

                          <!-- wali -->
                          <v-row
                            class="mb-2">
                            <v-col
                              cols="4"
                              class="py-0">
                              Nama Wali
                            </v-col>

                            <v-col
                              cols="8"
                              class="py-0">
                              : {{ detail.customer_wali_name !== '' ? detail.customer_wali_name : '-' }}
                            </v-col>
                          </v-row>

                          <!-- pendamping -->
                          <v-row
                            class="mb-2">
                            <v-col
                              cols="4"
                              class="py-0">
                              Nama Pendamping
                            </v-col>

                            <v-col
                              cols="8"
                              class="py-0">
                              : {{ detail.wali !== '' ? detail.wali : '-' }}
                            </v-col>
                          </v-row>

                          <v-divider
                            class="my-4"
                            style="border-top: 1px dashed #bbb;" />

                          <!-- alamat -->
                          <div v-if="detail.type === 'home_care'">
                            <v-row
                              class="mb-2">
                              <v-col
                                cols="4"
                                class="py-0">
                                Alamat
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0">
                                <div
                                  class="d-flex align-start">
                                  <div>
                                    :
                                  </div>

                                  <div
                                    class="ml-1">
                                    {{ detail.address_full !== '' ? detail.address_full : '-' }}
                                  </div>
                                </div>
                              </v-col>
                            </v-row>

                            <v-row
                              class="mb-2">
                              <v-col
                                cols="4"
                                class="py-0">
                                Kecamatan
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0">
                                : {{ detail.address_subdistrict_name !== '' ? detail.address_subdistrict_name : '-' }}
                              </v-col>
                            </v-row>

                            <v-row
                              class="mb-2">
                              <v-col
                                cols="4"
                                class="py-0">
                                Kabupaten
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0">
                                : {{ detail.address_city_name !== '' ? detail.address_city_name : '-' }}
                              </v-col>
                            </v-row>

                            <v-row
                              class="mb-2">
                              <v-col
                                cols="4"
                                class="py-0">
                                Provinsi
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0">
                                : {{ detail.address_province_name !== '' ? detail.address_province_name : '-' }}
                              </v-col>
                            </v-row>

                            <v-row
                              class="mb-2">
                              <v-col
                                cols="4"
                                class="py-0">
                                Keterangan Rumah
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0">
                                <div
                                  class="d-flex align-start">
                                  <div>
                                    :
                                  </div>

                                  <div
                                    class="ml-1">
                                    {{ detail.address_note !== '' ? detail.address_note : '-' }}
                                  </div>
                                </div>
                              </v-col>
                            </v-row>

                            <v-row
                              class="mb-2">
                              <v-col
                                cols="4"
                                class="py-0">
                                Gambar Rumah
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0">
                                <div
                                  class="d-flex align-start">
                                  <div>
                                    :
                                  </div>

                                  <div
                                    class="ml-1">
                                    <span
                                      @click="dialog.gambar_rumah = true"
                                      class="cursor-pointer"
                                      style="color: #1976d3;">
                                      Lihat disini
                                    </span>
                                  </div>
                                </div>
                              </v-col>
                            </v-row>

                            <v-row
                              class="mb-2">
                              <v-col
                                cols="4"
                                class="py-0">
                                Link Map
                              </v-col>

                              <v-col
                                cols="8"
                                class="py-0">
                                <div
                                  class="d-flex align-start">
                                  <div>
                                    :
                                  </div>

                                  <div
                                    class="text-truncate ml-1">
                                    <a v-if="detail.address_link_map !== ''"
                                      :href="detail.address_link_map"
                                      target="_blank">
                                      {{ detail.address_link_map }}
                                    </a>

                                    <span v-else>
                                      -
                                    </span>
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                          </div>

                          <v-divider v-if="detail.type === 'home_care'"
                            class="my-4"
                            style="border-top: 1px dashed #bbb;" />

                          <!-- transport -->
                          <div v-if="detail.type === 'home_care'"
                            class="my-4">
                            <div class="body-2 black--text font-weight-bold mb-2">
                              Transport
                            </div>

                            <div v-if="detail.transport_id !== '' && detail.transport_id !== '0'">
                              <div
                                class="d-flex align-center mb-1">
                                <div>
                                  {{ detail.transport_name }}
                                </div>

                                <v-spacer />

                                <div>
                                  {{ Number(detail.transport_cost) | price }}
                                </div>
                              </div>

                              <div
                                class="d-flex align-center">
                                <div>
                                  Qty ({{ detail.transport_qty }}x)
                                </div>

                                <v-spacer />

                                <div
                                  class="font-weight-bold black--text">
                                  {{ Number(detail.transport_total_cost) | price }}
                                </div>
                              </div>
                            </div>

                            <div v-else>
                              Transport belum ditentukan
                            </div>
                          </div>

                          <v-divider v-if="detail.type === 'home_care'"
                            class="my-4"
                            style="border-top: 1px dashed #bbb;" />

                          <!-- kpsp -->
                          <div v-if="detail.kpsp.length > 0">
                            <div
                              class="mb-2">
                              <div
                                class="body-2 black--text font-weight-bold mb-1">
                                KPSP
                              </div>

                              <div>
                                Berikut ini adalah data kpsp.
                              </div>
                            </div>

                            <v-list
                              v-for="(item, index) in detail.kpsp"
                              :key="index"
                              flat
                              dense
                              two-line
                              color="#FFFFFF40"
                              class="border-radius pa-0">
                              <v-list-item
                                class="pa-0">
                                <v-list-item-content>
                                  <v-list-item-title
                                    class="body-2 font-weight-bold text-capitalize">
                                    {{ item.kpsp_type }} - {{ item.kpsp_age_month }} Bulan
                                  </v-list-item-title>

                                  <v-list-item-subtitle
                                    class="body-2 font-weight-light line-text-second black--text"
                                    style="opacity: 70%;">
                                    {{ item.kpsp_question }}
                                  </v-list-item-subtitle>

                                  <v-list-item-subtitle
                                    class="body-2 font-weight-bold">
                                    Jawaban:

                                    <span
                                      :class="item.kpsp_answer === 'YA' ? 'green--text' : item.kpsp_answer === 'TIDAK' ? 'red--text' : ''">
                                      {{ item.kpsp_answer !== '' ? item.kpsp_answer : '-' }}
                                    </span>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </div>

                          <v-divider v-if="detail.kpsp.length > 0"
                            class="my-4"
                            style="border-top: 1px dashed #bbb;" />

                          <!-- bb / tb / lila / lk -->
                          <div v-if="!detail.graph_json_object.is_graph_empty_bool">
                            <div>
                              <div class="body-2 mb-2">
                                {{ detail.graph_json_object.bb.title }}
                              </div>

                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                style="border-radius: 10px; border: 2px dashed #bbb;"
                                alt="bidanvitacare"
                                :src="detail.graph_json_object.bb.image_url !== '' ? detail.graph_json_object.bb.image_url : require('@/assets/image/image_default.png')"
                                class="ma-auto d-flex align-center text-center">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>

                              <div
                                class="body-2 line-text-second mt-4">
                                <div
                                  class="black--text font-weight-bold mb-1">
                                  Kesimpulan
                                </div>

                                <div>
                                  {{ detail.graph_json_object.bb.kesimpulan }}
                                </div>
                              </div>
                            </div>

                            <v-divider
                              class="my-4"
                              style="border-top: 1px dashed #bbb;" />

                            <div>
                              <div class="body-2 mb-2">
                                {{ detail.graph_json_object.tb.title }}
                              </div>

                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                style="border-radius: 10px; border: 2px dashed #bbb;"
                                alt="bidanvitacare"
                                :src="detail.graph_json_object.tb.image_url !== '' ? detail.graph_json_object.tb.image_url : require('@/assets/image/image_default.png')"
                                class="ma-auto d-flex align-center text-center">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>

                              <div
                                class="body-2 line-text-second mt-4">
                                <div
                                  class="black--text font-weight-bold mb-1">
                                  Kesimpulan
                                </div>

                                <div>
                                  {{ detail.graph_json_object.tb.kesimpulan }}
                                </div>
                              </div>
                            </div>

                            <v-divider
                              class="my-4"
                              style="border-top: 1px dashed #bbb;" />

                            <div>
                              <div class="body-2 mb-2">
                                {{ detail.graph_json_object.lila.title }}
                              </div>

                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                style="border-radius: 10px; border: 2px dashed #bbb;"
                                alt="bidanvitacare"
                                :src="detail.graph_json_object.lila.image_url !== '' ? detail.graph_json_object.lila.image_url : require('@/assets/image/image_default.png')"
                                class="ma-auto d-flex align-center text-center">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>

                              <div
                                class="body-2 line-text-second mt-4">
                                <div
                                  class="black--text font-weight-bold mb-1">
                                  Kesimpulan
                                </div>

                                <div>
                                  {{ detail.graph_json_object.lila.kesimpulan }}
                                </div>
                              </div>
                            </div>

                            <v-divider
                              class="my-4"
                              style="border-top: 1px dashed #bbb;" />

                            <div>
                              <div class="body-2 mb-2">
                                {{ detail.graph_json_object.lk.title }}
                              </div>

                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                style="border-radius: 10px; border: 2px dashed #bbb;"
                                alt="bidanvitacare"
                                :src="detail.graph_json_object.lk.image_url !== '' ? detail.graph_json_object.lk.image_url : require('@/assets/image/image_default.png')"
                                class="ma-auto d-flex align-center text-center">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>

                              <div
                                class="body-2 line-text-second mt-4">
                                <div
                                  class="black--text font-weight-bold mb-1">
                                  Kesimpulan
                                </div>

                                <div>
                                  {{ detail.graph_json_object.lk.kesimpulan }}
                                </div>
                              </div>
                            </div>

                            <v-divider
                              class="my-4"
                              style="border-top: 1px dashed #bbb;" />
                          </div>

                          <!-- laporan -->
                          <div
                            class="mb-2">
                            <div
                              class="body-2 black--text font-weight-bold mb-1">
                              Laporan
                            </div>

                            <div>
                              Berikut ini adalah data laporan dari setiap terapis.
                            </div>
                          </div>

                          <!-- pilih terapis -->
                          <div v-if="detail.visit.length > 0">
                            <div
                              class="body-2 mb-2">
                              Pilih Terapis
                            </div>

                            <v-select
                              filled
                              rounded
                              dense
                              :color="set_color"
                              placeholder="Pilih Terapis"

                              :items="detail.visit"
                              item-value="id"
                              item-text="therapist_name"
                              :item-color="set_color"

                              :no-filter="false"

                              v-model="selected.detail_visit_id">
                            </v-select>
                          </div>

                          <!-- data laporan -->
                          <div>
                            <div
                              class="mb-3">
                              <div
                                class="font-weight-bold black--text mb-1">
                                Catatan Kejadian
                              </div>

                              <div>
                                {{ Object.keys(selected.detail_visit).length > 0 && selected.detail_visit.laporan_catatan_kejadian !== '' && selected.detail_visit.laporan_catatan_kejadian !== null ? selected.detail_visit.laporan_catatan_kejadian : '-' }}
                              </div>
                            </div>

                            <div
                              class="mb-3">
                              <div
                                class="font-weight-bold black--text mb-1">
                                Perihal Kesehatan Ibu & Anak yang ditanyakan
                              </div>

                              <div>
                                {{ Object.keys(selected.detail_visit).length > 0 && selected.detail_visit.laporan_perihal_kesehatan !== '' && selected.detail_visit.laporan_perihal_kesehatan !== null ? selected.detail_visit.laporan_perihal_kesehatan : '-' }}
                              </div>
                            </div>

                            <div
                              class="mb-3">
                              <div
                                class="font-weight-bold black--text mb-1">
                                Pertanyaan kepo tentang manajemen
                              </div>

                              <div>
                                {{ Object.keys(selected.detail_visit).length > 0 && selected.detail_visit.laporan_pertanyaan_kepo !== '' && selected.detail_visit.laporan_pertanyaan_kepo !== null ? selected.detail_visit.laporan_pertanyaan_kepo : '-' }}
                              </div>
                            </div>

                            <div
                              class="mb-3">
                              <div
                                class="font-weight-bold black--text mb-1">
                                Kesalahan yang dilakukan Terapis
                              </div>

                              <div>
                                {{ Object.keys(selected.detail_visit).length > 0 && selected.detail_visit.laporan_kesalahan_therapist !== '' && selected.detail_visit.laporan_kesalahan_therapist !== null ? selected.detail_visit.laporan_kesalahan_therapist : '-' }}
                              </div>
                            </div>

                            <div
                              class="mb-3">
                              <div
                                class="font-weight-bold black--text mb-1">
                                Komplain Klien secara langsung
                              </div>

                              <div>
                                {{ Object.keys(selected.detail_visit).length > 0 && selected.detail_visit.laporan_komplain !== '' && selected.detail_visit.laporan_komplain !== null ? selected.detail_visit.laporan_komplain : '-' }}
                              </div>
                            </div>

                            <div
                              class="mb-3">
                              <div
                                class="font-weight-bold black--text mb-1">
                                Masukkan untuk manajemen bidanvitacare / kendala yang dihadapi saat homecare atau onsite
                              </div>

                              <div>
                                {{ Object.keys(selected.detail_visit).length > 0 && selected.detail_visit.laporan_masukan !== '' && selected.detail_visit.laporan_masukan !== null ? selected.detail_visit.laporan_masukan : '-' }}
                              </div>
                            </div>

                            <div>
                              <div
                                class="font-weight-bold black--text mb-1">
                                Promosi
                              </div>

                              <div>
                                {{ Object.keys(selected.detail_visit).length > 0 && selected.detail_visit.laporan_promosi !== '' && selected.detail_visit.laporan_promosi !== null ? selected.detail_visit.laporan_promosi : '-' }}
                              </div>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </div>
                </v-skeleton-loader>
              </v-card-text>

              <!-- ubah / lampiran / grafik -->
              <v-card v-if="!process.load && Object.keys(detail).length > 0"
                flat
                color="#FFFFFF"
                min-height="50"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row>
                    <v-col
                      :cols="detail.client_type === 'bayi' || detail.client_type === 'anak' ? 4 : 6"
                      class="py-0">
                      <v-btn
                        @click="sheet.ubah = true; selected.menu = 0;"

                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/edit_pencil.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="blue--text ml-2">
                            Ubah
                          </div>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      :cols="detail.client_type === 'bayi' || detail.client_type === 'anak' ? 4 : 6"
                      class="py-0">
                      <v-btn
                        @click="sheet.lampiran = true; reset_lampiran();"

                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/attachment.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="pink--text ml-2">
                            Lamp
                          </div>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'"
                      cols="4"
                      class="py-0">
                      <v-btn
                        @click="sheet.grafik = true; reset_grafik();"

                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/grafik.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="green--text ml-2">
                            Grafik
                          </div>
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <!-- ubah -->
        <v-bottom-sheet
          v-model="sheet.ubah"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.ubah = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.ubah = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Ubah Data {{ detail.customer_name }} - {{ detail.client_name}}
                </span>
              </v-card-title>

              <v-card
                flat
                color="transparent">
                <v-card-text
                  class="pt-16 mt-3">
                  <v-row
                    class="px-1">
                    <v-col
                      v-for="(item, index) in menu"
                      :key="index"
                      cols="4"
                      class="py-0 px-2">
                      <v-btn
                        @click="selected.menu = index"
                        block
                        rounded
                        elevation="0"
                        :class="selected.menu !== index ? '' : 'font-weight-bold'"
                        :color="selected.menu !== index ? '#e2e2e2' : set_color"
                        :style="selected.menu !== index ? 'color: #8c8c8c;' : 'color: #FFFFFF;'"
                        class="body-2 text-capitalize mb-2">
                        {{ item }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="transparent">
                <v-card-text
                  class="pa-0">
                  <Antropometri v-if="selected.menu === 0"
                    :detail="detail"
                    @load="fetchDetail(detail.id); sheet.ubah = false;" />

                  <KPSP v-if="selected.menu === 1"
                    :detail="detail"
                    @load="fetchDetail(detail.id)"  />

                  <Laporan v-if="selected.menu === 2"
                    :detail="detail"
                    :detail_visit_id="selected.detail_visit_id"
                    @load="fetchDetail(detail.id, 'after_update'); sheet.ubah = false;" />
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <!-- lampiran -->
        <v-bottom-sheet
          v-model="sheet.lampiran"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div v-if="Object.keys(detail).length > 0"
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.lampiran = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.lampiran = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Lampiran {{ detail.customer_name }} - {{ detail.client_name}}
                </span>
              </v-card-title>

              <v-card-text
                class="pb-0 pt-16 mt-3">
                <div v-if="detail.visit.length > 0">
                  <v-select
                    filled
                    rounded
                    dense
                    :color="set_color"
                    placeholder="Pilih Terapis"

                    :items="detail.visit"
                    item-value="id"
                    item-text="therapist_name"
                    :item-color="set_color"

                    :no-filter="false"
                    hide-details
                    class="mb-4"

                    v-model="selected.detail_visit_id">
                  </v-select>
                </div>

                <v-btn
                  @click="sheet.form_lampiran = true; reset_lampiran();"

                  block
                  large
                  rounded
                  elevation="0"
                  class="body-1 font-weight-bold white--text text-capitalize btn_auth"
                  :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                  <div
                    class="btn_auth_in d-flex align-center justify-center"
                    :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                    <div>
                      <v-img
                        contain
                        width="20"
                        height="20"
                        alt="bidanvitacare"
                        :src="require('@/assets/icon/attachment.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>

                    <div
                      class="ml-2">
                      Tambah Lampiran
                    </div>
                  </div>
                </v-btn>
              </v-card-text>

              <v-card-text
                class="pb-0"
                style="height: calc(100vh - 185px); overflow: auto;">
                <v-row>
                  <v-col
                    v-for="(item, index) in form_lampiran.dokumentasi_json_array"
                    :key="index"
                    cols="6">
                    <v-card
                      flat
                      width="100%"
                      height="200"
                      class="d-flex align-center justify-center"
                      style="border-radius: 15px; border: 5px solid #0000000d; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
                      <v-img
                        alt="bidanvitacare"
                        width="100%"
                        height="190"
                        :src="item"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>

                <Empty v-if="form_lampiran.dokumentasi_json_array.length < 1"
                  class="py-6"
                  margin="my-16"
                  size="125"
                  message="Lampiran" />
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>

        <!-- tambah lampiran -->
        <v-bottom-sheet
          v-model="sheet.form_lampiran"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div v-if="Object.keys(selected.detail_visit).length > 0"
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.form_lampiran = false; reset_lampiran();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.form_lampiran = false; reset_lampiran();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Terapis {{ selected.detail_visit.therapist_name }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16">
                  <v-row>
                    <v-col
                      v-for="(item, index) in form_lampiran.dokumentasi_json_array"
                      :key="index"
                      cols="6">
                      <v-card
                        flat
                        width="100%"
                        height="200"
                        class="d-flex align-center justify-center"
                        style="border-radius: 15px; border: 5px solid #0000000d; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
                        <v-img
                          alt="bidanvitacare"
                          width="100%"
                          height="190"
                          :src="item"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>

                          <div
                            @click="form_lampiran.dokumentasi_json_array.splice(index, 1); save_lampiran(true);"
                            class="cursor-pointer"
                            style="position: absolute; top: 5px; right: 5px;">
                            <v-icon size="24" color="red">mdi-close-circle</v-icon>
                          </div>
                        </v-img>
                      </v-card>
                    </v-col>

                    <v-col
                      cols="6">
                      <upload-folder
                        v-model="upload.folder">
                        <div
                          slot="activator"
                          class="cursor-pointer">
                          <v-card
                            flat
                            width="100%"
                            height="200"
                            class="d-flex align-center justify-center"
                            style="border-radius: 15px; border: 5px solid #0000000d;">
                            <v-icon v-if="!process.file"
                              size="50"
                              color="grey">
                              mdi-plus
                            </v-icon>

                            <v-progress-circular v-else
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-card>
                        </div>
                      </upload-folder>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-text v-if="message.error.length > 0">
                  <div
                    class="text-center mt-3 mb-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/icon/attachment.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data Lampiran untuk rekam kunjungan di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        v-on:keyup.enter="save_lampiran()"
                        @click="save_lampiran()"
                        :disabled="process.form"
                        :loading="process.form"

                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.form_lampiran = false; reset_lampiran();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <!-- grafik -->
        <v-bottom-sheet
          v-model="sheet.grafik"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div v-if="Object.keys(detail).length > 0"
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.grafik = false; reset_grafik();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.grafik = false; reset_grafik();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Grafik {{ detail.customer_name }} - {{ detail.client_name}}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-5">
                  <!-- bb -->
                  <div>
                    <div class="body-2 line-text-second">
                      {{ detail.graph_json_object.bb.title }}
                    </div>

                    <div
                      id="bb"
                      class="my-4">
                      <apexchart
                        height="300"
                        :options="bar.bb.chartOptions"
                        :series="bar.bb.series">
                      </apexchart>
                    </div>

                    <div class="body-2 line-text-second mb-1">
                      <span class="font-weight-bold black--text">
                        Kesimpulan:
                      </span>

                      {{ detail.graph_json_object.bb.kesimpulan !== '' ? detail.graph_json_object.bb.kesimpulan : '-' }}
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <!-- tb -->
                  <div>
                    <div class="body-2 line-text-second">
                      {{ detail.graph_json_object.tb.title }}
                    </div>

                    <div
                      id="tb"
                      class="my-4">
                      <apexchart
                        height="300"
                        :options="bar.tb.chartOptions"
                        :series="bar.tb.series">
                      </apexchart>
                    </div>

                    <div class="body-2 line-text-second mb-1">
                      <span class="font-weight-bold black--text">
                        Kesimpulan:
                      </span>

                      {{ detail.graph_json_object.tb.kesimpulan !== '' ? detail.graph_json_object.tb.kesimpulan : '-' }}
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <!-- lila -->
                  <div>
                    <div class="body-2 line-text-second">
                      {{ detail.graph_json_object.lila.title }}
                    </div>

                    <div
                      id="lila"
                      class="my-4">
                      <apexchart
                        height="300"
                        :options="bar.lila.chartOptions"
                        :series="bar.lila.series">
                      </apexchart>
                    </div>

                    <div class="body-2 line-text-second mb-1">
                      <span class="font-weight-bold black--text">
                        Kesimpulan:
                      </span>

                      {{ detail.graph_json_object.lila.kesimpulan !== '' ? detail.graph_json_object.lila.kesimpulan : '-' }}
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <!-- lk -->
                  <div>
                    <div class="body-2 line-text-second">
                      {{ detail.graph_json_object.lk.title }}
                    </div>

                    <div
                      id="lk"
                      class="my-4">
                      <apexchart
                        height="300"
                        :options="bar.lk.chartOptions"
                        :series="bar.lk.series">
                      </apexchart>
                    </div>

                    <div class="body-2 line-text-second mb-1">
                      <span class="font-weight-bold black--text">
                        Kesimpulan:
                      </span>

                      {{ detail.graph_json_object.lk.kesimpulan !== '' ? detail.graph_json_object.lk.kesimpulan : '-' }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/icon/grafik.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data Grafik untuk rekam kunjungan di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn v-if="detail.graph_json_object.is_graph_empty_bool"
                        @click="to_save_grafik_all()"
                        :disabled="process.form"
                        :loading="process.form"

                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>

                      <v-btn v-else
                        @click="save_reset_grafik()"
                        :disabled="process.form"
                        :loading="process.form"

                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-refresh
                          </v-icon>

                          Reset
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.grafik = false; reset_grafik();"
                        :disabled="process.form"

                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          go_back: false,

          filter: false,
          gambar_rumah: false
        },

        sheet: {
          detail: false,

          ubah: false,
          lampiran: false,
          form_lampiran: false,
          grafik: false
        },

        menu: ['Antropometri', 'KPSP', 'Laporan'],

        selected: {
          gambar_rumah_index: '',

          detail_visit_id: '',
          detail_visit: {},

          menu: 0
        },

        filter: {
          date: '',
          client_id: '',
          store_id: ''
        },

        search_data: '',

        detail: {},

        list_data: [],
        pagination_data: {},
        limit_data: 10,

        search_klien: '',
        list_klien: [],
        pagination_klien: {},

        list_store: [],

        picker: {
          date: false
        },

        upload: {
          folder: null
        },

        form_lampiran: {
          visit_id: '',
          dokumentasi_json_array: []
        },

        form_grafik: {
          antropometri_id: '',
          graph_json_object: {
            bb: {
              title: '',
              data: [],
              image_url: '',
              kesimpulan: ''
            },
            tb: {
              title: '',
              data: [],
              image_url: '',
              kesimpulan: ''
            },
            lila: {
              title: '',
              data: [],
              image_url: '',
              kesimpulan: ''
            },
            lk: {
              title: '',
              data: [],
              image_url: '',
              kesimpulan: ''
            }
          }
        },

        bar: {
          bb: {
            series: [],
            chartOptions: {
              chart: {
                animations: {
                  enabled: true,
                  easing: 'line',
                  dynamicAnimation: {
                    speed: 1000
                  }
                },
                toolbar: {
                  show: false
                },
                zoom: {
                  enabled: false
                },
                type: 'area'
              },
              stroke: {
                width: 3,
                curve: 'smooth'
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                show: false
              },
              colors: ['#000000'],
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return Math.round(value)
                  }
                }
              },
              xaxis: {
                categories: []
              }
            }
          },
          tb: {
            series: [],
            chartOptions: {
              chart: {
                animations: {
                  enabled: true,
                  easing: 'line',
                  dynamicAnimation: {
                    speed: 1000
                  }
                },
                toolbar: {
                  show: false
                },
                zoom: {
                  enabled: false
                },
                type: 'area'
              },
              stroke: {
                width: 3,
                curve: 'smooth'
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                show: false
              },
              colors: ['#000000'],
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return Math.round(value)
                  }
                }
              },
              xaxis: {
                categories: []
              }
            }
          },
          lila: {
            series: [],
            chartOptions: {
              chart: {
                animations: {
                  enabled: true,
                  easing: 'line',
                  dynamicAnimation: {
                    speed: 1000
                  }
                },
                toolbar: {
                  show: false
                },
                zoom: {
                  enabled: false
                },
                type: 'area'
              },
              stroke: {
                width: 3,
                curve: 'smooth'
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                show: false
              },
              colors: ['#000000'],
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return Math.round(value)
                  }
                }
              },
              xaxis: {
                categories: []
              }
            }
          },
          lk: {
            series: [],
            chartOptions: {
              chart: {
                animations: {
                  enabled: true,
                  easing: 'line',
                  dynamicAnimation: {
                    speed: 1000
                  }
                },
                toolbar: {
                  show: false
                },
                zoom: {
                  enabled: false
                },
                type: 'area'
              },
              stroke: {
                width: 3,
                curve: 'smooth'
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                show: false
              },
              colors: ['#000000'],
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return Math.round(value)
                  }
                }
              },
              xaxis: {
                categories: []
              }
            }
          }
        },

        process: {
          load: false,

          form: false,
          file: false,

          klien: false,
          terapis: false
        },

        message: {
          success: '',
          error: ''
        },

        // SEO
        content: {
          url: '/kunjungan/new',
          title: 'Kunjungan',
          description: 'Kunjungan Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty'),

      Antropometri: () => import(/* webpackPrefetch: true */ '@/components/kunjungan/new/antropometri'),
      KPSP: () => import(/* webpackPrefetch: true */ '@/components/kunjungan/new/kpsp'),
      Laporan: () => import(/* webpackPrefetch: true */ '@/components/kunjungan/new/laporan')
    },
    watch: {
      'limit_data': function() {
        this.process.limit = true

        this.fetch('limit')
      },

      'search_klien': function() {
        this.fetchKlien()
      },

      'selected.detail_visit_id': function() {
        this.selected.detail_visit = this.detail.visit.find(x => x.id === this.selected.detail_visit_id)

        if (this.sheet.lampiran) this.reset_lampiran()
      },

      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form_lampiran.dokumentasi_json_array.push(this.upload.folder.fileuri)

              this.c
            }
          }
        },
        deep: true
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()

      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)

          t.dialog.go_back = true
        })
      },

      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search_data,
          limit: this.limit_data,

          client_id: this.filter.client_id,
          store_id: this.filter.store_id,

          date: this.filter.date
        }

        await this.$axios.$get(`${process.env.API}admin/schedule_v3/data_visit_client`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list_data = response.results.data
            this.pagination_data = response.results.pagination
          }
        })
      },

      async fetchDetail (id, type = 'before_update') {
        this.process.load = true

        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/schedule_v3/data_visit_client_detail`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.detail = response.results.data

            if (!this.sheet.ubah && !this.sheet.lampiran && !this.sheet.grafik && type === 'before_update') {
              this.selected.detail_visit_id = this.detail.visit[0].id
            } else {
              this.selected.detail_visit = this.detail.visit.find(x => x.id === this.selected.detail_visit_id)
            }

            console.log(this.selected.detail_visit_id)

            let grafik_bb = {
                value: [],
                sd1neg: [],
                sd0: [],
                sd1: [],
                month: []
              }

              this.detail.graph_json_object.bb.data.map(obj => {
                grafik_bb.value.push(obj.value || 0)
                grafik_bb.sd1neg.push(obj.sd1neg)
                grafik_bb.sd0.push(obj.sd0)
                grafik_bb.sd1.push(obj.sd1)

                grafik_bb.month.push(obj.month)
              })

              let grafik_tb = {
                value: [],
                sd1neg: [],
                sd0: [],
                sd1: [],
                month: []
              }

              this.detail.graph_json_object.tb.data.map(obj => {
                grafik_tb.value.push(obj.value || 0)
                grafik_tb.sd1neg.push(obj.sd1neg)
                grafik_tb.sd0.push(obj.sd0)
                grafik_tb.sd1.push(obj.sd1)

                grafik_tb.month.push(obj.month)
              })

              let grafik_lila = {
                value: [],
                sd1neg: [],
                sd0: [],
                sd1: [],
                month: []
              }

              this.detail.graph_json_object.lila.data.map(obj => {
                grafik_lila.value.push(obj.value || 0)
                grafik_lila.sd1neg.push(obj.sd1neg)
                grafik_lila.sd0.push(obj.sd0)
                grafik_lila.sd1.push(obj.sd1)

                grafik_lila.month.push(obj.month)
              })

              let grafik_lk = {
                value: [],
                sd1neg: [],
                sd0: [],
                sd1: [],
                month: []
              }

              this.detail.graph_json_object.lk.data.map(obj => {
                grafik_lk.value.push(obj.value || 0)
                grafik_lk.sd1neg.push(obj.sd1neg)
                grafik_lk.sd0.push(obj.sd0)
                grafik_lk.sd1.push(obj.sd1)

                grafik_lk.month.push(obj.month)
              })

            this.bar = {
              bb: {
                series:[
                  {
                    name: 'Berat Badan',
                    data: grafik_bb.value
                  },
                  {
                    name: 'Batas Bawah',
                    data: grafik_bb.sd1neg
                  },
                  {
                    name: 'Batas Normal',
                    data: grafik_bb.sd0
                  },
                  {
                    name: 'Batas Atas',
                    data: grafik_bb.sd1
                  }
                ],
                chartOptions: {
                  chart: {
                    animations: {
                      enabled: true,
                      easing: 'line',
                      dynamicAnimation: {
                        speed: 1000
                      }
                    },
                    toolbar: {
                      show: false
                    },
                    zoom: {
                      enabled: false
                    }
                  },
                  stroke: {
                    width: [3, 1, 1, 1],
                    curve: 'smooth',
                    dashArray: [0, 4, 4, 4]
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    show: true
                  },
                  colors: ['#000000', '#fb8c00', '#4caf50', '#fb8c00'],
                  yaxis: {
                    labels: {
                      formatter: function (value) {
                        return Math.round(value)
                      }
                    }
                  },
                  xaxis: {
                    categories: grafik_bb.month,
                    axisTicks: {
                      autoskip: true,
                      maxTicksLimit: 4,
                      interval: 3
                    }
                  }
                }
              },
              tb: {
                series:[
                  {
                    name: 'Tinggi Badan',
                    data: grafik_tb.value
                  },
                  {
                    name: 'Batas Bawah',
                    data: grafik_tb.sd1neg
                  },
                  {
                    name: 'Batas Normal',
                    data: grafik_tb.sd0
                  },
                  {
                    name: 'Batas Atas',
                    data: grafik_tb.sd1
                  }
                ],
                chartOptions: {
                  chart: {
                    animations: {
                      enabled: true,
                      easing: 'line',
                      dynamicAnimation: {
                        speed: 1000
                      }
                    },
                    toolbar: {
                      show: false
                    },
                    zoom: {
                      enabled: false
                    }
                  },
                  stroke: {
                    width: [3, 1, 1, 1],
                    curve: 'smooth',
                    dashArray: [0, 4, 4, 4]
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    show: true
                  },
                  colors: ['#000000', '#fb8c00', '#4caf50', '#fb8c00'],
                  yaxis: {
                    labels: {
                      formatter: function (value) {
                        return Math.round(value)
                      }
                    }
                  },
                  xaxis: {
                    categories: grafik_tb.month,
                    axisTicks: {
                      autoskip: true,
                      maxTicksLimit: 4,
                      interval: 3
                    }
                  }
                }
              },
              lila: {
                series:[
                  {
                    name: 'Lingkar Lengan Atas',
                    data: grafik_lila.value
                  },
                  {
                    name: 'Batas Bawah',
                    data: grafik_lila.sd1neg
                  },
                  {
                    name: 'Batas Normal',
                    data: grafik_lila.sd0
                  },
                  {
                    name: 'Batas Atas',
                    data: grafik_lila.sd1
                  }
                ],
                chartOptions: {
                  chart: {
                    animations: {
                      enabled: true,
                      easing: 'line',
                      dynamicAnimation: {
                        speed: 1000
                      }
                    },
                    toolbar: {
                      show: false
                    },
                    zoom: {
                      enabled: false
                    }
                  },
                  stroke: {
                    width: [3, 1, 1, 1],
                    curve: 'smooth',
                    dashArray: [0, 4, 4, 4]
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    show: true
                  },
                  colors: ['#000000', '#fb8c00', '#4caf50', '#fb8c00'],
                  yaxis: {
                    labels: {
                      formatter: function (value) {
                        return Math.round(value)
                      }
                    }
                  },
                  xaxis: {
                    categories: grafik_lila.month,
                    axisTicks: {
                      autoskip: true,
                      maxTicksLimit: 4,
                      interval: 3
                    }
                  }
                }
              },
              lk: {
                series:[
                  {
                    name: 'Lingkar Kepala',
                    data: grafik_lk.value
                  },
                  {
                    name: 'Batas Bawah',
                    data: grafik_lk.sd1neg
                  },
                  {
                    name: 'Batas Normal',
                    data: grafik_lk.sd0
                  },
                  {
                    name: 'Batas Atas',
                    data: grafik_lk.sd1
                  }
                ],
                chartOptions: {
                  chart: {
                    animations: {
                      enabled: true,
                      easing: 'line',
                      dynamicAnimation: {
                        speed: 1000
                      }
                    },
                    toolbar: {
                      show: false
                    },
                    zoom: {
                      enabled: false
                    }
                  },
                  stroke: {
                    width: [3, 1, 1, 1],
                    curve: 'smooth',
                    dashArray: [0, 4, 4, 4]
                  },
                  dataLabels: {
                    enabled: false
                  },
                  legend: {
                    show: true
                  },
                  colors: ['#000000', '#fb8c00', '#4caf50', '#fb8c00'],
                  yaxis: {
                    labels: {
                      formatter: function (value) {
                        return Math.round(value)
                      }
                    }
                  },
                  xaxis: {
                    categories: grafik_lk.month,
                    axisTicks: {
                      autoskip: true,
                      maxTicksLimit: 4,
                      interval: 3
                    }
                  }
                }
              }
            }
          }
        })
      },

      async fetchKlien () {
        this.process.klien = true

        let params = {
          search: this.search_klien,
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/customer`, { params })
        .then((response) => {
          this.process.klien = false

          if (response.status === 200) {
            this.list_klien = response.results.data
            this.pagination_klien = response.results.pagination
          }
        })
      },

      async fetchStore () {
        let params = {
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/store`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.list_store = response.results.data
          }
        })
      },

      reset_lampiran () {
        if (this.$refs.form) this.$refs.form.reset()

        this.form_lampiran = {
          visit_id: this.selected.detail_visit.id,
          dokumentasi_json_array: this.selected.detail_visit.dokumentasi_json_array
        }

        this.process.form = false

        this.message.error = ''
      },

      async save_lampiran (type = false) {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/schedule_v3/lampiran/update`, this.form_lampiran)
        .then((response) => {

          this.process.form = false

          if (response.status === 200) {
            this.sheet.form_lampiran = type

            this.fetchDetail(this.detail.id)
          } else {
            this.message.error = response.message
          }
        })
      },

      reset_grafik () {
        if (this.$refs.form) this.$refs.form.reset()

        this.form_grafik = {
          antropometri_id: this.detail.id,
          graph_json_object: Object.keys(this.detail.graph_json_object).length > 0 ? this.detail.graph_json_object : {
            bb: {
              title: '',
              data: [],
              image_url: '',
              kesimpulan: ''
            },
            tb: {
              title: '',
              data: [],
              image_url: '',
              kesimpulan: ''
            },
            lila: {
              title: '',
              data: [],
              image_url: '',
              kesimpulan: ''
            },
            lk: {
              title: '',
              data: [],
              image_url: '',
              kesimpulan: ''
            }
          }
        }

        this.process.form = false

        this.message.error = ''
      },

      to_save_grafik_all () {
        this.message.error = ''

        this.process.form = true

        this.uplaod_bb()
      },

      async uplaod_bb () {
        let self = this,
            data = document.getElementById('bb')

        await this.$html2canvas(data, {
          logging: true,
          letterRendering: true,
          allowTaint: false,
          useCORS: true,

          scale: 1920*2/window.innerWidth,

          scrollX: 0,
          scrollY: 0,
        })
        .then(function(canvas) {
          document.body.appendChild(canvas)

          let img = canvas.toDataURL('image/png', 1.0)

          fetch(img)
          .then(res => res.blob())
          .then(blob => {
            let formData = new FormData(),
                file = new File([blob], 'bidanvitacare.jpeg')
                formData.append('key', 'image')
                formData.append('image', file)


            fetch(`${process.env.API_CDN}image.php`, {
              method: 'POST',
              body: formData
            })
            .then(res => res.json())
            .then(response => {
              self.form_grafik.graph_json_object.bb.image_url = response.data.fileuri

              self.uplaod_tb()
            })
          })
        })
      },

      async uplaod_tb () {
        let self = this,
            data = document.getElementById('tb')

        await this.$html2canvas(data, {
          logging: true,
          letterRendering: true,
          allowTaint: false,
          useCORS: true,

          scale: 1920*2/window.innerWidth,

          scrollX: 0,
          scrollY: 0,
        })
        .then(function(canvas) {
          document.body.appendChild(canvas)

          let img = canvas.toDataURL('image/png', 1.0)

          fetch(img)
          .then(res => res.blob())
          .then(blob => {
            let formData = new FormData(),
                file = new File([blob], 'bidanvitacare.jpeg')
                formData.append('key', 'image')
                formData.append('image', file)


            fetch(`${process.env.API_CDN}image.php`, {
              method: 'POST',
              body: formData
            })
            .then(res => res.json())
            .then(response => {
              self.form_grafik.graph_json_object.tb.image_url = response.data.fileuri

              self.uplaod_lila()
            })
          })
        })
      },

      async uplaod_lila () {
        let self = this,
            data = document.getElementById('lila')

        await this.$html2canvas(data, {
          logging: true,
          letterRendering: true,
          allowTaint: false,
          useCORS: true,

          scale: 1920*2/window.innerWidth,

          scrollX: 0,
          scrollY: 0,
        })
        .then(function(canvas) {
          document.body.appendChild(canvas)

          let img = canvas.toDataURL('image/png', 1.0)

          fetch(img)
          .then(res => res.blob())
          .then(blob => {
            let formData = new FormData(),
                file = new File([blob], 'bidanvitacare.jpeg')
                formData.append('key', 'image')
                formData.append('image', file)


            fetch(`${process.env.API_CDN}image.php`, {
              method: 'POST',
              body: formData
            })
            .then(res => res.json())
            .then(response => {
              self.form_grafik.graph_json_object.lila.image_url = response.data.fileuri

              self.uplaod_lk()
            })
          })
        })
      },

      async uplaod_lk () {
        let self = this,
            data = document.getElementById('lk')

        await this.$html2canvas(data, {
          logging: true,
          letterRendering: true,
          allowTaint: false,
          useCORS: true,

          scale: 1920*2/window.innerWidth,

          scrollX: 0,
          scrollY: 0,
        })
        .then(function(canvas) {
          document.body.appendChild(canvas)

          let img = canvas.toDataURL('image/png', 1.0)

          fetch(img)
          .then(res => res.blob())
          .then(blob => {
            let formData = new FormData(),
                file = new File([blob], 'bidanvitacare.jpeg')
                formData.append('key', 'image')
                formData.append('image', file)


            fetch(`${process.env.API_CDN}image.php`, {
              method: 'POST',
              body: formData
            })
            .then(res => res.json())
            .then(response => {
              self.form_grafik.graph_json_object.lk.image_url = response.data.fileuri

              self.save_grafik()
            })
          })
        })
      },

      async save_grafik () {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/schedule_v3/grafik/update`, this.form_grafik)
        .then((response) => {

          this.process.form = false

          if (response.status === 200) {
            this.sheet.grafik = false

            this.fetchDetail(this.detail.id)
          } else {
            this.message.error = response.message
          }
        })
      },

      async save_reset_grafik () {
        this.message.error = ''

        this.process.form = true

        this.form_grafik.graph_json_object = {}

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/schedule_v3/grafik/update`, this.form_grafik)
        .then((response) => {

          this.process.form = false

          if (response.status === 200) {
            this.sheet.grafik = false

            this.fetchDetail(this.detail.id)
          } else {
            this.message.error = response.message
          }
        })
      }
    }
  }
</script>
