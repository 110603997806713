<template>
  <v-card
    flat
    class="overflow-hidden"
    style="border-radius: 0px;">
    <v-app-bar
      v-if="!loader &&
            $route.path !== '/' &&
            $route.path !== '/katalog' &&
            $route.path !== '/auth/login' &&
            $route.path !== '/auth/forgot' &&
            $route.path !== '/auth/check' &&
            $route.path !== '/auth/success' &&
            $route.name !== 'auth-reset-slug' &&
            $route.name !== 'invoice-slug' &&
            $route.name !== 'invoice-print-slug' &&
            $route.name !== 'feedback-slug'"
      :class="$route.name === 'jadwal-desktop' ||
              $route.name === 'jadwal-new-desktop' ||
              $route.name === 'jadwal-new-desktop-tambah' ||
              $route.name === 'jadwal-new-desktop-ubah-id' ? 'jadwal-desktop' : ''"
      app
      absolute
      color="#f9dee2"
      elevation="0"
      hide-on-scroll
      :prominent="false"
      scroll-target="#scrolling-techniques-4"
      style="z-index: 9; border-radius: 0px 0px 15px 15px;">
      <div v-if="$route.name === 'home'"
        class="d-flex align-center">
        <v-avatar
          size="36"
          style="border: 2px solid #FFF;">
          <v-img
            width="100%"
            height="100%"
            alt="bidanvitacare"
            :src="user.image_url !== '' ? user.image_url : require('@/assets/image/member_default.jpg')"
            class="ma-auto">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular
                  size="20"
                  indeterminate
                  :color="set_color">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>

        <div
          class="ml-2">
          <div
            style="font-size: 11px; opacity: 70%;">
            Selamat {{ isDay }},
          </div>

          <div
            class="body-2 font-weight-bold one-line text-capitalize"
            style="opacity: 70%;">
            {{ user.name }}
          </div>
        </div>
      </div>

      <div v-if="$route.name !== 'home'">
        <v-icon
          @click="
            $route.name === 'kasir' ? $router.push('/home') :
            $route.name === 'payment' ? $router.push('/kasir') :
            $route.name === 'pembayaran' ? $router.push('/payment') :
            $route.name === 'multi' ? $router.push('/payment') :
            $route.name === 'jadwal' ? $router.push('/home') :

            $route.name === 'jadwal-new' ? $router.push('/home') :
            $route.name === 'jadwal-new-tambah' ? $router.push('/jadwal/new') :
            $route.name === 'jadwal-new-ubah-id' ? $router.push('/jadwal/new') :

            $route.name === 'jadwal-new-desktop' ? $router.push('/home') :
            $route.name === 'jadwal-new-desktop-tambah' ? $router.push('/jadwal/new/desktop') :
            $route.name === 'jadwal-new-desktop-ubah-id' ? $router.push('/jadwal/new/desktop') :
            $router.go(-1)"
          :color="set_color"
          class="cursor-pointer">
          mdi-arrow-left-thick
        </v-icon>

        <span
          @click="
            $route.name === 'kasir' ? $router.push('/home') :
            $route.name === 'payment' ? $router.push('/kasir') :
            $route.name === 'pembayaran' ? $router.push('/payment') :
            $route.name === 'multi' ? $router.push('/payment') :
            $route.name === 'jadwal' ? $router.push('/home') :

            $route.name === 'jadwal-new' ? $router.push('/home') :
            $route.name === 'jadwal-new-tambah' ? $router.push('/jadwal/new') :
            $route.name === 'jadwal-new-ubah-id' ? $router.push('/jadwal/new') :

            $route.name === 'jadwal-new-desktop' ? $router.push('/home') :
            $route.name === 'jadwal-new-desktop-tambah' ? $router.push('/jadwal/new/desktop') :
            $route.name === 'jadwal-new-desktop-ubah-id' ? $router.push('/jadwal/new/desktop') :
            $router.go(-1)"
          class="body-1 font-weight-bold text-capitalize cursor-pointer"
          :style="`color: ${set_color};`">
          {{
            $route.name === 'jadwal-new-desktop' ? 'Jadwal Desktop' :
            $route.name === 'jadwal-new-desktop-tambah' ? 'Tambah Jadwal Desktop' :
            $route.name === 'jadwal-new-desktop-ubah-id' ? 'Ubah Jadwal Desktop' :

            $route.name === 'jadwal-new' ? 'Jadwal' :
            $route.name === 'jadwal-new-tambah' ? 'Tambah Jadwal' :
            $route.name === 'jadwal-new-ubah-id' ? 'Ubah Jadwal' :

            $route.name === 'jadwal-tambah' ? 'Tambah Jadwal' :
            $route.name === 'jadwal-desktop' ? 'Jadwal Desktop' :
            $route.name === 'jadwal-ubah-id' ? 'Ubah Jadwal' :

            $route.name === 'multi' ? 'Multi Payment' :
            $route.name === 'transaksi-payment-id' ? 'Ubah Metode Pembayaran' :
            $route.name === 'transaksi-payment-pembayaran-id' ? 'Ubah Metode Pembayaran' :
            $route.name === 'transaksi-payment-multi-id' ? 'Ubah Metode Pembayaran' :

            $route.name === 'penjadwalan-new' ? 'Penjadwalan' :
            $route.name === 'kunjungan-new' ? 'Kunjungan' :
            $route.name
          }}
        </span>
      </div>

      <v-spacer />

      <!-- <div v-if="$route.name === 'home'">
        <v-img
          @click="sheet = true"
          contain
          width="22"
          height="22"
          alt="bidanvitacare"
          :src="require('@/assets/icon/bell.png')"
          class="ma-auto cursor-pointer">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular
                size="20"
                indeterminate
                :color="set_color">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div> -->

      <!-- <div v-if="$route.name === 'home' && Object.keys(company).length > 0 && company.color.length > 0">
        <v-menu
          transition="slide-x-transition"
          bottom
          right
          max-width="124">
          <template v-slot:activator="{ on, attrs }">
            <v-img
              v-bind="attrs"
              v-on="on"
              contain
              width="22"
              height="22"
              alt="bidanvitacare"
              :src="require('@/assets/icon/palette_1.png')"
              class="ma-auto cursor-pointer ml-3">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    size="20"
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </template>

          <v-card
            flat
            class="pa-0"
            style="border-radius: 10px;">
            <v-btn
              v-for="(item, index) in company.color"
              :key="index"
              @click="item.active = true; save(index);"
              min-width="30"
              width="30"
              height="30"
              :color="item.hex"
              elevation="0"
              class="pa-0"
              style="border-radius: 0px;">
              <v-icon v-if="item.active"
                small
                color="#ffffff">
                mdi-check-circle-outline
              </v-icon>
            </v-btn>
          </v-card>
        </v-menu>
      </div> -->
    </v-app-bar>

    <v-sheet v-if="$route.name !== 'invoice-print-slug'"
      id="scrolling-techniques-4"
      class="overflow-y-auto"
      max-height="100vh"
      @scroll="onScroll">
      <div
        style="min-height: 100vh;">
        <Loader v-if="loader" />

        <div
          class="background">
          <nuxt
            class="blur" />
        </div>
      </div>
    </v-sheet>

    <v-sheet v-if="$route.name === 'invoice-print-slug'"
      id="scrolling-techniques-4">
      <div>
        <Loader v-if="loader" />

        <div
          class="background">
          <nuxt
            class="blur" />
        </div>
      </div>
    </v-sheet>

    <v-bottom-sheet
      v-model="sheet"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        style="border-radius: 0px;">
        <v-card-title
          @click="sheet = false"
          class="cursor-pointer pa-4"
          style="border-radius: 0px 0px 15px 15px;
                position: absolute;
                width: 100%;
                z-index: 1;
                background: #f9dee2;">
          <v-icon
            :color="set_color">
            mdi-arrow-left-thick
          </v-icon>

          <span
            class="body-1 font-weight-bold text-capitalize ml-1"
            :style="`color: ${set_color};`">
            Pemberitahuan
          </span>
        </v-card-title>

       <v-card-text
        style="margin-top: 250px;">
        <v-img
          width="200"
          height="auto"
          alt="bidanvitacare"
          :src="require('@/assets/image/coming_soon.png')"
          class="ma-auto">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular
                indeterminate
                :color="set_color">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>

        <div
          class="body-1 font-weight-light line-text-second text-center mt-8 mb-6">
          Tunggu fitur-fitur menarik dari Bidanvitacare.id, untuk kemudahan kebutuhan Anda.
        </div>

        <div
          class="body-1 font-weight-bold black--text line-text-second text-center">
          - Bidanvitacare.id -
        </div>
      </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </v-card>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import Loader from "~/components/Loader.vue"
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        loader: true,
        sheet: false,
        scroll: true
      }
    },
    components: {
      Loader
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      },
      user() {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      company () {
        return this.$store.state.header.config.company
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      const debounce = (fn) => {
        let frame;
        return (...params) => {
          if (frame) {
            cancelAnimationFrame(frame)
          }
          frame = requestAnimationFrame(() => {
            fn(...params)
          })
        }
      }

      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY
      }

      document.addEventListener('scroll', debounce(storeScroll), { passive: true })

      storeScroll()

      this.loader = false
    },
    beforeCreate() {
      this.loader = true
    },
    created() {

    },
    methods: {
      onScroll () {
        const el = document.getElementById('scrolling-techniques-4')

        this.scroll = el.scrollTop < window.innerHeight ? true : false

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      },
      async save (idx) {
        let forms = this.$store.state.header.config.company

        forms.color.map((obj, index) => {
          if (index === idx) {
            obj.active = true
          } else {
            obj.active = false
          }
        })

        forms = Object.assign({}, forms)
        forms.color = forms.color

        this.$store.commit('header/config/setCompany', forms)
        Cookie.set('company', forms)

        this.company.color.map((obj) => {
          if (obj.active) {
            this.$store.commit('header/config/setColor', obj.hex)
            Cookie.set('color', obj.hex)
          }
        })

        this.styles()
      },
      styles () {
        let style = document.createElement('style')

        // RESET STYLESHEET TO AUTO
        style.innerHTML = `
          ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: ${this.set_color};
          }

          html:not([data-scroll="0"]) .toolbar {
            background-color: ${this.set_color} !important;
          }
        `

        // ADD TO APPENDCHILD
        document.head.appendChild(style)
      }
    }
  }
</script>
