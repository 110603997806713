<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.deletes"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-text>
              <v-img 
                width="150"
                height="150"
                alt="bidanvitacare" 
                :src="require('@/assets/icon/hapus_info.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin menghapus data
                </div>
                
                <div>
                  <strong>{{ detail.content }}</strong>?
                </div>
              </div>
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="deletes()"
                :loading="process.form"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon 
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Saya yakin
                  </span>
                </div>
              </v-btn>
              
              <v-btn
                @click="dialog.deletes = false"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon 
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.go_back"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-text>
              <v-img 
                width="150"
                height="150"
                alt="bidanvitacare" 
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin akan keluar
                </div>

                <div>
                  dari halaman ini?
                </div>
              </div>
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                to="/home"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon 
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Keluar
                  </span>
                </div>
              </v-btn>
              
              <v-btn
                @click="dialog.go_back = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon 
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.filter"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar 
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span 
                class="body-1 font-weight-bold text-capitalize ml-2">
                Filter
              </span>
            </v-card-title>
            
            <v-divider 
              class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="body-2 text-first line-text-second mt-3">
              <div>
                <div class="body-2 mb-2">
                  Pilih Cabang
                </div>

                <v-select
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Pilih Cabang"

                  hide-details
                  
                  :items="store"
                  item-value="id"
                  item-text="name"
                  :item-color="set_color"

                  clearable
                  @click:clear="filter.store_id = ''"

                  :no-filter="false"

                  v-model="filter.store_id">
                </v-select>
              </div>
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.filter = false; fetch();"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon 
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>
              
              <v-btn
                @click="dialog.filter = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon 
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text
              class="mt-1">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                filled
                rounded
                dense 
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-btn
                @click="sheet.form = true; reset();"
                block
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
                :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                <div
                  class="btn_auth_in d-flex align-center justify-center"
                  :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                  <div>
                    <v-img 
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare" 
                      :src="require('@/assets/icon/add_plus.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            size="20"
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <div
                    class="ml-2">
                    Tambah Klien Rutin
                  </div>
                </div>
              </v-btn>
            </v-card-text>
            
            <div
              class="mt-2">
              <swiper 
                class="swiper"
                ref="swiper"
                :options="{ slidesPerView: 'auto' }">
                <swiper-slide 
                  v-for="(item, index) in days" 
                  :key="index" 
                  :class="index === 0 ? 'pl-4 pr-4' : index < days.length - 1 ? 'pr-4' : 'pr-4'"
                  style="max-width: 110px;">
                  <div>
                    <v-btn
                      @click="selected.days = item; fetch();"
                      width="78"
                      rounded
                      elevation="0"
                      color="#ececec"
                      class="body-2 font-weight-bold text-capitalize mb-2"
                      :class="selected.days === item ? `color: ${set_color};` : ''"
                      :style="selected.days === item ? `border: 2px dashed ${set_color}; color: ${set_color};` : ''">
                      {{ item }}
                    </v-btn>
                  </div>
                </swiper-slide>
              </swiper>
            </div>

            <div
              class="mt-2">
              <swiper 
                class="swiper"
                ref="swiper"
                :options="{ slidesPerView: 'auto' }">
                <swiper-slide 
                  v-for="(item, index) in kali" 
                  :key="index" 
                  :class="index === 0 ? 'pl-4 pr-4' : index < kali.length - 1 ? 'pr-4' : 'pr-4'"
                  style="max-width: 140px;">
                  <div>
                    <v-btn
                      @click="selected.kali = item; fetch();"
                      width="108"
                      rounded
                      elevation="0"
                      color="#ececec"
                      class="body-2 font-weight-bold text-capitalize mb-2"
                      :class="selected.kali === item ? `color: ${set_color};` : ''"
                      :style="selected.kali === item ? `border: 2px dashed ${set_color}; color: ${set_color};` : ''">
                      {{ item }}
                    </v-btn>
                  </div>
                </swiper-slide>
              </swiper>
            </div>

            <v-card-text
              class="pb-0">
              <div
                class="body-2 font-weight-bold black--text d-flex align-center mb-2">
                <div>
                  Klien Rutin
                </div>

                <v-spacer />

                <div>
                  <v-icon 
                    @click="dialog.filter = true; fetchStore();"
                    class="cursor-pointer">
                    mdi-filter-variant
                  </v-icon>
                </div>
              </div>

              <v-row>
                <v-col>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list.length > 0"
                          flat
                          dense
                          three-line
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-list-item
                            @click="sheet.detail = true; fetchDetail(item.id);">
                            <v-list-item-avatar
                              size="40"
                              class="mt-3"
                              style="border: 2px solid #ffffff;">
                              <v-img 
                                width="100%"
                                height="100%"
                                alt="bidanvitacare" 
                                :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      size="20"
                                      indeterminate 
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>
                            
                            <v-list-item-content>
                              <div>
                                <v-chip
                                  x-small
                                  class="caption white--text text-capitalize"
                                  :color="set_color"
                                  style="margin-bottom: 5px;">
                                  {{ item.customer_name }}
                                </v-chip>
                              </div>

                              <div
                                class="body-2 font-weight-bold text-capitalize">
                                Hari {{ item.day }}
                              </div>

                              <div
                                class="caption"
                                style="opacity: 60%;">
                                {{ item.create_datetime | date }}
                              </div>

                              <div
                                class="body-2 line-text-second"
                                style="opacity: 80%;">
                                {{ item.content }}
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <Empty v-if="!process.load && list.length < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Klien Rutin" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.detail"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            flat
            class="background">
            <div
              class="blur">
              <v-card-title 
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon 
                  @click="sheet.detail = false; detail = {}; fetch();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span 
                  @click="sheet.detail = false; detail = {}; fetch();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Klien {{ detail.content }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 80px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text 
                  class="pt-16 mt-4">
                  <div
                    class="d-flex align-center mb-4">
                    <v-avatar
                      size="40"
                      style="border: 2px solid #ffffff;">
                      <v-img 
                        width="100%"
                        height="100%"
                        alt="bidanvitacare" 
                        :src="detail.customer_image_url !== '' ? detail.customer_image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              size="20"
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 font-weight-bold black--text ml-2">
                      {{ detail.customer_name }}
                    </span>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Cabang
                    </div>

                    <div
                      class="body-2 text-capitalize black--text">
                      {{ detail.store_name }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Klien
                    </div>

                    <div
                      class="body-2 text-capitalize black--text">
                      {{ detail.client_name !== '' ? detail.client_name : '-' }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Terapis
                    </div>

                    <div
                      class="body-2 text-capitalize black--text">
                      {{ detail.therapist_name !== '' ? detail.therapist_name : '-' }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Hari
                    </div>

                    <div
                      class="body-2 text-capitalize black--text">
                      {{ detail.day }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Berapa Kali?
                    </div>

                    <div
                      class="body-2 text-capitalize black--text">
                      {{ detail.weekly !== '' ? detail.weekly : '-' }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Keterangan
                    </div>

                    <div
                      class="body-2 line-text-second black--text">
                      {{ detail.content }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Dibuat Tanggal
                    </div>

                    <div
                      class="body-2 line-text-second black--text">
                      {{ detail.create_datetime | date }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="50"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col 
                      cols="6" 
                      class="py-0">
                      <v-btn
                        @click="sheet.form = true; reset(detail);"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img 
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare" 
                              :src="require('@/assets/icon/edit_pencil.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    size="20"
                                    indeterminate 
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="blue--text ml-2">
                            Ubah
                          </div>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col 
                      cols="6" 
                      class="py-0">
                      <v-btn
                        @click="dialog.deletes = true"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img 
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare" 
                              :src="require('@/assets/icon/hapus.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    size="20"
                                    indeterminate 
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="red--text ml-2">
                            Hapus
                          </div>
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.form"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title 
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon 
                  @click="sheet.form = false; reset();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span 
                  @click="sheet.form = false; reset();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  {{ form.id === '' ? 'Tambah' : 'Ubah' }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text 
                  class="pt-16 mt-4">
                  <ValidationObserver 
                    ref="form">
                    <v-form>
                      <ValidationProvider 
                        name="Cabang" 
                        rules="required" 
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Cabang
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Pilih Cabang"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                          
                          :items="store"
                          item-value="id"
                          item-text="name"
                          :item-color="set_color"

                          clearable
                          @click:clear="form.store_id = ''"

                          :no-filter="false"

                          v-model="form.store_id"
                          success="valid"
                          :error-messages="errors"
                          required>
                        </v-select>
                      </ValidationProvider>

                      <ValidationProvider 
                        name="Customer" 
                        rules="required" 
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Customer
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-autocomplete
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. John Doe"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          :search-input.sync="autocomplete.customer"
                          :loading="process.customer"
                          
                          :items="customer"
                          item-value="id"
                          item-text="name"
                          :item-color="set_color"

                          :no-filter="false"

                          v-model="form.customer_id"
                          :success="valid"
                          :error-messages="errors"
                          required>
                          <template v-slot:item="data">
                            <div
                              class="d-flex align-center">
                              <v-avatar 
                                size="24">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="data.item.image_url !== '' ? data.item.image_url : require('@/assets/image/member_default.jpg')">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        size="20"
                                        indeterminate 
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>

                              <span 
                                class="body-2 text-capitalize ml-2">
                                {{ data.item.name }}
                              </span>
                            </div>
                          </template>

                          <template v-slot:selection="data">
                            <div
                              class="d-flex align-center">
                              <v-avatar 
                                size="24">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="data.item.image_url !== '' ? data.item.image_url : require('@/assets/image/member_default.jpg')">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        size="20"
                                        indeterminate 
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>

                              <span 
                                class="body-2 text-capitalize ml-2">
                                {{ data.item.name }}
                              </span>
                            </div>
                          </template>
                        </v-autocomplete>
                      </ValidationProvider>

                      <ValidationProvider 
                        name="Klien" 
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          Klien
                        </div>

                        <v-autocomplete
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Boy"
                          :append-icon="form.client_id !== '' && valid ? 'mdi-check-circle-outline' : ''"

                          :search-input.sync="autocomplete.klien"
                          :loading="process.klien"
                          
                          :items="klien"
                          item-value="id"
                          item-text="name"
                          :item-color="set_color"

                          :no-filter="false"

                          v-model="form.client_id"
                          :success="form.client_id !== '' && valid"
                          :error-messages="errors"
                          required>
                          <template v-slot:item="data">
                            <div
                              class="d-flex align-center">
                              <v-avatar 
                                size="24">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="data.item.image_url !== '' ? data.item.image_url : require('@/assets/image/member_default.jpg')">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        size="20"
                                        indeterminate 
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>

                              <span 
                                class="body-2 text-capitalize ml-2">
                                {{ data.item.name }}
                              </span>
                            </div>
                          </template>

                          <template v-slot:selection="data">
                            <div
                              class="d-flex align-center">
                              <v-avatar 
                                size="24">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="data.item.image_url !== '' ? data.item.image_url : require('@/assets/image/member_default.jpg')">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        size="20"
                                        indeterminate 
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>

                              <span 
                                class="body-2 text-capitalize ml-2">
                                {{ data.item.name }}
                              </span>
                            </div>
                          </template>
                        </v-autocomplete>
                      </ValidationProvider>

                      <ValidationProvider 
                        name="Terapis" 
                        rules="required" 
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Terapis
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-autocomplete
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Cintya"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          :search-input.sync="autocomplete.terapis"
                          :loading="process.terapis"
                          
                          :items="terapis"
                          item-value="id"
                          item-text="name"
                          :item-color="set_color"

                          :no-filter="false"

                          v-model="form.therapist_id"
                          :success="valid"
                          :error-messages="errors"
                          required>
                          <template v-slot:item="data">
                            <span
                              class="body-2 text-capitalize">
                              {{ data.item.name }}
                            </span>
                          </template>

                          <template v-slot:selection="data">
                            <span
                              class="body-2 text-capitalize">
                              {{ data.item.name }}
                            </span>
                          </template>
                        </v-autocomplete>
                      </ValidationProvider>
                      
                      <ValidationProvider 
                        name="Hari" 
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Hari
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Senin"
                          
                          :items="days"
                          :item-color="set_color"

                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                          v-model="form.day"
                          :success="valid"
                          :error-messages="errors"
                          required>
                          <template v-slot:selection="{ item }">
                            <span
                              class="body-2 text-capitalize">
                              {{ item }}
                            </span>
                          </template>

                          <template v-slot:item="{ item }">
                            <span
                              class="body-2 text-capitalize">
                              {{ item }}
                            </span>
                          </template>
                        </v-select>
                      </ValidationProvider>

                      <ValidationProvider 
                        name="Berapa Kali?" 
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Berapa Kali?
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 1 Minggu Sekali"
                          
                          :items="kali"
                          :item-color="set_color"

                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                          v-model="form.weekly"
                          :success="valid"
                          :error-messages="errors"
                          required>
                          <template v-slot:selection="{ item }">
                            <span
                              class="body-2 text-capitalize">
                              {{ item }}
                            </span>
                          </template>

                          <template v-slot:item="{ item }">
                            <span
                              class="body-2 text-capitalize">
                              {{ item }}
                            </span>
                          </template>
                        </v-select>
                      </ValidationProvider>

                      <ValidationProvider 
                        name="Keterangan" 
                        rules="required" 
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Keterangan
                          <!-- <span>
                            (boleh dikosongi)
                          </span> -->
                        </div>

                        <v-textarea
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          rows="5"
                          placeholder="Contoh. Tuliskan keterangan disini . . ."
                          :append-icon="form.content !== '' && valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.content"
                          :success="form.content !== '' && valid"
                          :error-messages="errors"
                          required>
                        </v-textarea>
                      </ValidationProvider>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center my-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
                      
                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row 
                    align="center">
                    <v-col 
                      cols="3" 
                      class="pb-0">
                      <v-img 
                        contain
                        alt="bidanvitacare" 
                        :src="require('@/assets/image/footer/client_rutin.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col 
                      cols="9" 
                      class="black--text pb-0">
                      <div>
                        <div 
                          class="body-2 line-text-second black--text" 
                          style="opacity: 60%;">
                          Masukkan data untuk klien rutin di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  
                  <v-row 
                    class="mt-4">
                    <v-col 
                      cols="6" 
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        v-on:keyup.enter="save"
                        @click="save"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon 
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col 
                      cols="6" 
                      class="pb-0">
                      <v-btn
                        @click="sheet.form = false; reset();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon 
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          deletes: false,

          go_back: false,

          filter: false
        },
        sheet: {
          form: false,
          detail: false
        },
        selected: {
          days: 'senin',
          kali: '1 minggu sekali'
        },

        autocomplete: {
          customer: '',
          klien: '',
          terapis: ''
        },

        filter: {
          store_id: ''
        },

        search: '',
        days: ['senin', 'selasa', 'rabu', 'kamis', 'jumat', 'sabtu', 'minggu'],
        kali: ['1 minggu sekali', '2 minggu sekali', '3 minggu sekali', '1 bulan sekali'],

        detail: {},

        store: [],
        customer: [],
        klien: [],
        terapis: [],

        list: [],
        pagination: {},
        form: {
          id: '',
          store_id: '',
          customer_id: '',
          client_id: '',
          therapist_id: '',
          content: '',
          day: '',
          weekly: ''
        },
        process: {
          load: false,
          form: false,

          customer: false,
          klien: false,
          terapis: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/rutin',
          title: 'Klien Rutin',
          description: 'Klien Rutin Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'autocomplete.customer': function() {
        this.fetchPelanggan()
      },

      'autocomplete.klien': function() {
        this.fetchPelanggan_klien()
      },

      'autocomplete.terapis': function() {
        this.fetchTerapis()
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()

      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)
          
          t.dialog.go_back = true
        })
      },

      async fetch () {
        this.process.load = true

        let params = {
          search: this.search,

          day: this.selected.days,
          weekly: this.selected.kali,

          store_id: this.filter.store_id
        }

        await this.$axios.$get(`${process.env.API}admin/customer-routine`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list = response.results.data
          }
        })
      },
      async fetchDetail (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/customer-routine/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail = response.results.data
          }
        })
      },

      async fetchStore () {
        let params = {
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/store`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.store = response.results.data

            this.form.store_id = this.user.store_id
          }
        })
      },

      async fetchPelanggan () {
        this.process.customer = true

        let params = {
          limit: 10000,
          search: this.autocomplete.customer
        }

        await this.$axios.$get(`${process.env.API}admin/customer`, { params })
        .then((response) => {
          this.process.customer = false

          if (response.status === 200) {
            this.customer = response.results.data
          }
        })
      },

      async fetchPelanggan_klien () {
        this.process.klien = true

        let params = {
          limit: 10000,
          search: this.autocomplete.klien
        }

        await this.$axios.$get(`${process.env.API}admin/customer`, { params })
        .then((response) => {
          this.process.klien = false

          if (response.status === 200) {
            this.klien = response.results.data
          }
        })
      },

      async fetchTerapis() {
        this.process.terapis = true

        let params = {
          // limit: 10000
          search: this.autocomplete.terapis,
          'role[eq]': 'therapist'
        }

        await this.$axios.$get(`${process.env.API}admin/user`, { params })
        .then((response) => {
          this.process.terapis = false

          if (response.status === 200) {
            this.terapis = response.results.data
          }
        })
      },

      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          id: item !== undefined ? item.id : '',
          store_id: item !== undefined ? item.store_id : '',
          customer_id: item !== undefined ? item.customer_id : '',
          client_id: item !== undefined ? item.client_id : '',
          therapist_id: item !== undefined ? item.therapist_id : '',
          content: item !== undefined ? item.content : '',
          day: item !== undefined ? item.day : '',
          weekly: item !== undefined ? item.weekly : ''
        }
        
        this.process.form = false

        this.message.error = ''

        if (this.sheet.form) this.fetchStore()
      },

      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form.id === '' ? 'admin/customer-routine/add' : 'admin/customer-routine/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form = false
              
              if (this.form.id === '') {
                this.fetch()
              } else {
                this.fetchDetail(this.detail.id)
              }
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      async deletes () {
        this.message.error = ''

        this.process.form = true

          // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/customer-routine/delete`, {
          id: this.detail.id
        })
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.dialog.deletes = false

            this.sheet.detail = false

            this.fetch()
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      }
    }
  }
</script>