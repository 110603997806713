<template>
  <v-layout column>
    <div class="text-center">
      <section>
        <v-container 
          class="d-flex align-center"
          style="min-height: 100vh;">
          <v-row 
            justify="center" 
            align="center">
            <v-col>
              <v-card
                flat
                color="transparent"
                class="pa-4">
                <v-row 
                  justify="center" 
                  align="center">
                  <v-col>
                    <div>
                      <v-img 
                        contain
                        width="200"
                        height="200"
                        alt="bidanvitacare" 
                        :src="require('@/assets/image/404.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>

                    <div>
                      <div class="my-16">
                        <div 
                          class="title font-weight-bold black--text mb-3">
                          Halaman Tidak Ditemukan
                        </div>

                        <div 
                          class="body-1 black--text font-weight-light line-text-second">
                          Sepertinya halaman yang Anda tuju tidak terdaftar dalam website kami.
                        </div>
                      </div>

                      <v-btn
                        to="/home"
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_auth"
                        :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                        <div
                          class="btn_auth_in"
                          :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                          <v-icon>mdi-arrow-left-thick</v-icon>
                          kembali ke beranda
                        </div>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </div>
  </v-layout>
</template>

<script> 
  import { createSEOMeta } from '@/utils/seo'
  export default {
    name: 'error-404',
    data () {
      return {
        // SEO
        content: {
          url: '',
          title: '404',
          description: 'Halaman tidak ditemukan',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    props: {
      error: {
        type: Object,
        default: () => {},
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    computed: {
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    }
  }
</script>