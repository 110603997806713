<template>
  <div>
    <v-dialog v-model="dialog.deletes"
      persistent>
      <v-card 
        class="border-radius pa-2">
        <v-card-text>
          <v-img 
            width="150"
            height="150"
            alt="bidanvitacare" 
            :src="require('@/assets/icon/hapus_info.png')"
            class="ma-auto mt-4 mb-3">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  size="20"
                  indeterminate 
                  :color="set_color">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <div
            class="body-2 text-center line-text-second">
            <div>
              Apakah Anda yakin menghapus data
            </div>
            
            <div>
              <strong>
                <span v-if="selected.type === 'category'">
                  {{ detail.name }}
                </span>

                <span v-if="selected.type !== 'category'"
                  v-html="detail_data.question">
                </span>
              </strong>?
            </div>
          </div>
        </v-card-text>

        <v-divider 
          class="mx-4 mb-3 mt-0"/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            @click="deletes()"
            :loading="process.form"
            :disabled="process.form"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_save">
            <div
              class="btn_save_in d-flex align-center justify-center">
              <v-icon 
                size="20">
                mdi-check-bold
              </v-icon>

              <span
                class="ml-1">
                Ya, Saya yakin
              </span>
            </div>
          </v-btn>
          
          <v-btn
            @click="dialog.deletes = false"
            :disabled="process.form"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
            <div
              class="btn_cancel_in">
              <v-icon 
                size="20">
                mdi-close-thick
              </v-icon>

              <span
                class="ml-1">
                Batal
              </span>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text 
          class="pb-0 mt-1">
          <v-text-field
            v-model="search_category"
            @click:clear="search_category = ''; fetch();"
            v-on:keyup.enter="fetch()"
            filled
            rounded
            dense 
            single-line
            hide-details
            :color="set_color"
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari data yang Anda butuhkan ?"
            clearable>
          </v-text-field>

          <v-btn
            @click="sheet.form = true; reset();"
            block
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
            :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
            <div
              class="btn_auth_in d-flex align-center justify-center"
              :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
              <div>
                <v-img 
                  contain
                  width="20"
                  height="20"
                  alt="bidanvitacare" 
                  :src="require('@/assets/icon/add_plus.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>

              <div
                class="ml-2">
                Tambah Kategori
              </div>
            </div>
          </v-btn>
        </v-card-text>

        <v-card-text
          class="pb-0">
          <div
            class="body-2 font-weight-bold black--text d-flex align-center mb-2">
            <div>
              Kategori
            </div>

            <v-spacer />

            <div>
              <!-- <v-icon>mdi-filter-variant</v-icon> -->
            </div>
          </div>

          <v-row>
            <v-col>
              <v-skeleton-loader
                v-for="(item, index) in process.load ? 5 : category"
                :key="index"
                :loading="process.load"
                type="list-item-avatar-three-line">
                <template>
                  <div>
                    <v-list 
                      v-if="!process.load && category.length > 0"
                      flat
                      dense
                      color="#FFFFFF40"
                      class="border-radius">
                      <v-list-item
                        v-if="!search_category_boll"
                        @click="sheet.detail = true; selected.type = 'category'; selected.category_id = item.id; fetchDetail(item.id);">
                        <v-list-item-avatar
                          size="40"
                          class="title d-flex align-center justify-center font-weight-bold black--text"
                          style="border: 2px dashed #e0e0e0;">
                          <span
                            style="opacity: 40%;">
                            {{ item.name | initial }}
                          </span>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="body-2 font-weight-bold text-capitalize">
                            {{ item.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        v-if="search_category_boll"
                        @click="sheet.detail = true; selected.type = 'category'; selected.category_id = item.id; fetchDetail(item.id);">
                        <v-list-item-avatar
                          size="40"
                          tile
                          style="border: 2px solid #ffffff; border-radius: 5px !important;">
                          <v-img 
                            contain
                            width="100%"
                            height="100%"
                            alt="bidanvitacare" 
                            :src="item.image_json_array.length > 0 ? item.image_json_array[0].image_url : require('@/assets/image/image_default.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  size="20"
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-subtitle>
                            <v-chip
                              x-small
                              class="white--text"
                              :color="set_color">
                              {{ item.category_name }}
                            </v-chip>
                          </v-list-item-subtitle>

                          <v-list-item-title
                            class="body-2 font-weight-bold text-capitalize"
                            style="margin-top: 5px; margin-bottom: 6px;">
                            <span
                              v-html="item.question">
                            </span>
                          </v-list-item-title>

                          <v-list-item-subtitle
                            class="body-2 font-weight-light">
                            <div
                              class="line-text-first"
                              v-html="item.answer">
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </template>
              </v-skeleton-loader>
            </v-col>
          </v-row>

          <!-- <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
            class="text-center my-4">
            <v-btn
              @click="limit += 10"
              :loading="process.limit"
              small
              rounded
              outlined
              elevation="0"
              :color="set_color"
              class="text-capitalize"
              :style="`color: ${set_color};`">
              Lebih banyak
            </v-btn>
          </div>

          <Empty v-if="!process.load && category.length < 1"
            class="py-6"
            margin="my-16"
            size="125"
            message="Kategori" /> -->
        </v-card-text>
      </v-card>
    </section>

    <v-bottom-sheet
      v-model="sheet.detail"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card v-if="Object.keys(detail).length > 0"
        flat
        class="background">
        <div
          class="blur">
          <v-card-title 
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon 
              @click="sheet.detail = false; detail = {}; fetch();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span 
              @click="sheet.detail = false; detail = {}; fetch();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Kategori {{ detail.name }}
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 0px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="pt-16 mt-4 pb-1">
              <div
                class="d-flex align-center pa-2"
                style="border: 1px dashed #bbb; border-radius: 10px;">
                <div
                  class="body-1 font-weight-bold black--text"
                  style="width: 80%;">
                  {{ detail.name }}
                </div>

                <v-spacer />

                <div
                  class="text-right"
                  style="width: 100px;">
                  <v-btn
                    @click="sheet.form = true; reset(detail);"
                    width="36"
                    height="36"
                    fab
                    large
                    rounded
                    color="#e0e0e0"
                    elevation="0">
                    <v-img 
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare" 
                      :src="require('@/assets/icon/edit_pencil.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            size="20"
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-btn>

                  <v-btn
                    @click="dialog.deletes = true"
                    width="36"
                    height="36"
                    fab
                    large
                    rounded
                    color="#f9dee2"
                    elevation="0"
                    class="ml-1">
                    <v-img 
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare" 
                      :src="require('@/assets/icon/hapus.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            size="20"
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-btn>
                </div>
              </div>
            </v-card-text>

            <v-card-text 
              class="px-0">
              <v-card
                flat
                color="transparent">
                <v-card-text 
                  class="pt-1">
                  <v-text-field
                    v-model="search_question"
                    @click:clear="search_question = ''; fetchData();"
                    v-on:keyup.enter="fetchData()"
                    filled
                    rounded
                    dense 
                    single-line
                    hide-details
                    :color="set_color"
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Cari data yang Anda butuhkan ?"
                    clearable>
                  </v-text-field>

                  <v-btn
                    @click="sheet.form_data = true; reset(); fetch();"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
                    :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                    <div
                      class="btn_auth_in d-flex align-center justify-center"
                      :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                      <div>
                        <v-img 
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare" 
                          :src="require('@/assets/icon/add_plus.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                size="20"
                                indeterminate 
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="ml-2">
                        Tambah Pertanyaan
                      </div>
                    </div>
                  </v-btn>
                </v-card-text>

                <v-card-text
                  class="pb-0">
                  <div
                    class="body-2 font-weight-bold black--text d-flex align-center mb-2">
                    <div>
                      Pertanyaan
                    </div>

                    <v-spacer />

                    <div>
                      <!-- <v-icon>mdi-filter-variant</v-icon> -->
                    </div>
                  </div>

                  <v-row>
                    <v-col>
                      <v-skeleton-loader
                        v-for="(item, index) in process.load ? 5 : list"
                        :key="index"
                        :loading="process.load"
                        :class="index < list.length - 1 ? 'mb-4' : ''"
                        type="list-item-avatar-three-line">
                        <template>
                          <div>
                            <v-list
                              v-if="!process.load && list.length > 0"
                              flat
                              dense
                              three-line
                              color="#FFFFFF40"
                              class="border-radius">
                              <v-list-item
                                @click="sheet.detail_data = true; selected.type = 'data'; fetchDetailData(item.id);">
                                <v-list-item-avatar
                                  size="40"
                                  tile
                                  style="border: 2px solid #ffffff; border-radius: 5px !important;">
                                  <v-img 
                                    contain
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare" 
                                    :src="item.image_json_array.length > 0 ? item.image_json_array[0].image_url : require('@/assets/image/image_default.png')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          size="20"
                                          indeterminate 
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    <v-chip
                                      x-small
                                      class="white--text"
                                      :color="set_color">
                                      {{ item.category_name }}
                                    </v-chip>
                                  </v-list-item-subtitle>

                                  <v-list-item-title
                                    class="body-2 font-weight-bold text-capitalize"
                                    style="margin-top: 5px; margin-bottom: 6px;">
                                    <span
                                      v-html="item.question">
                                    </span>
                                  </v-list-item-title>

                                  <v-list-item-subtitle
                                    class="body-2 font-weight-light">
                                    <div
                                      class="line-text-first"
                                      v-html="item.answer">
                                    </div>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </div>
                        </template>
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>

                  <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                    class="text-center my-4">
                    <v-btn
                      @click="limit += 10"
                      :loading="process.limit"
                      small
                      rounded
                      outlined
                      elevation="0"
                      :color="set_color"
                      class="text-capitalize"
                      :style="`color: ${set_color};`">
                      Lebih banyak
                    </v-btn>
                  </div>

                  <Empty v-if="!process.load && pagination.total_page < 1"
                    class="py-6"
                    margin="my-16"
                    size="125"
                    message="Pertanyaan" />
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.detail_data"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card v-if="Object.keys(detail_data).length > 0"
        flat
        class="background">
        <div
          class="blur">
          <v-card-title 
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon 
              @click="sheet.detail_data = false; detail_data = {}; fetchData(); sheet.detail = true; selected.type = 'category';"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span 
              @click="sheet.detail_data = false; detail_data = {}; fetchData(); sheet.detail = true; selected.type = 'category';"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Pertanyaan <span v-html="detail_data.question"></span>
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 80px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="pt-16 mt-4">
              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Kategori
                </div>

                <div
                  class="body-2 font-weight-bold black--text">
                  {{ detail_data.category_name }}
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Pertanyaan
                </div>

                <div
                  class="body-2 line-text-second black--text"
                  v-html="detail_data.question">
                </div>
              </div>

              <div
                class="mb-4">
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Jawaban
                </div>

                <div
                  class="body-2 line-text-second black--text"
                  v-html="detail_data.answer">
                </div>
              </div>

              <div>
                <div
                  class="caption mb-1"
                  style="opacity: 60%;">
                  Gambar
                </div>

                <div v-if="detail_data.image_json_array !== undefined && detail_data.image_json_array.length > 0">
                  <v-row>
                    <v-col 
                      v-for="(item, index) in detail_data.image_json_array"
                      :key="index"
                      cols="6" 
                      md="6">
                      <v-card 
                        flat
                        width="100%"
                        height="200"
                        class="d-flex align-center justify-center"
                        style="border-radius: 15px;
                              border: 5px solid #0000000d;
                              box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; ">
                        <v-img 
                          alt="bidanvitacare" 
                          width="100%"
                          height="196"
                          :src="item.image_url"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>

                <div v-else
                  class="body-2 line-text-second black--text">
                  -
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="50"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row
                align="center">
                <v-col 
                  cols="6" 
                  class="py-0">
                  <v-btn
                    @click="sheet.form_data = true; reset(detail_data);"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                    <div
                      class="btn_grey_in d-flex align-center justify-center">
                      <div>
                        <v-img 
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare" 
                          :src="require('@/assets/icon/edit_pencil.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                size="20"
                                indeterminate 
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="blue--text ml-2">
                        Ubah
                      </div>
                    </div>
                  </v-btn>
                </v-col>

                <v-col 
                  cols="6" 
                  class="py-0">
                  <v-btn
                    @click="dialog.deletes = true"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                    <div
                      class="btn_grey_in d-flex align-center justify-center">
                      <div>
                        <v-img 
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare" 
                          :src="require('@/assets/icon/hapus.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                size="20"
                                indeterminate 
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="red--text ml-2">
                        Hapus
                      </div>
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.form"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title 
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon 
              @click="sheet.form = false; reset();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span 
              @click="sheet.form = false; reset();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              {{ form.id === '' ? 'Tambah' : 'Ubah' }}
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 205px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text 
              class="pt-16 mt-4">
              <ValidationObserver 
                ref="form_category">
                <v-form>
                  <ValidationProvider 
                    name="Nama Kategori" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Nama Kategori
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. Pijat"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.name"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="text-center my-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
                  
                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="150"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row 
                align="center">
                <v-col 
                  cols="3" 
                  class="pb-0">
                  <v-img 
                    contain
                    alt="bidanvitacare" 
                    :src="require('@/assets/image/footer/guide.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col 
                  cols="9" 
                  class="black--text pb-0">
                  <div>
                    <div 
                      class="body-2 line-text-second black--text" 
                      style="opacity: 60%;">
                      Masukkan data untuk membuat panduan kategori di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                    </div>
                  </div>
                </v-col>
              </v-row>
              
              <v-row 
                class="mt-4">
                <v-col 
                  cols="6" 
                  class="pb-0">
                  <v-btn
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save"
                    @click="save"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in">
                      <v-icon 
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-check-bold
                      </v-icon>

                      Simpan
                    </div>
                  </v-btn>
                </v-col>

                <v-col 
                  cols="6" 
                  class="pb-0">
                  <v-btn
                    @click="sheet.form = false; reset();"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon 
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-close-thick
                      </v-icon>

                      Batal
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.form_data"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title 
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon 
              @click="sheet.form_data = false; reset();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span 
              @click="sheet.form_data = false; reset();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              {{ form.id === '' ? 'Tambah' : 'Ubah' }}
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 205px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text 
              class="pt-16 mt-4">
              <ValidationObserver 
                ref="form">
                <v-form>
                  <ValidationProvider 
                    name="Kategori" 
                    rules="required"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Kategori
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-select
                      v-on:keyup.enter="save_data"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. Pijat"
                      
                      :items="category"
                      item-text="name"
                      item-value="id"
                      :item-color="set_color"

                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                      v-model="form.category_id"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-select>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="Pertanyaan" 
                    rules="required"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Pertanyaan
                      <!-- <span>
                        (boleh dikosongi)
                      </span> -->
                    </div>

                    <quill-editor 
                      ref="myTextEditor"
                      class="mb-6"
                      :options="{
                        placeholder: 'Tuliskan pertanyaan disini . . .'
                      }"
                      v-model="form.question">
                    </quill-editor>

                    <!-- <v-textarea
                      v-on:keyup.enter="save_data"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      rows="5"
                      placeholder="Contoh. Tuliskan pertanyaan disini . . ."
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.question"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-textarea> -->
                  </ValidationProvider>

                  <ValidationProvider 
                    name="Jawaban" 
                    rules="required"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Jawaban
                      <!-- <span>
                        (boleh dikosongi)
                      </span> -->
                    </div>

                    <quill-editor 
                      ref="myTextEditor"
                      class="mb-6"
                      :options="{
                        placeholder: 'Tuliskan jawaban disini . . .'
                      }"
                      v-model="form.answer">
                    </quill-editor>

                    <!-- <v-textarea
                      v-on:keyup.enter="save_data"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      rows="5"
                      placeholder="Contoh. Tuliskan jawaban disini . . ."
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.answer"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-textarea> -->
                  </ValidationProvider>

                  <div>
                    <div class="body-2">
                      Gambar
                      <!-- <span>
                        (boleh dikosongi)
                      </span> -->
                    </div>

                    <v-row>
                      <v-col 
                        v-for="(item, index) in form.image_json_array"
                        :key="index"
                        cols="6" 
                        md="6">
                        <v-card 
                          flat
                          width="100%"
                          height="200"
                          class="d-flex align-center justify-center"
                          style="border-radius: 15px;
                                border: 5px solid #0000000d;
                                box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; ">
                          <v-img 
                            alt="bidanvitacare" 
                            width="100%"
                            height="196"
                            :src="item.image_url"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>

                            <div 
                              @click="form.image_json_array.splice(index, 1)"
                              class="cursor-pointer"
                              style="position: absolute; top: 5px; right: 5px;">
                              <v-icon size="24" color="red">mdi-close-circle</v-icon>
                            </div>
                          </v-img>
                        </v-card>
                      </v-col>

                      <v-col
                        cols="6" 
                        md="6">
                        <upload-folder 
                          v-model="upload.folder_array">
                          <div 
                            slot="activator" 
                            class="cursor-pointer">
                            <v-card 
                              flat
                              width="100%"
                              height="200"
                              class="d-flex align-center justify-center"
                              style="border-radius: 15px;
                                    border: 5px solid #0000000d;">
                              <v-icon v-if="!process.file_array"
                                size="50" 
                                color="grey">
                                mdi-plus
                              </v-icon>

                              <v-progress-circular v-else
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-card>
                          </div>
                        </upload-folder>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="text-center my-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
                  
                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="150"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row 
                align="center">
                <v-col 
                  cols="3" 
                  class="pb-0">
                  <v-img 
                    contain
                    alt="bidanvitacare" 
                    :src="require('@/assets/image/footer/guide.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col 
                  cols="9" 
                  class="black--text pb-0">
                  <div>
                    <div 
                      class="body-2 line-text-second black--text" 
                      style="opacity: 60%;">
                      Masukkan data untuk membuat panduan pertanyaan di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                    </div>
                  </div>
                </v-col>
              </v-row>
              
              <v-row 
                class="mt-4">
                <v-col 
                  cols="6" 
                  class="pb-0">
                  <v-btn
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save"
                    @click="save_data"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in">
                      <v-icon 
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-check-bold
                      </v-icon>

                      Simpan
                    </div>
                  </v-btn>
                </v-col>

                <v-col 
                  cols="6" 
                  class="pb-0">
                  <v-btn
                    @click="sheet.form_data = false; reset();"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon 
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-close-thick
                      </v-icon>

                      Batal
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        dialog: {
          deletes: false
        },
        sheet: {
          form: false,
          form_data: false,
          detail: false,
          detail_data: false
        },

        search_category: '',
        search_category_boll: false,
        search_question: '',
        selected: {
          type: 'category',
          category_id: ''
        },

        detail: {},
        detail_data: {},

        category: [],
        list: [],
        pagination: {},

        limit: 10,

        upload: {
          folder_array: null
        },
        form: {
          id: '',
          name: '',

          // PERTANYAAN
          category_id: '',
          question: '',
          answer: '',
          image_json_array: []
        },
        process: {
          load: false,
          form: false,
          file_array: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'upload.folder_array': {
        handler: function () {
          if (this.upload.folder_array) {
            this.process.file_array = this.upload.folder_array.process

            if (!this.process.file_array) {
              // CALLBACK FROM UPLOAD IMAGE

              let index = this.form.image_json_array.length + 1

              this.form.image_json_array.push({
                label: `Gambar ${index}`,
                image_url: this.upload.folder_array.fileuri
              })
            }
          }
        },
        deep: true
      },

      'limit': function() {
        this.process.limit = true

        // if (this.selected.type === 'category') {
        //   this.fetch('limit')
        // } else {
          this.fetchData('limit')
        // }
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          // search: this.search_category,
          // limit: this.limit
          limit: 10000
        }

        let params_data = {
          search: this.search_category,
          limit: 10000
        }

        this.category = []

        await this.$axios.$get(`${process.env.API}admin/guide-category`, { params })
        .then((response) => {
          // this.process.load = false

          // this.process.limit = false

          if (response.status === 200) {
            let data_first = response.results.data

            if (this.search_category === '' || this.search_category === null) {
              data_first.map(obj => {
                this.category.push({
                  id: obj.id,
                  name: obj.name,
                  
                  category_name: '',
                  question: '',
                  answer: '',
                  image_json_array: []
                })
              })
            }

            this.$axios.$get(`${process.env.API}admin/guide-data`, { params_data })
            .then((response) => {
              this.process.load = false

              // this.process.limit = false

              if (response.status === 200) {
                let data_second = response.results.data

                data_second.map(obj => {
                  this.category.push({
                    id: obj.category_id,
                    name: obj.category_name,
                    
                    category_name: obj.category_name,
                    question: obj.question,
                    answer: obj.answer,
                    image_json_array: obj.image_json_array
                  })
                })

                this.category = this.same_object(this.category)

                this.search_category_boll = this.search_category === '' || this.search_category === null ? false : true
              }
            })
          }
        })
      },
      same_object (arr) {
        let mapObj = new Map()
  
        arr.forEach(v => {
          let prevValue = mapObj.get(v.name)
          if(!prevValue){
            mapObj.set(v.name, v)
          } 
        })

        return [...mapObj.values()]
      },

      async fetchData (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search_question,
          limit: this.limit,

          category_id: this.selected.category_id
        }

        await this.$axios.$get(`${process.env.API}admin/guide-data`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchDetail (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/guide-category/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail = response.results.data

            this.fetchData()
          }
        })
      },
      async fetchDetailData (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/guide-data/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail_data = response.results.data
          }
        })
      },

      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()
        
        if (this.$refs.form_category) this.$refs.form_category.reset()

        this.form = {
          id: item !== undefined ? item.id : '',
          name: item !== undefined ? item.name : '',

          // PERTANYAAN
          category_id: item !== undefined ? item.category_id : '',
          question: item !== undefined ? item.question : '',
          answer: item !== undefined ? item.answer : '',
          image_json_array: item !== undefined ? item.image_json_array : []
        }
        
        this.process.form = false

        this.message.error = ''
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form_category.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form.id === '' ? 'admin/guide-category/add' : 'admin/guide-category/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form = false
              
              if (this.form.id === '') {
                this.fetch()
              } else {
                this.fetchDetail(this.detail.id)
              }
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form_category.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form_category.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },
      async save_data () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form.id === '' ? 'admin/guide-data/add' : 'admin/guide-data/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form_data = false

              if (this.form.id === '') {
                this.fetchData()
              } else {
                this.fetchDetailData(this.detail_data.id)
              }
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      async deletes () {
        this.message.error = ''

        this.process.form = true

        let url = this.selected.type === 'category' ? 'admin/guide-category/delete' : 'admin/guide-data/delete'

          // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}${url}`, {
          id: this.selected.type === 'category' ? this.detail.id : this.detail_data.id
        })
        .then((response) => {

          this.process.form = false

          if (response.status === 200) {

            this.dialog.deletes = false

            this.sheet.detail = this.selected.type === 'category' ? false : true

            this.sheet.detail_data = false

            if (this.selected.type === 'category') {
              this.fetch()
            } else {
              this.fetchData()
            }
          } else {
            this.message.error = response.message
          }
        })
      }
    }
  }
</script>