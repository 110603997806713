<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <div
              class="pa-2 mt-8 mx-4"
              :style="`background: ${set_color_rgb_soft}; border-radius: 50px;`">
              <div
                style="position: absolute; top: -15px; left: 15px">
                <v-img
                  contain
                  width="65"
                  height="65"
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/logo_bvc.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
              
              <div
                class="body-1 font-weight-bold text-center ml-10"
                :style="`color: ${set_color};`">
                Welcome to Bidanvitacare.id
              </div>
            </div>

            <v-card-text
              class="px-0 pb-0 mt-6">
              <no-ssr>
                <carousel-3d 
                  ref="carousel3d"
                  :autoplay="true"
                  :autoplay-timeout="5000"
                  :perspective="0"
                  :border="0"
                  :space="410"
                  :width="330"
                  :height="200"
                  :count="banner.length"
                  :display="3"
                  @after-slide-change="onAfterSlideChange">
                  <slide 
                    v-for="(item, index) in banner" 
                    :key="index" 
                    :index="index"
                    style="background: none;">
                    <v-card
                      flat
                      color="#FFFFFF40"
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px !important;
                            border-radius: 15px;">
                      <v-img 
                        width="100%"
                        height="175"
                        alt="kominfo" 
                        :src="item.image"
                        gradient="to top, rgba(0, 0, 0, .7), rgba(0, 0, 0, .1)"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#B71C1C">
                            </v-progress-circular>
                          </v-row>
                        </template>

                        <div 
                          class="white--text ma-4"
                          style="position: absolute; bottom: 0;"> 
                          <div
                            class="body-1 font-weight-bold one-line">
                            {{ item.title }}
                          </div>

                          <div
                            class="body-2 font-weight-light">
                            <span class="two-line white--text"
                              v-html="item.content.replace(/(<([^>]+)>)/ig, '')">
                            </span>
                          </div>
                        </div>
                      </v-img>
                    </v-card>
                  </slide>
                </carousel-3d>
              </no-ssr>
            </v-card-text>

            <div>
              <no-ssr>
                <swiper 
                  class="swiper"
                  ref="swiper"
                  :options="{ slidesPerView: 'auto' }">
                  <swiper-slide 
                    v-for="(item, index) in process.load ? 5 : list" 
                    :key="index" 
                    class="py-3 loader_produk" 
                    :class="index === 0 ? 'pl-4 pr-4' : index < list.length - 1 ? 'pr-4' : 'pr-4'"
                    style="max-width: 75px;">
                    <v-skeleton-loader
                      :loading="process.load"
                      type="image">
                      <div>
                        <v-card v-if="!process.load && list.length > 0"
                          flat
                          color="transparent"
                          class="border-radius">
                          <v-img 
                            contain
                            width="75"
                            height="75"
                            alt="bidanvitacare" 
                            :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                            class="ma-auto border-radius"
                            style="border: 2px solid #ffffff; border-radius: 10px !important;">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  size="20"
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>
                      </div>
                    </v-skeleton-loader>
                  </swiper-slide>
                </swiper>
              </no-ssr>
            </div>

            <v-card-text>
              <div
                class="d-flex align-end">
                <div>
                  <div
                    class="body-2 font-weight-bold black--text ">
                    Wuih Ada Promo
                  </div>

                  <div>
                    Berakhir pada {{ new Date() | date }}
                  </div>
                </div>

                <v-spacer />

                <div
                  class="font-weight-bold"
                  :style="`color: ${set_color};`">
                  Semua
                </div>
              </div>
            </v-card-text>

            <v-card
              flat
              :color="set_color_rgb_soft"
              style="border-radius: 0px;">
              <v-card-text
                class="py-2 px-0">
                <no-ssr>
                  <swiper 
                    class="swiper"
                    ref="swiper"
                    :options="{ slidesPerView: 'auto' }">
                    <swiper-slide v-if="!process.load && list.length > 0"
                      class="py-3 pl-4"
                      style="max-width: 150px;">
                      <v-card
                        flat
                        color="white"
                        min-height="218"
                        class="border-radius">
                        <v-img 
                          width="100%"
                          height="218"
                          alt="bidanvitacare" 
                          src="https://pbs.twimg.com/media/FrtgI45aEAAcsG2?format=jpg&name=large"
                          class="ma-auto border-radius">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                size="20"
                                indeterminate 
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-card>
                    </swiper-slide>

                    <swiper-slide 
                      v-for="(item, index) in process.load ? 5 : list" 
                      :key="index" 
                      class="py-3" 
                      :class="index === 0 ? 'pl-4 pr-4' : index < list.length - 1 ? 'pr-4' : 'pr-4'"
                      style="max-width: 150px;">
                      <v-skeleton-loader
                        :loading="process.load"
                        type="image">
                        <div>
                          <v-card v-if="!process.load && list.length > 0"
                            flat
                            color="white"
                            min-height="218"
                            class="border-radius">
                            <v-img 
                              width="100%"
                              height="100"
                              alt="bidanvitacare" 
                              :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                              class="ma-auto border-radius">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    size="20"
                                    indeterminate 
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>

                            <div
                              class="pa-3">
                              <div
                                class="body-2 font-weight-bold text-capitalize two-line"
                                style="height: 42px;">
                                {{ item.name }}
                              </div>

                              <div
                                class="body-2 font-weight-light line-text-second black--text my-1"
                                style="opacity: 70%;">
                                Durasi. {{ item.duration_minutes }} Menit
                              </div>

                              <div
                                class="body-2">
                                <span
                                  :class="item.special_price > 0 ? 'text-decoration-line-through red--text' : 'font-weight-bold'">
                                  {{ item.retail_price | price }}
                                </span>

                                <span v-if="item.special_price > 0"
                                  class="font-weight-bold ml-2">
                                  {{ item.special_price | price }}
                                </span>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </v-skeleton-loader>
                    </swiper-slide>
                  </swiper>
                </no-ssr>
              </v-card-text>
            </v-card>

            <v-card-text 
              :class="process.load || list.length > 0 ? 'mt-4' : ''">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                filled
                rounded
                dense 
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>
            </v-card-text>

            <v-card-text
              class="pb-0">
              <div
                class="body-2 font-weight-bold black--text d-flex align-center mb-2">
                <div>
                  Katalog Layanan
                </div>

                <v-spacer />

                <div>
                  <!-- <v-icon>mdi-filter-variant</v-icon> -->
                </div>
              </div>

              <v-row>
                <v-col>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list.length > 0"
                          flat
                          dense
                          two-line
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-list-item>
                            <v-list-item-avatar
                              size="40"
                              tile
                              class="mt-0"
                              style="border: 2px solid #ffffff; border-radius: 5px !important;">
                              <v-img 
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare" 
                                :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      size="20"
                                      indeterminate 
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                {{ item.name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light line-text-second black--text"
                                style="opacity: 70%;">
                                Durasi. {{ item.duration_minutes }} Menit
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="body-2">
                                <span
                                  :class="item.special_price > 0 ? 'text-decoration-line-through red--text' : 'font-weight-bold'">
                                  {{ item.retail_price | price }}
                                </span>

                                <span v-if="item.special_price > 0"
                                  class="font-weight-bold ml-2">
                                  {{ item.special_price | price }}
                                </span>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Layanan" />
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        selected: {
          banner: 0
        },
        banner: [
          {
            title: 'Bidanvitacare.id',
            content: 'Bidanvitacare.id merupakan homecare &amp; spa di Jogja untuk Ibu &amp; Si Kecil. Berbagai layanan berkualitas dari tenaga profesional Kami sediakan seperti perawatan Ibu Hamil, Ibu Menyusui, Perawatan Bayi &amp; Anak. Kami akan terus memberikan pelayanan terbaik dan juga Kami selalu mengedukasi sepenuh hati para konsumen.',
            image: require('@/assets/background/bg_hd_2.jpg')
          },
          {
            title: 'Bidanvitacare.id',
            content: 'Bidanvitacare.id merupakan homecare &amp; spa di Jogja untuk Ibu &amp; Si Kecil. Berbagai layanan berkualitas dari tenaga profesional Kami sediakan seperti perawatan Ibu Hamil, Ibu Menyusui, Perawatan Bayi &amp; Anak. Kami akan terus memberikan pelayanan terbaik dan juga Kami selalu mengedukasi sepenuh hati para konsumen.',
            image: require('@/assets/background/bg_hd_4.jpg')
          },
          {
            title: 'Bidanvitacare.id',
            content: 'Bidanvitacare.id merupakan homecare &amp; spa di Jogja untuk Ibu &amp; Si Kecil. Berbagai layanan berkualitas dari tenaga profesional Kami sediakan seperti perawatan Ibu Hamil, Ibu Menyusui, Perawatan Bayi &amp; Anak. Kami akan terus memberikan pelayanan terbaik dan juga Kami selalu mengedukasi sepenuh hati para konsumen.',
            image: require('@/assets/background/bg_hd_5.jpg')
          }
        ],
        search: '',
        list: [],
        pagination: {},
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/katalog',
          title: 'Katalog',
          description: 'Katalog Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_soft () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          limit: 5,
          search: this.search,
          'type[eq]': 'service'
        }

        await this.$axios.$get(`${process.env.API}catalog`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      onAfterSlideChange(index) {
        this.selected.banner = index
      },
      goToSlide(index) {
        this.selected.banner = index
        this.$refs.carousel3d.goSlide(index)
      },
      goToSlidePrev(index) {
        this.selected.banner = index === 0 ? this.list.banner.length - 1 : this.selected.banner - 1
        this.$refs.carousel3d.goSlide(this.selected.banner)
      },
      goToSlideNext(index) {
        this.selected.banner = index === this.list.banner.length - 1 ? 0 : this.selected.banner + 1
        this.$refs.carousel3d.goSlide(this.selected.banner)
      }
    }
  }
</script>

<style scoped>
  .carousel-3d-container {
    margin: 0px !important;
  }
</style>