<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text 
              class="py-0 mt-1">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                filled
                rounded
                dense 
                single-line

                hide-details
                
                class="mb-5"
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-select
                v-on:change="fetch()"
                filled
                rounded
                dense
                :color="set_color"
                placeholder="Pilih Cabang"

                hide-details
                
                :items="store"
                item-value="id"
                item-text="name"
                :item-color="set_color"

                :no-filter="false"

                v-model="form.store_id">
              </v-select>

              <div
                class="pa-4 mt-6"
                style="border: 1px dashed #bbb; border-radius: 10px;">
                <v-skeleton-loader
                  v-for="(item, index) in process.load ? 1 : 1"
                  :key="index"
                  :loading="process.load"
                  type="list-item-three-line">
                  <div>
                    <div v-if="!process.load && Object.keys(summary).length > 0">
                      <div
                        class="d-flex align-center mb-1">
                        <div
                          class="body-2 black--text"
                          style="opacity: 60%;">
                          Jml Item
                        </div>

                        <v-spacer />

                        <div
                          class="body-2 black--text">
                          {{ summary.sum_total_item_qty }}
                        </div>
                      </div>

                      <div
                        class="d-flex align-center mb-1">
                        <div
                          class="body-2 black--text"
                          style="opacity: 60%;">
                          Harga
                        </div>

                        <v-spacer />

                        <div
                          class="body-2 black--text">
                          {{ summary.sum_total_item_subtotal | price }}
                        </div>
                      </div>

                      <div
                        class="d-flex align-center mb-1">
                        <div
                          class="body-2 black--text"
                          style="opacity: 60%;">
                          Diskon
                        </div>

                        <v-spacer />

                        <div
                          class="body-2 black--text">
                          {{ summary.sum_discount_value | price }}
                        </div>
                      </div>

                      <div
                        class="d-flex align-center mb-1">
                        <div
                          class="body-2 black--text"
                          style="opacity: 60%;">
                          Sub Total
                        </div>

                        <v-spacer />

                        <div
                          class="body-2 black--text">
                          {{ summary.sum_nett_price | price }}
                        </div>
                      </div>

                      <div
                        class="d-flex align-center mb-1">
                        <div
                          class="body-2 black--text"
                          style="opacity: 60%;">
                          Transport
                        </div>

                        <v-spacer />

                        <div
                          class="body-2 black--text">
                          {{ summary.sum_transport_cost | price }}
                        </div>
                      </div>

                      <v-divider 
                        class="my-3"
                        style="border-top: 1px dashed #bbb;" />

                      <div
                        class="d-flex align-center">
                        <div
                          class="body-2 font-weight-bold black--text">
                          Grand Total
                        </div>

                        <v-spacer />

                        <div
                          class="body-2 font-weight-bold black--text">
                          {{ summary.sum_grand_total | price }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-skeleton-loader>
              </div>
            </v-card-text>

            <v-card-text
              class="pb-0"
              style="height: calc(100vh - 460px); overflow: auto;">
              <div
                class="d-flex align-center">
                <div
                  class="body-1 font-weight-bold black--text">
                  Transaksi Cabang
                </div>

                <v-spacer />

                <!-- <div>
                  <v-btn
                    @click="prints"
                    :loading="process.print"
                    :disabled="process.print"
                    small
                    rounded
                    :color="set_color"
                    elevation="0"
                    class="text-capitalize white--text font-weight-bold">
                    Print
                  </v-btn>
                </div> -->
              </div>

              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 4 : list"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-card v-if="!process.load && list.length > 0"
                          flat
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-card-text>
                            <div
                              class="caption mb-2 one-line d-flex align-center">
                              <v-icon small>mdi-account-circle-outline</v-icon>
                              
                              <span
                                class="ml-1">
                                {{ item.customer_name }}
                              </span>
                            </div>

                            <div
                              class="body-2 font-weight-bold black--text mb-2">
                              {{ item.code }}
                            </div>

                            <div
                              class="d-flex align-center">
                              <div
                                class="black--text">
                                {{ item.complete_datetime | date }}
                              </div>

                              <v-spacer />

                              <v-avatar
                                size="30"
                                style="border: 2px solid #ffffff;">
                                <v-img 
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare" 
                                  :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        size="20"
                                        indeterminate 
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>
                            </div>

                            <v-divider 
                              class="my-3"
                              style="border-top: 1px dashed #bbb;" />
                            
                            <div
                              class="d-flex align-center mb-1">
                              <div
                                class="body-2 black--text"
                                style="opacity: 60%;">
                                Jlm Item
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 black--text">
                                {{ item.total_item_qty }}
                              </div>
                            </div>

                            <div
                              class="d-flex align-center mb-1">
                              <div
                                class="body-2 black--text"
                                style="opacity: 60%;">
                                Harga
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 black--text">
                                {{ item.total_item_subtotal | price }}
                              </div>
                            </div>

                            <div
                              class="d-flex align-center mb-1">
                              <div
                                class="body-2 black--text"
                                style="opacity: 60%;">
                                Diskon <span v-if="item.discount_percent > 0">({{ item.discount_percent }}%)</span>
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 black--text">
                                {{ item.discount_value | price }}
                              </div>
                            </div>

                            <div
                              class="d-flex align-center mb-1">
                              <div
                                class="body-2 black--text"
                                style="opacity: 60%;">
                                Sub Total
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 black--text">
                                {{ item.nett_price | price }}
                              </div>
                            </div>

                            <div
                              class="d-flex align-center mb-1">
                              <div
                                class="body-2 black--text"
                                style="opacity: 60%;">
                                Transport
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 black--text">
                                {{ item.transport_cost | price }}
                              </div>
                            </div>

                            <v-divider 
                              class="my-3"
                              style="border-top: 1px dashed #bbb;" />

                            <div
                              class="d-flex align-center">
                              <div
                                class="body-2 font-weight-bold black--text">
                                Grand Total
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 font-weight-bold black--text">
                                {{ item.grand_total | price }}
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
              
              <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-12"
                margin="my-16"
                size="125"
                message="Transaksi Cabang" />
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import axios from "axios";
  import moment from "moment"
  import "moment/locale/id"
  export default {
    data () {
      return {
        search: '',
        detail: false,
        summary: {},
        store: [],
        list: [],
        pagination: {},

        limit: 10,

        form: {
          store_id: 0
        },
        process: {
          load: false,
          detail: false,
          print: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.process.limit = true
        
        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      this.fetchStore()
    },
    methods: {
      async fetchStore () {
        let params = {
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/store`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.store = response.results.data

            this.fetch()
          }
        })
      },
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          store_id: this.form.store_id,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}admin/report/transaction-store`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.summary = response.results.summary

            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async prints () {
        // this.process.print = true

        // await axios({
        //   url: `${process.env.API}admin/report/customer-so-month/export`,
        //   method: 'GET',
        //   responseType: 'blob', // important,
        //   headers: {
        //     Authorization: process.env.API_AUTH,
        //     token: this.$store.state.member.auth.token
        //   }
        // }).then((response) => {
        //   let res = response

        //   if (res.status === 200) {
        //     this.process.print = false

        //     const url = window.URL.createObjectURL(new Blob([response.data]))
        //     const link = document.createElement('a')
            
        //     link.href = url
        //     link.setAttribute('download', `BVC-Sales-Order-Pelanggan-${moment().format('DD-MMMM-YYYY', 'id')}-${moment().format('HH:mm:ss', 'id')}.xls`)
        //     document.body.appendChild(link)
        //     link.click()
        //   } else {
        //     this.process.print = false
        //     this.message.error = res.message
        //   }
        // })
      }
    }
  }
</script>