<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text 
              class="py-0 mt-1">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                filled
                rounded
                dense 
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>
            </v-card-text>

            <v-card-text
              class="pb-0"
              style="height: calc(100vh - 180px); overflow: auto;">
              <div
                class="d-flex align-center">
                <div
                  class="body-1 font-weight-bold black--text">
                  Top Pelanggan
                </div>

                <v-spacer />

                <div>
                  <v-btn
                    @click="prints"
                    :loading="process.print"
                    :disabled="process.print"
                    small
                    rounded
                    :color="set_color"
                    elevation="0"
                    class="text-capitalize white--text font-weight-bold">
                    Print
                  </v-btn>
                </div>
              </div>

              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 4 : list"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list.length > 0"
                          flat
                          dense
                          two-line
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-list-item>
                            <v-list-item-avatar
                              size="40"
                              class="mt-0"
                              style="border: 2px solid #ffffff;">
                              <v-img 
                                width="100%"
                                height="100%"
                                alt="bidanvitacare" 
                                :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      size="20"
                                      indeterminate 
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                {{ item.name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light line-text-second black--text"
                                style="opacity: 70%;">
                                Sales Order. {{ item.total_so }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="body-2 font-weight-bold">
                                Telp. {{ item.mobilephone }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
              
              <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-12"
                margin="my-16"
                size="125"
                message="Top Pelanggan" />
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import axios from "axios";
  import moment from "moment"
  import "moment/locale/id"
  export default {
    data () {
      return {
        search: '',
        list: [],
        pagination: {},

        limit: 10,

        process: {
          load: false,
          print: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.process.limit = true
        
        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}admin/report/top-customer`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async prints () {
        this.process.print = true

        await axios({
          url: `${process.env.API}admin/report/top-customer/export`,
          method: 'GET',
          responseType: 'blob', // important,
          headers: {
            Authorization: process.env.API_AUTH,
            token: this.$store.state.member.auth.token
          }
        }).then((response) => {
          let res = response

          if (res.status === 200) {
            this.process.print = false

            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            
            link.href = url
            link.setAttribute('download', `BVC-Top-Pelanggan-${moment().format('DD-MMMM-YYYY', 'id')}-${moment().format('HH:mm:ss', 'id')}.xls`)
            document.body.appendChild(link)
            link.click()
          } else {
            this.process.print = false
            this.message.error = res.message
          }
        })
      }
    }
  }
</script>