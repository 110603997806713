<template>
  <div>
    <section>
      <v-card
        flat
        height="calc(100vh - 205px)"
        style="overflow: auto;"
        color="transparent">
        <v-card-text
          class="py-0">
          <ValidationObserver
            ref="form">
            <v-form>
              <ValidationProvider
                name="Berat Badan"
                rules="required|min:0"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  <span class="red--text">*</span>
                  Berat Badan
                </div>

                <v-text-field
                  type="number"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                  min="0"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 0.25"
                  :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                  @focus="$event.target.select()"
                  suffix="Kg"

                  v-model="form.bb"
                  :success="valid"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'"
                name="Tinggi Badan"
                rules="required|min:0"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  <span class="red--text">*</span>
                  Tinggi Badan
                </div>

                <v-text-field
                  type="number"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                  min="0"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 100"
                  :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                  @focus="$event.target.select()"
                  suffix="Cm"

                  v-model="form.tb"
                  :success="valid"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'"
                name="Lingkar Lengan Atas"
                rules="required|min:0"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  <span class="red--text">*</span>
                  Lingkar Lengan Atas
                </div>

                <v-text-field
                  type="number"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                  min="0"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 20"
                  :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                  @focus="$event.target.select()"
                  suffix="Cm"

                  v-model="form.lila"
                  :success="valid"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'"
                name="Lingkar Kepala"
                rules="required|min:0"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  <span class="red--text">*</span>
                  Lingkar Kepala
                </div>

                <v-text-field
                  type="number"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                  min="0"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 35"
                  :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                  @focus="$event.target.select()"
                  suffix="Cm"

                  v-model="form.lk"
                  :success="valid"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider v-if="detail.client_type !== 'bayi' && detail.client_type !== 'anak'"
                name="Tensi"
                rules="required|min:0"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  <span class="red--text">*</span>
                  Tensi
                </div>

                <v-text-field
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 120/70"
                  :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                  @focus="$event.target.select()"
                  suffix="mmHg"

                  v-model="form.tensi"
                  :success="valid"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>
            </v-form>
          </ValidationObserver>

          <div v-if="message.error.length > 0"
            class="text-center my-2 d-flex align-center">
            <div
              class="error--text">
              <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

              <span
                class="ml-1">
                {{ message.error }}
              </span>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <v-card
        flat
        color="#FFFFFF"
        min-height="150"
        style="border-radius: 15px 15px 0px 0px;
              position: sticky;
              bottom: 0;
              width: 460px;
              z-index: 1;">
        <v-card-text
          class="pa-4">
          <v-row
            align="center">
            <v-col
              cols="3"
              class="pb-0">
              <v-img
                contain
                alt="bidanvitacare"
                :src="require('@/assets/image/footer/visit.png')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>

            <v-col
              cols="9"
              class="black--text pb-0">
              <div>
                <div
                  class="body-2 line-text-second black--text"
                  style="opacity: 60%;">
                  Masukkan data untuk mengubah kunjungan di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row
            class="mt-4">
            <v-col
              cols="6"
              class="pb-0">
              <v-btn
                @click="save()"
                :disabled="process.form"
                :loading="process.form"

                block
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in">
                  <v-icon
                    size="20"
                    style="position: absolute; left: 8px; top: 0; bottom: 0;">
                    mdi-check-bold
                  </v-icon>

                  Simpan
                </div>
              </v-btn>
            </v-col>

            <v-col
              cols="6"
              class="pb-0">
              <v-btn
                @click="reset(); $emit('load');"
                :disabled="process.form"

                block
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20"
                    style="position: absolute; left: 8px; top: 0; bottom: 0;">
                    mdi-close-thick
                  </v-icon>

                  Batal
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </section>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        form: {
          antropometri_id: '',
          age: '',
          bb: '',
          tb: '',
          lila: '',
          lk: '',
          tensi: '',
          keluhan: ''
        },

        process: {
          form: false
        },

        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.reset()
    },
    methods: {
      reset () {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          antropometri_id: this.detail.id,
          bb: this.detail.bb,
          tb: this.detail.tb,
          lila: this.detail.lila,
          lk: this.detail.lk,
          tensi: this.detail.tensi,
          keluhan: this.detail.keluhan
        }

        this.process.form = false

        this.message.error = ''
      },

      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}admin/schedule_v3/antropometri/update`, this.form)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {
              this.$emit('load')
            } else {
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>
