<template>
  <div>
    <v-dialog v-model="dialog.partner_or_terapis"
      persistent
      scrollable>
      <v-card
        class="border-radius pa-2">
        <v-card-title
          class="d-flex align-center py-0 px-3 text-right popup-header">
          <v-avatar
            size="30"
            tile>
            <v-img
              alt="bidanvitacare"
              :src="require('@/assets/logo/favicon.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    size="20"
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <span
            class="body-1 font-weight-bold text-capitalize ml-2">
            {{ selected.type_user }}
          </span>
        </v-card-title>

        <v-divider
          class="mx-4 mt-3 mb-2"/>

        <v-card-text v-if="selected.partner_or_terapis.length > 0"
          class="body-2 text-first line-text-second px-4 pb-2">
          <v-row>
            <v-col
              v-for="(item, index) in selected.partner_or_terapis"
              :key="index"
              cols="12">
              <v-list
                flat
                dense
                color="#FFFFFF40"
                class="border-radius"
                style="border: 1px dashed #bbb;">
                <v-list-item>
                  <v-list-item-avatar
                    size="40"
                    style="border: 2px solid #ffffff;">
                    <v-img
                      width="100%"
                      height="100%"
                      alt="bidanvitacare"
                      :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      class="body-2 font-weight-bold text-capitalize">
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider
          class="mx-4 mb-3 mt-0"/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            @click="dialog.partner_or_terapis = false"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
            <div
              class="btn_cancel_in">
              <v-icon
                size="20">
                mdi-close-thick
              </v-icon>

              <span
                class="ml-1">
                Tutup
              </span>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.go_back"
      persistent>
      <v-card
        class="border-radius pa-2">
        <v-card-text>
          <v-img
            width="150"
            height="150"
            alt="bidanvitacare"
            :src="require('@/assets/icon/info_back.png')"
            class="ma-auto mt-4 mb-3">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular
                  size="20"
                  indeterminate
                  :color="set_color">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <div
            class="body-2 text-center line-text-second">
            <div>
              Apakah Anda yakin akan keluar
            </div>

            <div>
              dari halaman ini?
            </div>
          </div>
        </v-card-text>

        <v-divider
          class="mx-4 mb-3 mt-0"/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            to="/home"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_save">
            <div
              class="btn_save_in d-flex align-center justify-center">
              <v-icon
                size="20">
                mdi-check-bold
              </v-icon>

              <span
                class="ml-1">
                Ya, Keluar
              </span>
            </div>
          </v-btn>

          <v-btn
            @click="dialog.go_back = false"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
            <div
              class="btn_cancel_in">
              <v-icon
                size="20">
                mdi-close-thick
              </v-icon>

              <span
                class="ml-1">
                Batal
              </span>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.gambar_rumah"
      persistent
      scrollable>
      <v-card v-if="Object.keys(detail).length > 0"
        class="border-radius pa-2">
        <v-card-title
          class="d-flex align-center py-0 px-3 text-right popup-header">
          <v-avatar
            size="30"
            tile>
            <v-img
              alt="bidanvitacare"
              :src="require('@/assets/logo/favicon.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    size="20"
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <span
            class="body-1 font-weight-bold text-capitalize ml-2">
            Gambar Rumah
          </span>
        </v-card-title>

        <v-divider
          class="mx-4 mt-3 mb-2"/>

        <v-card-text
          class="body-2 text-first line-text-second px-4 pb-2 my-4">
          <v-img
            contain
            width="100%"
            height="250"
            style="border: 5px solid #0000000d;
                  border-radius: 10px;"
            alt="bidanvitacare"
            :src="detail.address_image_url !== '' ? detail.address_image_url : require('@/assets/image/image_default.png')"
            class="ma-auto">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular
                  indeterminate
                  :color="set_color">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card-text>

        <v-divider
          class="mx-4 mb-3 mt-0"/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            @click="dialog.gambar_rumah = false"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
            <div
              class="btn_cancel_in">
              <v-icon
                size="20">
                mdi-close-thick
              </v-icon>

              <span
                class="ml-1">
                Tutup
              </span>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text
          class="pb-0 mt-1">
          <v-text-field
            v-model="search"
            @click:clear="search = ''; fetch();"
            v-on:keyup.enter="fetch()"
            filled
            rounded
            dense
            single-line
            hide-details
            :color="set_color"
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari data yang Anda butuhkan ?"
            clearable>
          </v-text-field>
        </v-card-text>

        <v-card-text
          class="pb-0">
          <v-row>
            <v-col
              cols="12">
              <v-skeleton-loader
                v-for="(item, index) in process.load ? 4 : list"
                :key="index"
                :loading="process.load"
                :class="index < list.length - 1 ? 'mb-4' : ''"
                type="list-item-avatar-three-line">
                <template>
                  <div>
                    <v-list
                      v-if="!process.load && list.length > 0"
                      flat
                      dense
                      three-line
                      color="#FFFFFF40"
                      class="border-radius">
                      <v-list-item
                        @click="sheet.detail = true; fetchDetail(item.id);">
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-2 font-weight-bold d-flex align-center">
                            <v-avatar
                              size="25"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <span
                              class="ml-2">
                              {{ item.customer_name }}
                            </span>
                          </v-list-item-title>

                          <v-list-item-subtitle
                            class="body-2 font-weight-light line-text-second black--text my-2 py-2"
                            style="border-top: 1px dashed #bbb; border-bottom: 1px dashed #bbb;">
                            <div
                              class="d-flex align-center">
                              <div>
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  Tipe
                                </div>

                                <div
                                  class="body-2 black--text">
                                  {{ item.type === 'on_site' ? 'On Site' : 'Home Care' }}
                                </div>
                              </div>

                              <v-spacer />

                              <div>
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  Umur
                                </div>

                                <div
                                  class="body-2 black--text">
                                  {{ item.age !== '' ? item.age : '-' }}
                                </div>
                              </div>
                            </div>

                            <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />

                            <div
                              class="d-flex align-center">
                              <div>
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  BB
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.bb }} Kg
                                </div>
                              </div>

                              <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                              <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  TB
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.tb }} Cm
                                </div>
                              </div>

                              <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                              <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  LILA
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.lila }} Cm
                                </div>
                              </div>

                              <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                              <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  LK
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.lk }} Cm
                                </div>
                              </div>

                              <v-spacer v-if="item.customer_type !== 'bayi' && item.customer_type !== 'anak'" />

                              <div v-if="item.customer_type !== 'bayi' && item.customer_type !== 'anak'">
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  Tensi
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.tensi }} mmHg
                                </div>
                              </div>
                            </div>

                            <!-- <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />

                            <div
                              class="d-flex align-center">
                              <div
                                class="caption"
                                style="opacity: 60%;">
                                Klien
                              </div>

                              <v-spacer />

                              <div>
                                <div
                                  class="d-flex align-center cursor-pointer">
                                  <v-avatar
                                    v-for="(item_customer, index_customer) in item.patner"
                                    :key="index_customer"
                                    v-show="index_customer < 2"
                                    size="24"
                                    class="mt-0"
                                    style="border: 2px solid #ffffff;"
                                    :style="item.patner.length === 2 && index_customer === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                            item.patner.length > 2 && index_customer === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                            item.patner.length > 2 && index_customer === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                                    <v-img
                                      width="100%"
                                      height="100%"
                                      alt="bidanvitacare"
                                      :src="item_customer.image_url !== '' ? item_customer.image_url : require('@/assets/image/member_default.jpg')"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular
                                            size="20"
                                            indeterminate
                                            :color="set_color">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>

                                  <span v-if="item.patner.length < 2"
                                    class="caption font-weight-bold one-line ml-1"
                                    style="width: 50px;">
                                    {{ item.patner[0].name }}
                                  </span>

                                  <span v-if="item.patner.length > 2"
                                    class="caption font-weight-bold ml-1">
                                    +{{ item.patner.length - 2 }}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />

                            <div
                              class="d-flex align-center">
                              <div
                                class="caption"
                                style="opacity: 60%;">
                                Terapis
                              </div>

                              <v-spacer />

                              <div>
                                <div
                                  class="d-flex align-center cursor-pointer">
                                  <v-avatar
                                    v-for="(item_therapist, index_therapist) in item.therapist"
                                    :key="index_therapist"
                                    v-show="index_therapist < 2"
                                    size="24"
                                    class="mt-0"
                                    style="border: 2px solid #ffffff;"
                                    :style="item.therapist.length === 2 && index_therapist === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                            item.therapist.length > 2 && index_therapist === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                            item.therapist.length > 2 && index_therapist === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                                    <v-img
                                      width="100%"
                                      height="100%"
                                      alt="bidanvitacare"
                                      :src="item_therapist.image_url !== '' ? item_therapist.image_url : require('@/assets/image/member_default.jpg')"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular
                                            size="20"
                                            indeterminate
                                            :color="set_color">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>

                                  <span v-if="item.therapist.length < 2"
                                    class="caption font-weight-bold one-line ml-1"
                                    style="width: 50px;">
                                    {{ item.therapist[0].name }}
                                  </span>

                                  <span v-if="item.therapist.length > 2"
                                    class="caption font-weight-bold ml-1">
                                    +{{ item.therapist.length - 2 }}
                                  </span>
                                </div>
                              </div>
                            </div> -->
                          </v-list-item-subtitle>

                          <v-list-item-subtitle
                            class="d-flex align-center">
                            <div>
                              <div
                                class="caption mb-1">
                                Tanggal & Waktu Kunjungan
                              </div>

                              <div
                                class="black--text">
                                {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                              </div>
                            </div>

                            <v-spacer />

                            <div>
                              <div
                                class="caption mb-1">
                                {{ item.customer_wali_status }}
                              </div>

                              <div
                                class="black--text">
                                {{ item.customer_wali_name }}
                              </div>
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </template>
              </v-skeleton-loader>
            </v-col>
          </v-row>

          <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
            class="text-center my-4">
            <v-btn
              @click="limit += 10"
              :loading="process.limit"
              small
              rounded
              outlined
              elevation="0"
              :color="set_color"
              class="text-capitalize"
              :style="`color: ${set_color};`">
              Lebih banyak
            </v-btn>
          </div>

          <Empty v-if="!process.load && pagination.total_page < 1"
            class="py-12"
            margin="my-16"
            size="125"
            message="Kunjungan" />
        </v-card-text>
      </v-card>
    </section>

    <v-bottom-sheet
      v-model="sheet.detail"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card v-if="Object.keys(detail).length > 0"
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.detail = false; detail = {};"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.detail = false; detail = {};"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Kunjungan {{ detail.customer_name }}
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 80px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="pt-16 mt-4 pb-0">
              <div
                class="d-flex align-center">
                <v-avatar
                  size="36"
                  style="border: 2px solid #ffffff;">
                  <v-img
                    width="100%"
                    height="100%"
                    alt="bidanvitacare"
                    :src="detail.customer_image_url !== '' ? detail.customer_image_url : require('@/assets/image/member_default.jpg')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="20"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <span
                  class="font-weight-bold black--text ml-2">
                  {{ detail.customer_name }}
                </span>
              </div>
            </v-card-text>

            <v-card-text>
              <v-divider
                class="mb-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="d-flex align-center">
                <div>
                  <div
                    class="body-2 mb-1">
                    Tipe
                  </div>

                  <div
                    class="body-2 font-weight-bold black--text">
                    {{ detail.type === 'on_site' ? 'On Site' : 'Home Care' }}
                  </div>
                </div>

                <v-spacer />

                <div>
                  <div
                    class="body-2 mb-1">
                    Umur
                  </div>

                  <div
                    class="body-2 font-weight-bold black--text">
                    {{ detail.age !== '' ? detail.age : '-' }}
                  </div>
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="d-flex align-center">
                <div>
                  <div
                    class="body-2 mb-1">
                    BB
                  </div>

                  <div
                    class="body-2 black--text font-weight-bold">
                    {{ detail.bb }} Kg
                  </div>
                </div>

                <v-spacer v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'" />

                <div v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'">
                  <div
                    class="body-2 mb-1">
                    TB
                  </div>

                  <div
                    class="body-2 black--text font-weight-bold">
                    {{ detail.tb }} Cm
                  </div>
                </div>

                <v-spacer v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'" />

                <div v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'">
                  <div
                    class="body-2 mb-1">
                    LILA
                  </div>

                  <div
                    class="body-2 black--text font-weight-bold">
                    {{ detail.lila }} Cm
                  </div>
                </div>

                <v-spacer v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'" />

                <div v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'">
                  <div
                    class="body-2 mb-1">
                    LK
                  </div>

                  <div
                    class="body-2 black--text font-weight-bold">
                    {{ detail.lk }} Cm
                  </div>
                </div>

                <v-spacer v-if="detail.customer_type !== 'bayi' && detail.customer_type !== 'anak'" />

                <div v-if="detail.customer_type !== 'bayi' && detail.customer_type !== 'anak'">
                  <div
                    class="body-2 mb-1">
                    Tensi
                  </div>

                  <div
                    class="body-2 black--text font-weight-bold">
                    {{ detail.tensi }} mmHg
                  </div>
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <!-- KLIEN -->
              <div
                class="d-flex align-center">
                <div
                  class="body-2">
                  Klien
                </div>

                <v-spacer />

                <div>
                  <div
                    @click="dialog.partner_or_terapis = true;
                            selected.type_user = 'Klien';
                            selected.partner_or_terapis = detail.patner;"
                    class="d-flex align-center cursor-pointer">
                    <v-avatar
                      v-for="(item_customer, index_customer) in detail.patner"
                      :key="index_customer"
                      v-show="index_customer < 2"
                      size="24"
                      class="mt-0"
                      style="border: 2px solid #ffffff;"
                      :style="detail.patner.length === 2 && index_customer === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                              detail.patner.length > 2 && index_customer === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                              detail.patner.length > 2 && index_customer === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="item_customer.image_url !== '' ? item_customer.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span v-if="detail.patner.length < 2"
                      class="caption black--text font-weight-bold one-line ml-1"
                      style="width: 50px;">
                      {{ detail.patner[0].name }}
                    </span>

                    <span v-if="detail.patner.length > 2"
                      class="caption black--text font-weight-bold ml-1">
                      +{{ detail.patner.length - 2 }}
                    </span>
                  </div>
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <!-- TERAPIS -->
              <div
                class="d-flex align-center">
                <div
                  class="body-2">
                  Terapis
                </div>

                <v-spacer />

                <div>
                  <div
                    @click="dialog.partner_or_terapis = true;
                            selected.type_user = 'Terapis';
                            selected.partner_or_terapis = detail.therapist;"
                    class="d-flex align-center cursor-pointer">
                    <v-avatar
                      v-for="(item_therapist, index_therapist) in detail.therapist"
                      :key="index_therapist"
                      v-show="index_therapist < 2"
                      size="24"
                      class="mt-0"
                      style="border: 2px solid #ffffff;"
                      :style="detail.therapist.length === 2 && index_therapist === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                              detail.therapist.length > 2 && index_therapist === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                              detail.therapist.length > 2 && index_therapist === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="item_therapist.image_url !== '' ? item_therapist.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span v-if="detail.therapist.length < 2"
                      class="caption black--text font-weight-bold one-line ml-1"
                      style="width: 50px;">
                      {{ detail.therapist[0].name }}
                    </span>

                    <span v-if="detail.therapist.length > 2"
                      class="caption black--text font-weight-bold ml-1">
                      +{{ detail.therapist.length - 2 }}
                    </span>
                  </div>
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Tanggal & Waktu Kunjungan
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail.datetime | date }} Pukul {{ detail.datetime | time }}
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Status
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail.customer_wali_status !== '' ? detail.customer_wali_status : '-' }}
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Nama Wali
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail.customer_wali_name !== '' ? detail.customer_wali_name : '-' }}
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Nama Pendamping
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail.schedule_wali !== '' ? detail.schedule_wali : '-' }}
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Keterangan
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  <div
                    class="d-flex align-start">
                    <div>
                      :
                    </div>

                    <div
                      class="ml-1">
                      {{ detail.note !== '' ? detail.note : '-' }}
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Alamat
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  <div
                    class="d-flex align-start">
                    <div>
                      :
                    </div>

                    <div
                      class="ml-1">
                      {{ detail.address_full !== '' ? detail.address_full : '-' }}
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Kecamatan
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail.address_subdistrict_name !== '' ? detail.address_subdistrict_name : '-' }}
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Kabupaten
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail.address_city_name !== '' ? detail.address_city_name : '-' }}
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Provinsi
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail.address_province_name !== '' ? detail.address_province_name : '-' }}
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Rumah
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  <div
                    class="d-flex align-start">
                    <div>
                      :
                    </div>

                    <div
                      class="ml-1">
                      {{ detail.address_note !== '' ? detail.address_note : '-' }}
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Gambar Rumah
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  <div
                    class="d-flex align-start">
                    <div>
                      :
                    </div>

                    <div
                      class="ml-1">
                      <span v-if="detail.address_image_url !== ''"
                        @click="dialog.gambar_rumah = true"
                        class="cursor-pointer"
                        style="color: #1976d3;">
                        Lihat disini
                      </span>

                      <span v-else>
                        -
                      </span>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="mb-3">
                <div
                  class="font-weight-bold black--text mb-1">
                  Catatan Kejadian
                </div>

                <div>
                  {{ detail.visit_detail[0].report_note !== '' && detail.visit_detail[0].report_note !== null ? detail.visit_detail[0].report_note : '-' }}
                </div>
              </div>

              <div
                class="mb-3">
                <div
                  class="font-weight-bold black--text mb-1">
                  Perihal Kesehatan Ibu & Anak yang ditanyakan
                </div>

                <div>
                  {{ detail.visit_detail[0].report_mom_health !== '' && detail.visit_detail[0].report_mom_health !== null ? detail.visit_detail[0].report_mom_health : '-' }}
                </div>
              </div>

              <div
                class="mb-3">
                <div
                  class="font-weight-bold black--text mb-1">
                  Pertanyaan kepo tentang manajemen
                </div>

                <div>
                  {{ detail.visit_detail[0].report_question !== '' && detail.visit_detail[0].report_question !== null ? detail.visit_detail[0].report_question : '-' }}
                </div>
              </div>

              <div
                class="mb-3">
                <div
                  class="font-weight-bold black--text mb-1">
                  Kesalahan yang dilakukan Terapis
                </div>

                <div>
                  {{ detail.visit_detail[0].report_mistake !== '' && detail.visit_detail[0].report_mistake !== null ? detail.visit_detail[0].report_mistake : '-' }}
                </div>
              </div>

              <div
                class="mb-3">
                <div
                  class="font-weight-bold black--text mb-1">
                  Komplain Klien secara langsung
                </div>

                <div>
                  {{ detail.visit_detail[0].report_complaint !== '' && detail.visit_detail[0].report_complaint !== null ? detail.visit_detail[0].report_complaint : '-' }}
                </div>
              </div>

              <div
                class="mb-3">
                <div
                  class="font-weight-bold black--text mb-1">
                  Masukkan untuk manajemen bidanvitacare / kendala yang dihadapi saat homecare atau onsite
                </div>

                <div>
                  {{ detail.visit_detail[0].report_suggest !== '' && detail.visit_detail[0].report_suggest !== null ? detail.visit_detail[0].report_suggest : '-' }}
                </div>
              </div>

              <div>
                <div
                  class="font-weight-bold black--text mb-1">
                  Promosi
                </div>

                <div>
                  {{ detail.visit_detail[0].report_promotion !== '' && detail.visit_detail[0].report_promotion !== null ? detail.visit_detail[0].report_promotion : '-' }}
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="50"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row>
                <v-col
                  cols="6"
                  class="py-0">
                  <v-btn
                    @click="sheet.attachment = true; fetchAttachment();"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                    <div
                      class="btn_grey_in d-flex align-center justify-center">
                      <div>
                        <v-img
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/attachment.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="pink--text ml-2">
                        Lampiran
                      </div>
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  cols="6"
                  class="py-0">
                  <v-btn
                    @click="sheet.history = true; fetchHistory();"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                    <div
                      class="btn_grey_in d-flex align-center justify-center">
                      <div>
                        <v-img
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/calendar_pending.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="blue--text ml-2">
                        Riwayat
                      </div>
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.attachment"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.attachment = false; fetch();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.attachment = false; fetch();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Lampiran {{ detail.customer_name }}
            </span>
          </v-card-title>

          <v-card-text
            class="pb-0 pt-16 mt-2"
            style="height: calc(100vh - 5px); overflow: auto;">
            <v-row>
              <v-col
                v-for="(item, index) in process.load ? 6 : list"
                :key="index"
                cols="6">
                <v-skeleton-loader
                  :loading="process.load"
                  type="image">
                  <template>
                    <div>
                      <v-card
                        v-if="!process.load && list.length > 0"
                        flat
                        color="transparent"
                        height="200">
                        <v-img
                          contain
                          width="100%"
                          height="100%"
                          alt="bidanvitacare"
                          :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                          class="ma-auto"
                          style="border: 5px solid #0000000d;
                                border-radius: 10px;">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-card>
                    </div>
                  </template>
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <Empty v-if="!process.load && pagination.total_page < 1"
              class="py-6"
              margin="my-16"
              size="125"
              message="Lampiran" />
          </v-card-text>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.history"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.history = false"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.attachment = false"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Riwayat {{ detail.customer_name }}
            </span>
          </v-card-title>

          <v-card-text
            class="pt-16 mt-2 px-0"
            style="height: calc(100vh - 5px); overflow: auto;">
            <v-card
              flat
              color="transparent">
              <v-card-text
                class="pb-0">
                <v-text-field
                  v-model="search"
                  @click:clear="search = ''; fetchHistory();"
                  v-on:keyup.enter="fetchHistory()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>
              </v-card-text>

              <v-card-text
                class="pb-0">
                <v-row>
                  <v-col
                    cols="12">
                    <v-skeleton-loader
                      v-for="(item, index) in process.load ? 4 : history"
                      :key="index"
                      :loading="process.load"
                      :class="index < history.length - 1 ? 'mb-4' : ''"
                      type="list-item-avatar-three-line">
                      <template>
                        <div>
                          <v-list
                            v-if="!process.load && history.length > 0"
                            flat
                            dense
                            three-line
                            color="#FFFFFF40"
                            class="border-radius">
                            <v-list-item
                              @click="sheet.detail = true; fetchDetail(item.id); sheet.history = false;">
                              <v-list-item-content>
                                <v-list-item-title
                                  class="body-2 font-weight-bold d-flex align-center">
                                  <v-avatar
                                    size="25"
                                    style="border: 2px solid #ffffff;">
                                    <v-img
                                      width="100%"
                                      height="100%"
                                      alt="bidanvitacare"
                                      :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular
                                            size="20"
                                            indeterminate
                                            :color="set_color">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>

                                  <span
                                    class="ml-2">
                                    {{ item.customer_name }}
                                  </span>
                                </v-list-item-title>

                                <v-list-item-subtitle
                                  class="body-2 font-weight-light line-text-second black--text my-2 py-2"
                                  style="border-top: 1px dashed #bbb; border-bottom: 1px dashed #bbb;">
                                  <div
                                    class="d-flex align-center">
                                    <div>
                                      <div
                                        class="caption"
                                        style="opacity: 60%; margin-bottom: 1px;">
                                        Tipe
                                      </div>

                                      <div
                                        class="body-2 black--text">
                                        {{ item.type === 'on_site' ? 'On Site' : 'Home Care' }}
                                      </div>
                                    </div>

                                    <v-spacer />

                                    <div>
                                      <div
                                        class="caption"
                                        style="opacity: 60%; margin-bottom: 1px;">
                                        Umur
                                      </div>

                                      <div
                                        class="body-2 black--text">
                                        {{ item.age !== '' ? item.age : '-' }}
                                      </div>
                                    </div>
                                  </div>

                                  <v-divider
                                    class="my-2"
                                    style="border-top: 1px dashed #bbb;" />

                                  <div
                                    class="d-flex align-center">
                                    <div>
                                      <div
                                        class="caption"
                                        style="opacity: 60%; margin-bottom: 1px;">
                                        BB
                                      </div>

                                      <div
                                        class="black--text font-weight-bold"
                                        style="font-size: 0.8125rem;">
                                        {{ item.bb }} Kg
                                      </div>
                                    </div>

                                    <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                                    <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                      <div
                                        class="caption"
                                        style="opacity: 60%; margin-bottom: 1px;">
                                        TB
                                      </div>

                                      <div
                                        class="black--text font-weight-bold"
                                        style="font-size: 0.8125rem;">
                                        {{ item.tb }} Cm
                                      </div>
                                    </div>

                                    <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                                    <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                      <div
                                        class="caption"
                                        style="opacity: 60%; margin-bottom: 1px;">
                                        LILA
                                      </div>

                                      <div
                                        class="black--text font-weight-bold"
                                        style="font-size: 0.8125rem;">
                                        {{ item.lila }} Cm
                                      </div>
                                    </div>

                                    <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                                    <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                      <div
                                        class="caption"
                                        style="opacity: 60%; margin-bottom: 1px;">
                                        LK
                                      </div>

                                      <div
                                        class="black--text font-weight-bold"
                                        style="font-size: 0.8125rem;">
                                        {{ item.lk }} Cm
                                      </div>
                                    </div>

                                    <v-spacer v-if="item.customer_type !== 'bayi' && item.customer_type !== 'anak'" />

                                    <div v-if="item.customer_type !== 'bayi' && item.customer_type !== 'anak'">
                                      <div
                                        class="caption"
                                        style="opacity: 60%; margin-bottom: 1px;">
                                        Tensi
                                      </div>

                                      <div
                                        class="black--text font-weight-bold"
                                        style="font-size: 0.8125rem;">
                                        {{ item.tensi }} mmHg
                                      </div>
                                    </div>
                                  </div>

                                  <!-- <v-divider
                                    class="my-2"
                                    style="border-top: 1px dashed #bbb;" />

                                  <div
                                    class="d-flex align-center">
                                    <div
                                      class="caption"
                                      style="opacity: 60%;">
                                      Klien
                                    </div>

                                    <v-spacer />

                                    <div>
                                      <div
                                        class="d-flex align-center cursor-pointer">
                                        <v-avatar
                                          v-for="(item_customer, index_customer) in item.patner"
                                          :key="index_customer"
                                          v-show="index_customer < 2"
                                          size="24"
                                          class="mt-0"
                                          style="border: 2px solid #ffffff;"
                                          :style="item.patner.length === 2 && index_customer === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                                  item.patner.length > 2 && index_customer === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                                  item.patner.length > 2 && index_customer === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                                          <v-img
                                            width="100%"
                                            height="100%"
                                            alt="bidanvitacare"
                                            :src="item_customer.image_url !== '' ? item_customer.image_url : require('@/assets/image/member_default.jpg')"
                                            class="ma-auto">
                                            <template v-slot:placeholder>
                                              <v-row
                                                class="fill-height"
                                                align="center"
                                                justify="center">
                                                <v-progress-circular
                                                  size="20"
                                                  indeterminate
                                                  :color="set_color">
                                                </v-progress-circular>
                                              </v-row>
                                            </template>
                                          </v-img>
                                        </v-avatar>

                                        <span v-if="item.patner.length < 2"
                                          class="caption font-weight-bold one-line ml-1"
                                          style="width: 50px;">
                                          {{ item.patner[0].name }}
                                        </span>

                                        <span v-if="item.patner.length > 2"
                                          class="caption font-weight-bold ml-1">
                                          +{{ item.patner.length - 2 }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <v-divider
                                    class="my-2"
                                    style="border-top: 1px dashed #bbb;" />

                                  <div
                                    class="d-flex align-center">
                                    <div
                                      class="caption"
                                      style="opacity: 60%;">
                                      Terapis
                                    </div>

                                    <v-spacer />

                                    <div>
                                      <div
                                        class="d-flex align-center cursor-pointer">
                                        <v-avatar
                                          v-for="(item_therapist, index_therapist) in item.therapist"
                                          :key="index_therapist"
                                          v-show="index_therapist < 2"
                                          size="24"
                                          class="mt-0"
                                          style="border: 2px solid #ffffff;"
                                          :style="item.therapist.length === 2 && index_therapist === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                                  item.therapist.length > 2 && index_therapist === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                                  item.therapist.length > 2 && index_therapist === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                                          <v-img
                                            width="100%"
                                            height="100%"
                                            alt="bidanvitacare"
                                            :src="item_therapist.image_url !== '' ? item_therapist.image_url : require('@/assets/image/member_default.jpg')"
                                            class="ma-auto">
                                            <template v-slot:placeholder>
                                              <v-row
                                                class="fill-height"
                                                align="center"
                                                justify="center">
                                                <v-progress-circular
                                                  size="20"
                                                  indeterminate
                                                  :color="set_color">
                                                </v-progress-circular>
                                              </v-row>
                                            </template>
                                          </v-img>
                                        </v-avatar>

                                        <span v-if="item.therapist.length < 2"
                                          class="caption font-weight-bold one-line ml-1"
                                          style="width: 50px;">
                                          {{ item.therapist[0].name }}
                                        </span>

                                        <span v-if="item.therapist.length > 2"
                                          class="caption font-weight-bold ml-1">
                                          +{{ item.therapist.length - 2 }}
                                        </span>
                                      </div>
                                    </div>
                                  </div> -->
                                </v-list-item-subtitle>

                                <v-list-item-subtitle
                                  class="d-flex align-center">
                                  <div>
                                    <div
                                      class="caption mb-1">
                                      Tanggal & Waktu Kunjungan
                                    </div>

                                    <div
                                      class="black--text">
                                      {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                                    </div>
                                  </div>

                                  <v-spacer />

                                  <div>
                                    <div
                                      class="caption mb-1">
                                      {{ item.customer_wali_status }}
                                    </div>

                                    <div
                                      class="black--text">
                                      {{ item.customer_wali_name }}
                                    </div>
                                  </div>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </template>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <div v-if="!process.load && pagination_history.total_data > 10 && limit < pagination_history.total_data"
                  class="text-center my-4">
                  <v-btn
                    @click="limit += 10"
                    :loading="process.limit"
                    small
                    rounded
                    outlined
                    elevation="0"
                    :color="set_color"
                    class="text-capitalize"
                    :style="`color: ${set_color};`">
                    Lebih banyak
                  </v-btn>
                </div>

                <Empty v-if="!process.load && pagination_history.total_page < 1"
                  class="py-12"
                  margin="my-16"
                  size="125"
                  message="Riwayat Kunjungan" />
              </v-card-text>
            </v-card>
          </v-card-text>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        dialog: {
          partner_or_terapis: false,

          go_back: false,

          gambar_rumah: false
        },
        sheet: {
          detail: false,
          attachment: false,

          history: false
        },
        selected: {
          type_user: 'Terapis',
          partner_or_terapis: []
        },
        search: '',
        search_attachment: '',
        detail: {},
        list: [],
        pagination: {},

        history: [],
        pagination_history: {},

        limit: 10,

        process: {
          load: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        },
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.process.limit = true

        if (this.sheet.history) {
          this.fetchHistory('limit')
        } else {
          this.fetch('limit')
        }
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()

      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)

          t.dialog.go_back = true
        })
      },

      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}therapist/customer-visit`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchDetail (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}therapist/customer-visit/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail = response.results.data
          }
        })
      },

      async fetchAttachment () {
        this.process.load = true

        let params = {
          visit_id: this.detail.id,
          search: this.search_attachment
        }

        await this.$axios.$get(`${process.env.API}therapist/customer-visit-attachment`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },

      async fetchHistory (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit,

          customer_id: this.detail.customer_id
        }

        await this.$axios.$get(`${process.env.API}therapist/customer-visit`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.history = response.results.data
            this.pagination_history = response.results.pagination
          }
        })
      }
    }
  }
</script>
