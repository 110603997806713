<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.go_back"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-text>
              <v-img 
                width="150"
                height="150"
                alt="bidanvitacare" 
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin akan keluar
                </div>

                <div>
                  dari halaman ini?
                </div>
              </div>
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                to="/home"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon 
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Keluar
                  </span>
                </div>
              </v-btn>
              
              <v-btn
                @click="dialog.go_back = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon 
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text
              class="pa-0">
              <Terapis v-if="user.role === 'therapist'" />

              <Admin v-if="user.role !== 'therapist'" />
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          go_back: false
        },

        // SEO
        content: {
          url: '/pelanggan',
          title: 'Pelanggan',
          description: 'Pelanggan Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Terapis: () => import(/* webpackPrefetch: true */ '@/components/titipan/terapis.vue'),
      Admin: () => import(/* webpackPrefetch: true */ '@/components/titipan/admin.vue')
    },
    watch: {
      
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)
          
          t.dialog.go_back = true
        })
      }
    }
  }
</script>