<template>
  <div>
    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text
          class="py-0">
          <div
            class="body-2 black--text font-weight-bold mb-6">
            Antropometri
          </div>

          <ValidationObserver
            ref="form">
            <v-form>
              <ValidationProvider
                name="Berat Badan"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  Berat Badan
                </div>

                <v-text-field
                  v-on:keyup.enter="save()"
                  type="number"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                  min="0"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 0.25"
                  :append-icon="valid && form.bb !== '' ? 'mdi-check-circle-outline' : ''"

                  @focus="$event.target.select()"
                  suffix="Kg"

                  @blur="save()"

                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran || status_required.is_done_bool"

                  v-model="form.bb"
                  :success="valid && form.bb !== ''"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'"
                name="Tinggi Badan"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  Tinggi Badan
                </div>

                <v-text-field
                  v-on:keyup.enter="save()"
                  type="number"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                  min="0"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 100"
                  :append-icon="valid && form.tb !== '' ? 'mdi-check-circle-outline' : ''"

                  @focus="$event.target.select()"
                  suffix="Cm"

                  @blur="save()"

                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran || status_required.is_done_bool"

                  v-model="form.tb"
                  :success="valid && form.tb !== ''"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'"
                name="Lingkar Lengan Atas"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  Lingkar Lengan Atas
                </div>

                <v-text-field
                  v-on:keyup.enter="save()"
                  type="number"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                  min="0"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 20"
                  :append-icon="valid && form.lila !== '' ? 'mdi-check-circle-outline' : ''"

                  @focus="$event.target.select()"
                  suffix="Cm"

                  @blur="save()"

                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran || status_required.is_done_bool"

                  v-model="form.lila"
                  :success="valid && form.lila !== ''"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'"
                name="Lingkar Kepala"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  Lingkar Kepala
                </div>

                <v-text-field
                  v-on:keyup.enter="save()"
                  type="number"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                  min="0"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 35"
                  :append-icon="valid && form.lk !== '' ? 'mdi-check-circle-outline' : ''"

                  @focus="$event.target.select()"
                  suffix="Cm"

                  @blur="save()"

                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran || status_required.is_done_bool"

                  v-model="form.lk"
                  :success="valid && form.lk !== ''"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider v-if="detail.client_type !== 'bayi' && detail.client_type !== 'anak'"
                name="Tensi"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  Tensi
                </div>

                <v-text-field
                  v-on:keyup.enter="save()"

                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 120/70"
                  :append-icon="valid && form.tensi !== '' ? 'mdi-check-circle-outline' : ''"

                  @focus="$event.target.select()"
                  suffix="mmHg"

                  @blur="save()"

                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran || status_required.is_done_bool"

                  v-model="form.tensi"
                  :success="valid && form.tensi !== ''"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>
            </v-form>
          </ValidationObserver>

          <div v-if="message.error.length > 0"
            class="text-center my-2 d-flex align-center">
            <div
              class="error--text">
              <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

              <span
                class="ml-1">
                {{ message.error }}
              </span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </section>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      },
      status_required: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        form: {
          antropometri_id: '',
          bb: '',
          tb: '',
          lila: '',
          lk: '',
          tensi: '',
          keluhan: ''
        },

        process: {
          form: false
        },

        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.reset()
    },
    methods: {
      reset () {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          antropometri_id: this.detail.antropometri.id,
          age: this.detail.antropometri.age,
          bb: this.detail.antropometri.bb,
          tb: this.detail.antropometri.tb,
          lila: this.detail.antropometri.lila,
          lk: this.detail.antropometri.lk,
          tensi: this.detail.antropometri.tensi,
          keluhan: this.detail.antropometri.keluhan
        }

        this.process.form = false

        this.message.error = ''
      },

      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}therapist/schedule_v3/antropometri/update`, this.form)
          .then((response) => {

            this.process.form = false
            if (response.status === 200) {
              this.$emit('load')
            } else {
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>
