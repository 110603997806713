<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text
              class="py-0 mt-3">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                filled
                rounded
                dense
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-btn
                @click="sheet.form = true; reset();"
                block
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
                :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                <div
                  class="btn_auth_in d-flex align-center justify-center"
                  :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                  <div>
                    <v-img
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/add_plus.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <div
                    class="ml-2">
                    Tambah Saldo
                  </div>
                </div>
              </v-btn>
            </v-card-text>

            <v-card-text
              class="pb-0"
              style="height: calc(100vh - 235px); overflow: auto;">
              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list.length > 0"
                          flat
                          dense
                          two-line
                          color="#FFFFFF40"
                          class="border-radius py-0">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                Saldo {{ item.type === 'in' ? 'Masuk' : 'Keluar' }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light line-text-second black--text"
                                style="opacity: 70%;">
                                {{ item.note !== '' ? item.note : '-' }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="body-2 font-weight-bold line-text-first black--text"
                                :class="item.type === 'in' ? 'green--text' : 'red--text'">
                                {{ item.amount | price }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-16"
                margin="my-16"
                size="125"
                message="Saldo" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.form"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.form = false; reset();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.form = false; reset();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Tambah
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-4">
                  <ValidationObserver
                    ref="form">
                    <v-form>
                      <ValidationProvider
                        name="Saldo"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Saldo
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 100.000"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.amount"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Tipe"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Tipe
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Masuk"

                          :items="[
                            {
                              text: 'Masuk',
                              value: 'in'
                            },
                            {
                              text: 'Keluar',
                              value: 'out'
                            }
                          ]"
                          item-text="text"
                          item-value="value"
                          :item-color="set_color"

                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                          v-model="form.type"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-select>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Keterangan"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          Keterangan
                          <!-- <span>
                            (boleh dikosongi)
                          </span> -->
                        </div>

                        <v-textarea
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          rows="5"
                          placeholder="Contoh. Tuliskan keterangan disini . . ."
                          :append-icon="form.note !== '' && valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.note"
                          :success="form.note !== '' && valid"
                          :error-messages="errors"
                          required>
                        </v-textarea>
                      </ValidationProvider>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center mt-3 mb-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/image/footer/saldo.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data Saldo untuk menambah saldo sedekah di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        v-on:keyup.enter="save"
                        @click="save"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.form = false; reset();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        sheet: {
          form: false
        },
        search: '',
        list: [],
        pagination: {},

        limit: 10,

        form: {
          type: 'in',
          amount: '',
          note: ''
        },
        process: {
          load: false,
          form: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.process.limit = true

        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}admin/saldo-donation`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          type: item !== undefined ? item.type : '',
          amount: item !== undefined ? item.amount : '',
          note: item !== undefined ? item.note : ''
        }

        this.process.form = false

        this.message.error = ''
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}admin/saldo-donation/add`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form = false

              this.fetch()
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>
