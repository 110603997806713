<template>
  <div>
    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text
          class="py-0">
          <div v-if="detail.kpsp.length > 0">
            <div
              class="mb-4">
              <div
                class="body-2 black--text font-weight-bold mb-1">
                KPSP
              </div>

              <div>
                Pilih data kpsp untuk mengubah jawaban dibawah ini.
              </div>
            </div>

            <v-list
              v-for="(item, index) in detail.kpsp"
              :key="index"
              :class="index < detail.kpsp.length - 1 ? 'mb-4' : ''"
              flat
              dense
              two-line
              color="#FFFFFF40"
              class="border-radius">
              <v-list-item
                @click="sheet.form = true; reset(item);">
                <v-list-item-avatar
                  size="40"
                  tile
                  class="mt-0"
                  style="border: 2px solid #ffffff; border-radius: 5px !important;">
                  <v-img
                    contain
                    width="100%"
                    height="100%"
                    alt="bidanvitacare"
                    :src="item.kpsp_image_url !== '' ? item.kpsp_image_url : require('@/assets/image/image_default.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="20"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    class="body-2 font-weight-bold text-capitalize">
                    {{ item.kpsp_type }} - {{ item.kpsp_age_month }} Bulan
                  </v-list-item-title>

                  <v-list-item-subtitle
                    class="body-2 font-weight-light line-text-second black--text"
                    style="opacity: 70%;">
                    {{ item.kpsp_question }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle
                    class="body-2 font-weight-bold">
                    Jawaban:

                    <span
                      :class="item.kpsp_answer === 'YA' ? 'green--text' : item.kpsp_answer === 'TIDAK' ? 'red--text' : ''">
                      {{ item.kpsp_answer !== '' ? item.kpsp_answer : '-' }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-card-text>
      </v-card>
    </section>

    <v-bottom-sheet
      v-model="sheet.form"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.form = false; reset();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.form = false; reset();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Laporan KPSP
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 205px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="pt-16 mt-3">
              <ValidationObserver
                ref="form">
                <v-form>
                  <v-list
                    flat
                    dense
                    color="#FFFFFF40"
                    class="border-radius pa-0">
                    <v-list-item
                      class="pa-0">
                      <v-list-item-avatar
                        size="40"
                        tile
                        style="border: 2px solid #ffffff; border-radius: 5px !important;">
                        <v-img
                          contain
                          width="100%"
                          height="100%"
                          alt="bidanvitacare"
                          :src="form.image_url !== '' ? form.image_url : require('@/assets/image/image_default.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="body-1 font-weight-bold text-capitalize">
                          {{ form.type }} - {{ form.age_month }} Bulan
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-divider
                    class="mt-4 mb-6"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="body-2 font-weight-bold black--text mb-2">
                    Pertanyaan
                  </div>

                  <div
                    class="line-text-second mb-6">
                    {{ form.question }}
                  </div>

                  <div
                    class="body-2 font-weight-bold black--text mb-2">
                    Jawaban
                  </div>

                  <div
                    class="mb-4">
                    Pilih jawaban Anda untuk menjawab pertanyaan diatas.
                  </div>

                  <ValidationProvider
                    name="Jawaban"
                    rules="required"
                    v-slot="{ errors, valid }">
                    <v-select
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. YA"

                      :items="[
                        {
                          text: 'YA',
                          value: 'YA'
                        },
                        {
                          text: 'TIDAK',
                          value: 'TIDAK'
                        }
                      ]"
                      item-text="text"
                      item-value="value"
                      :item-color="set_color"

                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                      v-model="form.answer"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-select>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="text-center mt-3 mb-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="150"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row
                align="center">
                <v-col
                  cols="3"
                  class="pb-0">
                  <v-img
                    contain
                    alt="bidanvitacare"
                    :src="require('@/assets/icon/vote.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col
                  cols="9"
                  class="black--text pb-0">
                  <div>
                    <div
                      class="body-2 line-text-second black--text"
                      style="opacity: 60%;">
                      Masukkan jawaban KPSP untuk setiap kunjungan di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row
                class="mt-4">
                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    v-on:keyup.enter="save()"
                    @click="save()"
                    :disabled="process.form"
                    :loading="process.form"

                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-check-bold
                      </v-icon>

                      Simpan
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    @click="sheet.form = false; reset();"
                    :disabled="process.form"

                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-close-thick
                      </v-icon>

                      Batal
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        sheet: {
          form: false
        },

        form: {
          kpsp_id: '',
          type: '',
          age_month: '',
          question: '',
          answer: '',
          image_url: ''
        },

        process: {
          form: false
        },

        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {

    },
    methods: {
      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          kpsp_id: item !== undefined ? item.id : '',
          type: item !== undefined ? item.kpsp_type : '',
          age_month: item !== undefined ? item.kpsp_age_month : '',
          question: item !== undefined ? item.kpsp_question : '',
          answer: item !== undefined ? item.kpsp_answer : '',
          image_url: item !== undefined ? item.kpsp_image_url : ''
        }

        this.process.form = false

        this.message.error = ''
      },

      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}admin/schedule_v3/kpsp/update`, this.form)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {
              this.sheet.form = false

              this.$emit('load')
            } else {
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>
