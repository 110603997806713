<template>
  <div>
     <v-dialog v-model="dialog.partner_or_terapis"
      persistent
      scrollable>
      <v-card
        class="border-radius pa-2">
        <v-card-title
          class="d-flex align-center py-0 px-3 text-right popup-header">
          <v-avatar
            size="30"
            tile>
            <v-img
              alt="bidanvitacare"
              :src="require('@/assets/logo/favicon.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    size="20"
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <span
            class="body-1 font-weight-bold text-capitalize ml-2">
            {{ selected.type_user }}
          </span>
        </v-card-title>

        <v-divider
          class="mx-4 mt-3 mb-2"/>

        <v-card-text v-if="selected.partner_or_terapis.length > 0"
          class="body-2 text-first line-text-second px-4 pb-2">
          <v-row>
            <v-col
              v-for="(item, index) in selected.partner_or_terapis"
              :key="index"
              cols="12">
              <v-list
                flat
                dense
                color="#FFFFFF40"
                class="border-radius"
                style="border: 1px dashed #bbb;">
                <v-list-item
                  v-if="selected.type_user === 'Klien'">
                  <v-list-item-avatar
                    size="40"
                    style="border: 2px solid #ffffff;">
                    <v-img
                      width="100%"
                      height="100%"
                      alt="bidanvitacare"
                      :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      class="body-2 font-weight-bold text-capitalize">
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="selected.type_user === 'Terapis'">
                  <v-list-item-avatar
                    size="40"
                    style="border: 2px solid #ffffff;">
                    <v-img
                      width="100%"
                      height="100%"
                      alt="bidanvitacare"
                      :src="item.user_image_url !== '' ? item.user_image_url : require('@/assets/image/member_default.jpg')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      class="body-2 font-weight-bold text-capitalize">
                      {{ item.user_name }}
                    </v-list-item-title>

                    <v-list-item-subtitle
                      class="body-2 font-weight-light text-capitalize line-text-second black--text"
                      style="opacity: 70%;">
                      {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider
          class="mx-4 mb-3 mt-0"/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            @click="dialog.partner_or_terapis = false"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
            <div
              class="btn_cancel_in">
              <v-icon
                size="20">
                mdi-close-thick
              </v-icon>

              <span
                class="ml-1">
                Tutup
              </span>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.go_back"
      persistent>
      <v-card
        class="border-radius pa-2">
        <v-card-text>
          <v-img
            width="150"
            height="150"
            alt="bidanvitacare"
            :src="require('@/assets/icon/info_back.png')"
            class="ma-auto mt-4 mb-3">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular
                  size="20"
                  indeterminate
                  :color="set_color">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <div
            class="body-2 text-center line-text-second">
            <div>
              Apakah Anda yakin akan keluar
            </div>

            <div>
              dari halaman ini?
            </div>
          </div>
        </v-card-text>

        <v-divider
          class="mx-4 mb-3 mt-0"/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            to="/home"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_save">
            <div
              class="btn_save_in d-flex align-center justify-center">
              <v-icon
                size="20">
                mdi-check-bold
              </v-icon>

              <span
                class="ml-1">
                Ya, Keluar
              </span>
            </div>
          </v-btn>

          <v-btn
            @click="dialog.go_back = false"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
            <div
              class="btn_cancel_in">
              <v-icon
                size="20">
                mdi-close-thick
              </v-icon>

              <span
                class="ml-1">
                Batal
              </span>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.gambar_rumah"
      persistent
      scrollable>
      <v-card v-if="Object.keys(detail).length > 0"
        class="border-radius pa-2">
        <v-card-title
          class="d-flex align-center py-0 px-3 text-right popup-header">
          <v-avatar
            size="30"
            tile>
            <v-img
              alt="bidanvitacare"
              :src="require('@/assets/logo/favicon.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    size="20"
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <span
            class="body-1 font-weight-bold text-capitalize ml-2">
            Gambar Rumah
          </span>
        </v-card-title>

        <v-divider
          class="mx-4 mt-3 mb-2"/>

        <v-card-text
          class="body-2 text-first line-text-second px-4 pb-2 my-4">
          <v-img
            contain
            width="100%"
            height="250"
            style="border: 5px solid #0000000d;
                  border-radius: 10px;"
            alt="bidanvitacare"
            :src="detail.address_image_url !== '' ? detail.address_image_url : require('@/assets/image/image_default.png')"
            class="ma-auto">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular
                  indeterminate
                  :color="set_color">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card-text>

        <v-divider
          class="mx-4 mb-3 mt-0"/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            @click="dialog.gambar_rumah = false"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
            <div
              class="btn_cancel_in">
              <v-icon
                size="20">
                mdi-close-thick
              </v-icon>

              <span
                class="ml-1">
                Tutup
              </span>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text
          class="pb-0 mt-1">
          <v-text-field
            v-model="search"
            @click:clear="search = ''; fetch();"
            v-on:keyup.enter="fetch()"
            filled
            rounded
            dense
            single-line
            hide-details
            :color="set_color"
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari data yang Anda butuhkan ?"
            clearable>
          </v-text-field>
        </v-card-text>

        <v-card-text
          class="pb-0">
          <v-row>
            <v-col
              cols="12">
              <v-skeleton-loader
                v-for="(item, index) in process.load ? 4 : list"
                :key="index"
                :loading="process.load"
                :class="index < list.length - 1 ? 'mb-4' : ''"
                type="list-item-avatar-three-line">
                <template>
                  <div>
                    <v-list
                      v-if="!process.load && list.length > 0"
                      flat
                      dense
                      three-line
                      color="#FFFFFF40"
                      class="border-radius">
                      <v-list-item
                        @click="sheet.detail = true; fetchDetail(item.id);">
                        <v-list-item-avatar
                          size="40"
                          class="mt-2"
                          style="border: 2px solid #ffffff;">
                          <v-img
                            width="100%"
                            height="100%"
                            alt="bidanvitacare"
                            :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="body-2 font-weight-bold text-capitalize">
                            {{ item.customer_name }}
                          </v-list-item-title>

                          <v-list-item-subtitle
                            class="body-2 font-weight-light line-text-second black--text my-1">
                            {{ item.type === 'on_site' ? 'On Site' : 'Home Care' }}
                          </v-list-item-subtitle>

                          <v-list-item-subtitle
                            class="d-flex align-center">
                            <div>
                              <div
                                class="caption mb-1">
                                Tanggal
                              </div>

                              <div
                                class="black--text">
                                {{ item.datetime | datetime }}
                              </div>
                            </div>

                            <v-spacer />

                            <div>
                              <div
                                class="caption mb-1">
                                Status
                              </div>

                              <div
                                :class="item.status === 'waiting' ? 'red--text' : item.status === 'process' ? 'orange--text' : 'green--text'">
                                {{
                                  item.status === 'waiting' ? 'Menunggu' :
                                  item.status === 'process' ? 'Proses' : 'Komplit'
                                }}
                              </div>
                            </div>
                          </v-list-item-subtitle>

                          <div
                            class="pa-3 mt-4"
                            style="border: 2px dashed #bbb; border-radius: 10px;">
                            <div
                              class="body-2 font-weight-light line-text-second black--text"
                              v-html="item.trello_format">
                            </div>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </template>
              </v-skeleton-loader>
            </v-col>
          </v-row>

          <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
            class="text-center my-4">
            <v-btn
              @click="limit += 10"
              :loading="process.limit"
              small
              rounded
              outlined
              elevation="0"
              :color="set_color"
              class="text-capitalize"
              :style="`color: ${set_color};`">
              Lebih banyak
            </v-btn>
          </div>

          <Empty v-if="!process.load && pagination.total_page < 1"
            class="py-12"
            margin="my-16"
            size="125"
            message="Kunjungan" />
        </v-card-text>
      </v-card>
    </section>

    <v-bottom-sheet
      v-model="sheet.detail"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card v-if="Object.keys(detail).length > 0"
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.detail = false; detail = {};"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.detail = false; detail = {};"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Jadwal {{ detail.customer_name }}
            </span>
          </v-card-title>

          <v-card-text
            class="mt-16"
            style="height:calc(100vh - 130px); overflow: auto;">
            <v-skeleton-loader
              :loading="process.detail"
              type="list-item-avatar-three-line, article, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, article, actions">
              <div>
                <div v-if="!process.detail && Object.keys(detail).length > 0">
                  <v-card
                    flat
                    color="transparent"
                    class="py-0"
                    style="border-radius: 10px;">
                    <v-list
                      flat
                      dense
                      color="transparent">
                      <v-list-item
                        class="px-0">
                        <v-list-item-avatar
                          size="40"
                          style="border: 2px solid #ffffff; overflow: unset;">
                          <v-img
                            width="100%"
                            height="100%"
                            alt="bidanvitacare"
                            :src="detail.customer_image_url !== '' ? detail.customer_image_url : require('@/assets/image/member_default.jpg')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="body-2 font-weight-bold text-capitalize mb-2">
                            {{ detail.customer_name }}
                          </v-list-item-title>

                          <v-list-item-subtitle
                            class="body-2 font-weight-light black--text">
                            {{ detail.customer_mobilephone | phone }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>

                  <v-divider
                    class="my-1"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="d-flex align-center my-4">
                    <div>
                      <div
                        class="line-text-second">
                        {{ detail.type === 'on_site' ? 'On Site' : 'Home Care' }}
                      </div>

                      <div
                        class="caption font-weight-bold">
                        {{ detail.datetime | datetime }}
                      </div>
                    </div>

                    <v-spacer />

                    <v-chip
                      small
                      class="white--text"
                      :color="detail.status === 'waiting' ? 'red' : detail.status === 'process' ? 'orange' : 'green'">
                      {{
                        detail.status === 'waiting' ? 'Menunggu' :
                        detail.status === 'process' ? 'Proses' : 'Komplit'
                      }}
                    </v-chip>
                  </div>

                  <v-divider
                    class="my-1"
                    style="border-top: 1px dashed #bbb;" />

                  <v-row>
                    <v-col
                      v-for="(item, index) in detail.items"
                      :key="index"
                      cols="12">
                      <v-card
                        flat
                        color="#FFFFFF40"
                        class="pa-3 pt-2"
                        style="border: 2px dashed #bbb; border-radius: 10px;">
                        <v-list
                          flat
                          dense
                          two-line
                          color="transparent"
                          class="py-0">
                          <v-list-item
                            class="px-0">
                            <v-list-item-avatar
                              size="40"
                              tile
                              class="mt-3"
                              style="border: 2px solid #ffffff; border-radius: 5px !important;">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <div
                                class="mb-2">
                                <v-chip
                                  x-small
                                  color="#424242"
                                  class="white--text d-flex justify-center"
                                  style="width: 65px;">
                                  {{ item.type === 'service' ? 'Layanan' : item.type === 'product' ? 'Produk' : 'Bahan' }}
                                </v-chip>
                              </div>

                              <div
                                class="body-2 font-weight-bold text-capitalize">
                                <span
                                  class="two-line">
                                  {{ item.name }}
                                </span>
                              </div>

                              <div v-if="item.customer.length > 0">
                                <v-divider
                                  class="my-3"
                                  style="border-top: 1px dashed #bbb;" />
                              </div>

                              <div v-if="item.customer.length > 0"
                                class="d-flex align-center">
                                <div>
                                  Klien
                                </div>

                                <v-spacer />

                                <div>
                                  <div
                                    @click="dialog.partner_or_terapis = true;
                                            selected.type_user = 'Klien';
                                            selected.partner_or_terapis = item.customer;"
                                    class="d-flex align-center cursor-pointer">
                                    <v-avatar
                                      v-for="(item_customer, index_customer) in item.customer"
                                      :key="index_customer"
                                      v-show="index_customer < 2"
                                      size="24"
                                      class="mt-0"
                                      style="border: 2px solid #ffffff;"
                                      :style="item.customer.length === 2 && index_customer === 0 ? 'position: absolute; right: 20px; z-index: 2;' :
                                              item.customer.length > 2 && index_customer === 0 ? 'position: absolute; right: 38px; z-index: 2;' :
                                              item.customer.length > 2 && index_customer === 1 ? 'position: absolute; right: 22px; z-index: 1;' : ''">
                                      <v-img
                                        width="100%"
                                        height="100%"
                                        alt="bidanvitacare"
                                        :src="item_customer.image_url !== '' ? item_customer.image_url : require('@/assets/image/member_default.jpg')"
                                        class="ma-auto">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular
                                              size="20"
                                              indeterminate
                                              :color="set_color">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-avatar>

                                    <span v-if="item.customer.length < 2"
                                      class="caption font-weight-bold one-line ml-1"
                                      style="width: 50px;">
                                      {{ item.customer[0].name }}
                                    </span>

                                    <span v-if="item.customer.length > 2"
                                      class="caption font-weight-bold ml-1">
                                      +{{ item.customer.length - 2 }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <v-divider
                                  :class="item.customer.length > 0 ? 'mt-3' : 'mt-2'"
                                  style="border-top: 1px dashed #bbb;" />
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>

                        <div
                          class="d-flex align-start">
                          <div
                            class="caption">
                            {{ item.note !== '' ? item.note : 'Tidak ada catatan' }}
                          </div>

                          <v-spacer />

                          <div v-if="item.type === 'service'">
                            <div v-if="item.visit.length > 0"
                              @click="dialog.partner_or_terapis = true;
                                      selected.type_user = 'Terapis';
                                      selected.partner_or_terapis = item.visit;"
                              class="d-flex align-center cursor-pointer">
                              <v-avatar
                                v-for="(item_visit, index_visit) in item.visit"
                                :key="index_visit"
                                v-show="index_visit < 2"
                                size="24"
                                class="mt-0"
                                style="border: 2px solid #ffffff;"
                                :style="item.visit.length === 2 && index_visit === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                        item.visit.length > 2 && index_visit === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                        item.visit.length > 2 && index_visit === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="item_visit.user_image_url !== '' ? item_visit.user_image_url : require('@/assets/image/member_default.jpg')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        size="20"
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>

                              <span v-if="item.visit.length < 2"
                                class="caption font-weight-bold one-line ml-1"
                                style="width: 50px;">
                                {{ item.visit[0].user_name }}
                              </span>

                              <span v-if="item.visit.length > 2"
                                class="caption font-weight-bold ml-1">
                                +{{ item.visit.length - 2 }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-divider
                    class="my-1"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="mt-4 mb-2">
                    Jlm Item ({{ detail.total_item_qty }})
                  </div>

                  <div
                    class="d-flex align-center mb-4">
                    <div>
                      Down Payment
                    </div>

                    <v-spacer />

                    <div>
                      {{ detail.dp | price }}
                    </div>
                  </div>

                  <v-divider v-if="detail.type === 'home_care'"
                    class="my-1"
                    style="border-top: 1px dashed #bbb;" />

                  <div v-if="detail.type === 'home_care'">
                    <div
                      class="font-weight-bold mt-4 mb-2">
                      Alamat
                    </div>

                    <div
                      class="mb-2">
                      {{ detail.address_note }}
                    </div>

                    <div
                      class="mb-4">
                      {{ detail.address_subdistrict_name }}, {{ detail.address_city_name }}, {{ detail.address_province_name }}
                    </div>
                  </div>

                  <div v-if="detail.type === 'home_care'">
                    <div
                      class="font-weight-bold mt-4 mb-2">
                      Gambar Rumah
                    </div>

                    <div
                      class="text-truncate mb-4">
                      <span v-if="detail.address_image_url !== ''"
                        @click="dialog.gambar_rumah = true"
                        class="cursor-pointer"
                        style="color: #1976d3;">
                        Lihat disini
                      </span>

                      <span v-else>
                        -
                      </span>
                    </div>
                  </div>

                  <div v-if="detail.type === 'home_care'">
                    <div
                      class="font-weight-bold mt-4 mb-2">
                      Link Map
                    </div>

                    <div
                      class="text-truncate mb-4">
                      <a v-if="detail.address_link_map !== ''"
                        :href="detail.address_link_map"
                        target="_blank">
                        {{ detail.address_link_map }}
                      </a>

                      <span v-else>
                        -
                      </span>
                    </div>
                  </div>

                  <v-divider
                    class="my-1"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="font-weight-bold mt-4 mb-2">
                    Pendamping
                  </div>

                  <div
                    class="d-flex align-center mb-2">
                    <div>
                      Nama Pendamping
                    </div>

                    <v-spacer />

                    <div
                      class="text-capitalize">
                      {{ detail.wali !== '' ? detail.wali : '-' }}
                    </div>
                  </div>

                  <!-- <div
                    class="d-flex align-center mb-2">
                    <div>
                      No. Handphone Wali
                    </div>

                    <v-spacer />

                    <div>
                      {{ detail.mobilephone_wali !== '' ? detail.mobilephone_wali : '-' }}
                    </div>
                  </div>

                  <div
                    class="d-flex align-center mb-2">
                    <div>
                      No. Handphone Lainnya
                    </div>

                    <v-spacer />

                    <div>
                      {{ detail.mobilephone_other !== '' ? detail.mobilephone_other : '-' }}
                    </div>
                  </div> -->
                </div>
              </div>
            </v-skeleton-loader>
          </v-card-text>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        dialog: {
          partner_or_terapis: false,

          go_back: false,

          gambar_rumah: false
        },
        sheet: {
          detail: false
        },
        selected: {
          type_user: 'Terapis',
          partner_or_terapis: []
        },
        search: '',
        detail: {},
        list: [],
        pagination: {},

        limit: 10,

        process: {
          load: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.process.limit = true

        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()

      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)

          t.dialog.go_back = true
        })
      },

      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}therapist/schedule/draft`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchDetail (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}therapist/schedule/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail = response.results.data
          }
        })
      }
    }
  }
</script>
