<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-title
              class="font-weight-bold black--text">
              Profil Saya
            </v-card-title>

            <v-card-text
              class="my-4">
              <v-card
                class="border-radius"
                :color="set_color_rgb_soft"
                :style="`box-shadow: ${set_color_rgb} 0px 25px 20px -20px;`">
                <v-list 
                  flat 
                  color="transparent">
                  <v-list-item 
                    style="border-radius: 0px;">
                    <v-list-item-avatar 
                      size="45">
                      <v-img 
                        width="100%"
                        height="100%"
                        alt="bidanvitacare" 
                        :src="detail.image_url !== '' ? detail.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title 
                        class="title font-weight-bold text-capitalize mb-1"
                        :style="`color: ${set_color};`">
                        {{ detail.name }}
                      </v-list-item-title>

                      <v-list-item-subtitle 
                        class="d-flex align-center black--text"
                        style="opacity: 60%;">
                        {{ detail.email }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <div
                  class="pa-4 pt-0">
                  <v-card-text
                    :class="user.role === 'superadmin' ? 'd-flex align-center' : ''"
                    style="border-radius: 15px;"
                    :style="`background: ${set_color};`">
                    <div
                      class="caption font-weight-bold white--text line-text-first"
                      :style="user.role === 'superadmin' ? 'width: 50%;' : ''">
                      Anda sekarang bertugas di lokasi {{ detail.store_name }}
                    </div>

                    <v-spacer v-if="user.role === 'superadmin'" />

                    <div v-if="user.role === 'superadmin'">
                      <v-btn
                        to="/saldo"
                        color="#FFFFFF40"
                        elevation="0"
                        rounded
                        class="body-2 font-weight-bold white--text text-capitalize">
                        Saldo
                      </v-btn>
                    </div>
                  </v-card-text>
                </div>
              </v-card>
            </v-card-text>

            <v-list 
              flat 
              dense
              class="px-4"
              color="transparent">
              <v-list-item 
                v-for="(item, index) in menu"
                :key="index"
                @click="goTo(index)"
                class="px-0 py-2"
                :style="index < menu.length - 1 ? 'border-bottom: 1px dashed #bbb;' : ''">
                <v-list-item-avatar 
                  size="22"
                  tile
                  class="mr-3">
                  <v-img 
                    contain
                    alt="bidanvitacare" 
                    :src="item.icon"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="20"
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <!-- <v-list-item-title 
                    class="body-2 font-weight-bold"
                    :class="index === menu.length - 1 ? 'red--text' : 'black--text'">
                    {{ item.title }}
                  </v-list-item-title> -->

                  <v-list-item-title 
                    class="body-2 font-weight-bold black--text">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.menu"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title 
                @click="goTo(selected.menu)"
                class="cursor-pointer pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon 
                  :color="set_color">
                  mdi-arrow-left-thick
                </v-icon>

                <span v-if="selected.menu !== null"
                  class="body-1 font-weight-bold text-capitalize ml-1"
                  :style="`color: ${set_color};`">
                  {{ menu[selected.menu].title }}
                </span>
              </v-card-title>

              <v-card-text 
                class="pa-0">
                <General v-if="selected.menu === 0"
                  :detail="detail"
                  @close="sheet.menu = $event; fetch();" />

                <Password v-if="selected.menu === 1"
                  @close="sheet.menu = $event; fetch();" />
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        sheet: {
          menu: false
        },
        selected: {
          menu: null
        },
        menu: [
          {
            title: 'Ubah General',
            icon: require('@/assets/image/profile/profile.png'),
            link: '#'
          },
          {
            title: 'Ubah Password',
            icon: require('@/assets/image/profile/password.png'),
            link: '#'
          },
          // {
          //   title: 'Keluar',
          //   icon: require('@/assets/image/profile/logout.png'),
          //   link: '/logout'
          // }
        ],
        detail: {},
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/profil',
          title: 'Profil Saya',
          description: 'Profil Saya Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      General: () => import(/* webpackPrefetch: true */ '@/components/profile/general.vue'),
      Password: () => import(/* webpackPrefetch: true */ '@/components/profile/password.vue')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b})`
      },
      set_color_rgb_soft () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        await this.$axios.$get(`${process.env.API}admin/profile/detail`)
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.detail = response.results.data
          }
        })
      },
      async goTo (index) {
        if (this.menu[index].link === '/logout') {
          await this.$axios.$post(`${process.env.API}auth/logout`, {
            username: this.user.username
          })
          .then((response) => {
            if (response.status === 200) {
              Cookie.remove('token')
              Cookie.remove('user')
              Cookie.remove('intro')
              Cookie.remove('fcm')
              Cookie.remove('notif')
              Cookie.remove('kasir')
              Cookie.remove('pelanggan')
              Cookie.remove('address')
              Cookie.remove('payment')

              window.location = '/auth/login'
            }
          })
        } else {
          this.selected.menu = index

          this.sheet.menu = !this.sheet.menu ? true : false
        }
      }
    }
  }
</script>