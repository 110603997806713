<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container
            style="height: 100vh;">
            <v-row
              align="center"
              style="height:calc(100vh - 100px)">
              <v-col>
                <v-card  
                  flat
                  color="transparent">
                  <v-card-text
                    class="text-center mb-8">
                    <v-img
                      contain
                      width="200"
                      height="auto"
                      class="ma-auto"
                      alt="bidanvitacare"
                      :src="require('@/assets/logo/favicon.png')">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div
                      style="position: relative; top: -35px;">
                      <div>
                        Mom, Baby & Kids Spa Sejak 2017
                      </div>
                      
                      <div
                        class="mt-2">
                        Sahabat Ceria Bunda & Buah Hati
                      </div>
                    </div>
                  </v-card-text>

                  <v-card-text>
                    <ValidationObserver 
                      ref="form">
                      <v-form>
                        <ValidationProvider 
                          name="new_password" 
                          vid="confirm"
                          rules="required|min:5|max:20" 
                          v-slot="{ errors }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            filled
                            rounded
                            dense
                            :color="set_color"
                            placeholder="Password Baru"
                            :type="show.new_password ? 'text' : 'password'"
                            prepend-inner-icon="mdi-lock-outline"
                            :append-icon="show.new_password ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show.new_password = !show.new_password"
                            :counter="20"
                            
                            v-model="form.new_password"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>

                        <ValidationProvider 
                          name="new_password_verify" 
                          rules="required|min:5|max:20|confirmed:confirm"
                          v-slot="{ errors }">
                          <v-text-field
                            v-on:keyup.enter="save"
                            filled
                            rounded
                            dense
                            :color="set_color"
                            placeholder="Konfirmasi Password Baru"
                            :type="show.new_password_verify ? 'text' : 'password'"
                            prepend-inner-icon="mdi-lock-check-outline"
                            :append-icon="show.new_password_verify ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show.new_password_verify = !show.new_password_verify"
                            :counter="20"
                            
                            v-model="form.new_password_verify"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </v-form>
                    </ValidationObserver>

                    <div v-if="message.error.length > 0"
                      class="text-center mt-3 mb-2 d-flex align-center">
                      <div
                        class="error--text">
                        <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
                        
                        <span
                          class="ml-1">
                          {{ message.error }}
                        </span>
                      </div>
                    </div>
                  </v-card-text>

                  <v-card-text
                    class="text-center">
                    <v-btn
                      v-on:keyup.enter="save"
                      :disabled="process.form"
                      :loading="process.form"
                      @click="save"
                      width="200"
                      large
                      rounded
                      elevation="0"
                      class="body-1 font-weight-bold white--text text-capitalize btn_auth"
                      :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                      <div
                        class="btn_auth_in"
                        :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                        Reset Password
                      </div>
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <div>
              <v-row
                justify="center">
                <v-col 
                  cols="2">
                  <v-divider 
                    :style="`border-top: 5px solid ${set_color}; border-radius: 50px;`" />
                </v-col>
              </v-row>

              <div
                class="body-2 text-center mt-2"
                style="opacity: 70%;">
                Copyright@{{ new Date().getFullYear() }} <strong>bidanvitacare.id</strong>
              </div>
            </div>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        show: {
          new_password: false,
          new_password_verify: false
        },
        form: {
          token: '',
          new_password: '',
          new_password_verify: ''
        },
        process: {
          form: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/auth/reset/:slug',
          title: 'Reset Password',
          description: 'Reset Password Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.form.token = this.$route.params.slug
    },
    methods: {
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          this.$router.push({ path: '/auth/success' })
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>