<template>
  <div>
    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text
          class="py-0">
          <div
            class="body-2 black--text font-weight-bold mb-6">
            Laporan
          </div>

          <ValidationObserver
            ref="form">
            <v-form>
              <ValidationProvider
                name="Catatan Kejadian"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  Catatan Kejadian
                </div>

                <v-textarea
                  v-on:keyup.enter="save()"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  rows="5"
                  placeholder="Contoh. Tuliskan catatan kejadian disini . . ."
                  :append-icon="form.catatan_kejadian !== '' && valid ? 'mdi-check-circle-outline' : ''"

                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran"

                  v-model="form.catatan_kejadian"
                  :success="form.catatan_kejadian !== '' && valid"
                  :error-messages="errors"
                  required>
                </v-textarea>
              </ValidationProvider>

              <ValidationProvider
                name="Perihal Kesehatan Ibu & Anak yang ditanyakan"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  Perihal Kesehatan Ibu & Anak yang ditanyakan
                </div>

                <v-textarea
                  v-on:keyup.enter="save()"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  rows="5"
                  placeholder="Contoh. Tuliskan perihal kesehatan ibu & anak yang ditanyakan disini . . ."
                  :append-icon="form.perihal_kesehatan !== '' && valid ? 'mdi-check-circle-outline' : ''"

                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran"

                  v-model="form.perihal_kesehatan"
                  :success="form.perihal_kesehatan !== '' && valid"
                  :error-messages="errors"
                  required>
                </v-textarea>
              </ValidationProvider>

              <ValidationProvider
                name="Pertanyaan kepo tentang manajemen"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  Pertanyaan kepo tentang manajemen
                </div>

                <v-textarea
                  v-on:keyup.enter="save()"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  rows="5"
                  placeholder="Contoh. Tuliskan pertanyaan kepo tentang manajemen disini . . ."
                  :append-icon="form.pertanyaan_kepo !== '' && valid ? 'mdi-check-circle-outline' : ''"

                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran"

                  v-model="form.pertanyaan_kepo"
                  :success="form.pertanyaan_kepo !== '' && valid"
                  :error-messages="errors"
                  required>
                </v-textarea>
              </ValidationProvider>

              <ValidationProvider
                name="Kesalahan yang dilakukan Terapis"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  Kesalahan yang dilakukan Terapis
                </div>

                <v-textarea
                  v-on:keyup.enter="save()"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  rows="5"
                  placeholder="Contoh. Tuliskan kesalahan yang dilakukan terapis disini . . ."
                  :append-icon="form.kesalahan_therapist !== '' && valid ? 'mdi-check-circle-outline' : ''"

                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran"

                  v-model="form.kesalahan_therapist"
                  :success="form.kesalahan_therapist !== '' && valid"
                  :error-messages="errors"
                  required>
                </v-textarea>
              </ValidationProvider>

              <ValidationProvider
                name="Komplain Klien secara langsung"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  Komplain Klien secara langsung
                </div>

                <v-textarea
                  v-on:keyup.enter="save()"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  rows="5"
                  placeholder="Contoh. Tuliskan komplain klien secara langsung disini . . ."
                  :append-icon="form.komplain !== '' && valid ? 'mdi-check-circle-outline' : ''"

                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran"

                  v-model="form.komplain"
                  :success="form.komplain !== '' && valid"
                  :error-messages="errors"
                  required>
                </v-textarea>
              </ValidationProvider>

              <ValidationProvider
                name="Masukkan untuk manajemen bidanvitacare / kendala yang dihadapi saat homecare atau onsite"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  Masukkan untuk manajemen bidanvitacare / kendala yang dihadapi saat homecare atau onsite
                </div>

                <v-textarea
                  v-on:keyup.enter="save()"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  rows="5"
                  placeholder="Contoh. Tuliskan masukkan untuk manajemen bidanvitacare / kendala yang dihadapi saat homecare atau onsite disini . . ."
                  :append-icon="form.masukan !== '' && valid ? 'mdi-check-circle-outline' : ''"

                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran"

                  v-model="form.masukan"
                  :success="form.masukan !== '' && valid"
                  :error-messages="errors"
                  required>
                </v-textarea>
              </ValidationProvider>

              <ValidationProvider
                name="Promosi"
                rules="required"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2">
                  <span class="red--text">*</span>
                  Promosi
                </div>

                <v-textarea
                  v-on:keyup.enter="save()"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  rows="5"
                  placeholder="Contoh. Tuliskan promosi disini . . ."
                  :append-icon="form.promosi !== '' && valid ? 'mdi-check-circle-outline' : ''"

                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran"

                  v-model="form.promosi"
                  :success="form.promosi !== '' && valid"
                  :error-messages="errors"
                  required>
                </v-textarea>
              </ValidationProvider>
            </v-form>
          </ValidationObserver>

          <div v-if="message.error.length > 0"
            class="text-center mt-3 mb-2 d-flex align-center">
            <div
              class="error--text">
              <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

              <span
                class="ml-1">
                {{ message.error }}
              </span>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <v-card
        flat
        color="#FFFFFF"
        min-height="50"
        style="border-radius: 15px 15px 0px 0px;
              position: sticky;
              bottom: 0;
              width: 460px;
              z-index: 1;">
        <v-card-text
          class="pa-4">
          <v-row
            align="center">
            <v-col
              cols="12"
              class="py-0">
              <div
                class="d-flex align-start">
                <!-- <v-checkbox
                  v-model="form.is_done"
                  :disabled="status_required.absen || status_required.absen_detail || status_required.lampiran"
                  color="green"
                  class="ma-0"
                  hide-details>
                </v-checkbox> -->

                <div
                  class="body-2 line-text-second">
                  <div>
                    Apakah anda yakin data diatas sudah benar & dipastikan tidak ada tambahan?
                  </div>

                  <div>
                    Jika sudah di klik maka akan terkirim ke WA bundanya feedback & link review (khusus laporan boleh diisi nanti yg penting tidak boleh lewat dari jam 12 malam)
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row
            class="mt-4">
            <v-col
              cols="7"
              class="pb-0">
              <v-btn
                v-on:keyup.enter="save()"
                @click="save()"
                :loading="process.form"
                :disabled="process.form"

                large
                block
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in">
                  <v-icon
                    size="20"
                    style="position: absolute; left: 8px; top: 0; bottom: 0;">
                    mdi-check-bold
                  </v-icon>

                  Selesai Treatment
                </div>
              </v-btn>
            </v-col>

            <v-col
              cols="5"
              class="pb-0">
              <v-btn
                @click="$emit('close')"
                :disabled="process.form"

                large
                block
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20"
                    style="position: absolute; left: 8px; top: 0; bottom: 0;">
                    mdi-close-thick
                  </v-icon>

                  Batal
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </section>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      },
      status_required: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        sheet: {
          form: false
        },

        form: {
          visit_id: '',
          catatan_kejadian: '',
          perihal_kesehatan: '',
          pertanyaan_kepo: '',
          kesalahan_therapist: '',
          komplain: '',
          masukan: '',
          promosi: ''
        },

        process: {
          form: false
        },

        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.reset()
    },
    methods: {
      reset () {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          visit_id: this.detail.id,
          catatan_kejadian: this.detail.laporan_catatan_kejadian,
          perihal_kesehatan: this.detail.laporan_perihal_kesehatan,
          pertanyaan_kepo: this.detail.laporan_pertanyaan_kepo,
          kesalahan_therapist: this.detail.laporan_kesalahan_therapist,
          komplain: this.detail.laporan_komplain,
          masukan: this.detail.laporan_masukan,
          promosi: this.detail.laporan_promosi
        }

        this.process.form = false

        this.message.error = ''
      },

      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}therapist/schedule_v3/laporan/update`, this.form)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {
              this.$emit('close')
            } else {
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>
