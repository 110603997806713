<template>
  <div class="fill-height pt-14">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.referensi"
          persistent>
          <v-card v-if="form.payment.length > 0"
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar 
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span 
                class="body-1 font-weight-bold text-capitalize ml-2">
                Nomor Referensi
              </span>
            </v-card-title>
            
            <v-divider 
              class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="body-2 text-first line-text-second mt-4">
              <v-text-field
                filled
                rounded
                dense
                :color="set_color"
                placeholder="Contoh. 1234567890"

                hide-details

                v-model.number="list.payment[selected.menu].data.method_ref">
              </v-text-field>
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.referensi = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon 
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>
              
              <v-btn
                @click="dialog.referensi = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon 
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.bank"
          persistent
          scrollable>
          <v-card v-if="form.payment.length > 0"
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar 
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span 
                class="body-1 font-weight-bold text-capitalize ml-2">
                Pilih Bank
              </span>
            </v-card-title>
            
            <v-divider 
              class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="body-2 text-first line-text-second mt-3">
              <v-row>
                <v-col
                  cols="12"
                  class="py-0">
                  <v-list
                    v-for="(item, index) in list.bank"
                    :key="index"
                    flat
                    dense
                    color="#FFFFFF40"
                    class="border-radius py-0">
                    <v-list-item
                      @click="list.payment[selected.menu].data.method_bank_name = item.text"
                      class="px-0">
                      <v-list-item-avatar
                        size="40"
                        tile>
                        <v-img 
                          contain
                          width="100%"
                          height="100%"
                          alt="bidanvitacare" 
                          :src="item.icon !== '' ? item.icon : require('@/assets/image/image_default.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                size="20"
                                indeterminate 
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="body-2 font-weight-bold text-capitalize">
                          {{ item.text }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action v-if="list.payment[selected.menu].data.method_bank_name === item.text">
                        <v-icon 
                          color="green"
                          size="18">
                          mdi-check-circle
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.bank = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon 
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>
              
              <v-btn
                @click="dialog.bank = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon 
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section v-if="form.payment.length > 0">
          <ValidationObserver 
            ref="form">
            <v-form>
              <v-card
                flat
                color="#FFFFFF60"
                style="border-radius: 0px;">
                <v-list
                  flat
                  dense
                  color="transparent"
                  class="pb-0">
                  <v-list-item>
                    <v-list-item-avatar
                      size="40"
                      style="border: 2px solid #ffffff; overflow: unset;">
                      <v-img 
                        width="100%"
                        height="100%"
                        alt="bidanvitacare" 
                        :src="Object.keys(selected.pelanggan).length > 0 && selected.pelanggan.image_url !== '' ? selected.pelanggan.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              size="20"
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        class="body-2 font-weight-bold text-capitalize mb-2">
                        {{ Object.keys(selected.pelanggan).length > 0 ? selected.pelanggan.name : 'Customer' }}
                      </v-list-item-title>

                      <v-list-item-subtitle
                        class="body-2 font-weight-light black--text">
                        {{ Object.keys(selected.pelanggan).length > 0 ? selected.pelanggan.mobilephone : 'Tidak ada informasi kontak' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-card-text>
                  <v-card
                    flat
                    color="#f9dee2"
                    class="border-radius">
                    <v-card-text
                      class="d-flex align-center">
                      <div
                        class="body-1 font-weight-bold">
                        Total Bayar
                      </div>

                      <v-spacer />

                      <div
                        class="title font-weight-bold black--text">
                        {{ form.amount_paid_origin | price }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="transparent"
                class="border-radius">
                <v-card-text
                  class="body-2 mt-4 pb-1">
                  Pilih multi payment
                </v-card-text>

                <no-ssr>
                  <swiper 
                    class="swiper"
                    ref="swiper"
                    :options="{ slidesPerView: 'auto' }">
                    <swiper-slide 
                      v-for="(item, index) in list.payment" 
                      :key="index" 
                      class="pt-3 loader_produk" 
                      :class="index === 0 ? 'pl-4 pr-4' : index < list.payment.length - 1 ? 'pr-4' : 'pr-4'"
                      style="max-width: 150px;">
                      <div>
                        <v-btn
                          @click="selected.menu = index; check_amount()"
                          width="118"
                          rounded
                          elevation="0"
                          color="#ececec"
                          class="body-2 font-weight-bold text-capitalize mb-2"
                          :style="selected.menu === index ? `border: 2px dashed ${set_color}; color: ${set_color};` : ''">
                          <div
                            class="d-flex align-center justify-center">
                            <div>
                              <v-img 
                                contain
                                width="20"
                                height="20"
                                alt="bidanvitacare" 
                                :src="item.icon"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      size="20"
                                      indeterminate 
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </div>

                            <div
                              class="text-capitalize ml-2">
                              {{ item.title }}
                            </div>
                          </div>
                        </v-btn>
                      </div>
                    </swiper-slide>
                  </swiper>
                </no-ssr>

                <v-card-text
                  class="py-0"
                  style="height: calc(100vh - 410px); overflow: auto;">
                  <div 
                    class="d-flex align-center mt-6"
                    :class="list.payment[selected.menu].check ? 'green--text' : ''">
                    <div
                      @click="list.payment[selected.menu].check = !list.payment[selected.menu].check ? true : false; to_check_amount();"
                      class="cursor-pointer">
                      <v-icon
                        :color="list.payment[selected.menu].check ? 'green' : ''">
                        {{ !list.payment[selected.menu].check ? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked' }}
                      </v-icon>
                    </div>

                    <div
                      @click="list.payment[selected.menu].check = !list.payment[selected.menu].check ? true : false"
                      class="body-1 font-weight-bold text-capitalize cursor-pointer ml-2">
                      {{ list.payment[selected.menu].title }}
                    </div>
                  </div>

                  <v-divider 
                    class="mt-3 mb-4"
                    style="border-top: 1px dashed #bbb;" />
                  
                  <div 
                    class="d-flex align-center">
                    <div
                      class="body-1 font-weight-bold">
                      Grand Total
                    </div>

                    <v-spacer />

                    <div
                      class="body-1 font-weight-bold"
                      :style="`color: ${set_color};`">
                      {{ form.amount_paid_sum | price }}
                    </div>
                  </div>

                  <v-divider v-if="total_bayar > 0"
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div v-if="total_bayar > 0"
                    class="d-flex align-center">
                    <div
                      class="body-1 font-weight-bold red--text">
                      Kekurangan
                    </div>

                    <v-spacer />

                    <div
                      class="body-1 font-weight-bold red--text">
                      {{ (parseInt(form.amount_paid_sum) - parseInt(total_bayar)) | price }}
                    </div>
                  </div>

                  <v-divider 
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <!-- CASH -->
                  <div v-if="selected.menu === 0"
                    :class="!list.payment[selected.menu].check ? 'screen_disabled' : ''">
                    <v-row
                      align="center">
                      <v-col
                        :cols="list.payment[selected.menu].data.amount > 0 ? 8 : 9">
                        <ValidationProvider 
                          name="list.payment[selected.menu].data.amount" 
                          rules="required"
                          v-slot="{ errors, valid }">
                          <div class="body-2 mb-2">
                            Jumlah Bayar
                          </div>
                          
                          <v-text-field
                            v-on:keyup.enter="save"
                            type="number"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                            min="0"
                            filled
                            rounded
                            dense
                            :color="set_color"
                            placeholder="Contoh. 10.000"
                            :append-icon="parseInt(list.payment[selected.menu].data.amount) > 0 && parseInt(list.payment[selected.menu].data.amount) >= parseInt(form.amount_paid_sum) && valid ? 'mdi-check-circle-outline' : ''"

                            @focus="$event.target.select()"
                            @keyup="list.payment[selected.menu].data.amount = list.payment[selected.menu].data.amount === '' || parseInt(list.payment[selected.menu].data.amount) < 1 ? 0 : parseInt(list.payment[selected.menu].data.amount)"
                            @blur="list.payment[selected.menu].data.amount = list.payment[selected.menu].data.amount === '' || parseInt(list.payment[selected.menu].data.amount) < 1 ? 0 : parseInt(list.payment[selected.menu].data.amount)"

                            v-model="list.payment[selected.menu].data.amount"
                            :success="parseInt(list.payment[selected.menu].data.amount) > 0 && parseInt(list.payment[selected.menu].data.amount) >= parseInt(form.amount_paid_sum) && valid"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>

                      <v-col
                        :cols="list.payment[selected.menu].data.amount > 0 ? 4 : 3"
                        class="d-flex align-center justify-center">
                        <div
                          @click="list.payment[selected.menu].data.amount = parseInt(form.amount_paid_sum)"
                          class="body-1 font-weight-bold cursor-pointer">
                          Sama
                        </div>

                        <div v-if="list.payment[selected.menu].data.amount > 0"
                          @click="list.payment[selected.menu].data.amount = 0"
                          class="body-1 font-weight-bold ml-3 red--text cursor-pointer">
                          Hapus
                        </div>
                      </v-col>
                    </v-row>

                    <div class="body-2 mb-2">
                      Atau pilih pecahan dibawah ini:
                    </div>

                    <v-row>
                      <v-col
                        v-for="(item, index) in list.money"
                        :key="index"
                        cols="6">
                        <v-btn
                          @click="list.payment[selected.menu].data.amount += item"
                          block
                          large
                          rounded
                          elevation="0"
                          class="body-1 font-weight-bold white--text text-capitalize btn_auth"
                          :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                          <div
                            class="d-flex align-center justify-center btn_auth_in"
                            :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                            {{ item | price }}
                          </div>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>

                  <!-- TRANSFER & EDC -->
                  <div v-if="selected.menu === 1 || selected.menu === 2"
                    :class="!list.payment[selected.menu].check ? 'screen_disabled' : ''">
                    <div 
                      class="d-flex align-center">
                      <div
                        class="body-2">
                        Nama Bank
                      </div>

                      <v-spacer />

                      <div
                        @click="dialog.bank = true"
                        class="body-2 cursor-pointer"
                        :style="`color: ${set_color};`">
                        <span v-if="list.payment[selected.menu].data.method_bank_name === ''">
                          Pilih
                        </span>

                        <span v-else>
                          <span 
                            class="d-flex align-center">
                            <v-icon :color="set_color" size="14">mdi-circle-edit-outline</v-icon>

                            <span
                              class="font-weight-bold ml-1">
                              {{ list.payment[selected.menu].data.method_bank_name }}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>

                    <v-divider 
                      class="mt-3 mb-4"
                      style="border-top: 1px dashed #bbb;" />

                    <div 
                      class="d-flex align-center">
                      <div
                        class="body-2">
                        Nomor Referensi
                      </div>

                      <v-spacer />

                      <div
                        @click="dialog.referensi = true"
                        class="body-2 cursor-pointer"
                        :style="`color: ${set_color};`">
                        <span v-if="list.payment[selected.menu].data.method_ref === ''">
                          Tambahkan
                        </span>

                        <span v-else>
                          <span 
                            class="d-flex align-center">
                            <v-icon :color="set_color" size="14">mdi-circle-edit-outline</v-icon>

                            <span
                              class="font-weight-bold ml-1">
                              {{ list.payment[selected.menu].data.method_ref }}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>

                    <v-divider
                      class="mt-3 mb-8"
                      style="border-top: 1px dashed #bbb;" />

                    <ValidationProvider 
                      name="list.payment[selected.menu].data.amount" 
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        Jumlah Bayar
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                        min="0"
                        filled
                        rounded
                        dense
                        :color="set_color"
                        placeholder="Contoh. 10.000"
                        :append-icon="parseInt(list.payment[selected.menu].data.amount) > 0 && parseInt(list.payment[selected.menu].data.amount) >= parseInt(form.amount_paid_sum) && valid ? 'mdi-check-circle-outline' : ''"

                        @focus="$event.target.select()"
                        @keyup="list.payment[selected.menu].data.amount = list.payment[selected.menu].data.amount === '' || parseInt(list.payment[selected.menu].data.amount) < 1 ? 0 : parseInt(list.payment[selected.menu].data.amount)"
                        @blur="list.payment[selected.menu].data.amount = list.payment[selected.menu].data.amount === '' || parseInt(list.payment[selected.menu].data.amount) < 1 ? 0 : parseInt(list.payment[selected.menu].data.amount)"

                        v-model="list.payment[selected.menu].data.amount"
                        :success="parseInt(list.payment[selected.menu].data.amount) > 0 && parseInt(list.payment[selected.menu].data.amount) >= parseInt(form.amount_paid_sum) && valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </div>

                  <!-- QRIS & SHOPEEPAY -->
                  <div v-if="selected.menu === 3 || selected.menu === 4"
                    :class="!list.payment[selected.menu].check ? 'screen_disabled' : ''">
                    <div 
                      class="d-flex align-center">
                      <div
                        class="body-2">
                        Nomor Referensi
                      </div>

                      <v-spacer />

                      <div
                        @click="dialog.referensi = true"
                        class="body-2 cursor-pointer"
                        :style="`color: ${set_color};`">
                        <span v-if="list.payment[selected.menu].data.method_ref === ''">
                          Tambahkan
                        </span>

                        <span v-else>
                          <span 
                            class="d-flex align-center">
                            <v-icon :color="set_color" size="14">mdi-circle-edit-outline</v-icon>

                            <span
                              class="font-weight-bold ml-1">
                              {{ list.payment[selected.menu].data.method_ref }}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>

                    <v-divider
                      class="mt-3 mb-8"
                      style="border-top: 1px dashed #bbb;" />

                    <ValidationProvider 
                      name="list.payment[selected.menu].data.amount" 
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        Jumlah Bayar
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save"
                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                        min="0"
                        filled
                        rounded
                        dense
                        :color="set_color"
                        placeholder="Contoh. 10.000"
                        :append-icon="parseInt(list.payment[selected.menu].data.amount) > 0 && parseInt(list.payment[selected.menu].data.amount) >= parseInt(form.amount_paid_sum) && valid ? 'mdi-check-circle-outline' : ''"

                        @focus="$event.target.select()"
                        @keyup="list.payment[selected.menu].data.amount = list.payment[selected.menu].data.amount === '' || parseInt(list.payment[selected.menu].data.amount) < 1 ? 0 : parseInt(list.payment[selected.menu].data.amount)"
                        @blur="list.payment[selected.menu].data.amount = list.payment[selected.menu].data.amount === '' || parseInt(list.payment[selected.menu].data.amount) < 1 ? 0 : parseInt(list.payment[selected.menu].data.amount)"

                        v-model="list.payment[selected.menu].data.amount"
                        :success="parseInt(list.payment[selected.menu].data.amount) > 0 && parseInt(list.payment[selected.menu].data.amount) >= parseInt(form.amount_paid_sum) && valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </div>
                </v-card-text>
              </v-card>
              
              <v-card 
                flat
                color="#FFFFFF"
                min-height="50"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row>
                    <v-col 
                      cols="7" 
                      class="py-0">
                      <v-btn 
                        @click="save()"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in d-flex align-center justify-center">
                          <v-icon color="white">mdi-check-circle</v-icon>

                          <span
                            class="ml-2">
                            Simpan Transaksi
                          </span>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col 
                      cols="5" 
                      class="py-0">
                      <v-btn
                        :to="`/transaksi/payment/${$route.params.id}`"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon 
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-arrow-left-circle
                          </v-icon>

                          <span
                            class="ml-8">
                            Kembali
                          </span>
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>
          </ValidationObserver>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          bank: false,
          referensi: false
        },

        list: {
          payment: [
            {
              title: 'cash',
              icon: require('@/assets/payment/cash.png'),
              desc: 'Pembayaran Cash bisa langsung dengan memberikan sejumlah uang tunai.',
              active: false,
              check: false,
              data: {
                id: '',
                amount: 0,
                method: '',
                method_bank_name: '',
                method_ref: ''
              }
            },
            {
              title: 'transfer',
              icon: require('@/assets/payment/transfer.png'),
              desc: 'Pembayaran Transfer melalui Bank, ATM, Internet Banking atau Mobile Banking.',
              active: false,
              check: false,
              data: {
                id: '',
                amount: 0,
                method: '',
                method_bank_name: '',
                method_ref: ''
              }
            },
            {
              title: 'edc',
              icon: require('@/assets/payment/edc.png'),
              desc: 'Pembayaran melalui mesin EDC yang diterbitkan oleh Bank.',
              active: false,
              check: false,
              data: {
                id: '',
                amount: 0,
                method: '',
                method_bank_name: '',
                method_ref: ''
              }
            },
            {
              title: 'qris',
              icon: require('@/assets/payment/qris.png'),
              desc: 'Pembayaran Qris dilakukan dengan menscan kode QR yang disediakan.',
              active: false,
              check: false,
              data: {
                id: '',
                amount: 0,
                method: '',
                method_bank_name: '',
                method_ref: ''
              }
            },
            {
              title: 'shopeepay',
              icon: require('@/assets/payment/shopeepay.png'),
              desc: 'Pembayaran dapat dilakukan melalui Shopeepay.',
              active: false,
              check: false,
              data: {
                id: '',
                amount: 0,
                method: '',
                method_bank_name: '',
                method_ref: ''
              }
            }
          ],
          money: [1000, 5000, 10000, 20000, 50000, 100000],
          bank: [
            {
              text: 'BRI',
              icon: require('@/assets/bank/bri.png')
            },
            {
              text: 'Mandiri',
              icon: require('@/assets/bank/mandiri.png')
            }
          ]
        },

        selected: {
          menu: 0,

          pelanggan: {},
          payment: {}
        },

        total_bayar: 0,

        form: {
          id: 0,

          amount_paid_origin: 0,
          amount_paid_sum: 0,
          amount_paid: 0,

          payment: []
        },

        process: {
          load: false,

          form: false
        },

        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/transaksi/payment/multi/:id',
          title: 'Ubah Metode Pembayaran',
          description: 'Ubah Metode Pembayaran Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {
      
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetchDetail()
    },
    methods: {
      async fetchDetail () {
        this.process.load = true

        let params = {
          id: this.$route.params.id
        }

        await this.$axios.$get(`${process.env.API}admin/order/detail`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            let data = response.results.data

            this.form = {
              id: data.id,
              
              amount_paid_origin: data.amount_paid,
              amount_paid_sum: data.amount_paid,
              amount_paid: data.amount_paid,
              
              payment: data.payments
            }

            this.selected.pelanggan = {
              name: data.customer_name,
              mobilephone: data.customer_mobilephone,
              image_url: data.customer_image_url
            }

            data.payments.forEach((item, index) => {
              if (item.method === 'cash') {
                this.list.payment[0].check = true
                this.list.payment[0].data = {
                  id: item.id,
                  amount: item.amount,
                  method: item.method,
                  method_bank_name: item.method_bank_name,
                  method_ref: item.method_ref
                }
              }

              if (item.method === 'transfer') {
                this.list.payment[1].check = true
                this.list.payment[1].data = {
                  id: item.id,
                  amount: item.amount,
                  method: item.method,
                  method_bank_name: item.method_bank_name,
                  method_ref: item.method_ref
                }
              }

              if (item.method === 'edc') {
                this.list.payment[2].check = true
                this.list.payment[2].data = {
                  id: item.id,
                  amount: item.amount,
                  method: item.method,
                  method_bank_name: item.method_bank_name,
                  method_ref: item.method_ref
                }
              }

              if (item.method === 'qris') {
                this.list.payment[3].check = true
                this.list.payment[3].data = {
                  id: item.id,
                  amount: item.amount,
                  method: item.method,
                  method_bank_name: item.method_bank_name,
                  method_ref: item.method_ref
                }
              }

              if (item.method === 'shopeepay') {
                this.list.payment[4].check = true
                this.list.payment[4].data = {
                  id: item.id,
                  amount: item.amount,
                  method: item.method,
                  method_bank_name: item.method_bank_name,
                  method_ref: item.method_ref
                }
              }
            })
          }
        })
      },

      to_check_amount () {
        if (!this.list.payment[this.selected.menu].check) {
          this.list.payment[this.selected.menu].data = {
            id: '',
            amount: 0,
            method: '',
            method_bank_name: '',
            method_ref: ''
          }

          this.check_amount()
        }
      },

      check_amount () {
        let item = this.list.payment

        this.total_bayar = 0

        item.map(obj => {
          this.total_bayar += obj.data.amount
        })
      },

      async save () {
        this.message.error = ''
  
        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          this.form.payment = []

          let payment_selected = this.list.payment

          payment_selected.map(obj => {
            if (obj.check) {
              obj.data.method = obj.title

              this.form.payment.push(obj.data)
            }
          })

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}admin/order/update-payment`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.$router.push({ path: '/transaksi' })
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>