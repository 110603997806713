<template>
  <div>
    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text 
          class="pb-0 mt-1">
          <v-text-field
            v-model="search"
            @click:clear="search = ''; fetch();"
            v-on:keyup.enter="fetch()"
            filled
            rounded
            dense 
            single-line
            hide-details
            :color="set_color"
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari data yang Anda butuhkan ?"
            clearable>
          </v-text-field>
        </v-card-text>

        <v-card-text
          class="pb-0">
          <v-row>
            <v-col
              cols="12">
              <v-skeleton-loader
                v-for="(item, index) in process.load ? 4 : list"
                :key="index"
                :loading="process.load"
                :class="index < list.length - 1 ? 'mb-4' : ''"
                type="list-item-avatar-three-line">
                <template>
                  <div>
                    <v-list
                      v-if="!process.load && list.length > 0"
                      flat
                      dense
                      three-line
                      color="#FFFFFF40"
                      class="border-radius">
                      <v-list-item
                        @click="sheet.detail = true; fetchDetail(item.id);">
                        <v-list-item-content>
                          <v-list-item-title
                            class="body-2 font-weight-bold d-flex align-center">
                            <v-avatar
                              size="25"
                              style="border: 2px solid #ffffff;">
                              <v-img 
                                width="100%"
                                height="100%"
                                alt="bidanvitacare" 
                                :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      size="20"
                                      indeterminate 
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <span
                              class="text-capitalize ml-2">
                              {{ item.customer_name }}
                            </span>
                          </v-list-item-title>

                          <v-list-item-subtitle
                            class="body-2 font-weight-light line-text-second black--text my-2 py-2"
                            style="border-top: 1px dashed #bbb; border-bottom: 1px dashed #bbb;">
                            <div
                              class="d-flex align-center">
                              <div>
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  Tipe
                                </div>

                                <div
                                  class="body-2 black--text">
                                  {{ item.type === 'on_site' ? 'On Site' : 'Home Care' }}
                                </div>
                              </div>

                              <v-spacer />

                              <div>
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  Umur
                                </div>

                                <div
                                  class="body-2 black--text">
                                  {{ item.age !== '' ? item.age : '-' }}
                                </div>
                              </div>
                            </div>

                            <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />

                            <div
                              class="d-flex align-center">
                              <div>
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  BB
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.bb }} Kg
                                </div>
                              </div>

                              <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                              <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  TB
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.tb }} Cm
                                </div>
                              </div>

                              <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                              <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  LILA
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.lila }} Cm
                                </div>
                              </div>

                              <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                              <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  LK
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.lk }} Cm
                                </div>
                              </div>

                              <v-spacer v-if="item.customer_type !== 'bayi' && item.customer_type !== 'anak'" />

                              <div v-if="item.customer_type !== 'bayi' && item.customer_type !== 'anak'">
                                <div
                                  class="caption"
                                  style="opacity: 60%; margin-bottom: 1px;">
                                  Tensi
                                </div>

                                <div
                                  class="black--text font-weight-bold"
                                  style="font-size: 0.8125rem;">
                                  {{ item.tensi }} mmHg
                                </div>
                              </div>
                            </div>

                            <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />

                            <div
                              class="d-flex align-center">
                              <div
                                class="caption"
                                style="opacity: 60%;">
                                Klien
                              </div>

                              <v-spacer />

                              <div>
                                <div
                                  class="d-flex align-center cursor-pointer">
                                  <v-avatar
                                    v-for="(item_customer, index_customer) in item.patner"
                                    :key="index_customer"
                                    v-show="index_customer < 2"
                                    size="24"
                                    class="mt-0"
                                    style="border: 2px solid #ffffff;"
                                    :style="item.patner.length === 2 && index_customer === 0 ? 'position: absolute; right: 32px; z-index: 2;' : 
                                            item.patner.length > 2 && index_customer === 0 ? 'position: absolute; right: 50px; z-index: 2;' : 
                                            item.patner.length > 2 && index_customer === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                                    <v-img 
                                      width="100%"
                                      height="100%"
                                      alt="bidanvitacare" 
                                      :src="item_customer.image_url !== '' ? item_customer.image_url : require('@/assets/image/member_default.jpg')"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular 
                                            size="20"
                                            indeterminate 
                                            :color="set_color">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>

                                  <span v-if="item.patner.length < 2"
                                    class="caption font-weight-bold one-line ml-1"
                                    style="width: 50px;">
                                    {{ item.patner[0].name }}
                                  </span>

                                  <span v-if="item.patner.length > 2"
                                    class="caption font-weight-bold ml-1">
                                    +{{ item.patner.length - 2 }}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <!-- <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />

                            <div
                              class="d-flex align-center">
                              <div
                                class="caption"
                                style="opacity: 60%;">
                                Terapis
                              </div>

                              <v-spacer />

                              <div>
                                <div
                                  class="d-flex align-center cursor-pointer">
                                  <v-avatar
                                    v-for="(item_therapist, index_therapist) in item.therapist"
                                    :key="index_therapist"
                                    v-show="index_therapist < 2"
                                    size="24"
                                    class="mt-0"
                                    style="border: 2px solid #ffffff;"
                                    :style="item.therapist.length === 2 && index_therapist === 0 ? 'position: absolute; right: 32px; z-index: 2;' : 
                                            item.therapist.length > 2 && index_therapist === 0 ? 'position: absolute; right: 50px; z-index: 2;' : 
                                            item.therapist.length > 2 && index_therapist === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                                    <v-img 
                                      width="100%"
                                      height="100%"
                                      alt="bidanvitacare" 
                                      :src="item_therapist.image_url !== '' ? item_therapist.image_url : require('@/assets/image/member_default.jpg')"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular 
                                            size="20"
                                            indeterminate 
                                            :color="set_color">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>

                                  <span v-if="item.therapist.length < 2"
                                    class="caption font-weight-bold one-line ml-1"
                                    style="width: 50px;">
                                    {{ item.therapist[0].name }}
                                  </span>

                                  <span v-if="item.therapist.length > 2"
                                    class="caption font-weight-bold ml-1">
                                    +{{ item.therapist.length - 2 }}
                                  </span>
                                </div>
                              </div>
                            </div> -->
                          </v-list-item-subtitle>

                          <v-list-item-subtitle
                            class="d-flex align-center">
                            <div>
                              <div
                                class="caption mb-1">
                                Tanggal & Waktu Kunjungan
                              </div>

                              <div
                                class="black--text">
                                {{ item.datetime | date }} Pukul {{ item.datetime | time }} 
                              </div>
                            </div>

                            <v-spacer />

                            <div>
                              <div
                                class="caption mb-1">
                                {{ item.customer_wali_status }}
                              </div>

                              <div
                                class="black--text">
                                {{ item.customer_wali_name }}
                              </div>
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </template>
              </v-skeleton-loader>
            </v-col>
          </v-row>

          <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
            class="text-center my-4">
            <v-btn
              @click="limit += 10"
              :loading="process.limit"
              small
              rounded
              outlined
              elevation="0"
              :color="set_color"
              class="text-capitalize"
              :style="`color: ${set_color};`">
              Lebih banyak
            </v-btn>
          </div>

          <Empty v-if="!process.load && pagination.total_page < 1"
            class="py-12"
            margin="my-16"
            size="125"
            message="Kunjungan" />
        </v-card-text>
      </v-card>
    </section>

    <v-bottom-sheet
      v-model="sheet.detail"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card v-if="Object.keys(detail).length > 0"
        flat
        class="background">
        <div
          class="blur">
          <v-card-title 
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon 
              @click="sheet.detail = false; detail = {}; fetch();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span 
              @click="sheet.detail = false; detail = {}; fetch();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Kunjungan {{ detail.customer_name }}
            </span>
          </v-card-title>

          <v-card v-if="Object.keys(detail).length > 0"
            flat
            class="pt-16"
            height="calc(100vh - 0px)"
            style="overflow: auto;"
            color="transparent">
            <Informasi
              :detail="detail"
              :status_required="status_required"
              @load="fetchDetail(detail.id)" />

            <v-divider
              class="mt-2 mb-4"
              style="border-top: 1px dashed #bbb;" />

            <Lampiran
              :detail="detail"
              :status_required="status_required"
              @load="fetchDetail(detail.id); count_attachment = $event;" />

            <v-divider
              class="mb-5"
              :class="count_attachment > 0 ? 'mt-2' : 'mt-5'"
              style="border-top: 1px dashed #bbb;" />

            <Antropometri 
              :detail="detail"
              :status_required="status_required"
              @load="fetchDetail(detail.id)" />

            <v-divider
              class="mb-5"
              style="border-top: 1px dashed #bbb;" />

            <Kpsp 
              :detail="detail"
              :status_required="status_required"
              @load="fetchDetail(detail.id)" />

            <v-divider
              class="mt-2 mb-5"
              style="border-top: 1px dashed #bbb;" />

            <Laporan 
              :detail="detail"
              :status_required="status_required"
              @load="fetchDetail(detail.id)"
              @close="sheet.detail = false" />
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        sheet: {
          detail: false
        },
        search: '',
        detail: {},
        list: [],
        pagination: [],

        limit: 10,

        count_attachment: 0,

        status_required: {
          absen: false,
          absen_detail: false,
          lampiran: false,
          is_done_bool: false
        },

        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty'),

      Informasi: () => import(/* webpackPrefetch: true */ '@/components/penjadwalan/step/informasi'),
      Lampiran: () => import(/* webpackPrefetch: true */ '@/components/penjadwalan/step/lampiran'),
      Antropometri: () => import(/* webpackPrefetch: true */ '@/components/penjadwalan/step/antropometri'),
      Kpsp: () => import(/* webpackPrefetch: true */ '@/components/penjadwalan/step/kpsp'),
      Laporan: () => import(/* webpackPrefetch: true */ '@/components/penjadwalan/step/laporan')
    },
    watch: {
      'limit': function() {
        this.process.limit = true
        
        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}therapist/customer-visit-today`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchDetail (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}therapist/customer-visit-today/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail = response.results.data

            if (Object.keys(this.detail).length > 0) this.required_form()
          }
        })
      },

      required_form () {
        let data = this.detail.detail,
            count_item = data.length,
            count_final = 0
        
        data.map(obj => {
          if (obj.absen_detail.length > 1) count_final++
        })

        if (this.detail.absen.length < 2 && this.detail.type === 'home_care') {
          this.status_required.absen = true
        } else {
          this.status_required.absen = false
        }

        if (count_item > count_final) {
          this.status_required.absen_detail = true
        } else {
          this.status_required.absen_detail = false
        }

        if (this.count_attachment < 1) {
          this.status_required.lampiran = true
        } else {
          this.status_required.lampiran = false
        }

        this.status_required.is_done_bool = this.detail.is_done_bool
      }
    }
  }
</script>