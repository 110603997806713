<template>
  <div class="fill-height pt-14">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.user_visit"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Terapis Dipilih
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text v-if="selected.user_index !== '' && form.items.length > 0"
              class="body-2 text-first line-text-second mt-2 px-4 pb-2">
              <v-btn
                @click="dialog.user_visit = false; dialog.user = true; fetchTerapis();"
                block
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_auth mb-2"
                :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                <div
                  class="btn_auth_in d-flex align-center justify-center"
                  :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                  <div>
                    <v-img
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/add_plus.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <div
                    class="ml-2">
                    Tambah Terapis
                  </div>
                </div>
              </v-btn>

              <v-row>
                <v-col
                  v-for="(item, index) in form.items[selected.user_index].visit"
                  :key="index"
                  cols="12">
                  <v-list
                    v-if="form.items[selected.user_index].visit.length > 0"
                    flat
                    dense
                    color="#FFFFFF40"
                    class="border-radius"
                    style="border: 1px dashed #bbb;">
                    <v-list-item>
                      <v-list-item-avatar
                        size="40"
                        style="border: 2px solid #ffffff;">
                        <v-img
                          width="100%"
                          height="100%"
                          alt="bidanvitacare"
                          :src="item.user_image_url !== '' ? item.user_image_url : require('@/assets/image/member_default.jpg')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="body-2 font-weight-bold text-capitalize">
                          {{ item.user_name }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-icon
                        @click="form.items[selected.user_index].visit.splice(index, 1)"
                        class="cursor-pointer"
                        color="red"
                        size="18"
                        style="position: absolute; right: 3px; top: -4px;">
                        mdi-close-circle
                      </v-icon>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>

              <Empty v-if="form.items[selected.user_index].visit.length < 1"
                margin="my-8"
                size="125"
                message="Terapis" />
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.user_visit = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.user_visit = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.user"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Terapis
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-2 px-4 pb-2">
              <v-text-field
                v-model="search_terapis"
                @click:clear="search_terapis = ''; fetchTerapis();"
                v-on:keyup.enter="fetchTerapis()"
                filled
                rounded
                dense
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data terapis ?"
                clearable
                class="mb-2">
              </v-text-field>

              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.terapis ? 5 : list.terapis"
                    :key="index"
                    :loading="process.terapis"
                    :class="index < list.terapis.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.terapis && list.terapis.length > 0"
                          flat
                          dense
                          two-line
                          color="#FFFFFF40"
                          class="border-radius"
                          :style="form.items.length > 0 && selected.user_terapis_index === index ? 'border: 2px dashed #ff8080;' : 'border: 1px dashed #bbb;'">
                          <v-list-item
                            @click="selected.user_terapis_item = item; selected.user_terapis_index = index;">
                            <v-list-item-avatar
                              size="40"
                              class="mt-0"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                {{ item.name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light text-capitalize line-text-second black--text"
                                style="opacity: 70%;">
                                {{ item.role }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="body-2">
                                Telp. {{ item.mobilephone }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <Empty v-if="!process.terapis && pagination.total_page < 1"
                margin="my-8"
                size="125"
                message="Terapis" />
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.user = false; dialog.user_visit = true; addTerapis(selected.user_terapis_item, selected.user_terapis_index)"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.user = false; dialog.user_visit = true;"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.address"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Alamat
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-2 px-4 pb-2">
              <v-text-field
                v-model="search_address"
                @click:clear="search_address = ''; fetchAddress();"
                v-on:keyup.enter="fetchAddress()"
                filled
                rounded
                dense
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data alamat ?"
                clearable
                class="mb-2">
              </v-text-field>

              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.address ? 5 : list.address"
                    :key="index"
                    :loading="process.address"
                    :class="index < list.address.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.address && list.address.length > 0"
                          flat
                          dense
                          two-line
                          color="#FFFFFF40"
                          class="border-radius"
                          :style="form.customer_address_id === item.id ? 'border: 2px dashed #ff8080;' : 'border: 1px dashed #bbb;'">
                          <v-list-item
                            @click="addAddress(item)">
                            <v-list-item-avatar
                              tile
                              size="40"
                              class="mt-0"
                              style="border: 2px solid #ffffff; border-radius: 5px !important;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                {{ item.address_full }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light line-text-second black--text"
                                style="opacity: 70%;">
                                {{ item.note !== '' ? item.note : '-' }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light line-text-second black--text"
                                style="opacity: 70%;">
                                {{ item.subdistrict_name }}, {{ item.city_name }}, {{ item.province_name }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <Empty v-if="!process.terapis && pagination.total_page < 1"
                margin="my-8"
                size="125"
                message="Alamat" />
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.address = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.address = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.date"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Tanggal
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3">
              <div>
                <div class="body-2 mb-2">
                  <span class="red--text">*</span>
                  Tanggal
                  <!-- <span class="error--text">
                    (wajib diisi)
                  </span> -->
                </div>

                <v-menu
                  ref="date"
                  min-width="290px"
                  max-width="290px"
                  transition="scale-transition"
                  offset-y
                  :nudge-right="40"
                  :close-on-content-click="false"
                  v-model="picker.date">
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider
                      name="Tanggal"
                      rules="required"
                      v-slot="{ errors, valid }">
                      <v-text-field
                        v-on:keyup.enter="save"
                        readonly
                        filled
                        rounded
                        dense
                        :color="set_color"
                        placeholder="Contoh. 2023-01-01"

                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        clearable
                        @click:clear="form.date = ''"
                        v-on="on"
                        v-bind="attrs"

                        v-model="form.date"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </template>

                  <v-date-picker
                    :color="set_color"
                    v-model="form.date"
                    @input="picker.date = false">
                  </v-date-picker>
                </v-menu>
              </div>

              <div>
                <div class="body-2 mb-2">
                  <!-- <span class="red--text">*</span> -->
                  Waktu
                  <!-- <span class="error--text">
                    (wajib diisi)
                  </span> -->
                </div>

                <v-menu
                  ref="time"
                  v-model="picker.time"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="form.time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider
                      name="Waktu"
                      rules="required"
                      v-slot="{ errors, valid }">
                      <v-text-field
                        v-on:keyup.enter="save"
                        readonly
                        filled
                        rounded
                        dense
                        :color="set_color"
                        placeholder="Contoh. 09:00"

                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        clearable
                        @click:clear="form.time = ''"
                        v-on="on"
                        v-bind="attrs"

                        hide-details

                        v-model="form.time"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </template>

                  <v-time-picker
                    v-if="picker.time"
                    v-model="form.time"
                    full-width
                    :color="set_color"
                    format="24hr"
                    @click:minute="$refs.time.save(`${form.time}:00`)">
                  </v-time-picker>
                </v-menu>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.date = false; form.datetime = `${form.date} ${form.time}`;"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.date = false; form.datetime = ''; form.date = ''; form.time = '';"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.wali"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Pendamping
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3">
              <div>
                <div class="body-2 mb-2">
                  Nama Pendamping
                </div>

                <v-text-field
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. John Doe"

                  v-model="form.wali">
                </v-text-field>
              </div>

              <div>
                <div class="body-2 mb-2">
                  No. Handphone Pendamping
                </div>

                <v-text-field
                  type="number"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                  min="0"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 081332XXXXXX"

                  v-model="form.mobilephone_wali">
                </v-text-field>
              </div>

              <div>
                <div class="body-2 mb-2">
                  No. Handphone Lainnya
                </div>

                <v-text-field
                  type="number"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                  min="0"
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. 085702XXXXXX"

                  hide-details

                  v-model="form.mobilephone_other">
                </v-text-field>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.wali = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.wali = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.user_partner"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Klien Dipilih
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text v-if="selected.partner_index !== '' && form.items.length > 0"
              class="body-2 text-first line-text-second mt-2 px-4 pb-2">
              <v-btn
                @click="dialog.user_partner = false; dialog.partner = true; fetchPelanggan();"
                block
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_auth mb-2"
                :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                <div
                  class="btn_auth_in d-flex align-center justify-center"
                  :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                  <div>
                    <v-img
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/add_plus.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <div
                    class="ml-2">
                    Tambah Klien
                  </div>
                </div>
              </v-btn>

              <v-row>
                <v-col
                  v-for="(item, index) in form.items[selected.partner_index].customer"
                  :key="index"
                  cols="12">
                  <v-list
                    v-if="form.items[selected.partner_index].customer.length > 0"
                    flat
                    dense
                    color="#FFFFFF40"
                    class="border-radius"
                    style="border: 1px dashed #bbb;">
                    <v-list-item>
                      <v-list-item-avatar
                        size="40"
                        style="border: 2px solid #ffffff;">
                        <v-img
                          width="100%"
                          height="100%"
                          alt="bidanvitacare"
                          :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="body-2 font-weight-bold text-capitalize">
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-icon
                        @click="form.items[selected.partner_index].customer.splice(index, 1)"
                        class="cursor-pointer"
                        color="red"
                        size="18"
                        style="position: absolute; right: 3px; top: -4px;">
                        mdi-close-circle
                      </v-icon>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>

              <Empty v-if="form.items[selected.partner_index].customer.length < 1"
                margin="my-8"
                size="125"
                message="Klien" />
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.user_partner = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.user_partner = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.partner"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Klien
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-2 px-4 pb-2">
              <v-text-field
                v-model="search_customer"
                @click:clear="search_customer = ''; fetchPelanggan();"
                v-on:keyup.enter="fetchPelanggan()"
                filled
                rounded
                dense
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data klien ?"
                clearable
                class="mb-2">
              </v-text-field>

              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list.pelanggan"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.pelanggan.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list.pelanggan.length > 0"
                          flat
                          dense
                          two-line
                          color="#FFFFFF40"
                          class="border-radius"
                          :style="form.items.length > 0 && selected.user_partner_index === index ? 'border: 2px dashed #ff8080;' : 'border: 1px dashed #bbb;'">
                          <v-list-item
                            @click="selected.user_partner_item = item; selected.user_partner_index = index;">
                            <v-list-item-avatar
                              size="40"
                              class="mt-0"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                {{ item.name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light text-capitalize line-text-second black--text"
                                style="opacity: 70%;">
                                {{ item.email }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="body-2">
                                Telp. {{ item.mobilephone }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <div v-if="!process.load && pagination_pelanggan.total_data > 10 && limit_pelanggan < pagination_pelanggan.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit_pelanggan += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination_pelanggan.total_page < 1"
                margin="my-8"
                size="125"
                message="Klien" />
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.partner = false; dialog.user_partner = true; addPartner(selected.user_partner_item, selected.user_partner_index)"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.partner = false; dialog.user_partner = true;"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.go_back"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin akan keluar
                </div>

                <div>
                  dari halaman ini?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                to="/jadwal"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Keluar
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.go_back = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.saldo"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Down Payment
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3 pb-2">
              <ValidationObserver
                ref="form">
                <v-form>
                  <ValidationProvider
                    name="Down Payment"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <!-- <span class="red--text">*</span> -->
                      Down Payment
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save"
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                      min="0"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 100.000"
                      :append-icon="valid && form.dp !== '' ? 'mdi-check-circle-outline' : ''"

                      @focus="$event.target.select()"
                      @blur="form.dp = form.dp === '' ? 0 : form.dp"

                      v-model="form.dp"
                      :success="valid && form.dp !== ''"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Metode Pembayaran"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <!-- <span class="red--text">*</span> -->
                      Metode Pembayaran
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-select
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. Cash"

                      :items="config.payment"
                      item-text="title"
                      item-value="title"
                      :item-color="set_color"

                      @change="form.dp_payment_method_bank_name = ''; form.dp_payment_method_ref = '';"

                      clearable
                      @click:clear="form.dp_payment_method = ''; form.dp_payment_method_bank_name = ''; form.dp_payment_method_ref = '';"

                      :append-icon="valid && form.dp_payment_method !== '' && form.dp_payment_method !== null ? 'mdi-check-circle-outline' : ''"
                      v-model="form.dp_payment_method"
                      :success="valid && form.dp_payment_method !== '' && form.dp_payment_method !== null"
                      :error-messages="errors"
                      required>
                      <template v-slot:selection="{ item }">
                        <v-avatar
                          tile
                          size="20">
                          <v-img
                            alt="bidanvitacare"
                            :src="item.icon">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>

                        <span
                          class="body-2 text-capitalize ml-2">
                          {{ item.title }}
                        </span>
                      </template>

                      <template v-slot:item="{ item }">
                        <v-avatar
                          tile
                          size="20">
                          <v-img
                            alt="bidanvitacare"
                            :src="item.icon">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>

                        <span
                          class="body-2 text-capitalize ml-2">
                          {{ item.title }}
                        </span>
                      </template>
                    </v-select>
                  </ValidationProvider>

                  <ValidationProvider v-if="form.dp_payment_method === 'transfer' || form.dp_payment_method === 'edc'"
                    name="Bank"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <!-- <span class="red--text">*</span> -->
                      Bank
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-select
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. BCA"

                      :items="config.bank"
                      item-text="text"
                      item-value="text"
                      :item-color="set_color"

                      :append-icon="valid && form.dp_payment_method_bank_name !== '' && form.dp_payment_method_bank_name !== null ? 'mdi-check-circle-outline' : ''"
                      v-model="form.dp_payment_method_bank_name"
                      :success="valid && form.dp_payment_method_bank_name !== '' && form.dp_payment_method_bank_name !== null"
                      :error-messages="errors"
                      required>
                      <template v-slot:selection="{ item }">
                        <v-avatar
                          tile
                          size="20">
                          <v-img
                            alt="bidanvitacare"
                            :src="item.icon">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>

                        <span
                          class="body-2 text-capitalize ml-2">
                          {{ item.text }}
                        </span>
                      </template>

                      <template v-slot:item="{ item }">
                        <v-avatar
                          tile
                          size="20">
                          <v-img
                            alt="bidanvitacare"
                            :src="item.icon">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>

                        <span
                          class="body-2 text-capitalize ml-2">
                          {{ item.text }}
                        </span>
                      </template>
                    </v-select>
                  </ValidationProvider>

                  <ValidationProvider v-if="form.dp_payment_method !== '' && form.dp_payment_method !== null && form.dp_payment_method !== 'cash'"
                    name="Nomor Referensi"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <!-- <span class="red--text">*</span> -->
                      Nomor Referensi
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save"
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                      min="0"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 1234567890"
                      :append-icon="valid && form.dp_payment_method_ref !== '' && form.dp_payment_method_ref !== null ? 'mdi-check-circle-outline' : ''"

                      v-model="form.dp_payment_method_ref"
                      :success="valid && form.dp_payment_method_ref !== '' && form.dp_payment_method_ref !== null"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="save_dp"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.saldo = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="#FFFFFF60"
            style="border-radius: 0px;">
            <v-list
              flat
              dense
              color="transparent">
              <v-list-item>
                <v-list-item-avatar
                  size="40"
                  style="border: 2px solid #ffffff; overflow: unset;">
                  <v-img
                    width="100%"
                    height="100%"
                    alt="bidanvitacare"
                    :src="Object.keys(selected.pelanggan).length > 0 && selected.pelanggan.image_url !== '' ? selected.pelanggan.image_url : require('@/assets/image/member_default.jpg')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="20"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <v-icon v-if="Object.keys(selected.pelanggan).length > 0"
                    @click="selected.pelanggan = {}; customer_id = '';"
                    class="cursor-pointer"
                    color="red"
                    size="18"
                    style="position: absolute; left: -15px; top: -15px;">
                    mdi-close-circle
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    class="body-2 font-weight-bold text-capitalize mb-2">
                    <!-- {{ Object.keys(selected.pelanggan).length > 0 ? selected.pelanggan.name : 'Umum' }} -->
                    {{ Object.keys(selected.pelanggan).length > 0 ? selected.pelanggan.name : 'Customer' }}
                  </v-list-item-title>

                  <v-list-item-subtitle
                    class="body-2 font-weight-light black--text">
                    <span v-if="Object.keys(selected.pelanggan).length > 0">
                      {{ selected.pelanggan.mobilephone | phone }}
                    </span>

                    <span v-else>
                      Tidak ada informasi kontak
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <div
                    class="d-flex align-center">
                    <v-icon
                      @click="sheet.pelanggan = true; fetchPelanggan();"
                      class="cursor-pointer"
                      :color="set_color">
                      mdi-account-search
                    </v-icon>

                    <v-icon
                      @click="dialog.wali = true"
                      class="cursor-pointer ml-2">
                      mdi-account-child
                    </v-icon>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-card-text
              class="d-flex align-center item_type">
              <div>
                Jml Item (<strong>{{ form.items.length }}</strong>)
              </div>

              <v-spacer />

              <div>
                <div
                  class="d-flex align-center">
                  <v-select
                    filled
                    rounded
                    dense
                    :color="set_color"
                    placeholder="Tipe"

                    class="mr-3"

                    :items="list.type"
                    item-text="text"
                    item-value="value"
                    :item-color="set_color"

                    @change="form.customer_address_id = ''; selected.address = {};"

                    hide-details

                    v-model="form.type">
                  </v-select>

                  <v-btn
                    @click="sheet.item = true; fetchItem();"
                    small
                    rounded
                    :color="set_color"
                    elevation="0"
                    class="text-capitalize d-flex align-center white--text">
                    <v-icon small>mdi-plus-circle-outline</v-icon>

                    <span
                      class="ml-1">
                      Item
                    </span>
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card v-if="form.items.length < 1"
            flat
            color="transparent"
            class="py-16">
            <v-card-text
              class="text-center py-16 my-16">
              <v-img
                contain
                width="200"
                height="auto"
                class="ma-auto"
                alt="bidanvitacare"
                :src="require('@/assets/logo/favicon.png')">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                style="position: relative; top: -35px;">
                <div>
                  Mom, Baby & Kids Spa Sejak 2017
                </div>

                <div
                  class="mt-2">
                  Sahabat Ceria Bunda & Buah Hati
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card v-if="form.items.length > 0"
            flat
            color="transparent">
            <v-card-text
              style="overflow: auto;"
              :style="form.type === 'on_site' ? `height: calc(100vh - 315px);` : `height: calc(100vh - 365px);`">
              <v-row>
                <v-col
                  v-for="(item, index) in form.items"
                  :key="index"
                  :class="index < form.items.length - 1 ? 'mb-4' : ''"
                  cols="12"
                  class="py-0">
                  <v-card
                    flat
                    color="#FFFFFF40"
                    class="border-radius">
                    <v-list
                      flat
                      dense
                      three-line
                      color="transparent">
                      <v-list-item>
                        <v-list-item-avatar
                          size="40"
                          tile
                          class="mt-3"
                          style="border: 2px solid #ffffff; border-radius: 5px !important;">
                          <v-img
                            contain
                            width="100%"
                            height="100%"
                            alt="bidanvitacare"
                            :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <div
                            class="mb-2">
                            <v-chip
                              x-small
                              color="#424242"
                              class="white--text d-flex justify-center"
                              style="width: 65px;">
                              {{ item.type === 'service' ? 'Layanan' : item.type === 'product' ? 'Produk' : 'Bahan' }}
                            </v-chip>
                          </div>

                          <div
                            class="body-2 font-weight-bold text-capitalize">
                            <span
                              class="two-line">
                              {{ item.name }}
                            </span>
                          </div>

                          <!-- <div
                            class="caption line-text-second black--text d-flex align-center">
                            <strong
                              style="opacity: 60%;">
                              {{ item.price | price }}
                            </strong>

                            <span
                              class="font-weight-light">
                              /{{ item.type === 'service' ? 'Durasi' : 'Pcs' }}
                            </span>
                          </div> -->

                          <div>
                            <v-divider
                              class="mt-2 mb-3"
                              style="border-top: 1px dashed #bbb;" />
                          </div>

                          <div
                            class="d-flex align-center">
                            <div
                              class="d-flex align-center">
                              <div>
                                <v-icon
                                  @click="qtyMin(index); countTotal();"
                                  :disabled="item.qty < 2"
                                  class="cursor-pointer"
                                  size="28"
                                  color="red">
                                  mdi-minus-circle
                                </v-icon>
                              </div>

                              <div
                                class="input_qty px-1">
                                <v-text-field
                                  type="number"
                                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                                  min="1"
                                  filled
                                  rounded
                                  dense
                                  :color="set_color"

                                  @focus="$event.target.select()"
                                  @keyup="item.qty = item.qty === '' || parseInt(item.qty) < 1 ? 1 : item.qty; item.total_price = parseInt(item.price) * parseInt(item.qty); countTotal();"
                                  @blur="item.qty = item.qty === '' || parseInt(item.qty) < 1 ? 1 : item.qty; item.total_price = parseInt(item.price) * parseInt(item.qty); countTotal();"

                                  hide-details

                                  v-model.number="item.qty">
                                </v-text-field>
                              </div>

                              <div>
                                <v-icon
                                  @click="qtyPlus(index); countTotal();"
                                  class="cursor-pointer"
                                  size="28"
                                  color="green">
                                  mdi-plus-circle
                                </v-icon>
                              </div>
                            </div>

                            <v-spacer />

                            <!-- <div
                              class="body-2 font-weight-bold black--text">
                              {{ item.total_price | price }}
                            </div> -->
                          </div>

                          <div>
                            <v-divider
                              class="my-3"
                              style="border-top: 1px dashed #bbb;" />
                          </div>

                          <div
                            class="d-flex align-center">
                            <div>
                              Klien
                            </div>

                            <v-spacer />

                            <div
                              @click="selected.partner_index = index; dialog.user_partner = true;">
                              <div v-if="item.customer.length < 1"
                                class="d-flex align-center cursor-pointer"
                                style="opacity: 60%;">
                                <v-icon color="black" size="14">mdi-account-supervisor-circle-outline</v-icon>

                                <span
                                  class="caption ml-1">
                                  Tambah
                                </span>
                              </div>

                              <div v-else
                                class="d-flex align-center cursor-pointer">
                                <v-avatar
                                  v-for="(item_customer, index_customer) in item.customer"
                                  :key="index_customer"
                                  v-show="index_customer < 2"
                                  size="24"
                                  class="mt-0"
                                  style="border: 2px solid #ffffff;"
                                  :style="item.customer.length === 2 && index_customer === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                          item.customer.length > 2 && index_customer === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                          item.customer.length > 2 && index_customer === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                                  <v-img
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare"
                                    :src="item_customer.image_url !== '' ? item_customer.image_url : require('@/assets/image/member_default.jpg')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          size="20"
                                          indeterminate
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>

                                <span v-if="item.customer.length < 2"
                                  class="caption blue--text font-weight-bold one-line text-capitalize ml-1"
                                  style="width: 50px;">
                                  {{ item.customer[0].name }}
                                </span>

                                <span v-if="item.customer.length > 2"
                                  class="caption blue--text font-weight-bold ml-1">
                                  +{{ item.customer.length - 2 }}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div>
                            <v-divider
                              class="mt-3"
                              style="border-top: 1px dashed #bbb;" />
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                    <v-icon
                      @click="form.items.splice(index, 1); countTotal();"
                      class="cursor-pointer"
                      color="red"
                      size="18"
                      style="position: absolute; left: 0px; top: 0px;">
                      mdi-close-circle
                    </v-icon>

                    <div v-if="!item.active"
                      class="d-flex align-center">
                      <div>
                        <div
                          @click="item.active = true"
                          class="d-flex align-center pa-4 pt-0 cursor-pointer"
                          style="opacity: 60%;">
                          <v-icon color="black" size="14">mdi-circle-edit-outline</v-icon>

                          <span
                            class="caption ml-1">
                            {{ item.note !== '' ? item.note : 'Tuliskan catatan disini.' }}
                          </span>
                        </div>
                      </div>

                      <v-spacer />

                      <div v-if="item.type === 'service'">
                        <!-- <div
                          @click="selected.user_index = index; dialog.user = true; fetchTerapis();">
                          <div v-if="item.user_id === ''"
                            class="d-flex align-center pa-4 pt-0 cursor-pointer"
                            style="opacity: 60%;">
                            <v-icon color="black" size="14">mdi-account-circle-outline</v-icon>

                            <span
                              class="caption ml-1">
                              Terapis
                            </span>
                          </div>

                          <div v-else
                            class="d-flex align-center pa-4 pt-0 cursor-pointer">
                            <v-avatar
                              size="20"
                              class="mt-0"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.user_image_url !== '' ? item.user_image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <span
                              class="caption blue--text font-weight-bold ml-1">
                              {{ item.user_name }}
                            </span>
                          </div>
                        </div> -->

                        <div
                          @click="selected.user_index = index; dialog.user_visit = true;">
                          <div v-if="item.visit.length < 1"
                            class="d-flex align-center pa-4 pt-0 cursor-pointer"
                            style="opacity: 60%;">
                            <v-icon color="black" size="14">mdi-account-circle-outline</v-icon>

                            <span
                              class="caption ml-1">
                              Terapis
                            </span>
                          </div>

                          <div v-else
                            class="d-flex align-center pa-4 pt-0 cursor-pointer">
                            <v-avatar
                              v-for="(item_visit, index_visit) in item.visit"
                              :key="index_visit"
                              v-show="index_visit < 2"
                              size="24"
                              class="mt-0"
                              style="border: 2px solid #ffffff;"
                              :style="item.visit.length === 2 && index_visit === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                      item.visit.length > 2 && index_visit === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                      item.visit.length > 2 && index_visit === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item_visit.user_image_url !== '' ? item_visit.user_image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <span v-if="item.visit.length < 2"
                              class="caption blue--text font-weight-bold one-line text-capitalize ml-1"
                              style="width: 50px;">
                              {{ item.visit[0].user_name }}
                            </span>

                            <span v-if="item.visit.length > 2"
                              class="caption blue--text font-weight-bold ml-1">
                              +{{ item.visit.length - 2 }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="item.active"
                      class="pa-4 pt-0">
                      <v-textarea
                        v-on:keyup.enter="item.active = false"
                        @blur="item.active = false"
                        @focus="$event.target.select()"

                        filled
                        rounded
                        dense
                        :color="set_color"
                        rows="2"
                        placeholder="Tuliskan catatan disini."

                        hide-details

                        v-model="item.note"
                        style="border-radius: 10px !important;">
                      </v-textarea>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card v-if="form.items.length > 0"
            flat
            color="#FFFFFF"
            min-height="50"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <div v-if="form.type === 'home_care'"
                class="d-flex align-center">
                <div
                  class="body-2">
                  Alamat
                </div>

                <v-spacer />

                <div
                  @click="dialog.address = true; fetchAddress();"
                  class="body-2 cursor-pointer"
                  :style="`color: ${set_color};`">
                  <span v-if="form.customer_address_id === ''">
                    Tambahkan
                  </span>

                  <span v-else>
                    <span
                      class="d-flex align-center">
                      <v-icon :color="set_color" size="14">mdi-circle-edit-outline</v-icon>

                      <span
                        class="font-weight-bold one-line ml-1"
                        style="width: 150px;">
                        {{ selected.address.address_full }}
                      </span>
                    </span>
                  </span>
                </div>
              </div>

              <v-divider v-if="form.type === 'home_care'"
                class="mt-3 mb-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="d-flex align-center">
                <div
                  class="body-2">
                  Tanggal
                </div>

                <v-spacer />

                <div
                  @click="dialog.date = true"
                  class="body-2 cursor-pointer"
                  :style="`color: ${set_color};`">
                  <span v-if="form.datetime === '' || form.datetime === null">
                    Tambahkan
                  </span>

                  <span v-else>
                    <span
                      class="d-flex align-center">
                      <v-icon :color="set_color" size="14">mdi-circle-edit-outline</v-icon>

                      <span
                        class="font-weight-bold ml-1">
                        {{ form.datetime | datetime }}
                      </span>
                    </span>
                  </span>
                </div>
              </div>

              <v-divider
                class="mt-3 mb-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="d-flex align-center">
                <div
                  class="d-flex align-center">
                  <div
                    class="mr-2">
                    <v-img
                      @click="deletes()"
                      contain
                      width="30"
                      height="30"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/trash.png')"
                      class="ma-auto cursor-pointer">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <div>
                    <v-btn
                      @click="save()"
                      :disabled="process.form"
                      :loading="process.form"
                      block
                      large
                      rounded
                      elevation="0"
                      class="body-1 font-weight-bold white--text text-capitalize btn_save">
                      <div
                        class="btn_save_in d-flex align-center justify-center">
                        <span
                          class="mr-2">
                          Simpan
                        </span>

                        <v-icon color="white">mdi-check-circle</v-icon>
                      </div>
                    </v-btn>
                  </div>
                </div>

                <v-spacer />

                <!-- <div>
                  <div
                    class="body-2 black--text"
                    style="opacity: 60%;">
                    Down Payment
                  </div>

                  <div
                    class="body-1 font-weight-bold black--text">
                    {{ form.dp | price }}
                  </div>
                </div> -->

                <div>
                  <div
                    class="body-2 black--text"
                    style="opacity: 60%;">
                    <span
                      @click="dialog.saldo = true"
                      class="d-flex align-center cursor-pointer">
                      <v-icon color="#666666" size="14">mdi-circle-edit-outline</v-icon>

                      <span
                        class="ml-1">
                        Down Payment
                      </span>
                    </span>
                  </div>

                  <div
                    class="body-1 font-weight-bold black--text">
                    {{ form.dp | price }}
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.pelanggan"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.pelanggan = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.pelanggan = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Pilih Pelanggan
                </span>
              </v-card-title>

              <v-card-text
                class="pt-5 pb-0 mt-16">
                <v-text-field
                  v-model="search_customer"
                  @click:clear="search_customer = ''; fetchPelanggan();"
                  v-on:keyup.enter="fetchPelanggan()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>

                <v-btn
                  @click="sheet.pelanggan = false; sheet.form_customer = true; reset_customer();"
                  block
                  large
                  rounded
                  elevation="0"
                  class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
                  :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                  <div
                    class="btn_auth_in d-flex align-center justify-center"
                    :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                    <div>
                      <v-img
                        contain
                        width="20"
                        height="20"
                        alt="bidanvitacare"
                        :src="require('@/assets/icon/add_user.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>

                    <div
                      class="ml-2">
                      Tambah Pelanggan
                    </div>
                  </div>
                </v-btn>
              </v-card-text>

              <v-card-text
                class="pb-0"
                style="height: calc(100vh - 185px); overflow: auto;">
                <v-row>
                  <v-col
                    cols="12">
                    <v-skeleton-loader
                      v-for="(item, index) in process.load ? 5 : list.pelanggan"
                      :key="index"
                      :loading="process.load"
                      :class="index < list.pelanggan.length - 1 ? 'mb-4' : ''"
                      type="list-item-avatar-three-line">
                      <template>
                        <div>
                          <v-list
                            v-if="!process.load && list.pelanggan.length > 0"
                            flat
                            dense
                            two-line
                            color="#FFFFFF40"
                            class="border-radius">
                            <v-list-item
                              @click="selected.pelanggan = item; form.customer_id = item.id; sheet.pelanggan = false;">
                              <v-list-item-avatar
                                size="40"
                                class="mt-0"
                                style="border: 2px solid #ffffff;">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        size="20"
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title
                                  class="body-2 font-weight-bold text-capitalize">
                                  {{ item.name }}
                                </v-list-item-title>

                                <v-list-item-subtitle
                                  class="body-2 font-weight-light line-text-second black--text"
                                  style="opacity: 70%;">
                                  {{ item.email !== '' ? item.email : '-' }}
                                </v-list-item-subtitle>

                                <v-list-item-subtitle
                                  class="body-2">
                                  Telp. {{ item.mobilephone !== '' ? item.mobilephone : '-' }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </template>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <div v-if="!process.load && pagination_pelanggan.total_data > 10 && limit_pelanggan < pagination_pelanggan.total_data"
                  class="text-center my-4">
                  <v-btn
                    @click="limit_pelanggan += 10"
                    :loading="process.limit"
                    small
                    rounded
                    outlined
                    elevation="0"
                    :color="set_color"
                    class="text-capitalize"
                    :style="`color: ${set_color};`">
                    Lebih banyak
                  </v-btn>
                </div>

                <Empty v-if="!process.load && pagination_pelanggan.total_page < 1"
                  class="py-6"
                  margin="my-16"
                  size="125"
                  message="Pelanggan" />
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.item"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.item = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.item = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Pilih Item
                </span>
              </v-card-title>

              <v-card-text
                class="pt-5 pb-0 mt-16">
                <v-text-field
                  v-model="search"
                  @click:clear="search = ''; fetchItem();"
                  v-on:keyup.enter="fetchItem()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>

                <v-row v-if="list.type_item.length > 1"
                  class="px-1">
                  <v-col
                    v-for="(item, index) in list.type_item"
                    :key="index"
                    cols="4"
                    class="py-0 px-2">
                    <v-btn
                      @click="selected.item = item.value; fetchItem();"
                      block
                      rounded
                      elevation="0"
                      :class="selected.item !== item.value ? '' : 'font-weight-bold'"
                      :color="selected.item !== item.value ? '#e2e2e2' : set_color"
                      :style="selected.item !== item.value ? 'color: #8c8c8c;' : 'color: #FFFFFF;'"
                      class="body-2 text-capitalize mt-4">
                      {{ item.text }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-text
                class="pb-0"
                style="overflow: auto;"
                :style="list.type_item.length > 1 ? 'height: calc(100vh - 185px);' : 'height: calc(100vh - 130px);'">
                <v-row>
                  <v-col
                    cols="12">
                    <v-skeleton-loader
                      v-for="(item, index) in process.item ? 5 : list.item"
                      :key="index"
                      :loading="process.item"
                      :class="index < list.item.length - 1 ? 'mb-4' : ''"
                      type="list-item-avatar-three-line">
                      <template>
                        <div>
                          <v-list
                            v-if="!process.item && list.item.length > 0"
                            flat
                            dense
                            three-line
                            color="#FFFFFF40"
                            class="border-radius">
                            <v-list-item
                              @click="addItem(item)">
                              <v-list-item-avatar
                                size="40"
                                tile
                                class="mt-2"
                                style="border: 2px solid #ffffff; border-radius: 5px !important;">
                                <v-img
                                  contain
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        size="20"
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <div v-if="item.type !== 'material'">
                                  <v-chip
                                    x-small
                                    class="caption white--text text-capitalize"
                                    :color="set_color"
                                    style="margin-bottom: 5px; max-width: 250px;">
                                    <span
                                      class="one-line">
                                      {{ item.category_name !== '' ? item.category_name : '-' }}
                                    </span>
                                  </v-chip>
                                </div>

                                <v-list-item-title
                                  class="body-2 font-weight-bold text-capitalize">
                                  {{ item.name }}
                                </v-list-item-title>

                                <v-list-item-subtitle
                                  class="body-2 font-weight-light line-text-second black--text"
                                  style="opacity: 70%;">
                                  <span v-if="item.type !== 'service'">
                                    Stok. {{ item.stock }}
                                  </span>

                                  <span v-else>
                                    Durasi. {{ item.duration_minutes }} Menit
                                  </span>
                                </v-list-item-subtitle>

                                <v-list-item-subtitle v-if="item.type !== 'material'"
                                  class="body-2">
                                  <span
                                    :class="item.special_price > 0 ? 'text-decoration-line-through red--text' : 'font-weight-bold'">
                                    {{ item.retail_price | price }}
                                  </span>

                                  <span v-if="item.special_price > 0"
                                    class="font-weight-bold ml-2">
                                    {{ item.special_price | price }}
                                  </span>
                                </v-list-item-subtitle>

                                <!-- <div v-if="item.type === 'material'"
                                  class="pa-3 mt-2"
                                  style="border: 2px dashed #bbb; border-radius: 10px;">
                                  <div
                                    class="body-2 font-weight-light black--text">
                                    <span v-if="item.description !== ''"
                                      v-html="item.description">
                                    </span>

                                    <span v-else>
                                      Tidak ada keterangan
                                    </span>
                                  </div>
                                </div> -->

                                <div v-if="item.type === 'material'"
                                  class="pa-3 mt-2"
                                  style="border: 2px dashed #bbb; border-radius: 10px;">
                                  <ul v-if="item.composition_json_array.length > 0"
                                    class="body-2 font-weight-light black--text"
                                    style="position: relative; left: -10px;">
                                    <li
                                      v-for="(item_alat, index_alat) in item.composition_json_array"
                                      :key="index_alat"
                                      :class="index_alat < item.composition_json_array.length - 1 ? 'mb-4' : ''">
                                      <div
                                        class="mb-2">
                                        {{ item_alat.name }}
                                      </div>

                                      <div
                                        v-for="(item_stok, index_stok) in item_alat.stock"
                                        :key="index_stok">
                                        <div
                                          class="font-weight-bold mb-1"
                                          style="opacity: 60%;">
                                          {{ item_stok.store_name }}
                                        </div>

                                        <div
                                          class="d-flex align-center">
                                          <div>
                                            <div
                                              class="caption"
                                              style="opacity: 60%;">
                                              Stok
                                            </div>

                                            <div>
                                              {{ item_stok.stock }}
                                            </div>
                                          </div>

                                          <v-spacer />

                                          <div>
                                            <div
                                              class="caption"
                                              style="opacity: 60%;">
                                              Minimal Stok
                                            </div>

                                            <div>
                                              {{ item_stok.stock_min }}
                                            </div>
                                          </div>
                                        </div>

                                        <v-divider
                                          v-if="index_stok < item_alat.stock.length - 1"
                                          class="my-3"
                                          style="border-top: 1px dashed #bbb;" />
                                      </div>
                                    </li>
                                  </ul>

                                  <div v-else>
                                    Tidak ada kelengkapan alat
                                  </div>
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </template>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <div v-if="!process.item && pagination_item.total_data > 10 && limit_item < pagination_item.total_data"
                  class="text-center my-4">
                  <v-btn
                    @click="limit_item += 10"
                    :loading="process.limit"
                    small
                    rounded
                    outlined
                    elevation="0"
                    :color="set_color"
                    class="text-capitalize"
                    :style="`color: ${set_color};`">
                    Lebih banyak
                  </v-btn>
                </div>

                <Empty v-if="!process.item && pagination_item.total_page < 1"
                  class="py-6"
                  margin="my-16"
                  size="125"
                  message="Item" />
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.error">
          <v-sheet
            color="#f9dee2"
            min-height="50px"
            style="border-radius: 15px 15px 0px 0px; overflow-y: auto;">
            <v-card
              flat
              color="transparent">
              <v-card-text>
                <ul
                  style="position: relative; left: -10px;">
                  <li
                    v-for="(item, index) in message.errors"
                    :key="index"
                    class="red--text"
                    :class="index < message.errors.length - 1 ? 'mb-2' : ''"
                    v-html="item">
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-sheet>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.form_customer"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.form_customer = false; reset_customer(); sheet.pelanggan = true; fetchPelanggan();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.form_customer = false; reset_customer(); sheet.pelanggan = true; fetchPelanggan();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  {{ form.id === '' ? 'Tambah' : 'Ubah' }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-4">
                  <ValidationObserver
                    ref="form_customer">
                    <v-form>
                      <ValidationProvider
                        name="Nama Lengkap"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Nama Lengkap
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save_customer"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. John Doe"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form_customer.name"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Tipe"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Tipe
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          v-on:keyup.enter="save_customer"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Bayi"

                          :items="type"
                          item-text="text"
                          item-value="value"
                          :item-color="set_color"

                          @change="form_customer.hpht_date = ''"

                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                          v-model="form_customer.type"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-select>
                      </ValidationProvider>

                      <div v-if="form_customer.type === 'ibu_hamil'">
                        <div class="body-2 mb-2">
                          HPHT
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-menu
                          ref="picker_hpht"
                          min-width="290px"
                          max-width="290px"
                          transition="scale-transition"
                          offset-y
                          :nudge-right="40"
                          :close-on-content-click="false"
                          v-model="picker_hpht">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider
                              name="HPHT"
                              v-slot="{ errors, valid }">
                              <v-text-field
                                v-on:keyup.enter="save"
                                readonly
                                filled
                                rounded
                                dense
                                :color="set_color"
                                placeholder="Contoh. 2020-01-01"

                                :append-icon="form_customer.hpht_date !== '' && valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form_customer.hpht_date = ''"
                                v-on="on"
                                v-bind="attrs"

                                v-model="form_customer.hpht_date"
                                :success="form_customer.hpht_date !== '' && valid"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>
                          </template>

                          <v-date-picker
                            :color="set_color"
                            v-model="form_customer.hpht_date"
                            @input="picker_hpht = false">
                          </v-date-picker>
                        </v-menu>
                      </div>

                      <ValidationProvider
                        name="No. Handphone"
                        rules="min:8|max:13"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <!-- <span class="red--text">*</span> -->
                          No. Handphone
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save_customer"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 081332XXXXXX"
                          :append-icon="valid && form_customer.mobilephone !== '' ? 'mdi-check-circle-outline' : ''"

                          v-model="form_customer.mobilephone"
                          :success="valid && form_customer.mobilephone !== ''"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center mt-3 mb-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/image/footer/pelanggan_bvc.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data Pelanggan untuk membuat akun di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        v-on:keyup.enter="save_customer"
                        @click="save_customer"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.form_customer = false; reset_customer(); sheet.pelanggan = true; fetchPelanggan();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          discount: false,
          transport: false,
          user: false,
          address: false,
          date: false,

          wali: false,

          user_visit: false,

          partner: false,
          user_partner: false,

          go_back: false,

          saldo: false
        },
        sheet: {
          pelanggan: false,
          item: false,

          error: false
        },
        search: '',
        search_terapis: '',
        search_customer: '',
        search_address: '',
        list: {
          type: [
            {
              text: 'On Site',
              value: 'on_site'
            },
            {
              text: 'Home Care',
              value: 'home_care'
            },
            {
              text: 'Produk',
              value: 'product'
            }
          ],
          type_item: [
            {
              text: 'Layanan',
              value: 'service'
            },
            {
              text: 'Produk',
              value: 'product'
            },
            {
              text: 'Bahan',
              value: 'material'
            }
          ],
          pelanggan: [],
          item: [],
          terapis: [],
          address: []
        },

        config: {
          payment: [
            {
              title: 'cash',
              icon: require('@/assets/payment/cash.png'),
              desc: 'Pembayaran Cash bisa langsung dengan memberikan sejumlah uang tunai.'
            },
            {
              title: 'transfer',
              icon: require('@/assets/payment/transfer.png'),
              desc: 'Pembayaran Transfer melalui Bank, ATM, Internet Banking atau Mobile Banking.'
            },
            {
              title: 'edc',
              icon: require('@/assets/payment/edc.png'),
              desc: 'Pembayaran melalui mesin EDC yang diterbitkan oleh Bank.'
            },
            {
              title: 'qris',
              icon: require('@/assets/payment/qris.png'),
              desc: 'Pembayaran Qris dilakukan dengan menscan kode QR yang disediakan.'
            },
            {
              title: 'shopeepay',
              icon: require('@/assets/payment/shopeepay.png'),
              desc: 'Pembayaran melalui paylater berjangka dari Shopeepay.'
            }
          ],
          bank: [
            // {
            //   text: 'BCA',
            //   icon: require('@/assets/bank/bca.png')
            // },
            {
              text: 'BRI',
              icon: require('@/assets/bank/bri.png')
            },
            // {
            //   text: 'BNI',
            //   icon: require('@/assets/bank/bni.png')
            // },
            {
              text: 'Mandiri',
              icon: require('@/assets/bank/mandiri.png')
            },
            // {
            //   text: 'CIMB',
            //   icon: require('@/assets/bank/cimb.png')
            // }
          ]
        },

        pagination: {},
        pagination_pelanggan: {},
        pagination_item: {},

        limit_pelanggan: 10,
        limit_item: 10,

        selected: {
          pelanggan: {},
          address: {},
          item: 'service',
          user_index: '',

          user_terapis_item: {},
          user_terapis_index: '',

          partner_index: '',
          user_partner_item: {},
          user_partner_index: '',

          form_customer: false
        },
        picker: {
          date: false,
          time: false
        },
        form: {
          id: '',
          type: 'on_site',
          customer_id: '',
          customer_address_id: '',
          datetime: '',
          note: '',
          dp: 0,
          items: [],

          date: '',
          time: '',

          amount_paid_sum: 0,

          wali: '',
          mobilephone_wali: '',
          mobilephone_other: '',

          is_process: false,

          dp_payment_method: '',
          dp_payment_method_bank_name: '',
          dp_payment_method_ref: ''
        },

        type: [
          {
            text: 'Ibu Hamil',
            value: 'ibu_hamil'
          },
          {
            text: 'Ibu Menyusui',
            value: 'ibu_menyusui'
          },
          {
            text: 'Bayi',
            value: 'bayi'
          },
          {
            text: 'Anak',
            value: 'anak'
          },
          {
            text: 'Umum',
            value: 'umum'
          }
        ],

        picker_hpht: false,

        form_customer: {
          id: '',
          name: '',
          mobilephone: '',
          type: 'umum',
          hpht_date: ''
        },

        process: {
          load: false,
          item: false,
          terapis: false,
          address: false,
          form: false,

          limit: false
        },
        message: {
          success: '',
          error: '',
          errors: []
        },
        // SEO
        content: {
          url: '/jadwal/ubah/:id',
          title: 'Ubah Jadwal',
          description: 'Ubah Jadwal Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'form.type': function (val) {
        if (val === 'product') {
          this.deletes_item()

          this.list.type_item = [
            {
              text: 'Produk',
              value: 'product'
            }
          ]

          this.selected.item = 'product'
        } else {
          this.list.type_item = [
            {
              text: 'Layanan',
              value: 'service'
            },
            {
              text: 'Produk',
              value: 'product'
            },
            {
              text: 'Bahan',
              value: 'material'
            }
          ]

          this.selected.item = 'service'
        }
      },

      'limit_pelanggan': function() {
        this.process.limit = true

        this.fetchPelanggan('', 'limit')
      },

      'limit_item': function() {
        this.process.limit = true

        this.fetchItem('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetchDetail()

      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)

          t.dialog.go_back = true
        })
      },
      async fetchDetail () {
        let params = {
          id: this.$route.params.id
        }

        await this.$axios.$get(`${process.env.API}admin/schedule/detail`, { params })
        .then((response) => {
          if (response.status === 200) {
            let item = response.results.data
            console.log(item.datetime.split(' ')[1])

            this.form = {
              id: item.id,
              type: item.type,
              customer_id: item.customer_id,
              customer_address_id: item.customer_address_id,
              datetime: item.datetime,
              note: item.note,
              dp: item.dp,
              items: [],

              date: item.datetime.split(' ')[0],
              time: item.datetime.split(' ')[1] !== '00:00:00' ? item.datetime.split(' ')[1] : '',

              amount_paid_sum: 0,

              wali: item.wali,
              mobilephone_wali: item.mobilephone_wali,
              mobilephone_other: item.mobilephone_other,

              is_process: item.is_process.toString(),

              dp_payment_method: item.dp_payment_method,
              dp_payment_method_bank_name: item.dp_payment_method_bank_name,
              dp_payment_method_ref: item.dp_payment_method_ref
            }

            let items_detail = item.items

            items_detail.map(obj => {
              this.form.items.push({
                id: obj.id,
                qty: obj.qty,
                note: obj.note,
                // user_id: obj.therapist_id,
                // user_name: obj.therapist_name,
                // user_image_url: obj.therapist_image_url,
                name: obj.name,
                image_url: obj.image_url,
                price: 0,
                total_price: 0,
                type: obj.type,
                active: false,

                visit: obj.visit,
                customer: obj.customer
              })
            })

            this.fetchPelanggan(item.customer_id, '')
            this.fetchAddress(item.customer_address_id)
          }
        })
      },
      async fetchPelanggan (id, type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search_customer,
          id: id !== undefined ? id : '',
          limit: type !== undefined ? this.limit_pelanggan : 10
        }

        await this.$axios.$get(`${process.env.API}admin/customer`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list.pelanggan = response.results.data
            this.pagination_pelanggan = response.results.pagination

            if (id !== undefined) {
              this.selected.pelanggan = this.list.pelanggan.length > 0 ? this.list.pelanggan[0] : {}
            }
          }
        })
      },
      async fetchItem (type) {
        this.process.item = type === undefined ? true : false

        let params = {
          search: this.search,
          'type[eq]': this.selected.item,
          limit: type !== undefined ? this.limit_item : 10
        }

        await this.$axios.$get(`${process.env.API}admin/item`, { params })
        .then((response) => {
          this.process.item = false

          this.process.limit = false

          if (response.status === 200) {
            this.list.item = response.results.data
            this.pagination_item = response.results.pagination
          }
        })
      },
      async fetchTerapis () {
        this.process.terapis = true

        let params = {
          search: this.search_terapis,
          // 'role[eq]': 'therapist'

          customer_id: this.form.customer_id,
          limit: 10000
        }

        // await this.$axios.$get(`${process.env.API}admin/user`, { params })
        await this.$axios.$get(`${process.env.API}admin/user/therapist-allow`, { params })
        .then((response) => {
          this.process.terapis = false

          if (response.status === 200) {
            this.list.terapis = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchAddress (id) {
        this.process.address = true

        let params = {
          search: this.search_address,
          customer_id: this.form.customer_id,
          id: id !== undefined ? id : '',
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/customer-address`, { params })
        .then((response) => {
          this.process.address = false

          if (response.status === 200) {
            this.list.address = response.results.data
            this.pagination = response.results.pagination

            if (id !== undefined) {
              this.selected.address = this.list.address.length > 0 ? this.list.address[0] : {}
            }
          }
        })
      },

      addItem (item) {
        this.form.items.push({
          id: item.id,
          qty: 1,
          note: '',
          // user_id: '',
          // user_name: '',
          // user_image_url: '',
          name: item.name,
          image_url: item.image_url,
          price: item.special_price > 0 ? item.special_price : item.retail_price,
          total_price: item.special_price > 0 ? item.special_price : item.retail_price,
          type: item.type,
          active: false,

          visit: [],
          customer: []
        })

        this.sheet.item = false

        this.countTotal()
      },
      qtyMin (index) {
        this.form.items[index].qty -= 1
        this.form.items[index].total_price = parseInt(this.form.items[index].price) * parseInt(this.form.items[index].qty)
      },
      qtyPlus (index) {
        this.form.items[index].qty += 1
        this.form.items[index].total_price = parseInt(this.form.items[index].price) * parseInt(this.form.items[index].qty)
      },
      countTotal () {
        this.form.amount_paid_sum = 0

        this.form.amount_paid_sum = parseInt(this.form.items.reduce((a, item) => a + item.total_price, 0))

        this.form.amount_paid_sum = parseInt(this.form.amount_paid_sum)
      },

      addTerapis (item) {
        // this.form.items[this.selected.user_index].user_id = item.id
        // this.form.items[this.selected.user_index].user_name = item.name
        // this.form.items[this.selected.user_index].user_image_url = item.image_url

        this.form.items[this.selected.user_index].visit.push({
          user_id: item.id,
          user_name: item.name,
          user_image_url: item.image_url,

          // date: this.visit_date
          // datetime: `${item.visit_date} ${item.visit_time}`
        })

        this.selected.user_terapis_item = {}
        this.selected.user_terapis_index = ''
      },
      addAddress (item) {
        this.form.customer_address_id = item.id

        this.selected.address = item
      },

      addPartner (item) {
        this.form.items[this.selected.partner_index].customer.push({
          id: item.id,
          name: item.name,
          image_url: item.image_url
        })

        this.selected.user_partner_item = {}
        this.selected.user_partner_index = ''
      },

      deletes () {
        this.form = {
          id: '',
          type: 'on_site',
          customer_id: '',
          customer_address_id: '',
          datetime: '',
          note: '',
          dp: 0,
          items: [],

          date: '',
          time: '',

          amount_paid_sum: 0
        }
      },

      deletes_item () {
        this.form.items = []
      },

      async save () {
        this.message.errors = []

        if (this.form.customer_id === '') {
          this.message.errors.push('Silahkan pilih <strong>Pelanggan</strong>')
        }

        // if (this.form.wali === '' || this.form.mobilephone_wali === '' || this.form.mobilephone_other === '') {
        //   this.message.errors.push('Silahkan tambahkan <strong>Pendamping</strong>')
        // }

        // if (this.form.wali === '') {
        //   this.message.errors.push('Silahkan tambahkan <strong>Pendamping</strong>')
        // }

        // if (this.form.items.length > 0) {
        //   this.form.items.map(obj => {
        //     if (obj.type === 'service' && obj.visit.length < 1) {
        //       this.message.errors.push(`Silahkan pilih <strong>Terapis</strong> untuk item <strong>${obj.name}</strong>`)
        //     }
        //   })
        // }

        // if (this.form.type === 'home_care' && (this.form.customer_address_id === '' || this.form.customer_address_id === undefined)) {
        //   this.message.errors.push('Silahkan tambahkan <strong>Alamat</strong>')
        // }

        // if (this.form.type === 'home_care' && this.form.transport_name === '') {
        //   this.message.errors.push('Silahkan tambahkan <strong>Trasnport</strong>')
        // }

        if (this.form.date === '') {
          this.message.errors.push('Silahkan tambahkan <strong>Tanggal</strong>')
        }

        // if (this.form.time === '') {
        //   this.message.errors.push('Silahkan tambahkan <strong>Waktu</strong>')
        // }

        if (this.message.errors.length > 0) {
          this.sheet.error = true
        } else {
          this.message.error = ''

          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}admin/schedule/update`, this.form)
          .then((response) => {

            if (response.status === 200) {

              this.$router.push({ path: '/jadwal', query: { menu: this.$route.query.menu } })
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
        }
      },

      reset_customer (item) {
        if (this.$refs.form_customer) this.$refs.form_customer.reset()

        this.form_customer = {
          id: item !== undefined ? item.id : '',
          name: item !== undefined ? item.name : '',
          mobilephone: item !== undefined ? item.mobilephone : '',
          type: item !== undefined ? item.type : 'umum',
          hpht_date: item !== undefined ? item.hpht_date : ''
        }

        this.picker_hpht = false

        this.process.form = false

        this.message.error = ''
      },
      async save_customer () {
        this.message.error = ''

        const isValid = await this.$refs.form_customer.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form.id === '' ? 'admin/customer/add' : 'admin/customer/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form_customer)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form_customer = false

              this.sheet.pelanggan = true

              this.fetchPelanggan()
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form_customer.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form_customer.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      reset_dp () {
        if (this.$refs.form) this.$refs.form.reset()

        this.form.dp = 0
        this.form.dp_payment_method = ''
        this.form.dp_payment_method_bank_name = ''
        this.form.dp_payment_method_ref = ''
      },

      async save_dp () {
        const isValid = await this.$refs.form.validate()
        if (isValid) {

          if (this.form.dp === '') this.form.dp = 0

          this.dialog.saldo = false

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>

<style>
  .item_type .v-input__slot {
    padding: 0 16px !important;
    width: 150px !important;
    min-height: 30px !important;
    height: 30px !important;
    font-size: 0.8rem !important;
    padding-right: 5px !important;
  }

  /* .item_type .v-input input {
    text-align: center !important;
  }

  .v-input input {
    text-align: center !important;
  } */

  .item_type .v-input__append-inner {
    margin: 4px !important;
  }

  .input_qty .v-input__slot {
    padding: 0 16px !important;
    width: 75px !important;
    min-height: 28px !important;
  }
</style>
