<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.go_back"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin akan keluar
                </div>

                <div>
                  dari halaman ini?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                to="/home"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Keluar
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.go_back = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text
              class="pb-0 mt-1">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                filled
                rounded
                dense
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>
            </v-card-text>

            <v-card-text
              class="pb-0">
              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 4 : list"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list.length > 0"
                          flat
                          dense
                          three-line
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-list-item
                            @click="sheet.form = true; reset(item);">
                            <v-list-item-avatar
                              tile
                              size="40"
                              class="mt-2"
                              style="border: 2px solid #ffffff; border-radius: 5px !important;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="require('@/assets/icon/store.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold">
                                {{ item.name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light line-text-first my-1"
                                style="margin-bottom: 6px !important;">
                                {{ item.address }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light black--text">
                                Buka. <strong class="font-weight-bold">{{ item.open_hours }}</strong>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>

                        <v-img
                          width="100%"
                          height="200"
                          alt="bidanvitacare"
                          gradient="to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)"
                          :src="require('@/assets/image/map_bvc.jpeg')"
                          class="ma-auto d-flex align-center text-center"
                          style="border-radius: 10px;">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>

                          <v-btn
                            :href="item.google_review_link"
                            target="_blank"
                            color="#212121"
                            elevation="0"
                            class="body-2 d-flex align-center text-capitalize white--text"
                            style="border-radius: 0px !important;">
                            <v-icon small color="white">mdi-map-search</v-icon>

                            <span
                              class="ml-1">
                              Buka link
                            </span>
                          </v-btn>
                        </v-img>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Lokasi" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.form"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.form = false; reset();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.form = false; reset();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  {{ form.id === '' ? 'Tambah' : 'Ubah' }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="d-flex align-center pt-16 mt-4">
                  <v-avatar
                    tile
                    size="150">
                    <v-img
                      width="100%"
                      height="100%"
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
                            border: 5px solid #0000000d;
                            border-radius: 10px;"
                      alt="bidanvitacare"
                      :src="form.payment_qr_url !== '' ? form.payment_qr_url : require('@/assets/image/image_default.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>

                  <div>
                    <upload-folder
                      v-model="upload.folder">
                      <div slot="activator">
                        <v-btn
                          :disabled="process.file"
                          :loading="process.file"
                          elevation="0"
                          rounded
                          block
                          large
                          color="#0000000d"
                          class="body-1 font-weight-bold text-capitalize d-flex align-center"
                          style="border-radius: 0px 50px 50px 0px;"
                          :style="`color: ${set_color};`">
                          <v-icon
                            size="24"
                            :color="set_color">
                            mdi-camera
                          </v-icon>

                          <span
                            class="ml-1">
                            Gambar
                          </span>
                        </v-btn>
                      </div>
                    </upload-folder>
                  </div>
                </v-card-text>

                <v-card-text>
                  <ValidationObserver
                    ref="form">
                    <v-form>
                      <ValidationProvider
                        name="Nama Lokasi"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Nama Lokasi
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Pusat"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.name"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <v-row>
                        <v-col
                          cols="6"
                          class="py-0">
                          <div>
                            <div class="body-2 mb-2">
                              <span class="red--text">*</span>
                              Jam Buka
                              <!-- <span class="error--text">
                                (wajib diisi)
                              </span> -->
                            </div>

                            <v-menu
                              ref="start"
                              v-model="time.start"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="form.start_hours"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px">
                              <template v-slot:activator="{ on, attrs }">
                                <ValidationProvider
                                  name="Jam Buka"
                                  rules="required"
                                  v-slot="{ errors, valid }">
                                  <v-text-field
                                    v-on:keyup.enter="save"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                    :color="set_color"
                                    placeholder="Contoh. 08:00"

                                    :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                    clearable
                                    @click:clear="form.start_hours = ''"
                                    v-on="on"
                                    v-bind="attrs"

                                    v-model="form.start_hours"
                                    :success="valid"
                                    :error-messages="errors"
                                    required>
                                  </v-text-field>
                                </ValidationProvider>
                              </template>

                              <v-time-picker
                                v-if="time.start"
                                v-model="form.start_hours"
                                full-width
                                :color="set_color"
                                format="24hr"
                                @click:minute="$refs.start.save(form.start_hours)">
                              </v-time-picker>
                            </v-menu>
                          </div>
                        </v-col>

                        <v-col
                          cols="6"
                          class="py-0">
                          <div>
                            <div class="body-2 mb-2">
                              <span class="red--text">*</span>
                              Jam Tutup
                              <!-- <span class="error--text">
                                (wajib diisi)
                              </span> -->
                            </div>

                            <v-menu
                              ref="end"
                              v-model="time.end"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="form.end_hours"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px">
                              <template v-slot:activator="{ on, attrs }">
                                <ValidationProvider
                                  name="Jam Tutup"
                                  rules="required"
                                  v-slot="{ errors, valid }">
                                  <v-text-field
                                    v-on:keyup.enter="save"
                                    readonly
                                    filled
                                    rounded
                                    dense
                                    :color="set_color"
                                    placeholder="Contoh. 17:00"

                                    :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                    clearable
                                    @click:clear="form.end_hours = ''"
                                    v-on="on"
                                    v-bind="attrs"

                                    v-model="form.end_hours"
                                    :success="valid"
                                    :error-messages="errors"
                                    required>
                                  </v-text-field>
                                </ValidationProvider>
                              </template>

                              <v-time-picker
                                v-if="time.end"
                                v-model="form.end_hours"
                                full-width
                                :color="set_color"
                                format="24hr"
                                :min="form.start_hours"
                                @click:minute="$refs.end.save(form.end_hours)">
                              </v-time-picker>
                            </v-menu>
                          </div>
                        </v-col>
                      </v-row>

                      <ValidationProvider
                        name="Bank"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Bank
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. BCA"

                          :items="bank"
                          item-text="bank_label"
                          item-value="bank_id"
                          :item-color="set_color"

                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          multiple

                          return-object

                          v-model="form.bank"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-select>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Alamat"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          Alamat
                          <!-- <span>
                            (boleh dikosongi)
                          </span> -->
                        </div>

                        <v-textarea
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          rows="5"
                          placeholder="Contoh. Tuliskan alamat disini . . ."
                          :append-icon="form.address !== '' && valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.address"
                          :success="form.address !== '' && valid"
                          :error-messages="errors"
                          required>
                        </v-textarea>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Link Review Google"
                        v-slot="{ errors, valid }">
                        <div
                          class="body-2 mb-2 d-flex align-center">
                          <div>
                            Link Review Google
                            <!-- <span>
                              (boleh dikosongi)
                            </span> -->
                          </div>

                          <v-spacer />

                          <div>
                            <a
                              :href="form.google_review_link"
                              target="_blank"
                              class="d-flex align-center blue--text">
                              <v-icon small color="blue">mdi-map-search</v-icon>

                              <span
                                class="ml-1">
                                Buka link
                              </span>
                            </a>
                          </div>
                        </div>

                        <v-textarea
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          rows="5"
                          placeholder="Contoh. Tuliskan link review google disini . . ."
                          :append-icon="form.google_review_link !== '' && valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.google_review_link"
                          :success="form.google_review_link !== '' && valid"
                          :error-messages="errors"
                          required>
                        </v-textarea>
                      </ValidationProvider>

                      <ValidationProvider
                        name="WA Ringkasan Homecare Footer"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          WA Ringkasan Homecare Footer
                          <span>
                            (boleh dikosongi)
                          </span>
                        </div>

                        <v-textarea
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          rows="5"
                          placeholder="Contoh. Tuliskan wa ringkasan homecare footer disini . . ."
                          :append-icon="form.wa_ringkasan_homecare_footer !== '' && valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.wa_ringkasan_homecare_footer"
                          :success="form.wa_ringkasan_homecare_footer !== '' && valid"
                          :error-messages="errors"
                          required>
                        </v-textarea>
                      </ValidationProvider>

                      <ValidationProvider
                        name="WA Ringkasan On Site Footer"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          WA Ringkasan On Site Footer
                          <span>
                            (boleh dikosongi)
                          </span>
                        </div>

                        <v-textarea
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          rows="5"
                          placeholder="Contoh. Tuliskan wa ringkasan on site footer disini . . ."
                          :append-icon="form.wa_ringkasan_onsite_footer !== '' && valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.wa_ringkasan_onsite_footer"
                          :success="form.wa_ringkasan_onsite_footer !== '' && valid"
                          :error-messages="errors"
                          required>
                        </v-textarea>
                      </ValidationProvider>

                      <ValidationProvider
                        name="WA Konfirmasi Homecare Footer"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          WA Konfirmasi Homecare Footer
                          <span>
                            (boleh dikosongi)
                          </span>
                        </div>

                        <v-textarea
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          rows="5"
                          placeholder="Contoh. Tuliskan wa konfirmasi homecare footer disini . . ."
                          :append-icon="form.wa_konfirmasi_homecare_footer !== '' && valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.wa_konfirmasi_homecare_footer"
                          :success="form.wa_konfirmasi_homecare_footer !== '' && valid"
                          :error-messages="errors"
                          required>
                        </v-textarea>
                      </ValidationProvider>

                      <ValidationProvider
                        name="WA Konfirmasi On Site Footer"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          WA Konfirmasi On Site Footer
                          <span>
                            (boleh dikosongi)
                          </span>
                        </div>

                        <v-textarea
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          rows="5"
                          placeholder="Contoh. Tuliskan wa konfirmasi on site footer disini . . ."
                          :append-icon="form.wa_konfirmasi_onsite_footer !== '' && valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.wa_konfirmasi_onsite_footer"
                          :success="form.wa_konfirmasi_onsite_footer !== '' && valid"
                          :error-messages="errors"
                          required>
                        </v-textarea>
                      </ValidationProvider>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center my-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/image/footer/store.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data untuk mengubah data lokasi di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        v-on:keyup.enter="save"
                        @click="save"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.form = false; reset();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          go_back: false
        },

        sheet: {
          form: false
        },
        search: '',
        detail: {},
        bank: [],
        list: [],
        pagination: {},
        upload: {
          folder: null
        },
        time: {
          start: false,
          end: false
        },
        form: {
          id: '',
          name: '',
          address: '',
          open_hours: '',
          payment_qr_url: '',
          google_review_link: '',

          start_hours: '',
          end_hours: '',

          bank: [],

          wa_ringkasan_homecare_footer: '',
          wa_ringkasan_onsite_footer: '',
          wa_konfirmasi_homecare_footer: '',
          wa_konfirmasi_onsite_footer: ''
        },
        process: {
          load: false,
          form: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/toko',
          title: 'Lokasi',
          description: 'Lokasi Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form.payment_qr_url = this.upload.folder.fileuri
            }
          }
        },
        deep: true
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()

      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)

          t.dialog.go_back = true
        })
      },

      async fetch () {
        this.process.load = true

        let params = {
          search: this.search,
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/store`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchBank () {
        await this.$axios.$get(`${process.env.API}admin/bank`)
        .then((response) => {
          if (response.status === 200) {
            let data = response.results.data

            data.map(obj => {
              this.bank.push({
                bank_id: obj.id,
                bank_label: obj.label
              })
            })
          }
        })
      },
      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          id: item !== undefined ? item.id : '',
          name: item !== undefined ? item.name : '',
          address: item !== undefined ? item.address : '',
          open_hours: item !== undefined ? item.open_hours : '',
          payment_qr_url: item !== undefined ? item.payment_qr_url : '',
          google_review_link: item !== undefined ? item.google_review_link : '',

          start_hours: item !== undefined ? item.open_hours.split(' - ')[0] : '',
          end_hours: item !== undefined ? item.open_hours.split(' - ')[1] : '',

          bank: item !== undefined ? item.bank_json_object : '',

          wa_ringkasan_homecare_footer: item !== undefined ? item.wa_ringkasan_homecare_footer : '',
          wa_ringkasan_onsite_footer: item !== undefined ? item.wa_ringkasan_onsite_footer : '',
          wa_konfirmasi_homecare_footer: item !== undefined ? item.wa_konfirmasi_homecare_footer : '',
          wa_konfirmasi_onsite_footer: item !== undefined ? item.wa_konfirmasi_onsite_footer : ''
        }

        this.process.form = false

        this.message.error = ''

        if (this.sheet.form) this.fetchBank()
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          this.form.open_hours = `${this.form.start_hours} - ${this.form.end_hours}`

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}admin/store/update`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form = false

              this.fetch()
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>
