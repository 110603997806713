<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.reset"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Reset Password
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-4 px-4 pb-2">
              Apakah Anda yakin ingin mereset password <strong>{{ detail.name }}</strong>?

              <div v-if="message.error.length > 0"
                class="text-center my-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="save_reset"
                :loading="process.form"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.reset = false"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Tidak
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.success"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold ml-2">
                Reset Password Berhasil
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3">
              Terima kasih, Anda telah mereset password untuk keamanan data pribadi akun di Bidanvitacare.id
            </v-card-text>

            <v-divider class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.success = false"
                rounded
                elevation="0"
                class="body-2 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in d-flex align-center">
                  <v-icon
                    size="16"
                    class="mr-1">
                    mdi-close-thick
                  </v-icon>

                  Tutup
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.deletes"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/hapus_info.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin menghapus data
                </div>

                <div>
                  <strong>{{ detail.name }}</strong>?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="sheet.detail_shift === true ? deletes_shift() : deletes()"
                :loading="process.form"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Saya yakin
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.deletes = false"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.status"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/status.png')"
                class="ma-auto my-4">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin {{ detail.is_active_bool ? 'menonaktifkan' : 'mengaktifkan' }}
                </div>

                <div
                  class="text-capitalize">
                  <strong>{{ detail.name }}</strong>?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="statuses()"
                :loading="process.form"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Saya yakin
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.status = false"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.go_back"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin akan keluar
                </div>

                <div>
                  dari halaman ini?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                to="/home"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Keluar
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.go_back = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text
              class="pb-0 mt-1">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                filled
                rounded
                dense
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-row>
                <v-col cols="6" class="pr-1">
                  <v-btn
                    @click="sheet.form = true; reset();"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
                    :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                    <div
                      class="btn_auth_in d-flex align-center justify-center"
                      :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                      <div>
                        <v-img
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/add_user.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="ml-2">
                        Tambah Pegawai
                      </div>
                    </div>
                  </v-btn>
                </v-col>
                <v-col cols="6" class="pr-1">
                  <v-btn
                    @click="sheet.shift = true; fetchDataShift()"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
                    :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                    <div
                      class="btn_auth_in d-flex align-center justify-center"
                      :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                      <div>
                        <v-img
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/draft.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="ml-2">
                        Shift
                      </div>
                    </div>
                  </v-btn>
                </v-col>
              </v-row>

            </v-card-text>

            <v-card-text
              class="pb-0"
              style="height: calc(100vh - 185px); overflow: auto;">
              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list.length > 0"
                          flat
                          dense
                          three-line
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-list-item
                            @click="sheet.detail = true; fetchDetail(item.id);">
                            <v-list-item-avatar
                              size="40"
                              class="mt-1"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <div>
                                <v-chip
                                  x-small
                                  class="caption white--text text-capitalize"
                                  :color="item.is_active_bool ? 'green' : 'red'"
                                  style="margin-bottom: 5px;">
                                  {{ item.is_active_bool ? 'Aktif' : 'Non Aktif' }}
                                </v-chip>
                              </div>

                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                {{ item.name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light text-capitalize line-text-second black--text"
                                style="opacity: 70%;">
                                {{ item.role }} - {{ item.store_name }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="body-2">
                                Telp. {{ item.mobilephone }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Pegawai" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.detail"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.detail = false; detail = {};"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.detail = false; detail = {};"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  {{ detail.name }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 80px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-4">
                  <v-avatar
                    tile
                    size="150">
                    <v-img
                      width="100%"
                      height="100%"
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
                            border: 5px solid #0000000d;
                            border-radius: 10px;"
                      alt="bidanvitacare"
                      :src="detail.image_url !== '' ? detail.image_url : require('@/assets/image/member_default.jpg')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                </v-card-text>

                <v-card-text>
                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Status
                    </div>

                    <v-chip
                      small
                      class="caption white--text text-capitalize"
                      :color="detail.is_active_bool ? 'green' : 'red'"
                      style="margin-bottom: 5px;">
                      {{ detail.is_active_bool ? 'Aktif' : 'Non Aktif' }}
                    </v-chip>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Nama Lengkap
                    </div>

                    <div
                      class="body-2 font-weight-bold black--text">
                      {{ detail.name }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Username
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail.username }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Email
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail.email }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      No. Handphone
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail.mobilephone }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Posisi
                    </div>

                    <div
                      class="body-2 black--text text-capitalize">
                      {{ detail.position }} - {{ detail.store_name }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Role
                    </div>

                    <div
                      class="body-2 black--text text-capitalize">
                      {{ detail.role }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Shift
                    </div>

                    <div
                      class="body-2 black--text text-capitalize">
                      {{ detail.shift_name }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Tanggal Join
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail.join_date | date }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Tanggal Expired
                    </div>

                    <div
                      class="body-2 black--text">
                      <span v-if="detail.end_join_date !== ''">
                        {{ detail.end_join_date | date }}
                      </span>

                      <span v-else>
                        -
                      </span>
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Masa Percobaan
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail.probation_status === '-' ? 'Belum Diinput' : detail.probation_status === 'tuntas' ? 'Tuntas' : 'Tidak Tuntas' }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Saldo
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail.saldo | price }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Fee
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail.fee_percent }}%
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Tunjangan Perhari
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail.salary | price }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <!-- <v-card
                flat
                color="#FFFFFF"
                min-height="50"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row>
                    <v-col
                      cols="4"
                      class="py-0">
                      <v-btn
                        @click="sheet.form = true; reset(detail);"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/edit_user.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="blue--text ml-2">
                            Ubah
                          </div>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="4"
                      class="py-0">
                      <v-btn
                        @click="sheet.fee = true; reset(detail);"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/fee.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="green--text ml-2">
                            Fee
                          </div>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="4"
                      class="py-0">
                      <v-btn
                        @click="dialog.reset = true"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/reset.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="orange--text ml-2">
                            Reset
                          </div>
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card> -->

              <v-speed-dial
                v-model="fab"
                :bottom="true"
                :right="true"
                direction="top"
                :open-on-hover="false"
                transition="slide-y-reverse-transition"
                style="position: absolute;">
                <template v-slot:activator>
                  <v-btn
                    v-model="fab"
                    color="#ff808040"
                    fab
                    elevation="0">
                    <v-icon v-if="fab"
                      :color="set_color">
                      mdi-close-thick
                    </v-icon>

                    <v-avatar v-else>
                      <v-img
                        contain
                        width="30"
                        height="30"
                        alt="bidanvitacare"
                        :src="require('@/assets/icon/settings.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                  </v-btn>
                </template>

                <v-btn
                  @click="sheet.form = true; reset(detail);"
                  width="80"
                  rounded
                  dark
                  small
                  color="#E3F2FD"
                  elevation="0"
                  class="d-flex align-center justify-start px-2"
                  style="position: relative; right: 12px;">
                  <v-avatar
                    tile
                    size="20">
                    <v-img
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/edit_user.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>

                  <div
                    class="caption blue--text text-capitalize ml-1">
                    Ubah
                  </div>
                </v-btn>

                <v-btn
                  @click="dialog.status = true; reset(detail);"
                  v-if="user.role === 'superadmin'"
                  width="80"
                  rounded
                  dark
                  small
                  color="#eee3fd"
                  elevation="0"
                  class="d-flex align-center justify-start px-2"
                  style="position: relative; right: 12px;">
                  <v-avatar
                    tile
                    size="20">
                    <v-img
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/status.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>

                  <div
                    class="caption purple--text text-capitalize ml-1">
                    Status
                  </div>
                </v-btn>

                <v-btn
                  @click="sheet.fee = true; reset(detail);"
                  v-if="user.role === 'superadmin'"
                  width="80"
                  rounded
                  dark
                  small
                  color="#E7FFED"
                  elevation="0"
                  class="d-flex align-center justify-start px-2"
                  style="position: relative; right: 12px;">
                  <v-avatar
                    tile
                    size="20">
                    <v-img
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/fee.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>

                  <div
                    class="caption green--text text-capitalize ml-1">
                    Fee
                  </div>
                </v-btn>

                <v-btn
                  @click="dialog.deletes = true"
                  v-if="user.role === 'superadmin'"
                  width="80"
                  rounded
                  dark
                  small
                  color="#ffdede"
                  elevation="0"
                  class="d-flex align-center justify-start px-2"
                  style="position: relative; right: 12px;">
                  <v-avatar
                    tile
                    size="20">
                    <v-img
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/hapus.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>

                  <div
                    class="caption red--text text-capitalize ml-1">
                    Hapus
                  </div>
                </v-btn>

                <v-btn
                  @click="dialog.reset = true"
                  v-if="user.role === 'superadmin'"
                  width="80"
                  rounded
                  dark
                  small
                  color="#FFFADE"
                  elevation="0"
                  class="d-flex align-center justify-start px-2"
                  style="position: relative; right: 12px;">
                  <v-avatar
                    tile
                    size="20">
                    <v-img
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/reset.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>

                  <div
                    class="caption orange--text text-capitalize ml-1">
                    reset
                  </div>
                </v-btn>

                <v-btn
                  @click="sheet.store = true; reset(detail); fetchStore();"
                  v-if="user.role === 'superadmin'"
                  width="80"
                  rounded
                  dark
                  small
                  color="#FFDEDE"
                  elevation="0"
                  class="d-flex align-center justify-start px-2"
                  style="position: relative; right: 12px;">
                  <v-avatar
                    tile
                    size="20">
                    <v-img
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/edit_store.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>

                  <div
                    class="caption red--text text-capitalize ml-1">
                    Lokasi
                  </div>
                </v-btn>

                <v-btn
                  @click="sheet.before_preview_sallary = true; reset(detail);"
                  v-if="user.role === 'superadmin'"
                  width="80"
                  rounded
                  dark
                  small
                  color="#DEFFFE"
                  elevation="0"
                  class="d-flex align-center justify-start px-2"
                  style="position: relative; right: 12px;">
                  <v-avatar
                    tile
                    size="20">
                    <v-img
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/slip_gaji.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>

                  <div
                    class="caption cyan--text text-capitalize ml-1">
                    Slip
                  </div>
                </v-btn>
              </v-speed-dial>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.form"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.form = false; reset();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.form = false; reset();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  {{ form.id === '' ? 'Tambah' : 'Ubah' }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="d-flex align-center pt-16 mt-4">
                  <v-avatar
                    tile
                    size="150">
                    <v-img
                      width="100%"
                      height="100%"
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
                            border: 5px solid #0000000d;
                            border-radius: 10px;"
                      alt="bidanvitacare"
                      :src="form.image_url !== '' ? form.image_url : require('@/assets/image/member_default.jpg')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>

                  <div>
                    <upload-folder
                      v-model="upload.folder">
                      <div slot="activator">
                        <v-btn
                          :disabled="process.file"
                          :loading="process.file"
                          elevation="0"
                          rounded
                          block
                          large
                          color="#0000000d"
                          class="body-1 font-weight-bold text-capitalize d-flex align-center"
                          style="border-radius: 0px 50px 50px 0px;"
                          :style="`color: ${set_color};`">
                          <v-icon
                            size="24"
                            :color="set_color">
                            mdi-camera
                          </v-icon>

                          <span
                            class="ml-1">
                            Foto
                          </span>
                        </v-btn>
                      </div>
                    </upload-folder>
                  </div>
                </v-card-text>

                <v-card-text>
                  <ValidationObserver
                    ref="form">
                    <v-form>
                      <ValidationProvider
                        name="Nama Lengkap"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Nama Lengkap
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. John Doe"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.name"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Username"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Username
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. johndoe"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.username"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Email"
                        rules="required|email"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Email
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. johndoe@gmail.com"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.email"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="No. Handphone"
                        rules="required|min:8|max:13"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          No. Handphone
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 081332XXXXXX"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.mobilephone"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Posisi"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Posisi
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Spesialis"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.position"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Role"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Role
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Terapis"

                          :items="[
                            {
                              text: 'Terapis',
                              value: 'therapist'
                            },
                            {
                              text: 'Admin',
                              value: 'admin'
                            }
                          ]"
                          item-text="text"
                          item-value="value"
                          :item-color="set_color"

                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                          v-model="form.role"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-select>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Shift"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Shift
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Pagi"

                          :items="shift"
                          item-text="name"
                          item-value="id"
                          :item-color="set_color"

                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                          v-model="form.shift_id"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-select>
                      </ValidationProvider>

                      <div>
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Tanggal Join
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-menu
                          ref="pickerjoin_date"
                          min-width="290px"
                          max-width="290px"
                          transition="scale-transition"
                          offset-y
                          :nudge-right="40"
                          :close-on-content-click="false"
                          v-model="picker.join_date">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider
                              name="Tanggal Join"
                              rules="required"
                              v-slot="{ errors, valid }">
                              <v-text-field
                                v-on:keyup.enter="save"
                                readonly
                                filled
                                rounded
                                dense
                                :color="set_color"
                                placeholder="Contoh. 2023-01-01"

                                :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form.join_date = ''"
                                v-on="on"
                                v-bind="attrs"

                                v-model="form.join_date"
                                :success="valid"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>
                          </template>

                          <v-date-picker
                            :color="set_color"
                            v-model="form.join_date"
                            @input="picker.join_date = false">
                          </v-date-picker>
                        </v-menu>
                      </div>

                      <div>
                        <div class="body-2 mb-2">
                          Tanggal Expired
                          <!-- <span>
                            (boleh dikosongi)
                          </span> -->
                        </div>

                        <v-menu
                          ref="pickerend_join_date"
                          min-width="290px"
                          max-width="290px"
                          transition="scale-transition"
                          offset-y
                          :nudge-right="40"
                          :close-on-content-click="false"
                          v-model="picker.end_join_date">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider
                              name="Tanggal Expired"
                              v-slot="{ errors, valid }">
                              <v-text-field
                                v-on:keyup.enter="save"
                                readonly
                                filled
                                rounded
                                dense
                                :color="set_color"
                                placeholder="Contoh. 2023-12-01"

                                :append-icon="form.end_join_date !== '' && form.end_join_date !== null && valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form.end_join_date = ''"
                                v-on="on"
                                v-bind="attrs"

                                v-model="form.end_join_date"
                                :success="form.end_join_date !== '' && form.end_join_date !== null && valid"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>
                          </template>

                          <v-date-picker
                            :color="set_color"
                            v-model="form.end_join_date"
                            @input="picker.end_join_date = false">
                          </v-date-picker>
                        </v-menu>
                      </div>

                      <ValidationProvider
                        name="Masa Percobaan"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Masa Percobaan
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Tuntas"

                          :items="[
                            {
                              text: 'Tuntas',
                              value: 'tuntas'
                            },
                            {
                              text: 'Tidak Tuntas',
                              value: 'tidak_tuntas'
                            },
                            {
                              text: 'Belum Diinput',
                              value: '-'
                            }
                          ]"
                          item-text="text"
                          item-value="value"
                          :item-color="set_color"

                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                          v-model="form.probation_status"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-select>
                      </ValidationProvider>

                      <ValidationProvider v-if="form.id === ''"
                        name="Password"
                        rules="required|min:5|max:20"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Password
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. ******"

                          v-model="form.password"
                          :type="show ? 'text' : 'password'"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="show = !show"
                          :counter="20"

                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center my-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/image/footer/nurse_bvc.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data Pegawai untuk membuat akun di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        v-on:keyup.enter="save"
                        @click="save"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.form = false; reset();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.fee"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.fee = false; reset();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.fee = false; reset();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Fee {{ detail.name }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-4">
                  <ValidationObserver
                    ref="form">
                    <v-form>
                      <ValidationProvider
                        name="Fee %"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Fee %
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 20"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.fee_percent"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Tunjangan Perhari"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Tunjangan Perhari
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 3.000.000"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.salary"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center my-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/icon/fee.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data Fee % untuk memberi fee ke pegawai di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        v-on:keyup.enter="save"
                        @click="save"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.fee = false; reset();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.store"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.store = false; reset(); fetch();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.store = false; reset(); fetch();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Lokasi {{ detail.name }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-5">
                  <v-text-field
                    v-model="search"
                    @click:clear="search = ''; fetchStore();"
                    v-on:keyup.enter="fetchStore()"
                    filled
                    rounded
                    dense
                    single-line
                    hide-details
                    :color="set_color"
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Cari data yang Anda butuhkan ?"
                    clearable>
                  </v-text-field>

                  <v-row>
                    <v-col
                      cols="12">
                      <v-skeleton-loader
                        v-for="(item, index) in process.load ? 4 : list"
                        :key="index"
                        :loading="process.load"
                        :class="index < list.length - 1 ? 'mb-4' : ''"
                        type="list-item-avatar-three-line">
                        <template>
                          <div>
                            <v-list
                              v-if="!process.load && list.length > 0"
                              flat
                              dense
                              three-line
                              color="#FFFFFF40"
                              class="border-radius">
                              <v-list-item
                                @click="form.store_id = item.id">
                                <v-list-item-avatar
                                  tile
                                  size="40"
                                  class="mt-2"
                                  style="border: 2px solid #ffffff; border-radius: 5px !important;">
                                  <v-img
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare"
                                    :src="require('@/assets/icon/store.png')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          size="20"
                                          indeterminate
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title
                                    class="body-2 font-weight-bold">
                                    {{ item.name }}

                                    <div v-if="form.store_id === item.id"
                                      style="position: absolute; top: 0; right: 0;">
                                      <v-icon color="green">mdi-check-circle</v-icon>
                                    </div>
                                  </v-list-item-title>

                                  <v-list-item-subtitle
                                    class="body-2 font-weight-light line-text-first my-1"
                                    style="margin-bottom: 6px !important;">
                                    {{ item.address }}
                                  </v-list-item-subtitle>

                                  <v-list-item-subtitle
                                    class="body-2 font-weight-light black--text">
                                    Buka. <strong class="font-weight-bold">{{ item.open_hours }}</strong>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </div>
                        </template>
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>

                  <Empty v-if="!process.load && pagination.total_page < 1"
                    class="py-6"
                    margin="my-16"
                    size="125"
                    message="Lokasi" />

                  <div v-if="message.error.length > 0"
                    class="text-center my-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/icon/edit_store.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data Lokasi untuk informasi pegawai di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        @click="save_store"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.store = false; reset(); fetch();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.before_preview_sallary"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.before_preview_sallary = false; reset();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.before_preview_sallary = false; reset();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Slip Gaji {{ detail.name }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-4">
                  <ValidationObserver
                    ref="form">
                    <v-form>
                      <div>
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Mulai
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-menu
                          ref="start_date"
                          min-width="290px"
                          max-width="290px"
                          transition="scale-transition"
                          offset-y
                          :nudge-right="40"
                          :close-on-content-click="false"
                          v-model="picker.start">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider
                              name="Mulai"
                              rules="required"
                              v-slot="{ errors, valid }">
                              <v-text-field
                                v-on:keyup.enter="save_preview_sallary"
                                readonly
                                filled
                                rounded
                                dense
                                :color="set_color"
                                placeholder="Contoh. 2023-01-01"

                                :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form.start_date = ''"
                                v-on="on"
                                v-bind="attrs"

                                v-model="form.start_date"
                                :success="valid"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>
                          </template>

                          <v-date-picker
                            :color="set_color"
                            v-model="form.start_date"
                            @input="picker.start = false">
                          </v-date-picker>
                        </v-menu>
                      </div>

                      <div>
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Selesai
                          <!-- <span>
                            (boleh dikosongi)
                          </span> -->
                        </div>

                        <v-menu
                          ref="end_date"
                          min-width="290px"
                          max-width="290px"
                          transition="scale-transition"
                          offset-y
                          :nudge-right="40"
                          :close-on-content-click="false"
                          v-model="picker.end_date">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider
                              name="Selesai"
                              rules="required"
                              v-slot="{ errors, valid }">
                              <v-text-field
                                v-on:keyup.enter="save_preview_sallary"
                                readonly
                                filled
                                rounded
                                dense
                                :color="set_color"
                                placeholder="Contoh. 2023-12-01"

                                :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form.end_date = ''"
                                v-on="on"
                                v-bind="attrs"

                                v-model="form.end_date"
                                :success="form.end_date !== '' && form.end_date !== null && valid"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>
                          </template>

                          <v-date-picker
                            :min="form.start_date"
                            :color="set_color"
                            v-model="form.end_date"
                            @input="picker.end_date = false">
                          </v-date-picker>
                        </v-menu>
                      </div>

                      <ValidationProvider
                        name="Bonus Kinerja"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Bonus Kinerja
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save_preview_sallary()"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 1.000.000"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.bonus_kinerja"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Overtime Perjam"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Overtime Perjam
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save_preview_sallary()"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 1.000.000"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.overtime_perjam"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center my-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/icon/slip_gaji.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data untuk preview slip gaji pegawai di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        @click="save_preview_sallary();"
                        v-on:keyup.enter="save_preview_sallary();"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Preview
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.before_preview_sallary = false; reset();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.sallary"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.preview_sallary = true ; save_preview_sallary();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.preview_sallary = true ; save_preview_sallary();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Slip Gaji {{ detail.name }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-4">
                  <ValidationObserver
                    ref="form">
                    <v-form>
                      <div>
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Mulai
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-menu
                          ref="start_date"
                          min-width="290px"
                          max-width="290px"
                          transition="scale-transition"
                          offset-y
                          :nudge-right="40"
                          :close-on-content-click="false"
                          v-model="picker.start">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider
                              name="Mulai"
                              rules="required"
                              v-slot="{ errors, valid }">
                              <v-text-field
                                v-on:keyup.enter="save_preview_sallary"
                                readonly
                                filled
                                rounded
                                dense
                                :color="set_color"
                                placeholder="Contoh. 2023-01-01"

                                :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form.start_date = ''"
                                v-on="on"
                                v-bind="attrs"

                                v-model="form.start_date"
                                :success="valid"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>
                          </template>

                          <v-date-picker
                            :color="set_color"
                            v-model="form.start_date"
                            @input="picker.start = false">
                          </v-date-picker>
                        </v-menu>
                      </div>

                      <div>
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Selesai
                          <!-- <span>
                            (boleh dikosongi)
                          </span> -->
                        </div>

                        <v-menu
                          ref="end_date"
                          min-width="290px"
                          max-width="290px"
                          transition="scale-transition"
                          offset-y
                          :nudge-right="40"
                          :close-on-content-click="false"
                          v-model="picker.end_date">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider
                              name="Selesai"
                              rules="required"
                              v-slot="{ errors, valid }">
                              <v-text-field
                                v-on:keyup.enter="save_preview_sallary"
                                readonly
                                filled
                                rounded
                                dense
                                :color="set_color"
                                placeholder="Contoh. 2023-12-01"

                                :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form.end_date = ''"
                                v-on="on"
                                v-bind="attrs"

                                v-model="form.end_date"
                                :success="form.end_date !== '' && form.end_date !== null && valid"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>
                          </template>

                          <v-date-picker
                            :min="form.start_date"
                            :color="set_color"
                            v-model="form.end_date"
                            @input="picker.end_date = false">
                          </v-date-picker>
                        </v-menu>
                      </div>

                      <ValidationProvider
                        name="Bonus Kinerja"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Bonus Kinerja
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save_preview_sallary()"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 1.000.000"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.bonus_kinerja"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Overtime Perjam"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Overtime Perjam
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save_preview_sallary()"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 1.000.000"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.overtime_perjam"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Jumlah Absen"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Jumlah Absen
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save_preview_sallary()"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 2"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.count_absen"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Jumlah Overtime (Menit)"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Jumlah Overtime (Menit)
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save_preview_sallary()"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 30"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.total_overtime_minutes"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Fee Layanan"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Fee Layanan
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save_preview_sallary()"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 25.000"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.fee_layanan"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Fee Transport"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Fee Transport
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save_preview_sallary()"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 100.000"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.fee_transport"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center my-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/icon/slip_gaji.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Ubah data dan preview ulang slip gaji pegawai di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        @click="save_preview_sallary()"
                        v-on:keyup.enter="save_preview_sallary()"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          preview
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.preview_sallary = true ; save_preview_sallary();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.preview_sallary"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            flat
            class="background">
            <div
              class="blur">
              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="mt-6">
                  <div
                    class="black--text">
                    <div>
                      <div
                        class="body-1 font-weight-bold text-center mb-2">
                        Bidanvitacare.id
                      </div>

                      <div
                        class="body-2 line-text-second text-center mb-4">
                        Jl. Gito Gati Jl. Gondang Legi No.Dusun, RT.02/RW.13, Rejodani 2, Sariharjo, Kec. Ngaglik, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55581 082259374766
                      </div>

                      <div>
                        <v-divider
                          style="border-top: 2px solid #000000;" />

                        <v-divider
                          style="border-bottom: 1px solid #000000;" />
                      </div>

                      <div
                        class="body-1 font-weight-bold text-center text-decoration-underline my-6">
                        SLIP PENERIMAAN GAJI
                      </div>
                    </div>

                    <div
                      class="mb-6">
                      <v-row>
                        <v-col
                          cols="5"
                          class="py-1">
                          Hari / Tanggal
                        </v-col>

                        <v-col
                          cols="1"
                          class="py-1">
                          :
                        </v-col>

                        <v-col
                          cols="6"
                          class="py-1">
                          {{ preview_sallary.end_date | date }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="5"
                          class="py-1">
                          Nama
                        </v-col>

                        <v-col
                          cols="1"
                          class="py-1">
                          :
                        </v-col>

                        <v-col
                          cols="6"
                          class="py-1">
                          {{ preview_sallary.user_name }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="5"
                          class="py-1">
                          Posisi
                        </v-col>

                        <v-col
                          cols="1"
                          class="py-1">
                          :
                        </v-col>

                        <v-col
                          cols="6"
                          class="py-1">
                          {{ detail.position }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="5"
                          class="py-1">
                          Jumlah Hari Kerja
                        </v-col>

                        <v-col
                          cols="1"
                          class="py-1">
                          :
                        </v-col>

                        <v-col
                          cols="6"
                          class="py-1">
                          {{ preview_sallary.count_absen }}
                        </v-col>
                      </v-row>
                    </div>

                    <div>
                      <v-row>
                        <v-col
                          cols="5"
                          class="py-1">
                          Gaji Pokok
                        </v-col>

                        <v-col
                          cols="1"
                          class="py-1">
                          :
                        </v-col>

                        <v-col
                          cols="6"
                          class="py-1">
                          {{ preview_sallary.gaji_pokok | price }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="5"
                          class="py-1">
                          Bonus Kinerja
                        </v-col>

                        <v-col
                          cols="1"
                          class="py-1">
                          :
                        </v-col>

                        <v-col
                          cols="6"
                          class="py-1">
                          {{ preview_sallary.bonus_kinerja | price }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="5"
                          class="py-1">
                          Overtime {{ preview_sallary.total_overtime_minutes | pricing }} Menit
                        </v-col>

                        <v-col
                          cols="1"
                          class="py-1">
                          :
                        </v-col>

                        <v-col
                          cols="6"
                          class="py-1">
                          {{ parseInt(preview_sallary.gaji_lembur) | price }}
                        </v-col>
                      </v-row>

                      <v-row v-if="user.role === 'therapist'">
                        <v-col
                          cols="5"
                          class="py-1">
                          Therapist Fee
                        </v-col>

                        <v-col
                          cols="1"
                          class="py-1">
                          :
                        </v-col>

                        <v-col
                          cols="6"
                          class="py-1">
                          {{ (parseInt(preview_sallary.fee_layanan) + parseInt(preview_sallary.fee_transport)) | price }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="5"
                          class="py-1">
                          Total Penerimaan
                        </v-col>

                        <v-col
                          cols="1"
                          class="py-1">
                          :
                        </v-col>

                        <v-col
                          cols="6"
                          class="py-1">
                          {{ (parseInt(preview_sallary.gaji_pokok) + parseInt(preview_sallary.bonus_kinerja) + parseInt(preview_sallary.gaji_lembur) + parseInt(preview_sallary.fee_layanan) + parseInt(preview_sallary.fee_transport)) | price }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="5"
                          class="py-1">
                          Terbilang
                        </v-col>

                        <v-col
                          cols="1"
                          class="py-1">
                          :
                        </v-col>

                        <v-col
                          cols="6"
                          class="py-1">
                          {{ terbilang(parseInt(preview_sallary.gaji_pokok) + parseInt(preview_sallary.bonus_kinerja) + parseInt(preview_sallary.gaji_lembur) + parseInt(preview_sallary.fee_layanan) + parseInt(preview_sallary.fee_transport)) }} Rupiah
                        </v-col>
                      </v-row>
                    </div>

                    <div
                      class="body-2 line-text-second text-center mt-6">
                      Semoga Berkah & Selalu Menjaga Amanah Kepercayaan dari Manajemen Bidanvitacare.id, doakan Bidanvitacare.id selalu berkembang dan memberikan manfaat bagi banyak orang. Aamin
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/icon/preview_slip_gaji.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Ubah dan preview atau cetak data slip gaji pegawai di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        @click="sheet.preview_sallary = false; sheet.sallary = true; form.is_first = false"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in">
                          <v-icon
                            size="20"
                            color="blue"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-circle-edit-outline
                          </v-icon>

                          <span
                            class="blue--text">
                            Ubah
                          </span>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        @click="save_sallary()"
                        v-on:keyup.enter="save_sallary()"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Cetak
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>


        <v-bottom-sheet
          v-model="sheet.shift"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">

              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.shift = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="process.load = false; process.limit = false; sheet.shift = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Data Shift
                </span>
              </v-card-title>
              <v-card
                flat
                height="calc(100vh - 80px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-4">
                  <v-text-field
                    v-model="search_shift"
                    @click:clear="search_shift = ''; fetchDataShift();"
                    v-on:keyup.enter="fetchDataShift()"
                    filled
                    rounded
                    dense
                    single-line
                    hide-details
                    :color="set_color"
                    class="mb-4"
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Cari data yang Anda butuhkan ?"
                    clearable>
                  </v-text-field>

                  <v-menu
                    ref="date_list"
                    min-width="290px"
                    max-width="290px"
                    transition="scale-transition"
                    offset-y
                    :nudge-right="40"
                    :close-on-content-click="false"
                    v-model="picker.date_list">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        readonly
                        filled
                        rounded
                        dense
                        :color="set_color"
                        placeholder="Pilih Tanggal"

                        clearable
                        @click:clear="filter.datetime_list = ''; fetchDataShift();"

                        v-on="on"
                        v-bind="attrs"

                        hide-details

                        class="mb-4"

                        v-model="filter.datetime_list">
                      </v-text-field>
                    </template>

                    <v-date-picker
                      :color="set_color"
                      v-model="filter.datetime_list"
                      @input="picker.date_list = false; fetchDataShift();">
                    </v-date-picker>
                  </v-menu>

                  <v-btn
                    @click="sheet.form_shift = true; reset_shift();"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
                    :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                    <div
                      class="btn_auth_in d-flex align-center justify-center"
                      :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                      <div>
                        <v-img
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/draft.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="ml-2">
                        Tambah Shift
                      </div>
                    </div>
                  </v-btn>

                  <div
                    class="black--text">
                    <v-row>
                      <v-col
                        cols="12">
                        <v-skeleton-loader
                          v-for="(item, index) in process.load ? 5 : list_shift"
                          :key="index"
                          :loading="process.load"
                          :class="index < list_shift.length - 1 ? 'mb-4' : ''"
                          type="list-item-avatar-three-line">
                          <template>
                            <div>
                              <v-list
                                v-if="!process.load && list_shift.length > 0"
                                flat
                                dense
                                three-line
                                color="#FFFFFF40"
                                class="border-radius">
                                <v-list-item
                                  @click="sheet.detail_shift = true; detail_shift = item">

                                  <v-list-item-content>
                                    <div>
                                      <v-chip
                                        x-small
                                        class="caption white--text text-capitalize"
                                        :color="'green'"
                                        style="margin-bottom: 5px;">
                                        {{ item.date | dateFull }}
                                      </v-chip>
                                    </div>

                                    <v-list-item-title
                                      class="body-2 font-weight-bold text-capitalize">
                                      {{ item.user_name }}
                                    </v-list-item-title>

                                    <v-list-item-subtitle
                                      class="body-2 font-weight-light text-capitalize line-text-second black--text"
                                      style="opacity: 70%;">
                                      {{ item.shift_name }}
                                    </v-list-item-subtitle>

                                    <v-list-item-subtitle
                                      class="body-2">
                                      Keterangan: {{ item.note }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </div>
                          </template>
                        </v-skeleton-loader>
                      </v-col>
                    </v-row>

                    <div v-if="!process.load && pagination_shift.total_data > 10 && limit < pagination_shift.total_data"
                      class="text-center my-4">
                      <v-btn
                        @click="limit += 10"
                        :loading="process.limit"
                        small
                        rounded
                        outlined
                        elevation="0"
                        :color="set_color"
                        class="text-capitalize"
                        :style="`color: ${set_color};`">
                        Lebih banyak
                      </v-btn>
                    </div>

                    <Empty v-if="!process.load && pagination_shift.total_page < 1"
                      class="py-6"
                      margin="my-16"
                      size="125"
                      message="Data Shift" />
                  </div>
                </v-card-text>
              </v-card>


            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.detail_shift"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card v-if="Object.keys(detail_shift).length > 0"
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.detail_shift = false; detail_shift = {}; fetch();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.detail_shift = false; detail_shift = {}; fetch();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Nama Therapis {{ detail_shift.user_name }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 80px)"
                style="overflow: auto;"
                color="transparent">

                <v-card-text class="pt-12 mt-6">
                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Nama Therapis
                    </div>

                    <div
                      class="body-2 font-weight-bold black--text">
                      {{ detail_shift.user_name }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Tanggal
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail_shift.date | dateFull }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Shift
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail_shift.shift_name }}
                    </div>
                  </div>

                  <div
                    class="mb-4">
                    <div
                      class="caption mb-1"
                      style="opacity: 60%;">
                      Keterangan
                    </div>

                    <div
                      class="body-2 black--text">
                      {{ detail_shift.note }}
                    </div>
                  </div>

                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="50"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="6"
                      class="py-0">
                      <v-btn
                        @click="sheet.form_shift = true; reset_shift(detail_shift);"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/edit_pencil.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="blue--text ml-2">
                            Ubah
                          </div>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="py-0">
                      <v-btn
                        @click="dialog.deletes = true"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/hapus.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="red--text ml-2">
                            Hapus
                          </div>
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.form_shift"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.form_shift = false; reset();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.form_shift = false; reset();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  {{ form_shift.id === '' ? 'Tambah Shift' : 'Ubah Shift' }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 125px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text class="pt-12 mt-6">
                  <ValidationObserver
                    ref="form_shift">
                    <v-form>

                      <div v-if="form_shift.id === ''">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Pilih Tanggal
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-menu
                          ref="pickerjoin_date"
                          min-width="290px"
                          max-width="290px"
                          transition="scale-transition"
                          offset-y
                          :nudge-right="40"
                          :close-on-content-click="false"
                          v-model="picker.join_date">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider
                              name="Pilih Tanggal"
                              rules="required"
                              v-slot="{ errors, valid }">
                              <v-combobox
                                v-model="form_shift.date_array"
                                multiple
                                chips
                                small-chips
                                readonly
                                filled
                                rounded
                                dense
                                autofocus
                                :color="set_color"
                                placeholder="Contoh. 2023-01-01"

                                :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form_shift.date_array = []"
                                v-on="on"
                                v-bind="attrs"
                                :success="valid"
                                :error-messages="errors"
                                required
                              ></v-combobox>
                              <!-- <v-text-field
                                v-model="form_shift.date_array"
                                readonly
                                filled
                                rounded
                                dense
                                :color="set_color"
                                placeholder="Contoh. 2023-01-01"

                                :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form_shift.date_array = []"
                                v-on="on"
                                v-bind="attrs"
                                :success="valid"
                                :error-messages="errors"
                                required>
                              </v-text-field> -->
                            </ValidationProvider>
                          </template>

                          <v-date-picker
                            :color="set_color"
                            multiple
                            v-model="form_shift.date_array"
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="picker.join_date = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.pickerjoin_date.save(form_shift.date_array)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </div>

                      <div v-if="form_shift.id !== ''">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Pilih Tanggal
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-menu
                          ref="pickerjoin_date"
                          min-width="290px"
                          max-width="290px"
                          transition="scale-transition"
                          offset-y
                          :nudge-right="40"
                          :close-on-content-click="false"
                          v-model="picker.join_date">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider
                              name="Pilih Tanggal"
                              rules="required"
                              v-slot="{ errors, valid }">
                              <v-text-field
                                v-model="form_shift.date_array"
                                readonly
                                filled
                                rounded
                                dense
                                :color="set_color"
                                placeholder="Contoh. 2023-01-01"

                                :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form_shift.date_array = []"
                                v-on="on"
                                v-bind="attrs"
                                :success="valid"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>
                          </template>

                          <v-date-picker
                            :color="set_color"
                            v-model="form_shift.date_array"
                            @input="picker.join_date = false"
                            scrollable
                          >
                          </v-date-picker>
                        </v-menu>
                      </div>

                      <ValidationProvider
                        name="Admin / terapis"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Admin / terapis
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-autocomplete
                          v-on:keyup.enter="save_shift"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. John Doe"

                          :items="list_pegawai"
                          item-text="name"
                          item-value="id"
                          :item-color="set_color"

                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                          v-model="form_shift.user_id"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-autocomplete>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Shift"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Shift
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          v-on:keyup.enter="save_shift"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Pagi"

                          :items="shift"
                          item-text="name"
                          item-value="name"
                          :item-color="set_color"

                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                          v-model="form_shift.shift_name"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-select>
                      </ValidationProvider>


                      <ValidationProvider
                        name="Keterangan"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Keterangan
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          v-on:keyup.enter="save_shift"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. FULL"
                          v-on:change="form_shift.shift_note = form_shift.shift_note_selected === 'Lainnya' ? '' : form_shift.shift_note_selected"

                          :items="['FULL', 'Belum Ada Jadwal', 'Lainnya']"
                          :item-color="set_color"

                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                          v-model="form_shift.shift_note_selected"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-select>
                      </ValidationProvider>

                      <div v-if="form_shift.shift_note_selected === 'Lainnya'">
                        <ValidationProvider
                          name="Keterangan Lainnya"
                          rules="required"
                          v-slot="{ errors, valid }">
                          <div class="body-2 mb-2">
                            <span class="red--text">*</span>
                            Keterangan Lainnya
                            <!-- <span class="error--text">
                              (wajib diisi)
                            </span> -->
                          </div>

                          <v-text-field
                            v-on:keyup.enter="save_shift()"
                            filled
                            rounded
                            dense
                            :color="set_color"
                            placeholder="Contoh. Lainnya"
                            :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                            v-model="form_shift.shift_note"
                            :success="valid"
                            :error-messages="errors"
                            required>
                          </v-text-field>
                        </ValidationProvider>
                      </div>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center my-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="125"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        v-on:keyup.enter="save"
                        @click="save_shift"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.form_shift = false; reset_shift();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import axios from "axios";
  import moment from "moment"
  import "moment/locale/id"
  export default {
    middleware: 'authenticated',
    data () {
      return {
        fab: false,
        dialog: {
          reset: false,
          success: false,

          deletes: false,

          status: false,

          go_back: false
        },
        sheet: {
          form: false,
          detail: false,

          fee: false,

          store: false,
          sallary: false,

          preview_sallary: false,
          before_preview_sallary: false,
          shift: false,
          form_shift: false,
          detail_shift: false
        },
        filter: {
          datetime_list: ''
        },
        search: '',
        detail: {},
        shift: [],
        list: [],
        list_pegawai: [],
        pagination: {},
        list_shift: [],
        pagination_shift: {},
        search_shift: '',
        limit_shift: 10,

        limit: 10,

        upload: {
          folder: null
        },
        show: false,
        picker: {
          join_date: false,
          end_join_date: false,

          // SLIP GAJI
          start: false,
          end: false,
          date_list: false
        },

        preview_sallary: {},

        form: {
          id: '',
          name: '',
          username: '',
          email: '',
          image_url: '',
          mobilephone: '',
          position: '',
          role: '',
          join_date: '',
          end_join_date: '',
          password: '',

          fee_percent: 0,
          probation_status: '',

          salary: 0,

          store_id: 0,

          // SLIP GAJI
          user_id: '',
          start_date: '',
          end_date: '',
          count_absen: 0,
          bonus_kinerja: 0,
          overtime_perjam: 0,
          total_overtime_minutes: 0,
          fee_layanan: 0,
          fee_transport: 0,

          is_first: false,

          // STATUS
          is_active: false,

          // SHIFT
          shift_id: ''
        },
        detail_shift: {},
        form_shift: {
          id: '',
          date_array: [],
          user_id: '',
          shift_name: '',
          shift_note: '',
          shift_note_selected: ''
        },
        process: {
          load: false,
          form: false,
          file: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/pegawai',
          title: 'Pegawai',
          description: 'Pegawai Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form.image_url = this.upload.folder.fileuri
            }
          }
        },
        deep: true
      },

      'limit': function() {
        this.process.limit = true

        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()
      this.fetchAll()

      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)

          t.dialog.go_back = true
        })
      },

      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit,
          'role[neq]': 'superadmin'
        }

        await this.$axios.$get(`${process.env.API}admin/user`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },

      async fetchDataShift (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search_shift,
          limit: this.limit_shift,
          date: this.filter.datetime_list
        }

        await this.$axios.$get(`${process.env.API}admin/user_shift/data`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list_shift = response.results.data
            this.pagination_shift = response.results.pagination
          }
        })
      },

      async fetchDataShiftDetail (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          id: this.detail_shift.id
        }

        await this.$axios.$get(`${process.env.API}admin/user_shift/data`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.detail_shift = response.results.data[0]
          }
        })
      },

      async fetchAll () {

        let params = {
          pagination_bool: false,
          'role[neq]': 'superadmin'
        }

        await this.$axios.$get(`${process.env.API}admin/user`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.list_pegawai = response.results.data
          }
        })
      },
      async fetchDetail (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/user/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail = response.results.data
          }
        })
      },
      async fetchStore () {
        this.process.load = true

        let params = {
          search: this.search
        }

        await this.$axios.$get(`${process.env.API}admin/store`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },

      async fetchShift () {
        await this.$axios.$get(`${process.env.API}admin/shift`)
        .then((response) => {
          if (response.status === 200) {
            this.shift = response.results.data
          }
        })
      },

      reset_shift (item) {
        if (this.$refs.form_shift) this.$refs.form_shift.reset()

        this.form_shift = {
          id: item !== undefined ? item.id : '',
          date_array: item !== undefined ? item.date : [],
          user_id: item !== undefined ? item.user_id : '',
          shift_name: item !== undefined ? item.shift_name : '',
          shift_note: item !== undefined ? item.note : '',
          shift_note_selected: item !== undefined ? item.note : ''
        }

        this.picker = {
          join_date: false,
          end_join_date: false
        }

        this.process.form = false

        this.message.error = ''

        if (this.sheet.form_shift) this.fetchShift()
        if (this.sheet.form_shift) this.fetchAll()
      },

      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          id: item !== undefined ? item.id : '',
          name: item !== undefined ? item.name : '',
          username: item !== undefined ? item.username : '',
          email: item !== undefined ? item.email : '',
          image_url: item !== undefined ? item.image_url : '',
          mobilephone: item !== undefined ? item.mobilephone : '',
          position: item !== undefined ? item.position : '',
          role: item !== undefined ? item.role : '',
          join_date: item !== undefined ? item.join_date : '',
          end_join_date: item !== undefined ? item.end_join_date : '',
          password: item !== undefined ? '' : '',

          fee_percent: item !== undefined ? item.fee_percent : 0,
          probation_status: item !== undefined ? item.probation_status : '',

          salary: item !== undefined ? item.salary : 0,

          store_id: item !== undefined ? item.store_id : 0,

          // SLIP GAJI
          user_id: item !== undefined ? item.id : '',
          start_date: '',
          end_date: '',
          count_absen: 0,
          bonus_kinerja: 0,
          overtime_perjam: 0,
          total_overtime_minutes: 0,
          fee_layanan: 0,
          fee_transport: 0,

          is_first: true,

          // STATUS
          is_active: item !== undefined ? item.is_active_bool : '',

          // SHIFT
          shift_id: item !== undefined ? item.shift_id : ''
        }

        this.picker = {
          join_date: false,
          end_join_date: false
        }

        this.process.form = false

        this.message.error = ''

        if (this.sheet.form) this.fetchShift()
      },

      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form.id === '' ? 'admin/user/add' : 'admin/user/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form = false

              this.sheet.fee = false

              this.fetch()

              this.fetchDetail(this.detail.id)
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      async save_reset () {
        this.message.error = ''

        this.process.form = true

        let form = {
          id: this.detail.id
        }

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/user/reset-password`, form)
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.dialog.reset = false

            this.dialog.success = true

            this.fetchDetail(this.detail.id)
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      },

      async save_store () {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/user/update-store`, this.form)
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.sheet.store = false

            this.fetchDetail(this.detail.id)

            this.fetch()
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      },

      async save_sallary () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let params = {
            user_id: this.form.user_id,
            start_date: this.form.start_date,
            end_date: this.form.end_date,
            count_absen: this.form.count_absen,
            bonus_kinerja: this.form.bonus_kinerja,
            overtime_perjam: this.form.overtime_perjam,
            total_overtime_minutes: this.form.total_overtime_minutes,
            fee_layanan: this.form.fee_layanan,
            fee_transport: this.form.fee_transport
          }

          // SAVE TO REST API
          await axios({
            url: `${process.env.API}admin/user/print-slip-gaji`,
            method: 'GET',
            responseType: 'blob', // important,
            params: params,
            headers: {
              Authorization: process.env.API_AUTH,
              token: this.$store.state.member.auth.token
            }
          }).then((response) => {
            let res = response

            if (res.status === 200) {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')

              link.href = url
              link.setAttribute('download', `BVC-Slip-Gaji-${moment().format('DD-MMMM-YYYY', 'id')}-${moment().format('HH:mm:ss', 'id')}.pdf`)
              document.body.appendChild(link)
              link.click()

              this.sheet.before_preview_sallary = false

              this.sheet.sallary = false

              this.sheet.preview_sallary = false

              this.process.form = false

              this.fetchDetail(this.detail.id)
            } else {
              this.process.form = false
              this.message.error = res.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      async save_preview_sallary () {
        this.process.form = true

        let params = {
          user_id: this.form.user_id,
          start_date: this.form.start_date,
          end_date: this.form.end_date,
          count_absen: this.form.count_absen,
          bonus_kinerja: this.form.bonus_kinerja,
          overtime_perjam: this.form.overtime_perjam,
          total_overtime_minutes: this.form.total_overtime_minutes,
          fee_layanan: this.form.fee_layanan,
          fee_transport: this.form.fee_transport,
          // FIRST
          is_first: this.form.is_first
        }

        await this.$axios.$get(`${process.env.API}admin/user/preview-slip-gaji`, { params })
        .then((response) => {
          this.process.form = false

          if (response.status === 200) {
            this.preview_sallary = response.results.data

            this.form.start_date = this.preview_sallary.start_date
            this.form.end_date = this.preview_sallary.end_date
            this.form.count_absen = this.preview_sallary.count_absen
            this.form.bonus_kinerja = this.preview_sallary.bonus_kinerja
            this.form.overtime_perjam = this.preview_sallary.overtime_perjam
            this.form.total_overtime_minutes = this.preview_sallary.total_overtime_minutes
            this.form.fee_layanan = this.preview_sallary.fee_layanan
            this.form.fee_transport = this.preview_sallary.fee_transport

            this.sheet.preview_sallary = true
          }
        })
      },

      async save_shift () {
        this.message.error = ''

        const isValid = await this.$refs.form_shift.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form_shift.id === '' ? 'admin/user_shift/add' : 'admin/user_shift/update'
          let form = this.form_shift.id === '' ? {
            id: this.form_shift.id,
            date_array: this.form_shift.date_array,
            user_id: this.form_shift.user_id,
            shift_name: this.form_shift.shift_name,
            shift_note: this.form_shift.shift_note
          } : {
            id: this.form_shift.id,
            date: this.form_shift.date_array,
            user_id: this.form_shift.user_id,
            shift_name: this.form_shift.shift_name,
            shift_note: this.form_shift.shift_note
          }

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form_shift = false

              this.sheet.fee = false

              this.fetchDataShift()
              this.fetchDataShiftDetail()

            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      terbilang (a) {
        if (isNaN(a)) return 'Not a Number'

        let bilangan = ['','Satu','Dua','Tiga','Empat','Lima','Enam','Tujuh','Delapan','Sembilan','Sepuluh','Sebelas'],
            utama = 0,
            depan = 0,
            belakang = 0,
            kalimat = 0


        // 1 - 11
        if(a < 12){
          kalimat = bilangan[a]
        }
        // 12 - 19
        else if(a < 20){
          kalimat = bilangan[a-10]+' Belas'
        }
        // 20 - 99
        else if(a < 100){
          utama = a/10
          depan = parseInt(String(utama).substr(0,1))
          belakang = a%10
          kalimat = bilangan[depan]+' Puluh '+bilangan[belakang]
        }
        // 100 - 199
        else if(a < 200){
          kalimat = 'Seratus '+ this.terbilang(a - 100)
        }
        // 200 - 999
        else if(a < 1000){
          utama = a/100
          depan = parseInt(String(utama).substr(0,1))
          belakang = a%100
          kalimat = bilangan[depan] + ' Ratus '+ this.terbilang(belakang)
        }
        // 1,000 - 1,999
        else if(a < 2000){
          kalimat = 'Seribu '+ this.terbilang(a - 1000)
        }
        // 2,000 - 9,999
        else if(a < 10000){
          utama = a/1000
          depan = parseInt(String(utama).substr(0,1))
          belakang = a%1000
          kalimat = bilangan[depan] + ' Ribu '+ this.terbilang(belakang)
        }
        // 10,000 - 99,999
        else if(a < 100000){
          utama = a/100
          depan = parseInt(String(utama).substr(0,2))
          belakang = a%1000
          kalimat = this.terbilang(depan) + ' Ribu '+ this.terbilang(belakang)
        }
        // 100,000 - 999,999
        else if(a < 1000000){
          utama = a/1000
          depan = parseInt(String(utama).substr(0,3))
          belakang = a%1000
          kalimat = this.terbilang(depan) + ' Ribu '+ this.terbilang(belakang)
        }
        // 1,000,000 - 	99,999,999
        else if(a < 100000000){
          utama = a/1000000
          depan = parseInt(String(utama).substr(0,4))
          belakang = a%1000000
          kalimat = this.terbilang(depan) + ' Juta '+ this.terbilang(belakang)
        }
        else if(a < 1000000000){
          utama = a/1000000
          depan = parseInt(String(utama).substr(0,4))
          belakang = a%1000000
          kalimat = this.terbilang(depan) + ' Juta '+ this.terbilang(belakang)
        }
        else if(a < 10000000000){
          utama = a/1000000000
          depan = parseInt(String(utama).substr(0,1))
          belakang = a%1000000000
          kalimat = this.terbilang(depan) + ' Milyar '+ this.terbilang(belakang)
        }
        else if(a < 100000000000){
          utama = a/1000000000
          depan = parseInt(String(utama).substr(0,2))
          belakang = a%1000000000
          kalimat = this.terbilang(depan) + ' Milyar '+ this.terbilang(belakang)
        }
        else if(a < 1000000000000){
          utama = a/1000000000
          depan = parseInt(String(utama).substr(0,3))
          belakang = a%1000000000
          kalimat = this.terbilang(depan) + ' Milyar '+ this.terbilang(belakang)
        }
        else if(a < 10000000000000){
          utama = a/10000000000
          depan = parseInt(String(utama).substr(0,1))
          belakang = a%10000000000
          kalimat = this.terbilang(depan) + ' Triliun '+ this.terbilang(belakang)
        }
        else if(a < 100000000000000){
          utama = a/1000000000000
          depan = parseInt(String(utama).substr(0,2))
          belakang = a%1000000000000
          kalimat = this.terbilang(depan) + ' Triliun '+ this.terbilang(belakang)
        }

        else if(a < 1000000000000000){
          utama = a/1000000000000
          depan = parseInt(String(utama).substr(0,3))
          belakang = a%1000000000000
          kalimat = this.terbilang(depan) + ' Triliun '+ this.terbilang(belakang)
        }

        else if(a < 10000000000000000){
          utama = a/1000000000000000
          depan = parseInt(String(utama).substr(0,1))
          belakang = a%1000000000000000
          kalimat = this.terbilang(depan) + ' Kuadriliun '+ this.terbilang(belakang)
        }

        let pisah = kalimat.split(' '),
            full = []

        for (let i=0; i<pisah.length; i++){
          if (pisah[i] != '') {
            full.push(pisah[i])
          }
        }

        return full.join(' ')
      },

      async deletes () {
        this.message.error = ''

        this.process.form = true

          // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/user/delete`, {
          id: this.detail.id
        })
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.dialog.deletes = false

            this.sheet.detail = false

            this.fetch()
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      },

      async deletes_shift () {
        this.message.error = ''

        this.process.form = true

          // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/user_shift/delete`, {
          id: this.detail_shift.id
        })
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.dialog.deletes = false

            this.sheet.detail_shift = false

            this.fetchDataShift()
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      },

      async statuses () {
        this.message.error = ''

        this.process.form = true

        let status = this.form.is_active ? false : true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/user/update-status`, {
          id: this.form.id,
          is_active: status.toString()
        })
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.dialog.status = false

            this.fetch()

            this.fetchDetail(this.detail.id)
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      }
    }
  }
</script>
