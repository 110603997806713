<template>
  <div>
    <v-dialog v-model="dialog.partner_or_terapis"
      persistent
      scrollable>
      <v-card
        class="border-radius pa-2">
        <v-card-title
          class="d-flex align-center py-0 px-3 text-right popup-header">
          <v-avatar
            size="30"
            tile>
            <v-img
              alt="bidanvitacare"
              :src="require('@/assets/logo/favicon.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    size="20"
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <span
            class="body-1 font-weight-bold text-capitalize ml-2">
            {{ selected.type_user }}
          </span>
        </v-card-title>

        <v-divider
          class="mx-4 mt-3 mb-2"/>

        <v-card-text v-if="selected.partner_or_terapis.length > 0"
          class="body-2 text-first line-text-second px-4 pb-2">
          <v-row>
            <v-col
              v-for="(item, index) in selected.partner_or_terapis"
              :key="index"
              cols="12">
              <v-list
                flat
                dense
                color="#FFFFFF40"
                class="border-radius"
                style="border: 1px dashed #bbb;">
                <v-list-item>
                  <v-list-item-avatar
                    size="40"
                    style="border: 2px solid #ffffff;">
                    <v-img
                      width="100%"
                      height="100%"
                      alt="bidanvitacare"
                      :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      class="body-2 font-weight-bold text-capitalize">
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider
          class="mx-4 mb-3 mt-0"/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            @click="dialog.partner_or_terapis = false"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
            <div
              class="btn_cancel_in">
              <v-icon
                size="20">
                mdi-close-thick
              </v-icon>

              <span
                class="ml-1">
                Tutup
              </span>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.gambar_rumah"
      persistent
      scrollable>
      <v-card
        class="border-radius pa-2">
        <v-card-title
          class="d-flex align-center py-0 px-3 text-right popup-header">
          <v-avatar
            size="30"
            tile>
            <v-img
              alt="bidanvitacare"
              :src="require('@/assets/logo/favicon.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    size="20"
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <span
            class="body-1 font-weight-bold text-capitalize ml-2">
            Gambar Rumah
          </span>
        </v-card-title>

        <v-divider
          class="mx-4 mt-3 mb-2"/>

        <v-card-text
          class="body-2 text-first line-text-second px-4 pb-2 my-4">
          <v-img
            contain
            width="100%"
            height="250"
            style="border: 5px solid #0000000d;
                  border-radius: 10px;"
            alt="bidanvitacare"
            :src="detail.address_image_url !== '' ? detail.address_image_url : require('@/assets/image/image_default.png')"
            class="ma-auto">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular
                  indeterminate
                  :color="set_color">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card-text>

        <v-divider
          class="mx-4 mb-3 mt-0"/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            @click="dialog.gambar_rumah = false"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
            <div
              class="btn_cancel_in">
              <v-icon
                size="20">
                mdi-close-thick
              </v-icon>

              <span
                class="ml-1">
                Tutup
              </span>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.permission"
      persistent>
      <v-card
        class="border-radius">
        <v-card-title
          class="d-flex align-center pa-3 text-right popup-header">
          <v-avatar
            size="30"
            tile>
            <v-img
              alt="amanata"
              :src="require('@/assets/logo/favicon.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <span
            class="body-1 font-weight-bold text-capitalize ml-2">
            Permission Telah di block
          </span>
        </v-card-title>

        <v-divider
          class="mb-2"
          style="border-top: 2px dashed #e0e0e0;"/>

        <v-card-text
          class="body-2 text-first line-text-second mt-3">
          Silahkan aktifkan permission lokasi di perangkat Anda terlebih dahulu.
        </v-card-text>
      </v-card>
    </v-dialog>

    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text
          class="pb-0">
          <div
            class="d-flex align-center">
            <v-avatar
              size="36"
              style="border: 2px solid #ffffff;">
              <v-img
                width="100%"
                height="100%"
                alt="bidanvitacare"
                :src="detail.customer_image_url !== '' ? detail.customer_image_url : require('@/assets/image/member_default.jpg')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>

            <span
              class="font-weight-bold text-capitalize black--text ml-2">
              {{ detail.customer_name }}
            </span>

            <v-spacer />

            <v-btn
              @click="sheet.riwayat = true; fetchRiwayat();"
              width="36"
              height="36"
              fab
              dark
              :color="set_color"
              elevation="0">
              <v-icon>mdi-clipboard-text-clock-outline</v-icon>
            </v-btn>
          </div>
        </v-card-text>

        <v-card-text
          class="pb-0">
          <v-divider
            class="mb-4"
            style="border-top: 1px dashed #bbb;" />

          <div
            class="d-flex align-center">
            <div>
              <div
                class="body-2 mb-1">
                Tipe
              </div>

              <div
                class="body-2 font-weight-bold black--text">
                {{ detail.type === 'on_site' ? 'On Site' : 'Home Care' }}
              </div>
            </div>

            <v-spacer />

            <div>
              <div
                class="body-2 mb-1">
                Umur
              </div>

              <div
                class="body-2 font-weight-bold black--text">
                {{ detail.age !== '' ? detail.age : '-' }}
              </div>
            </div>
          </div>

          <v-divider
            class="my-4"
            style="border-top: 1px dashed #bbb;" />

          <div
            class="d-flex align-center">
            <div>
              <div
                class="body-2 mb-1">
                BB
              </div>

              <div
                class="body-2 black--text font-weight-bold">
                {{ detail.bb }} Kg
              </div>
            </div>

            <v-spacer v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'" />

            <div v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'">
              <div
                class="body-2 mb-1">
                TB
              </div>

              <div
                class="body-2 black--text font-weight-bold">
                {{ detail.tb }} Cm
              </div>
            </div>

            <v-spacer v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'" />

            <div v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'">
              <div
                class="body-2 mb-1">
                LILA
              </div>

              <div
                class="body-2 black--text font-weight-bold">
                {{ detail.lila }} Cm
              </div>
            </div>

            <v-spacer v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'" />

            <div v-if="detail.customer_type === 'bayi' || detail.customer_type === 'anak'">
              <div
                class="body-2 mb-1">
                LK
              </div>

              <div
                class="body-2 black--text font-weight-bold">
                {{ detail.lk }} Cm
              </div>
            </div>

            <v-spacer v-if="detail.customer_type !== 'bayi' && detail.customer_type !== 'anak'" />

            <div v-if="detail.customer_type !== 'bayi' && detail.customer_type !== 'anak'">
              <div
                class="body-2 mb-1">
                Tensi
              </div>

              <div
                class="body-2 black--text font-weight-bold">
                {{ detail.tensi }} mmHg
              </div>
            </div>
          </div>

          <v-divider
            class="my-4"
            style="border-top: 1px dashed #bbb;" />

          <div
            class="d-flex align-center">
            <div
              class="body-2">
              Klien
            </div>

            <v-spacer />

            <div>
              <div
                @click="dialog.partner_or_terapis = true;
                        selected.type_user = 'Klien';
                        selected.partner_or_terapis = detail.patner;"
                class="d-flex align-center cursor-pointer">
                <v-avatar
                  v-for="(item_customer, index_customer) in detail.patner"
                  :key="index_customer"
                  v-show="index_customer < 2"
                  size="24"
                  class="mt-0"
                  style="border: 2px solid #ffffff;"
                  :style="detail.patner.length === 2 && index_customer === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                          detail.patner.length > 2 && index_customer === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                          detail.patner.length > 2 && index_customer === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                  <v-img
                    width="100%"
                    height="100%"
                    alt="bidanvitacare"
                    :src="item_customer.image_url !== '' ? item_customer.image_url : require('@/assets/image/member_default.jpg')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="20"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <span v-if="detail.patner.length < 2"
                  class="caption black--text font-weight-bold one-line text-capitalize ml-1"
                  style="width: 50px;">
                  {{ detail.patner[0].name }}
                </span>

                <span v-if="detail.patner.length > 2"
                  class="caption black--text font-weight-bold ml-1">
                  +{{ detail.patner.length - 2 }}
                </span>
              </div>
            </div>
          </div>

          <!-- <v-divider
            class="my-4"
            style="border-top: 1px dashed #bbb;" />

          <div
            class="d-flex align-center">
            <div
              class="body-2">
              Terapis
            </div>

            <v-spacer />

            <div>
              <div
                @click="dialog.partner_or_terapis = true;
                        selected.type_user = 'Terapis';
                        selected.partner_or_terapis = detail.therapist;"
                class="d-flex align-center cursor-pointer">
                <v-avatar
                  v-for="(item_therapist, index_therapist) in detail.therapist"
                  :key="index_therapist"
                  v-show="index_therapist < 2"
                  size="24"
                  class="mt-0"
                  style="border: 2px solid #ffffff;"
                  :style="detail.therapist.length === 2 && index_therapist === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                          detail.therapist.length > 2 && index_therapist === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                          detail.therapist.length > 2 && index_therapist === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                  <v-img
                    width="100%"
                    height="100%"
                    alt="bidanvitacare"
                    :src="item_therapist.image_url !== '' ? item_therapist.image_url : require('@/assets/image/member_default.jpg')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="20"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <span v-if="detail.therapist.length < 2"
                  class="caption black--text font-weight-bold one-line ml-1"
                  style="width: 50px;">
                  {{ detail.therapist[0].name }}
                </span>

                <span v-if="detail.therapist.length > 2"
                  class="caption black--text font-weight-bold ml-1">
                  +{{ detail.therapist.length - 2 }}
                </span>
              </div>
            </div>
          </div> -->

          <v-divider
            class="my-4"
            style="border-top: 1px dashed #bbb;" />

          <div>
            <div
              class="body-2 mb-2">
              Alat yang harus dibawa
            </div>

            <div
              class="body-2">
              <ul v-if="detail.alat.length > 0"
                style="position: relative; left: -10px;">
                <li
                  v-for="(item, index) in detail.alat"
                  :key="index"
                  :class="index < detail.alat.length - 1 ? 'mb-1' : ''"
                  v-html="item.name">
                </li>
              </ul>

              <span v-else>
                -
              </span>
            </div>
          </div>

          <v-divider
            class="my-4"
            style="border-top: 1px dashed #bbb;" />

          <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Tanggal & Waktu Kunjungan
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              : {{ detail.datetime | date }} Pukul {{ detail.datetime | time }}
            </v-col>
          </v-row>

          <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Status
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              : {{ detail.customer_wali_status !== '' ? detail.customer_wali_status : '-' }}
            </v-col>
          </v-row>

          <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Nama Wali
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              : {{ detail.customer_wali_name !== '' ? detail.customer_wali_name : '-' }}
            </v-col>
          </v-row>

          <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Nama Pendamping
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              : {{ detail.schedule_wali !== '' ? detail.schedule_wali : '-' }}
            </v-col>
          </v-row>

          <!-- <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Keluhan
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              <div
                class="d-flex align-start">
                <div>
                  :
                </div>

                <div
                  class="ml-1">
                  {{ detail.complaint !== '' ? detail.complaint : '-' }}
                </div>
              </div>
            </v-col>
          </v-row> -->

          <!-- <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Keterangan
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              <div
                class="d-flex align-start">
                <div>
                  :
                </div>

                <div
                  class="ml-1">
                  {{ detail.note !== '' ? detail.note : '-' }}
                </div>
              </div>
            </v-col>
          </v-row> -->

          <v-divider
            class="my-4"
            style="border-top: 1px dashed #bbb;" />

          <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Alamat
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              <div
                class="d-flex align-start">
                <div>
                  :
                </div>

                <div
                  class="ml-1">
                  {{ detail.address_full !== '' ? detail.address_full : '-' }}
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Kecamatan
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              : {{ detail.address_subdistrict_name !== '' ? detail.address_subdistrict_name : '-' }}
            </v-col>
          </v-row>

          <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Kabupaten
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              : {{ detail.address_city_name !== '' ? detail.address_city_name : '-' }}
            </v-col>
          </v-row>

          <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Provinsi
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              : {{ detail.address_province_name !== '' ? detail.address_province_name : '-' }}
            </v-col>
          </v-row>

          <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Rumah
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              <div
                class="d-flex align-start">
                <div>
                  :
                </div>

                <div
                  class="ml-1">
                  {{ detail.address_note !== '' ? detail.address_note : '-' }}
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Gambar Rumah
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              <div
                class="d-flex align-start">
                <div>
                  :
                </div>

                <div
                  class="ml-1">
                  <span
                    @click="dialog.gambar_rumah = true"
                    class="cursor-pointer"
                    style="color: #1976d3;">
                    Lihat disini
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Link Map
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              <div
                class="d-flex align-start">
                <div>
                  :
                </div>

                <div
                  class="text-truncate ml-1">
                  <a v-if="detail.address_link_map !== ''"
                    :href="detail.address_link_map"
                    target="_blank">
                    {{ detail.address_link_map }}
                  </a>

                  <span v-else>
                    -
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>

          <!-- <v-divider
            class="my-4"
            style="border-top: 1px dashed #bbb;" /> -->

          <!-- <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Komplain untuk Terapis
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              <div
                class="d-flex align-start">
                <div>
                  :
                </div>

                <div
                  class="ml-1">
                  {{ detail.therapist_complaint !== '' ? detail.therapist_complaint : '-' }}
                </div>
              </div>
            </v-col>
          </v-row> -->

          <!-- <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Saran / Masukkan
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              <div
                class="d-flex align-start">
                <div>
                  :
                </div>

                <div
                  class="ml-1">
                  {{ detail.therapist_suggest !== '' ? detail.therapist_suggest : '-' }}
                </div>
              </div>
            </v-col>
          </v-row> -->

          <div v-if="detail.type === 'home_care'">
            <v-divider
              class="my-4"
              style="border-top: 1px dashed #bbb;" />

            <div
              class="body-2 font-weight-bold black--text"
              :class="detail.absen.length < 2 ? 'mb-2' : 'mb-4'">
              Absen
            </div>

            <div v-if="detail.absen.length < 2"
              class="d-flex align-center"
              :class="detail.absen.length > 0 ? 'mb-6' : ''">
              <div
                class="line-text-second"
                style="width: 50%;">
                Silahkan melakukan absensi treatment
              </div>

              <v-spacer />

              <div>
                <v-btn
                  @click="reset_attendance(); fetchAbsen();"
                  fab
                  width="40"
                  height="40"
                  elevation="0">
                  <v-avatar
                    size="40">
                    <v-img
                      contain
                      width="100%"
                      height="100%"
                      alt="bidanvitacare"
                      :src="detail.absen.length < 1 ? require('@/assets/icon/fingerprint_attendance_start.png') : require('@/assets/icon/fingerprint_attendance_stop.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                </v-btn>
              </div>
            </div>

            <div v-if="detail.absen.length > 0">
              <v-row
                v-for="(item, index) in detail.absen"
                :key="index"
                class="mb-4">
                <v-col
                  cols="4"
                  class="py-0 text-capitalize">
                  {{ item.status }}
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  <div
                    class="d-flex align-start">
                    <div>
                      :
                    </div>

                    <div
                      class="ml-1">
                      <div
                        style="margin-bottom: 3px;">
                        {{ item.create_datetime | date }} Pukul {{ item.create_datetime | time }}
                      </div>

                      <a
                        :href="`http://maps.google.com/maps?q=${item.latitude},${item.longitude}`"
                        target="_blank"
                        class="d-flex align-center">
                        <v-icon size="18" color="blue">mdi-map-marker-radius</v-icon>

                        <span
                          class="ml-1">
                          Lihat lokasi
                        </span>
                      </a>
                    </div>

                    <div v-if="item.status === 'selesai' && count_attachment < 1"
                      class="ml-1">
                      <v-icon size="18" color="red">mdi-paperclip-off</v-icon>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>

          <v-divider
            class="my-4"
            style="border-top: 1px dashed #bbb;" />

          <div
            class="d-flex align-center mb-1">
            <div
              class="body-2 black--text font-weight-bold">
              Layanan
            </div>

            <v-spacer />

            <div>
              <v-btn
                @click="sheet.item = true; fetchItem();"
                :disabled="status_required.absen || status_required.is_done_bool"
                small
                rounded
                :color="set_color"
                elevation="0"
                class="text-capitalize d-flex align-center white--text">
                <v-icon small>mdi-plus-circle-outline</v-icon>

                <span
                  class="ml-1">
                  Tambah
                </span>
              </v-btn>
            </div>
          </div>

          <v-row>
            <v-col
              v-for="(item, index) in detail.detail"
              :key="index"
              cols="12">
              <v-card
                flat
                color="#FFFFFF40"
                class="pa-3 pt-2"
                style="border: 2px dashed #bbb; border-radius: 10px;">
                <v-list
                  flat
                  dense
                  three-line
                  color="transparent"
                  class="py-0">
                  <v-list-item
                    class="px-0">
                    <v-list-item-avatar
                      size="40"
                      tile
                      class="mt-3"
                      style="border: 2px solid #ffffff; border-radius: 5px !important;">
                      <v-img
                        contain
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="item.item_image_url !== '' ? item.item_image_url : require('@/assets/image/image_default.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <div
                        class="body-2 font-weight-bold text-capitalize">
                        <span
                          class="two-line">
                          {{ item.item_name }}
                        </span>
                      </div>

                      <!-- <div>
                        <v-divider
                          class="my-2"
                          style="border-top: 1px dashed #bbb;" />
                      </div> -->

                      <div
                        class="pa-3 mt-2 mb-3"
                        style="border: 2px dashed #bbb; border-radius: 10px;">
                        <div
                          class="body-2 font-weight-light line-text-second black--text"
                          style="opacity: 70%;">
                          <div
                            class="line-text-second">
                            Durasi: {{ item.item_duration_minutes }} menit
                          </div>

                          <div>
                            Catatan: {{ item.order_note !== '' ? item.order_note : '' }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="d-flex align-center">
                        <div>
                          <div
                            class="body-2 mb-1"
                            style="opacity: 70%;">
                            Mulai
                          </div>

                          <div
                            class="body-2 font-weight-bold black--text">
                            <span v-if="item.absen_detail.length > 0"
                              @click="selected.form_attendance_item_index = index; reset_attendance_countdown();"
                              :class="!item.is_end_bool ? 'blue--text cursor-pointer' : ''">
                              {{ item.absen_detail[0].create_datetime | time }}
                            </span>

                            <span v-else>
                              <span v-if="detail.type === 'home_care' && detail.absen.length < 2">
                                -
                              </span>

                              <v-btn v-if="(detail.type === 'home_care' && detail.absen.length > 1) || detail.type === 'on_site'"
                                @click="selected.form_attendance_item_index = index; reset_attendance_detail(); fetchAbsen();"
                                fab
                                width="40"
                                height="40"
                                elevation="0">
                                <v-avatar
                                  size="40">
                                  <v-img
                                    contain
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare"
                                    :src="require('@/assets/icon/fingerprint_attendance_start.png')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          indeterminate
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>
                              </v-btn>
                            </span>
                          </div>
                        </div>

                        <v-spacer />

                        <div>
                          <div
                            class="body-2 mb-1"
                            style="opacity: 70%;">
                            Selesai
                          </div>

                          <div
                            class="body-2 font-weight-bold black--text">
                            <span v-if="item.absen_detail.length > 1">
                              {{ item.absen_detail[1].create_datetime | time }}
                            </span>

                            <span v-else>
                              <span v-if="detail.type === 'home_care' && detail.absen.length < 2">
                                -
                              </span>

                              <v-btn v-if="(detail.type === 'home_care' && detail.absen.length > 1) || detail.type === 'on_site'"
                                @click="selected.form_attendance_item_index = index; reset_attendance_detail(); fetchAbsen();"
                                fab
                                width="40"
                                height="40"
                                elevation="0">
                                <v-avatar
                                  size="40">
                                  <v-img
                                    contain
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare"
                                    :src="require('@/assets/icon/fingerprint_attendance_stop.png')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          indeterminate
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>
                              </v-btn>
                            </span>
                          </div>
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>

          <!-- <div v-if="detail.is_record_bool">
            <v-divider
              class="my-4"
              style="border-top: 1px dashed #bbb;" />

            <div
              class="body-2 font-weight-bold black--text mb-4">
              Laporan Harian
            </div>

            <v-row
              class="mb-2">
              <v-col
                cols="4"
                class="py-0">
                Keterangan
              </v-col>

              <v-col
                cols="8"
                class="py-0">
                <div
                  class="d-flex align-start">
                  <div>
                    :
                  </div>

                  <div
                    class="ml-1">
                    {{ detail.report_note !== '' ? detail.report_note : '-' }}
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row
              class="mb-2">
              <v-col
                cols="4"
                class="py-0">
                Perihal Kesehatan Ibu & Anak
              </v-col>

              <v-col
                cols="8"
                class="py-0">
                <div
                  class="d-flex align-start">
                  <div>
                    :
                  </div>

                  <div
                    class="ml-1">
                    {{ detail.report_mom_health !== '' ? detail.report_mom_health : '-' }}
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row
              class="mb-2">
              <v-col
                cols="4"
                class="py-0">
                Pertanyaan
              </v-col>

              <v-col
                cols="8"
                class="py-0">
                <div
                  class="d-flex align-start">
                  <div>
                    :
                  </div>

                  <div
                    class="ml-1">
                    {{ detail.report_question !== '' ? detail.report_question : '-' }}
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row
              class="mb-2">
              <v-col
                cols="4"
                class="py-0">
                Kesalahan yang dilakukan Terapis
              </v-col>

              <v-col
                cols="8"
                class="py-0">
                <div
                  class="d-flex align-start">
                  <div>
                    :
                  </div>

                  <div
                    class="ml-1">
                    {{ detail.report_mistake !== '' ? detail.report_mistake : '-' }}
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row
              class="mb-2">
              <v-col
                cols="4"
                class="py-0">
                Keluhan
              </v-col>

              <v-col
                cols="8"
                class="py-0">
                <div
                  class="d-flex align-start">
                  <div>
                    :
                  </div>

                  <div
                    class="ml-1">
                    {{ detail.report_complaint !== '' ? detail.report_complaint : '-' }}
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row
              class="mb-2">
              <v-col
                cols="4"
                class="py-0">
                Masukkan
              </v-col>

              <v-col
                cols="8"
                class="py-0">
                <div
                  class="d-flex align-start">
                  <div>
                    :
                  </div>

                  <div
                    class="ml-1">
                    {{ detail.report_suggest !== '' ? detail.report_suggest : '-' }}
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row
              class="mb-2">
              <v-col
                cols="4"
                class="py-0">
                Promosi
              </v-col>

              <v-col
                cols="8"
                class="py-0">
                <div
                  class="d-flex align-start">
                  <div>
                    :
                  </div>

                  <div
                    class="ml-1">
                    {{ detail.report_promotion !== '' ? detail.report_promotion : '-' }}
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row
              class="mb-2">
              <v-col
                cols="4"
                class="py-0">
                Apakah sudah selesai ?
              </v-col>

              <v-col
                cols="8"
                class="py-0">
                <div
                  class="d-flex align-start">
                  <div>
                    :
                  </div>

                  <div
                    class="ml-1">
                    {{ detail.is_done_bool ? 'Sudah' : 'Belum' }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </div> -->

          <!-- <div v-if="detail.kpsp.length > 0">
            <v-divider
              class="my-4"
              style="border-top: 1px dashed #bbb;" />

            <div
              class="mb-4">
              <div
                class="body-2 black--text font-weight-bold mb-1">
                KPSP
              </div>

              <div>
                Pilih data kpsp untuk mengubah jawaban dibawah ini.
              </div>
            </div>

            <v-list
              v-for="(item, index) in detail.kpsp"
              :key="index"
              :class="index < detail.kpsp.length - 1 ? 'mb-4' : ''"
              flat
              dense
              two-line
              color="#FFFFFF40"
              class="border-radius">
              <v-list-item>
                <v-list-item-avatar
                  size="40"
                  tile
                  class="mt-0"
                  style="border: 2px solid #ffffff; border-radius: 5px !important;">
                  <v-img
                    contain
                    width="100%"
                    height="100%"
                    alt="bidanvitacare"
                    :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="20"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    class="body-2 font-weight-bold text-capitalize">
                    {{ item.type }} - {{ item.age_month }} Bulan
                  </v-list-item-title>

                  <v-list-item-subtitle
                    class="body-2 font-weight-light line-text-second black--text"
                    style="opacity: 70%;">
                    {{ item.question }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle
                    class="body-2 font-weight-bold">
                    Jawaban:

                    <span
                      :class="item.answer === 'YA' ? 'green--text' : item.answer === 'TIDAK' ? 'red--text' : ''">
                      {{ item.answer !== '' ? item.answer : '-' }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div> -->
        </v-card-text>
      </v-card>
    </section>

    <v-bottom-sheet
      v-model="sheet.attendance"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.attendance = false; $emit('load');"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.attendance = false; $emit('load');"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Absensi
            </span>
          </v-card-title>

          <v-card-text
            class="pt-16">
            <v-row
              align="center"
              style="height: calc(100vh - 70px); overflow-y: auto;">
              <v-col
                cols="12"
                class="pa-0">
                <ValidationObserver
                  ref="form_attendance">
                  <v-form>
                    <div
                      class="text-center">
                      <div>
                        <div
                          class="title font-weight-bold mb-6">
                          Hai, {{ detail.therapist_name }}
                        </div>

                        <div
                          class="text-h4 font-weight-bold black--text">
                          {{ detail.datetime | time }}
                        </div>

                        <div
                          class="mb-8">
                          {{ detail.datetime | date }}
                        </div>

                        <div
                          class="mb-1">
                          <v-chip
                            small
                            class="caption white--text text-capitalize"
                            :color="set_color">
                            {{ detail.category_name !== '' ? detail.category_name : '-' }}
                          </v-chip>
                        </div>

                        <div
                          class="font-weight-bold">
                          {{ detail.item_name }}
                        </div>


                        <v-divider
                          class="my-2 mx-12"
                          style="border-top: 1px dashed #bbbbbb" />

                        <div
                          class="mb-16">
                          {{ detail.customer_name }} - {{ detail.customer_mobilephone | phone }}
                        </div>
                      </div>

                      <v-btn v-if="process.absen"
                        disabled
                        fab
                        width="175"
                        height="175"
                        elevation="0"
                        class="mt-2"
                        style="filter: grayscale(100%);">
                        <v-avatar
                          size="150">
                          <v-img
                            contain
                            width="100%"
                            height="100%"
                            alt="bidanvitacare"
                            :src="form_attendance.status === 'berangkat' || form_attendance.status === 'mulai' ? require('@/assets/icon/fingerprint_attendance_start.png') : require('@/assets/icon/fingerprint_attendance_stop.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </v-btn>

                      <v-btn v-if="!process.absen"
                        @click="save_attendance"
                        :loading="process.form"
                        :disabled="process.form"
                        fab
                        width="175"
                        height="175"
                        elevation="0"
                        class="mt-2">
                        <v-avatar
                          size="150">
                          <v-img
                            contain
                            width="100%"
                            height="100%"
                            alt="bidanvitacare"
                            :src="form_attendance.status === 'berangkat' || form_attendance.status === 'mulai' ? require('@/assets/icon/fingerprint_attendance_start.png') : require('@/assets/icon/fingerprint_attendance_stop.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </v-btn>

                      <div>
                        <a
                          :href="`http://maps.google.com/maps?q=${form_attendance.latitude},${form_attendance.longitude}`"
                          target="_blank"
                          class="d-flex align-center justify-center mt-4 mb-2">
                          <v-icon size="18" color="blue">mdi-map-marker-radius</v-icon>

                          <span
                            class="ml-1">
                            Lokasi Anda Terkini
                          </span>
                        </a>

                        <div
                          class="body-1 font-weight-bold mt-16 mb-6">
                          Riwayat Absen
                        </div>

                        <v-row v-if="form_attendance.status === 'berangkat' || form_attendance.status === 'sampai'">
                          <v-col
                            cols="6">
                            <v-avatar
                              tile
                              size="40">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="require('@/assets/icon/check_in.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <div v-if="detail.absen.length > 0"
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                {{ detail.absen[0].create_datetime | time }}
                              </div>

                              <div>
                                Berangkat
                              </div>
                            </div>

                            <div v-else
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                -
                              </div>

                              <div>
                                Belum Berangkat
                              </div>
                            </div>
                          </v-col>

                          <v-col
                            cols="6">
                            <v-avatar
                              tile
                              size="40">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="require('@/assets/icon/check_out.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <div v-if="detail.absen.length > 1"
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                {{ detail.absen[1].create_datetime | time }}
                              </div>

                              <div>
                                Sampai
                              </div>
                            </div>

                            <div v-else
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                -
                              </div>

                              <div>
                                Belum Sampai
                              </div>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row v-if="form_attendance.status === 'mulai' || form_attendance.status === 'selesai'">
                          <v-col
                            cols="6">
                            <v-avatar
                              tile
                              size="40">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="require('@/assets/icon/mulai.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <div v-if="selected.form_attendance_item_index !== '' && detail.detail[selected.form_attendance_item_index].absen_detail.length > 0"
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                {{ detail.detail[selected.form_attendance_item_index].absen_detail[0].create_datetime | time }}
                              </div>

                              <div>
                                Mulai
                              </div>
                            </div>

                            <div v-else
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                -
                              </div>

                              <div>
                                Belum Mulai
                              </div>
                            </div>
                          </v-col>

                          <v-col
                            cols="6">
                            <v-avatar
                              tile
                              size="40">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="require('@/assets/icon/selesai.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <div v-if="selected.form_attendance_item_index !== '' && detail.detail[selected.form_attendance_item_index].absen_detail.length > 1"
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                {{ detail.detail[selected.form_attendance_item_index].absen_detail[1].create_datetime | time }}
                              </div>

                              <div>
                                Selesai
                              </div>
                            </div>

                            <div v-else
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                -
                              </div>

                              <div>
                                Belum Selesai
                              </div>
                            </div>
                          </v-col>
                        </v-row>

                        <div v-if="message.error.length > 0"
                          class="text-center my-4 d-flex align-center justify-center">
                          <div
                            class="error--text">
                            <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                            <span
                              class="ml-1">
                              {{ message.error }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-form>
                </ValidationObserver>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.item"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.item = false; $emit('load');"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.item = false; $emit('load');"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Item
            </span>
          </v-card-title>

          <v-card-text
            class="pt-16 mt-4">
            <v-text-field
              v-model="search_item"
              @click:clear="search_item = ''; fetchItem();"
              v-on:keyup.enter="fetchItem()"
              filled
              rounded
              dense
              single-line
              hide-details
              :color="set_color"
              prepend-inner-icon="mdi-magnify"
              placeholder="Cari data yang Anda butuhkan ?"
              clearable>
            </v-text-field>

            <v-btn
              @click="sheet.form_item = true; reset_item();"
              block
              large
              rounded
              elevation="0"
              class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
              :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
              <div
                class="btn_auth_in d-flex align-center justify-center"
                :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                <div>
                  <v-img
                    contain
                    width="20"
                    height="20"
                    alt="bidanvitacare"
                    :src="require('@/assets/icon/items.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="20"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>

                <div
                  class="ml-2">
                  Tambah Item
                </div>
              </div>
            </v-btn>
          </v-card-text>

          <v-card-text
            class="pb-0"
            style="height: calc(100vh - 350px); overflow: auto;">
            <v-row>
              <v-col
                cols="12">
                <v-skeleton-loader
                  v-for="(item, index) in process.load ? 5 : listing"
                  :key="index"
                  :loading="process.load"
                  :class="index < listing.length - 1 ? 'mb-4' : ''"
                  type="list-item-avatar-three-line">
                  <template>
                    <div>
                      <v-card
                        v-if="!process.load && listing.length > 0"
                        flat
                        color="#FFFFFF40"
                        class="border-radius">
                        <v-list
                          flat
                          dense
                          three-line
                          color="transparent"
                          class="py-0">
                          <v-list-item>
                            <v-list-item-avatar
                              size="40"
                              tile
                              class="mt-3"
                              style="border: 2px solid #ffffff; border-radius: 5px !important; overflow: unset;">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.item_image_url !== '' ? item.item_image_url : require('@/assets/image/image_default.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>

                              <v-icon
                                @click="deletes(item)"
                                class="cursor-pointer"
                                color="red"
                                size="18"
                                style="position: absolute; left: -15px; top: -15px;">
                                mdi-close-circle
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <div
                                class="body-2 font-weight-bold text-capitalize">
                                <span
                                  class="two-line">
                                  {{ item.item_name }}
                                </span>
                              </div>

                              <div
                                class="caption line-text-second black--text d-flex align-center">
                                <strong
                                  style="opacity: 60%;">
                                  Jml ({{ item.order_qty }})
                                </strong>

                                <span
                                  class="font-weight-light">
                                  /{{ item.item_duration_minutes }}Menit
                                </span>
                              </div>

                              <div>
                                <v-divider
                                  class="my-2"
                                  style="border-top: 1px dashed #bbb;" />
                              </div>

                              <div>
                                <span
                                  class="caption">
                                  {{ item.note !== '' ? item.note : '-' }}
                                </span>
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </div>
                  </template>
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <Empty v-if="!process.load && listing.length < 1"
              class="py-12"
              margin="my-16"
              size="125"
              message="Item" />
          </v-card-text>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.form_item"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.form_item = false; reset_item();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.form_item = false; reset_item();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Tambah
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 205px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="pt-16 mt-5">
              <ValidationObserver
                ref="form_item">
                <v-form>
                  <ValidationProvider
                    name="Item"
                    rules="required"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Item
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-autocomplete
                      v-on:keyup.enter="save_item"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. Paket Terapi"

                      :items="list_item"
                      item-text="name"
                      item-value="id"
                      :item-color="set_color"

                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                      v-model="form_item.item_id"
                      :success="valid"
                      :error-messages="errors"
                      required>
                      <template v-slot:selection="{ item }">
                        <v-avatar
                          tile
                          size="20">
                          <v-img
                            alt="bidanvitacare"
                            :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>

                        <span
                          class="body-2 text-capitalize ml-2">
                          {{ item.name }}
                        </span>
                      </template>

                      <template v-slot:item="{ item }">
                        <v-avatar
                          tile
                          size="20">
                          <v-img
                            alt="bidanvitacare"
                            :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>

                        <span
                          class="body-2 text-capitalize ml-2">
                          {{ item.name }}
                        </span>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Jumlah"
                    rules="required|min:0"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Jumlah
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-text-field
                      v-on:keyup.enter="save_item"
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                      min="0"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 5"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      @focus="$event.target.select()"

                      v-model="form_item.qty"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    name="Keterangan"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      Keterangan
                      <!-- <span>
                        (boleh dikosongi)
                      </span> -->
                    </div>

                    <v-textarea
                      v-on:keyup.enter="save_item"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      rows="5"
                      placeholder="Contoh. Tuliskan keterangan disini . . ."
                      :append-icon="form_item.note !== '' && valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form_item.note"
                      :success="form_item.note !== '' && valid"
                      :error-messages="errors"
                      required>
                    </v-textarea>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="text-center mt-3 mb-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="#FFFFFF"
            min-height="150"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row
                align="center">
                <v-col
                  cols="3"
                  class="pb-0">
                  <v-img
                    contain
                    alt="bidanvitacare"
                    :src="require('@/assets/icon/items.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col
                  cols="9"
                  class="black--text pb-0">
                  <div>
                    <div
                      class="body-2 line-text-second black--text"
                      style="opacity: 60%;">
                      Masukkan data Item untuk rekam kunjungan di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row
                class="mt-4">
                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save_item"
                    @click="save_item"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-check-bold
                      </v-icon>

                      Simpan
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  cols="6"
                  class="pb-0">
                  <v-btn
                    @click="sheet.form_item = false; reset_item();"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-close-thick
                      </v-icon>

                      Batal
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="sheet.riwayat"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.riwayat = false"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.riwayat = false"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Kunjungan Sebelumnya
            </span>
          </v-card-title>

          <v-card-text
            class="pt-16 mt-4 pb-0">
            <v-text-field
              v-model="search_riwayat"
              @click:clear="search_riwayat = ''; fetchRiwayat();"
              v-on:keyup.enter="fetchRiwayat()"
              filled
              rounded
              dense
              single-line
              hide-details
              :color="set_color"
              prepend-inner-icon="mdi-magnify"
              placeholder="Cari data yang Anda butuhkan ?"
              clearable>
            </v-text-field>
          </v-card-text>

          <v-card-text
            class="pb-0"
            style="height: calc(100vh - 135px); overflow: auto;">
            <v-row>
              <v-col
                cols="12">
                <v-skeleton-loader
                  v-for="(item, index) in process.riwayat ? 4 : list_riwayat"
                  :key="index"
                  :loading="process.riwayat"
                  :class="index < list_riwayat.length - 1 ? 'mb-4' : ''"
                  type="list-item-avatar-three-line">
                  <template>
                    <div>
                      <v-list
                        v-if="!process.riwayat && list_riwayat.length > 0"
                        flat
                        dense
                        three-line
                        color="#FFFFFF40"
                        class="border-radius">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="body-2 font-weight-bold d-flex align-center">
                              <v-avatar
                                size="25"
                                style="border: 2px solid #ffffff;">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        size="20"
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>

                              <span
                                class="ml-2">
                                {{ item.customer_name }}
                              </span>
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class="body-2 font-weight-light line-text-second black--text my-2 py-2"
                              style="border-top: 1px dashed #bbb; border-bottom: 1px dashed #bbb;">
                              <div
                                class="d-flex align-center">
                                <div
                                  class="caption"
                                  style="opacity: 60%;">
                                  Umur
                                </div>

                                <v-spacer />

                                <div
                                  class="body-2 black--text">
                                  {{ item.age !== '' ? item.age : '-' }}
                                </div>
                              </div>

                              <v-divider
                                class="my-2"
                                style="border-top: 1px dashed #bbb;" />

                              <div
                                class="d-flex align-center">
                                <div>
                                  <div
                                    class="caption"
                                    style="opacity: 60%; margin-bottom: 1px;">
                                    BB
                                  </div>

                                  <div
                                    class="black--text font-weight-bold"
                                    style="font-size: 0.8125rem;">
                                    {{ item.bb }} Kg
                                  </div>
                                </div>

                                <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                                <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                  <div
                                    class="caption"
                                    style="opacity: 60%; margin-bottom: 1px;">
                                    TB
                                  </div>

                                  <div
                                    class="black--text font-weight-bold"
                                    style="font-size: 0.8125rem;">
                                    {{ item.tb }} Cm
                                  </div>
                                </div>

                                <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                                <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                  <div
                                    class="caption"
                                    style="opacity: 60%; margin-bottom: 1px;">
                                    LILA
                                  </div>

                                  <div
                                    class="black--text font-weight-bold"
                                    style="font-size: 0.8125rem;">
                                    {{ item.lila }} Cm
                                  </div>
                                </div>

                                <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                                <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                  <div
                                    class="caption"
                                    style="opacity: 60%; margin-bottom: 1px;">
                                    LK
                                  </div>

                                  <div
                                    class="black--text font-weight-bold"
                                    style="font-size: 0.8125rem;">
                                    {{ item.lk }} Cm
                                  </div>
                                </div>

                                <v-spacer v-if="item.customer_type !== 'bayi' && item.customer_type !== 'anak'" />

                                <div v-if="item.customer_type !== 'bayi' && item.customer_type !== 'anak'">
                                  <div
                                    class="caption"
                                    style="opacity: 60%; margin-bottom: 1px;">
                                    Tensi
                                  </div>

                                  <div
                                    class="black--text font-weight-bold"
                                    style="font-size: 0.8125rem;">
                                    {{ item.tensi }} mmHg
                                  </div>
                                </div>
                              </div>

                              <!-- <v-divider
                                class="my-2"
                                style="border-top: 1px dashed #bbb;" />

                              <div
                                class="d-flex align-center">
                                <div
                                  class="caption"
                                  style="opacity: 60%;">
                                  Klien
                                </div>

                                <v-spacer />

                                <div>
                                  <div
                                    class="d-flex align-center cursor-pointer">
                                    <v-avatar
                                      v-for="(item_customer, index_customer) in item.patner"
                                      :key="index_customer"
                                      v-show="index_customer < 2"
                                      size="24"
                                      class="mt-0"
                                      style="border: 2px solid #ffffff;"
                                      :style="item.patner.length === 2 && index_customer === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                              item.patner.length > 2 && index_customer === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                              item.patner.length > 2 && index_customer === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                                      <v-img
                                        width="100%"
                                        height="100%"
                                        alt="bidanvitacare"
                                        :src="item_customer.image_url !== '' ? item_customer.image_url : require('@/assets/image/member_default.jpg')"
                                        class="ma-auto">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular
                                              size="20"
                                              indeterminate
                                              :color="set_color">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-avatar>

                                    <span v-if="item.patner.length < 2"
                                      class="caption font-weight-bold one-line ml-1"
                                      style="width: 50px;">
                                      {{ item.patner[0].name }}
                                    </span>

                                    <span v-if="item.patner.length > 2"
                                      class="caption font-weight-bold ml-1">
                                      +{{ item.patner.length - 2 }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <v-divider
                                class="my-2"
                                style="border-top: 1px dashed #bbb;" />

                              <div
                                class="d-flex align-center">
                                <div
                                  class="caption"
                                  style="opacity: 60%;">
                                  Terapis
                                </div>

                                <v-spacer />

                                <div>
                                  <div
                                    class="d-flex align-center cursor-pointer">
                                    <v-avatar
                                      v-for="(item_therapist, index_therapist) in item.therapist"
                                      :key="index_therapist"
                                      v-show="index_therapist < 2"
                                      size="24"
                                      class="mt-0"
                                      style="border: 2px solid #ffffff;"
                                      :style="item.therapist.length === 2 && index_therapist === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                              item.therapist.length > 2 && index_therapist === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                              item.therapist.length > 2 && index_therapist === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                                      <v-img
                                        width="100%"
                                        height="100%"
                                        alt="bidanvitacare"
                                        :src="item_therapist.image_url !== '' ? item_therapist.image_url : require('@/assets/image/member_default.jpg')"
                                        class="ma-auto">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular
                                              size="20"
                                              indeterminate
                                              :color="set_color">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-avatar>

                                    <span v-if="item.therapist.length < 2"
                                      class="caption font-weight-bold one-line ml-1"
                                      style="width: 50px;">
                                      {{ item.therapist[0].name }}
                                    </span>

                                    <span v-if="item.therapist.length > 2"
                                      class="caption font-weight-bold ml-1">
                                      +{{ item.therapist.length - 2 }}
                                    </span>
                                  </div>
                                </div>
                              </div> -->
                            </v-list-item-subtitle>

                            <v-list-item-subtitle
                              class="d-flex align-center">
                              <div>
                                <div
                                  class="caption mb-1">
                                  Tanggal & Waktu Kunjungan
                                </div>

                                <div
                                  class="black--text">
                                  {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                                </div>
                              </div>

                              <v-spacer />

                              <div>
                                <div
                                  class="caption mb-1">
                                  {{ item.customer_wali_status }}
                                </div>

                                <div
                                  class="black--text">
                                  {{ item.customer_wali_name }}
                                </div>
                              </div>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </template>
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <div v-if="!process.riwayat && pagination_riwayat.total_data > 10 && limit < pagination_riwayat.total_data"
              class="text-center my-4">
              <v-btn
                @click="limit += 10"
                :loading="process.limit"
                small
                rounded
                outlined
                elevation="0"
                :color="set_color"
                class="text-capitalize"
                :style="`color: ${set_color};`">
                Lebih banyak
              </v-btn>
            </div>

            <Empty v-if="!process.riwayat && pagination_riwayat.total_page < 1"
              class="py-12"
              margin="my-16"
              size="125"
              message="Kunjungan Sebelumnya" />
          </v-card-text>
        </div>
      </v-card>
    </v-bottom-sheet>

    <!-- COUNTDOWN -->
    <v-bottom-sheet
      v-model="sheet.countdown"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.countdown = false; $emit('load');"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.countdown = false; $emit('load');"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Waktu Pijat
            </span>
          </v-card-title>

          <v-card-text
            class="pt-16">
            <v-row
              align="center"
              style="height: calc(100vh - 70px); overflow-y: auto;">
              <v-col
                cols="12"
                class="pa-0">
                <ValidationObserver
                  ref="form_attendance">
                  <v-form>
                    <div
                      class="text-center">
                      <div>
                        <div
                          class="title font-weight-bold mb-6">
                          Hai, {{ detail.therapist_name }}
                        </div>

                        <div
                          class="text-h4 font-weight-bold black--text">
                          {{ detail.datetime | time }}
                        </div>

                        <div
                          class="mb-8">
                          {{ detail.datetime | date }}
                        </div>

                        <div
                          class="mb-1">
                          <v-chip
                            small
                            class="caption white--text text-capitalize"
                            :color="set_color">
                            {{ detail.category_name !== '' ? detail.category_name : '-' }}
                          </v-chip>
                        </div>

                        <div
                          class="font-weight-bold">
                          {{ detail.item_name }}
                        </div>


                        <v-divider
                          class="my-2 mx-12"
                          style="border-top: 1px dashed #bbbbbb" />

                        <div
                          class="mb-16">
                          {{ detail.customer_name }} - {{ detail.customer_mobilephone | phone }}
                        </div>
                      </div>

                      <v-btn
                        fab
                        disabled
                        width="200"
                        height="200"
                        elevation="0"
                        class="mt-2"
                        :style="`border: 10px solid ${set_color};`">
                        <div
                          class="text-h3 font-weight-bold"
                          :style="`color: ${set_color};`">
                          {{ time }}
                        </div>
                      </v-btn>

                      <div>
                        <a
                          :href="`http://maps.google.com/maps?q=${form_attendance.latitude},${form_attendance.longitude}`"
                          target="_blank"
                          class="d-flex align-center justify-center mt-4 mb-2">
                          <v-icon size="18" color="blue">mdi-map-marker-radius</v-icon>

                          <span
                            class="ml-1">
                            Lokasi Anda Terkini
                          </span>
                        </a>

                        <div
                          class="body-1 font-weight-bold mt-16 mb-6">
                          Waktu Pijat
                        </div>

                        <v-row v-if="form_attendance.status === 'berangkat' || form_attendance.status === 'sampai'">
                          <v-col
                            cols="6">
                            <v-avatar
                              tile
                              size="40">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="require('@/assets/icon/check_in.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <div v-if="detail.absen.length > 0"
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                {{ detail.absen[0].create_datetime | time }}
                              </div>

                              <div>
                                Berangkat
                              </div>
                            </div>

                            <div v-else
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                -
                              </div>

                              <div>
                                Belum Berangkat
                              </div>
                            </div>
                          </v-col>

                          <v-col
                            cols="6">
                            <v-avatar
                              tile
                              size="40">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="require('@/assets/icon/check_out.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <div v-if="detail.absen.length > 1"
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                {{ detail.absen[1].create_datetime | time }}
                              </div>

                              <div>
                                Sampai
                              </div>
                            </div>

                            <div v-else
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                -
                              </div>

                              <div>
                                Belum Sampai
                              </div>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row v-if="form_attendance.status === 'mulai' || form_attendance.status === 'selesai'">
                          <v-col
                            cols="6">
                            <v-avatar
                              tile
                              size="40">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="require('@/assets/icon/mulai.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <div v-if="selected.form_attendance_item_index !== '' && detail.detail[selected.form_attendance_item_index].absen_detail.length > 0"
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                {{ detail.detail[selected.form_attendance_item_index].absen_detail[0].create_datetime | time }}
                              </div>

                              <div>
                                Mulai
                              </div>
                            </div>

                            <div v-else
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                -
                              </div>

                              <div>
                                Belum Mulai
                              </div>
                            </div>
                          </v-col>

                          <v-col
                            cols="6">
                            <v-avatar
                              tile
                              size="40">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="require('@/assets/icon/selesai.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <div v-if="selected.form_attendance_item_index !== '' && detail.detail[selected.form_attendance_item_index].absen_detail.length > 1"
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                {{ detail.detail[selected.form_attendance_item_index].absen_detail[1].create_datetime | time }}
                              </div>

                              <div>
                                Selesai
                              </div>
                            </div>

                            <div v-else
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                -
                              </div>

                              <div>
                                Belum Selesai
                              </div>
                            </div>
                          </v-col>
                        </v-row>

                        <div v-if="message.error.length > 0"
                          class="text-center my-4 d-flex align-center justify-center">
                          <div
                            class="error--text">
                            <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                            <span
                              class="ml-1">
                              {{ message.error }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-form>
                </ValidationObserver>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-card>
    </v-bottom-sheet>

    <!-- ABSEN HADIR -->
    <v-bottom-sheet
      v-model="sheet.error"
      inset>
      <v-sheet
        class="text-center"
        style="border-radius: 15px 15px 0px 0px; background: #f9dee2;">
        <div
          class="pa-4">
          <v-avatar
            size="50"
            style="border: 2px solid #ffffff;">
            <v-img
              width="100%"
              height="100%"
              alt="bidanvitacare"
              :src="user.image_url !== '' ? user.image_url : require('@/assets/image/member_default.jpg')"
              class="ma-auto">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    size="20"
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <div
            style="opacity: 70%;">
            <div
              class="font-weight-bold mt-2 mb-3"
              style="font-size: 18px;">
              Hai, {{ user.name }}
            </div>

            <div
              class="body-2 line-text-second">
              Silahkan melakukan absensi kehadiran terlebih dahulu sebelum melanjutkan proses kunjungan.
            </div>
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      },
      status_required: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        dialog: {
          partner_or_terapis: false,

          gambar_rumah: false,

          permission: false
        },
        sheet: {
          attendance: false,

          item: false,
          form_item: false,

          riwayat: false,

          countdown: false,

          error: false
        },
        selected: {
          type_user: 'Terapis',
          partner_or_terapis: [],

          form_attendance_item_index: ''
        },

        search: '',
        search_item: '',
        search_riwayat: '',
        listing: [],
        list_item: [],
        list_riwayat: [],
        pagination: {},
        pagination_item: {},
        pagination_riwayat: {},

        limit: 10,

        form_attendance: {
          id: '',
          detail_id: '',
          status: '',
          latitude: '',
          longitude: ''
        },

        form: {
          visit_id: '',
          item_id: '',
          qty: 0,
          note: ''
        },

        time: 0,

        absen_hadir: false,

        process: {
          load: false,
          form: false,
          item: false,

          riwayat: false,
          limit: false,

          absen: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.process.limit = true

        this.fetchRiwayat('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {

    },
    methods: {
      reset_attendance () {
        if (this.$refs.form_attendance) this.$refs.form_attendance.reset()

        let self = this

        navigator.permissions.query({ name: 'geolocation' })
        .then(function(result) {
          if (result.state !== 'denied') {
            navigator.geolocation.getCurrentPosition(position => {
              if (self.detail.type === 'home_care') {
                self.form_attendance = {
                  id: self.detail.visit_id,
                  detail_id: '',
                  status: self.detail.absen.length === 0 ? 'berangkat' : 'sampai',
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude
                }
              }
            })
          } else {
            self.dialog.permission = true
          }
        })

        this.process.form = false

        this.message.error = ''

        this.sheet.attendance = true
      },
      reset_attendance_detail (item) {
        if (this.$refs.form_attendance) this.$refs.form_attendance.reset()

        let self = this

        navigator.permissions.query({ name: 'geolocation' })
        .then(function(result) {
          if (result.state !== 'denied') {
            navigator.geolocation.getCurrentPosition(position => {
              if ((self.detail.type === 'home_care' && self.detail.absen.length >= 2) || self.detail.type === 'on_site') {
                self.form_attendance = {
                  id: self.detail.visit_id,
                  detail_id: self.detail.detail[self.selected.form_attendance_item_index].id,
                  status: self.detail.detail[self.selected.form_attendance_item_index].absen_detail.length === 0 ? 'mulai' : 'selesai',
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude
                }
              }
            })
          } else {
            self.dialog.permission = true
          }
        })

        this.process.form = false

        this.message.error = ''

        this.sheet.attendance = true
      },

      reset_attendance_countdown (item) {
        if (this.$refs.form_attendance) this.$refs.form_attendance.reset()

        let self = this

        navigator.permissions.query({ name: 'geolocation' })
        .then(function(result) {
          if (result.state !== 'denied') {
            navigator.geolocation.getCurrentPosition(position => {
              if ((self.detail.type === 'home_care' && self.detail.absen.length >= 2) || self.detail.type === 'on_site') {
                self.form_attendance = {
                  id: self.detail.visit_id,
                  detail_id: self.detail.detail[self.selected.form_attendance_item_index].id,
                  status: self.detail.detail[self.selected.form_attendance_item_index].absen_detail.length === 0 ? 'mulai' : 'selesai',
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude
                }
              }
            })
          } else {
            self.dialog.permission = true
          }
        })

        this.process.form = false

        this.message.error = ''

        this.sheet.countdown = true

        this.time = 0

        let ms = this.detail.detail[this.selected.form_attendance_item_index].second_remaining,
            minutes = Math.floor(ms / 60),
            seconds = ms - minutes * 60

        this.countdown(minutes, seconds)
      },

      countdown(minutes, seconds) {
        let endTime, hours, mins, msLeft, time

        const twoDigits = (n) => {
          return n <= 9 ? "0" + n : n
        }

        const updateTimer = () => {
          msLeft = endTime - +new Date()

          if (msLeft < 1000) {
            this.time = '00:00'

            let self = this

            navigator.permissions.query({ name: 'geolocation' })
            .then(function(result) {
              if (result.state !== 'denied') {
                navigator.geolocation.getCurrentPosition(position => {
                  if ((self.detail.type === 'home_care' && self.detail.absen.length >= 2) || self.detail.type === 'on_site') {
                    self.form_attendance = {
                      id: self.detail.visit_id,
                      detail_id: self.detail.detail[self.selected.form_attendance_item_index].id,
                      status: 'selesai',
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude
                    }
                  }
                })
              } else {
                self.dialog.permission = true
              }
            })

            this.save_attendance()
          } else {
            time = new Date(msLeft)

            hours = time.getUTCHours()
            mins = time.getUTCMinutes()

            this.time = (hours ? hours + ':' + twoDigits(mins) : mins) + ':' + twoDigits(time.getUTCSeconds())

            setTimeout(updateTimer, time.getUTCMilliseconds() + 500)
          }
        }

        endTime = +new Date() + 1000 * (60 * minutes + seconds) + 500

        updateTimer()
      },

      async save_attendance () {
        this.message.error = ''

        if (!this.absen_hadir) {
          this.sheet.error = true
        } else {
          const isValid = await this.$refs.form_attendance.validate()
          if (isValid) {
            this.process.form = true

            let url = ''

            if (this.detail.type === 'home_care' && this.detail.absen.length < 2) {
              url = 'therapist/customer-visit-today/absen'
            }

            if ((this.detail.type === 'home_care' && this.detail.absen.length >= 2) || this.detail.type === 'on_site') {
              url = 'therapist/customer-visit-today/absen-detail'

              if (this.form_attendance.status === 'mulai') {
                this.play_start()
              } else {
                this.play_end()
              }
            }

            // SAVE TO REST API
            await this.$axios.$post(`${process.env.API}${url}`, this.form_attendance)
            .then((response) => {

              if (response.status === 200) {
                this.process.form = false

                this.sheet.attendance = false

                this.$emit('load')
              } else {
                this.process.form = false
                this.message.error = response.message
              }
            })

          } else {
            // AUTO FOCUS ON TEXT-FIELD
            setTimeout(() => {
              const errors = Object.entries(this.$refs.form_attendance.errors)
                .map(([key, value]) => ({ key, value }))
                .filter(error => error['value'].length)
              this.$refs.form_attendance.refs[errors[0]['key']].$el.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              })
            }, 10)
          }
        }
      },
      play_start () {
        let audio = new Audio('https://cdndev.amanata.co/2023/07/file/awalan-1688230927.mp3')

        audio.play()
      },
      play_end () {
        let audio = new Audio('https://cdndev.amanata.co/2023/07/file/akhiran+revisi-1688230981.mp3')

        audio.play()
      },

      async fetchItem () {
        this.process.load = true

        let params = {
          visit_id: this.detail.visit_id,
          search: this.search_item,
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}therapist/customer-visit-item`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.listing = response.results.data
          }
        })
      },
      async fetchItemSelect () {
        this.process.item = true

        let params = {
          search: this.search,
          'type[eq]': 'service',
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}therapist/item`, { params })
        .then((response) => {
          this.process.item = false

          if (response.status === 200) {
            this.list_item = response.results.data
            this.pagination_item = response.results.pagination
          }
        })
      },
      reset_item (item) {
        if (this.$refs.form_item) this.$refs.form_item.reset()

        this.form_item = {
          visit_id: this.detail.visit_id,
          item_id: item !== undefined ? item.item_id : '',
          qty: item !== undefined ? item.order_qty : 0,
          note: item !== undefined ? item.note : ''
        }

        this.process.form = false

        this.message.error = ''

        this.fetchItemSelect()
      },
      async save_item () {
        this.message.error = ''

        const isValid = await this.$refs.form_item.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}therapist/customer-visit-item/add`, this.form_item)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form_item = false

              this.fetchItem()
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form_item.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form_item.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },
      async deletes (item) {
        this.message.error = ''

          // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}therapist/customer-visit-item/delete`, {
          visit_id: this.detail.visit_id,
          item_id: item.item_id
        })
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.fetchItem()
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      },

      async fetchRiwayat (type) {
        this.process.riwayat = type === undefined ? true : false

        let params = {
          search: this.search_riwayat,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}therapist/customer-visit`, { params })
        .then((response) => {
          this.process.riwayat = false

          this.process.limit = false

          if (response.status === 200) {
            this.list_riwayat = response.results.data
            this.pagination_riwayat = response.results.pagination
          }
        })
      },

      async fetchAbsen () {
        this.process.absen = true

        await this.$axios.$get(`${process.env.API}therapist/absen/detail`)
        .then((response) => {

          this.process.absen = false

          if (response.status === 200) {
            this.absen_hadir = Object.keys(response.results.data).length > 0 ? true : false
          }
        })
      }
    }
  }
</script>
