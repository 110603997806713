<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.partner_or_terapis"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                {{ selected.type_user }}
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text v-if="selected.partner_or_terapis.length > 0"
              class="body-2 text-first line-text-second px-4 pb-2">
              <v-row>
                <v-col
                  v-for="(item, index) in selected.partner_or_terapis"
                  :key="index"
                  cols="12">
                  <v-list
                    flat
                    dense
                    color="#FFFFFF40"
                    class="border-radius"
                    style="border: 1px dashed #bbb;">
                    <v-list-item
                      v-if="selected.type_user === 'Klien'">
                      <v-list-item-avatar
                        size="40"
                        style="border: 2px solid #ffffff;">
                        <v-img
                          width="100%"
                          height="100%"
                          alt="bidanvitacare"
                          :src="item.image_url !== '' ? item.image_url : require('@/assets/image/member_default.jpg')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="body-2 font-weight-bold text-capitalize">
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="selected.type_user === 'Terapis'">
                      <v-list-item-avatar
                        size="40"
                        style="border: 2px solid #ffffff;">
                        <v-img
                          width="100%"
                          height="100%"
                          alt="bidanvitacare"
                          :src="item.user_image_url !== '' ? item.user_image_url : require('@/assets/image/member_default.jpg')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="body-2 font-weight-bold text-capitalize">
                          {{ item.user_name }}
                        </v-list-item-title>

                        <v-list-item-subtitle
                          class="body-2 font-weight-light text-capitalize line-text-second black--text"
                          style="opacity: 70%;">
                          {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.partner_or_terapis = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Tutup
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.info_berhasil"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="125"
                height="125"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_berhasil.png')"
                class="ma-auto my-6">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  WA konfirmasi resume jadwal,
                </div>

                <div>
                  telah berhasil dikirim!
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.info_berhasil = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Tutup
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.info_gagal"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="125"
                height="125"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto my-6">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  No Handphone customer tidak ditemukan,
                </div>

                <div>
                  cantumkan untuk kirim resume!
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.info_gagal = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Tutup
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.info_warning"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="125"
                height="125"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto my-6">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  {{ selected.menu === 'waiting' ? 'Apakah Anda yakin mengubah' : 'Silahkan periksa jadwal Anda kembali' }}
                </div>

                <div>
                  {{ selected.menu === 'waiting' ? 'status jadwal menjadi proses?' : 'sebelum di teruskan ke terapis!' }}
                </div>

                <div
                  v-if="selected.menu !== 'waiting'">
                  Pastikan jadwal sudah terkonfirmasi klien dan terkonfirmasi terapis.
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn v-if="selected.menu === 'waiting'"
                @click="save_process"
                :loading="process.form"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn v-if="selected.menu === 'process'"
                @click="save_complete"
                :loading="process.form"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Konfirmasi
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.info_warning = false"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.go_back"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin akan keluar
                </div>

                <div>
                  dari halaman ini?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                to="/home"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Keluar
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.go_back = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.gambar_rumah"
          persistent
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Gambar Rumah
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second px-4 pb-2 my-4">
              <v-img
                contain
                width="100%"
                height="250"
                style="border: 5px solid #0000000d;
                      border-radius: 10px;"
                alt="bidanvitacare"
                :src="detail.address_image_url !== '' ? detail.address_image_url : require('@/assets/image/image_default.png')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.gambar_rumah = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Tutup
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.gambar_rumah_complete"
          persistent
          scrollable>
          <v-card v-if="Object.keys(detail_complete).length > 0"
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Gambar Rumah
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second px-4 pb-2 my-4">
              <v-img
                contain
                width="100%"
                height="250"
                style="border: 5px solid #0000000d;
                      border-radius: 10px;"
                alt="bidanvitacare"
                :src="detail_complete.address_image_url !== '' ? detail_complete.address_image_url : require('@/assets/image/image_default.png')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.gambar_rumah_complete = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Tutup
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.filter"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Filter
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-6 pb-0">
              <!-- <v-text-field
                filled
                rounded
                dense
                :color="set_color"
                placeholder="Nama Customer"

                @click:clear="filter.customer_name = ''"
                clearable

                v-model="filter.customer_name">
              </v-text-field>

              <v-text-field
                filled
                rounded
                dense
                :color="set_color"
                placeholder="Nama Klien"

                @click:clear="filter.client_name = ''"
                clearable

                v-model="filter.client_name">
              </v-text-field> -->

              <v-autocomplete
                filled
                rounded
                dense
                :color="set_color"
                placeholder="Pilih Customer"

                :search-input.sync="autocomplete.customer"
                :loading="process.customer"

                :items="customer"
                item-value="name"
                item-text="name"
                :item-color="set_color"

                @click:clear="autocomplete.customer = ''; filter.customer_name = '';"
                clearable

                :no-filter="false"

                v-model="filter.customer_name">
                <template v-slot:item="data">
                  <span
                    class="body-2 text-capitalize">
                    {{ data.item.name }}
                  </span>
                </template>

                <template v-slot:selection="data">
                  <span
                    class="body-2 text-capitalize">
                    {{ data.item.name }}
                  </span>
                </template>
              </v-autocomplete>

              <v-autocomplete
                filled
                rounded
                dense
                :color="set_color"
                placeholder="Pilih Klien"

                :search-input.sync="autocomplete.klien"
                :loading="process.klien"

                :items="klien"
                item-value="name"
                item-text="name"
                :item-color="set_color"

                @click:clear="autocomplete.klien = ''; filter.client_name = '';"
                clearable

                :no-filter="false"

                v-model="filter.client_name">
                <template v-slot:item="data">
                  <span
                    class="body-2 text-capitalize">
                    {{ data.item.name }}
                  </span>
                </template>

                <template v-slot:selection="data">
                  <span
                    class="body-2 text-capitalize">
                    {{ data.item.name }}
                  </span>
                </template>
              </v-autocomplete>

              <v-text-field
                type="number"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                min="0"
                filled
                rounded
                dense
                :color="set_color"
                placeholder="No. Handphone"

                @click:clear="filter.mobile_phone = ''"
                clearable

                v-model="filter.mobile_phone">
              </v-text-field>

              <v-select
                filled
                rounded
                dense
                :color="set_color"
                placeholder="Pilih Cabang"

                :items="store"
                item-value="id"
                item-text="name"
                :item-color="set_color"

                clearable
                @click:clear="filter.store_id = ''"

                :no-filter="false"

                v-model="filter.store_id">
              </v-select>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.filter = false; sheet.kalender ? fetch() : selected.menu_utama === 'all' ? fetchAll() : fetchNonTerapis();"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.filter = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.delete"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/hapus_info.png')"
                class="ma-auto my-6">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                Apakah Anda yakin akan menghapus jadwal ini?
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="deletes()"
                :loading="process.delete"
                :disabled="process.delete"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Saya yakin
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.delete = false"
                :disabled="process.delete"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card
              flat
              color="transparent">
              <v-card-text
                class="pb-0 mt-1">
                <v-row
                  class="px-1">
                  <v-col
                    v-for="(item, index) in menu_utama"
                    :key="index"
                    cols="4"
                    class="py-0 px-2">
                    <v-btn
                      @click="selected.menu_utama = item.value"
                      block
                      rounded
                      elevation="0"
                      :class="selected.menu_utama !== item.value ? '' : 'font-weight-bold'"
                      :color="selected.menu_utama !== item.value ? '#e2e2e2' : set_color"
                      :style="selected.menu_utama !== item.value ? 'color: #8c8c8c;' : 'color: #FFFFFF;'"
                      class="body-2 text-capitalize mb-6">
                      {{ item.text }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card-text
              class="py-0">
              <v-autocomplete v-if="selected.menu_utama === 'exist'"
                filled
                rounded
                dense
                :color="set_color"
                placeholder="Pilih Terapis"

                :search-input.sync="autocomplete.terapis"
                :loading="process.terapis"

                :items="terapis"
                item-value="id"
                item-text="name"
                :item-color="set_color"

                @change="fetchKalender()"
                v-on:keyup.enter="fetchKalender()"
                @click:clear="autocomplete.terapis = ''; filter.therapist_id = ''; fetchKalender();"
                clearable

                hide-details

                class="mb-4"

                :no-filter="false"

                v-model="filter.therapist_id">
                <template v-slot:item="data">
                  <span
                    class="body-2 text-capitalize">
                    {{ data.item.name }}
                  </span>
                </template>

                <template v-slot:selection="data">
                  <span
                    class="body-2 text-capitalize">
                    {{ data.item.name }}
                  </span>
                </template>
              </v-autocomplete>

              <div v-if="selected.menu_utama === 'all' || selected.menu_utama === 'not_exist'"
                class="d-flex align-center mb-4">
                <v-text-field
                  v-model="search"
                  @click:clear="search = ''; selected.menu_utama === 'all' ? fetchAll() : fetchNonTerapis();"
                  v-on:keyup.enter="selected.menu_utama === 'all' ? fetchAll() : fetchNonTerapis()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>

                <v-btn
                  @click="dialog.filter = true"
                  width="40"
                  height="40"
                  fab
                  elevation="0"
                  class="ml-3">
                  <v-icon
                    style="opacity: 60%;">
                    mdi-filter-variant
                  </v-icon>
                </v-btn>
              </div>

              <v-menu
                ref="date_list"
                min-width="290px"
                max-width="290px"
                transition="scale-transition"
                offset-y
                :nudge-right="40"
                :close-on-content-click="false"
                v-model="picker.date_list">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    readonly
                    filled
                    rounded
                    dense
                    :color="set_color"
                    placeholder="Pilih Tanggal"

                    clearable
                    @click:clear="filter.datetime_list = ''; selected.menu_utama === 'all' ? fetchAll() : selected.menu_utama === 'not_exist' ? fetchNonTerapis() : fetchKalender();"

                    v-on="on"
                    v-bind="attrs"

                    hide-details

                    class="mb-4"

                    v-model="filter.datetime_list">
                  </v-text-field>
                </template>

                <v-date-picker
                  :color="set_color"
                  v-model="filter.datetime_list"
                  @input="picker.date_list = false; selected.menu_utama === 'all' ? fetchAll() : selected.menu_utama === 'not_exist' ? fetchNonTerapis() : fetchKalender();">
                </v-date-picker>
              </v-menu>

              <v-row>
                <v-col
                  cols="12"
                  md="6">
                  <v-btn
                    to="/jadwal/tambah"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_auth"
                    :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                    <div
                      class="btn_auth_in d-flex align-center justify-center"
                      :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                      <div>
                        <v-img
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/add_plus.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="ml-2">
                        Tambah Jadwal
                      </div>
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  md="6">
                  <v-btn
                    to="/jadwal/desktop"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_auth"
                    :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                    <div
                      class="btn_auth_in d-flex align-center justify-center"
                      :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                      <div>
                        <v-img
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/jadwal_besar.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="ml-2">
                        Jadwal Desktop
                      </div>
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text v-if="selected.menu_utama === 'all' || selected.menu_utama === 'not_exist'"
              class="pb-0">
              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 4 : list_all_or_non_terapis"
                    :key="index"
                    :loading="process.load"
                    :class="index < list_all_or_non_terapis.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list_all_or_non_terapis.length > 0"
                          flat
                          dense
                          three-line
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-list-item
                            @click="sheet.detail = true; fetchDetail(item.id);">
                            <v-list-item-avatar
                              size="40"
                              class="mt-2"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize">
                                {{ item.customer_name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light line-text-second black--text my-1">
                                {{ item.type === 'on_site' ? 'On Site' : 'Home Care' }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle
                                class="d-flex align-center">
                                <div>
                                  <div
                                    class="caption mb-1">
                                    Tanggal
                                  </div>

                                  <div
                                    class="black--text">
                                    {{ item.datetime | datetime }}
                                  </div>
                                </div>

                                <v-spacer />

                                <div>
                                  <div
                                    class="caption mb-1">
                                    Status
                                  </div>

                                  <div
                                    :class="item.status === 'waiting' ? 'red--text' : item.status === 'process' ? 'orange--text' : 'green--text'">
                                    {{
                                      item.status === 'waiting' ? 'Menunggu' :
                                      item.status === 'process' ? 'Proses' : 'Terkonfirmasi'
                                    }}
                                  </div>
                                </div>
                              </v-list-item-subtitle>

                              <div
                                class="pa-3 mt-4"
                                style="border: 2px dashed #bbb; border-radius: 10px;">
                                <div
                                  class="body-2 font-weight-light line-text-second black--text"
                                  v-html="item.trello_format">
                                </div>
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <div v-if="!process.load && pagination_all_or_non_terapis.total_data > 10 && limit < pagination_all_or_non_terapis.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination_all_or_non_terapis.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Non Terapis" />
            </v-card-text>

            <v-card-text v-if="selected.menu_utama === 'exist'"
              class="pb-0">
              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 4 : list_kalender"
                    :key="index"
                    :loading="process.load"
                    :class="index < list_kalender.length - 1 ? 'mb-4' : ''"
                    type="list-item-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list_kalender.length > 0"
                          flat
                          dense
                          three-line
                          color="#FFFFFF40"
                          style="border: 2px dashed #bbb; border-radius: 10px;">
                          <v-list-item
                            @click="sheet.kalender = true; filter.datetime_kalender = item.date; filter.therapist_id = item.user_id; fetch();">
                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 text-capitalize">
                                <div
                                  class="d-flex align-start">
                                  <div
                                    class="font-weight-bold">
                                    {{ item.user_name }}
                                  </div>

                                  <v-spacer />

                                  <div
                                    style="opacity: 70%;">
                                    {{ item.date | weekday }} - {{ item.date | date }}
                                  </div>
                                </div>
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="d-flex align-center">
                                <div>
                                  <div
                                    class="caption mb-1">
                                    Menunggu
                                  </div>

                                  <div
                                    class="red--text font-weight-bold">
                                    {{ item.waiting_count }}
                                  </div>
                                </div>

                                <v-spacer />

                                <div>
                                  <div
                                    class="caption mb-1">
                                    Proses
                                  </div>

                                  <div
                                    class="orange--text font-weight-bold">
                                    {{ item.process_count }}
                                  </div>
                                </div>

                                <v-spacer />

                                <div>
                                  <div
                                    class="caption mb-1">
                                    Terkonfirmasi
                                  </div>

                                  <div
                                    class="green--text font-weight-bold">
                                    {{ item.complete_count }}
                                  </div>
                                </div>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <div v-if="!process.load && pagination_kalender.total_data > 10 && limit < pagination_kalender.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination_kalender.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Kalender" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.kalender"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.kalender = false; fetchKalender();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.kalender = false; fetchKalender();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Jadwal {{ filter.datetime_kalender | date }}
                </span>
              </v-card-title>

              <v-card-text
                class="px-0 mt-16"
                style="height:calc(100vh - 65px); overflow: auto;">
                <v-card
                  flat
                  color="transparent">
                  <v-card-text
                    class="pb-0 mt-1">
                    <v-row
                      class="px-1">
                      <v-col
                        v-for="(item, index) in menu"
                        :key="index"
                        cols="4"
                        class="py-0 px-2">
                        <v-btn
                          @click="goTo(item.value)"
                          block
                          rounded
                          elevation="0"
                          :class="selected.menu !== item.value ? '' : 'font-weight-bold'"
                          :color="selected.menu !== item.value ? '#e2e2e2' : set_color"
                          :style="selected.menu !== item.value ? 'color: #8c8c8c;' : 'color: #FFFFFF;'"
                          class="body-2 text-capitalize mb-6">
                          {{ item.text }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-card v-if="selected.menu !== 'complete'"
                  flat
                  color="transparent">
                  <v-card-text
                    class="pb-0 pt-1">
                    <div
                      class="d-flex align-center">
                      <v-text-field
                        v-model="search"
                        @click:clear="search = ''; fetch();"
                        v-on:keyup.enter="fetch()"
                        filled
                        rounded
                        dense
                        single-line
                        hide-details
                        :color="set_color"
                        prepend-inner-icon="mdi-magnify"
                        placeholder="Cari data yang Anda butuhkan ?"
                        clearable>
                      </v-text-field>

                      <v-btn
                        @click="dialog.filter = true"
                        width="40"
                        height="40"
                        fab
                        elevation="0"
                        class="ml-3">
                        <v-icon
                          style="opacity: 60%;">
                          mdi-filter-variant
                        </v-icon>
                      </v-btn>
                    </div>

                    <v-btn
                      to="/jadwal/tambah"
                      block
                      large
                      rounded
                      elevation="0"
                      class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
                      :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                      <div
                        class="btn_auth_in d-flex align-center justify-center"
                        :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                        <div>
                          <v-img
                            contain
                            width="20"
                            height="20"
                            alt="bidanvitacare"
                            :src="require('@/assets/icon/add_plus.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>

                        <div
                          class="ml-2">
                          Tambah Jadwal
                        </div>
                      </div>
                    </v-btn>
                  </v-card-text>

                  <v-card-text
                    class="pb-0">
                    <v-row>
                      <v-col
                        cols="12">
                        <v-skeleton-loader
                          v-for="(item, index) in process.load ? 4 : list"
                          :key="index"
                          :loading="process.load"
                          :class="index < list.length - 1 ? 'mb-4' : ''"
                          type="list-item-avatar-three-line">
                          <template>
                            <div>
                              <v-list
                                v-if="!process.load && list.length > 0"
                                flat
                                dense
                                three-line
                                color="#FFFFFF40"
                                class="border-radius">
                                <v-list-item
                                  @click="sheet.detail = true; fetchDetail(item.id);">
                                  <v-list-item-avatar
                                    size="40"
                                    class="mt-2"
                                    style="border: 2px solid #ffffff;">
                                    <v-img
                                      width="100%"
                                      height="100%"
                                      alt="bidanvitacare"
                                      :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular
                                            size="20"
                                            indeterminate
                                            :color="set_color">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-list-item-avatar>

                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="body-2 font-weight-bold text-capitalize">
                                      {{ item.customer_name }}
                                    </v-list-item-title>

                                    <v-list-item-subtitle
                                      class="body-2 font-weight-light line-text-second black--text my-1">
                                      {{ item.type === 'on_site' ? 'On Site' : 'Home Care' }}
                                    </v-list-item-subtitle>

                                    <v-list-item-subtitle
                                      class="d-flex align-center">
                                      <div>
                                        <div
                                          class="caption mb-1">
                                          Tanggal
                                        </div>

                                        <div
                                          class="black--text">
                                          {{ item.datetime | datetime }}
                                        </div>
                                      </div>

                                      <v-spacer />

                                      <div>
                                        <div
                                          class="caption mb-1">
                                          Status
                                        </div>

                                        <div
                                          :class="item.status === 'waiting' ? 'red--text' : item.status === 'process' ? 'orange--text' : 'green--text'">
                                          {{
                                            item.status === 'waiting' ? 'Menunggu' :
                                            item.status === 'process' ? 'Proses' : 'Terkonfirmasi'
                                          }}
                                        </div>
                                      </div>
                                    </v-list-item-subtitle>

                                    <div
                                      class="pa-3 mt-4"
                                      style="border: 2px dashed #bbb; border-radius: 10px;">
                                      <div
                                        class="body-2 font-weight-light line-text-second black--text"
                                        v-html="item.trello_format">
                                      </div>
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </div>
                          </template>
                        </v-skeleton-loader>
                      </v-col>
                    </v-row>

                    <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                      class="text-center my-4">
                      <v-btn
                        @click="limit += 10"
                        :loading="process.limit"
                        small
                        rounded
                        outlined
                        elevation="0"
                        :color="set_color"
                        class="text-capitalize"
                        :style="`color: ${set_color};`">
                        Lebih banyak
                      </v-btn>
                    </div>

                    <Empty v-if="!process.load && pagination.total_page < 1"
                      class="py-6"
                      margin="my-16"
                      size="125"
                      message="Jadwal" />
                  </v-card-text>
                </v-card>

                <v-card v-if="selected.menu === 'complete'"
                  flat
                  color="transparent">
                  <v-card-text
                    class="pb-0 pt-1">
                    <v-text-field
                      v-model="search_complete"
                      @click:clear="search_complete = ''; fetchComplete();"
                      v-on:keyup.enter="fetchComplete()"
                      filled
                      rounded
                      dense
                      single-line
                      hide-details
                      :color="set_color"
                      prepend-inner-icon="mdi-magnify"
                      placeholder="Cari data yang Anda butuhkan ?"
                      clearable>
                    </v-text-field>
                  </v-card-text>

                  <v-card-text
                    class="pb-0">
                    <v-row>
                      <v-col
                        cols="12">
                        <v-skeleton-loader
                          v-for="(item, index) in process.load ? 4 : list"
                          :key="index"
                          :loading="process.load"
                          :class="index < list.length - 1 ? 'mb-4' : ''"
                          type="list-item-avatar-three-line">
                          <template>
                            <div>
                              <v-list
                                v-if="!process.load && list.length > 0"
                                flat
                                dense
                                three-line
                                color="#FFFFFF40"
                                class="border-radius">
                                <v-list-item
                                  @click="sheet.detail_complete = true; fetchDetailComplete(item.id);">
                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="body-2 font-weight-bold d-flex align-center">
                                      <v-avatar
                                        size="25"
                                        style="border: 2px solid #ffffff;">
                                        <v-img
                                          width="100%"
                                          height="100%"
                                          alt="bidanvitacare"
                                          :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                          class="ma-auto">
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height"
                                              align="center"
                                              justify="center">
                                              <v-progress-circular
                                                size="20"
                                                indeterminate
                                                :color="set_color">
                                              </v-progress-circular>
                                            </v-row>
                                          </template>
                                        </v-img>
                                      </v-avatar>

                                      <span
                                        class="text-capitalize ml-2">
                                        {{ item.customer_name }}
                                      </span>

                                      <v-spacer />

                                      <v-chip
                                        small
                                        class="white--text text-capitalize"
                                        :color="item.so_status === 'complete' ? 'green' :
                                                item.so_status === 'refund' ? 'orange' :
                                                item.so_status === 'cancel' ? 'red' : 'grey'">
                                        {{ item.so_status }}
                                      </v-chip>
                                    </v-list-item-title>

                                    <v-list-item-subtitle
                                      class="body-2 font-weight-light line-text-second black--text my-2 py-2"
                                      style="border-top: 1px dashed #bbb; border-bottom: 1px dashed #bbb;">
                                      <div
                                        class="d-flex align-center">
                                        <div>
                                          <div
                                            class="caption"
                                            style="opacity: 60%; margin-bottom: 1px;">
                                            Tipe
                                          </div>

                                          <div
                                            class="body-2 black--text">
                                            {{ item.type === 'on_site' ? 'On Site' : 'Home Care' }}
                                          </div>
                                        </div>

                                        <v-spacer />

                                        <div>
                                          <div
                                            class="caption"
                                            style="opacity: 60%; margin-bottom: 1px;">
                                            Umur
                                          </div>

                                          <div
                                            class="body-2 black--text">
                                            {{ item.age !== '' ? item.age : '-' }}
                                          </div>
                                        </div>
                                      </div>

                                      <v-divider
                                        class="my-2"
                                        style="border-top: 1px dashed #bbb;" />

                                      <div
                                        class="d-flex align-center">
                                        <div>
                                          <div
                                            class="caption"
                                            style="opacity: 60%; margin-bottom: 1px;">
                                            BB
                                          </div>

                                          <div
                                            class="black--text font-weight-bold"
                                            style="font-size: 0.8125rem;">
                                            {{ item.bb }} Kg
                                          </div>
                                        </div>

                                        <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                                        <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                          <div
                                            class="caption"
                                            style="opacity: 60%; margin-bottom: 1px;">
                                            TB
                                          </div>

                                          <div
                                            class="black--text font-weight-bold"
                                            style="font-size: 0.8125rem;">
                                            {{ item.tb }} Cm
                                          </div>
                                        </div>

                                        <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                                        <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                          <div
                                            class="caption"
                                            style="opacity: 60%; margin-bottom: 1px;">
                                            LILA
                                          </div>

                                          <div
                                            class="black--text font-weight-bold"
                                            style="font-size: 0.8125rem;">
                                            {{ item.lila }} Cm
                                          </div>
                                        </div>

                                        <v-spacer v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'" />

                                        <div v-if="item.customer_type === 'bayi' || item.customer_type === 'anak'">
                                          <div
                                            class="caption"
                                            style="opacity: 60%; margin-bottom: 1px;">
                                            LK
                                          </div>

                                          <div
                                            class="black--text font-weight-bold"
                                            style="font-size: 0.8125rem;">
                                            {{ item.lk }} Cm
                                          </div>
                                        </div>

                                        <v-spacer v-if="item.customer_type !== 'bayi' && item.customer_type !== 'anak'" />

                                        <div v-if="item.customer_type !== 'bayi' && item.customer_type !== 'anak'">
                                          <div
                                            class="caption"
                                            style="opacity: 60%; margin-bottom: 1px;">
                                            Tensi
                                          </div>

                                          <div
                                            class="black--text font-weight-bold"
                                            style="font-size: 0.8125rem;">
                                            {{ item.tensi }} mmHg
                                          </div>
                                        </div>
                                      </div>

                                      <v-divider
                                        class="my-2"
                                        style="border-top: 1px dashed #bbb;" />

                                      <div
                                        class="d-flex align-center">
                                        <div
                                          class="caption"
                                          style="opacity: 60%;">
                                          Klien
                                        </div>

                                        <v-spacer />

                                        <div>
                                          <div
                                            class="d-flex align-center cursor-pointer">
                                            <v-avatar
                                              v-for="(item_customer, index_customer) in item.patner"
                                              :key="index_customer"
                                              v-show="index_customer < 2"
                                              size="24"
                                              class="mt-0"
                                              style="border: 2px solid #ffffff;"
                                              :style="item.patner.length === 2 && index_customer === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                                      item.patner.length > 2 && index_customer === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                                      item.patner.length > 2 && index_customer === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                                              <v-img
                                                width="100%"
                                                height="100%"
                                                alt="bidanvitacare"
                                                :src="item_customer.image_url !== '' ? item_customer.image_url : require('@/assets/image/member_default.jpg')"
                                                class="ma-auto">
                                                <template v-slot:placeholder>
                                                  <v-row
                                                    class="fill-height"
                                                    align="center"
                                                    justify="center">
                                                    <v-progress-circular
                                                      size="20"
                                                      indeterminate
                                                      :color="set_color">
                                                    </v-progress-circular>
                                                  </v-row>
                                                </template>
                                              </v-img>
                                            </v-avatar>

                                            <span v-if="item.patner.length < 2"
                                              class="caption font-weight-bold one-line ml-1"
                                              style="width: 50px;">
                                              {{ item.patner[0].name }}
                                            </span>

                                            <span v-if="item.patner.length > 2"
                                              class="caption font-weight-bold ml-1">
                                              +{{ item.patner.length - 2 }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>

                                      <!-- <v-divider
                                        class="my-2"
                                        style="border-top: 1px dashed #bbb;" />

                                      <div
                                        class="d-flex align-center">
                                        <div
                                          class="caption"
                                          style="opacity: 60%;">
                                          Terapis
                                        </div>

                                        <v-spacer />

                                        <div>
                                          <div
                                            class="d-flex align-center cursor-pointer">
                                            <v-avatar
                                              v-for="(item_therapist, index_therapist) in item.therapist"
                                              :key="index_therapist"
                                              v-show="index_therapist < 2"
                                              size="24"
                                              class="mt-0"
                                              style="border: 2px solid #ffffff;"
                                              :style="item.therapist.length === 2 && index_therapist === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                                      item.therapist.length > 2 && index_therapist === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                                      item.therapist.length > 2 && index_therapist === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                                              <v-img
                                                width="100%"
                                                height="100%"
                                                alt="bidanvitacare"
                                                :src="item_therapist.image_url !== '' ? item_therapist.image_url : require('@/assets/image/member_default.jpg')"
                                                class="ma-auto">
                                                <template v-slot:placeholder>
                                                  <v-row
                                                    class="fill-height"
                                                    align="center"
                                                    justify="center">
                                                    <v-progress-circular
                                                      size="20"
                                                      indeterminate
                                                      :color="set_color">
                                                    </v-progress-circular>
                                                  </v-row>
                                                </template>
                                              </v-img>
                                            </v-avatar>

                                            <span v-if="item.therapist.length < 2"
                                              class="caption font-weight-bold one-line ml-1"
                                              style="width: 50px;">
                                              {{ item.therapist[0].name }}
                                            </span>

                                            <span v-if="item.therapist.length > 2"
                                              class="caption font-weight-bold ml-1">
                                              +{{ item.therapist.length - 2 }}
                                            </span>
                                          </div>
                                        </div>
                                      </div> -->
                                    </v-list-item-subtitle>

                                    <v-list-item-subtitle
                                      class="d-flex align-center">
                                      <div>
                                        <div
                                          class="caption mb-1">
                                          Tanggal & Waktu Kunjungan
                                        </div>

                                        <div
                                          class="black--text">
                                          {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                                        </div>
                                      </div>

                                      <v-spacer />

                                      <div>
                                        <div
                                          class="caption mb-1">
                                          {{ item.customer_wali_status }}
                                        </div>

                                        <div
                                          class="black--text">
                                          {{ item.customer_wali_name }}
                                        </div>
                                      </div>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </div>
                          </template>
                        </v-skeleton-loader>
                      </v-col>
                    </v-row>

                    <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                      class="text-center my-4">
                      <v-btn
                        @click="limit += 10"
                        :loading="process.limit"
                        small
                        rounded
                        outlined
                        elevation="0"
                        :color="set_color"
                        class="text-capitalize"
                        :style="`color: ${set_color};`">
                        Lebih banyak
                      </v-btn>
                    </div>

                    <Empty v-if="!process.load && pagination.total_page < 1"
                      class="py-12"
                      margin="my-16"
                      size="125"
                      message="Jadwal" />
                  </v-card-text>
                </v-card>
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.detail"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.detail = false; detail = {};"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.detail = false; detail = {};"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Jadwal {{ detail.customer_name }}
                </span>
              </v-card-title>

              <v-card-text
                class="mt-16"
                style="height:calc(100vh - 130px); overflow: auto;">
                <v-skeleton-loader
                  :loading="process.detail"
                  type="list-item-avatar-three-line, article, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, article, actions">
                  <div>
                    <div v-if="!process.detail && Object.keys(detail).length > 0">
                      <v-card
                        flat
                        color="transparent"
                        class="py-0"
                        style="border-radius: 10px;">
                        <v-list
                          flat
                          dense
                          color="transparent">
                          <v-list-item
                            class="px-0">
                            <v-list-item-avatar
                              size="40"
                              style="border: 2px solid #ffffff; overflow: unset;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="detail.customer_image_url !== '' ? detail.customer_image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize mb-2">
                                {{ detail.customer_name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light black--text">
                                {{ detail.customer_mobilephone }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card>

                      <v-divider
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <div
                        class="d-flex align-center my-4">
                        <div>
                          <div
                            class="line-text-second">
                            {{ detail.type === 'on_site' ? 'On Site' : 'Home Care' }}
                          </div>

                          <div
                            class="caption font-weight-bold">
                            {{ detail.datetime | datetime }}
                          </div>
                        </div>

                        <v-spacer />

                        <v-chip
                          small
                          class="white--text"
                          :color="detail.status === 'waiting' ? 'red' : detail.status === 'process' ? 'orange' : 'green'">
                          {{
                            detail.status === 'waiting' ? 'Menunggu' :
                            detail.status === 'process' ? 'Proses' : 'Terkonfirmasi'
                          }}
                        </v-chip>
                      </div>

                      <v-divider
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <v-row>
                        <v-col
                          v-for="(item, index) in detail.items"
                          :key="index"
                          cols="12">
                          <v-card
                            flat
                            color="#FFFFFF40"
                            class="pa-3 pt-2"
                            style="border: 2px dashed #bbb; border-radius: 10px;">
                            <v-list
                              flat
                              dense
                              two-line
                              color="transparent"
                              class="py-0">
                              <v-list-item
                                class="px-0">
                                <v-list-item-avatar
                                  size="40"
                                  tile
                                  class="mt-3"
                                  style="border: 2px solid #ffffff; border-radius: 5px !important;">
                                  <v-img
                                    contain
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare"
                                    :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          size="20"
                                          indeterminate
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <div
                                    class="mb-2">
                                    <v-chip
                                      x-small
                                      color="#424242"
                                      class="white--text d-flex justify-center"
                                      style="width: 65px;">
                                      {{ item.type === 'service' ? 'Layanan' : item.type === 'product' ? 'Produk' : 'Bahan' }}
                                    </v-chip>
                                  </div>

                                  <div
                                    class="body-2 font-weight-bold text-capitalize">
                                    <span
                                      class="two-line">
                                      {{ item.name }}
                                    </span>
                                  </div>

                                  <div v-if="item.customer.length > 0">
                                    <v-divider
                                      class="my-3"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>

                                  <div v-if="item.customer.length > 0"
                                    class="d-flex align-center">
                                    <div>
                                      Klien
                                    </div>

                                    <v-spacer />

                                    <div>
                                      <div
                                        @click="dialog.partner_or_terapis = true;
                                                selected.type_user = 'Klien';
                                                selected.partner_or_terapis = item.customer;"
                                        class="d-flex align-center cursor-pointer">
                                        <v-avatar
                                          v-for="(item_customer, index_customer) in item.customer"
                                          :key="index_customer"
                                          v-show="index_customer < 2"
                                          size="24"
                                          class="mt-0"
                                          style="border: 2px solid #ffffff;"
                                          :style="item.customer.length === 2 && index_customer === 0 ? 'position: absolute; right: 20px; z-index: 2;' :
                                                  item.customer.length > 2 && index_customer === 0 ? 'position: absolute; right: 38px; z-index: 2;' :
                                                  item.customer.length > 2 && index_customer === 1 ? 'position: absolute; right: 22px; z-index: 1;' : ''">
                                          <v-img
                                            width="100%"
                                            height="100%"
                                            alt="bidanvitacare"
                                            :src="item_customer.image_url !== '' ? item_customer.image_url : require('@/assets/image/member_default.jpg')"
                                            class="ma-auto">
                                            <template v-slot:placeholder>
                                              <v-row
                                                class="fill-height"
                                                align="center"
                                                justify="center">
                                                <v-progress-circular
                                                  size="20"
                                                  indeterminate
                                                  :color="set_color">
                                                </v-progress-circular>
                                              </v-row>
                                            </template>
                                          </v-img>
                                        </v-avatar>

                                        <span v-if="item.customer.length < 2"
                                          class="caption font-weight-bold one-line ml-1"
                                          style="width: 50px;">
                                          {{ item.customer[0].name }}
                                        </span>

                                        <span v-if="item.customer.length > 2"
                                          class="caption font-weight-bold ml-1">
                                          +{{ item.customer.length - 2 }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <v-divider
                                      :class="item.customer.length > 0 ? 'mt-3' : 'mt-2'"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>

                            <div
                              class="d-flex align-start">
                              <div
                                class="caption">
                                {{ item.note !== '' ? item.note : 'Tidak ada catatan' }}
                              </div>

                              <v-spacer />

                              <div v-if="item.type === 'service'">
                                <!-- <div
                                  class="d-flex align-center">
                                  <v-avatar
                                    size="20"
                                    class="mt-0"
                                    style="border: 2px solid #ffffff;">
                                    <v-img
                                      width="100%"
                                      height="100%"
                                      alt="bidanvitacare"
                                      :src="item.therapist_image_url !== '' ? item.therapist_image_url : require('@/assets/image/member_default.jpg')"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular
                                            size="20"
                                            indeterminate
                                            :color="set_color">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>

                                  <span
                                    class="caption font-weight-bold ml-1">
                                    {{ item.therapist_name }}
                                  </span>
                                </div> -->

                                <div v-if="item.visit.length > 0"
                                  @click="dialog.partner_or_terapis = true;
                                          selected.type_user = 'Terapis';
                                          selected.partner_or_terapis = item.visit;"
                                  class="d-flex align-center cursor-pointer">
                                  <v-avatar
                                    v-for="(item_visit, index_visit) in item.visit"
                                    :key="index_visit"
                                    v-show="index_visit < 2"
                                    size="24"
                                    class="mt-0"
                                    style="border: 2px solid #ffffff;"
                                    :style="item.visit.length === 2 && index_visit === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                            item.visit.length > 2 && index_visit === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                            item.visit.length > 2 && index_visit === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                                    <v-img
                                      width="100%"
                                      height="100%"
                                      alt="bidanvitacare"
                                      :src="item_visit.user_image_url !== '' ? item_visit.user_image_url : require('@/assets/image/member_default.jpg')"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular
                                            size="20"
                                            indeterminate
                                            :color="set_color">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>

                                  <span v-if="item.visit.length < 2"
                                    class="caption font-weight-bold one-line ml-1"
                                    style="width: 50px;">
                                    {{ item.visit[0].user_name }}
                                  </span>

                                  <span v-if="item.visit.length > 2"
                                    class="caption font-weight-bold ml-1">
                                    +{{ item.visit.length - 2 }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-divider
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <div
                        class="mt-4 mb-2">
                        Jlm Item ({{ detail.total_item_qty }})
                      </div>

                      <div
                        class="d-flex align-center mb-4">
                        <div>
                          Down Payment
                        </div>

                        <v-spacer />

                        <div>
                          {{ detail.dp | price }}
                        </div>
                      </div>

                      <v-divider v-if="detail.type === 'home_care'"
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <div v-if="detail.type === 'home_care'">
                        <div
                          class="font-weight-bold mt-4 mb-2">
                          Alamat
                        </div>

                        <div
                          class="mb-2">
                          {{ detail.address_note }}
                        </div>

                        <div
                          class="mb-4">
                          {{ detail.address_subdistrict_name }}, {{ detail.address_city_name }}, {{ detail.address_province_name }}
                        </div>
                      </div>

                      <div v-if="detail.type === 'home_care'">
                        <div
                          class="font-weight-bold mt-4 mb-2">
                          Gambar Rumah
                        </div>

                        <div
                          class="text-truncate mb-4">
                          <span v-if="detail.address_image_url !== ''"
                            @click="dialog.gambar_rumah = true"
                            class="cursor-pointer"
                            style="color: #1976d3;">
                            Lihat disini
                          </span>

                          <span v-else>
                            -
                          </span>
                        </div>
                      </div>

                      <div v-if="detail.type === 'home_care'">
                        <div
                          class="font-weight-bold mt-4 mb-2">
                          Link Map
                        </div>

                        <div
                          class="text-truncate mb-4">
                          <a v-if="detail.address_link_map !== ''"
                            :href="detail.address_link_map"
                            target="_blank">
                            {{ detail.address_link_map }}
                          </a>

                          <span v-else>
                            -
                          </span>
                        </div>
                      </div>

                      <v-divider
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <div
                        class="font-weight-bold mt-4 mb-2">
                        Pendamping
                      </div>

                      <div
                        class="d-flex align-center mb-2">
                        <div>
                          Nama Pendamping
                        </div>

                        <v-spacer />

                        <div
                          class="text-capitalize">
                          {{ detail.wali !== '' ? detail.wali : '-' }}
                        </div>
                      </div>

                      <div
                        class="d-flex align-center mb-2">
                        <div>
                          No. Handphone Pendamping
                        </div>

                        <v-spacer />

                        <div>
                          {{ detail.mobilephone_wali !== '' ? detail.mobilephone_wali : '-' }}
                        </div>
                      </div>

                      <div
                        class="d-flex align-center mb-2">
                        <div>
                          No. Handphone Lainnya
                        </div>

                        <v-spacer />

                        <div>
                          {{ detail.mobilephone_other !== '' ? detail.mobilephone_other : '-' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-skeleton-loader>
              </v-card-text>

              <v-card
                flat
                color="#FFFFFF"
                min-height="50"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="4"
                      class="py-0">
                      <v-btn v-if="detail.status !== 'complete'"
                        :to="`/jadwal/ubah/${detail.id}?menu=${selected.menu}`"
                        :disabled="process.form || process.delete"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/calendar_edit.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="blue--text ml-2">
                            Ubah
                          </div>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="4"
                      class="py-0">
                      <v-btn v-if="detail.status === 'waiting'"
                        @click="dialog.info_warning = true"
                        :disabled="process.form || process.delete"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/calendar_pending.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="orange--text ml-2">
                            Proses
                          </div>
                        </div>
                      </v-btn>

                      <v-btn v-if="detail.status === 'process'"
                        @click="to_save_complete"
                        :disabled="process.form || process.delete"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/calendar_check.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="green--text ml-2">
                            Konfirm
                          </div>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="2"
                      class="py-0">
                      <v-img
                        @click="dialog.delete = true"
                        contain
                        width="30"
                        height="30"
                        alt="bidanvitacare"
                        :src="require('@/assets/icon/trash.png')"
                        class="ma-auto cursor-pointer">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="2"
                      class="py-0">
                      <v-img
                        @click="to_wa()"
                        :disabled="process.wa"
                        contain
                        width="30"
                        height="30"
                        alt="bidanvitacare"
                        :src="require('@/assets/icon/whatsapp.png')"
                        class="ma-auto cursor-pointer">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.detail_complete"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card v-if="Object.keys(detail_complete).length > 0"
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.detail_complete = false; detail_complete = {}; fetchComplete();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.detail_complete = false; detail_complete = {}; fetchComplete();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Kunjungan {{ detail_complete.customer_name }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 80px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-4 pb-0">
                  <div
                    class="d-flex align-center">
                    <div
                      class="d-flex align-center">
                      <v-avatar
                        size="36"
                        style="border: 2px solid #ffffff;">
                        <v-img
                          width="100%"
                          height="100%"
                          alt="bidanvitacare"
                          :src="detail_complete.customer_image_url !== '' ? detail_complete.customer_image_url : require('@/assets/image/member_default.jpg')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>

                      <span
                        class="font-weight-bold text-capitalize black--text ml-2">
                        {{ detail_complete.customer_name }}
                      </span>
                    </div>

                    <v-spacer />

                    <v-btn
                      @click="print_nota"
                      :loading="process.print"
                      :disabled="process.print"
                      min-width="36"
                      width="36"
                      height="36"
                      rounded
                      elevation="0"
                      :color="set_color"
                      class="text-capitalize white--text px-0 ml-2">
                      <v-icon>mdi-printer-outline</v-icon>
                    </v-btn>
                  </div>
                </v-card-text>

                <v-card-text>
                  <v-divider
                    class="mb-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="d-flex align-center">
                    <div>
                      <div
                        class="body-2 mb-1">
                        Tipe
                      </div>

                      <div
                        class="body-2 font-weight-bold black--text">
                        {{ detail_complete.type === 'on_site' ? 'On Site' : 'Home Care' }}
                      </div>
                    </div>

                    <v-spacer />

                    <div>
                      <div
                        class="body-2 mb-1">
                        Umur
                      </div>

                      <div
                        class="body-2 font-weight-bold black--text">
                        {{ detail_complete.age !== '' ? detail_complete.age : '-' }}
                      </div>
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="d-flex align-center">
                    <div>
                      <div
                        class="body-2 mb-1">
                        BB
                      </div>

                      <div
                        class="body-2 black--text font-weight-bold">
                        {{ detail_complete.bb }} Kg
                      </div>
                    </div>

                    <v-spacer v-if="detail_complete.customer_type === 'bayi' || detail_complete.customer_type === 'anak'" />

                    <div v-if="detail_complete.customer_type === 'bayi' || detail_complete.customer_type === 'anak'">
                      <div
                        class="body-2 mb-1">
                        TB
                      </div>

                      <div
                        class="body-2 black--text font-weight-bold">
                        {{ detail_complete.tb }} Cm
                      </div>
                    </div>

                    <v-spacer v-if="detail_complete.customer_type === 'bayi' || detail_complete.customer_type === 'anak'" />

                    <div v-if="detail_complete.customer_type === 'bayi' || detail_complete.customer_type === 'anak'">
                      <div
                        class="body-2 mb-1">
                        LILA
                      </div>

                      <div
                        class="body-2 black--text font-weight-bold">
                        {{ detail_complete.lila }} Cm
                      </div>
                    </div>

                    <v-spacer v-if="detail_complete.customer_type === 'bayi' || detail_complete.customer_type === 'anak'" />

                    <div v-if="detail_complete.customer_type === 'bayi' || detail_complete.customer_type === 'anak'">
                      <div
                        class="body-2 mb-1">
                        LK
                      </div>

                      <div
                        class="body-2 black--text font-weight-bold">
                        {{ detail_complete.lk }} Cm
                      </div>
                    </div>

                    <v-spacer v-if="detail_complete.customer_type !== 'bayi' && detail_complete.customer_type !== 'anak'" />

                    <div v-if="detail_complete.customer_type !== 'bayi' && detail_complete.customer_type !== 'anak'">
                      <div
                        class="body-2 mb-1">
                        Tensi
                      </div>

                      <div
                        class="body-2 black--text font-weight-bold">
                        {{ detail_complete.tensi }} mmHg
                      </div>
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="d-flex align-center">
                    <div
                      class="body-2">
                      Klien
                    </div>

                    <v-spacer />

                    <div>
                      <div
                        @click="dialog.partner_or_terapis = true;
                                selected.type_user = 'Klien';
                                selected.partner_or_terapis = detail_complete.patner;"
                        class="d-flex align-center cursor-pointer">
                        <v-avatar
                          v-for="(item_customer, index_customer) in detail_complete.patner"
                          :key="index_customer"
                          v-show="index_customer < 2"
                          size="24"
                          class="mt-0"
                          style="border: 2px solid #ffffff;"
                          :style="detail_complete.patner.length === 2 && index_customer === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                  detail_complete.patner.length > 2 && index_customer === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                  detail_complete.patner.length > 2 && index_customer === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                          <v-img
                            width="100%"
                            height="100%"
                            alt="bidanvitacare"
                            :src="item_customer.image_url !== '' ? item_customer.image_url : require('@/assets/image/member_default.jpg')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>

                        <span v-if="detail_complete.patner.length < 2"
                          class="caption black--text font-weight-bold one-line ml-1"
                          style="width: 50px;">
                          {{ detail_complete.patner[0].name }}
                        </span>

                        <span v-if="detail_complete.patner.length > 2"
                          class="caption black--text font-weight-bold ml-1">
                          +{{ detail_complete.patner.length - 2 }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <!-- <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="d-flex align-center">
                    <div
                      class="body-2">
                      Terapis
                    </div>

                    <v-spacer />

                    <div>
                      <div
                        @click="dialog.partner_or_terapis = true;
                                selected.type_user = 'Terapis';
                                selected.partner_or_terapis = detail_complete.therapist;"
                        class="d-flex align-center cursor-pointer">
                        <v-avatar
                          v-for="(item_therapist, index_therapist) in detail_complete.therapist"
                          :key="index_therapist"
                          v-show="index_therapist < 2"
                          size="24"
                          class="mt-0"
                          style="border: 2px solid #ffffff;"
                          :style="detail_complete.therapist.length === 2 && index_therapist === 0 ? 'position: absolute; right: 32px; z-index: 2;' :
                                  detail_complete.therapist.length > 2 && index_therapist === 0 ? 'position: absolute; right: 50px; z-index: 2;' :
                                  detail_complete.therapist.length > 2 && index_therapist === 1 ? 'position: absolute; right: 34px; z-index: 1;' : ''">
                          <v-img
                            width="100%"
                            height="100%"
                            alt="bidanvitacare"
                            :src="item_therapist.image_url !== '' ? item_therapist.image_url : require('@/assets/image/member_default.jpg')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>

                        <span v-if="detail_complete.therapist.length < 2"
                          class="caption black--text font-weight-bold one-line ml-1"
                          style="width: 50px;">
                          {{ detail_complete.therapist[0].name }}
                        </span>

                        <span v-if="detail_complete.therapist.length > 2"
                          class="caption black--text font-weight-bold ml-1">
                          +{{ detail_complete.therapist.length - 2 }}
                        </span>
                      </div>
                    </div>
                  </div> -->

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <v-row
                    class="mb-2">
                    <v-col
                      cols="4"
                      class="py-0">
                      Tanggal & Waktu Kunjungan
                    </v-col>

                    <v-col
                      cols="8"
                      class="py-0">
                      : {{ detail_complete.datetime | date }} Pukul {{ detail_complete.datetime | time }}
                    </v-col>
                  </v-row>

                  <v-row
                    class="mb-2">
                    <v-col
                      cols="4"
                      class="py-0">
                      Status
                    </v-col>

                    <v-col
                      cols="8"
                      class="py-0">
                      : {{ detail_complete.customer_wali_status !== '' ? detail_complete.customer_wali_status : '-' }}
                    </v-col>
                  </v-row>

                  <v-row
                    class="mb-2">
                    <v-col
                      cols="4"
                      class="py-0">
                      Nama Wali
                    </v-col>

                    <v-col
                      cols="8"
                      class="py-0">
                      : {{ detail_complete.customer_wali_name !== '' ? detail_complete.customer_wali_name : '-' }}
                    </v-col>
                  </v-row>

                  <v-row
                    class="mb-2">
                    <v-col
                      cols="4"
                      class="py-0">
                      Nama Pendamping
                    </v-col>

                    <v-col
                      cols="8"
                      class="py-0">
                      : {{ detail_complete.schedule_wali !== '' ? detail_complete.schedule_wali : '-' }}
                    </v-col>
                  </v-row>

                  <!-- <v-row
                    class="mb-2">
                    <v-col
                      cols="4"
                      class="py-0">
                      Keluhan
                    </v-col>

                    <v-col
                      cols="8"
                      class="py-0">
                      <div
                        class="d-flex align-start">
                        <div>
                          :
                        </div>

                        <div
                          class="ml-1">
                          {{ detail_complete.complaint !== '' ? detail_complete.complaint : '-' }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mb-2">
                    <v-col
                      cols="4"
                      class="py-0">
                      Keterangan
                    </v-col>

                    <v-col
                      cols="8"
                      class="py-0">
                      <div
                        class="d-flex align-start">
                        <div>
                          :
                        </div>

                        <div
                          class="ml-1">
                          {{ detail_complete.note !== '' ? detail_complete.note : '-' }}
                        </div>
                      </div>
                    </v-col>
                  </v-row> -->

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <v-row
                    class="mb-2">
                    <v-col
                      cols="4"
                      class="py-0">
                      Alamat
                    </v-col>

                    <v-col
                      cols="8"
                      class="py-0">
                      <div
                        class="d-flex align-start">
                        <div>
                          :
                        </div>

                        <div
                          class="ml-1">
                          {{ detail_complete.address_full !== '' ? detail_complete.address_full : '-' }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mb-2">
                    <v-col
                      cols="4"
                      class="py-0">
                      Kecamatan
                    </v-col>

                    <v-col
                      cols="8"
                      class="py-0">
                      : {{ detail_complete.address_subdistrict_name !== '' ? detail_complete.address_subdistrict_name : '-' }}
                    </v-col>
                  </v-row>

                  <v-row
                    class="mb-2">
                    <v-col
                      cols="4"
                      class="py-0">
                      Kabupaten
                    </v-col>

                    <v-col
                      cols="8"
                      class="py-0">
                      : {{ detail_complete.address_city_name !== '' ? detail_complete.address_city_name : '-' }}
                    </v-col>
                  </v-row>

                  <v-row
                    class="mb-2">
                    <v-col
                      cols="4"
                      class="py-0">
                      Provinsi
                    </v-col>

                    <v-col
                      cols="8"
                      class="py-0">
                      : {{ detail_complete.address_province_name !== '' ? detail_complete.address_province_name : '-' }}
                    </v-col>
                  </v-row>

                  <v-row
                    class="mb-2">
                    <v-col
                      cols="4"
                      class="py-0">
                      Rumah
                    </v-col>

                    <v-col
                      cols="8"
                      class="py-0">
                      <div
                        class="d-flex align-start">
                        <div>
                          :
                        </div>

                        <div
                          class="ml-1">
                          {{ detail_complete.address_note !== '' ? detail_complete.address_note : '-' }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mb-2">
                    <v-col
                      cols="4"
                      class="py-0">
                      Gambar Rumah
                    </v-col>

                    <v-col
                      cols="8"
                      class="py-0">
                      <div
                        class="d-flex align-start">
                        <div>
                          :
                        </div>

                        <div
                          class="ml-1">
                          <span v-if="detail_complete.address_image_url !== ''"
                            @click="dialog.gambar_rumah_complete = true"
                            class="cursor-pointer"
                            style="color: #1976d3;">
                            Lihat disini
                          </span>

                          <span v-else>
                            -
                          </span>
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <v-row
                    class="mb-2">
                    <v-col
                      cols="4"
                      class="py-0">
                      Komplain untuk Terapis (Admin)
                    </v-col>

                    <v-col
                      cols="8"
                      class="py-0">
                      <div
                        class="d-flex align-start">
                        <div>
                          :
                        </div>

                        <div
                          class="ml-1">
                          {{ detail_complete.admin_complaint !== '' ? detail_complete.admin_complaint : '-' }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mb-2">
                    <v-col
                      cols="4"
                      class="py-0">
                      Saran / Masukkan (Admin)
                    </v-col>

                    <v-col
                      cols="8"
                      class="py-0">
                      <div
                        class="d-flex align-start">
                        <div>
                          :
                        </div>

                        <div
                          class="ml-1">
                          {{ detail_complete.admin_suggest !== '' ? detail_complete.admin_suggest : '-' }}
                        </div>
                      </div>
                    </v-col>
                  </v-row> -->

                  <!-- <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <v-row
                    class="mb-2">
                    <v-col
                      cols="4"
                      class="py-0">
                      Komplain untuk Terapis (Customer)
                    </v-col>

                    <v-col
                      cols="8"
                      class="py-0">
                      <div
                        class="d-flex align-start">
                        <div>
                          :
                        </div>

                        <div
                          class="ml-1">
                          {{ detail_complete.therapist_complaint !== '' ? detail_complete.therapist_complaint : '-' }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mb-2">
                    <v-col
                      cols="4"
                      class="py-0">
                      Saran / Masukkan (Customer)
                    </v-col>

                    <v-col
                      cols="8"
                      class="py-0">
                      <div
                        class="d-flex align-start">
                        <div>
                          :
                        </div>

                        <div
                          class="ml-1">
                          {{ detail_complete.therapist_suggest !== '' ? detail_complete.therapist_suggest : '-' }}
                        </div>
                      </div>
                    </v-col>
                  </v-row> -->

                  <v-divider
                    v-if="detail_complete.graph_bb_image_url !== ''"
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div v-if="detail_complete.graph_bb_image_url !== ''"
                    class="mb-6">
                    <div class="body-2 mb-2">
                      <!-- Grafik BB -->
                      {{ detail_complete.graph_bb_title }}
                    </div>

                    <v-img
                      contain
                      width="100%"
                      height="100%"
                      style="border-radius: 10px; border: 2px dashed #bbb;"
                      alt="bidanvitacare"
                      :src="detail_complete.graph_bb_image_url !== '' ? detail_complete.graph_bb_image_url : require('@/assets/image/image_default.png')"
                      class="ma-auto d-flex align-center text-center">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div
                      class="body-2 line-text-second mt-4">
                      <div
                        class="black--text font-weight-bold mb-2">
                        Kesimpulan
                      </div>

                      <div>
                        {{ detail_complete.graph_bb_kesimpulan }}
                      </div>
                    </div>
                  </div>

                  <v-divider
                    v-if="detail_complete.graph_tb_image_url !== ''"
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div v-if="detail_complete.graph_tb_image_url !== ''"
                    class="mb-6">
                    <div class="body-2 mb-2">
                      <!-- Grafik TB -->
                      {{ detail_complete.graph_tb_title }}
                    </div>

                    <v-img
                      contain
                      width="100%"
                      height="100%"
                      style="border-radius: 10px; border: 2px dashed #bbb;"
                      alt="bidanvitacare"
                      :src="detail_complete.graph_tb_image_url !== '' ? detail_complete.graph_tb_image_url : require('@/assets/image/image_default.png')"
                      class="ma-auto d-flex align-center text-center">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div
                      class="body-2 line-text-second mt-4">
                      <div
                        class="black--text font-weight-bold mb-2">
                        Kesimpulan
                      </div>

                      <div>
                        {{ detail_complete.graph_tb_kesimpulan }}
                      </div>
                    </div>
                  </div>

                  <v-divider
                    v-if="detail_complete.graph_lila_image_url !== ''"
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div v-if="detail_complete.graph_lila_image_url !== ''"
                    class="mb-6">
                    <div class="body-2 mb-2">
                      <!-- Grafik LILA -->
                      {{ detail_complete.graph_lila_title }}
                    </div>

                    <v-img
                      contain
                      width="100%"
                      height="100%"
                      style="border-radius: 10px; border: 2px dashed #bbb;"
                      alt="bidanvitacare"
                      :src="detail_complete.graph_lila_image_url !== '' ? detail_complete.graph_lila_image_url : require('@/assets/image/image_default.png')"
                      class="ma-auto d-flex align-center text-center">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div
                      class="body-2 line-text-second mt-4">
                      <div
                        class="black--text font-weight-bold mb-2">
                        Kesimpulan
                      </div>

                      <div>
                        {{ detail_complete.graph_lila_kesimpulan }}
                      </div>
                    </div>
                  </div>

                  <v-divider
                    v-if="detail_complete.graph_lk_image_url !== ''"
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div v-if="detail_complete.graph_lk_image_url !== ''"
                    class="mb-6">
                    <div class="body-2 mb-2">
                      <!-- Grafik LK -->
                      {{ detail_complete.graph_lk_title }}
                    </div>

                    <v-img
                      contain
                      width="100%"
                      height="100%"
                      style="border-radius: 10px; border: 2px dashed #bbb;"
                      alt="bidanvitacare"
                      :src="detail_complete.graph_lk_image_url !== '' ? detail_complete.graph_lk_image_url : require('@/assets/image/image_default.png')"
                      class="ma-auto d-flex align-center text-center">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div
                      class="body-2 line-text-second mt-4">
                      <div
                        class="black--text font-weight-bold mb-2">
                        Kesimpulan
                      </div>

                      <div>
                        {{ detail_complete.graph_lk_kesimpulan }}
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="50"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row>
                    <v-col
                      :cols="detail_complete.type === 'on_site' && (detail_complete.customer_type === 'bayi' || detail_complete.customer_type === 'anak') ? 4 : 6"
                      class="py-0">
                      <v-btn
                        @click="sheet.form = true; reset(detail_complete);"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/edit_pencil.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="blue--text ml-2">
                            Ubah
                          </div>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      :cols="detail_complete.type === 'on_site' && (detail_complete.customer_type === 'bayi' || detail_complete.customer_type === 'anak') ? 4 : 6"
                      class="py-0">
                      <v-btn
                        @click="sheet.attachment = true; fetchAttachment();"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/attachment.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="pink--text ml-2">
                            Lamp
                          </div>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col v-if="detail_complete.type === 'on_site' && (detail_complete.customer_type === 'bayi' || detail_complete.customer_type === 'anak')"
                      cols="4"
                      class="py-0">
                      <v-btn
                        @click="sheet.form_grafik = true; reset_grafik();"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/grafik.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="green--text ml-2">
                            Grafik
                          </div>
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.form"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.form = false; reset();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.form = false; reset();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Ubah
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-4">
                  <ValidationObserver
                    ref="form">
                    <v-form>
                      <div>
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Tanggal Kunjungan
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-menu
                          ref="date"
                          min-width="290px"
                          max-width="290px"
                          transition="scale-transition"
                          offset-y
                          :nudge-right="40"
                          :close-on-content-click="false"
                          v-model="picker.date">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider
                              name="Tanggal Kunjungan"
                              rules="required"
                              v-slot="{ errors, valid }">
                              <v-text-field
                                v-on:keyup.enter="save"
                                readonly
                                filled
                                rounded
                                dense
                                :color="set_color"
                                placeholder="Contoh. 2023-01-01"

                                :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form.date = ''"
                                v-on="on"
                                v-bind="attrs"

                                v-model="form.date"
                                :success="valid"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>
                          </template>

                          <v-date-picker
                            :color="set_color"
                            v-model="form.date"
                            @input="picker.date = false">
                          </v-date-picker>
                        </v-menu>
                      </div>

                      <div>
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Waktu Kunjungan
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-menu
                          ref="time"
                          v-model="picker.time"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="form.time"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider
                              name="Waktu Kunjungan"
                              rules="required"
                              v-slot="{ errors, valid }">
                              <v-text-field
                                v-on:keyup.enter="save"
                                readonly
                                filled
                                rounded
                                dense
                                :color="set_color"
                                placeholder="Contoh. 09:00"

                                :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form.time = ''"
                                v-on="on"
                                v-bind="attrs"

                                v-model="form.time"
                                :success="valid"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>
                          </template>

                          <v-time-picker
                            v-if="picker.time"
                            v-model="form.time"
                            full-width
                            :color="set_color"
                            format="24hr"
                            @click:minute="$refs.time.save(`${form.time}:00`)">
                          </v-time-picker>
                        </v-menu>
                      </div>

                      <ValidationProvider
                        name="Berat Badan"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Berat Badan
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 0.25"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"
                          suffix="Kg"

                          v-model="form.bb"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider v-if="detail_complete.customer_type === 'bayi' || detail_complete.customer_type === 'anak'"
                        name="Tinggi Badan"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Tinggi Badan
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 100"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"
                          suffix="Cm"

                          v-model="form.tb"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider v-if="detail_complete.customer_type === 'bayi' || detail_complete.customer_type === 'anak'"
                        name="Lingkar Lengan Atas"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Lingkar Lengan Atas
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 20"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"
                          suffix="Cm"

                          v-model="form.lila"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider v-if="detail_complete.customer_type === 'bayi' || detail_complete.customer_type === 'anak'"
                        name="Lingkar Kepala"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Lingkar Kepala
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 35"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"
                          suffix="Cm"

                          v-model="form.lk"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider v-if="detail_complete.customer_type !== 'bayi' && detail_complete.customer_type !== 'anak'"
                        name="Tensi"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Tensi
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"

                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 120/70"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"
                          suffix="mmHg"

                          v-model="form.tensi"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <!-- <ValidationProvider
                        name="Keluhan"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          Keluhan
                          <span>
                            (boleh dikosongi)
                          </span>
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Kaki kesemutan"
                          :append-icon="form.complaint !== '' && valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.complaint"
                          :success="form.complaint !== '' && valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider> -->

                      <!-- <ValidationProvider
                        name="Keterangan"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          Keterangan
                          <span>
                            (boleh dikosongi)
                          </span>
                        </div>

                        <v-textarea
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          rows="5"
                          placeholder="Contoh. Tuliskan keterangan disini . . ."
                          :append-icon="form.note !== '' && valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.note"
                          :success="form.note !== '' && valid"
                          :error-messages="errors"
                          required>
                        </v-textarea>
                      </ValidationProvider> -->

                      <!-- <ValidationProvider
                        name="Komplain untuk Terapis"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          Komplain untuk Terapis
                          <span>
                            (boleh dikosongi)
                          </span>
                        </div>

                        <v-textarea
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          rows="5"
                          placeholder="Contoh. Tuliskan komplain disini . . ."
                          :append-icon="form.admin_complaint !== '' && valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.admin_complaint"
                          :success="form.admin_complaint !== '' && valid"
                          :error-messages="errors"
                          required>
                        </v-textarea>
                      </ValidationProvider> -->

                      <!-- <ValidationProvider
                        name="Saran / Masukkan"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          Saran / Masukkan
                          <span>
                            (boleh dikosongi)
                          </span>
                        </div>

                        <v-textarea
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          rows="5"
                          placeholder="Contoh. Tuliskan saran / masukkan disini . . ."
                          :append-icon="form.admin_suggest !== '' && valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.admin_suggest"
                          :success="form.admin_suggest !== '' && valid"
                          :error-messages="errors"
                          required>
                        </v-textarea>
                      </ValidationProvider> -->
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center my-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/image/footer/visit.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data untuk mengubah kunjungan di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        v-on:keyup.enter="save"
                        @click="save"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.form = false; reset();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.attachment"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.attachment = false; fetchComplete();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.attachment = false; fetchComplete();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Lampiran {{ detail_complete.customer_name }}
                </span>
              </v-card-title>

              <v-card-text
                class="pb-0 pt-16 mt-5">
                <!-- <v-text-field
                  v-model="search_attachment"
                  @click:clear="search_attachment = ''; fetchAttachment();"
                  v-on:keyup.enter="fetchAttachment()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field> -->

                <v-btn
                  @click="sheet.form_attachment = true; reset_attachment();"
                  block
                  large
                  rounded
                  elevation="0"
                  class="body-1 font-weight-bold white--text text-capitalize btn_auth"
                  :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                  <div
                    class="btn_auth_in d-flex align-center justify-center"
                    :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                    <div>
                      <v-img
                        contain
                        width="20"
                        height="20"
                        alt="bidanvitacare"
                        :src="require('@/assets/icon/attachment.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>

                    <div
                      class="ml-2">
                      Tambah Lampiran
                    </div>
                  </div>
                </v-btn>
              </v-card-text>

              <v-card-text
                class="pb-0"
                style="height: calc(100vh - 185px); overflow: auto;">
                <v-row>
                  <v-col
                    v-for="(item, index) in process.load ? 6 : list_attachment"
                    :key="index"
                    cols="6">
                    <v-skeleton-loader
                      :loading="process.load"
                      type="image">
                      <template>
                        <div>
                          <v-card
                            v-if="!process.load && list_attachment.length > 0"
                            flat
                            color="transparent"
                            height="200">
                            <v-img
                              contain
                              width="100%"
                              height="100%"
                              alt="bidanvitacare"
                              :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                              class="ma-auto"
                              style="border: 5px solid #0000000d;
                                    border-radius: 10px;">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-card>
                        </div>
                      </template>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <Empty v-if="!process.load && pagination_attachment.total_page < 1"
                  class="py-6"
                  margin="my-16"
                  size="125"
                  message="Lampiran" />
              </v-card-text>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.form_attachment"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.form_attachment = false; reset_attachment();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.form_attachment = false; reset_attachment();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Tambah
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="d-flex align-center pt-16 mt-5">
                  <upload-folder
                    v-model="upload.folder_attachment"
                    style="width: 100%;">
                    <div
                      slot="activator"
                      class="cursor-pointer d-flex align-center justify-center"
                      style="border: 5px solid #0000000d;
                            border-radius: 10px;
                            min-width: 100%;
                            width: 100%;
                            height: 400px;">
                      <v-img v-if="!process.file && form_attachment.image_url !== ''"
                        contain
                        width="100%"
                        height="100%"
                        style="border-radius: 6px;"
                        alt="bidanvitacare"
                        :src="form_attachment.image_url !== '' ? form_attachment.image_url : require('@/assets/image/image_default.png')"
                        class="ma-auto d-flex align-center text-center">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>

                        <v-icon
                          size="50"
                          color="white">
                          mdi-cloud-upload-outline
                        </v-icon>
                      </v-img>

                      <v-icon v-if="!process.file && form_attachment.image_url === ''"
                        size="50"
                        color="grey">
                        mdi-cloud-upload-outline
                      </v-icon>

                      <v-progress-circular v-if="process.file"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </div>
                  </upload-folder>
                </v-card-text>

                <v-card-text>
                  <div v-if="message.error.length > 0"
                    class="text-center mt-3 mb-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/icon/attachment.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data Lampiran untuk rekam kunjungan di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        v-on:keyup.enter="save_attachment"
                        @click="save_attachment"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.form_attachment = false; reset_attachment();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.form_grafik"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.form_grafik = false; reset_grafik();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.form_grafik = false; reset_grafik();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Ubah
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-5">
                  <div v-if="detail_complete.graph === undefined">
                    <Empty
                      class="py-6"
                      margin="my-16"
                      size="125"
                      message="Grafik" />
                  </div>

                  <div v-else>
                    <div>
                      <div class="body-2 line-text-second">
                        {{ detail_complete.graph.bb.title }}
                      </div>

                      <div
                        id="bb"
                        class="my-4">
                        <apexchart
                          height="300"
                          :options="bar.bb.chartOptions"
                          :series="bar.bb.series">
                        </apexchart>
                      </div>

                      <div class="body-2 line-text-second mb-1">
                        <span class="font-weight-bold black--text">
                          Kesimpulan:
                        </span>

                        {{ detail_complete.graph.bb.kesimpulan !== '' ? detail_complete.graph.bb.kesimpulan : '-' }}
                      </div>

                      <div
                        class="d-flex align-center">
                        <div>
                          Upload file grafik berat badan
                        </div>

                        <v-spacer />

                        <!-- <div>
                          <v-btn
                            @click="to_upload('bb', detail_complete.graph)"
                            :loading="process.file_bb"
                            :disabled="process.file_bb"
                            x-small
                            rounded
                            elevation="0"
                            :color="form_grafik.graph_bb_image_url === '' ? set_color : 'green'"
                            class="text-capitalize white--text d-flex align-center">
                            <v-icon small color="white">mdi-cloud-upload-outline</v-icon>

                            <span
                              class="ml-1">
                              Unggah
                            </span>
                          </v-btn>
                        </div> -->
                      </div>
                    </div>

                    <v-divider
                      class="my-4"
                      style="border-top: 1px dashed #bbb;" />

                    <div>
                      <div class="body-2 line-text-second">
                        {{ detail_complete.graph.tb.title }}
                      </div>

                      <div
                        id="tb"
                        class="my-4">
                        <apexchart
                          height="300"
                          :options="bar.tb.chartOptions"
                          :series="bar.tb.series">
                        </apexchart>
                      </div>

                      <div class="body-2 line-text-second mb-1">
                        <span class="font-weight-bold black--text">
                          Kesimpulan:
                        </span>

                        {{ detail_complete.graph.tb.kesimpulan !== '' ? detail_complete.graph.tb.kesimpulan : '-' }}
                      </div>

                      <div
                        class="d-flex align-center">
                        <div>
                          Upload file grafik tinggi badan
                        </div>

                        <v-spacer />

                        <!-- <div>
                          <v-btn
                            @click="to_upload('tb', detail_complete.graph)"
                            :loading="process.file_tb"
                            :disabled="process.file_tb"
                            x-small
                            rounded
                            elevation="0"
                            :color="form_grafik.graph_tb_image_url === '' ? set_color : 'green'"
                            class="text-capitalize white--text d-flex align-center">
                            <v-icon small color="white">mdi-cloud-upload-outline</v-icon>

                            <span
                              class="ml-1">
                              Unggah
                            </span>
                          </v-btn>
                        </div> -->
                      </div>
                    </div>

                    <v-divider
                      class="my-4"
                      style="border-top: 1px dashed #bbb;" />

                    <div>
                      <div class="body-2 line-text-second">
                        {{ detail_complete.graph.lila.title }}
                      </div>

                      <div
                        id="lila"
                        class="my-4">
                        <apexchart
                          height="300"
                          :options="bar.lila.chartOptions"
                          :series="bar.lila.series">
                        </apexchart>
                      </div>

                      <div class="body-2 line-text-second mb-1">
                        <span class="font-weight-bold black--text">
                          Kesimpulan:
                        </span>

                        {{ detail_complete.graph.lila.kesimpulan !== '' ? detail_complete.graph.lila.kesimpulan : '-' }}
                      </div>

                      <div
                        class="d-flex align-center">
                        <div>
                          Upload file grafik lingkar lengan atas
                        </div>

                        <v-spacer />

                        <!-- <div>
                          <v-btn
                            @click="to_upload('lila', detail_complete.graph)"
                            :loading="process.file_lila"
                            :disabled="process.file_lila"
                            x-small
                            rounded
                            elevation="0"
                            :color="form_grafik.graph_lila_image_url === '' ? set_color : 'green'"
                            class="text-capitalize white--text d-flex align-center">
                            <v-icon small color="white">mdi-cloud-upload-outline</v-icon>

                            <span
                              class="ml-1">
                              Unggah
                            </span>
                          </v-btn>
                        </div> -->
                      </div>
                    </div>

                    <v-divider
                      class="my-4"
                      style="border-top: 1px dashed #bbb;" />

                    <div>
                      <div class="body-2 line-text-second">
                        {{ detail_complete.graph.lk.title }}
                      </div>

                      <div
                        id="lk"
                        class="my-4">
                        <apexchart
                          height="300"
                          :options="bar.lk.chartOptions"
                          :series="bar.lk.series">
                        </apexchart>
                      </div>

                      <div class="body-2 line-text-second mb-1">
                        <span class="font-weight-bold black--text">
                          Kesimpulan:
                        </span>

                        {{ detail_complete.graph.lk.kesimpulan !== '' ? detail_complete.graph.lk.kesimpulan : '-' }}
                      </div>

                      <div
                        class="d-flex align-center">
                        <div>
                          Upload file grafik lingkar kepala
                        </div>

                        <v-spacer />

                        <!-- <div>
                          <v-btn
                            @click="to_upload('lk', detail_complete.graph)"
                            :loading="process.file_lk"
                            :disabled="process.file_lk"
                            x-small
                            rounded
                            elevation="0"
                            :color="form_grafik.graph_lk_image_url === '' ? set_color : 'green'"
                            class="text-capitalize white--text d-flex align-center">
                            <v-icon small color="white">mdi-cloud-upload-outline</v-icon>

                            <span
                              class="ml-1">
                              Unggah
                            </span>
                          </v-btn>
                        </div> -->
                      </div>
                    </div>
                  </div>

                  <div v-if="message.error.length > 0"
                    class="text-center mt-3 mb-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/icon/grafik.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data Grafik untuk rekam kunjungan di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form || detail_complete.graph === undefined"
                        :loading="process.form"
                        v-on:keyup.enter="to_save_all"
                        @click="to_save_all"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.form_grafik = false; reset_grafik();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.error">
          <v-sheet
            color="#f9dee2"
            min-height="50px"
            style="border-radius: 15px 15px 0px 0px; overflow-y: auto;">
            <v-card
              flat
              color="transparent">
              <v-card-text>
                <ul
                  style="position: relative; left: -10px;">
                  <li
                    v-for="(item, index) in message.errors"
                    :key="index"
                    class="red--text"
                    :class="index < message.errors.length - 1 ? 'mb-2' : ''"
                    v-html="item">
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-sheet>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          partner_or_terapis: false,

          info_berhasil: false,
          info_gagal: false,

          info_warning: false,

          go_back: false,

          gambar_rumah: false,
          gambar_rumah_complete: false,

          filter: false,
          delete: false
        },
        sheet: {
          detail: false,

          detail_complete: false,

          error: false,

          form: false,

          attachment: false,
          form_attachment: false,

          form_grafik: false,

          kalender: false
        },

        search: '',
        search_complete: '',

        filter: {
          therapist_id: '',
          datetime_list: '',
          datetime_kalender: '',

          customer_name: '',
          client_name: '',
          mobile_phone: '',

          store_id: ''
        },

        autocomplete: {
          terapis: '',
          customer: '',
          klient: ''
        },

        selected: {
          type_user: 'Partner',
          partner_or_terapis: [],

          menu_utama: 'exist',
          menu: 'waiting'
        },

        menu_utama: [
          {
            text: 'Terapis',
            value: 'exist'
          },
          {
            text: 'Non Terapis',
            value: 'not_exist'
          },
          {
            text: 'Semua',
            value: 'all'
          }
        ],

        menu: [
          {
            text: 'Menunggu',
            value: 'waiting'
          },
          {
            text: 'Proses',
            value: 'process'
          },
          {
            text: 'Riwayat',
            value: 'complete'
          }
        ],

        detail: {},
        detail_complete: {},

        // list_all: [],
        // pagination_all: {},

        // list_non_terapis: [],
        // pagination_non_terapis: {},

        list_all_or_non_terapis: [],
        pagination_all_or_non_terapis: {},

        list_kalender: [],
        pagination_kalender: {},

        list: [],
        pagination: {},

        list_attachment: [],
        pagination_attachment: {},

        terapis: [],

        customer: [],
        klien: [],

        store: [],

        limit: 10,

        upload: {
          folder_attachment: null
        },

        picker: {
          date_list: '',
          date: false,
          time: false
        },

        form: {
          id: '',
          age: '',
          bb: '',
          tb: '',
          lila: '',
          lk: '',
          complaint: '',
          note: '',

          date: '',
          time: '',
          datetime: '',

          admin_complaint: '',
          admin_suggest: '',

          tensi: ''
        },
        form_attachment: {
          visit_id: '',
          image_url: ''
        },
        form_grafik: {
          id: '',

          graph_bb_title: '',
          graph_bb_image_url: '',
          graph_bb_kesimpulan: '',

          graph_tb_title: '',
          graph_tb_image_url: '',
          graph_tb_kesimpulan: '',

          graph_lila_title: '',
          graph_lila_image_url: '',
          graph_lila_kesimpulan: '',

          graph_lk_title: '',
          graph_lk_image_url: '',
          graph_lk_kesimpulan: ''
        },

        bar: {
          bb: {
            series: [],
            chartOptions: {
              chart: {
                animations: {
                  enabled: true,
                  easing: 'line',
                  dynamicAnimation: {
                    speed: 1000
                  }
                },
                toolbar: {
                  show: true
                },
                zoom: {
                  enabled: true
                },
                type: 'area'
              },
              stroke: {
                width: 3,
                curve: 'smooth'
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                show: false
              },
              colors: ['#000000'],
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return Math.round(value)
                  }
                }
              },
              xaxis: {
                categories: []
              }
            }
          },
          tb: {
            series: [],
            chartOptions: {
              chart: {
                animations: {
                  enabled: true,
                  easing: 'line',
                  dynamicAnimation: {
                    speed: 1000
                  }
                },
                toolbar: {
                  show: true
                },
                zoom: {
                  enabled: true
                },
                type: 'area'
              },
              stroke: {
                width: 3,
                curve: 'smooth'
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                show: false
              },
              colors: ['#000000'],
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return Math.round(value)
                  }
                }
              },
              xaxis: {
                categories: []
              }
            }
          },
          lila: {
            series: [],
            chartOptions: {
              chart: {
                animations: {
                  enabled: true,
                  easing: 'line',
                  dynamicAnimation: {
                    speed: 1000
                  }
                },
                toolbar: {
                  show: true
                },
                zoom: {
                  enabled: true
                },
                type: 'area'
              },
              stroke: {
                width: 3,
                curve: 'smooth'
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                show: false
              },
              colors: ['#000000'],
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return Math.round(value)
                  }
                }
              },
              xaxis: {
                categories: []
              }
            }
          },
          lk: {
            series: [],
            chartOptions: {
              chart: {
                animations: {
                  enabled: true,
                  easing: 'line',
                  dynamicAnimation: {
                    speed: 1000
                  }
                },
                toolbar: {
                  show: true
                },
                zoom: {
                  enabled: true
                },
                type: 'area'
              },
              stroke: {
                width: 3,
                curve: 'smooth'
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                show: false
              },
              colors: ['#000000'],
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return Math.round(value)
                  }
                }
              },
              xaxis: {
                categories: []
              }
            }
          }
        },

        process: {
          load: false,
          detail: false,
          form: false,
          delete: false,

          limit: false,

          wa: false,

          file: false,

          file_bb: false,
          file_tb: false,
          file_lila: false,
          file_lk: false,

          terapis: false,

          print: false,

          customer: false,
          klien: false
        },
        message: {
          success: '',
          error: '',
          errors: []
        },
        // SEO
        content: {
          url: '/jadwal',
          title: 'Jadwal',
          description: 'Jadwal Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'upload.folder_attachment': {
        handler: function () {
          if (this.upload.folder_attachment) {
            this.process.file = this.upload.folder_attachment.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form_attachment.image_url = this.upload.folder_attachment.fileuri
            }
          }
        },
        deep: true
      },

      'limit': function() {
        this.process.limit = true

        // if (this.selected.menu !== 'complete') {
        //   this.fetch('limit')
        // } else {
        //   this.fetchComplete('limit')
        // }

        console.log(this.sheet.kalender)

        if (!this.sheet.kalender) {
          if (this.selected.menu_utama === 'all') {
            this.fetchAll('limit')
          } else if (this.selected.menu_utama === 'not_exist') {
            this.fetchNonTerapis('limit')
          } else {
            this.fetchKalender('limit')
          }
        } else {
          if (this.selected.menu !== 'complete') {
            this.fetch('limit')
          } else {
            this.fetchComplete('limit')
          }
        }
      },

      'selected.menu_utama': function(val) {
        if (val === 'all') {
          this.fetchAll()
        } if (val === 'not_exist') {
          this.fetchNonTerapis()
        } else {
          this.fetchKalender()
        }
      },

      'selected.menu': function(val) {
        if (val !== 'complete') {
          this.fetch()
        } else {
          this.fetchComplete()
        }
      },

      'autocomplete.terapis': function() {
        this.fetchTerapis()
      },
      'autocomplete.customer': function() {
        this.fetchCustomer()
      },
      'autocomplete.klien': function() {
        this.fetchKlien()
      },

      'dialog.filter': function(val) {
        if (val) {
          this.fetchStore()
        }
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetchAll()

      this.selected.menu = this.$route.query.menu !== undefined ? this.$route.query.menu : 'waiting'

      // if (this.selected.menu !== 'complete') {
      //   this.fetch()
      // } else {
      //   this.fetchComplete()
      // }

      // this.fetchTerapis()

      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)

          t.dialog.go_back = true
        })
      },

      goTo (val) {
        this.selected.menu = val

        this.$router.push({ path: `/jadwal`, query: { menu: val }})
      },

      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit,
          // status: 'waiting'
          'status[eq]': this.selected.menu !== 'process' && this.selected.menu !== 'complete' ? this.selected.menu : '',
          'status[neq]': this.selected.menu === 'process' || this.selected.menu === 'complete' ? 'waiting' : '',

          datetime: this.filter.datetime_kalender,
          therapist_id: this.filter.therapist_id,

          customer_name: this.filter.customer_name,
          client_name: this.filter.client_name,
          mobile_phone: this.filter.mobile_phone,

          store_id: this.filter.store_id
        }

        await this.$axios.$get(`${process.env.API}admin/schedule`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchComplete (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search_complete,
          limit: this.limit,

          datetime: this.filter.datetime_kalender,
          therapist_id: this.filter.therapist_id
        }

        await this.$axios.$get(`${process.env.API}admin/customer-visit/data-transaction`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },

      async fetchDetail (id) {
        this.process.detail = true

        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/schedule/detail`, { params })
        .then((response) => {
          this.process.detail = false

          if (response.status === 200) {
            this.detail = response.results.data
          }
        })
      },
      async fetchDetailComplete (id) {
        this.process.detail = true

        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/customer-visit/detail`, { params })
        .then((response) => {
          this.process.detail = false

          if (response.status === 200) {
            this.detail_complete = response.results.data

            if (this.detail_complete.graph !== undefined) {
              let grafik_bb = {
                value: [],
                sd0: [],
                month: []
              }

              this.detail_complete.graph.bb.data.map(obj => {
                grafik_bb.value.push(obj.value !== null ? obj.value : 0)
                grafik_bb.sd0.push(obj.sd0)

                grafik_bb.month.push(obj.month)
              })

              let grafik_tb = {
                value: [],
                sd0: [],
                month: []
              }

              this.detail_complete.graph.tb.data.map(obj => {
                grafik_tb.value.push(obj.value !== null ? obj.value : 0)
                grafik_tb.sd0.push(obj.sd0)

                grafik_tb.month.push(obj.month)
              })

              let grafik_lila = {
                value: [],
                sd0: [],
                month: []
              }

              this.detail_complete.graph.tb.data.map(obj => {
                grafik_lila.value.push(obj.value !== null ? obj.value : 0)
                grafik_lila.sd0.push(obj.sd0)

                grafik_lila.month.push(obj.month)
              })

              let grafik_lk = {
                value: [],
                sd0: [],
                month: []
              }

              this.detail_complete.graph.tb.data.map(obj => {
                grafik_lk.value.push(obj.value !== null ? obj.value : 0)
                grafik_lk.sd0.push(obj.sd0)

                grafik_lk.month.push(obj.month)
              })

              this.bar = {
                bb: {
                  series:[
                    {
                      name: 'Berat Badan',
                      data: grafik_bb.value
                    },
                    {
                      name: 'Batas Normal',
                      data: grafik_bb.sd0
                    }
                  ],
                  chartOptions: {
                    chart: {
                      animations: {
                        enabled: true,
                        easing: 'line',
                        dynamicAnimation: {
                          speed: 1000
                        }
                      },
                      toolbar: {
                        show: true
                      },
                      zoom: {
                        enabled: true
                      }
                    },
                    stroke: {
                      width: 1,
                      curve: 'smooth'
                    },
                    dataLabels: {
                      enabled: false
                    },
                    legend: {
                      show: true
                    },
                    colors: ['#00BCD4', '#F44336'],
                    yaxis: {
                      labels: {
                        formatter: function (value) {
                          return Math.round(value)
                        }
                      }
                    },
                    xaxis: {
                      categories: grafik_bb.month,
                      axisTicks: {
                        autoskip: true,
                        maxTicksLimit: 4,
                        interval: 3
                      }
                    }
                  }
                },
                tb: {
                  series:[
                    {
                      name: 'Tinggi Badan',
                      data: grafik_tb.value
                    },
                    {
                      name: 'Batas Normal',
                      data: grafik_tb.sd0
                    }
                  ],
                  chartOptions: {
                    chart: {
                      animations: {
                        enabled: true,
                        easing: 'line',
                        dynamicAnimation: {
                          speed: 1000
                        }
                      },
                      toolbar: {
                        show: true
                      },
                      zoom: {
                        enabled: true
                      }
                    },
                    stroke: {
                      width: 1,
                      curve: 'smooth'
                    },
                    dataLabels: {
                      enabled: false
                    },
                    legend: {
                      show: true
                    },
                    colors: ['#00BCD4', '#F44336'],
                    yaxis: {
                      labels: {
                        formatter: function (value) {
                          return Math.round(value)
                        }
                      }
                    },
                    xaxis: {
                      categories: grafik_tb.month,
                      axisTicks: {
                        autoskip: true,
                        maxTicksLimit: 4,
                        interval: 3
                      }
                    }
                  }
                },
                lila: {
                  series:[
                    {
                      name: 'Lingkar Lengan Atas',
                      data: grafik_lila.value
                    },
                    {
                      name: 'Batas Normal',
                      data: grafik_lila.sd0
                    }
                  ],
                  chartOptions: {
                    chart: {
                      animations: {
                        enabled: true,
                        easing: 'line',
                        dynamicAnimation: {
                          speed: 1000
                        }
                      },
                      toolbar: {
                        show: true
                      },
                      zoom: {
                        enabled: true
                      }
                    },
                    stroke: {
                      width: 1,
                      curve: 'smooth'
                    },
                    dataLabels: {
                      enabled: false
                    },
                    legend: {
                      show: true
                    },
                    colors: ['#00BCD4', '#F44336'],
                    yaxis: {
                      labels: {
                        formatter: function (value) {
                          return Math.round(value)
                        }
                      }
                    },
                    xaxis: {
                      categories: grafik_lila.month,
                      axisTicks: {
                        autoskip: true,
                        maxTicksLimit: 4,
                        interval: 3
                      }
                    }
                  }
                },
                lk: {
                  series:[
                    {
                      name: 'Lingkar Kepala',
                      data: grafik_lk.value
                    },
                    {
                      name: 'Batas Normal',
                      data: grafik_lk.sd0
                    }
                  ],
                  chartOptions: {
                    chart: {
                      animations: {
                        enabled: true,
                        easing: 'line',
                        dynamicAnimation: {
                          speed: 1000
                        }
                      },
                      toolbar: {
                        show: true
                      },
                      zoom: {
                        enabled: true
                      }
                    },
                    stroke: {
                      width: 1,
                      curve: 'smooth'
                    },
                    dataLabels: {
                      enabled: false
                    },
                    legend: {
                      show: true
                    },
                    colors: ['#00BCD4', '#F44336'],
                    yaxis: {
                      labels: {
                        formatter: function (value) {
                          return Math.round(value)
                        }
                      }
                    },
                    xaxis: {
                      categories: grafik_lk.month,
                      axisTicks: {
                        autoskip: true,
                        maxTicksLimit: 4,
                        interval: 3
                      }
                    }
                  }
                }
              }
            }
          }
        })
      },

      async save_process () {
        this.message.error = ''

        this.process.form = true

        let form = {
          id: this.detail.id,
          type: this.detail.type,
          customer_id: this.detail.customer_id,
          customer_address_id: this.detail.customer_address_id,
          datetime: this.detail.datetime,
          note: this.detail.note,
          dp: this.detail.dp,
          items: this.detail.items,

          date: this.detail.datetime.split(' ')[0],
          time: this.detail.datetime.split(' ')[1],

          amount_paid_sum: 0,

          wali: this.detail.wali,
          mobilephone_wali: this.detail.mobilephone_wali,
          mobilephone_other: this.detail.mobilephone_other,

          is_process: 'true'
        }

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/schedule/update`, form)
        .then((response) => {
          this.process.form = false

          if (response.status === 200) {

            this.sheet.detail = false

            this.dialog.info_warning = false

            this.selected.menu = 'process'

            this.fetch()
          } else {
            this.message.error = response.message
          }
        })
      },

      to_save_complete () {
        this.message.errors = []

        if (this.detail.items.length > 0) {
          this.detail.items.map(obj => {
            if (obj.type === 'service' && obj.visit.length < 1) {
              this.message.errors.push(`<strong>Terapis</strong> untuk item <strong>${obj.name}</strong> belum di tentukan`)
            }
          })
        }


        let time = this.detail.datetime.split(' ')[1] !== '00:00:00' ? this.detail.datetime.split(' ')[1] : ''

        if (time === '') {
          this.message.errors.push('<strong>Waktu</strong> belum di tentukan')
        }

        if (this.message.errors.length > 0) {
          this.sheet.error = true
        } else {
          this.dialog.info_warning = true
        }
      },
      async save_complete () {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/schedule/complete`, {
          id: this.detail.id
        })
        .then((response) => {
          this.process.form = false

          if (response.status === 200) {

            this.sheet.detail = false

            this.dialog.info_warning = false

            // this.selected.menu = 'complete'

            this.fetch()
          } else {
            this.message.error = response.message
          }
        })
      },

      async deletes () {
        this.message.error = ''

        this.process.delete = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/schedule/delete`, {
          id: this.detail.id
        })
        .then((response) => {

          if (response.status === 200) {
            this.process.delete = false

            this.dialog.delete = false

            this.sheet.detail = false

            this.fetch()
          } else {
            this.process.delete = false
            this.message.error = response.message
          }
        })
      },

      async to_wa () {
        if (this.detail.customer_mobilephone === '') {
          this.dialog.info_gagal = true
        } else {
          this.message.error = ''

          this.process.wa = true

          console.log('masuk')

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}admin/schedule/send-summary-customer`, {
            id: this.detail.id
          })
          .then((response) => {

            if (response.status === 200) {
              this.process.wa = false

              this.dialog.info_berhasil = true

              this.fetchDetail(this.detail.id)
            } else {
              this.process.wa = false
              this.message.error = response.message
            }
          })
        }
      },

      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          id: item !== undefined ? item.id : '',
          age: item !== undefined ? item.age : '',
          bb: item !== undefined ? item.bb : '',
          tb: item !== undefined ? item.tb : '',
          lila: item !== undefined ? item.lila : '',
          lk: item !== undefined ? item.lk : '',
          complaint: item !== undefined ? item.complaint : '',
          note: item !== undefined ? item.note : '',

          date: item !== undefined ? item.datetime.split(' ')[0] : '',
          time: item !== undefined ? item.datetime.split(' ')[1] : '',

          datetime: item !== undefined ? item.datetime : '',

          admin_complaint: item !== undefined ? item.admin_complaint : '',
          admin_suggest: item !== undefined ? item.admin_suggest : '',

          tensi: item !== undefined ? item.tensi : ''
        }

        this.process.form = false

        this.message.error = ''
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          this.form.datetime = `${this.form.date} ${this.form.time}`

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}admin/customer-visit/update`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form = false

              this.fetchDetailComplete(this.detail_complete.id)
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      async fetchAttachment () {
        this.process.load = true

        let params = {
          visit_id: this.detail_complete.id,
          search: this.search_attachment,
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/customer-visit-attachment`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.list_attachment = response.results.data
            this.pagination_attachment = response.results.pagination
          }
        })
      },
      reset_attachment (item) {
        if (this.$refs.form_attachment) this.$refs.form_attachment.reset()

        this.form_attachment = {
          visit_id: this.detail_complete.id,
          image_url: item !== undefined ? item.image_url : ''
        }

        this.process.form = false

        this.message.error = ''
      },
      async save_attachment () {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/customer-visit-attachment/add`, this.form_attachment)
        .then((response) => {

          if (response.status === 200) {
            this.process.form = false

            this.sheet.form_attachment = false

            this.fetchAttachment()
          } else {
            this.process.form = false
            this.message.error = response.message
          }
        })
      },

      reset_grafik () {
        this.form_grafik = {
          id: this.detail_complete.id,

          graph_bb_title: '',
          graph_bb_image_url: '',
          graph_bb_kesimpulan: '',

          graph_tb_title: '',
          graph_tb_image_url: '',
          graph_tb_kesimpulan: '',

          graph_lila_title: '',
          graph_lila_image_url: '',
          graph_lila_kesimpulan: '',

          graph_lk_title: '',
          graph_lk_image_url: '',
          graph_lk_kesimpulan: ''
        }

        this.process.form = false

        this.message.error = ''
      },
      to_upload (type, item) {
        if (type === 'bb') {
          this.uplaod_bb(item)
        } else if (type === 'tb') {
          this.uplaod_tb(item)
        } else if ( type === 'lila') {
          this.uplaod_lila(item)
        } else {
          this.uplaod_lk(item)
        }
      },
      to_save_all () {
        this.message.error = ''

        this.process.form = true

        this.uplaod_bb(this.detail_complete.graph)
      },
      async uplaod_bb (item) {
        let self = this,
            data = document.getElementById('bb')

        self.process.file_bb = true

        await this.$html2canvas(data, {
          logging: true,
          letterRendering: true,
          allowTaint: false,
          useCORS: true,

          scale: 1920*2/window.innerWidth,

          scrollX: 0,
          scrollY: 0,
        })
        .then(function(canvas) {
          document.body.appendChild(canvas)

          let img = canvas.toDataURL('image/png', 1.0)

          fetch(img)
          .then(res => res.blob())
          .then(blob => {
            let formData = new FormData(),
                file = new File([blob], 'bidanvitacare.jpeg')
                formData.append('key', 'image')
                formData.append('image', file)


            fetch(`${process.env.API_CDN}image.php`, {
              method: 'POST',
              body: formData
            })
            .then(res => res.json())
            .then(response => {
              self.form_grafik.graph_bb_title = item.bb.title
              self.form_grafik.graph_bb_image_url = response.data.fileuri
              self.form_grafik.graph_bb_kesimpulan = item.bb.kesimpulan

              self.process.file_bb = false

              self.uplaod_tb(self.detail_complete.graph)
            })
          })
        })
      },
      async uplaod_tb (item) {
        let self = this,
            data = document.getElementById('tb')

        self.process.file_tb = true

        await this.$html2canvas(data, {
          logging: true,
          letterRendering: true,
          allowTaint: false,
          useCORS: true,

          scale: 1920*2/window.innerWidth,

          scrollX: 0,
          scrollY: 0,
        })
        .then(function(canvas) {
          document.body.appendChild(canvas)

          let img = canvas.toDataURL('image/png', 1.0)

          fetch(img)
          .then(res => res.blob())
          .then(blob => {
            let formData = new FormData(),
                file = new File([blob], 'bidanvitacare.jpeg')
                formData.append('key', 'image')
                formData.append('image', file)


            fetch(`${process.env.API_CDN}image.php`, {
              method: 'POST',
              body: formData
            })
            .then(res => res.json())
            .then(response => {
              self.form_grafik.graph_tb_title = item.tb.title
              self.form_grafik.graph_tb_image_url = response.data.fileuri
              self.form_grafik.graph_tb_kesimpulan = item.tb.kesimpulan

              self.process.file_tb = false

              self.uplaod_lila(self.detail_complete.graph)
            })
          })
        })
      },
      async uplaod_lila (item) {
        let self = this,
            data = document.getElementById('lila')

        self.process.file_lila = true

        await this.$html2canvas(data, {
          logging: true,
          letterRendering: true,
          allowTaint: false,
          useCORS: true,

          scale: 1920*2/window.innerWidth,

          scrollX: 0,
          scrollY: 0,
        })
        .then(function(canvas) {
          document.body.appendChild(canvas)

          let img = canvas.toDataURL('image/png', 1.0)

          fetch(img)
          .then(res => res.blob())
          .then(blob => {
            let formData = new FormData(),
                file = new File([blob], 'bidanvitacare.jpeg')
                formData.append('key', 'image')
                formData.append('image', file)


            fetch(`${process.env.API_CDN}image.php`, {
              method: 'POST',
              body: formData
            })
            .then(res => res.json())
            .then(response => {
              self.form_grafik.graph_lila_title = item.lila.title
              self.form_grafik.graph_lila_image_url = response.data.fileuri
              self.form_grafik.graph_lila_kesimpulan = item.lila.kesimpulan

              self.process.file_lila = false

              self.uplaod_lk(self.detail_complete.graph)
            })
          })
        })
      },
      async uplaod_lk (item) {
        let self = this,
            data = document.getElementById('lk')

        self.process.file_lk = true

        await this.$html2canvas(data, {
          logging: true,
          letterRendering: true,
          allowTaint: false,
          useCORS: true,

          scale: 1920*2/window.innerWidth,

          scrollX: 0,
          scrollY: 0,
        })
        .then(function(canvas) {
          document.body.appendChild(canvas)

          let img = canvas.toDataURL('image/png', 1.0)

          fetch(img)
          .then(res => res.blob())
          .then(blob => {
            let formData = new FormData(),
                file = new File([blob], 'bidanvitacare.jpeg')
                formData.append('key', 'image')
                formData.append('image', file)


            fetch(`${process.env.API_CDN}image.php`, {
              method: 'POST',
              body: formData
            })
            .then(res => res.json())
            .then(response => {
              self.form_grafik.graph_lk_title = item.lk.title
              self.form_grafik.graph_lk_image_url = response.data.fileuri
              self.form_grafik.graph_lk_kesimpulan = item.lk.kesimpulan

              self.process.file_lk = false

              self.save_grafik()
            })
          })
        })
      },
      async save_grafik () {
        // this.message.error = ''

        // this.process.form = true

        if (this.form_grafik.graph_bb_image_url !== '' && this.form_grafik.graph_tb_image_url !== '' && this.form_grafik.graph_lila_image_url !== '' && this.form_grafik.graph_lk_image_url !== '') {
          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}admin/customer-visit/update-graph-image`, this.form_grafik)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form_grafik = false

              this.fetchDetailComplete(this.detail_complete.id)

              // clearInterval(refresh)
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
        }
      },

      // KALENDER BY TERAPIS
      async fetchTerapis () {
        this.process.terapis = true

        this.process.load = true

        let params = {
          search: this.autocomplete.terapis,
          'role[eq]': 'therapist',
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/user`, { params })
        .then((response) => {
          this.process.terapis = false

          if (response.status === 200) {
            this.terapis = response.results.data

            this.fetchKalender()
          }
        })
      },

      async fetchAll (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit,

          datetime: this.filter.datetime_list,

          customer_name: this.filter.customer_name,
          client_name: this.filter.client_name,
          mobile_phone: this.filter.mobile_phone,

          store_id: this.filter.store_id
        }

        await this.$axios.$get(`${process.env.API}admin/schedule`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list_all_or_non_terapis = response.results.data
            this.pagination_all_or_non_terapis = response.results.pagination
          }
        })
      },

      async fetchNonTerapis (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit,

          'status[eq]': 'waiting',
          datetime: this.filter.datetime_list,

          customer_name: this.filter.customer_name,
          client_name: this.filter.client_name,
          mobile_phone: this.filter.mobile_phone,

          store_id: this.filter.store_id,

          is_non_therapist: true
        }

        await this.$axios.$get(`${process.env.API}admin/schedule`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list_all_or_non_terapis = response.results.data
            this.pagination_all_or_non_terapis = response.results.pagination
          }
        })
      },

      async fetchKalender (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          // limit: this.limit,
          limit: 10000,

          therapist_id: this.filter.therapist_id,
          date: this.filter.datetime_list
        }

        await this.$axios.$get(`${process.env.API}admin/schedule/data-date`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list_kalender = response.results.data
            this.pagination_kalender = response.results.pagination
          }
        })
      },

      async print_nota () {
        // this.message.error = ''

        // this.process.print = true

        // let params = {
        //   id: this.detail_complete.id
        // }

        // // SAVE TO REST API
        // await this.$axios.$get(`${process.env.API}admin/order/print-ulang`, { params })
        // .then((response) => {

        //   if (response.status === 200) {
        //     this.process.print = false

        //     // CONTENT SUCCESS
        //   } else {
        //     this.process.print = false
        //     this.message.error = response.message
        //   }
        // })

        // window.open(`savoirprint://${process.env.API}public/order/print-detail?uuid=${this.detail_complete.uuid}`)

        location.href = `savoirprint://${process.env.API}public/order/print-detail?uuid=${this.detail_complete.uuid}`
      },

      async fetchCustomer () {
        this.process.customer = true

        let params = {
          search: this.autocomplete.customer,
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/customer`, { params })
        .then((response) => {
          this.process.customer = false

          if (response.status === 200) {
            this.customer = response.results.data
          }
        })
      },
      async fetchKlien () {
        this.process.klien = true

        let params = {
          search: this.autocomplete.klien,
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/customer`, { params })
        .then((response) => {
          this.process.klien = false

          if (response.status === 200) {
            this.klien = response.results.data
          }
        })
      },

      async fetchStore () {
        let params = {
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/store`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.store = response.results.data
          }
        })
      }
    }
  }
</script>
