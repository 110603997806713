<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.success"
          persistent>
          <v-card 
            class="border-radius pa-2">
            <v-card-title 
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar 
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span 
                class="body-1 font-weight-bold ml-2">
                Ubah Profil Berhasil
              </span>
            </v-card-title>
            
            <v-divider 
              class="mx-4 mt-3 mb-2"/>

            <v-card-text 
              class="body-2 text-first line-text-second mt-3">
              Terima kasih, Anda telah melengkapi data pribadi di Bidanvitacare.id
            </v-card-text>

            <v-divider 
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                href="/profil"
                @click="dialog.success = false"
                rounded
                elevation="0"
                class="body-2 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in d-flex align-center">
                  <v-icon 
                    size="16"
                    class="mr-1">
                    mdi-close-thick
                  </v-icon>

                  Tutup
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card 
            flat
            height="calc(100vh - 260px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text
              class="pb-2 d-flex align-center">
              <v-avatar 
                tile
                size="150">
                <v-img 
                  width="100%"
                  height="100%"
                  style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
                        border: 5px solid #0000000d;
                        border-radius: 10px;"
                  alt="bidanvitacare" 
                  :src="form.image_url !== '' ? form.image_url : require('@/assets/image/member_default.jpg')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <div>
                <v-btn
                  @click="sheet.option = true"
                  :disabled="process.file"
                  :loading="process.file"
                  elevation="0"
                  rounded
                  block
                  large
                  color="#0000000d"
                  class="body-1 font-weight-bold text-capitalize d-flex align-center"
                  style="border-radius: 0px 50px 50px 0px;"
                  :style="`color: ${set_color};`">
                  <v-icon
                    size="24"
                    :color="set_color">
                    mdi-camera
                  </v-icon>

                  <span
                    class="ml-1">
                    Ubah Foto
                  </span>
                </v-btn>
              </div>
            </v-card-text>

            <v-card-text>
              <ValidationObserver 
                ref="form">
                <v-form>
                  <ValidationProvider 
                    name="Nama Lengkap" 
                    rules="required" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Nama Lengkap
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. John Doe"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.name"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="Email" 
                    rules="required|email" 
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Email
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. johndoe@gmail.com"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.email"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="No. Handphone" 
                    rules="required|min:8|max:13"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      No. Handphone
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                      min="0"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. 081332XXXXXX"
                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                      v-model="form.mobilephone"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="Shift" 
                    rules="required"
                    v-slot="{ errors, valid }">

                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Shift
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-select
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. Shift 1 (Pagi)"
                      
                      :items="shift"
                      item-text="name"
                      item-value="id"
                      :item-color="set_color"

                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                      v-model="form.shift_id"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-select>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="Cabang" 
                    rules="required"
                    v-slot="{ errors, valid }">

                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Cabang
                      <!-- <span class="error--text">
                        (wajib diisi)
                      </span> -->
                    </div>

                    <v-select
                      v-on:keyup.enter="save"
                      filled
                      rounded
                      dense
                      :color="set_color"
                      placeholder="Contoh. Pusat Gito Gati"
                      
                      :items="store"
                      item-text="name"
                      item-value="id"
                      :item-color="set_color"

                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                      v-model="form.store_id"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-select>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>

              <div v-if="message.error.length > 0"
                class="text-center mt-3 mb-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
                  
                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card v-if="!sheet.option"
            flat
            color="#FFFFFF"
            min-height="150"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row 
                align="center">
                <v-col 
                  cols="3" 
                  class="pb-0">
                  <v-img 
                    contain
                    alt="bidanvitacare" 
                    :src="require('@/assets/image/profile/profile.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col 
                  cols="9" 
                  class="black--text pb-0">
                  <div>
                    <div 
                      class="body-2 line-text-second black--text" 
                      style="opacity: 60%;">
                      Ubah data diri Anda untuk informasi akun di layanan kami, kemudian klik <b class="font-italic">Simpan</b>.
                    </div>
                  </div>
                </v-col>
              </v-row>
              
              <v-row 
                class="mt-4">
                <v-col 
                  cols="6" 
                  class="pb-0">
                  <v-btn
                    :disabled="process.form"
                    :loading="process.form"
                    v-on:keyup.enter="save"
                    @click="save"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_save">
                    <div
                      class="btn_save_in">
                      <v-icon 
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-check-bold
                      </v-icon>

                      Simpan
                    </div>
                  </v-btn>
                </v-col>

                <v-col 
                  cols="6" 
                  class="pb-0">
                  <v-btn
                    @click="$emit('close', false)"
                    :disabled="process.form"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                    <div
                      class="btn_cancel_in">
                      <v-icon 
                        size="20"
                        style="position: absolute; left: 8px; top: 0; bottom: 0;">
                        mdi-close-thick
                      </v-icon>

                      Batal
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.option"
          hide-overlay>
          <v-card
            flat
            color="#ffffff"
            class="d-flex align-center"
            style="border-radius: 30px 30px 0px 0px;">
            <v-card-text
              class="pa-3">
              <v-row 
                align="center" 
                justify="center">
                <!-- CAMERA -->
                <v-col cols="4" class="text-center">
                  <div @click="sheet.upload = true; selected.type = 'camera';"
                    class="cursor-pointer">
                    <v-avatar 
                      size="50" 
                      class="cursor-pointer" 
                      v-ripple
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                      <v-img 
                        alt="bidanvitacare" 
                        :src="require('@/assets/image/camera.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <div 
                      class="font-weight-bold black--text mt-2">
                      Kamera
                    </div>
                  </div>
                </v-col>

                <v-col 
                  cols="4" 
                  class="text-center px-0">
                  <div 
                    class="d-flex align-center">
                    <v-divider 
                      style="border: 2px solid #eaeaea; border-radius: 50px;" />

                    <div 
                      class="mx-2 font-weight-bold"
                      style="opacity: 80%;">
                      Atau
                    </div>

                    <v-divider 
                      style="border: 2px solid #eaeaea; border-radius: 50px;" />
                  </div>
                </v-col>

                <!-- FOLDER -->
                <v-col 
                  cols="4" 
                  class="text-center">
                  <upload-folder 
                    v-model="upload.folder">
                    <div slot="activator">
                      <v-avatar 
                        size="50" 
                        class="cursor-pointer" 
                        v-ripple
                        style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                        <v-img 
                          alt="bidanvitacare" 
                          :src="require('@/assets/image/folder.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>

                      <div 
                        class="font-weight-bold black--text mt-2">
                        Galeri
                      </div>
                    </div>
                  </upload-folder>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.upload"
          hide-overlay
          persistent>
          <upload-camera v-if="selected.type === 'camera'"
            :sheet="sheet.upload"
            @frame="sheet.upload = $event"
            @input="addCamera($event)"
            @close="sheet.upload = $event; selected.type = '';" />
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        dialog: {
          success: false
        },
        selected: {
          type: ''
        },
        sheet: {
          option: false,
          upload: false
        },

        shift: [],
        store: [],
        
        upload: {
          camera: null,
          folder: null
        },
        form: {
          name: '',
          email: '',
          mobilephone: '',
          image_url: '',

          shift_id: '',
          store_id: ''
        },
        process: {
          form: false,
          file: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      UploadCamera: () => import(/* webpackPrefetch: true */ '@/components/upload/camera.vue'),
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE
              this.sheet.option = false

              this.form.image_url = this.upload.folder.fileuri
            }
          }
        },
        deep: true
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      this.reset()
    },
    methods: {
      addCamera (e) {
        this.process.file = e.process

        if (!e.process) {
          this.form.image_url = e.fileuri

          this.sheet.option = false
        } 
      },
      reset () {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = this.detail

        this.process.form = false

        this.message.error = ''

        this.fetchShift()
        this.fetchStore()
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}admin/profile/update`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false
              
              this.$emit('close', false)

              Cookie.set('user', JSON.stringify(response.results.data))

              this.dialog.success = true
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      async fetchShift () {
        let params = {
          
        }

        await this.$axios.$get(`${process.env.API}admin/shift`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.shift = response.results.data
          }
        })
      },

      async fetchStore () {
        let params = {
          
        }

        await this.$axios.$get(`${process.env.API}admin/store`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.store = response.results.data
          }
        })
      }
    }
  }
</script>