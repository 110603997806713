<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section :style="`padding-bottom: ${margin_bottom}px`">
          <v-card
            flat
            class="transparent"
            style="font-family: Roboto Condensed, sans-serif;">
            <div
              id="nota">
              <v-card v-if="Object.keys(detail).length > 0"
                flat
                color="transparent">
                <v-card-text
                  class="text-center pt-0">
                  <v-img
                    contain
                    width="200"
                    height="auto"
                    class="ma-auto"
                    alt="bidanvitacare"
                    :src="detail.store.logo">
                    <!-- <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template> -->
                  </v-img>

                  <div>
                    <div
                      class="mb-7">
                      <div class="black--text"
                        style="font-size: 16px">
                        Mom, Baby & Kids Spa Sejak 2017
                      </div>

                      <div
                        class="mt-1 black--text"
                        style="font-size: 16px">
                        Sahabat Ceria Bunda & Buah Hati
                      </div>
                    </div>

                    <div
                      class="mb-4">
                      <div
                        class="font-weight-bold black--text mb-2"
                        style="font-size: 16px">
                        {{ detail.store.name }}
                      </div>

                      <div class="black--text"
                        style="font-size: 16px">
                        {{ detail.store.address }}
                      </div>
                    </div>

                    <div class="black--text"
                      style="font-size: 16px">
                      Buka Setiap Hari {{ detail.store.open_hours }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card v-if="Object.keys(detail).length > 0"
                flat
                color="transparent">
                <v-card-text
                  class="pt-0 mb-2">
                  <div
                    class="text-center mb-6">
                    <div
                      class="font-weight-bold black--text"
                      style="font-size: 16px">
                      Transaksi {{ detail.code }}
                    </div>

                    <div class="black--text"
                      style="font-size: 16px">
                      {{ detail.create_datetime | date }}
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 2px dashed #e0e0e0;" />

                  <div>
                    <div
                      class="d-flex align-center mb-4">
                      <div
                        class="font-weight-bold black--text"
                        style="font-size: 16px">
                        {{ detail.customer_name }}
                      </div>

                      <v-spacer />

                      <div class="black--text"
                        style="font-size: 16px">
                        Kunjungan ke <strong class="black--text">{{ detail.so_count }}</strong>
                      </div>
                    </div>

                    <div
                      v-for="(item, index) in detail.items"
                      :key="index"
                      :class="index < detail.items.length - 1 ? 'mb-4' : ''"
                      class="px-3"
                      style="border: 2px dashed #e0e0e0; border-radius: 10px;">
                      <div
                        class="mt-2 black--text"
                        style="font-size: 16px">
                        {{ item.name }}
                      </div>

                      <v-row>
                        <v-col
                          cols="8"
                          class="pb-0 black--text"
                          style="font-size: 16px">
                          Harga
                        </v-col>

                        <v-col
                          cols="4"
                          class="text-right pb-0 black--text"
                          style="font-size: 16px">
                          {{ item.price | price }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="8"
                          class="py-0 black--text"
                          style="font-size: 16px">
                          Diskon <span v-if="item.disc_percent > 0">({{ item.disc_percent }}%)</span>
                        </v-col>

                        <v-col
                          cols="4"
                          class="text-right py-0 black--text"
                          style="font-size: 16px">
                          {{ item.disc_value | price }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="8"
                          class="py-0 black--text"
                          style="font-size: 16px">
                          Sub Total
                        </v-col>

                        <v-col
                          cols="4"
                          class="text-right py-0 black--text"
                          style="font-size: 16px">
                          {{ item.nett_price | price }}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="8"
                          class="py-0 black--text"
                          style="font-size: 16px">
                          Qty
                        </v-col>

                        <v-col
                          cols="4"
                          class="text-right py-0 black--text"
                          style="font-size: 16px">
                          {{ item.qty }}x
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="8"
                          class="font-weight-bold pt-1 black--text"
                          style="font-size: 16px">
                          Total
                        </v-col>

                        <v-col
                          cols="4"
                          class="text-right font-weight-bold pt-1 black--text"
                          style="font-size: 16px">
                          {{ item.subtotal | price }}
                        </v-col>
                      </v-row>

                      <v-divider v-if="item.customer.length > 0"
                        style="border-top: 2px dashed #e0e0e0;" />

                      <div v-if="item.customer.length > 0"
                        class="black--text font-weight-bold mt-4 mb-2"
                        style="font-size: 16px">
                        Customer
                      </div>

                      <div>
                        <div
                          v-for="(item_customer, index_customer) in item.customer"
                          :key="index_customer"
                          class="mb-2">
                          <div
                            class="d-flex align-center">
                            <v-avatar
                              size="24"
                              class="mt-0"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item_customer.image_url !== '' ? item_customer.image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto"
                                style="filter: grayscale(1) invert(0.1) !important;">
                                <!-- <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template> -->
                              </v-img>
                            </v-avatar>

                            <span
                              class="font-weight-bold ml-1 black--text"
                              style="font-size: 16px">
                              {{ item_customer.name }}
                            </span>
                          </div>
                        </div>
                      </div>


                      <v-divider v-if="item.visit.length > 0"
                        class="my-4"
                        style="border-top: 2px dashed #e0e0e0;" />

                      <div v-if="item.visit.length > 0"
                        class="black--text font-weight-bold mt-4 mb-2"
                        style="font-size: 16px">
                        Terapis
                      </div>

                      <div
                        v-for="(item_visit, index_visit) in item.visit"
                        :key="index_visit"
                        :class="index_visit < item.visit.length - 1 ? 'mb-2' : 'mb-3'">
                        <div
                          class="d-flex align-center">
                          <v-avatar
                            size="24"
                            class="mt-0"
                            style="border: 2px solid #ffffff;">
                            <v-img
                              width="100%"
                              height="100%"
                              alt="bidanvitacare"
                              :src="item_visit.user_image_url !== '' ? item_visit.user_image_url : require('@/assets/image/member_default.jpg')"
                              class="ma-auto"
                              style="filter: grayscale(1) invert(0.1) !important;">
                              <!-- <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template> -->
                            </v-img>
                          </v-avatar>

                          <span
                            class="font-weight-bold ml-1 black--text"
                            style="font-size: 16px">
                            {{ item_visit.user_name }}
                          </span>
                        </div>
                      </div>

                      <v-divider v-if="item.advice.length > 0"
                        style="border-top: 2px dashed #e0e0e0;" />

                      <div v-if="item.advice.length > 0"
                        class="mb-3">
                        <div
                          class="black--text font-weight-bold mt-4 mb-2 black--text"
                          style="font-size: 16px">
                          Advice
                        </div>

                        <div class="black--text"
                          style="font-size: 16px">
                          <span v-if="item.advice !== ''"
                            v-html="item.advice">
                          </span>

                          <span v-else>
                            -
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 2px dashed #e0e0e0;" />

                  <div>
                    <div
                      class="d-flex align-center black--text"
                      style="font-size: 16px">
                      <div>
                        Sub Total
                      </div>

                      <v-spacer />

                      <div>
                        {{ detail.total_item_subtotal | price }}
                      </div>
                    </div>

                    <div
                      class="d-flex align-center black--text"
                      style="font-size: 16px">
                      <div>
                        Down Payment
                      </div>

                      <v-spacer />

                      <div>
                        {{ detail.dp_paid | price }}
                      </div>
                    </div>

                    <div
                      class="d-flex align-center black--text"
                      :class="detail.type === 'home_care' ? '' : ''"
                      style="font-size: 16px">
                      <div style="font-size: 16px">
                        Diskon <span v-if="detail.discount_percent > 0">({{ detail.discount_percent }}%)</span>
                      </div>

                      <v-spacer />

                      <div style="font-size: 16px">
                        {{ detail.discount_value | price }}
                      </div>
                    </div>

                    <div v-if="detail.type === 'home_care'"
                      class="d-flex align-center black--text"
                      style="font-size: 16px">
                      <div style="font-size: 16px">
                        Transport
                      </div>

                      <v-spacer />

                      <div style="font-size: 16px">
                        {{ detail.transport_cost | price }}
                      </div>
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    v-for="(item_payment, index_payment) in detail.payments"
                    :key="index_payment">
                    <div
                      class="d-flex align-start black--text"
                      :class="item_payment.method !== 'cash' ? 'mt-4' : 'my-4'"
                      style="font-size: 16px">
                      <div style="font-size: 16px">
                        Metode Pembayaran

                        <span v-if="detail.payments.length > 1">
                          {{ index_payment + 1 }}
                        </span>
                      </div>

                      <v-spacer />

                      <div>
                        <div
                          class="text-capitalize text-right"
                          style="font-size: 16px">
                          {{ item_payment.method }}
                        </div>

                        <div
                          class="text-capitalize text-right"
                          style="font-size: 16px">
                          {{ item_payment.amount | price }}
                        </div>
                      </div>
                    </div>

                    <div v-if="item_payment.method === 'transfer' || item_payment.method === 'edc'"
                      class="d-flex align-center black--text"
                      style="font-size: 16px">
                      <div style="font-size: 16px">
                        Bank
                      </div>

                      <v-spacer />

                      <div
                        class="text-capitalize"
                        style="font-size: 16px">
                        {{ item_payment.method_bank_name }}
                      </div>
                    </div>

                    <div v-if="item_payment.method !== 'cash'"
                      class="d-flex align-center mb-4 black--text">
                      <div style="font-size: 16px">
                        Nomor Referensi
                      </div>

                      <v-spacer />

                      <div
                        class="text-capitalize"
                        style="font-size: 16px">
                        {{ item_payment.method_ref }}
                      </div>
                    </div>

                    <v-divider
                      class="my-4"
                      style="border-top: 1px dashed #bbb;" />
                  </div>

                  <div>
                    <div
                      class="font-weight-bold black--text d-flex align-center black--text">
                      <div style="font-size: 16px">
                        Total
                      </div>

                      <v-spacer />

                      <div style="font-size: 16px">
                        {{ detail.grand_total | price }}
                      </div>
                    </div>

                    <div
                      class="d-flex align-end black--text">
                      <div>
                        <div
                          class="text-capitalize"
                          style="font-size: 16px">
                          {{ detail.payment_method }}
                        </div>

                        <div style="font-size: 16px">
                          {{ detail.complete_datetime | date }} Pukul {{ detail.complete_datetime | time }}
                        </div>
                      </div>

                      <v-spacer />

                      <div style="font-size: 16px">
                        {{ detail.amount_paid | price }}
                      </div>
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 2px dashed #e0e0e0;" />

                  <div
                    class="d-flex align-center mb-2 black--text">
                    <div style="font-size: 16px">
                      Kasir
                    </div>

                    <v-spacer />

                    <div style="font-size: 16px">
                      {{ detail.cashier_name }}
                    </div>
                  </div>

                  <v-divider
                    v-if="detail.kpsp.length > 0"
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div v-if="detail.kpsp.length > 0"
                    class="mb-4 black--text">
                    <div
                      class="font-weight-bold black--text mb-1"
                      style="font-size: 16px">
                      KPSP
                    </div>

                    <div
                      v-for="(item, index) in detail.kpsp"
                      :key="index">
                      <div
                        class="mb-1"
                        style="font-size: 16px">
                        Petanyaan:
                      </div>

                      <div
                        class="line-text-second mb-1"
                        style="font-size: 16px">
                        {{ item.customer_visit_kpsp_question }}
                      </div>

                      <div
                        class="mb-1"
                        style="font-size: 16px">
                        Jawaban:
                      </div>

                      <div
                        class="black--text"
                        style="font-size: 16px">
                        <strong :class="item.customer_visit_kpsp_answer === 'YA' ? 'green--text' : 'red--text'">{{ item.customer_visit_kpsp_answer }}</strong>
                      </div>

                      <v-divider
                        v-if="index < detail.kpsp.length - 1"
                        class="my-4"
                        style="border-top: 1px dashed #bbb;" />
                    </div>
                  </div>

                  <v-divider
                    v-if="detail.graph.bb.image !== '' || detail.graph.tb.image !== '' || (typeof detail.graph.lila === 'object' && detail.graph.lila.image !== '') || (typeof detail.graph.lk === 'object' && detail.graph.lk.image !== '')"
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div v-if="detail.graph.bb.image !== ''"
                    class="text-center mb-6 black--text">
                    <div>
                      <div
                        class="font-weight-bold black--text mb-1"
                        style="font-size: 16px">
                        {{ detail.graph.bb.title }}
                      </div>
                    </div>

                    <v-img
                      contain
                      width="100%"
                      height="100%"
                      style="border-radius: 10px; border: 2px dashed #bbb;"
                      alt="bidanvitacare"
                      :src="detail.graph.bb.image !== '' ? detail.graph.bb.image : require('@/assets/image/image_default.png')"
                      class="ma-auto d-flex align-center text-center">
                      <!-- <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template> -->
                    </v-img>

                    <div
                      class="body-2 line-text-second mt-4">
                      <div
                        class="black--text font-weight-bold mb-1"
                        style="font-size: 16px">
                        Kesimpulan
                      </div>

                      <div style="font-size: 16px">
                        {{ detail.graph.bb.kesimpulan }}
                      </div>
                    </div>
                  </div>

                  <div v-if="detail.graph.tb.image !== ''"
                    class="text-center mb-6 black--text">
                    <div>
                      <div
                        class="font-weight-bold black--text mb-1"
                        style="font-size: 16px">
                        {{ detail.graph.tb.title }}
                      </div>
                    </div>

                    <v-img
                      contain
                      width="100%"
                      height="100%"
                      style="border-radius: 10px; border: 2px dashed #bbb;"
                      alt="bidanvitacare"
                      :src="detail.graph.tb.image !== '' ? detail.graph.tb.image : require('@/assets/image/image_default.png')"
                      class="ma-auto d-flex align-center text-center">
                      <!-- <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template> -->
                    </v-img>

                    <div
                      class="body-2 line-text-second mt-4">
                      <div
                        class="black--text font-weight-bold mb-1"
                        style="font-size: 16px">
                        Kesimpulan
                      </div>

                      <div style="font-size: 16px">
                        {{ detail.graph.tb.kesimpulan }}
                      </div>
                    </div>
                  </div>

                  <div v-if="typeof detail.graph.lila === 'object' && detail.graph.lila.image !== ''"
                    class="text-center mb-6 black--text">
                    <div>
                      <div
                        class="font-weight-bold black--text mb-1"
                        style="font-size: 16px">
                        {{ detail.graph.lila.title }}
                      </div>
                    </div>

                    <v-img
                      contain
                      width="100%"
                      height="100%"
                      style="border-radius: 10px; border: 2px dashed #bbb;"
                      alt="bidanvitacare"
                      :src="detail.graph.lila.image !== '' ? detail.graph.lila.image : require('@/assets/image/image_default.png')"
                      class="ma-auto d-flex align-center text-center">
                      <!-- <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template> -->
                    </v-img>

                    <div
                      class="body-2 line-text-second mt-4">
                      <div
                        class="black--text font-weight-bold mb-1"
                        style="font-size: 16px">
                        Kesimpulan
                      </div>

                      <div style="font-size: 16px">
                        {{ detail.graph.lila.kesimpulan }}
                      </div>
                    </div>
                  </div>

                  <div v-if="typeof detail.graph.lk === 'object' && detail.graph.lk.image !== ''"
                    class="text-center mb-6 black--text">
                    <div>
                      <div
                        class="font-weight-bold black--text mb-1"
                        style="font-size: 16px">
                        {{ detail.graph.lk.title }}
                      </div>
                    </div>

                    <v-img
                      contain
                      width="100%"
                      height="100%"
                      style="border-radius: 10px; border: 2px dashed #bbb;"
                      alt="bidanvitacare"
                      :src="detail.graph.lk.image !== '' ? detail.graph.lk.image : require('@/assets/image/image_default.png')"
                      class="ma-auto d-flex align-center text-center">
                      <!-- <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template> -->
                    </v-img>

                    <div
                      class="body-2 line-text-second mt-4">
                      <div
                        class="black--text font-weight-bold mb-1"
                        style="font-size: 16px">
                        Kesimpulan
                      </div>

                      <div style="font-size: 16px">
                        {{ detail.graph.lk.kesimpulan }}
                      </div>
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="text-center mb-2 black--text">
                    <div>
                      <div
                        class="font-weight-bold black--text"
                        style="font-size: 16px">
                        Nomor Rekening Bidanvitacare.id
                      </div>

                      <div
                        class="mb-1"
                        style="font-size: 16px">
                        Scan QR Kode untuk melakukan pembayaran
                      </div>
                    </div>

                    <v-img
                      contain
                      width="300"
                      height="300"
                      alt="bidanvitacare"
                      :src="detail.qris_url"
                      class="ma-auto">
                      <!-- <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template> -->
                    </v-img>

                    <div
                      v-for="(item, index) in detail.banks"
                      :key="index"
                      class="mt-1"
                      style="font-size: 16px">
                      No. Rekening - {{ item.label }}
                    </div>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="text-center mb-2 black--text">
                    <div>
                      <div
                        class="font-weight-bold black--text"
                        style="font-size: 16px">
                        Katalog Bidanvitacare.id
                      </div>

                      <div
                        class="mb-1"
                        style="font-size: 16px">
                        Scan QR Kode untuk mengunjungi katalog kami
                      </div>
                    </div>

                    <v-img
                      contain
                      width="300"
                      height="300"
                      alt="bidanvitacare"
                      :src="detail.qrcode_katalog"
                      class="ma-auto">
                      <!-- <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template> -->
                    </v-img>
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="text-center black--text">
                    <div
                      class="font-weight-bold black--text mt-6 mb-1"
                      style="font-size: 16px">
                      Sosial Media Kami
                    </div>

                    <div style="font-size: 16px">
                      Facebook: bidanvitacare.id
                    </div>

                    <div style="font-size: 16px">
                      Instagram: bidanvitacare.id
                    </div>

                    <div style="font-size: 16px">
                      Twitter: bidanvitacare.id
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </section>
      </v-flex>
    </v-layout>

    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Saira+Extra+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  import moment from 'moment'
  moment.locale('id')

  export default {
    middleware: false,
    data () {
      return {
        detail: {},

        margin_bottom: 175,

        process: {
          load: false
        },

        message: {
          success: '',
          error: ''
        },

        // SEO
        content: {
          url: '/invoice/print/:slug',
          title: 'Invoice',
          description: 'Invoice Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        let params = {
          uuid: this.$route.params.slug
        }

        await this.$axios.$get(`${process.env.API}public/order/detail`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.detail = response.results.data

            this.$nextTick(() => {
              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
                block: 'end'
              })

              setTimeout(() => { window.print() }, 2000)
            })
          }
        })
      }
    }
  }
</script>
