<template>
  <div>
    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text
          class="mt-1">
          <v-text-field
            v-model="search"
            @click:clear="search = ''; fetch();"
            v-on:keyup.enter="fetch()"
            filled
            rounded
            dense 
            single-line
            hide-details
            :color="set_color"
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari data yang Anda butuhkan ?"
            clearable>
          </v-text-field>
        </v-card-text>

        <v-card-text
          class="pb-0">
          <div
            class="body-2 font-weight-bold black--text d-flex align-center mb-2">
            <div>
              Titipan
            </div>

            <v-spacer />

            <div>
              <!-- <v-icon 
                @click="dialog.filter = true; fetchStore();"
                class="cursor-pointer">
                mdi-filter-variant
              </v-icon> -->
            </div>
          </div>

          <v-row>
            <v-col>
              <v-skeleton-loader
                v-for="(item, index) in process.load ? 5 : list.data"
                :key="index"
                :loading="process.load"
                :class="index < list.data.length - 1 ? 'mb-4' : ''"
                type="list-item-avatar-three-line">
                <template>
                  <div>
                    <v-list
                      v-if="!process.load && list.data.length > 0"
                      flat
                      dense
                      three-line
                      color="#FFFFFF40"
                      class="border-radius">
                      <v-list-item
                        @click="sheet.detail = true; fetchDetail(item.id);">
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            <div
                              class="caption mb-2 one-line d-flex align-center">
                              <v-avatar
                                size="25"
                                style="border: 2px solid #ffffff;">
                                <v-img 
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare" 
                                  :src="item.therapist_image_url !== '' ? item.therapist_image_url : require('@/assets/image/member_default.jpg')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular 
                                        size="20"
                                        indeterminate 
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>
                              
                              <span
                                class="ml-1">
                                {{ item.therapist_name }}
                              </span>
                            </div>
                          </v-list-item-subtitle>

                          <v-list-item-title
                            class="body-2 font-weight-bold text-capitalize">
                            {{ item.so_code }}
                          </v-list-item-title>

                          <v-list-item-subtitle
                            class="body-2 font-weight-light line-text-second black--text my-2 py-2"
                            style="border-top: 1px dashed #bbb; border-bottom: 1px dashed #bbb;">
                            <div
                              class="d-flex align-center">
                              <div
                                class="caption"
                                style="opacity: 60%; margin-bottom: 1px;">
                                Nominal
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 black--text">
                                {{ item.nominal | price }}
                              </div>
                            </div>

                            <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />

                            <div
                              class="d-flex align-center">
                              <div
                                class="caption"
                                style="opacity: 60%; margin-bottom: 1px;">
                                Dikembalikan
                              </div>

                              <v-spacer />

                              <div
                                class="body-2 black--text">
                                {{ item.nominal_return | price }}
                              </div>
                            </div>
                          </v-list-item-subtitle>

                          <v-list-item-subtitle
                            class="body-2 line-text-second">
                            {{ item.create_datetime | date }} Pukul {{ item.create_datetime | time }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </template>
              </v-skeleton-loader>
            </v-col>
          </v-row>

          <div v-if="!process.load && pagination.data.total_data > 10 && limit < pagination.data.total_data"
            class="text-center my-4">
            <v-btn
              @click="limit += 10"
              :loading="process.limit"
              small
              rounded
              outlined
              elevation="0"
              :color="set_color"
              class="text-capitalize"
              :style="`color: ${set_color};`">
              Lebih banyak
            </v-btn>
          </div>

          <Empty v-if="!process.load && pagination.data.total_page < 1"
            class="py-6"
            margin="my-16"
            size="125"
            message="Titipan" />
        </v-card-text>
      </v-card>
    </section>

    <v-bottom-sheet
      v-model="sheet.detail"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card v-if="Object.keys(detail).length > 0"
        flat
        class="background">
        <div
          class="blur">
          <v-card-title 
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon 
              @click="sheet.detail = false; detail = {}; fetch();"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span 
              @click="sheet.detail = false; detail = {}; fetch();"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Titipan {{ detail.therapist_name }}
            </span>
          </v-card-title>

          <v-card
            flat
            height="calc(100vh - 80px)"
            style="overflow: auto;"
            color="transparent">
            <v-card-text 
              class="pt-16 mt-4 pb-0">
              <div
                class="d-flex align-center">
                <v-avatar
                  size="36"
                  style="border: 2px solid #ffffff;">
                  <v-img 
                    width="100%"
                    height="100%"
                    alt="bidanvitacare" 
                    :src="detail.therapist_image_url !== '' ? detail.therapist_image_url : require('@/assets/image/member_default.jpg')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="20"
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <span
                  class="font-weight-bold black--text ml-2">
                  {{ detail.therapist_name }}
                </span>
              </div>
            </v-card-text>

            <v-card-text>
              <v-divider
                class="mb-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="body-2 font-weight-bold">
                {{ detail.so_code }}
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />
              

              <div
                class="d-flex align-center mb-4">
                <div
                  class="body-2">
                  Nominal
                </div>

                <v-spacer />

                <div
                  class="body-2 font-weight-bold black--text">
                  {{ detail.nominal | price }}
                </div>
              </div>
              
              <div
                class="d-flex align-center">
                <div
                  class="body-2">
                  Dikembalikan
                </div>

                <v-spacer />

                <div
                  class="body-2 font-weight-bold black--text">
                  {{ detail.nominal_return | price }}
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="d-flex align-center">
                <div
                  class="body-2">
                  Admin
                </div>

                <v-spacer />

                <div
                  class="body-2 font-weight-bold black--text">
                  {{ detail.user_create_name }}
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <div
                class="body-2">
                {{ detail.create_datetime | date }} Pukul {{ detail.create_datetime | time }}
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    middleware: 'authenticated',
    data () {
      return {
        sheet: {
          form: false,
          detail: false
        },

        search: '',

        filter: {
          store_id: ''
        },

        detail: {},

        list: {
          data: []
        },
        pagination: {
          data: {
            total_data: 0,
            total_page: 0
          }
        },

        limit: 10,
        
        process: {
          load: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.process.limit = true
        
        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit,
          store_id: this.filter.store_id
        }

        await this.$axios.$get(`${process.env.API}therapist/therapist-cash/data`, { params })
        .then((response) => {
          this.process.load = false
          
          this.process.limit = false

          if (response.status === 200) {
            this.list.data = response.results.data
            this.pagination.data = response.results.pagination
          }
        })
      },
      async fetchDetail (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}therapist/therapist-cash/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail = response.results.data
          }
        })
      }
    }
  }
</script>